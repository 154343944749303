import Joi from 'joi';

const ConfirmClaim = Joi.object({
	paymentMethod: Joi.string()
		.label('Payment Method')
		.trim()
		.min(1)
		.max(50)
		.required(),
	bank: Joi.string()
		.label('Bank')
		.trim()
		.min(1)
		.max(50)
		.when('paymentMethod', {
			is: 'ibanking',
			then: Joi.required(),
			otherwise: Joi.optional().allow(''),
		}),
	beneficiaryName: Joi.string()
		.label('Beneficiary Name')
		.trim()
		.min(1)
		.max(50)
		.when('paymentMethod', {
			is: 'ibanking',
			then: Joi.required(),
			otherwise: Joi.optional().allow(''),
		}),
	accountNo: Joi.string()
		.label('Account Number')
		.trim()
		.min(1)
		.max(50)
		.required(),
	clientNotes: Joi.string()
		.label('Notes')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default ConfirmClaim;
