import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../config/api';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.secondary,
	},
}));

export default function ContextMenu(props) {
	const { invoice, invoiceDocument, setTriggerUpdate } = props;
	const classes = useStyles();
	const [menu, setMenu] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleResendInvoice = (e) => {
		e.preventDefault();

		if (loading) {
			return;
		} else {
			setLoading(true);

			const data = new FormData();
			let generatedDate = moment(new Date()).format('DD-MM-YYYY');
			data.append('Invoice', invoiceDocument, `invoice-${generatedDate}.pdf`);
			data.append('json', JSON.stringify(invoice));

			try {
				api.post(`invoices/resend/${invoice._id}`, data).then((res) => {
					if (res.data.ok) {
						toast.success('Invoice sent successfully!');
						setLoading(false);
						setTriggerUpdate(true);
					} else {
						toast.error(res.data.error);
						setLoading(false);
					}
				});
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		}
	};

	const handleDownloadInvoice = (e) => {
		e.preventDefault();

		if (window.Blob && window.URL) {
			let generatedDate = moment(new Date()).format('DD-MM-YYYY');
			const url = window.URL.createObjectURL(invoiceDocument);
			const a = document.createElement('a');
			a.href = url;
			a.download = `invoice-${generatedDate}.pdf`;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		} else {
			toast.error(
				'Your browser does not support the download functionality. Please try a different browser.'
			);
		}
	};

	return (
		<div>
			<IconButton
				style={{ padding: 0 }}
				onClick={(e) => {
					setMenu(e.currentTarget);
				}}
			>
				<MoreHorizOutlinedIcon />
			</IconButton>
			<Menu
				id='menu'
				open={Boolean(menu)}
				anchorEl={menu}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				onClose={() => setMenu(null)}
				className={classes.headerMenu}
				classes={{ paper: classes.menu }}
				disableAutoFocusItem
			>
				<MenuItem
					className={classNames(classes.menuLink, classes.headerMenuItem)}
					onClick={(e) => {
						setMenu(null);
						handleDownloadInvoice(e);
					}}
					style={{ textDecoration: 'none' }}
					disabled={loading}
				>
					Download Invoice
				</MenuItem>

				<MenuItem
					className={classNames(classes.menuLink, classes.headerMenuItem)}
					onClick={(e) => {
						setMenu(null);
						handleResendInvoice(e);
					}}
					style={{ textDecoration: 'none' }}
					disabled={loading}
				>
					Resend Invoice
				</MenuItem>
			</Menu>
		</div>
	);
}
