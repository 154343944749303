import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Joi from 'joi';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FileUploader from '../../components/FileUploader';
import api from '../../config/api';
import Backdrop from '../../components/Backdrop';

const useStyles = makeStyles((theme) => ({
	paper: {
		minWidth: 560,
		padding: 24,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
}));

const UploadDocument = (props) => {
	const {
		open,
		handleClose,
		body,
		value,
		transactionId,
		data,
		setTriggerUpdate,
	} = props;
	const classes = useStyles();
	const [documentName, setDocumentName] = useState('');
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);

	const handleValidation = () => {
		let result = Joi.object({
			documentName: Joi.string()
				.label('Document Name')
				.trim()
				.min(1)
				.max(50)
				.required(),
		}).validate(
			{ documentName: documentName },
			{
				abortEarly: false,
				convert: false,
			}
		);

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			console.log(error.details);
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleFileChange = (e, files) => {
		e.preventDefault();
		console.log(files);
		setSelectedDocuments(files);
	};

	const handleSubmit = () => {
		if (!loading) {
			setLoading(true);

			let valid = handleValidation();

			if (valid) {
				const formData = new FormData();

				formData.append(
					documentName,
					selectedDocuments[0],
					selectedDocuments[0].name
				);

				let json = {
					transactionId: transactionId,
					type: value,
					documentName: documentName,
				};
				formData.append('json', JSON.stringify(json));

				try {
					console.log(`Upload type: ${value}, txnId: ${transactionId}`);
					api.post('documents', formData).then((res) => {
						console.log(res.data);
						if (res.data.ok) {
							toast.success('Document uploaded successfully!');
							setTriggerUpdate(true);
						} else {
							toast.error('Document upload failed!');
						}
					});
				} catch (error) {
					console.log(error);
				} finally {
					setLoading(false);
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	return (
		<>
			<Backdrop text='Uploading File...' open={loading} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Upload Document
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					disableRipple
					disableFocusRipple
					disableTouchRipple
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<Grid
				style={{ marginTop: 24 }}
				container
				direction='row'
				alignItems='center'
				spacing={3}
			>
				<Grid item xs={6}>
					<Typography color='textPrimary' variant='body2'>
						Document Name
					</Typography>
					<TextField
						name='documentName'
						value={documentName}
						onChange={(e) => setDocumentName(e.target.value)}
						placeholder='Enter Document Name'
						variant='filled'
						fullWidth
						margin='dense'
						error={!!errorMessages['documentName']}
						helperText={
							errorMessages['documentName'] && errorMessages['documentName']
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<FileUploader
						fileLimit={1}
						files={selectedDocuments}
						handleFileChange={handleFileChange}
						formErrorMessage={
							errorMessages[`selectedDocuments`] &&
							errorMessages[`selectedDocuments`]
						}
					/>
				</Grid>
			</Grid>
			<div style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginRight: 'auto' }}
					onClick={handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={handleSubmit}
					disabled={selectedDocuments.length < 1 || !documentName}
					variant='contained'
					color='primary'
				>
					Upload
				</Button>
			</div>
		</>
	);
};

const ReplaceDocument = (props) => {
	const {
		open,
		handleClose,
		body,
		value,
		transactionId,
		data,
		setTriggerUpdate,
	} = props;
	const classes = useStyles();
	const [documentName, setDocumentName] = useState(data.documentName);
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);

	const handleValidation = () => {
		let result = Joi.object({
			documentName: Joi.string()
				.label('Document Name')
				.trim()
				.min(1)
				.max(50)
				.required(),
		}).validate(
			{ documentName: documentName },
			{
				abortEarly: false,
				convert: false,
			}
		);

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			console.log(error.details);
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleFileChange = (e, files) => {
		e.preventDefault();
		setSelectedDocuments(files);
	};

	const handleSubmit = () => {
		if (!loading) {
			setLoading(true);

			let valid = handleValidation();

			if (valid) {
				const formData = new FormData();

				formData.append(
					documentName,
					selectedDocuments[0],
					selectedDocuments[0].name
				);

				let json = {
					transactionId: transactionId,
					type: value,
					documentName: documentName,
				};
				formData.append('json', JSON.stringify(json));

				try {
					console.log(`Upload type: ${value}, txnId: ${transactionId}`);
					api.put(`documents/${data.documentId}`, formData).then((res) => {
						console.log(res.data);
						if (res.data.ok) {
							toast.success('Document uploaded successfully!');
							setTriggerUpdate(true);
						} else {
							toast.error('Document upload failed!');
						}
					});
				} catch (error) {
					console.log(error);
				} finally {
					setLoading(false);
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	return (
		<>
			<Backdrop text='Uploading File...' open={loading} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Replace Document
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					disableRipple
					disableFocusRipple
					disableTouchRipple
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<Grid
				style={{ marginTop: 12 }}
				container
				direction='row'
				alignItems='center'
				spacing={3}
			>
				<Grid item xs={12}>
					<Typography variant='body1' color='textPrimary'>
						You may rename the Document Name to label your replacement document.
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography color='textPrimary' variant='body2'>
						Document Name
					</Typography>
					<TextField
						name='documentName'
						value={documentName}
						onChange={(e) => setDocumentName(e.target.value)}
						placeholder='Enter Document Name'
						variant='filled'
						fullWidth
						margin='dense'
						error={!!errorMessages['documentName']}
						helperText={
							errorMessages['documentName'] && errorMessages['documentName']
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<FileUploader
						fileLimit={1}
						files={selectedDocuments}
						handleFileChange={handleFileChange}
						formErrorMessage={
							errorMessages[`selectedDocuments`] &&
							errorMessages[`selectedDocuments`]
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant='body1'
						color='textPrimary'
						style={{
							color: props.theme.palette.error.main,
						}}
					>
						Note: This action cannot be undone. The replacement file will
						completely overwrite the original.
					</Typography>
				</Grid>
			</Grid>
			<div style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginRight: 'auto' }}
					onClick={handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={handleSubmit}
					disabled={selectedDocuments.length < 1 || !documentName}
					variant='contained'
					color='primary'
				>
					Update
				</Button>
			</div>
		</>
	);
};

const DeleteDocument = (props) => {
	const {
		open,
		handleClose,
		body,
		value,
		transactionId,
		data,
		setTriggerUpdate,
	} = props;
	const classes = useStyles();
	const [loading, setLoading] = useState(false);

	const handleSubmit = () => {
		if (!loading) {
			setLoading(true);

			try {
				console.log(`Delete type: ${value}, txnId: ${transactionId}`);
				api.delete(`documents/${data.documentId}?type=${value}`).then((res) => {
					console.log(res.data);
					if (res.data.ok) {
						toast.success('Document deleted successfully!');
						setTriggerUpdate(true);
					} else {
						toast.error('Document delete failed!');
					}
				});
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<>
			<Backdrop text='Deleting File...' open={loading} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Delete Document
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					disableRipple
					disableFocusRipple
					disableTouchRipple
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<Typography
				variant='body1'
				color='textPrimary'
				style={{ marginTop: 24, marginBottom: 24 }}
			>
				Are you sure you want to delete the following Document?
			</Typography>
			<Typography color='textPrimary' variant='body2'>
				{data.documentName}
			</Typography>
			<Typography color='textPrimary' variant='body1'>
				{data.displayName}
			</Typography>
			<Typography
				variant='body1'
				color='textPrimary'
				style={{
					marginTop: 24,
					color: props.theme.palette.error.main,
				}}
			>
				Note: This action cannot be undone.
			</Typography>
			<div style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginRight: 'auto' }}
					onClick={handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={handleSubmit}
					variant='contained'
					style={{
						backgroundColor: props.theme.palette.error.main,
						color: props.theme.palette.background.default,
					}}
				>
					Delete
				</Button>
			</div>
		</>
	);
};

const getBody = (props) => {
	switch (props.body) {
		case 'uploadDocument':
			return UploadDocument(props);
		case 'replaceDocument':
			return ReplaceDocument(props);
		case 'deleteDocument':
			return DeleteDocument(props);
		default:
			return 'Unknown modal';
	}
};

const DocumentModal = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			maxWidth={props.size}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<div className={classes.paper}>{getBody({ ...props, theme: theme })}</div>
		</Dialog>
	);
};

export default DocumentModal;
