import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserProfile } from '../../../components/Session';
import Email from './email';
import Invoice from './invoice';
import Tags from './tags';

function TabPanel(props) {
	const { children, value, selected, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== selected}
			id={`full-width-tabpanel-${selected}`}
			aria-labelledby={`full-width-tab-${selected}`}
			{...other}
		>
			{value === selected && <Box p={3}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginLeft: 24,
	},
	tabRoot: {
		minWidth: 10,
	},
}));

const options = [
	// {
	// 	label: 'Calendar',
	// 	endpoint: 'calendar',
	// },
	{
		label: 'Email Templates',
		endpoint: 'email-templates',
	},
	{
		label: 'Invoice',
		endpoint: 'invoice',
	},
	{
		label: 'Tags',
		endpoint: 'tags',
	},
];

export default function Customization(props) {
	const userProfile = useUserProfile();
	const { subType } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [selected, setSelected] = useState('email-templates');

	useEffect(() => {
		if (subType) {
			if (options.some((option) => subType === option.endpoint))
				setSelected(subType);
		} else {
			setSelected('email-templates');
		}
	}, [props]);

	return (
		<>
			<div className={classes.root}>
				<Tabs value={selected} aria-label='full width tabs example'>
					{options.map((option, index) => (
						<Tab
							classes={{ root: classes.tabRoot }}
							key={option.endpoint}
							label={option.label}
							value={option.endpoint}
							component={Link}
							to={'/settings/customization/' + option.endpoint}
							disabled={
								option.endpoint === 'invoice' && !userProfile.hasOrganization
							}
							{...a11yProps(index)}
						/>
					))}
				</Tabs>
			</div>
			<Divider />

			<Box width='100%' style={{ height: 'inherit', overflowY: 'auto' }}>
				{/* <TabPanel value={selectedIndex} index={0} dir={theme.direction}>
					<Calendar />
				</TabPanel> */}
				<TabPanel
					value={'email-templates'}
					index={0}
					selected={selected}
					dir={theme.direction}
				>
					<Email />
				</TabPanel>
				<TabPanel
					value={'invoice'}
					index={1}
					selected={selected}
					dir={theme.direction}
				>
					<Invoice />
				</TabPanel>
				<TabPanel
					value={'tags'}
					index={2}
					selected={selected}
					dir={theme.direction}
				>
					<Tags />
				</TabPanel>
			</Box>
		</>
	);
}
