import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import React, { useState, useEffect } from 'react';
import Chart from 'react-google-charts';
import { Link } from 'react-router-dom';
import Calendar from '../../components/Calendar/index';
import api from '../../config/api';
import { makeStyles } from '@material-ui/core/styles';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { AuthUserContext } from '../../components/Session';

import {
	Clients,
	GrossPremium,
	PoliciesExpiring,
	Principals,
	RecentlyViewed,
	Revenue,
	SalesTargets,
	Transactions,
} from '../../components/Widgets';

const GridLayout = WidthProvider(Responsive);

const useStyles = makeStyles((theme) => ({}));

const defaultLayouts = {
	lg: [
		{ i: '1', w: 1, h: 1, x: 0, y: 0 },
		{ i: '2', w: 1, h: 1, x: 1, y: 0 },
		{ i: '3', w: 1, h: 1, x: 0, y: 1 },
		{ i: '4', w: 1, h: 1, x: 1, y: 1 },
	],
};

export default function Dashboard() {
	const classes = useStyles();
	const [layouts, setLayouts] = useState(defaultLayouts);
	const [loading, setLoading] = useState(false);

	const resetLayouts = () => {
		setLayouts(defaultLayouts);
		// setLayout({ ...defaultLayout });
	};

	const handleLayoutChange = (layout, layouts) => {
		setLayouts(layouts);
	};

	const handleResize = (layout, oldLayoutItem, layoutItem, placeholder) => {
		//snap to max height
		if (layoutItem.h >= 1.35 && layoutItem.w <= 1.35) {
			layoutItem.w = 1;
			layoutItem.h = 2;
			placeholder.w = 1;
			placeholder.h = 2;
		}
		//snap to max width
		if (layoutItem.h <= 1.35 && layoutItem.w >= 1.35) {
			layoutItem.w = 2;
			layoutItem.h = 1;
			placeholder.w = 2;
			placeholder.h = 1;
		}
		//snap to max width & height
		if (layoutItem.h >= 1.35 && layoutItem.w >= 1.35) {
			layoutItem.w = 2;
			layoutItem.h = 2;
			placeholder.w = 2;
			placeholder.h = 2;
		}
	};

	const removeWidget = (key) => {
		// this.setState({ items: _.reject(this.state.items, { i: i }) });
	};

	const renderWidget = (el) => {
		const removeStyle = {
			position: 'absolute',
			right: '2px',
			top: 0,
			cursor: 'pointer',
		};
		return (
			<div key={el.i}>
				<span className='text'>{el.i}</span>
			</div>
			// <div key={el.i} style={{ backgroundColor: '#F7FAFC' }} data-grid={el}>
			// 	{/* {getType(el.i)} */}
			// 	{/* <div style={{ width: '100%', height: '100%' }}>{el.i}</div> */}
			// 	<span
			// 	// className='remove'
			// 	// style={removeStyle}
			// 	// onClick={removeWidget(el.i)}
			// 	>
			// 		{el.i}
			// 	</span>
			// 	{/* <span
			// 		className='remove'
			// 		style={removeStyle}
			// 		onClick={removeWidget(el.i)}
			// 	>
			// 		x
			// 	</span> */}
			// </div>
		);
	};

	return (
		<AuthUserContext.Consumer>
			{(credentials) => (
				<>
					<Typography variant='h2' style={{ textTransform: 'capitalize' }}>
						{!credentials.userProfile.profile.firstName ? (
							<>{`Welcome Back, ${credentials.authUser._delegate.email}`}</>
						) : (
							<>{`Welcome Back, ${credentials.userProfile.profile.firstName}`}</>
						)}
						{/* {`Welcome Back, ${credentials.userProfile.profile.firstName}`} */}
					</Typography>
					{/* <Button
						variant='outlined'
						color='primary'
						onClick={() => resetLayouts()}
					>
						Reset Layout
					</Button> */}
					<Divider style={{ margin: '24px -24px' }} />

					<Grid container spacing={3}>
						<Grid item xs={12} md={6} style={{ height: 344 }}>
							<GrossPremium />
						</Grid>
						<Grid item xs={12} md={3} style={{ height: 344 }}>
							<SalesTargets />
						</Grid>
						<Grid item xs={12} md={3} style={{ height: 344 }}>
							<Principals />
						</Grid>
						<Grid item xs={12} md={6} style={{ height: 344 }}>
							<Transactions />
						</Grid>
						<Grid item xs={12} md={3} style={{ height: 344 }}>
							<Clients />
						</Grid>
						<Grid item xs={12} md={3} style={{ height: 344 }}>
							<Revenue />
						</Grid>
						<Grid item xs={12} md={6} style={{ height: 344 }}>
							<PoliciesExpiring />
						</Grid>
						<Grid item xs={12} md={6} style={{ height: 344 }}>
							<RecentlyViewed uid={credentials.authUser._delegate.uid} />
						</Grid>
					</Grid>

					{/* <GridLayout
						className='layout'
						cols={{ lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 }}
						breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
						rowHeight={400}
						// isResizable={true}
						// isDraggable={true}
						// compactType='vertical'
						// margin={[20, 20]}
						// containerPadding={[50, 50]}
						layouts={layouts}
						onLayoutChange={(layout, layouts) =>
							handleLayoutChange(layout, layouts)
						}
						// onBreakpointChange={this.onBreakpointChange}
						// onWidthChange={this.onWidthChange}
						// onResize={() => handleResize()}
					>
						{layouts && layouts.lg && layouts.lg.map((el) => renderWidget(el))}
					</GridLayout> */}
				</>
			)}
		</AuthUserContext.Consumer>
	);
}
