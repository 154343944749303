import DateFnsUtils from '@date-io/date-fns';
import Backdrop from '../../components/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import matchSorter from 'match-sorter';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../config/api';
import { RecordPayment as RecordPaymentSchema } from '../../utils/validators/schemas';
import FileUploader from '../../components/FileUploader';
import Tooltip from '../../components/Tooltip';

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 32,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
}));

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

const RecordPayment = (props) => {
	const initFormData = {
		paymentDate: new Date(),
		paymentMethod: 'Bank Transfer / Internet Banking',
		paymentNotes: '',
	};
	const { open, handleClose, body, invoiceId, data, setTriggerUpdate } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [expand, setExpand] = useState(false);
	const [formData, setFormData] = useState(initFormData);
	const [errorMessages, setErrorMessages] = useState({});
	const [commissionValue, setCommissionValue] = useState('');
	const [commissionPercentage, setCommissionPercentage] = useState('');
	const [loading, setLoading] = useState(false);

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;

		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleDateChange = (target, date) => {
		setFormData((prev) => ({
			...prev,
			[target]: date,
		}));
	};

	const handleFileChange = (e, files) => {
		e.preventDefault();
		setFormData((prev) => ({
			...prev,
			fileData: files,
		}));
	};

	const handleValidation = () => {
		let result = RecordPaymentSchema.validate(formData, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);
			let valid = handleValidation();

			if (valid) {
				const data = new FormData();
				let json = cloneDeep(formData);
				if (!!formData.fileData) {
					data.append(
						'Payment Document',
						formData.fileData[0],
						formData.fileData[0].name
					);
					delete json.fileData;
				}

				console.log(JSON.stringify(json, null, 4));
				data.append('json', JSON.stringify(json));

				try {
					api.put(`invoices/${invoiceId}/record-payment`, data).then((res) => {
						if (res.data.ok) {
							toast.success('Payment recorded successfully!');
							setLoading(false);
							setTriggerUpdate(true);
							handleClose();
						} else {
							toast.error(res.data.error);
							setLoading(false);
							handleClose();
						}
					});
				} catch (error) {
					console.log(error);
					toast.error(error);
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	return (
		<>
			<Backdrop text='Loading Data...' open={loading} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Record Payment
				</Typography>
			</div>
			<Grid container spacing={4} style={{ paddingBottom: 10, marginTop: 24 }}>
				<Grid item xs={12}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Total Premium
					</Typography>
					<Typography
						style={{ marginTop: 8 }}
						color='textPrimary'
						variant='body1'
						align='left'
					>
						{currencyFormatter.format(data)}
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Payment Date
					</Typography>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							name='paymentDate'
							disableToolbar
							placeholder='DD/MM/YYYY'
							inputVariant='filled'
							format='dd/MM/yyyy'
							disableFuture
							openTo='year'
							views={['year', 'month', 'date']}
							margin='dense'
							fullWidth
							value={formData.paymentDate ? formData.paymentDate : null}
							autoOk
							variant='inline'
							invalidDateMessage='Invalid Date Format'
							onChange={(date) => handleDateChange('paymentDate', date)}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							error={!!errorMessages[`paymentDate`]}
							helperText={
								errorMessages[`paymentDate`] && errorMessages[`paymentDate`]
							}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={7}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Payment Method
					</Typography>
					<TextField
						name='paymentMethod'
						value={formData.paymentMethod}
						onChange={(e) => handleInputChange(e)}
						placeholder='Enter Payment Method'
						variant='filled'
						fullWidth
						margin='dense'
						disabled
						error={!!errorMessages[`paymentMethod`]}
						helperText={
							errorMessages[`paymentMethod`] && errorMessages[`paymentMethod`]
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Payment Notes
					</Typography>
					<TextField
						name='paymentNotes'
						value={formData.paymentNotes}
						onChange={(e) => handleInputChange(e)}
						placeholder='Enter Payment Notes'
						variant='filled'
						fullWidth
						margin='dense'
						multiline
						minRows={4}
						error={!!errorMessages[`paymentNotes`]}
						helperText={
							errorMessages[`paymentNotes`] && errorMessages[`paymentNotes`]
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Typography
							style={{ marginRight: 8 }}
							color='textPrimary'
							variant='body2'
							align='left'
						>
							Upload Payment Document
						</Typography>
						<Tooltip content={printReqString()} />
					</div>
					<div
						style={{
							width: '100%',
							marginTop: 8,
							marginBottom: 4,
						}}
					>
						<FileUploader
							fileLimit={1}
							files={formData.fileData}
							handleFileChange={handleFileChange}
							formErrorMessage={
								errorMessages[`paymentDocument`] &&
								errorMessages[`paymentDocument`]
							}
						/>
					</div>
				</Grid>
			</Grid>
			<div style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginRight: 'auto' }}
					onClick={handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={(e) => handleSubmit(e)}
					variant='contained'
					color='primary'
				>
					Submit
				</Button>
			</div>
		</>
	);
};

const confirmDeleteInvoices = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Delete Invoice(s)
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, marginBottom: 24 }}
		>
			Are you sure you want to delete the selected invoice(s)?
		</Typography>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, color: props.theme.palette.error.main }}
		>
			Note: This action cannot be undone.
		</Typography>
		<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
			<Button
				style={{ marginRight: 16 }}
				onClick={props.handleClose}
				variant='outlined'
				color='primary'
			>
				Cancel
			</Button>
			<Button
				onClick={props.handleConfirm}
				variant='contained'
				style={{
					backgroundColor: props.theme.palette.error.main,
					color: props.theme.palette.background.default,
				}}
			>
				Delete
			</Button>
		</div>
	</>
);

const getBody = (props) => {
	switch (props.body) {
		case 'recordPayment':
			return RecordPayment(props);
		case 'deleteInvoices':
			return confirmDeleteInvoices(props);

		default:
			return 'Unknown modal';
	}
};

const InvoiceModal = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<div className={classes.paper}>{getBody({ ...props, theme: theme })}</div>
		</Dialog>
	);
};

export default InvoiceModal;
