import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import Workflows from '../../../../../components/Data/workflows.json';

const CustomConnector = withStyles({
	alternativeLabel: {
		top: 12,
		left: 'calc(-88% + 16px)',
		right: 'calc(100% + 16px)',
	},
})(StepConnector);

const useStepIconStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.grey[400],
	},
	active: {
		color: theme.palette.tertiary.main,
	},
	completed: {
		color: theme.palette.success.main,
	},
}));

function CustomStepIcon(props) {
	const classes = useStepIconStyles();
	const { active, completed } = props;

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{completed ? (
				<CheckCircleIcon />
			) : (
				<>
					{active ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
				</>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	rootContainer: {
		padding: 24,
	},
	container: {
		'&:not(:first-child)': {
			marginTop: 32,
		},
	},
	stepper: {
		padding: 24,
		width: '100%',
		backgroundColor: 'unset',
	},
	step: {
		flex: '0 1 160px',
		padding: 0,
	},
	stepLabelRoot: {
		alignItems: 'flex-start',
	},
	stepLabelLabel: {
		fontSize: '0.875rem',
		fontWeight: 500,
		'&&[class*="MuiStepLabel-alternativeLabel"]': {
			textAlign: 'left',
			marginTop: 0,
		},
		color: theme.palette.grey[400],
		'&&[class*="MuiStepLabel-active"]': {
			fontWeight: 500,
		},
		'&&[class*="MuiStepLabel-completed"]': {
			fontWeight: 500,
			color: theme.palette.success.main,
		},
	},
}));

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

//0: Quotation/Renewal - No Docs + Normal/Self-Gen
//1: Quotation/Renewal - Have Docs + Normal/Self-Gen
//2: Quotation/Renewal - No Docs + Data Entry
//3: Quotation/Renewal - Have Docs + Data Entry
//4: Claims - No Docs + Normal
//5: Claims - Have Docs + Normal
//4: Claims - No Docs + Data Entry
//6: Claims - Have Docs + Data Entry
const workflowMapping = {
	'Quotation:false:false': 0,
	'Quotation:true:false': 1,
	'Quotation:false:true': 2,
	'Quotation:true:true': 3,
	'Renewal:false:false': 0,
	'Renewal:true:false': 1,
	'Renewal:false:true': 2,
	'Renewal:true:true': 3,
	'Claims:false:false': 4,
	'Claims:true:false': 5,
	'Claims:false:true': 4,
	'Claims:true:true': 6,
	Default: 0,
};

const stepMapping = {
	'0:In-Progress:Pending Client': 0, // 0/2/4
	'0:In-Progress:Pending Quotes': 1, // 0
	'0:In-Progress:Pending Underwriter': 1, // 0
	'0:In-Progress:Pending Quote Selection': 2, // 0/1
	'0:Approved In-Principle:Pending Quote Processing': 3, // 0/1
	'0:Approved In-Principle:Pending Policy Info': 4, // 0/1/2
	'0:Completed:Quote Declined': 5, // 0/1
	'0:Completed:Policy In Force': 5, // 0/1/2/3

	'1:In-Progress:Pending Underwriter': 0, // 1
	'1:In-Progress:Pending Quote Selection': 1, // 0/1
	'1:Approved In-Principle:Pending Quote Processing': 2, // 0/1
	'1:Approved In-Principle:Pending Policy Info': 3, // 0/1/2
	'1:Completed:Quote Declined': 4, // 0/1
	'1:Completed:Policy In Force': 4, // 0/1/2/3

	'2:In-Progress:Pending Client': 0, // 0/2/4
	'2:In-Progress:Pending Policy Info': 1, // 0/1/2
	'2:Completed:Policy In Force': 3, // 0/1/2/3

	'3:Completed:Policy In Force': 1, // 0/1/2/3

	'4:In-Progress:Pending Client': 0, // 0/2/4
	'4:In-Progress:Pending Claim Assessment': 1, // 4
	'4:In-Progress:Pending Underwriter': 1, // 0/2/4
	'4:In-Progress:Pending Claim Info': 1, // 4/5
	'4:Approved In-Principle:Pending Claim Processing': 2, // 4/5
	'4:Completed:Claim Declined': 3, // 4/5
	'4:Completed:Claim Processed': 3, // 4/5/6

	'5:In-Progress:Pending Claim Info': 0, // 4/5
	'5:Approved In-Principle:Pending Claim Processing': 1, // 4/5
	'5:Completed:Claim Declined': 2, // 4/5
	'5:Completed:Claim Processed': 2, // 4/5/6

	'6:Completed:Claim Declined': 1, // 4/5/6
	'6:Completed:Claim Processed': 1, // 4/5/6
	// 'In-Progress:Pending Claim Confirmation': 2,
	Default: 0,
};

function getWorkflow(transaction) {
	const { transactionType, clientRequestId, isDirectPurchase } = transaction;
	const haveDocuments = isEmpty(clientRequestId);
	const key = `${transactionType}:${haveDocuments}:${isDirectPurchase}`;
	return {
		workflowId: workflowMapping[key],
		steps:
			Workflows[workflowMapping[key]] || Workflows[workflowMapping['Default']],
	};
}

function getActiveStep(transaction, workflowId) {
	const { status, subStatus } = transaction;
	const workflowKey = `${workflowId}:${status}:${subStatus}`;
	console.log(workflowKey);
	return stepMapping[workflowKey] || stepMapping['Default'];
}

export default function SummaryViewer(props) {
	const { transaction, setTransaction, setTriggerUpdate } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [selectedQuoteInfo, setSelectedQuoteInfo] = useState(null);

	const { workflowId, steps } = getWorkflow(transaction);
	const currentStep = getActiveStep(transaction, workflowId);

	const getSelectedQuoteInfo = () => {
		const [requestId, quoteName] =
			transaction.quoteSelectionRequest.responseData.selectedQuote.split('-');
		let selectedRequest = transaction.requests.find(
			(req) => req._id === requestId
		);
		let quote = selectedRequest.responseData.quoteList.find(
			(quote) => quote.quoteName === quoteName
		);

		setSelectedQuoteInfo({
			principalName: selectedRequest.principalName,
			selectedQuote: quote,
		});
	};

	useEffect(() => {
		if (transaction.quoteSelectionRequest?.status === 'Responded') {
			getSelectedQuoteInfo();
		}
	}, [props]);

	return (
		<>
			<Stepper
				className={classes.stepper}
				activeStep={currentStep}
				alternativeLabel
				connector={<CustomConnector />}
			>
				{steps.map((label) => (
					<Step key={label} className={classes.step}>
						<StepLabel
							classes={{
								root: classes.stepLabelRoot,
								alternativeLabel: classes.stepLabelLabel,
							}}
							StepIconComponent={CustomStepIcon}
						>
							{label}
						</StepLabel>
					</Step>
				))}
			</Stepper>
			<div className={classes.rootContainer}>
				<div id='txn-summary-container' className={classes.container}>
					<Typography
						style={{ flexGrow: 1 }}
						color='textPrimary'
						variant='h4'
						align='left'
					>
						TRANSACTION DETAILS
					</Typography>
					<Divider style={{ margin: '16px 0px' }} />
					<div style={{ width: '90%' }}>
						<Grid container spacing={1}>
							<Grid
								item
								xs={12}
								sm={6}
								container
								spacing={1}
								alignItems='baseline'
							>
								<Grid item xs={12} sm={6}>
									<Typography color='textPrimary' variant='body2' align='left'>
										Transaction Number
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography color='textPrimary' variant='body1' align='left'>
										TXN-{transaction.transactionNo}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								container
								spacing={1}
								alignItems='baseline'
							>
								<Grid item xs={12} sm={6}>
									<Typography color='textPrimary' variant='body2' align='left'>
										Policyholder / Insured
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6}>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Link
											style={{
												display: 'inline-block',
											}}
											component={RouterLink}
											color='primary'
											to={'/contacts/' + transaction.client.contactId._id}
										>
											{transaction.client.contactName}
										</Link>
										{transaction.client.companyId && (
											<Link
												style={{
													display: 'inline-block',
												}}
												component={RouterLink}
												color='primary'
												to={'/companies/' + transaction.client.companyId._id}
											>
												{transaction.client.companyName}
											</Link>
										)}
									</div>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								container
								spacing={1}
								alignItems='baseline'
							>
								<Grid item xs={12} sm={6}>
									<Typography color='textPrimary' variant='body2' align='left'>
										Transaction Type
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography color='textPrimary' variant='body1' align='left'>
										{transaction.transactionType}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								container
								spacing={1}
								alignItems='baseline'
							>
								<Grid item xs={12} sm={6}>
									<Typography color='textPrimary' variant='body2' align='left'>
										Insurance
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography color='textPrimary' variant='body1' align='left'>
										{transaction.insuranceType}
										{transaction.insuranceSubType && (
											<> ({transaction.insuranceSubType})</>
										)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</div>
				{transaction.quoteSelectionRequest?.status === 'Responded' && (
					<div id='selected-quote-container' className={classes.container}>
						<Typography
							style={{ flexGrow: 1 }}
							color='textPrimary'
							variant='h4'
							align='left'
						>
							CLIENT’S SELECTED QUOTE DETAILS
						</Typography>
						<Divider style={{ margin: '16px 0px' }} />
						<div style={{ width: '90%' }}>
							<Grid container spacing={1}>
								<Grid
									item
									xs={12}
									sm={6}
									container
									spacing={1}
									alignItems='baseline'
								>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Insurer
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
										>
											{selectedQuoteInfo?.principalName}
										</Typography>
									</Grid>
								</Grid>
								<Box width='100%' />
								<Grid
									item
									xs={12}
									sm={6}
									container
									spacing={1}
									alignItems='baseline'
								>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Quotation Name
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
										>
											{selectedQuoteInfo?.selectedQuote.quoteName}
										</Typography>
									</Grid>
								</Grid>
								<Box width='100%' />
								<Grid
									item
									xs={12}
									sm={6}
									container
									spacing={1}
									alignItems='baseline'
								>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Period of Insurance
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
										>
											{`${moment(
												selectedQuoteInfo?.selectedQuote.startDate
											).format('DD/MM/YYYY')} - ${moment(
												selectedQuoteInfo?.selectedQuote.endDate
											).format('DD/MM/YYYY')}`}
										</Typography>
									</Grid>
								</Grid>
								<Box width='100%' />
								<Grid
									item
									xs={12}
									sm={6}
									container
									spacing={1}
									alignItems='baseline'
								>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Total Premium
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
										>
											{currencyFormatter.format(
												selectedQuoteInfo?.selectedQuote.premiumAmt
											)}
										</Typography>
									</Grid>
								</Grid>
								<Box width='100%' />
							</Grid>
						</div>
					</div>
				)}
				{!isEmpty(transaction.refTransaction) && (
					<div id='existing-policy-container' className={classes.container}>
						<Typography
							style={{ flexGrow: 1 }}
							color='textPrimary'
							variant='h4'
							align='left'
						>
							EXISTING POLICY DETAILS
						</Typography>
						<Divider style={{ margin: '16px 0px' }} />
						<div style={{ width: '90%' }}>
							<Grid container spacing={1}>
								<Grid
									item
									xs={12}
									sm={6}
									container
									spacing={1}
									alignItems='baseline'
								>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Transaction Number
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Link
											style={{
												textDecoration: 'none',
												display: 'inline-block',
											}}
											component={RouterLink}
											color='primary'
											to={
												'/transactions/' +
												transaction.refTransaction.transactionId._id
											}
										>
											TXN-{transaction.refTransaction.transactionNo}
										</Link>
									</Grid>
								</Grid>
								<Box width='100%' />
								<Grid
									item
									xs={12}
									sm={6}
									container
									spacing={1}
									alignItems='baseline'
								>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Insurer
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
										>
											{
												transaction.refTransaction.transactionId.policyData
													.principalName
											}
										</Typography>
									</Grid>
								</Grid>
								<Box width='100%' />
								<Grid
									item
									xs={12}
									sm={6}
									container
									spacing={1}
									alignItems='baseline'
								>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Policy Number
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
										>
											{
												transaction.refTransaction.transactionId.policyData
													.policyNo
											}
										</Typography>
									</Grid>
								</Grid>
								<Box width='100%' />
								<Grid
									item
									xs={12}
									sm={6}
									container
									spacing={1}
									alignItems='baseline'
								>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Period of Insurance
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
										>
											{`${moment(
												transaction.refTransaction.transactionId.policyData
													.startDate
											).format('DD/MM/YYYY')} - ${moment(
												transaction.refTransaction.transactionId.policyData
													.endDate
											).format('DD/MM/YYYY')}`}
										</Typography>
									</Grid>
								</Grid>
								<Box width='100%' />
								<Grid
									item
									xs={12}
									sm={6}
									container
									spacing={1}
									alignItems='baseline'
								>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Total Premium
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
										>
											{currencyFormatter.format(
												transaction.refTransaction.transactionId.policyData
													.premiumAmt
											)}
										</Typography>
									</Grid>
								</Grid>
								<Box width='100%' />
							</Grid>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
