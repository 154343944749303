import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import React, { useState, useRef } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Delete } from '../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		marginTop: 32,
		backgroundColor: 'unset',
	},
	cardContent: {
		display: 'flex',
		alignItems: 'center',
		padding: 16,
		'&:last-child': {
			padding: 16,
		},
	},
}));

const ImportFileUploader = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const fileInputRef = useRef();

	const [errorMessage, setErrorMessage] = useState('');

	const preventDefault = (e) => {
		e.preventDefault();
	};

	const dragOver = (e) => {
		preventDefault(e);
	};

	const dragEnter = (e) => {
		preventDefault(e);
	};

	const dragLeave = (e) => {
		preventDefault(e);
	};

	const fileDrop = (e) => {
		preventDefault(e);
		const files = e.dataTransfer.files;
		if (files.length) {
			handleFiles(files);
		}
	};

	const filesSelected = (e) => {
		preventDefault(e);
		if (fileInputRef.current.files.length) {
			handleFiles(fileInputRef.current.files);
		}
	};

	const fileInputClicked = () => {
		fileInputRef.current.click();
	};

	const handleFiles = (files) => {
		if (files.length > 1) {
			setErrorMessage('Only 1 file is permitted');
		} else {
			let result = validateFile(files[0]);
			if (result.valid) {
				setErrorMessage('');
				props.setSelectedFiles([files[0]]);
			} else {
				setErrorMessage(result.errorMessage);
			}
		}
	};

	const validateFile = (file) => {
		const validTypes = [
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.ms-excel',
			'text/csv',
		];
		let valid = true;
		let errorMessage = '';

		if (validTypes.indexOf(file.type) === -1) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (!file.name.match(/.(xls|xlsx|xlsm|csv)$/i)) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (file.size > 52428800) {
			valid = false;
			errorMessage = 'File size cannot exceed 50mb';
		}

		return {
			valid: valid,
			errorMessage: errorMessage,
		};
	};

	const fileSize = (size) => {
		if (size === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const i = Math.floor(Math.log(size) / Math.log(k));
		return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
	};

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	const removeFile = () => {
		setErrorMessage('');
		if (fileInputRef.current !== null) {
			fileInputRef.current.value = null;
		}
		props.setSelectedFiles([]);
	};

	return (
		<>
			<div className='wrapper' style={{ marginTop: 16 }}>
				<div
					className='drop-container'
					style={{
						height: 260,
						display: props.selectedFiles.length > 0 && 'none',
					}}
					onDragOver={dragOver}
					onDragEnter={dragEnter}
					onDragLeave={dragLeave}
					onDrop={fileDrop}
					onClick={fileInputClicked}
				>
					<CloudUploadOutlinedIcon
						style={{
							width: 60,
							height: 60,
							color: theme.palette.text.secondary,
						}}
					/>
					<div className='drop-message'>
						Drag and drop file, or click to browse computer
					</div>
					<input
						ref={fileInputRef}
						accept='.xls, .xlsx, .xlsm, .csv'
						className='file-input'
						type='file'
						multiple
						onChange={filesSelected}
					/>
				</div>
				{errorMessage && (
					<span className='file-error-message'>{errorMessage}</span>
				)}
				{props.formErrorMessage && (
					<span className='file-error-message'>{props.formErrorMessage}</span>
				)}

				{props.selectedFiles.map((data, i) => (
					<Card key={i} className={classes.cardContainer}>
						<CardContent classes={{ root: classes.cardContent }}>
							<div style={{ width: 24 }}>
								<FileIcon
									labelUppercase
									extension={fileType(data.name)}
									{...defaultStyles[fileType(data.name)]}
								/>
							</div>
							<Typography
								style={{
									marginLeft: 24,
								}}
								variant='body1'
								color='textPrimary'
								component='span'
							>
								{data.name}
							</Typography>
							<Typography
								style={{
									marginLeft: 24,
								}}
								variant='body1'
								color='textSecondary'
								component='span'
							>
								{fileSize(data.size)}
							</Typography>
							<IconButton
								style={{ marginLeft: 'auto' }}
								aria-label='expand row'
								size='small'
								onClick={() => removeFile()}
							>
								<Delete style={{ fontSize: 24 }} />
							</IconButton>
						</CardContent>
					</Card>
				))}
			</div>
		</>
	);
};

export default ImportFileUploader;
