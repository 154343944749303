import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImageSearchOutlinedIcon from '@material-ui/icons/ImageSearchOutlined';
import PaletteIcon from '@material-ui/icons/Palette';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { cloneDeep, debounce, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { toast } from 'react-toastify';
import InvoiceSample from '../../../components/InvoiceSample';
import api from '../../../config/api';
import { InvoiceSettings } from '../../../utils/validators/schemas';
import { Delete, Save } from '../../../components/Icons';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box
					p={3}
					style={{ padding: '5px 2%', height: '55vh', background: 'grey' }}
				>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	large: {
		width: 180,
		height: 180,
	},
	skeletonImage: {
		width: 180,
		height: 180,
	},
	input: {
		display: 'none',
	},
	popover: {
		position: 'absolute',
		zIndex: '2',
		top: 55,
		right: 15,
	},
	cover: {
		position: 'fixed',
		top: '0px',
		right: '0px',
		bottom: '0px',
		left: '0px',
	},
}));
const str2bool = (value) => {
	if (value && typeof value === 'string') {
		if (value.toLowerCase() === 'true') return true;
		if (value.toLowerCase() === 'false') return false;
	}
	return value;
};

const options = ['Invoice'];

const initSettings = {
	color: '#5E80E6',
	paymentDue: 14,
	paymentReminder: 7,
	beneficiaryName: '',
	bank: '',
	branchCode: '',
	accountNo: '',
	paynowEnabled: false,
	paynowId: '',
	paynowQrCode: null,
	terms: '',
};

export default function InvoiceCustomization(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [settings, setSettings] = useState(cloneDeep(initSettings));
	const [previewSettings, setPreviewSettings] = useState({});
	const [profile, setProfile] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [open0, setOpen0] = useState(true);
	const [open1, setOpen1] = useState(false);
	const [paletteOpen, setPaletteOpen] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getInvoiceSettings();
	}, []);

	const getInvoiceSettings = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('settings/type/invoice')
			.then((response) => {
				if (response.data.ok) {
					let updatedSettings = merge(settings, response.data.data);
					setSettings(updatedSettings);
					setProfile({
						userProfile: response.data.data.userProfile,
						orgProfile: response.data.data.orgProfile,
					});
				}

				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const debouncePreviewSettings = useCallback(
		debounce((settings) => setPreviewSettings(settings), 1000),
		[]
	);

	useEffect(() => {
		debouncePreviewSettings(settings);
	}, [settings]);

	const handleExpand = (panel) => {
		switch (panel) {
			case 0:
				return setOpen0(!open0);
			case 1:
				return setOpen1(!open1);
			default:
				return;
		}
	};

	const handleReset = () => {
		console.log('handleReset');

		setSettings(cloneDeep(initSettings));
	};

	const handleChange = (e) => {
		const {
			target: { value, name, type },
		} = e;

		setSettings((prev) => ({
			...prev,
			[name]: type === 'number' ? parseInt(value ? value : 0) : value,
		}));
	};

	const validateFile = (file) => {
		const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];
		let valid = true;
		let errorMessage = '';

		if (validTypes.indexOf(file.type) === -1) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (!file.name.match(/.(jpg|jpeg|png|bmp)$/i)) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (file.size > 2097152) {
			valid = false;
			errorMessage = 'File size cannot exceed 2mb';
		}

		return {
			valid: valid,
			errorMessage: errorMessage,
		};
	};

	const handleChangeImage = async (e) => {
		try {
			const { name, files } = e.target;

			if (files.length > 0) {
				let result = validateFile(files[0]);
				if (result.valid) {
					setSettings((prev) => ({
						...prev,
						newPaynowQrCode: files[0],
						previewImage: URL.createObjectURL(files[0]),
						qrImage: null,
					}));
				} else {
					toast.error(result.errorMessage);
				}
			} else {
				return;
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleRemoveImage = async (e) => {
		try {
			setSettings((prev) => ({
				...prev,
				paynowQrCode: null,
				newPaynowQrCode: null,
				previewImage: null,
				qrImage: null,
			}));
		} catch (error) {
			console.log('error', error);
			setLoading(false);
		}
	};

	const togglePalette = () => {
		if (!paletteOpen) {
			setPaletteOpen(true);
		}
		setPaletteOpen(!paletteOpen);
	};

	const handleChangeColor = (color) => {
		setSettings((prev) => ({ ...prev, color: color.hex }));
	};

	const handleCheckBox = (e) => {
		const {
			target: { checked, name },
		} = e;
		setSettings((prev) => ({
			...prev,
			[name]: checked,
		}));
	};

	const handleRadio = (e) => {
		const {
			target: { value, name },
		} = e;
		let val = str2bool(value);
		setSettings((prev) => ({
			...prev,
			[name]: val,
		}));
	};

	const handleValidation = () => {
		let result = InvoiceSettings.validate(settings, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			console.log(error.details);
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = () => {
		if (!loading) {
			setLoading(true);

			let valid = handleValidation();

			if (valid) {
				const data = new FormData();

				if (settings.newPaynowQrCode) {
					data.append(
						'paynowQrCode',
						settings.newPaynowQrCode,
						settings.newPaynowQrCode.name
					);
				}

				let json = cloneDeep(settings);

				if (settings.paynowQrCode !== null) {
					delete json.paynowQrCode;
				}
				delete json.orgLogo;
				delete json.qrImage;
				delete json.newPaynowQrCode;
				delete json.previewImage;
				delete json.userProfile;
				delete json.orgProfile;

				console.log(JSON.stringify(json, null, 4));
				data.append('json', JSON.stringify(json));

				try {
					api
						.put('settings/organization/type/invoice', data)
						.then((response) => {
							if (response.data.ok) {
								toast.success('Invoice Settings updated!');
							}

							setLoading(false);
						})
						.catch(function (error) {
							toast.error('Error updating settings!');
							console.log(error);
							setLoading(false);
						});
				} catch (error) {
					console.log(error);
					toast.error(error);
					setLoading(false);
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	if (loading) return <CircularProgress />;
	return (
		<>
			<Typography variant='body1' style={{ marginBottom: 24 }}>
				Customize your invoice to your preferred style and settings.
			</Typography>
			<div>
				<Typography color='textPrimary' variant='h3' align='left'>
					Custom Style Settings
				</Typography>
				<Divider
					style={{
						backgroundColor: theme.palette.secondary.main,
						height: 2,
						marginTop: 16,
						marginBottom: 24,
					}}
				/>
				<Box display='flex'>
					<Box style={{ width: '35%' }}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Accent Color
						</Typography>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<TextField
								name='color'
								variant='filled'
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={settings.color}
								InputProps={{
									style: { color: settings.color && settings.color },
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												onClick={() => togglePalette()}
												aria-label='color palette'
											>
												<PaletteIcon />
											</IconButton>
											{paletteOpen && (
												<div className={classes.popover}>
													<div
														className={classes.cover}
														onClick={() => togglePalette()}
													/>
													<TwitterPicker
														triangle='top-right'
														color={settings.color}
														onChange={handleChangeColor}
													/>
												</div>
											)}
										</InputAdornment>
									),
								}}
								error={!!errorMessages[`color`]}
								helperText={
									errorMessages[`color`]
										? errorMessages[`color`]
										: 'Choose an accent colour to use in the invoice.'
								}
							/>
						</div>
					</Box>
					{profile && (
						<Box
							style={{ width: '65%', display: 'flex', flexDirection: 'column' }}
						>
							<Typography color='textPrimary' variant='body2' align='left'>
								Preview
							</Typography>
							<div style={{ height: '55vh', marginTop: 8 }}>
								<InvoiceSample profile={profile} settings={previewSettings} />
							</div>
						</Box>
					)}
				</Box>
				<Typography color='textPrimary' variant='h3' align='left'>
					Invoice Settings
				</Typography>
				<Divider
					style={{
						backgroundColor: theme.palette.secondary.main,
						height: 2,
						marginTop: 16,
						marginBottom: 24,
					}}
				/>
				<Box style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Payment Due (Optional)
					</Typography>
					<div
						style={{
							display: 'flex',
							alignItems: 'baseline',
							marginBottom: 16,
						}}
					>
						<TextField
							name='paymentDue'
							variant='filled'
							type='number'
							placeholder='Enter number'
							margin='dense'
							onChange={(e) => handleChange(e)}
							style={{ marginRight: '2%' }}
							value={settings.paymentDue ?? ''}
							inputProps={{ inputMode: 'numeric', min: 0, pattern: '[0-9]*' }}
							error={!!errorMessages[`paymentDue`]}
							helperText={
								errorMessages[`paymentDue`] && errorMessages[`paymentDue`]
							}
						/>
						Days
					</div>
					<Typography color='textPrimary' variant='body2' align='left'>
						Payment Reminder (Optional)
					</Typography>
					<div
						style={{
							display: 'flex',
							alignItems: 'baseline',
							marginBottom: 16,
						}}
					>
						<TextField
							name='paymentReminder'
							variant='filled'
							type='number'
							placeholder='Enter number'
							margin='dense'
							onChange={(e) => handleChange(e)}
							style={{ marginRight: '2%' }}
							value={settings.paymentReminder ?? ''}
							inputProps={{ inputMode: 'numeric', min: 0, pattern: '[0-9]*' }}
							error={!!errorMessages[`paymentReminder`]}
							helperText={
								errorMessages[`paymentReminder`] &&
								errorMessages[`paymentReminder`]
							}
						/>
						Days
					</div>
				</Box>
				<Typography
					style={{ marginBottom: 24 }}
					color='textPrimary'
					variant='body2'
					align='left'
				>
					Payment Methods
				</Typography>
				<Box style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
					<Card className={classes.root}>
						<CardHeader
							style={{ padding: 24 }}
							avatar={
								<Avatar aria-label='bank-transfer' className={classes.avatar}>
									B
								</Avatar>
							}
							action={
								<IconButton
									className={clsx(classes.expand, {
										[classes.expandOpen]: open0,
									})}
									onClick={() => handleExpand(0)}
									aria-expanded={open0}
									aria-label='show more'
								>
									<ExpandMoreIcon />
								</IconButton>
							}
							title='Bank Transfer / Internet Banking'
						/>
						<Collapse in={open0} timeout='auto' unmountOnExit>
							<CardContent style={{ padding: 24 }}>
								{/* <Grid container direction='row'> */}
								<div style={{ display: 'flex' }}>
									<Grid
										container
										spacing={2}
										direction='column'
										style={{ marginRight: 32 }}
									>
										<Grid item>
											<Typography
												color='textPrimary'
												variant='body2'
												align='left'
											>
												Bank
											</Typography>
											<TextField
												name='bank'
												variant='filled'
												placeholder='Enter Bank'
												margin='dense'
												onChange={(e) => handleChange(e)}
												value={settings.bank}
												error={!!errorMessages[`bank`]}
												helperText={
													errorMessages[`bank`] && errorMessages[`bank`]
												}
											/>
										</Grid>
										<Grid item>
											<Typography
												color='textPrimary'
												variant='body2'
												align='left'
											>
												Beneficiary Name
											</Typography>
											<TextField
												name='beneficiaryName'
												variant='filled'
												placeholder='Enter Beneficiary Name'
												margin='dense'
												onChange={(e) => handleChange(e)}
												value={settings.beneficiaryName}
												error={!!errorMessages[`beneficiaryName`]}
												helperText={
													errorMessages[`beneficiaryName`] &&
													errorMessages[`beneficiaryName`]
												}
											/>
										</Grid>
										<Grid item>
											<Typography
												color='textPrimary'
												variant='body2'
												align='left'
											>
												Account Number
											</Typography>
											<TextField
												name='accountNo'
												variant='filled'
												placeholder='Enter Account Number'
												margin='dense'
												onChange={(e) => handleChange(e)}
												value={settings.accountNo}
												error={!!errorMessages[`accountNo`]}
												helperText={
													errorMessages[`accountNo`] &&
													errorMessages[`accountNo`]
												}
											/>
										</Grid>
									</Grid>
									<Grid container spacing={2} direction='column'>
										<Grid item>
											<Typography
												color='textPrimary'
												variant='body2'
												align='left'
											>
												PayNow Enabled?
											</Typography>
											<FormControl component='fieldset'>
												<RadioGroup
													style={{ flexDirection: 'row' }}
													aria-label='paynowEnabled'
													name='paynowEnabled'
													value={settings.paynowEnabled}
													onChange={(e) => handleRadio(e)}
												>
													<FormControlLabel
														value={true}
														control={<Radio color='primary' />}
														label='Yes'
													/>
													<FormControlLabel
														value={false}
														control={<Radio color='primary' />}
														label='No'
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
										<Grid container spacing={2}>
											<Grid item>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													UEN
												</Typography>
												<TextField
													name='paynowId'
													variant='filled'
													placeholder='Enter UEN'
													margin='dense'
													onChange={(e) => handleChange(e)}
													value={settings.paynowId}
													error={!!errorMessages[`paynowId`]}
													helperText={
														errorMessages[`paynowId`] &&
														errorMessages[`paynowId`]
													}
												/>
											</Grid>
										</Grid>
										<Grid item>
											<Typography
												color='textPrimary'
												variant='body2'
												align='left'
											>
												PayNow QR Code
											</Typography>
											<div
												className={classes.avatarContainer}
												style={{ display: 'flex', alignItems: 'flex-end' }}
											>
												<input
													accept='image/jpeg, image/jpg, image/png, image/bmp'
													className={classes.input}
													id='icon-button-file'
													type='file'
													name='paynowQrCode'
													onChange={handleChangeImage}
												/>
												<label htmlFor='icon-button-file'>
													<IconButton
														component='span'
														style={{ borderRadius: 'unset' }}
													>
														{loading ? (
															<Skeleton
																className={classes.skeletonImage}
																variant='square'
																animation='wave'
															/>
														) : (
															<>
																{settings.qrImage || settings.previewImage ? (
																	<Avatar
																		alt='QRCode'
																		variant='square'
																		src={
																			settings.qrImage !== null
																				? `data:image/*;base64,${settings.qrImage}`
																				: settings.previewImage
																		}
																		className={classes.large}
																	/>
																) : (
																	<Avatar
																		variant='square'
																		className={classes.large}
																	>
																		<ImageSearchOutlinedIcon />
																	</Avatar>
																)}
															</>
														)}
													</IconButton>
												</label>
												{!loading &&
													(settings.qrImage || settings.previewImage) && (
														<IconButton
															className={classes.deleteIcon}
															disableFocusRipple
															disableRipple
															size='small'
															onClick={
																process.env.REACT_APP_ENVIRONMENT !== 'demo'
																	? (e) => handleRemoveImage(e)
																	: undefined
															}
														>
															<Delete
																style={{ fontSize: 24 }}
																colorcode={theme.palette.text.secondary}
															/>
														</IconButton>
													)}
											</div>
										</Grid>
									</Grid>
								</div>
							</CardContent>
						</Collapse>
					</Card>

					<Typography color='textPrimary' variant='body2' align='left'>
						Terms & Conditions / Notes
					</Typography>
					<TextField
						name='terms'
						multiline
						onChange={(e) => handleChange(e)}
						variant='filled'
						placeholder='Enter Terms & Conditions / Notes'
						fullWidth
						margin='dense'
						minRows={10}
						style={{ flexGrow: 1 }}
						value={settings.terms}
						error={!!errorMessages[`terms`]}
						helperText={errorMessages[`terms`] && errorMessages[`terms`]}
					/>
				</Box>
			</div>
			<div className='formFooter' style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginLeft: 'auto', marginRight: 16 }}
					variant='outlined'
					color='primary'
					onClick={() => handleReset()}
				>
					Restore to Default
				</Button>
				<Button
					variant='contained'
					color='primary'
					startIcon={
						<Save
							style={{ fontSize: 24 }}
							colorcode={theme.palette.primary.contrastText}
						/>
					}
					onClick={() => handleSave()}
				>
					Save
				</Button>
			</div>
		</>
	);
}
