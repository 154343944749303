import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { toast } from 'react-toastify';
import DocumentModal from '../../../../../components/Modals/documents.js';
import api from '../../../../../config/api';
import {
	Delete,
	Preview,
	ReplaceDocument,
	UploadDocument,
} from '../../../../../components/Icons/index.js';

const useStyles = makeStyles((theme) => ({
	table: {
		width: '100%',
		display: 'inline-block',
		overflowY: 'auto',
		maxHeight: (props) => props.size === 'small' && 245,
		height: '100%',
	},
	docIcon: {
		minWidth: 24,
		maxWidth: 24,
		height: 24,
		marginTop: -4,
	},
	centeredRow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		height: '100%',
		padding: 16,
	},
	actionButton: {
		padding: 6,
	},
}));

function Row(props) {
	const { type, document, handleModalOpen } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [loading, setLoading] = useState(false);

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	const downloadDocument = (e, document) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);
		}
		api
			.get(`documents/${document.documentId}?action=download`)
			.then((response) => {
				if (response.data === 'file not found') {
					setLoading(false);
					return toast.error('Error retrieving Document!');
				}
				window.open(response.data, '_blank');
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				setLoading(false);
				toast.error('Error retrieving Document!');
			});
	};

	return (
		<>
			<TableRow
				style={{
					display: 'flex',
					width: '100%',
					height: 52,
					padding: '8px 0px',
				}}
			>
				<TableCell
					style={{
						borderBottom: 'unset',
						padding: 0,
						flexGrow: 1,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<div className={classes.docIcon}>
						<FileIcon
							labelUppercase
							extension={fileType(document.displayName)}
							{...defaultStyles[fileType(document.displayName)]}
						/>
					</div>
					<Typography variant='subtitle1' style={{ marginLeft: 12 }}>
						{document.documentName} ({document.displayName})
					</Typography>
				</TableCell>
				<TableCell
					style={{
						borderBottom: 'unset',
						marginLeft: 12,
						padding: 0,
					}}
				>
					<IconButton
						className={classes.actionButton}
						onClick={() => handleModalOpen('replaceDocument', type, document)}
						disabled={loading}
					>
						<ReplaceDocument colorcode={theme.palette.tertiary.main} />
					</IconButton>
				</TableCell>
				<TableCell
					style={{
						borderBottom: 'unset',
						marginLeft: 12,
						padding: 0,
					}}
				>
					<IconButton
						className={classes.actionButton}
						onClick={(e) => downloadDocument(e, document)}
						disabled={loading}
					>
						<Preview colorcode={theme.palette.tertiary.main} />
					</IconButton>
				</TableCell>
				<TableCell
					style={{
						borderBottom: 'unset',
						marginLeft: 12,
						padding: 0,
					}}
				>
					<IconButton
						className={classes.actionButton}
						onClick={() => handleModalOpen('deleteDocument', type, document)}
						disabled={loading}
					>
						<Delete colorcode={theme.palette.tertiary.main} />
					</IconButton>
				</TableCell>
			</TableRow>
		</>
	);
}

export default function DocumentTracker(props) {
	const {
		transactionId,
		transactionType,
		supportingDocuments,
		policyDocuments,
		claimDocuments,
		setTriggerUpdate,
	} = props;
	const classes = useStyles(props);
	const theme = useTheme();

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);
	const [modalData, setModalData] = useState(null);
	const [value, setValue] = useState(0);

	const handleModalOpen = (body, val, obj) => {
		setValue(val);
		setModalType(body);
		setModalData(obj);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setValue(0);
		setModalOpen(false);
		setModalType(null);
		setModalData(null);
	};

	return (
		<div style={{ padding: '16px 24px' }}>
			<div id='supporting-documents' className={classes.typeContainer}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography
						style={{ flexGrow: 1 }}
						color='textPrimary'
						variant='h4'
						align='left'
					>
						SUPPORTING DOCUMENTS
					</Typography>
					<Button
						variant='text'
						color='primary'
						startIcon={
							<UploadDocument
								style={{ fontSize: 24 }}
								colorcode={theme.palette.tertiary.main}
							/>
						}
						onClick={() => handleModalOpen('uploadDocument', 0, null)}
					>
						Upload Document
					</Button>
				</div>
				<Divider style={{ margin: '8px 0px' }} />
				<table className={classes.table} cellSpacing='0'>
					<tbody
						style={{
							display: 'inherit',
							width: '100%',
						}}
					>
						{supportingDocuments && supportingDocuments.length > 0 ? (
							<>
								{supportingDocuments.map((document, index) => (
									<Row
										key={document.documentId}
										type={0}
										document={document}
										handleModalOpen={handleModalOpen}
									/>
								))}
							</>
						) : (
							<tr className={classes.centeredRow}>
								<td>
									<Typography variant='body1' color='textPrimary'>
										No Supporting Documents uploaded
									</Typography>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			{transactionType === 'Claims' ? (
				<div id='claim-documents' className={classes.typeContainer}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							style={{ flexGrow: 1 }}
							color='textPrimary'
							variant='h4'
							align='left'
						>
							CLAIM DOCUMENTS
						</Typography>
						<Button
							variant='text'
							color='primary'
							startIcon={
								<UploadDocument
									style={{ fontSize: 24 }}
									colorcode={theme.palette.tertiary.main}
								/>
							}
							onClick={() => handleModalOpen('uploadDocument', 2, null)}
						>
							Upload Document
						</Button>
					</div>
					<Divider style={{ margin: '8px 0px' }} />
					<table className={classes.table} cellSpacing='0'>
						<tbody
							style={{
								display: 'inherit',
								width: '100%',
							}}
						>
							{claimDocuments && claimDocuments.length > 0 ? (
								<>
									{claimDocuments.map((document, index) => (
										<Row
											key={document.documentId}
											type={2}
											document={document}
											handleModalOpen={handleModalOpen}
										/>
									))}
								</>
							) : (
								<tr className={classes.centeredRow}>
									<td>
										<Typography variant='body1' color='textPrimary'>
											No Claim Documents uploaded
										</Typography>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			) : (
				<div id='policy-documents' className={classes.typeContainer}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							style={{ flexGrow: 1 }}
							color='textPrimary'
							variant='h4'
							align='left'
						>
							POLICY DOCUMENTS
						</Typography>
						<Button
							variant='text'
							color='primary'
							startIcon={
								<UploadDocument
									style={{ fontSize: 24 }}
									colorcode={theme.palette.tertiary.main}
								/>
							}
							onClick={() => handleModalOpen('uploadDocument', 1, null)}
						>
							Upload Document
						</Button>
					</div>
					<Divider style={{ margin: '8px 0px' }} />
					<table className={classes.table} cellSpacing='0'>
						<tbody
							style={{
								display: 'inherit',
								width: '100%',
							}}
						>
							{policyDocuments && policyDocuments.length > 0 ? (
								<>
									{policyDocuments.map((document, index) => (
										<Row
											key={document.documentId}
											type={1}
											document={document}
											handleModalOpen={handleModalOpen}
										/>
									))}
								</>
							) : (
								<tr className={classes.centeredRow}>
									<td>
										<Typography variant='body1' color='textPrimary'>
											No Policy Documents uploaded
										</Typography>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			)}

			{modalOpen && (
				<DocumentModal
					open={modalOpen}
					handleClose={handleModalClose}
					body={modalType}
					value={value}
					transactionId={transactionId}
					data={modalData}
					setTriggerUpdate={setTriggerUpdate}
				/>
			)}
		</div>
	);
}
