import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 32,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
}));

const confirmDeleteTag = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Remove Tag
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<div style={{ marginTop: 24, marginBottom: 24 }}>
			<Typography variant='body1' color='textPrimary'>
				Are you sure you want to delete <b>{props.selectedTag}</b> and remove it
				from ALL associated contacts?
			</Typography>
			<Typography
				variant='body1'
				color='textPrimary'
				style={{ marginTop: 24, color: props.theme.palette.error.main }}
			>
				Note: This action cannot be undone.
			</Typography>
		</div>
		<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
			<Button
				style={{ marginRight: 16 }}
				onClick={props.handleClose}
				variant='outlined'
				color='primary'
			>
				Cancel
			</Button>
			<Button
				style={{
					backgroundColor: props.theme.palette.error.main,
					color: props.theme.palette.background.default,
				}}
				onClick={props.handleConfirm}
				variant='contained'
				color='primary'
			>
				Remove
			</Button>
		</div>
	</>
);

const getBody = (props) => {
	switch (props.body) {
		case 'delete-tag':
			return confirmDeleteTag(props);

		default:
			return 'Unknown modal';
	}
};

const DialogBox = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<div className={classes.paper}>
					{getBody({ ...props, theme: theme })}
				</div>
			</Dialog>
		</div>
	);
};

export default DialogBox;
