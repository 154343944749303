import Joi from 'joi';

const ProposalForm = Joi.object({
	url: Joi.string()
		.label('URL')
		.trim()
		.pattern(
			new RegExp(
				/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/i
			)
		)
		.required()
		.messages({
			'string.pattern.base': 'Invalid URL',
		}),
	instructions: Joi.string()
		.label('Instructions')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default ProposalForm;
