import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		backgroundColor: theme.palette.primary.main,
		zIndex: theme.zIndex.drawer + 1,
		boxShadow: 'none',
	},
	alertBar: {
		borderRadius: 0,
		position: 'absolute',
		alignItems: 'center',
		top: 80,
		width: '100%',
		padding: '8px 24px',
		zIndex: theme.zIndex.drawer + 1,
		background: '#E8EBF7',
		boxShadow: '0px 4px 12px rgb(0 0 0 / 16%), 0px 0px 4px rgb(0 0 0 / 16%)',
		color: '#4A93DC',
		fontWeight: 400,
		'& .MuiAlert-message': {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
		},
	},
	toolBar: {
		paddingLeft: 32,
		paddingRight: 32,
		minHeight: 80,
	},
	toolBarMobile: {
		paddingLeft: 24,
		paddingRight: 24,
		minHeight: 80,
	},
	resourceDrawer: {
		// width: 280,
		// flexShrink: 0,
		display: 'contents',
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: 280,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		backgroundColor: '#F1F3FA',
		boxShadow: '0px 4px 4px rgb(0 0 0 / 25%), 0px 2px 8px rgb(0 0 0 / 16%)',
	},
	drawerPaper: {
		width: 280,
	},
	drawerMobile: {
		// width: 280,
		// flexShrink: 0,
		display: 'contents',
	},
	// drawerPaperMobile: {
	// 	width: '100%',
	// },
	drawerContainer: {
		overflow: 'auto',
	},
	drawerHeader: {
		// display: 'flex',
		// alignItems: 'center',
		// padding: theme.spacing(0, 1),
		// ...theme.mixins.toolbar,
		// justifyContent: 'flex-end',
	},
	drawerOpen: {
		width: 280,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
		backgroundColor: '#F1F3FA',
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: 88,
		backgroundColor: '#F1F3FA',
		// width: theme.spacing(7) + 1,
		// [theme.breakpoints.up('sm')]: {
		// 	width: theme.spacing(9) + 1,
		// },
	},
	menuButton: {
		marginRight: 20,
	},
	menuButtonMobile: {
		marginRight: 12,
	},
	nested: {
		paddingLeft: 32,
		paddingTop: 16,
		paddingBottom: 16,
	},
	content: {
		position: 'relative',
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// marginLeft: -280,
		marginTop: 80,
		overflowY: 'auto',
		height: 'calc(100vh - 80px)',
		// height: '93vh',
	},
	contentShift: {
		position: 'relative',
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
		marginTop: 80,
		overflowY: 'auto',
		height: 'calc(100vh - 80px)',
		// height: '93vh',
	},
	landingContent: {
		flexGrow: 1,
		height: '100vh',
	},
	paddingOverride: {
		paddingLeft: 32,
		paddingRight: 32,
		paddingTop: 16,
		paddingBottom: 16,
	},
	paddingOverrideSub: {
		paddingLeft: 32,
		paddingTop: 16,
		paddingBottom: 16,
	},
	grow: {
		marginLeft: 16,
		// marginRight: 12,
	},
	growMobile: {
		marginLeft: 4,
	},
	headerIcon: {
		fontSize: 32,
		color: '#ffffff',
	},
	headerIconMobile: {
		fontSize: 28,
		color: '#ffffff',
	},
	toastContainerAuth: {
		top: '96px !important',
		right: '24px !important',
	},
	// toastContainer: {

	// }
	// listItemSelected: {
	// 	backgroundColor: theme.palette.secondary.main,
	// },
}));

// export default makeStyles(theme => ({
//   root: {
//     display: 'flex',
//   },
//   grow:{
//     padding: '10px',
//   },
//   appBar: {
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     backgroundColor: '#5E80E6'
//   },
//   appBarShift: {
//     width: `calc(100% - 240px)`,
//     marginLeft: 240,
//     transition: theme.transitions.create(['margin', 'width'], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   hide: {
//     display: 'none',
//   },
//   drawer: {
//     width: 240,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: 240,
//   },
//   drawerHeader: {
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0, 1),
//     ...theme.mixins.toolbar,
//     justifyContent: 'flex-end',
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: -240,
//   },
//   landingContent: {
//     flexGrow: 1,
//   },
//   contentShift: {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   },
// }));
