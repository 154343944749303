import Button from '@material-ui/core/Button';
import Backdrop from '../../components/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepIcon from '@material-ui/core/StepIcon';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../config/api';
import { Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { AuthUserContext } from '../../components/Session';
import Step1 from './step1.js';
import Step2 from './step2.js';
import Step3 from './step3.js';
import Joi from 'joi';

const useCustomStepIconStyles = makeStyles((theme) => ({
	root: {
		zIndex: 1,
		color: theme.palette.background.default,
		width: 40,
		height: 40,
		display: 'flex',
		border: `2px solid ${theme.palette.grey[500]}`,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '100%',
		textAlign: 'center',
		flexDirection: 'column',
	},
	active: {
		border: `2px solid ${theme.palette.background.secondary}`,
	},
	activeMobile: {
		border: `2px solid ${theme.palette.primary.main}`,
	},
	completed: {
		background: theme.palette.success.main,
		border: 'unset',
		fill: theme.palette.primary.main,
	},
	completedMobile: {
		border: `2px solid ${theme.palette.success.main}`,
		fill: theme.palette.success.main,
	},
	completedIcon: {
		background: theme.palette.success.main,
		border: 'unset',
		fill: theme.palette.primary.main,
	},
	completedIconMobile: {
		fill: theme.palette.success.main,
	},
	icon: {
		color: 'transparent',
		fontSize: 48,
	},
	iconMobile: {
		color: 'transparent',
		fontSize: 48,
	},
	iconText: {
		color: 'transparent',
		fill: theme.palette.grey[500],
	},
	iconTextActive: {
		color: 'transparent',
		fill: theme.palette.background.secondary,
	},
	iconTextActiveMobile: {
		fill: theme.palette.primary.main,
	},
	iconContainerMobile: {
		padding: 0,
	},
	stepLabel: {
		lineHeight: 1.2,
		fontWeight: 500,
		color: theme.palette.grey[500],
		'&&[class*="MuiStepLabel-active"]': {
			color: theme.palette.background.secondary,
			fontWeight: 600,
		},
		'&&[class*="MuiStepLabel-completed"]': {
			color: theme.palette.success.main,
			fontWeight: 500,
		},
	},
	arrowRoot: {
		marginTop: 8,
		color: theme.palette.grey[500],
	},
	arrowActive: {
		marginTop: 8,
		color: theme.palette.primary.main,
	},
	arrowCompleted: {
		marginTop: 8,
		color: theme.palette.success.main,
	},
}));

const CustomStepConnector = withStyles({
	vertical: {
		padding: 0,
	},
	line: {
		height: 0,
		border: 0,
		borderRadius: 1,
	},
})(StepConnector);

function CustomStepConnectorMobile(props) {
	const classes = useCustomStepIconStyles();
	const { active, completed } = props;

	return (
		<div style={{ margin: '0px 8px' }}>
			<ArrowForwardIcon
				className={clsx(classes.arrowRoot, {
					[classes.arrowActive]: active,
					[classes.arrowCompleted]: completed,
				})}
			/>
		</div>
	);
}

function CustomStepIcon(props) {
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const classes = useCustomStepIconStyles();
	const { active, completed } = props;

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active && isDesktopOrLaptop,
				[classes.activeMobile]: active && !isDesktopOrLaptop,
				[classes.completed]: completed && isDesktopOrLaptop,
				[classes.completedMobile]: completed && !isDesktopOrLaptop,
			})}
			style={{ marginRight: isDesktopOrLaptop && 16 }}
		>
			{completed ? (
				<Check
					className={
						isDesktopOrLaptop
							? classes.completedIcon
							: classes.completedIconMobile
					}
				/>
			) : (
				<StepIcon
					classes={{
						root: isDesktopOrLaptop ? classes.icon : classes.iconMobile,
						text: clsx(classes.iconText, {
							[classes.iconTextActive]: active && isDesktopOrLaptop,
							[classes.iconTextActiveMobile]: active && !isDesktopOrLaptop,
						}),
					}}
					icon={props.icon}
				/>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	input: {
		width: '100%',
		maxWidth: '460px',
		height: '50px',
		marginBottom: '2vh',
	},
	button: {
		width: '100%',
		maxWidth: '460px',
		height: '40px',
		marginTop: '5vh',
		marginBottom: '2vh',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
	},
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		height: 450,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	root: {
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
	stepper: {
		backgroundColor: 'unset',
		padding: 0,
	},
	steps: {
		'& > * + *': {
			marginTop: 32,
		},
	},
	stepLabel: {
		fontSize: '1.75rem',
		lineHeight: 1.2,
		fontWeight: 500,
		color: theme.palette.grey[500],
		'&&[class*="MuiStepLabel-active"]': {
			color: theme.palette.background.secondary,
			fontWeight: 600,
		},
		'&&[class*="MuiStepLabel-completed"]': {
			color: theme.palette.success.main,
			fontWeight: 500,
		},
	},
	iconContainerMobile: {
		padding: 0,
	},
}));

function getSteps() {
	return ['Your Corporate Identity', 'Personal', 'Company'];
}

const initProfile = {
	agentId: '',
	firstName: '',
	lastName: '',
	mobileNo: '',
	nationality: '',
};

const initOrgProfile = {
	name: '',
	uen: '',
	agencyNo: '',
	address: '',
	city: '',
	unitNo: '',
	postalCode: '',
};

export default function Onboarding(props) {
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

	const steps = getSteps();

	const [activeStep, setActiveStep] = useState(0);
	const [profile, setProfile] = useState({ ...initProfile });
	const [orgProfile, setOrgProfile] = useState({ ...initOrgProfile });
	const [principals, setPrincipals] = useState([]);
	const [noOrganization, setNoOrganization] = useState(false);
	const [professionType, setProfessionType] = useState('LI');
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [errorMessages, setErrorMessages] = useState({});

	const handleValidation = () => {
		let errorData = {};
		let dataToValidate = {};
		let schemaObject = {};
		let result = {};
		let isValid = true;

		if (activeStep === 0) {
			if (principals.length === 0) {
				errorData['selectedPrincipals'] =
					'Please select at least one Principal';
				isValid = false;
			} else {
				if (professionType === 'GI' && principals.length > 3) {
					errorData['selectedPrincipals'] =
						'Only IFAs / Brokers can have more than three Principals';
					isValid = false;
				} else {
					dataToValidate['principals'] = principals;
					schemaObject['principals'] = Joi.array().items(
						Joi.object({
							contact: {
								firstName: Joi.string()
									.label('First Name')
									.trim()
									.min(1)
									.max(50)
									.required(),
								lastName: Joi.string()
									.label('Last Name')
									.trim()
									.min(1)
									.max(50)
									.optional()
									.allow(''),
								email: Joi.string()
									.label('Email')
									.trim()
									.email({ tlds: { allow: false } })
									.required(),
								mobileNo: Joi.string()
									.label('Mobile Number')
									.trim()
									.min(1)
									.max(50)
									.pattern(/^[0-9+\s#)(-]*$/, '')
									.messages({
										'string.pattern.base': `"Mobile Number" should only contain Numbers (0-9) and Symbols (#+-)`,
									})
									.optional()
									.allow(''),
							},
						}).unknown(true)
					);
				}
			}
			dataToValidate['agentId'] = profile.agentId;
			schemaObject['agentId'] = Joi.string()
				.label('Agent ID')
				.trim()
				.min(1)
				.max(50)
				.required();

			let schema = Joi.object(schemaObject);
			result = schema.validate(dataToValidate, {
				abortEarly: false,
				convert: false,
			});
		}
		if (activeStep === 1) {
			dataToValidate = {
				firstName: profile.firstName,
				lastName: profile.lastName,
				mobileNo: profile.mobileNo,
				nationality: profile.nationality,
			};
			let schema = Joi.object({
				firstName: Joi.string()
					.label('First Name')
					.trim()
					.min(1)
					.max(50)
					.required(),
				lastName: Joi.string()
					.label('Last Name')
					.trim()
					.min(1)
					.max(50)
					.required(),
				mobileNo: Joi.string()
					.label('Mobile Number')
					.trim()
					.min(1)
					.max(50)
					.pattern(/^[0-9+\s#)(-]*$/, '')
					.messages({
						'string.pattern.base': `"Mobile Number" should only contain Numbers (0-9) and Symbols (#+-)`,
					})
					.required(),
				nationality: Joi.string()
					.label('Nationality')
					.trim()
					.min(1)
					.max(50)
					.required(),
			});
			result = schema.validate(dataToValidate, {
				abortEarly: false,
				convert: false,
			});
		}
		if (activeStep === 2) {
			if (!noOrganization) {
				dataToValidate = {
					name: orgProfile.name,
					uen: orgProfile.uen,
					agencyNo: orgProfile.agencyNo,
					address: orgProfile.address,
					city: orgProfile.city,
					unitNo: orgProfile.unitNo,
					postalCode: orgProfile.postalCode,
				};
				let schema = Joi.object({
					name: Joi.string()
						.label('Organization Name')
						.trim()
						.min(1)
						.max(50)
						.required(),
					uen: Joi.string().label('UEN').trim().min(1).max(50).required(),
					agencyNo: Joi.string()
						.label('Agency Number')
						.trim()
						.min(1)
						.max(50)
						.required(),
					address: Joi.string()
						.label('Address')
						.trim()
						.min(1)
						.max(100)
						.required(),
					city: Joi.string().label('City').trim().min(1).max(50).required(),
					unitNo: Joi.string()
						.label('Unit Number')
						.trim()
						.min(1)
						.max(50)
						.required(),
					postalCode: Joi.string()
						.label('Postal Code')
						.trim()
						.min(1)
						.max(50)
						.required(),
				});
				result = schema.validate(dataToValidate, {
					abortEarly: false,
					convert: false,
				});
			}
		}

		if (result.error === undefined && isValid) {
			setErrorMessages({});
			return true;
		} else {
			if (result.error) {
				console.log(result.error.details);
				for (let item of result.error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			console.log(errorData);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleNext = async () => {
		let valid = handleValidation();
		if (valid) {
			if (activeStep === steps.length - 1) {
				setOpen(true);
				let data = {
					profile: profile,
					orgProfile: !noOrganization && orgProfile,
					principals: principals,
					noOrganization: noOrganization,
					professionType: professionType,
				};

				try {
					api.post(`onboard`, data).then((res) => {
						if (res.data.ok) {
							window.location.reload();
							setSuccess(true);
							setOpen(false);
							setError(null);
							setErrorMessages({});
						} else {
							setSuccess(false);
							setError(res.data.error);
							toast.error(res.data.error);
							setOpen(false);
						}
					});
				} catch (error) {
					console.log(error);
					setSuccess(false);
					setError(error);
					toast.error(error);
					setOpen(false);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else {
			toast.error('An error has occured!');
			setOpen(false);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	function getStepContent(step) {
		switch (step) {
			case 0:
				return (
					<Step1
						profile={profile}
						setProfile={setProfile}
						professionType={professionType}
						setProfessionType={setProfessionType}
						principals={principals}
						setPrincipals={setPrincipals}
						errorMessages={errorMessages}
					/>
				);
			case 1:
				return (
					<Step2
						profile={profile}
						setProfile={setProfile}
						errorMessages={errorMessages}
					/>
				);
			case 2:
				return (
					<Step3
						orgProfile={orgProfile}
						setOrgProfile={setOrgProfile}
						noOrganization={noOrganization}
						setNoOrganization={setNoOrganization}
						errorMessages={errorMessages}
					/>
				);
			default:
				return 'Unknown step';
		}
	}

	if (success) return <Redirect to='/dashboard' />;
	if (open)
		return (
			<Backdrop
				text='We’re almost there... setting up your account!'
				open={open}
			/>
		);
	return (
		<>
			<AuthUserContext.Consumer>
				{(credentials) => {
					if (
						credentials.authUser &&
						credentials.userProfile &&
						credentials.userProfile.onboarded
					)
						return <Redirect to='/dashboard' />;

					return (
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='stretch'
							style={{ height: '100vh' }}
						>
							{isDesktopOrLaptop && (
								<Grid
									item
									style={{
										maxWidth: '45%',
										minWidth: 346,
									}}
								>
									<div
										style={{
											height: '100%',
											backgroundColor: theme.palette.primary.main,
											padding: 40,
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<div style={{ width: '100%', marginBottom: '20%' }}>
											<img
												style={{ width: 200 }}
												src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
												alt='brand-logo'
											/>
										</div>
										<div
											className={classes.steps}
											style={{
												flexGrow: 1,
												color: theme.palette.primary.contrastText,
											}}
										>
											<Stepper
												className={classes.stepper}
												activeStep={activeStep}
												orientation='vertical'
												connector={<CustomStepConnector />}
											>
												{steps.map((label, index) => (
													<Step key={label}>
														<StepLabel
															classes={{ label: classes.stepLabel }}
															StepIconComponent={CustomStepIcon}
														>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'space-between',
																}}
															>
																<Typography variant='h2'>{label}</Typography>
																{index === activeStep ? (
																	<ArrowForwardIcon
																		style={{
																			width: 32,
																			height: 32,
																			color: theme.palette.background.secondary,
																			marginLeft: 16,
																		}}
																	/>
																) : (
																	<span
																		style={{ width: 32, marginLeft: 16 }}
																	></span>
																)}
															</div>
														</StepLabel>
													</Step>
												))}
											</Stepper>
										</div>
									</div>
								</Grid>
							)}
							<Grid item xs>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										height: '100%',
										padding: isDesktopOrLaptop ? 40 : '16px 24px',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											width: '100%',
											marginBottom: !isDesktopOrLaptop && 16,
										}}
									>
										<Typography
											style={{ marginLeft: 'auto' }}
											color='textSecondary'
											variant='body1'
										>
											Need Help?
										</Typography>
										<Link
											style={{
												color: theme.palette.tertiary.main,
												marginLeft: 8,
											}}
											variant='subtitle1'
											href='https://www.uuoni.com/contact'
											target='_blank'
											rel='noopener'
										>
											Contact Us
										</Link>
									</div>
									{!isDesktopOrLaptop && (
										<div
											style={{
												margin: '24px auto',
												width: '75%',
											}}
										>
											<Stepper
												className={classes.stepper}
												style={{ justifyContent: 'space-between' }}
												activeStep={activeStep}
												connector={<CustomStepConnectorMobile />}
											>
												{steps.map((label, index) => (
													<Step key={label} style={{ padding: 0 }}>
														<StepLabel
															classes={{
																label: classes.stepLabel,
																iconContainer: classes.iconContainerMobile,
															}}
															StepIconComponent={CustomStepIcon}
														></StepLabel>
													</Step>
												))}
											</Stepper>
										</div>
									)}
									<div
										style={{
											flexGrow: isDesktopOrLaptop && 1,
											padding: isDesktopOrLaptop && 24,
										}}
									>
										{getStepContent(activeStep)}
									</div>
									<div
										className='form-footer'
										style={{
											display: 'flex',
											alignItems: 'center',
											width: '100%',
											marginTop: !isDesktopOrLaptop && 24,
										}}
									>
										<Button
											style={{
												display: activeStep === 0 && 'none',
												marginRight: 16,
												width: !isDesktopOrLaptop && '100%',
											}}
											disabled={activeStep === 0}
											variant='outlined'
											color='primary'
											onClick={handleBack}
										>
											Back
										</Button>
										<Button
											style={{
												marginLeft: activeStep !== 0 && 'auto',
												width: !isDesktopOrLaptop && '100%',
											}}
											variant='contained'
											color='primary'
											onClick={handleNext}
										>
											{activeStep === steps.length - 1
												? `Let's begin!`
												: 'Next'}
										</Button>
									</div>
								</div>
							</Grid>
						</Grid>
					);
				}}
			</AuthUserContext.Consumer>
		</>
	);
}
