import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import General from './general';
import Customization from './customization';
import Principals from './principals';
import Subscription from './subscription';

function TabPanel(props) {
	const { children, value, selected, ...other } = props;

	return (
		<Box
			style={{
				width: '100%',
				height: '100%',
				display: value !== selected ? 'none' : 'flex',
				flexDirection: 'column',
			}}
			role='tabpanel'
			hidden={value !== selected}
			id={`full-width-tabpanel-${selected}`}
			aria-labelledby={`full-width-tab-${selected}`}
			{...other}
		>
			{value === selected && <>{children}</>}
		</Box>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	listItem: {
		cursor: 'pointer',
		padding: '16px 24px',
	},
}));

const options = [
	'general',
	'customization',
	'principals',
	// 'Security',
	'subscription',
	// 'Notifications',
	// 'Users',
];

export default function Settings(props) {
	const { type, subType } = useParams();
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div style={{ margin: -24, height: 'inherit' }}>
			<div
				style={{
					borderBottom: '1px solid #edf2f7',
					padding: 24,
					marginLeft: 2,
				}}
			>
				<Typography variant='h2'>Settings</Typography>
			</div>
			<Box
				display='flex'
				style={{ height: 'calc(100% - 82.6px)', marginLeft: 2 }}
			>
				<Box minWidth={250} style={{ borderRight: '1px solid #edf2f7' }}>
					<List style={{ padding: 0 }}>
						{options.map((option, index) => (
							<ListItem
								key={option}
								className={classes.listItem}
								disableGutters
								button
								component={Link}
								to={'/settings/' + option}
								selected={option === type}
							>
								<ListItemText
									style={{ margin: 0, textTransform: 'capitalize' }}
									primaryTypographyProps={{
										style: {
											color: option === type && theme.palette.tertiary.main,
											fontWeight: option === type ? 600 : 400,
										},
									}}
									primary={option}
								/>
							</ListItem>
						))}
					</List>
				</Box>
				<TabPanel
					value={'general'}
					index={0}
					selected={type}
					dir={theme.direction}
				>
					<Typography variant='h2' style={{ padding: 24 }}>
						General
					</Typography>
					<General subType={subType} />
				</TabPanel>
				<TabPanel
					value={'customization'}
					index={1}
					selected={type}
					dir={theme.direction}
				>
					<Typography variant='h2' style={{ padding: 24 }}>
						Customization
					</Typography>
					<Customization subType={subType} />
				</TabPanel>
				<TabPanel
					value={'principals'}
					index={2}
					selected={type}
					dir={theme.direction}
				>
					<Typography variant='h2' style={{ padding: 24 }}>
						Principals
					</Typography>
					<Principals subType={subType} />
				</TabPanel>
				{/* <TabPanel value={selected} index={3} dir={theme.direction}>
					<Typography variant='h2' style={{ padding: 24 }}>Security</Typography>
						<Security />
					</TabPanel> */}
				<TabPanel
					value={'subscription'}
					index={3}
					selected={type}
					dir={theme.direction}
				>
					<Typography variant='h2' style={{ padding: 24 }}>
						Subscription
					</Typography>
					<Subscription subType={subType} />
				</TabPanel>
				{/* <TabPanel value={selected} index={5} dir={theme.direction}>
						<Typography variant='h2' style={{ padding: 24 }}>Notifications</Typography>
						<Notifications />
					</TabPanel> */}
				{/* <TabPanel value={selected} index={6} dir={theme.direction}>
						<Typography variant='h2' style={{ padding: 24 }}>Users</Typography>
						<Users />
					</TabPanel> */}
			</Box>
		</div>
	);
}
