import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import defaultTheme from './default';

const overrides = {
	typography: {
		fontFamily: ['Poppins'].join(','),
		h1: {
			fontSize: '2.25rem',
			fontWeight: 600,
		},
		h2: {
			fontSize: '1.75rem',
			fontWeight: 600,
		},
		h3: {
			fontSize: '1.25rem',
			fontWeight: 600,
		},
		h4: {
			fontSize: '1.125rem',
			fontWeight: 600,
		},
		h5: {
			fontSize: '1rem',
			fontWeight: 600,
		},
		h6: {
			fontSize: '0.875rem',
			fontWeight: 600,
		},
		body1: {
			fontSize: '1rem',
			fontWeight: 400,
		},
		body2: {
			fontSize: '1rem',
			fontWeight: 600,
			lineHeight: 1.5,
			letterSpacing: '0.00938em',
		},
		subtitle1: {
			fontSize: '0.875rem',
			fontWeight: 400,
		},
		subtitle2: {
			fontSize: '0.875rem',
			fontWeight: 600,
		},
		button: {
			fontSize: '0.75rem',
			fontWeight: 500,
		},
		caption: {
			fontSize: '0.75rem',
			fontWeight: 400,
		},
	},
};

let base = createTheme(defaultTheme);

const fontOptions = {
	breakpoints: ['sm'],
	factor: 5,
};

export { base };
