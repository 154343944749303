import { Badge, IconButton, Menu } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { toast } from 'react-toastify';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import api from '../../config/api';
import { Notification } from '../Icons';

function getTimeDifference(date) {
	let difference =
		moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(
			moment(date, 'DD/MM/YYYY HH:mm:ss')
		) / 1000;

	if (difference < 60) return `${Math.floor(difference)} seconds ago`;
	else if (difference < 3599)
		return `${Math.floor(difference / 60)} minutes ago`;
	else if (difference < 86399)
		return `${Math.floor(difference / 3660)} hour(s) ago`;
	else if (difference < 86400 * 30)
		return `${Math.floor(difference / 86400)} day(s) ago`;
	else if (difference < 86400 * 30 * 12)
		return `${Math.floor(difference / 86400 / 30)} month(s) ago`;
	else return `${moment(date).format('DD/MM/YYYY')}`;
}

const useStyles = makeStyles((theme) => ({
	headerMenu: {
		marginTop: theme.spacing(10),
	},
	headerMenuList: {
		display: 'flex',
		flexDirection: 'column',
		padding: 0,
	},
	headerMenuButton: {
		marginLeft: theme.spacing(2),
		padding: theme.spacing(0.5),
	},
	headerIcon: {
		fontSize: 24,
		marginLeft: 2,
	},
	headerIconMobile: {
		fontSize: 24,
		marginLeft: 2,
	},
	profileMenu: {
		width: 400,
		top: '0px !important',
		borderRadius: 4,
		background: '#FFFFFF',
		boxShadow:
			'0px 0px 1px rgba(12, 26, 75, 0.2), 0px 1px 3px rgba(50, 50, 71, 0.1)',
	},
	badge: {
		color: theme.palette.error.contrastText,
		backgroundColor: theme.palette.error.main,
	},
}));

const NotificationMenu = (props) => {
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();
	const { socketInstance, isDesktopOrLaptop } = props;
	const [panelOpen, setPanelOpen] = useState(false);
	const [count, setCount] = useState(0);
	const [notificationList, setNotificationList] = useState([]);
	const [notificationMenu, setNotificationMenu] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (socketInstance) {
			socketInstance.on('new-notification', (notification) => {
				toast.success(notification.content, {
					onClick: () => history.push(notification.targetUrl),
				});
				setCount((prev) => prev + 1);
			});
		}
	}, []);

	const getCount = async () => {
		try {
			const response = await api.get('notifications/count');
			setCount(response.data);
		} catch (error) {
			console.log('error', error);
		}
	};

	useEffect(() => {
		getCount();
	}, []);

	useEffect(() => {
		if (panelOpen) {
			getNotifications();
		}
	}, [panelOpen]);

	const handleListItemClick = (id) => {
		if (process.env.REACT_APP_ENVIRONMENT !== 'demo') {
			updateNotifications(id);
		}
		setNotificationMenu(null);
		setPanelOpen(false);
		getCount();
	};

	const getNotifications = async () => {
		try {
			if (!loading) {
				setLoading(true);
			}
			const response = await api.get('notifications');
			setNotificationList(response.data);
			setLoading(false);
		} catch (error) {
			console.log('error', error);
		}
	};

	const updateNotifications = async (id) => {
		try {
			await api.put('notifications/seen/' + id);
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleDrawerToggle = () => {
		if (!panelOpen) {
			setPanelOpen(true);
		} else {
			setPanelOpen(!panelOpen);
			setNotificationMenu(null);
		}
		setCount(0);
	};

	return (
		<>
			<IconButton
				style={{
					backgroundColor: notificationMenu && theme.palette.primary.light,
				}}
				size={isDesktopOrLaptop ? 'medium' : 'small'}
				onClick={(e) => {
					setNotificationMenu(e.currentTarget);
					handleDrawerToggle();
				}}
			>
				{count > 0 ? (
					<Badge classes={{ badge: classes.badge }} variant='dot'>
						<Notification
							classes={{
								root: isDesktopOrLaptop
									? classes.headerIcon
									: classes.headerIconMobile,
							}}
							colorcode={theme.palette.primary.contrastText}
						/>
					</Badge>
				) : (
					<Notification
						classes={{
							root: isDesktopOrLaptop
								? classes.headerIcon
								: classes.headerIconMobile,
						}}
						colorcode={theme.palette.primary.contrastText}
					/>
				)}
			</IconButton>

			<Menu
				id='notification-menu'
				open={Boolean(notificationMenu)}
				anchorEl={notificationMenu}
				onClose={() => handleDrawerToggle()}
				MenuListProps={{ className: classes.headerMenuList }}
				className={classes.headerMenu}
				classes={{ paper: classes.profileMenu }}
				TransitionComponent={Fade}
				disableAutoFocusItem
			>
				<div style={{ display: 'flex', alignItems: 'center', padding: 16 }}>
					<Typography variant='h3'>Notifications</Typography>
					<Link
						style={{
							marginLeft: 'auto',
							textDecoration: 'none',
							fontWeight: 400,
						}}
						component={RouterLink}
						color='primary'
						to={'/notifications'}
						onClick={() => handleDrawerToggle()}
					>
						See all
					</Link>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: 640,
						overflowY: 'auto',
					}}
				>
					{loading ? (
						<CircularProgress
							style={{ height: 88, width: 88, margin: 'auto' }}
						/>
					) : notificationList.length ? (
						notificationList.map((notification, index) => (
							<React.Fragment key={index}>
								<ListItem
									button
									component={RouterLink}
									to={notification.targetUrl}
									style={{
										textDecoration: 'none',
										alignItems: 'center',
									}}
									alignItems='flex-start'
									onClick={() => handleListItemClick(notification._id)}
								>
									<ListItemText
										primary={
											<Typography variant='body1' color='textPrimary'>
												{notification.content}
											</Typography>
										}
										secondary={
											<Typography
												variant='subtitle1'
												style={{
													marginTop: 8,
													fontWeight: 500,
													color: theme.palette.tertiary.main,
												}}
											>
												{getTimeDifference(new Date(notification.createdAt))}
											</Typography>
										}
									/>
									{!notification.seen && (
										<ListItemIcon
											style={{
												marginTop: 0,
												color: theme.palette.tertiary.main,
												justifyContent: 'flex-end',
											}}
										>
											<FiberManualRecordIcon />
										</ListItemIcon>
									)}
								</ListItem>
							</React.Fragment>
						))
					) : (
						<ListItem key={'empty'} alignItems='flex-start'>
							<ListItemText primary='No notifications found!' />
						</ListItem>
					)}
				</div>
			</Menu>
		</>
	);
};

export default NotificationMenu;
