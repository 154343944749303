import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { combineNames } from '../../../../utils/combineNames';

const useStyles = makeStyles((theme) => ({
	chip: {
		margin: theme.spacing(0.5),
	},
	tableRow: {},
}));

const DataRow = (props) => {
	const classes = useStyles();
	const { data, handleCheckbox } = props;
	return (
		<TableRow
			className={classes.tableRow}
			style={{ cursor: 'default' }}
			hover
			selected={data.checked}
			key={data._id}
			onClick={(e) => {
				let target = e.target.closest('td');
				if (target === null) {
					return;
				}
				if (target.cellIndex === 0 || target.cellIndex === undefined) {
					return;
				}
				props.setSelectedUnderwriter(data);
				props.setAction('update');
			}}
		>
			<TableCell className='checkBox' style={{ padding: 6 }}>
				<Checkbox
					checked={data.checked}
					onChange={(e) => handleCheckbox(e, data._id)}
					inputProps={{
						'aria-label': 'primary checkbox',
					}}
				/>
			</TableCell>
			<TableCell style={{ padding: 6 }}>
				{combineNames({
					fName: data.firstName,
					mName: data.middleName !== undefined ? data.middleName : null,
					lName: data.lastName !== undefined ? data.lastName : null,
				})}
			</TableCell>
			<TableCell style={{ padding: 6 }}>{data.email}</TableCell>
			<TableCell style={{ padding: 6 }}>
				{data.mobileNo} <br /> {data.officeNo}
			</TableCell>
			<TableCell style={{ padding: 6 }}>{data.principalName}</TableCell>

			<TableCell style={{ padding: 6 }}>{data.department}</TableCell>
			{/* <TableCell style={{ padding: 6 }}>
				{data.status ? 'Active' : 'Inactive'}
			</TableCell> */}
			{/* {data.status ? (
					<Chip className={classes.chip} label='Active' />
				) : (
					<Chip className={classes.chip} label='Inactive' />
				)} */}
		</TableRow>
	);
};

export default DataRow;
