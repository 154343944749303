import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { cloneDeep, isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import QuotationCard from '../QuotationCard';
import api from '../../../../../../../config/api';
import { getDiff } from '../../../../../../../utils/formHelper';
import TransactionModal from '../../../../../../../components/Modals/transactions';
import QuoteSelectionRequestItem from '../QuoteSelectionRequestItem';
import { Quote } from '../../../../../../../utils/validators/schemas';
import { Edit, Send } from '../../../../../../../components/Icons';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: 120,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	tabs: {
		height: 48,
		minWidth: 10,
	},
	principalTabs: {
		overflowY: 'hidden',
	},
	tabContent: {
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: 24,
	},
}));

const initQuoteData = {
	expiryDate: null,
	startDate: null,
	endDate: null,
	premiumAmt: null,
	sumInsured: null,
	notes: '',
	fileData: null,
	recommended: false,
	recommendationRemarks: '',
};

function TabContent(props) {
	const {
		principal,
		request,
		transactionId,
		hasExistingRequest,
		handleRequestListUpdate,
		setTriggerUpdate,
		disableEdit,
		handleReset,
	} = props;
	const classes = useStyles();
	const theme = useTheme();

	const [requestData, setRequestData] = useState(cloneDeep(request));
	const [currentQuoteList, setCurrentQuoteList] = useState([]);
	const [updatedQuoteList, setUpdatedQuoteList] = useState([]);
	const [editable, setEditable] = useState(hasExistingRequest ? false : true);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);

	const initializeData = () => {
		if (
			request?.status !== 'Pending Response' &&
			request?.status !== 'Email failed' &&
			request?.responseData?.quoteList !== undefined
		) {
			let initQuoteList = request.responseData.quoteList
				.sort((a, b) => -b.quoteName.localeCompare(a.quoteName))
				.map((quote, index) => ({
					...quote,
					id: nanoid(),
					open: index === 0 ? true : false,
				}));

			setCurrentQuoteList(cloneDeep(initQuoteList));
			setUpdatedQuoteList(cloneDeep(initQuoteList));
		} else {
			setCurrentQuoteList([]);
			setUpdatedQuoteList([]);
		}
		setRequestData(cloneDeep(request));
	};

	useEffect(() => {
		initializeData();
	}, [props]);

	const hasQuoteList = () => {
		if (isEmpty(requestData?.responseData?.quoteList)) {
			if (updatedQuoteList.length > 0) {
				return true;
			}
			return false;
		} else {
			return true;
		}
	};

	const handleAddQuote = () => {
		if (!editable) {
			setEditable(true);
		}

		const quoteNumbersInUse = updatedQuoteList.map((quote) =>
			parseInt(quote.quoteName.replace(/\D/g, ''), 10)
		);

		const nextAvailableQuoteNumber =
			quoteNumbersInUse.length > 0 ? Math.max(...quoteNumbersInUse) + 1 : 1;

		const newQuote = {
			...initQuoteData,
			quoteName: `Quote ${nextAvailableQuoteNumber}`,
			id: nanoid(),
			open: true,
		};

		setUpdatedQuoteList([...updatedQuoteList, newQuote]);
	};

	const handleRemoveQuote = (id) => {
		setUpdatedQuoteList(updatedQuoteList.filter((quote) => quote.id !== id));
	};

	const handleInputChange = (e, id) => {
		const {
			target: { value, type, name },
		} = e;

		setUpdatedQuoteList(
			updatedQuoteList.map((quote) =>
				quote.id === id
					? {
							...quote,
							[name]:
								type === 'number' && value !== '' ? parseFloat(value) : value,
					  }
					: quote
			)
		);
	};

	const handleDateChange = (id, target, date) => {
		let updatedDate = date;

		if (target === 'endDate') {
			updatedDate = new Date(date);
			updatedDate.setHours(23, 59, 59, 999); // Set to end of the day
		}

		setUpdatedQuoteList(
			updatedQuoteList.map((quote) =>
				quote.id === id ? { ...quote, [target]: updatedDate } : quote
			)
		);
	};

	const handleFileChange = (id, files) => {
		setUpdatedQuoteList(
			updatedQuoteList.map((quote) =>
				quote.id === id ? { ...quote, fileData: files } : quote
			)
		);
	};

	const toggleOpen = (id) => {
		setUpdatedQuoteList(
			updatedQuoteList.map((quote) =>
				quote.id === id ? { ...quote, open: !quote.open } : quote
			)
		);
	};

	const getQuotationCards = () => {
		if (updatedQuoteList.length > 0) {
			return updatedQuoteList.map((quote, index) => (
				<QuotationCard
					key={quote.id}
					index={index}
					id={quote.id}
					editable={quote.selectedByClient ? false : editable}
					errorMessages={errorMessages}
					requestData={requestData}
					quotation={quote}
					handleRemoveQuote={handleRemoveQuote}
					handleInputChange={handleInputChange}
					handleDateChange={handleDateChange}
					handleFileChange={handleFileChange}
					toggleOpen={toggleOpen}
					isSelfGenerated={true}
					setTriggerUpdate={setTriggerUpdate}
					disableRemove={quote.selectedByClient}
					disableEdit={disableEdit}
				/>
			));
		} else {
			return (
				<Typography color='textSecondary' variant='subtitle1'>
					No Quotations provided!
				</Typography>
			);
		}
	};

	const handleCancel = () => {
		initializeData();
		setEditable(false);
	};

	const handleValidation = () => {
		let errorData = {};
		let dataToValidate = {};
		let schemaObject = {};
		let result = {};
		let isValid = true;

		let validateQuotations = updatedQuoteList.length > 0;
		if (validateQuotations) {
			dataToValidate['updatedQuoteList'] = updatedQuoteList;
			schemaObject['updatedQuoteList'] = Joi.array().items(Quote);
			updatedQuoteList.forEach((quotation, index) => {
				if (
					isEmpty(quotation.responseDocument) &&
					isEmpty(quotation.fileData)
				) {
					errorData[`updatedQuoteList.${index}.fileData`] =
						'Quote Document is required';
					isValid = false;
				}
			});
		}

		let schema = Joi.object(schemaObject);
		result = schema.validate(dataToValidate, {
			abortEarly: false,
			convert: true,
		});

		if (result.error === undefined && isValid) {
			setErrorMessages({});
			return true;
		} else {
			if (result.error) {
				console.log(result.error.details);
				for (let item of result.error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			console.log(errorData);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = () => {
		if (!loading) {
			console.log('handleSave');
			setLoading(true);

			let valid = handleValidation();
			if (valid) {
				let changedData;
				if (!isEmpty(requestData)) {
					changedData = getDiff(updatedQuoteList, currentQuoteList);
					console.log(changedData);
				}
				if (!isEmpty(request) && !changedData) {
					setErrorMessages({});
					setEditable(false);
					setLoading(false);
				} else {
					try {
						const data = new FormData();
						let json = cloneDeep(updatedQuoteList);

						if (json.length > 0) {
							json.forEach((quote) => {
								if (!isEmpty(quote.fileData)) {
									data.append(
										quote.quoteName,
										quote.fileData[0],
										quote.fileData[0].name
									);
								}
								delete quote.fileData;
								delete quote.id;
								delete quote.open;
							});
						}

						if (!hasExistingRequest) {
							if (json.length < 1) {
								throw new Error('Please provide at least one Quotation!');
							}
							let formData = {
								updatedFields: {
									principalId: principal._id,
									principalName: principal.shortName,
									responseData: {
										accepted: true,
										quoteList: json,
									},
								},
								auditLog: changedData,
							};

							console.log(formData);
							data.append('json', JSON.stringify(formData));

							api
								.put(`transactions/${transactionId}/populateQuotes`, data)
								.then((res) => {
									if (res.data.ok) {
										setErrorMessages({});
										setEditable(false);
										// if (!isEmpty(requestData)) {
										// 	handleRequestListUpdate(requestData._id, res.data.data);
										// } else {
										setTriggerUpdate(true);
										// }
										toast.success('Quotes updated!');
									} else {
										toast.error(res.data.error);
									}
								});
						} else {
							let formData = {
								updatedFields: {
									responseData: {
										accepted: true,
										quoteList: json,
									},
								},
								auditLog: changedData,
							};

							console.log(formData);
							data.append('json', JSON.stringify(formData));

							api
								.put(`requests/${request._id}/update-quotes`, data)
								.then((res) => {
									if (res.data.ok) {
										setErrorMessages({});
										setEditable(false);
										handleRequestListUpdate(requestData._id, res.data.data);

										toast.success('Quotes updated!');
									} else {
										toast.error(res.data.error);
									}
								});
						}
					} catch (error) {
						console.log(error);
						toast.error(error.message);
					} finally {
						setLoading(false);
					}
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	const getActions = () => {
		if (editable) {
			return (
				<>
					<Button
						onClick={() => handleCancel()}
						variant='outlined'
						color='primary'
						className={classes.button}
					>
						Cancel
					</Button>
					<Button
						onClick={() => handleSave()}
						variant='contained'
						color='primary'
						className={classes.button}
						style={{ marginLeft: 8 }}
					>
						Save Quotes
					</Button>
				</>
			);
		} else {
			return (
				<Button
					onClick={() => setEditable(true)}
					variant='outlined'
					color='primary'
					className={classes.button}
					startIcon={
						<Edit
							style={{ fontSize: 24 }}
							colorcode={theme.palette.tertiary.main}
						/>
					}
				>
					Edit Quotes
				</Button>
			);
		}
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div id='quote-list-container'>
				{!hasQuoteList() ? (
					<div className={classes.root}>
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddCircleOutlineIcon />}
							onClick={() => handleAddQuote()}
							disabled={disableEdit}
						>
							Add Quotation
						</Button>
					</div>
				) : (
					<>
						{hasExistingRequest && (
							<div
								id='action-container'
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
									marginBottom: 16,
								}}
							>
								{!disableEdit && getActions()}
							</div>
						)}
						{getQuotationCards()}
						{editable && (
							<div id='add-quotation'>
								<Button
									style={{ alignSelf: 'flex-start', padding: '16px 8px' }}
									color='primary'
									startIcon={<AddCircleOutlineIcon />}
									onClick={() => handleAddQuote()}
									disabled={disableEdit}
								>
									Add Quotation
								</Button>
							</div>
						)}
						{!disableEdit && !hasExistingRequest && editable && (
							<>
								<Divider style={{ margin: '0px -24px 16px -24px' }} />
								<div
									id='footer-action-container'
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<Button
										onClick={() => handleReset()}
										variant='outlined'
										color='primary'
										className={classes.button}
									>
										Cancel
									</Button>
									<Button
										onClick={() => handleSave()}
										variant='contained'
										color='primary'
										className={classes.button}
									>
										Save Quotes
									</Button>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default function SelfGeneratedView(props) {
	const { transaction, setTransaction, setTriggerUpdate, disableEdit } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [principals, setPrincipals] = useState([]);
	const [value, setValue] = useState(0);
	const [requestList, setRequestList] = useState([]);
	const [loading, setLoading] = useState(false);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);
	const [modalData, setModalData] = useState(null);

	const getPrincipalList = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('principals/list/active')
			.then((res) => {
				setPrincipals(res.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getRequestList = (requests) => {
		let updatedRequestList = requests.map((request, index) => ({
			...request,
			id: nanoid(),
		}));
		setRequestList(updatedRequestList);
	};

	useEffect(() => {
		if (!isEmpty(transaction.requests)) {
			getRequestList(transaction.requests);
		}
		getPrincipalList();
	}, [transaction]);

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};

	const handleRequestListUpdate = (requestId, data) => {
		setTransaction((prev) => ({
			...prev,
			requests: prev.requests.map((request) =>
				request._id === requestId
					? {
							...request,
							...data,
					  }
					: request
			),
		}));
	};

	const initRequests = () => {
		let updatedRequestList = principals.map((principal, index) => ({
			id: nanoid(),
			principalId: principal._id,
			principalName: principal.shortName,
			responseData: {
				accepted: true,
				quoteList: [
					{
						...initQuoteData,
						quoteName: `Quote 1`,
						id: nanoid(),
						open: true,
					},
				],
			},
		}));
		setRequestList(updatedRequestList);
	};

	const getQuoteCount = () => {
		return transaction.requests
			.filter((request) => request.status === 'Acknowledged')
			.reduce((total, request) => {
				return (
					total +
					(request.responseData.quoteList
						? request.responseData.quoteList.length
						: 0)
				);
			}, 0);
	};

	const handleModalOpen = (body, data) => {
		if (data) {
			setModalData(data);
		}
		setModalType(body);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalType(null);
	};

	const getQuoteSelectionRequestInfo = () => {
		if (!isEmpty(transaction.quoteSelectionRequest)) {
			return (
				<QuoteSelectionRequestItem
					request={transaction.quoteSelectionRequest}
					quoteRequestList={requestList.filter(
						(request) =>
							request.status === 'Acknowledged' &&
							request.responseData.quoteList !== undefined &&
							request.responseData.quoteList.length > 0
					)}
					setTriggerUpdate={setTriggerUpdate}
					disableEdit={disableEdit}
				/>
			);
		} else {
			if (!isEmpty(requestList)) {
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '16px 24px',
						}}
					>
						<Typography color='textPrimary' variant='body1'>
							{getQuoteCount()} Quote(s) ready for Client's Selection
						</Typography>
						<Button
							onClick={() =>
								handleModalOpen(
									'sendClientRequestForQuoteSelection',
									requestList.filter(
										(request) =>
											request.status === 'Acknowledged' &&
											request.responseData.quoteList !== undefined &&
											request.responseData.quoteList.length > 0
									)
								)
							}
							variant='contained'
							color='primary'
							className={classes.button}
							style={{ marginLeft: 'auto' }}
							startIcon={
								<Send
									style={{ fontSize: 24 }}
									colorcode={theme.palette.primary.contrastText}
								/>
							}
							disabled={getQuoteCount() === 0}
						>
							Forward to Client
						</Button>
					</div>
				);
			}
		}
	};

	if (loading || isEmpty(requestList))
		return (
			<div className={classes.root}>
				{loading ? (
					<CircularProgress />
				) : (
					<Button
						onClick={() => initRequests()}
						variant='contained'
						color='primary'
						className={classes.button}
						startIcon={<AddCircleOutlineIcon />}
						disabled={disableEdit}
					>
						Add Quotation
					</Button>
				)}
			</div>
		);

	return (
		<div
			id='self-generated-view'
			style={{ flexDirection: 'column', width: '100%' }}
		>
			<div id='qsr-container'>{getQuoteSelectionRequestInfo()}</div>
			<Divider />
			<Tabs
				classes={{ scrollable: classes.principalTabs }}
				style={{ padding: '0px 24px', minHeight: 42, height: 42 }}
				value={value}
				onChange={handleChangeTab}
				variant='scrollable'
				scrollButtons='auto'
				aria-label='full width tabs example'
			>
				{principals.map((principal, index) => (
					<Tab
						key={principal._id}
						className={classes.tabs}
						label={principal.shortName}
						{...a11yProps(index)}
					/>
				))}
			</Tabs>
			<Divider />
			{principals.map((principal, index) => (
				<TabPanel
					key={principal._id}
					className={classes.tabContent}
					value={value}
					index={index}
					dir={theme.direction}
				>
					<TabContent
						principal={principal}
						request={requestList?.find(
							(request) => request.principalName === principal.shortName
						)}
						transactionId={transaction._id}
						hasExistingRequest={transaction.requests?.find(
							(request) => request.principalName === principal.shortName
						)}
						handleRequestListUpdate={handleRequestListUpdate}
						setTriggerUpdate={setTriggerUpdate}
						disableEdit={disableEdit}
						handleReset={() => setRequestList([])}
					/>
				</TabPanel>
			))}

			{modalOpen && (
				<TransactionModal
					open={modalOpen}
					handleClose={handleModalClose}
					body={modalType}
					transactionId={transaction._id}
					data={modalData}
					setTriggerUpdate={setTriggerUpdate}
					size={
						modalType === 'sendClientRequestForQuoteSelection' ? 'xl' : 'sm'
					}
				/>
			)}
		</div>
	);
}
