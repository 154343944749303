import Joi from 'joi';

const SupportingDocument = Joi.object({
	name: Joi.string().label('Document Name').trim().min(1).max(50).required(),

	remarks: Joi.string()
		.label('Remarks')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default SupportingDocument;
