import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import CountryList from '../../../../components/Data/countries.json';
import DevExcelForm from '../../../../views/Import/';

const useStyles = makeStyles((theme) => ({}));

const options = ['Profile', 'Company'];

export default function InvoiceCustomization(props) {
	const classes = useStyles();
	const [client, setClient] = useState(props.client);

	const [selectedIndex, setSelectedIndex] = useState(0);

	useEffect(() => {
		setClient(props.client);
	}, [props]);

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
	};

	return <DevExcelForm type='Underwriter' />;
}
