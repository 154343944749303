import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import React from 'react';
import PlansData from './PlansData';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles((theme) => ({
	priceGridContainer: {
		alignSelf: 'center',
	},
	priceGridContainerItem: {
		borderRadius: 8,
	},
	priceGridContainerItemContent: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		textAlign: 'left',
		background: theme.palette.background.default,
		boxShadow:
			'0px 4px 12px rgba(0, 0, 0, 0.16), 0px 0px 4px rgba(0, 0, 0, 0.16)',
		borderRadius: 8,
	},
	promoBanner: {
		display: 'flex',
		justifyContent: 'center',
		color: theme.palette.tertiary.main,
		background: theme.palette.secondary.main,
		borderRadius: '8px 8px 0px 0px',
	},
	headerContainer: {
		marginBottom: 24,
	},
	contentContainer: {
		height: 152,
		overflow: 'auto',
		marginTop: 'auto',
	},
	featureList: {
		height: 256,
		overflow: 'auto',
	},
}));

export default function PricingTable(props) {
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const classes = useStyles();
	const theme = useTheme();
	const { yearly, setSelectedPlan, quantity, setQuantity, isExistingCustomer } =
		props;

	const selectPlan = (item) => {
		if (!item) {
			return;
		} else {
			if (yearly) {
				setSelectedPlan({
					name: item.name,
					priceId: item.priceYrId,
				});
			} else {
				setSelectedPlan({
					name: item.name,
					priceId: item.priceMthId,
				});
			}
		}
	};

	return (
		<div
			className={classes.priceGridContainer}
			style={{ margin: isDesktopOrLaptop && 16 }}
		>
			<Grid container spacing={isDesktopOrLaptop ? 3 : 4}>
				{PlansData.map((item, index) => (
					<Grid key={index} item xs={12} md={6} lg={3}>
						<div
							className={classes.priceGridContainerItem}
							style={{
								border: item.recommended && '2px solid #5E80E6',
							}}
						>
							<div id='recommended' style={{ height: isDesktopOrLaptop && 42 }}>
								{item.recommended && (
									<div
										className={classes.promoBanner}
										style={{ height: !isDesktopOrLaptop ? 42 : '100%' }}
									>
										<Typography variant='h4' style={{ margin: 'auto' }}>
											Most Popular Plan
										</Typography>
									</div>
								)}
							</div>
							<div
								className={classes.priceGridContainerItemContent}
								style={{
									padding: isDesktopOrLaptop ? 32 : 24,
									borderRadius: item.recommended && '0px 0px 8px 8px',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										minHeight: isDesktopOrLaptop && 340,
									}}
								>
									<Typography
										variant='h2'
										style={{ textTransform: 'uppercase', marginBottom: 24 }}
									>
										{item.name}
									</Typography>
									<div className={classes.headerContainer}>
										{item.priceMth >= 0 && item.priceYr >= 0 ? (
											<>
												{!isExistingCustomer && (
													<Typography
														variant='h3'
														color='textPrimary'
														style={{ fontWeight: 400 }}
													>
														Starts at <b>30 Days Free*</b>, then at
													</Typography>
												)}
												{yearly ? (
													<>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																flexWrap: 'center',
															}}
														>
															<Typography
																variant='h1'
																style={{
																	color: theme.palette.tertiary.main,
																	lineHeight: 1.5,
																}}
															>
																{item.priceYr === 0
																	? `S$XXX`
																	: `S$${item.priceYr}`}
															</Typography>
														</div>
														<div
															style={{ display: 'flex', alignItems: 'center' }}
														>
															<Typography
																variant='h3'
																style={{ fontWeight: 400 }}
															>
																per year
															</Typography>
														</div>
													</>
												) : (
													<>
														<Typography
															variant='h1'
															style={{
																color: theme.palette.tertiary.main,
																lineHeight: 1.5,
															}}
														>
															{item.priceMth === 0
																? `S$XX`
																: `S$${item.priceMth}`}
														</Typography>
														<Typography
															variant='h3'
															style={{ fontWeight: 400 }}
														>
															per month
														</Typography>
													</>
												)}
											</>
										) : (
											<>
												<Typography
													variant='h3'
													color='textPrimary'
													style={{ fontWeight: 400 }}
												>
													Reach out to us to get a Pricing Quotation for your
													needs.
												</Typography>
											</>
										)}
									</div>
									<div
										className={isDesktopOrLaptop && classes.contentContainer}
									>
										<Typography
											variant='h3'
											color='primary'
											style={{ textTransform: 'uppercase' }}
										>
											{item.heading}
										</Typography>
										<Typography variant='body1' style={{ marginTop: 8 }}>
											{item.subHeading}
										</Typography>
									</div>
								</div>
								<Divider
									style={{
										width: '100%',
										margin: '24px 0px',
										color: theme.palette.secondary.main,
									}}
								/>
								<List
									className={isDesktopOrLaptop && classes.featureList}
									subheader={<li />}
								>
									{item.featureList.map((feature, index) => (
										<ListItem
											key={index}
											disableGutters
											style={{ padding: '4px 0px' }}
										>
											<CheckOutlinedIcon
												style={{ color: theme.palette.tertiary.main }}
											/>
											<Typography
												variant='body1'
												style={{ marginLeft: 8, fontWeight: 400 }}
											>
												{feature.label}
											</Typography>
											{!feature.available && (
												<Chip
													style={{
														marginLeft: 8,
														backgroundColor: theme.palette.secondary.main,
														color: theme.palette.text.secondary,
														height: 25,
														fontSize: 12,
														fontWeight: 500,
														borderRadius: 4,
													}}
													label={'COMING SOON'}
													color='secondary'
												/>
											)}
										</ListItem>
									))}
								</List>

								{item.addons && (
									<>
										<Typography
											variant='body2'
											color='primary'
											style={{ textTransform: 'uppercase', marginTop: 24 }}
										>
											OPTIONAL ADD-ONS
										</Typography>

										<List subheader={<li />}>
											{item.addons.map((addon, index) => (
												<ListItem key={index} disableGutters>
													<AddCircleOutlineRoundedIcon
														style={{ color: theme.palette.tertiary.main }}
													/>
													<Typography
														variant='body1'
														style={{ marginLeft: 8, fontWeight: 400 }}
													>
														{addon}
													</Typography>
												</ListItem>
											))}
										</List>
									</>
								)}

								{item.name === 'Business' ? (
									<Button
										variant='contained'
										color='primary'
										style={{
											marginTop: 16,
											width: '100%',
											alignSelf: 'center',
											color: theme.palette.secondary.light,
											textDecoration: 'none',
										}}
										component={Link}
										href='https://www.uuoni.com/contact'
										target='_blank'
										rel='noopener'
									>
										Talk to Us
									</Button>
								) : (
									<Button
										variant='contained'
										color='primary'
										style={{
											marginTop: 16,
											width: '100%',
											alignSelf: 'center',
										}}
										onClick={() => selectPlan(item)}
										disabled={!item.enabled}
									>
										{item.enabled
											? isExistingCustomer
												? `Continue with ${item.name}`
												: `Start Free with ${item.name}`
											: `Coming Soon`}
									</Button>
								)}
							</div>
						</div>
					</Grid>
				))}
			</Grid>
		</div>
	);
}

{
	/* {item.name === 'Associates' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									margin: 'auto',
									marginBottom: 24,
								}}
							>
								<Typography variant='body2' style={{ margin: '0px 32px' }}>
									Add Additional Licenses
								</Typography>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<IconButton onClick={(e) => setQuantity((prev) => prev - 1)}>
										<RemoveIcon />
									</IconButton>
									<Typography variant='body1' style={{ margin: '0px 32px' }}>
										{quantity}
									</Typography>
									<IconButton onClick={(e) => setQuantity((prev) => prev + 1)}>
										<AddIcon />
									</IconButton>
								</div>
							</div>
						)} */
}
