import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CustomTooltip from '../../../../components/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactComponent as Claim } from '../../../../assets/Txn_Claim_v1.svg';
import { ReactComponent as Quotation } from '../../../../assets/Txn_Quotation_v1.svg';
import { ReactComponent as Renewal } from '../../../../assets/Txn_Renewal_v1.svg';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEmpty } from 'lodash';
import matchSorter from 'match-sorter';
import React, { useEffect, useState } from 'react';
import api from '../../../../config/api';
import InsuranceTypes from '../../insuranceTypes.json';
import DataRow from './transactionRow';
import CustomSwitch from '../../../../components/Switch';
import { Company, Contacts } from '../../../../components/Icons';

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		margin: '24px -24px 0px -24px;',
	},
	cardContainer: {
		padding: 32,
	},
	card: {
		width: 320,
		height: 140,
		marginBottom: 32,
		transition: 'all 0.25s',
		'&:hover': {
			boxShadow: `0px 0px 0px 2px ${theme.palette.tertiary.main}`,
		},
		'&:focus': {
			boxShadow: `0px 0px 0px 2px ${theme.palette.tertiary.main}`,
		},
	},
	cardContent: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
	cardContentRoot: { padding: 24 },
	cardText: {
		marginLeft: 24,
	},
	cover: {
		width: 76,
	},
	contentContainer: {
		width: '70%',
	},
	infoContainer: {
		padding: 24,
	},
	iconNumber: {
		display: 'flex',
		minWidth: 32,
		minHeight: 32,
		borderRadius: '50%',
		backgroundColor: theme.palette.tertiary.main,
		color: theme.palette.background.default,
		marginRight: 24,
	},
	methodButton: {
		borderRadius: 8,
		padding: 16,
	},
	methodButtonIcon: {},
	badge: {
		alignItems: 'center',
		flexDirection: 'column',
		width: 100,
	},
	badgeIcon: {
		color: '#7FA572',
		backgroundColor: '#fafafa',
		padding: 'unset',
	},
	offset: {
		right: -15,
		top: -15,
	},
}));

const str2bool = (value) => {
	if (value && typeof value === 'string') {
		if (value.toLowerCase() === 'true') return true;
		if (value.toLowerCase() === 'false') return false;
	}
	return value;
};

const printSourceString = (type) => {
	if (!type) {
		return type;
	}

	let msg = '';

	if (type === 'Renewal') {
		msg =
			'If the Policy to file a Renewal for is done outside of Uuoni, please create a Transaction for that Policy first!';
	}

	if (type === 'Claims') {
		msg =
			'If the Policy to file a Claim for is done outside of Uuoni, please create a Transaction for that Policy first!';
	}

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body1' color='textPrimary'>
				{msg}
			</Typography>
			{/* <Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{existingBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{existingMsg}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{externalBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{externalMsg}
			</Typography> */}
		</div>
	);
};

const printDPString = (haveDocuments) => {
	return (
		<Typography variant='body1' color='textPrimary'>
			{haveDocuments
				? `Select if this transaction was completed `
				: `Select if this transaction will be processed `}
			<Tooltip
				placement='top'
				interactive
				arrow
				title={
					<div style={{ whiteSpace: 'pre-line' }}>
						<Typography variant='body1' color='textPrimary'>
							Transactions performed externally through Insurance Companies'
							e-portal.
						</Typography>
						<Typography
							style={{ marginTop: 16 }}
							variant='body1'
							color='textPrimary'
						>
							Toggling this will create a transaction record for you to log
							information from your external efforts on to Uuoni.
						</Typography>
					</div>
				}
			>
				<u>
					<b>outside of Uuoni</b>
				</u>
			</Tooltip>
		</Typography>
	);
};

const printSelfGeneratedString = (haveDocuments) => {
	return (
		<Typography variant='body1' color='textPrimary'>
			Select if quotes are{' '}
			<Tooltip
				placement='top'
				interactive
				arrow
				title={
					<div style={{ whiteSpace: 'pre-line' }}>
						<Typography variant='body1' color='textPrimary'>
							Insurance that can be purchased online via Insurers' Portal (e.g.
							Personal Motor Vehicle Insurance)
						</Typography>
					</div>
				}
			>
				<u>
					<b>Self-Generated</b>
				</u>
			</Tooltip>
		</Typography>
	);
};

export default function InitTransaction(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { type, clientId, formData, handleFormDataChange, errorMessages } =
		props;
	const [insuranceTypes, setInsuranceTypes] = useState(InsuranceTypes);
	const [selectedInsuranceTypes, setSelectedInsuranceTypes] = useState([]);
	const [val, setVal] = useState(null);
	const [inputVal, setInputVal] = useState('');
	const [transactions, setTransactions] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState(false);

	const selectedClient = type === 'company' && clientId ? true : false;

	const getTransactionData = async () => {
		if (!loading) {
			setLoading(true);
		}
		try {
			if (type && clientId) {
				const response = await api.get(
					`transactions/?filter=Completed&clientType=${type}&clientId=${clientId}`
				);
				setTransactions(response.data);
			} else {
				const response = await api.get('transactions/?filter=Completed');
				setTransactions(response.data);
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (formData.source === 'Internal') {
			getTransactionData();
		}
	}, [formData.source]);

	useEffect(() => {
		setInsuranceTypes(
			insuranceTypes.sort((a, b) =>
				a.name === 'Other Insurance'
					? 1
					: b.name === 'Other Insurance'
					? -1
					: a.name > b.name
					? 1
					: b.name > a.name
					? -1
					: 0
			)
		);
	}, [insuranceTypes]);

	const handleAddInsuranceType = (option) => {
		if (option === null) return;
		let insuranceSubType = '';

		if (option.subTypes.length > 0) {
			insuranceSubType = option.subTypes[0].name;
		}

		let selectedInsuranceType = {
			...formData.selectedInsuranceType,
			insuranceType: option.name,
			insuranceSubType: insuranceSubType,
		};

		handleFormDataChange('selectedInsuranceType', selectedInsuranceType);
		setVal(option);
	};

	const handleInsuranceSubTypeChange = (e, index) => {
		const { name, value, checked } = e.target;
		let updatedForm = formData.selectedInsuranceType;
		if (name === 'isCommercial') {
			updatedForm['isCommercial'] = checked;
		} else {
			updatedForm['insuranceSubType'] = value;
		}
		handleFormDataChange('selectedInsuranceType', updatedForm);
	};

	const handleInsuranceSubTypeSelection = (e, index) => {
		const { name, value } = e.target;
		let updatedForm = formData.selectedInsuranceType;
		let selectedSubType = InsuranceTypes.find(
			(type) => type.name === updatedForm['insuranceType']
		).subTypes.find((subType) => subType.name === value);

		updatedForm['insuranceSubType'] = value;

		handleFormDataChange('selectedInsuranceType', updatedForm);
	};

	const handleIsDirectPurchaseChange = (e) => {
		handleFormDataChange('isDirectPurchase', e.target.checked);
	};

	const handleIsSelfGeneratedChange = (e) => {
		handleFormDataChange('isSelfGenerated', e.target.checked);
	};

	const handleIsCommercialChange = (val) => {
		let updatedForm = formData.selectedInsuranceType;
		updatedForm['isCommercial'] = val;
		handleFormDataChange('selectedInsuranceType', updatedForm);
	};

	const handleHaveDocumentsChange = (e) => {
		let val = str2bool(e.target.value);
		handleFormDataChange('haveDocuments', val);
	};

	const handleTransactionTypeChange = (val) => {
		handleFormDataChange('transactionType', val);
		setInsuranceTypes(InsuranceTypes);
		handleFormDataChange('selectedInsuranceType', {
			insuranceType: '',
			insuranceSubType: '',
			isCommercial: false,
		});
		handleFormDataChange('selectedTransaction', null);
		setVal(null);
	};

	const handleSelectTransaction = (e, transaction) => {
		handleFormDataChange('selectedTransaction', transaction);
	};

	const searchData = (data) => {
		if (searchValue) {
			let val = searchValue.replace(/\s+/g, ' ').toLowerCase();
			let results = data.filter((item) => {
				return Object.keys(item).some((key) => {
					if (key === 'transactionNo') {
						return String(`TXN-${item[key]}`)
							.toLowerCase()
							.includes(val.toLowerCase());
					} else if (key === 'client') {
						return Object.keys(item[key]).some((subKey) => {
							return (
								typeof item[key][subKey] === 'string' &&
								item[key][subKey].toLowerCase().includes(val.toLowerCase())
							);
						});
					} else if (key === 'policyData') {
						return Object.keys(item[key]).some((subKey) => {
							return (
								typeof item[key][subKey] === 'string' &&
								item[key][subKey].toLowerCase().includes(val.toLowerCase())
							);
						});
					} else {
						return (
							typeof item[key] === 'string' &&
							item[key].toLowerCase().includes(val.toLowerCase())
						);
					}
				});
			});
			return results;
		}
		return data;
	};

	const filteredTransactions = searchData(transactions);

	const transactionList = () => {
		if (loading) {
			return [...Array(4)].map((_, i) => (
				<DataRow key={i} data={i} loading={loading} />
			));
		}
		if (!filteredTransactions.length && !loading) {
			return (
				<tr
					style={{
						height: 213,
					}}
				>
					<td
						colSpan={4}
						className='noResults'
						style={{
							textAlign: 'center',
							border: '1px solid #edf2f7',
						}}
					>
						No Transactions Found
					</td>
				</tr>
			);
		}
		return filteredTransactions
			.sort((a, b) => {
				return a.transactionNo - b.transactionNo;
			})
			.map((currentTransaction) => (
				<DataRow
					key={currentTransaction._id}
					transactionType={formData.transactionType}
					data={currentTransaction}
					handleSelectTransaction={handleSelectTransaction}
					selected={
						currentTransaction._id === formData.selectedTransaction?._id
					}
				/>
			));
	};

	return (
		<>
			<div className={classes.root}>
				<Divider style={{ width: '100%' }} />
				<div style={{ display: 'flex', height: '100%', flexGrow: 1 }}>
					<div id='transaction-types' className={classes.cardContainer}>
						<Card
							className={classes.card}
							style={{
								boxShadow:
									formData.transactionType === 'Claims' &&
									`0px 0px 0px 2px ${theme.palette.tertiary.main}`,
								backgroundColor:
									formData.transactionType === 'Claims' &&
									theme.palette.tertiary.hover,
							}}
						>
							<CardActionArea
								className={classes.card}
								onClick={() => handleTransactionTypeChange('Claims')}
							>
								<CardContent
									className={classes.cardContent}
									classes={{ root: classes.cardContentRoot }}
								>
									<div className={classes.cover}>
										<Claim />
									</div>
									<div className={classes.cardText}>
										<Typography
											style={{
												color:
													formData.transactionType === 'Claims' &&
													theme.palette.tertiary.main,
											}}
											variant='h4'
										>
											Claim
										</Typography>
									</div>
								</CardContent>
							</CardActionArea>
						</Card>
						<Card
							className={classes.card}
							style={{
								boxShadow:
									formData.transactionType === 'Renewal' &&
									`0px 0px 0px 2px ${theme.palette.tertiary.main}`,
								backgroundColor:
									formData.transactionType === 'Renewal' &&
									theme.palette.tertiary.hover,
							}}
						>
							<CardActionArea
								className={classes.card}
								onClick={() => handleTransactionTypeChange('Renewal')}
							>
								<CardContent
									className={classes.cardContent}
									classes={{ root: classes.cardContentRoot }}
								>
									<div className={classes.cover}>
										<Renewal />
									</div>
									<div className={classes.cardText}>
										<Typography
											style={{
												color:
													formData.transactionType === 'Renewal' &&
													theme.palette.tertiary.main,
											}}
											variant='h4'
										>
											Renewal
										</Typography>
									</div>
								</CardContent>
							</CardActionArea>
						</Card>
						<Card
							className={classes.card}
							style={{
								boxShadow:
									formData.transactionType === 'Quotation' &&
									`0px 0px 0px 2px ${theme.palette.tertiary.main}`,
								backgroundColor:
									formData.transactionType === 'Quotation' &&
									theme.palette.tertiary.hover,
							}}
						>
							<CardActionArea
								className={classes.card}
								onClick={() => handleTransactionTypeChange('Quotation')}
							>
								<CardContent
									className={classes.cardContent}
									classes={{ root: classes.cardContentRoot }}
								>
									<div className={classes.cover}>
										<Quotation />
									</div>
									<div className={classes.cardText}>
										<Typography
											style={{
												color:
													formData.transactionType === 'Quotation' &&
													theme.palette.tertiary.main,
											}}
											variant='h4'
										>
											Quotation
										</Typography>
									</div>
								</CardContent>
							</CardActionArea>
						</Card>
					</div>
					<Divider
						orientation='vertical'
						flexItem
						style={{ marginBottom: -64 }}
					/>
					<div id='info-section' className={classes.contentContainer}>
						{formData.transactionType === 'Renewal' ||
						formData.transactionType === 'Claims' ? (
							<>
								<div id='transaction-source' className={classes.infoContainer}>
									<div
										style={{
											display: 'flex',
											alignItems: 'baseline',
										}}
									>
										<div className={classes.iconNumber}>
											<p style={{ margin: 'auto' }}>1</p>
										</div>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: '100%',
											}}
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Typography
													style={{ marginRight: 8 }}
													color='textPrimary'
													variant='h3'
												>
													SELECT A TRANSACTION TO{' '}
													{formData.transactionType === 'Renewal'
														? 'RENEW'
														: 'CLAIM'}
												</Typography>
												<CustomTooltip
													placement='right'
													content={printSourceString(formData.transactionType)}
												/>
											</div>
											{/* <div
												style={{
													display: 'flex',
													alignItems: 'center',
													marginTop: 16,
												}}
											>
												<FormControl component='fieldset'>
													<RadioGroup
														style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
														aria-label='source'
														name='source'
														value={formData.source}
														onChange={(e) =>
															handleFormDataChange('source', e.target.value)
														}
													>
														<FormControlLabel
															style={{ marginRight: 32 }}
															value={'Internal'}
															control={<Radio color='primary' />}
															label={
																<Typography
																	style={{
																		color:
																			formData.source !== 'External' &&
																			theme.palette.tertiary.main,
																	}}
																	variant='body1'
																>
																	Existing
																</Typography>
															}
														/>
														<FormControlLabel
															style={{ marginRight: 32 }}
															value={'External'}
															control={<Radio color='primary' />}
															label={
																<Typography
																	style={{
																		color:
																			formData.source === 'External' &&
																			theme.palette.tertiary.main,
																	}}
																	variant='body1'
																>
																	External
																</Typography>
															}
														/>
													</RadioGroup>
												</FormControl>
											</div> */}
											{formData.source !== 'External' ? (
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														marginTop: 16,
													}}
												>
													<TextField
														style={{ marginBottom: 24 }}
														name='searchValue'
														value={searchValue}
														onChange={(e) => setSearchValue(e.target.value)}
														placeholder='Search for a transaction'
														variant='filled'
														fullWidth
														margin='dense'
														InputProps={{
															startAdornment: (
																<InputAdornment
																	position='start'
																	style={{
																		marginTop: 0,
																		color: theme.palette.grey[500],
																	}}
																>
																	<SearchIcon />
																</InputAdornment>
															),
															endAdornment: (
																<InputAdornment position='end'>
																	<IconButton
																		onClick={() => setSearchValue('')}
																		disableFocusRipple
																		disableRipple
																		style={{
																			backgroundColor: 'transparent',
																			display: !searchValue && 'none',
																		}}
																	>
																		<ClearIcon />
																	</IconButton>
																</InputAdornment>
															),
														}}
													/>
													<TableContainer
														style={{
															minHeight: 265,
															maxHeight: 265,
															minWidth: '100%',
															width: '100%',
															boxShadow: '0px 1px 2px 0px #0000001a',
															borderRadius: 8,
														}}
													>
														<Table stickyHeader>
															<TableHead>
																<TableRow>
																	<TableCell
																		style={{
																			height: 52,
																			background:
																				theme.palette.background.paper,
																			padding: '0px 0px 0px 24px',
																		}}
																	>
																		<Typography
																			color='textPrimary'
																			variant='h5'
																		>
																			TRANSACTION
																		</Typography>
																	</TableCell>
																	{formData.transactionType === 'Claims' ? (
																		<TableCell
																			style={{
																				height: 52,
																				background:
																					theme.palette.background.paper,
																				padding: 0,
																			}}
																		>
																			<Typography
																				color='textPrimary'
																				variant='h5'
																			>
																				PERIOD OF INSURANCE
																			</Typography>
																		</TableCell>
																	) : (
																		<>
																			<TableCell
																				style={{
																					height: 52,
																					background:
																						theme.palette.background.paper,
																					padding: 0,
																				}}
																			>
																				<Typography
																					color='textPrimary'
																					variant='h5'
																				>
																					EXPIRY DATE
																				</Typography>
																			</TableCell>
																			<TableCell
																				style={{
																					height: 52,
																					background:
																						theme.palette.background.paper,
																					padding: 0,
																				}}
																			>
																				<Typography
																					color='textPrimary'
																					variant='h5'
																				>
																					DAYS TO EXPIRY
																				</Typography>
																			</TableCell>
																		</>
																	)}
																	<TableCell
																		style={{
																			height: 52,
																			background:
																				theme.palette.background.paper,
																			padding: 0,
																		}}
																	></TableCell>
																</TableRow>
															</TableHead>
															<TableBody>{transactionList()}</TableBody>
														</Table>
													</TableContainer>
													{errorMessages[`selectedTransaction`] && (
														<Typography
															color='error'
															variant='caption'
															style={{ marginTop: 16 }}
														>
															{errorMessages[`selectedTransaction`]}
														</Typography>
													)}
													{formData.transactionType !== 'Claims' && (
														<div
															style={{
																background: theme.palette.background.paper,
																padding: '8px 16px',
																borderRadius: 8,
																marginTop: 16,
																width: 'fit-content',
															}}
														>
															<CustomSwitch
																state={formData.isSelfGenerated}
																setState={handleIsSelfGeneratedChange}
																label={printSelfGeneratedString(
																	formData.haveDocuments
																)}
																disabled={formData.isDirectPurchase}
															/>
														</div>
													)}
												</div>
											) : (
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														marginTop: 16,
													}}
												>
													<Typography color='textPrimary' variant='body2'>
														Are you transacting for?
													</Typography>
													<div
														style={{
															display: 'flex',
															marginTop: 24,
														}}
													>
														<Button
															className={classes.methodButton}
															classes={{
																label: classes.methodButtonLabel,
																startIcon: classes.methodButtonIcon,
															}}
															style={{ marginRight: 48 }}
															variant='outlined'
															color={
																!formData.selectedInsuranceType.isCommercial
																	? 'primary'
																	: 'default'
															}
															disabled={selectedClient}
															onClick={() => handleIsCommercialChange(false)}
														>
															<Badge
																className={classes.badge}
																classes={{
																	anchorOriginTopRightRectangular:
																		classes.offset,
																	colorPrimary: classes.badgeIcon,
																}}
																badgeContent={
																	!formData.selectedInsuranceType
																		.isCommercial ? (
																		<CheckCircleIcon
																			style={{ height: 24, width: 24 }}
																		/>
																	) : null
																}
																color='primary'
																overlap='rectangular'
																anchorOrigin={{
																	vertical: 'top',
																	horizontal: 'right',
																}}
															>
																<Contacts
																	style={{ fontSize: 24 }}
																	colorcode={
																		!formData.selectedInsuranceType.isCommercial
																			? theme.palette.tertiary.main
																			: undefined
																	}
																/>
																<Typography variant='body1'>Contact</Typography>
															</Badge>
														</Button>
														<Button
															className={classes.methodButton}
															classes={{
																label: classes.methodButtonLabel,
																startIcon: classes.methodButtonIcon,
															}}
															variant='outlined'
															color={
																formData.selectedInsuranceType.isCommercial
																	? 'primary'
																	: 'default'
															}
															disabled={selectedClient}
															onClick={() => handleIsCommercialChange(true)}
														>
															<Badge
																className={classes.badge}
																classes={{
																	anchorOriginTopRightRectangular:
																		classes.offset,
																	colorPrimary: classes.badgeIcon,
																}}
																badgeContent={
																	formData.selectedInsuranceType
																		.isCommercial ? (
																		<CheckCircleIcon
																			style={{ height: 24, width: 24 }}
																		/>
																	) : null
																}
																color='primary'
																overlap='rectangular'
																anchorOrigin={{
																	vertical: 'top',
																	horizontal: 'right',
																}}
															>
																<Company
																	style={{ fontSize: 24 }}
																	colorcode={
																		formData.selectedInsuranceType.isCommercial
																			? theme.palette.tertiary.main
																			: undefined
																	}
																/>
																<Typography variant='body1'>Company</Typography>
															</Badge>
														</Button>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
								<div
									id='radio-info'
									className={classes.infoContainer}
									style={{ paddingTop: 0 }}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'baseline',
										}}
									>
										<div className={classes.iconNumber}>
											<p style={{ margin: 'auto' }}>2</p>
										</div>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Typography
													style={{ marginRight: 8 }}
													color='textPrimary'
													variant='h3'
												>
													CLIENT'S DETAIL & DOCUMENTS
												</Typography>
												<CustomTooltip
													content={
														<Typography variant='body1' color='textPrimary'>
															Questions selection will determine a specific,
															standard workflow
														</Typography>
													}
												/>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'baseline',
													marginTop: 16,
												}}
											>
												<Typography variant='body1' color='textPrimary'>
													Do you have all of your clients' details and documents
													on hand?
												</Typography>
												<FormControl component='fieldset'>
													<RadioGroup
														style={{
															flexDirection: 'column',
															flexWrap: 'nowrap',
														}}
														aria-label='haveDocuments'
														name='haveDocuments'
														value={formData.haveDocuments}
														onChange={handleHaveDocumentsChange}
													>
														<FormControlLabel
															value={true}
															control={<Radio color='primary' />}
															label={
																<Typography
																	style={{
																		color:
																			formData.haveDocuments &&
																			theme.palette.tertiary.main,
																	}}
																	variant='body1'
																>
																	Yes
																</Typography>
															}
														/>
														<FormControlLabel
															value={false}
															control={<Radio color='primary' />}
															label={
																<Typography
																	style={{
																		color:
																			!formData.haveDocuments &&
																			theme.palette.tertiary.main,
																	}}
																	variant='body1'
																>
																	No
																</Typography>
															}
														/>
													</RadioGroup>
												</FormControl>
											</div>
											{/* {formData.transactionType !== 'Claims' && ( */}
											<div
												style={{
													background: theme.palette.background.paper,
													padding: '8px 16px',
													borderRadius: 8,
													marginTop: 16,
													width: 'fit-content',
												}}
											>
												<CustomSwitch
													state={formData.isDirectPurchase}
													setState={handleIsDirectPurchaseChange}
													label={printDPString(formData.haveDocuments)}
													disabled={formData.isSelfGenerated}
												/>
											</div>
											{/* )} */}
										</div>
									</div>
								</div>
								{formData.source === 'External' && (
									<div
										id='insurance-info'
										className={classes.infoContainer}
										style={{ paddingTop: 0 }}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'baseline',
											}}
										>
											<div className={classes.iconNumber}>
												<p style={{ margin: 'auto' }}>3</p>
											</div>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Typography color='textPrimary' variant='h3'>
													SELECT INSURANCE
												</Typography>
												<div
													style={{
														display: 'flex',
														marginTop: 32,
													}}
												>
													<Autocomplete
														style={{ width: 480 }}
														value={val}
														onChange={(event, newValue) => {
															handleAddInsuranceType(newValue);
														}}
														id='select-insurance'
														options={insuranceTypes}
														noOptionsText=''
														getOptionSelected={(option, value) =>
															option.name === value.name
														}
														getOptionLabel={(option) => option.name || ''}
														renderInput={(params) => (
															<TextField
																{...params}
																variant='filled'
																placeholder='Search or Select an Insurance'
																error={!!errorMessages[`selectedInsuranceType`]}
																helperText={
																	errorMessages[`selectedInsuranceType`] &&
																	errorMessages[`selectedInsuranceType`]
																}
															/>
														)}
													/>
													{!isEmpty(
														formData.selectedInsuranceType.insuranceType
													) && (
														<>
															{formData.selectedInsuranceType.insuranceType ===
															'Other Insurance' ? (
																<>
																	<TextField
																		style={{
																			width: 330,
																			marginLeft: 16,
																			marginTop: 0,
																			marginBottom: 0,
																		}}
																		name='insuranceSubType'
																		value={
																			formData.selectedInsuranceType
																				.insuranceSubType
																		}
																		onChange={(e) =>
																			handleInsuranceSubTypeChange(e)
																		}
																		placeholder='Please Specify the Insurance'
																		variant='filled'
																		margin='dense'
																		error={!!errorMessages[`insuranceSubType`]}
																		helperText={
																			errorMessages[`insuranceSubType`] &&
																			errorMessages[`insuranceSubType`]
																		}
																	/>
																</>
															) : (
																<>
																	{InsuranceTypes.find(
																		(type) =>
																			type.name ===
																			formData.selectedInsuranceType
																				.insuranceType
																	).subTypes.length > 0 && (
																		<Select
																			style={{
																				width: 330,
																				marginLeft: 16,
																			}}
																			native
																			name='insuranceSubType'
																			value={
																				formData.selectedInsuranceType
																					.insuranceSubType
																			}
																			inputProps={{
																				style: { color: 'unset' },
																			}}
																			onChange={(e) =>
																				handleInsuranceSubTypeSelection(e)
																			}
																			variant='filled'
																			margin='dense'
																		>
																			{InsuranceTypes.find(
																				(type) =>
																					type.name ===
																					formData.selectedInsuranceType
																						.insuranceType
																			).subTypes.map((subType, index) => {
																				return (
																					<option
																						key={index}
																						value={subType.name}
																					>
																						{subType.name}
																					</option>
																				);
																			})}
																		</Select>
																	)}
																</>
															)}
														</>
													)}
												</div>
												{formData.transactionType !== 'Claims' && (
													<div
														style={{
															background: theme.palette.background.paper,
															padding: '8px 16px',
															borderRadius: 8,
															marginTop: 16,
															width: 'fit-content',
														}}
													>
														<CustomSwitch
															state={formData.isSelfGenerated}
															setState={handleIsSelfGeneratedChange}
															label={printSelfGeneratedString(
																formData.haveDocuments
															)}
															disabled={formData.isDirectPurchase}
														/>
													</div>
												)}
											</div>
										</div>
									</div>
								)}
							</>
						) : (
							<>
								<div id='radio-info' className={classes.infoContainer}>
									<div
										style={{
											display: 'flex',
											alignItems: 'baseline',
										}}
									>
										<div className={classes.iconNumber}>
											<p style={{ margin: 'auto' }}>1</p>
										</div>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Typography
													style={{ marginRight: 8 }}
													color='textPrimary'
													variant='h3'
												>
													TRANSACTING FOR?
												</Typography>
											</div>
											<div
												style={{
													display: 'flex',
													marginTop: 24,
												}}
											>
												<Button
													className={classes.methodButton}
													classes={{
														label: classes.methodButtonLabel,
														startIcon: classes.methodButtonIcon,
													}}
													style={{ marginRight: 48 }}
													variant='outlined'
													color={
														!formData.selectedInsuranceType.isCommercial
															? 'primary'
															: 'default'
													}
													disabled={selectedClient}
													onClick={() => handleIsCommercialChange(false)}
												>
													<Badge
														className={classes.badge}
														classes={{
															anchorOriginTopRightRectangular: classes.offset,
															colorPrimary: classes.badgeIcon,
														}}
														badgeContent={
															!formData.selectedInsuranceType.isCommercial ? (
																<CheckCircleIcon
																	style={{ height: 24, width: 24 }}
																/>
															) : null
														}
														color='primary'
														overlap='rectangular'
														anchorOrigin={{
															vertical: 'top',
															horizontal: 'right',
														}}
													>
														<Contacts
															style={{ fontSize: 24 }}
															colorcode={
																!formData.selectedInsuranceType.isCommercial
																	? theme.palette.tertiary.main
																	: undefined
															}
														/>
														<Typography variant='body1'>Contact</Typography>
													</Badge>
												</Button>
												<Button
													className={classes.methodButton}
													classes={{
														label: classes.methodButtonLabel,
														startIcon: classes.methodButtonIcon,
													}}
													variant='outlined'
													color={
														formData.selectedInsuranceType.isCommercial
															? 'primary'
															: 'default'
													}
													disabled={selectedClient}
													onClick={() => handleIsCommercialChange(true)}
												>
													<Badge
														className={classes.badge}
														classes={{
															anchorOriginTopRightRectangular: classes.offset,
															colorPrimary: classes.badgeIcon,
														}}
														badgeContent={
															formData.selectedInsuranceType.isCommercial ? (
																<CheckCircleIcon
																	style={{ height: 24, width: 24 }}
																/>
															) : null
														}
														color='primary'
														overlap='rectangular'
														anchorOrigin={{
															vertical: 'top',
															horizontal: 'right',
														}}
													>
														<Company
															style={{ fontSize: 24 }}
															colorcode={
																formData.selectedInsuranceType.isCommercial
																	? theme.palette.tertiary.main
																	: undefined
															}
														/>
														<Typography variant='body1'>Company</Typography>
													</Badge>
												</Button>
											</div>
										</div>
									</div>
								</div>
								<div
									id='radio-info'
									className={classes.infoContainer}
									style={{ paddingTop: 0 }}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'baseline',
										}}
									>
										<div className={classes.iconNumber}>
											<p style={{ margin: 'auto' }}>2</p>
										</div>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Typography
													style={{ marginRight: 8 }}
													color='textPrimary'
													variant='h3'
												>
													CLIENT'S DETAIL & DOCUMENTS
												</Typography>
												<CustomTooltip
													content={
														<Typography variant='body1' color='textPrimary'>
															Questions selection will determine a specific,
															standard workflow
														</Typography>
													}
												/>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'baseline',
													marginTop: 16,
												}}
											>
												<Typography variant='body1' color='textPrimary'>
													Do you have all of your clients' details and documents
													on hand?
												</Typography>
												<FormControl component='fieldset'>
													<RadioGroup
														style={{
															flexDirection: 'column',
															flexWrap: 'nowrap',
														}}
														aria-label='haveDocuments'
														name='haveDocuments'
														value={formData.haveDocuments}
														onChange={handleHaveDocumentsChange}
													>
														<FormControlLabel
															value={true}
															control={<Radio color='primary' />}
															label={
																<Typography
																	style={{
																		color:
																			formData.haveDocuments &&
																			theme.palette.tertiary.main,
																	}}
																	variant='body1'
																>
																	Yes
																</Typography>
															}
														/>
														<FormControlLabel
															value={false}
															control={<Radio color='primary' />}
															label={
																<Typography
																	style={{
																		color:
																			!formData.haveDocuments &&
																			theme.palette.tertiary.main,
																	}}
																	variant='body1'
																>
																	No
																</Typography>
															}
														/>
													</RadioGroup>
												</FormControl>
											</div>
											{/* {formData.transactionType !== 'Claims' && ( */}
											<div
												style={{
													background: theme.palette.background.paper,
													padding: '8px 16px',
													borderRadius: 8,
													marginTop: 16,
													width: 'fit-content',
												}}
											>
												<CustomSwitch
													state={formData.isDirectPurchase}
													setState={handleIsDirectPurchaseChange}
													label={printDPString(formData.haveDocuments)}
													disabled={formData.isSelfGenerated}
												/>
											</div>
											{/* )} */}
										</div>
									</div>
								</div>
								<div
									id='insurance-info'
									className={classes.infoContainer}
									style={{ paddingTop: 0 }}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'baseline',
										}}
									>
										<div className={classes.iconNumber}>
											<p style={{ margin: 'auto' }}>3</p>
										</div>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<Typography color='textPrimary' variant='h3'>
												SELECT INSURANCE
											</Typography>
											<div
												style={{
													display: 'flex',
													marginTop: 32,
												}}
											>
												<Autocomplete
													style={{ width: 480 }}
													value={val}
													onChange={(event, newValue) => {
														handleAddInsuranceType(newValue);
													}}
													id='select-insurance'
													options={insuranceTypes}
													noOptionsText=''
													getOptionSelected={(option, value) =>
														option.name === value.name
													}
													getOptionLabel={(option) => option.name || ''}
													renderInput={(params) => (
														<TextField
															{...params}
															variant='filled'
															placeholder='Search or Select an Insurance'
															error={!!errorMessages[`selectedInsuranceType`]}
															helperText={
																errorMessages[`selectedInsuranceType`] &&
																errorMessages[`selectedInsuranceType`]
															}
														/>
													)}
												/>
												{!isEmpty(
													formData.selectedInsuranceType.insuranceType
												) && (
													<>
														{formData.selectedInsuranceType.insuranceType ===
														'Other Insurance' ? (
															<>
																<TextField
																	style={{
																		width: 330,
																		marginLeft: 16,
																		marginTop: 0,
																		marginBottom: 0,
																	}}
																	name='insuranceSubType'
																	value={
																		formData.selectedInsuranceType
																			.insuranceSubType
																	}
																	onChange={(e) =>
																		handleInsuranceSubTypeChange(e)
																	}
																	placeholder='Please Specify the Insurance'
																	variant='filled'
																	margin='dense'
																	error={!!errorMessages[`insuranceSubType`]}
																	helperText={
																		errorMessages[`insuranceSubType`] &&
																		errorMessages[`insuranceSubType`]
																	}
																/>
															</>
														) : (
															<>
																{InsuranceTypes.find(
																	(type) =>
																		type.name ===
																		formData.selectedInsuranceType.insuranceType
																).subTypes.length > 0 && (
																	<Select
																		style={{
																			width: 330,
																			marginLeft: 16,
																		}}
																		native
																		name='insuranceSubType'
																		value={
																			formData.selectedInsuranceType
																				.insuranceSubType
																		}
																		inputProps={{
																			style: { color: 'unset' },
																		}}
																		onChange={(e) =>
																			handleInsuranceSubTypeSelection(e)
																		}
																		variant='filled'
																		margin='dense'
																	>
																		{InsuranceTypes.find(
																			(type) =>
																				type.name ===
																				formData.selectedInsuranceType
																					.insuranceType
																		).subTypes.map((subType, index) => {
																			return (
																				<option
																					key={index}
																					value={subType.name}
																				>
																					{subType.name}
																				</option>
																			);
																		})}
																	</Select>
																)}
															</>
														)}
													</>
												)}
											</div>
											{formData.transactionType !== 'Claims' && (
												<div
													style={{
														background: theme.palette.background.paper,
														padding: '8px 16px',
														borderRadius: 8,
														marginTop: 16,
														width: 'fit-content',
													}}
												>
													<CustomSwitch
														state={formData.isSelfGenerated}
														setState={handleIsSelfGeneratedChange}
														label={printSelfGeneratedString(
															formData.haveDocuments
														)}
														disabled={formData.isDirectPurchase}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
