import { Card } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DetailsViewer from './DetailsViewer';
import DocumentViewer from './DocumentViewer';
import SummaryViewer from './SummaryViewer';
import { Details, Documents, Summary } from '../../../../components/Icons';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	tab: {
		height: 58,
		minWidth: 10,
		padding: 16,
		'&:first-child': {
			paddingLeft: 24,
		},
	},
	tabContent: {
		overflowY: 'auto',
		overflowX: 'hidden',
		height: 'calc(100% - 58px)',
	},
	content: {
		backgroundColor: 'unset',
		borderRadius: 8,
		boxShadow: '0px 4px 12px rgb(0 0 0 / 16%), 0px 0px 4px rgb(0 0 0 / 16%)',
		marginBottom: 24,
		display: 'inline-block',
		width: '100%',
		minHeight: '100%',
	},
}));

export default function Viewers(props) {
	const {
		transaction,
		setTransaction,
		setTriggerUpdate,
		setTriggerUpdateActivities,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Card className={classes.content} style={{ height: value !== 1 && '100%' }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					height: 58,
				}}
			>
				<Tabs
					value={value}
					onChange={handleChangeTab}
					variant='scrollable'
					aria-label='full width tabs example'
				>
					<Tab
						className={classes.tab}
						label={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Summary
									style={{ marginRight: 8, fontSize: 24 }}
									colorcode={
										value === 0
											? theme.palette.tertiary.main
											: theme.palette.grey[500]
									}
								/>
								<span style={{ fontSize: '1.25rem' }}>SUMMARY</span>
							</div>
						}
						{...a11yProps(0)}
					/>
					<Tab
						className={classes.tab}
						label={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Details
									style={{ marginRight: 8, fontSize: 24 }}
									colorcode={
										value === 1
											? theme.palette.tertiary.main
											: theme.palette.grey[500]
									}
								/>
								<span style={{ fontSize: '1.25rem' }}>DETAILS</span>
							</div>
						}
						{...a11yProps(1)}
					/>
					<Tab
						className={classes.tab}
						label={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Documents
									style={{ marginRight: 8, fontSize: 24 }}
									colorcode={
										value === 2
											? theme.palette.tertiary.main
											: theme.palette.grey[500]
									}
								/>
								<span style={{ fontSize: '1.25rem' }}>DOCUMENTS</span>
							</div>
						}
						{...a11yProps(2)}
					/>
				</Tabs>
			</div>
			<Divider />
			<TabPanel
				className={classes.tabContent}
				value={value}
				index={0}
				dir={theme.direction}
			>
				<SummaryViewer
					transaction={transaction}
					setTransaction={setTransaction}
					setTriggerUpdate={setTriggerUpdate}
				/>
			</TabPanel>
			<TabPanel
				className={classes.tabContent}
				value={value}
				index={1}
				dir={theme.direction}
			>
				<DetailsViewer
					transaction={transaction}
					setTransaction={setTransaction}
					setTriggerUpdate={setTriggerUpdate}
					setTriggerUpdateActivities={setTriggerUpdateActivities}
				/>
			</TabPanel>
			<TabPanel
				className={classes.tabContent}
				value={value}
				index={2}
				dir={theme.direction}
			>
				<DocumentViewer
					transactionId={transaction._id}
					transactionType={transaction.transactionType}
					supportingDocuments={transaction.documents || []}
					policyDocuments={transaction.policyDocuments || []}
					claimDocuments={transaction.claimDocuments || []}
					setTriggerUpdate={setTriggerUpdate}
				/>
			</TabPanel>
		</Card>
	);
}
