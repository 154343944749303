import DateFnsUtils from '@date-io/date-fns';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '../../../components/Backdrop';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tooltip from '../../../components/Tooltip';
import FileUploader from '../../../components/FileUploader';
import SuccessMessage from '../../../components/SuccessMessage';
import api from '../../../config/apiPublic';
import useStyles from '../styles';
import Loader from '../../../components/Loader';
import { Quote as QuoteRequestSchema } from '../../../utils/validators/schemas';

const rejReasonList = ['No suitable product available', 'High Risk', 'Other'];

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

const initFormData = {
	accepted: true,
	expiryDate: null,
	startDate: null,
	endDate: null,
	premiumAmt: null,
	sumInsured: null,
	notes: '',
	rejReason: rejReasonList[0],
	rejRemarks: '',
};

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

export default function QuoteRequest(props) {
	const { id } = useParams();
	const classes = useStyles();
	const theme = useTheme();
	const [quoteRequest, setQuoteRequest] = useState(null);
	const [formData, setFormData] = useState(initFormData);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(true);

	const getData = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get(`external/requests/quoteRequest/${id}`)
			.then((res) => {
				setQuoteRequest(res.data.data);
				if (res.data.data.responseData) {
					setFormData(res.data.data.responseData);
				}
				setLoading(false);
			})
			.catch(function (error) {
				setLoading(false);
				console.log(error);
			});
	};

	useEffect(() => {
		getData();
	}, []);

	const handleDecline = (e) => {
		setFormData((prev) => ({
			...prev,
			accepted: false,
		}));
	};

	const handleCancel = (e) => {
		setFormData((prev) => ({
			...prev,
			accepted: true,
		}));
	};

	const handleInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;

		setFormData((prev) => ({
			...prev,
			[name]: type === 'number' && value !== '' ? parseFloat(value) : value,
		}));
	};

	const handleDateChange = (target, date) => {
		setFormData((prev) => ({
			...prev,
			[target]: date,
		}));
	};

	const handleFileChange = (e, files) => {
		e.preventDefault();
		setFormData((prev) => ({
			...prev,
			fileData: files,
		}));
	};

	const handleValidation = () => {
		let hasFile = formData.accepted ? !!formData.fileData : true;
		let formattedFormData;

		if (formData.accepted) {
			formattedFormData = {
				accepted: formData.accepted,
				expiryDate: new Date(formData.expiryDate),
				startDate: new Date(formData.startDate),
				endDate: new Date(formData.endDate),
				premiumAmt: formData.premiumAmt,
				sumInsured: formData.sumInsured,
				notes: formData.notes,
			};
		} else {
			formattedFormData = {
				accepted: formData.accepted,
				rejReason: formData.rejReason,
				rejRemarks: formData.rejRemarks,
			};
		}

		let result = QuoteRequestSchema.validate(formattedFormData, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		if (!error && hasFile) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			if (error?.details !== undefined) {
				console.log(error.details);
				for (let item of error.details) {
					const key = item.path.join('.');
					const message = item.message;
					console.log(key);
					console.log(message);
					errorData[key] = message;
				}
			}
			if (!hasFile) {
				errorData['quoteDocument'] = 'Quotation Document is required';
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (open) return;
		setOpen(true);

		let valid = handleValidation();

		if (valid) {
			const data = new FormData();
			let json = cloneDeep(formData);

			if (formData.accepted) {
				data.append(
					'Quotation Document',
					formData.fileData[0],
					formData.fileData[0].name
				);
				delete json.fileData;
			}

			console.log(JSON.stringify(json, null, 4));
			data.append('json', JSON.stringify(json));
			try {
				api.put(`external/requests/quoteRequest/${id}`, data).then((res) => {
					if (res.data.ok) {
						setErrorMessages({});
						setSuccess(true);
						setOpen(false);
					} else {
						setSuccess(false);
						toast.error(res.data.error);
						setOpen(false);
					}
				});
			} catch (error) {
				console.log(error);
				setSuccess(false);
				toast.error(error);
				setOpen(false);
			}
		} else {
			toast.error('An error has occured!');
			setOpen(false);
		}
	};

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	const getHeader = () => {
		return (
			<>
				Quotation Request - {quoteRequest.insuranceType}
				{quoteRequest.insuranceSubType && (
					<> ({quoteRequest.insuranceSubType})</>
				)}{' '}
				- {quoteRequest.client.contactName}
			</>
		);
	};

	if (loading) return <Loader size='lg' />;
	if (!loading && !quoteRequest)
		return (
			<>
				<AppBar position='fixed' className={classes.appBar}>
					<Toolbar className={classes.toolBar} disableGutters>
						<div className='brandContainer'>
							<Button
								target='_blank'
								href='https://www.uuoni.com'
								style={{ padding: 0, backgroundColor: 'transparent' }}
								disableRipple
								disableFocusRipple
								disableElevation
							>
								<img
									className='brand-logo'
									src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
									alt=''
								/>
							</Button>
						</div>
					</Toolbar>
				</AppBar>
				<SuccessMessage type={'request-not-found'} />
			</>
		);
	return (
		<>
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar className={classes.toolBar} disableGutters>
					<div className='brandContainer'>
						<Button
							target='_blank'
							href='https://www.uuoni.com'
							style={{ padding: 0, backgroundColor: 'transparent' }}
							disableRipple
							disableFocusRipple
							disableElevation
						>
							<img
								className='brand-logo'
								src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
								alt=''
							/>
						</Button>
					</div>
				</Toolbar>
			</AppBar>
			{quoteRequest && quoteRequest.status !== 'Pending Response' ? (
				<SuccessMessage type={'invalid-request'} />
			) : (
				<>
					{success ? (
						<SuccessMessage type={'request-responded'} />
					) : (
						<div className={classes.content}>
							<Backdrop text='Processing Information...' open={open} />
							<div className='header' style={{ marginBottom: 32 }}>
								<Typography variant='h1' color='textPrimary'>
									{getHeader()}
								</Typography>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginTop: 16,
									}}
								>
									<Typography
										style={{ textTransform: 'uppercase' }}
										variant='h5'
										color='textPrimary'
									>
										Requested by{' '}
										{`${quoteRequest.agentInfo.firstName} ${quoteRequest.agentInfo.lastName}`}
									</Typography>
									<div style={{ marginLeft: 16 }}>
										<IconButton
											component={Link}
											target='_blank'
											href={`tel:${quoteRequest.agentInfo.mobileNo}`}
											size='small'
										>
											<WhatsAppIcon />
										</IconButton>
										<IconButton
											component={Link}
											href={`mailto:${quoteRequest.agentInfo.email}`}
											size='small'
										>
											<MailOutlineIcon />
										</IconButton>
									</div>
								</div>
								<div style={{ display: 'flex', margin: '24px 0px' }}>
									<Typography color='textPrimary' variant='body1'>
										Please check files uploaded and the information filled. All
										mandatory fields are marked in red asterisk (
									</Typography>
									<Typography color='error' variant='body1'>
										*
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										)
									</Typography>
								</div>
							</div>
							{formData.accepted && (
								<>
									<div className='body' style={{ marginBottom: 32 }}>
										<Typography
											style={{ textTransform: 'uppercase' }}
											variant='h3'
											color='textPrimary'
										>
											Submitted Documents
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
										<div style={{ marginBottom: 24 }}>
											{quoteRequest &&
												quoteRequest.supportingDocuments?.map((doc, index) => (
													<Chip
														key={index}
														icon={
															<div>
																<FileIcon
																	labelUppercase
																	extension={fileType(doc.displayName)}
																	{...defaultStyles[fileType(doc.displayName)]}
																/>
															</div>
														}
														label={`${doc.documentName} (${doc.displayName})`}
														className={classes.chip}
														onClick={() => window.open(doc.url, '_blank')}
														variant='outlined'
														color='primary'
													/>
												))}
										</div>
										{quoteRequest &&
											quoteRequest.counterOfferAmt !== undefined && (
												<>
													<Typography
														style={{ textTransform: 'uppercase' }}
														variant='h3'
														color='textPrimary'
													>
														Counter Offer Info
													</Typography>
													<Divider
														style={{
															backgroundColor: theme.palette.primary.light,
															height: 2,
															marginTop: 16,
															marginBottom: 16,
														}}
													/>
													<Grid
														container
														spacing={4}
														style={{ marginBottom: 24 }}
													>
														<Grid item xs={12} sm={6} md={3}>
															<Typography
																color='textPrimary'
																variant='body2'
																align='left'
															>
																Counter Offer Amount
															</Typography>
															<Typography
																color='textPrimary'
																variant='body1'
																align='left'
															>
																{quoteRequest &&
																	currencyFormatter.format(
																		quoteRequest.counterOfferAmt
																	)}
															</Typography>
														</Grid>
														<Grid item xs={12} md={6}>
															<Typography
																color='textPrimary'
																variant='body2'
																align='left'
															>
																Counter Offer Remarks
															</Typography>
															<Typography
																color='textPrimary'
																variant='body1'
																align='left'
															>
																{quoteRequest &&
																	quoteRequest.counterOfferRemarks}
															</Typography>
														</Grid>
													</Grid>
												</>
											)}
										<Typography
											style={{ textTransform: 'uppercase' }}
											variant='h3'
											color='textPrimary'
										>
											Quotation Details
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={6} md={3}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Quotation Expiry Date
												</Typography>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<KeyboardDatePicker
														name='expiryDate'
														disableToolbar
														placeholder='DD/MM/YYYY'
														inputVariant='filled'
														format='dd/MM/yyyy'
														openTo='year'
														views={['year', 'month', 'date']}
														margin='dense'
														fullWidth
														value={
															formData.expiryDate ? formData.expiryDate : null
														}
														autoOk
														variant='inline'
														invalidDateMessage='Invalid Date Format'
														onChange={(date) =>
															handleDateChange('expiryDate', date)
														}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														error={!!errorMessages[`expiryDate`]}
														helperText={
															errorMessages[`expiryDate`] &&
															errorMessages[`expiryDate`]
														}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={6} md={3}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Period of Insurance (From)
												</Typography>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<KeyboardDatePicker
														name='startDate'
														disableToolbar
														placeholder='DD/MM/YYYY'
														inputVariant='filled'
														format='dd/MM/yyyy'
														openTo='year'
														views={['year', 'month', 'date']}
														margin='dense'
														fullWidth
														value={
															formData.startDate ? formData.startDate : null
														}
														autoOk
														variant='inline'
														invalidDateMessage='Invalid Date Format'
														onChange={(date) =>
															handleDateChange('startDate', date)
														}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														error={!!errorMessages[`startDate`]}
														helperText={
															errorMessages[`startDate`] &&
															errorMessages[`startDate`]
														}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Period of Insurance (To)
												</Typography>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<KeyboardDatePicker
														name='endDate'
														disableToolbar
														placeholder='DD/MM/YYYY'
														inputVariant='filled'
														format='dd/MM/yyyy'
														openTo='year'
														views={['year', 'month', 'date']}
														margin='dense'
														fullWidth
														value={formData.endDate ? formData.endDate : null}
														autoOk
														variant='inline'
														invalidDateMessage='Invalid Date Format'
														onChange={(date) =>
															handleDateChange('endDate', date)
														}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														error={!!errorMessages[`endDate`]}
														helperText={
															errorMessages[`endDate`] &&
															errorMessages[`endDate`]
														}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={6} md={3}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Total Premium
												</Typography>
												<TextField
													name='premiumAmt'
													value={formData.premiumAmt ?? ''}
													type='number'
													onChange={(e) => handleInputChange(e)}
													placeholder='Enter Total Premium'
													variant='filled'
													fullWidth
													margin='dense'
													InputProps={{
														startAdornment: (
															<InputAdornment
																style={{ marginTop: 0 }}
																position='start'
															>
																<AttachMoneyOutlinedIcon />
															</InputAdornment>
														),
													}}
													error={!!errorMessages[`premiumAmt`]}
													helperText={
														errorMessages[`premiumAmt`] &&
														errorMessages[`premiumAmt`]
													}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Typography
														style={{ marginRight: 8 }}
														color='textPrimary'
														variant='body2'
														align='left'
													>
														Sum Insured
													</Typography>
													<Tooltip
														content={
															<Typography variant='body1' color='textPrimary'>
																Enter "0" if Sum Insured is available in the
																Quotation/Policy Document
															</Typography>
														}
													/>
												</div>
												<TextField
													name='sumInsured'
													value={formData.sumInsured ?? ''}
													type='number'
													onChange={(e) => handleInputChange(e)}
													placeholder='Enter Sum Insured'
													variant='filled'
													fullWidth
													margin='dense'
													InputProps={{
														startAdornment: (
															<InputAdornment
																style={{ marginTop: 0 }}
																position='start'
															>
																<AttachMoneyOutlinedIcon />
															</InputAdornment>
														),
													}}
													error={!!errorMessages[`sumInsured`]}
													helperText={
														errorMessages[`sumInsured`] &&
														errorMessages[`sumInsured`]
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} md={6}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Notes
												</Typography>
												<TextField
													name='notes'
													value={formData.notes}
													onChange={(e) => handleInputChange(e)}
													placeholder='Enter Notes'
													variant='filled'
													fullWidth
													margin='dense'
													multiline
													minRows={4}
													error={!!errorMessages[`notes`]}
													helperText={
														errorMessages[`notes`] && errorMessages[`notes`]
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} md={6}>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Typography
														style={{ marginRight: 8 }}
														color='textPrimary'
														variant='body2'
														align='left'
													>
														Upload Quotation Document
													</Typography>
													<Tooltip content={printReqString()} />
												</div>
												<div
													style={{
														width: '100%',
														marginTop: 8,
														marginBottom: 4,
													}}
												>
													<FileUploader
														fileLimit={1}
														files={formData.fileData}
														handleFileChange={handleFileChange}
														formErrorMessage={
															errorMessages[`quoteDocument`] &&
															errorMessages[`quoteDocument`]
														}
													/>
												</div>
											</Grid>
										</Grid>
									</div>
									<div
										className='form-footer'
										style={{ display: 'flex', alignItems: 'center' }}
									>
										<Button
											className={classes.buttonAccept}
											variant='contained'
											disabled={loading || open}
											onClick={handleSubmit}
										>
											Acknowledge
										</Button>
										<Button
											className={classes.buttonReject}
											style={{ marginLeft: 16 }}
											variant='contained'
											disabled={loading || open}
											onClick={handleDecline}
										>
											Decline
										</Button>
										{/* <Button
											style={{ marginLeft: 16 }}
											variant='outlined'
											color='primary'
											disabled={loading || open}
											onClick={handleDecline}
										>
											Exit Session
										</Button> */}
									</div>
								</>
							)}
							{!formData.accepted && (
								<>
									<div className='body' style={{ marginBottom: 32 }}>
										<Typography
											style={{ textTransform: 'uppercase' }}
											variant='h3'
											color='textPrimary'
										>
											Decline Request
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={6} md={3}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Please select a reason for declining this request
												</Typography>
												<FormControl style={{ width: '100%' }}>
													<Select
														name='rejReason'
														value={formData.rejReason}
														onChange={(e) => handleInputChange(e)}
														variant='filled'
														fullWidth
														autoWidth={true}
														margin='dense'
														style={{ marginTop: 8, marginBottom: 4 }}
														error={!!errorMessages[`rejReason`]}
													>
														{rejReasonList.map((val, index) => {
															return (
																<MenuItem key={index} value={val}>
																	{val}
																</MenuItem>
															);
														})}
													</Select>
													<FormHelperText>
														{errorMessages[`rejReason`] &&
															errorMessages[`rejReason`]}
													</FormHelperText>
												</FormControl>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} md={6}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Remarks
												</Typography>
												<TextField
													name='rejRemarks'
													value={formData.rejRemarks}
													onChange={(e) => handleInputChange(e)}
													placeholder='Enter Remarks'
													variant='filled'
													fullWidth
													margin='dense'
													multiline
													minRows={4}
													error={!!errorMessages[`rejRemarks`]}
													helperText={
														errorMessages[`rejRemarks`] &&
														errorMessages[`rejRemarks`]
													}
												/>
											</Grid>
										</Grid>
									</div>
									<div
										className='form-footer'
										style={{ display: 'flex', alignItems: 'center' }}
									>
										<Button
											className={classes.buttonReject}
											variant='contained'
											disabled={loading || open}
											onClick={handleSubmit}
										>
											Decline
										</Button>
										<Button
											style={{ marginLeft: 16 }}
											variant='outlined'
											color='primary'
											disabled={loading || open}
											onClick={handleCancel}
										>
											Cancel
										</Button>
									</div>
								</>
							)}
						</div>
					)}
				</>
			)}
		</>
	);
}
