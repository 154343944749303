import Backdrop from '../../components/Backdrop';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import NavPrompt from '../../components/NavPrompt';
import React, { useEffect, useState } from 'react';
import { Link as routerLink, Prompt } from 'react-router-dom';
import api from '../../config/api';
import Step1 from './step1.js';
import Step2 from './step2.js';
import Step3 from './step3.js';
import Step4 from './step4.js';
import Step5 from './step5.js';
import {
	Import,
	ProgressAssign,
	ProgressComplete,
	ProgressPreview,
	ProgressUpload,
} from '../../components/Icons/index.js';

const CustomStepConnector = withStyles((theme) => ({
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
			background: theme.palette.success.main,
		},
	},
	completed: {
		'& $line': {
			background: theme.palette.success.main,
		},
	},
	line: {
		height: 10,
		border: 0,
		backgroundColor: theme.palette.grey[400],
		borderRadius: 1,
	},
}))(StepConnector);

const useCustomStepIconStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.grey[400],
		zIndex: 1,
		color: theme.palette.background.default,
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		background: theme.palette.tertiary.main,
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed: {
		background: theme.palette.success.main,
	},
}));

function CustomStepIcon(props) {
	const classes = useCustomStepIconStyles();
	const theme = useTheme();
	const { active, completed } = props;

	const icons = {
		1: (
			<Import
				style={{ fontSize: 24, marginLeft: -1 }}
				colorcode={
					active
						? theme.palette.background.default
						: theme.palette.text.secondary
				}
			/>
		),
		2: (
			<ProgressUpload
				style={{ fontSize: 24, marginLeft: 5 }}
				colorcode={
					active
						? theme.palette.background.default
						: theme.palette.text.secondary
				}
			/>
		),
		3: (
			<ProgressPreview
				style={{ fontSize: 24 }}
				colorcode={
					active
						? theme.palette.background.default
						: theme.palette.text.secondary
				}
			/>
		),
		4: (
			<ProgressAssign
				style={{ fontSize: 24 }}
				colorcode={
					active
						? theme.palette.background.default
						: theme.palette.text.secondary
				}
			/>
		),
		5: (
			<ProgressComplete
				style={{ fontSize: 24 }}
				colorcode={
					active
						? theme.palette.background.default
						: theme.palette.text.secondary
				}
			/>
		),
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{/* {icons[String(props.icon)]} */}
			{completed ? (
				<Check className={classes.completed} />
			) : (
				<>{icons[String(props.icon)]}</>
			)}
		</div>
	);
}

CustomStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
	backButton: {
		marginRight: theme.spacing(1),
	},
	contentContainer: {
		paddingLeft: 8,
		paddingRight: 32,
		flexGrow: 1,
	},
}));

function getSteps() {
	return ['Instructions', 'Upload', 'Preview', 'Assign', 'Complete'];
}

export default function ImportData(props) {
	const classes = useStyles();
	const theme = useTheme();
	const steps = getSteps();

	const [activeStep, setActiveStep] = useState(0);
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [headers, setHeaders] = useState([]);
	const [loading, setLoading] = useState(false);
	const [importData, setImportData] = useState([]);
	const [previewList, setPreviewList] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [importResults, setImportResults] = useState(null);
	const [errors, setErrors] = useState('');
	const [errorMessages, setErrorMessages] = useState({});
	const [loaded, setLoaded] = useState(0);
	const [type, setType] = useState('Client');
	const [modified, setModified] = useState(false);

	useEffect(() => {
		if (type) setType(type);
	}, []);

	useEffect(() => {
		if (activeStep === 0) {
			setHeaders([]);
			setImportData([]);
			setPreviewList([]);
			setSelectedFiles([]);
			setImportResults(null);
			setErrors('');
			setErrorMessages({});
		}
	}, [activeStep]);

	const handleUpload = () => {
		if (loading) return;
		if (!selectedFiles.length) {
			return;
		}
		setLoading(true);
		const data = new FormData();
		data.append('file', selectedFiles[0], selectedFiles[0].name);

		api
			.post('excel/upload', data, {
				onUploadProgress: (ProgressEvent) => {
					setLoaded(
						Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
					);
				},
			})
			.then((res) => {
				if (res.data.data.length > 5) {
					setPreviewList(
						[...Array(5)].map((_, i) => {
							return res.data.data[i];
						})
					);
				} else {
					setPreviewList(res.data.data);
				}
				let importList = res.data.headers.map((header, index) => {
					return {
						headerIndex: index,
						header: header,
						field: '',
						skip: !header ? true : false,
					};
				});
				setModified(true);
				setImportData(importList);
				setHeaders(res.data.headers);

				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	const handleProcess = () => {
		setBackdropOpen(true);
		const data = new FormData();
		data.append('file', selectedFiles[0], selectedFiles[0].name);
		data.append('json', JSON.stringify(importData));

		api
			.post('imports/clients', data)
			.then((res) => {
				setImportResults(res.data.data);
				setModified(false);
				setBackdropOpen(false);
			})
			.catch((err) => {
				console.log(err);
				setBackdropOpen(false);
			});
	};

	const handleValidation = () => {
		let errorData = {};
		let isValid = true;

		if (activeStep === 1 && !selectedFiles.length) {
			errorData['selectedFiles'] = 'Please select a File';
			isValid = false;
		}
		if (activeStep === 3) {
			let hasContact = false;
			let hasCompany = false;
			let validContact = false;
			let validCompany = false;

			if (
				importData.findIndex((row) => row.field === 'firstName') !== -1 ||
				importData.findIndex((row) => row.field === 'email') !== -1
			) {
				hasContact = true;
				if (
					importData.findIndex((row) => row.field === 'firstName') !== -1 &&
					importData.findIndex((row) => row.field === 'email') !== -1
				) {
					validContact = true;
				}
			}
			if (
				importData.findIndex((row) => row.field === 'uen') !== -1 ||
				importData.findIndex((row) => row.field === 'companyName') !== -1
			) {
				hasCompany = true;
				if (
					importData.findIndex((row) => row.field === 'uen') !== -1 &&
					importData.findIndex((row) => row.field === 'companyName') !== -1
				) {
					validCompany = true;
				}
			}

			if (!hasContact && !hasCompany) {
				errorData['noValidClient'] =
					'Both "First Name" and "Contact Email" required!';
				isValid = false;
			} else if (
				(hasContact && !validContact) ||
				(hasCompany && !validCompany)
			) {
				if (hasContact && !validContact) {
					errorData['invalidContact'] =
						'Both "First Name" and "Contact Email" required!';
					isValid = false;
				}
				if (hasCompany && !validCompany) {
					errorData['invalidCompany'] =
						'Both "Company Name" and "UEN" required!';
					isValid = false;
				}
			}
		}

		if (isValid) {
			setErrorMessages({});
			return true;
		} else {
			console.log(errorData);
			setErrorMessages(errorData);
			return false;
		}
	};

	const onChangeMapping = (e, index) => {
		let updatedImportData = [...importData];
		updatedImportData[index].field = e.target.value;
		setImportData(updatedImportData);
	};

	const onChangeCheckbox = (e, index) => {
		let updatedImportData = [...importData];
		updatedImportData[index].skip = e.target.checked;
		updatedImportData[index].field = '';
		setImportData(updatedImportData);
	};

	const handleNext = async () => {
		let valid = handleValidation();
		if (valid) {
			if (activeStep === 1) {
				try {
					await handleUpload();
				} catch (e) {
					console.log(e);
					return;
				}
			}
			if (activeStep === 3) {
				try {
					await handleProcess();
				} catch (e) {
					console.log(e);
					return;
				}
			}
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else {
			toast.error('An error has occured!');
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setHeaders([]);
		setImportData([]);
		setPreviewList([]);
		setSelectedFiles([]);
		setImportResults(null);
		setErrors('');
		setErrorMessages({});
		setActiveStep(1);
	};

	function getStepContent(step) {
		switch (step) {
			case 0:
				return <Step1 type={type} />;
			case 1:
				return (
					<Step2
						type={type}
						selectedFiles={selectedFiles}
						setSelectedFiles={setSelectedFiles}
						errorMessages={errorMessages}
					/>
				);
			case 2:
				return <Step3 previewList={previewList} headers={headers} />;
			case 3:
				return (
					<Step4
						type={type}
						onChangeMapping={onChangeMapping}
						onChangeCheckbox={onChangeCheckbox}
						importData={importData}
						errorMessages={errorMessages}
					/>
				);
			case 4:
				return <Step5 importResults={importResults} />;
			default:
				return 'Unknown step';
		}
	}

	if (loading) return <Loader size='lg' />;
	return (
		<>
			<NavPrompt when={modified} />
			<Backdrop text='Importing Clients...' open={backdropOpen} />
			<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
				<Breadcrumbs separator='›' aria-label='breadcrumb'>
					<Typography color='inherit'>Clients</Typography>
					<Typography color='primary'>Import Clients</Typography>
				</Breadcrumbs>
				<Typography variant='h2' style={{ margin: '24px 0px 0px 0px' }}>
					Import {type}s
				</Typography>
				<Stepper
					style={{ background: 'unset', margin: '56px 0px', padding: 0 }}
					activeStep={activeStep}
					alternativeLabel
					connector={<CustomStepConnector />}
				>
					{steps.map((label, index) => (
						<Step key={label}>
							<StepLabel StepIconComponent={CustomStepIcon}>
								<Typography
									variant='h5'
									style={{
										textTransform: 'uppercase',
										color:
											index === activeStep
												? theme.palette.tertiary.main
												: theme.palette.text.secondary,
									}}
								>
									{label}
								</Typography>
							</StepLabel>
						</Step>
					))}
				</Stepper>
				<div className={classes.contentContainer}>
					{getStepContent(activeStep)}
				</div>
				{activeStep === steps.length - 1 ? (
					<div
						className='form-footer'
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '16px 0px',
						}}
					>
						<Button
							component={routerLink}
							to={type === 'Client' ? '/contacts' : '/underwriters'}
							variant='outlined'
							color='primary'
						>
							Back to {type === 'Client' ? 'Contacts' : 'Underwriters'}
						</Button>
						<Button
							style={{ textDecoration: 'none', marginLeft: 'auto' }}
							variant='contained'
							color='primary'
							onClick={handleReset}
						>
							Import Another
						</Button>
					</div>
				) : (
					<div
						className='form-footer'
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '16px 0px',
						}}
					>
						<Button
							style={{ display: activeStep === 0 && 'none' }}
							onClick={handleBack}
							startIcon={<NavigateBeforeIcon />}
							variant='outlined'
							color='primary'
							className={classes.backButton}
						>
							Back
						</Button>
						{activeStep === 0 ? (
							<Button variant='contained' color='primary' onClick={handleNext}>
								Start
							</Button>
						) : (
							<Button
								style={{ marginLeft: 'auto' }}
								variant='contained'
								color='primary'
								endIcon={
									activeStep !== steps.length - 2 && <NavigateNextIcon />
								}
								disabled={loading || backdropOpen}
								onClick={handleNext}
							>
								{activeStep === steps.length - 2 ? 'Import' : 'Next'}
							</Button>
						)}
					</div>
				)}
			</div>
		</>
	);
}
