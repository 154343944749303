import Backdrop from '../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import FilterMenu from '../../components/FilterMenu';
import api from '../../config/api';
import { filterData } from '../../utils/filterData';
import DataRow from './invoiceRow';
import InvoiceWidget from './Widget';
import { toast } from 'react-toastify';
import InvoiceModal from '../../components/Modals/invoices';
import { Delete } from '../../components/Icons';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	content: {
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	deleteIcon: {
		padding: 0,
		marginLeft: 16,
	},
	deleteText: {
		color: theme.palette.primary.main,
	},
}));

const columns = [
	{ label: 'Invoice No.', name: 'invoiceNo', type: 'string' },
	{ label: 'Transaction No.', name: 'transactionNo', type: 'string' },
	{ label: 'Bill To', name: 'client', type: 'object' },
	{ label: 'Creation Date', name: 'createdAt', type: 'string' },

	{ label: 'Amount', name: 'amount', type: 'number' },
	{ label: 'Payment Status', name: 'status', type: 'string' },
];

const initFilter = {
	processor: 'And',
	column: columns[0].name,
	type: columns[0].type,
	operator:
		columns[0].type === 'string' || columns[0].type === 'array'
			? 'contains'
			: '=',
	value: '',
};

export default function Invoices() {
	const classes = useStyles();
	const theme = useTheme();

	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [selectedInvoice, setSelectedInvoice] = useState({});
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [invoices, setInvoices] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [editable, setEditable] = useState(false);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogBody, setDialogBody] = useState(0);
	const [filterList, setFilterList] = useState([initFilter]);
	const [sortOptions, setSortOptions] = useState({});
	const [viewTags, setViewTags] = useState(false);
	const [triggerListUpdate, setTriggerListUpdate] = useState(true);

	useEffect(() => {
		if (triggerListUpdate) getInvoices();
	}, [triggerListUpdate]);

	const getInvoices = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('invoices')
			.then((response) => {
				let bulkList = response.data
					.map((invoice, index) => {
						return {
							...invoice,
							status:
								invoice.status === 'Pending'
									? new Date(invoice.dueDate) < new Date()
										? 'Overdue'
										: invoice.status
									: invoice.status,
							checked: false,
						};
					})
					.sort((a, b) => {
						return new Date(b.createdAt) - new Date(a.createdAt);
					});
				setInvoices(bulkList);
				setTriggerListUpdate(false);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const openPane = (e, invoice) => {
		let target = e.target.closest('td');
		if (target === null) {
			return;
		}
		if (target.cellIndex === 0 || target.cellIndex === undefined) {
			return;
		}
		if (target.cellIndex === 5) {
			setViewTags(true);
		}
		setOpen(true);
		setSelectedInvoice(invoice);
	};

	const closePane = () => {
		setOpen(false);
		setViewTags(false);
		setSelectedInvoice({});
	};

	const handleDelete = () => {
		setDialogOpen(false);
		setBackdropOpen(true);
		let checked = invoices.filter((props) => props.checked);
		let ids = checked.map((item) => item._id);
		api.post('invoices/delete/bulk-delete', ids).then((res) => {
			if (res.data.ok) {
				toast.success('Invoice(s) deleted!');
				setTriggerListUpdate(true);
				setCheckAll(false);
				setPage(0);
				setBackdropOpen(false);
			} else {
				toast.error('An error has occured!');
			}
		});
	};

	const handleCancel = () => {
		setDialogOpen(false);
		closePane();
		setEditable(false);
	};

	const toggleDialog = (body) => {
		if (!dialogOpen) {
			return setDialogBody(body), setDialogOpen(true);
		}
		setDialogOpen(!dialogOpen);
	};

	const totalChecked = () => {
		return invoices.filter((props) => props.checked).length;
	};

	const handleCheckAll = (e) => {
		let updatedInvoices = [...invoices];
		updatedInvoices.forEach((invoice) => (invoice.checked = e.target.checked));
		setCheckAll(e.target.checked);
		setInvoices(updatedInvoices);
	};

	const handleCheckbox = (e, index) => {
		let updatedInvoices = [...invoices];
		updatedInvoices.find((x) => x._id === index).checked = e.target.checked;
		setInvoices(updatedInvoices);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleSearch = (e) => {
		setPage(0);
		setSearchValue(e.target.value);
	};

	const handleClearSearch = () => {
		setPage(0);
		setSearchValue('');
	};

	const searchData = (data) => {
		if (searchValue) {
			let val = searchValue.replace(/\s+/g, ' ').toLowerCase();
			let results = data.filter((item) => {
				return Object.keys(item).some((key) => {
					if (key === 'invoiceNo') {
						return String(`INV-${item[key]}`)
							.toLowerCase()
							.includes(val.toLowerCase());
					} else if (key === 'transactionNo') {
						return String(`TXN-${item[key]}`)
							.toLowerCase()
							.includes(val.toLowerCase());
					} else if (key === 'client') {
						return Object.keys(item[key]).some((subKey) => {
							return (
								typeof item[key][subKey] === 'string' &&
								item[key][subKey].toLowerCase().includes(val.toLowerCase())
							);
						});
					} else if (key === 'amount') {
						return String(item[key]).toLowerCase().includes(val.toLowerCase());
					} else {
						return (
							typeof item[key] === 'string' &&
							item[key].toLowerCase().includes(val.toLowerCase())
						);
					}
				});
			});
			return results;
		}
		return data;
	};

	const handleSort = (key) => {
		setPage(0);
		setSortOptions((prevState) => ({ [key]: !prevState[key] }));
	};

	const sortData = (data) => {
		let sortedData = data;
		Object.keys(sortOptions).forEach((e) => {
			if (e === 'invoiceNo') {
				return sortedData.sort(compareNumberByDesc(e, sortOptions[e]));
			}
			if (e === 'transactionNo') {
				return sortedData.sort(compareNumberByDesc(e, sortOptions[e]));
			}
			if (e === 'contactName' || e === 'companyName') {
				return sortedData.sort(compareObjByDesc(e, sortOptions[e]));
			}
			if (e === 'amount') {
				return sortedData.sort(compareNumberByDesc(e, sortOptions[e]));
			}
			return sortedData.sort(compareByDesc(e, sortOptions[e]));
		});
		return sortedData;
	};

	const compareObjByDesc = (key, order) => {
		if (order) {
			return function (a, b) {
				if (a['client'][key] === undefined) return 1;
				if (b['client'][key] === undefined) return -1;
				if (a['client'][key] === b['client'][key]) return 0;
				return a['client'][key].toLowerCase() < b['client'][key].toLowerCase()
					? -1
					: 1;
			};
		} else {
			return function (a, b) {
				if (a['client'][key] === undefined) return 1;
				if (b['client'][key] === undefined) return -1;
				if (a['client'][key] === b['client'][key]) return 0;
				return a['client'][key].toLowerCase() > b['client'][key].toLowerCase()
					? -1
					: 1;
			};
		}
	};

	const compareNumberByDesc = (key, order) => {
		if (order) {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key] - b[key];
			};
		} else {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return b[key] - a[key];
			};
		}
	};

	const compareByDesc = (key, order) => {
		if (order) {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key].toLowerCase() < b[key].toLowerCase() ? -1 : 1;
			};
		} else {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key].toLowerCase() > b[key].toLowerCase() ? -1 : 1;
			};
		}
	};

	const getFilteredInvoices = () => {
		let initialList = invoices;
		let sortedInvoices = sortData(initialList);
		let filteredInvoices = filterData(sortedInvoices, filterList);
		let searchedInvoices = searchData(filteredInvoices);
		return searchedInvoices;
	};

	const filteredInvoices = getFilteredInvoices();

	const emptyRows =
		rowsPerPage -
		Math.min(rowsPerPage, filteredInvoices.length - page * rowsPerPage);

	const responseList = () => {
		return filteredInvoices
			.slice(page * 5, page * 5 + 5)
			.map((currentInvoice) => (
				<DataRow
					key={currentInvoice._id}
					data={currentInvoice}
					handleCheckbox={handleCheckbox}
				/>
			));
	};

	return (
		<>
			<Backdrop text='Deleting Invoice(s)...' open={backdropOpen} />
			<Breadcrumbs separator='›' aria-label='breadcrumb'>
				<Typography color='inherit'>Invoices</Typography>
				<Typography color='primary'>Invoice Overview</Typography>
			</Breadcrumbs>
			<Typography variant='h2' style={{ margin: '24px 0px 0px 0px' }}>
				Invoice Overview
			</Typography>
			<InvoiceWidget invoices={invoices} />
			<div margin='auto'>
				<div className='tableBar'>
					<Box display='flex' alignItems='center'>
						<Box style={{ width: 400 }}>
							<TextField
								style={{ margin: 0 }}
								value={searchValue}
								onChange={(e) => handleSearch(e)}
								placeholder='Search Invoices'
								variant='filled'
								fullWidth
								margin='dense'
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											style={{ marginTop: 0, color: theme.palette.grey[500] }}
										>
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												onClick={handleClearSearch}
												disableFocusRipple
												disableRipple
												style={{
													backgroundColor: 'transparent',
													display: !searchValue && 'none',
												}}
											>
												<ClearIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Box style={{ marginLeft: 16 }}>
							<FilterMenu
								columns={columns}
								filterList={filterList}
								setFilterList={setFilterList}
							/>
						</Box>
					</Box>
				</div>
				<div className='pagination'>
					<TablePagination
						className='pagination'
						component='div'
						count={filteredInvoices.length}
						rowsPerPageOptions={[]}
						rowsPerPage={rowsPerPage}
						page={page}
						SelectProps={{
							inputProps: {
								'aria-label': 'rows per page',
							},
							native: true,
						}}
						onPageChange={handleChangePage}
					/>
				</div>
				<div className={classes.content}>
					<Box
						display='flex'
						style={{
							overflow: 'auto',
							border: '1px solid #edf2f7',
							borderRadius: 8,
						}}
					>
						<Box style={{ width: '100%' }}>
							{totalChecked() > 0 && (
								<div
									style={{
										display: 'flex',
										width: '100%',
										height: 40,
										alignItems: 'center',
										backgroundColor:
											totalChecked() > 0
												? theme.palette.tertiary.light
												: theme.palette.background.paper,
									}}
								>
									{totalChecked() > 0 && (
										<div style={{ paddingLeft: 20, display: 'flex' }}>
											<Typography
												className={classes.deleteText}
												variant='body1'
												align='left'
											>
												{totalChecked()} Selected
											</Typography>
											<IconButton
												className={classes.deleteIcon}
												onClick={() => toggleDialog('deleteInvoices')}
											>
												<Delete colorcode={theme.palette.text.secondary} />
											</IconButton>
										</div>
									)}
								</div>
							)}
							<table className='tableContent' cellSpacing='0'>
								<thead>
									<tr
										style={{
											height: 52,
											backgroundColor: theme.palette.background.paper,
										}}
									>
										<th className='checkBox'>
											<Checkbox
												checked={checkAll}
												onChange={(e) => handleCheckAll(e)}
												inputProps={{
													'aria-label': 'primary checkbox',
												}}
											/>
										</th>
										<th onClick={() => handleSort('invoiceNo')}>
											<div className='aligner'>
												<div className='text'>Invoice No.</div>
												<div className='icon'>
													{sortOptions['invoiceNo'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('transactionNo')}>
											<div className='aligner'>
												<div className='text'>Transaction No.</div>
												<div className='icon'>
													{sortOptions['transactionNo'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('contactName')}>
											<div className='aligner'>
												<div className='text'>Bill To</div>
												<div className='icon'>
													{sortOptions['contactName'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('createdAt')}>
											<div className='aligner'>
												<div className='text'>Creation Date</div>
												<div className='icon'>
													{sortOptions['createdAt'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										{/* <th onClick={() => handleSort('status')}>
											<div className='aligner'>
												<div className='text'>Issue Date</div>
												<div className='icon'>
													{sortOptions['status'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th> */}
										<th onClick={() => handleSort('amount')}>
											<div className='aligner'>
												<div className='text'>Amount</div>
												<div className='icon'>
													{sortOptions['amount'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('status')}>
											<div className='aligner'>
												<div className='text'>Payment Status</div>
												<div className='icon'>
													{sortOptions['status'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{loading ? (
										<tr
											style={{
												height: 320,
											}}
										>
											<td
												colSpan={7}
												className='noResults'
												style={{
													textAlign: 'center',
													border: '1px solid #edf2f7',
												}}
											>
												<CircularProgress size={80} />
											</td>
										</tr>
									) : (
										<>
											{responseList()}
											{emptyRows > 0 && emptyRows < 5 && (
												<tr
													style={{
														height: 64 * emptyRows,
													}}
												>
													<td
														colSpan={7}
														style={{
															border: '1px solid #edf2f7',
														}}
													/>
												</tr>
											)}
										</>
									)}
									{!filteredInvoices.length && !loading && (
										<tr
											style={{
												height: 320,
											}}
										>
											<td
												colSpan={7}
												className='noResults'
												style={{
													textAlign: 'center',
													border: '1px solid #edf2f7',
												}}
											>
												No Results Found
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</Box>
					</Box>
				</div>
				<InvoiceModal
					open={dialogOpen}
					body={dialogBody}
					handleCancel={() => handleCancel()}
					handleConfirm={() => handleDelete()}
					handleClose={() => toggleDialog(0)}
				/>
			</div>
		</>
	);
}
