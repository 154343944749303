import DateFnsUtils from '@date-io/date-fns';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import IndustryList from '../../../components/Data/industries.json';
import AssociatedContactForm from './associatedContactForm';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const str2bool = (value) => {
	if (value && typeof value === 'string') {
		if (value.toLowerCase() === 'true') return true;
		if (value.toLowerCase() === 'false') return false;
	}
	return value;
};

const useStyles = makeStyles((theme) => ({
	tabPanel: {
		width: '80%',
		minHeight: 600,
	},
	tabPanelMobile: {
		minHeight: 600,
	},
}));

export default function CompanyForm(props) {
	const { formData, handleFormUpdate, errorMessages, errorTabs } = props;
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState(0);

	const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;

		let updatedData = formData.data;
		updatedData[name] = value;

		handleFormUpdate('data', updatedData);
	};

	const handleDateChange = (target, date) => {
		let updatedData = formData.data;
		updatedData[target] = date;

		handleFormUpdate('data', updatedData);
	};

	const handleAddContact = () => {
		let updatedContacts = [...formData.contacts];
		let newContactData = {
			id: nanoid(),
			isPrimary: false,
			isExisting: false,
			existingId: '',
			formData: {
				firstName: '',
				middleName: '',
				lastName: '',
				dob: null,
				gender: '',
				maritalStatus: '',
				idNo: '',
				nationality: '',
				email: '',
				mobileNo: '',
				homeNo: '',
				address: '',
				unitNo: '',
				postalCode: '',
			},
		};
		updatedContacts.push(newContactData);

		handleFormUpdate('contacts', updatedContacts);
	};

	const handleRemoveContact = (id) => {
		if (formData.contacts.length === 1) return;
		let updatedContacts = [...formData.contacts].filter((el) => el.id !== id);
		if (updatedContacts.length === 1) {
			updatedContacts[0].isPrimary = true;
		}

		handleFormUpdate('contacts', updatedContacts);
	};

	const handleSelectContact = (value, type, index) => {
		let updatedContacts = [...formData.contacts];
		let selected = updatedContacts.some(
			(contact) => contact.existingId === value._id
		);
		if (selected) {
			return;
		} else {
			updatedContacts[index]['formData'] = value;
			updatedContacts[index].existingId = value._id;

			handleFormUpdate('contacts', updatedContacts);
		}
	};

	const handleContactInputChange = (e, index) => {
		const {
			target: { value, name },
		} = e;
		let updatedContacts = [...formData.contacts];
		updatedContacts[index]['formData'][name] = value;

		handleFormUpdate('contacts', updatedContacts);
	};

	const handleContactDateChange = (index, target, date) => {
		let updatedContacts = [...formData.contacts];
		updatedContacts[index]['formData'][target] = date;

		handleFormUpdate('contacts', updatedContacts);
	};

	const handleChangePrimary = (id) => {
		let updatedContacts = [...formData.contacts];
		updatedContacts.forEach((el) =>
			el.id === id ? (el.isPrimary = true) : (el.isPrimary = false)
		);

		handleFormUpdate('contacts', updatedContacts);
	};

	const handleChangeExisting = (e, index) => {
		let val = str2bool(e.target.value);
		let updatedContacts = [...formData.contacts];
		if (!val) {
			updatedContacts[index]['formData'] = {
				firstName: '',
				middleName: '',
				lastName: '',
				dob: null,
				gender: '',
				maritalStatus: '',
				idNo: '',
				nationality: '',
				email: '',
				mobileNo: '',
				homeNo: '',
				address: '',
				unitNo: '',
				postalCode: '',
			};
			updatedContacts[index].existingId = '';
		}
		updatedContacts[index]['isExisting'] = val;

		handleFormUpdate('contacts', updatedContacts);
	};

	return (
		<>
			<AppBar
				elevation={1}
				position='static'
				style={{ color: theme.palette.text.disabled, backgroundColor: 'unset' }}
			>
				<Tabs
					value={value}
					onChange={handleChangeTab}
					variant='fullWidth'
					aria-label='full width tabs example'
				>
					<Tab
						label={
							errorTabs[0] === true ? (
								<div>
									Company Information
									<WarningIcon
										style={{
											verticalAlign: 'middle',
											marginLeft: 20,
											color: theme.palette.error.light,
										}}
									/>
								</div>
							) : (
								'Company Information'
							)
						}
						{...a11yProps(0)}
					/>
					<Tab
						label={
							errorTabs[1] === true ? (
								<div>
									Contact Person Information
									<WarningIcon
										style={{
											verticalAlign: 'middle',
											marginLeft: 20,
											color: theme.palette.error.light,
										}}
									/>
								</div>
							) : (
								'Contact Person Information'
							)
						}
						{...a11yProps(1)}
					/>
				</Tabs>
			</AppBar>
			<TabPanel
				className={
					isDesktopOrLaptop ? classes.tabPanel : classes.tabPanelMobile
				}
				value={value}
				index={0}
				dir={theme.direction}
			>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={4}>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								Company Name
							</Typography>
							<Typography
								style={{ marginLeft: 2 }}
								color='error'
								variant='body1'
								align='right'
							>
								*
							</Typography>
						</Grid>
						<TextField
							name='companyName'
							value={formData.data.companyName}
							onChange={handleInputChange}
							placeholder='Enter Company Name'
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.companyName`]}
							helperText={
								errorMessages[`data.companyName`] &&
								errorMessages[`data.companyName`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								Unique Entity Number (UEN)
							</Typography>
							<Typography
								style={{ marginLeft: 2 }}
								color='error'
								variant='body1'
								align='right'
							>
								*
							</Typography>
						</Grid>
						<TextField
							name='uen'
							value={formData.data.uen}
							onChange={handleInputChange}
							placeholder='Enter UEN'
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.uen`]}
							helperText={
								errorMessages[`data.uen`] && errorMessages[`data.uen`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Website
						</Typography>
						<TextField
							name='website'
							value={formData.data.website}
							onChange={handleInputChange}
							placeholder='E.g. www.xyz.com'
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.website`]}
							helperText={
								errorMessages[`data.website`] && errorMessages[`data.website`]
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Nature of Business
						</Typography>
						<Select
							native
							name='industryName'
							value={formData.data.industryName}
							onChange={handleInputChange}
							variant='filled'
							fullWidth
							autoWidth={true}
							margin='dense'
							style={{ marginTop: 8, marginBottom: 4 }}
						>
							<option key={0} value=''></option>
							{IndustryList.map((industry, index) => (
								<option key={index} value={industry.section}>
									{industry.name}
								</option>
							))}
						</Select>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Established On
						</Typography>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								name='establishDate'
								disableToolbar
								placeholder='DD/MM/YYYY'
								inputVariant='filled'
								format='dd/MM/yyyy'
								disableFuture
								openTo='year'
								views={['year', 'month', 'date']}
								margin='dense'
								fullWidth
								value={
									formData.data.establishDate
										? formData.data.establishDate
										: null
								}
								autoOk
								variant='inline'
								invalidDateMessage='Invalid Date Format'
								onChange={(date) => handleDateChange('establishDate', date)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								error={!!errorMessages[`data.establishDate`]}
								helperText={
									errorMessages[`data.establishDate`] &&
									errorMessages[`data.establishDate`]
								}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				</Grid>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Email
						</Typography>
						<TextField
							name='companyEmail'
							value={formData.data.companyEmail}
							onChange={handleInputChange}
							placeholder="Enter Client's Email Address"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.companyEmail`]}
							helperText={
								errorMessages[`data.companyEmail`] &&
								errorMessages[`data.companyEmail`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Office
						</Typography>
						<TextField
							name='officeNo'
							value={formData.data.officeNo}
							onChange={handleInputChange}
							placeholder="Enter Company's Office Number"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.officeNo`]}
							helperText={
								errorMessages[`data.officeNo`] && errorMessages[`data.officeNo`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Fax
						</Typography>
						<TextField
							name='faxNo'
							value={formData.data.faxNo}
							onChange={handleInputChange}
							placeholder="Enter Company's Fax Number"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.faxNo`]}
							helperText={
								errorMessages[`data.faxNo`] && errorMessages[`data.faxNo`]
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Address
						</Typography>
						<TextField
							name='companyAddress'
							value={formData.data.companyAddress}
							onChange={handleInputChange}
							placeholder="Enter Client's Blk / House No. / Street Address"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.companyAddress`]}
							helperText={
								errorMessages[`data.companyAddress`] &&
								errorMessages[`data.companyAddress`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Floor / Unit Number
						</Typography>
						<TextField
							name='companyUnitNo'
							value={formData.data.companyUnitNo}
							onChange={handleInputChange}
							placeholder='Enter Unit No.'
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.unitNo`]}
							helperText={
								errorMessages[`data.unitNo`] && errorMessages[`data.unitNo`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Postal / Zip Code
						</Typography>
						<TextField
							name='companyPostalCode'
							value={formData.data.companyPostalCode}
							onChange={handleInputChange}
							placeholder='Enter Postal Code'
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.companyPostalCode`]}
							helperText={
								errorMessages[`data.companyPostalCode`] &&
								errorMessages[`data.companyPostalCode`]
							}
						/>
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel
				className={classes.tabPanelMobile}
				value={value}
				index={1}
				dir={theme.direction}
			>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{formData.contacts?.map((contact, index) => (
						<AssociatedContactForm
							key={contact.id}
							index={index}
							contact={contact}
							errorMessages={errorMessages}
							handleChangePrimary={handleChangePrimary}
							handleChangeExisting={handleChangeExisting}
							handleSelectContact={handleSelectContact}
							handleRemoveContact={handleRemoveContact}
							handleContactInputChange={handleContactInputChange}
							handleContactDateChange={handleContactDateChange}
							disableRemove={formData.contacts.length === 1}
							showDuplicateMessage={showDuplicateMessage}
						/>
					))}
					<Button
						style={{ alignSelf: 'flex-start' }}
						color='primary'
						startIcon={<AddCircleOutlineIcon />}
						onClick={handleAddContact}
					>
						Add Contact
					</Button>
				</div>
			</TabPanel>
		</>
	);
}
