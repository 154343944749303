import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
		height: '100%',
	},
}));

export default function Loader(props) {
	const { size } = props;
	const classes = useStyles();

	const getSize = (size) => {
		switch (size) {
			case 'sm':
				return 24;
			case 'lg':
				return 120;
			default:
				return 40;
		}
	};

	return (
		<div className={classes.wrapper}>
			<CircularProgress
				style={{ height: getSize(size), width: getSize(size) }}
			/>
		</div>
	);
}
