import AppBar from '@material-ui/core/AppBar';
import Backdrop from '../../../components/Backdrop';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React, { useEffect, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SuccessMessage from '../../../components/SuccessMessage';
import api from '../../../config/apiPublic';
import ClientLogin from '../ClientLogin';
import useStyles from '../styles';
import Loader from '../../../components/Loader';
import { ConfirmClaim as ConfirmClaimSchema } from '../../../utils/validators/schemas';
import { SessionTimeout } from '../../../components/Session';

const initFormData = {
	paymentMethod: 'ibanking',
	bank: '',
	beneficiaryName: '',
	accountNo: '',
	clientNotes: '',
};

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

export default function ConfirmClaim(props) {
	const { id } = useParams();
	const classes = useStyles();
	const theme = useTheme();
	const [claimConfirmationRequest, setClaimConfirmationRequest] =
		useState(null);
	const [formData, setFormData] = useState(initFormData);
	const [isFormInvalid, setIsFormInvalid] = useState(false);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);
	const [hasAuth, setHasAuth] = useState(false);

	const getData = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get(`external/client-requests/confirmClaim/${id}`)
			.then((response) => {
				if (response.data.ok) {
					setClaimConfirmationRequest(response.data.data);
				} else {
					setClaimConfirmationRequest(null);
				}
				setLoading(false);
			})
			.catch(function (error) {
				setLoading(false);
				setClaimConfirmationRequest(null);
				console.log(error);
			});
	};

	useEffect(() => {
		if (hasAuth) getData();
	}, [hasAuth]);

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;

		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const getHeader = () => {
		return (
			<>
				Confirmation of Claim - {claimConfirmationRequest.insuranceType}
				{claimConfirmationRequest.insuranceSubType && (
					<> ({claimConfirmationRequest.insuranceSubType})</>
				)}{' '}
				{claimConfirmationRequest.transactionType} -{' '}
				{claimConfirmationRequest.client.contactName}
			</>
		);
	};

	const handleValidation = () => {
		let result = ConfirmClaimSchema.validate(formData, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (open) return;
		setOpen(true);

		let valid = handleValidation();

		if (valid) {
			try {
				api
					.put(`external/client-requests/confirmClaim/${id}`, formData)
					.then((res) => {
						if (res.data.ok) {
							setErrorMessages({});
							setSuccess(true);
							setOpen(false);
						} else {
							setSuccess(false);
							toast.error(res.data.error);
							setOpen(false);
						}
					});
			} catch (error) {
				console.log(error);
				setSuccess(false);
				toast.error(error);
				setOpen(false);
			}
		} else {
			toast.error('An error has occured!');
			setOpen(false);
		}
	};

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	if (!hasAuth)
		return (
			<ClientLogin
				reqType='confirmClaim'
				reqId={id}
				hasAuth={hasAuth}
				setHasAuth={setHasAuth}
			/>
		);

	if (hasAuth && loading && !claimConfirmationRequest)
		return <Loader size='lg' />;
	if (hasAuth && !loading && !claimConfirmationRequest)
		return (
			<>
				<AppBar position='fixed' className={classes.appBar}>
					<Toolbar className={classes.toolBar} disableGutters>
						<div className='brandContainer'>
							<Button
								target='_blank'
								href='https://www.uuoni.com'
								style={{ padding: 0, backgroundColor: 'transparent' }}
								disableRipple
								disableFocusRipple
								disableElevation
							>
								<img
									className='brand-logo'
									src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
									alt=''
								/>
							</Button>
						</div>
					</Toolbar>
				</AppBar>
				<SuccessMessage type={'request-not-found'} />
			</>
		);
	return (
		<>
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar className={classes.toolBar} disableGutters>
					<div className='brandContainer'>
						<Button
							target='_blank'
							href='https://www.uuoni.com'
							style={{ padding: 0, backgroundColor: 'transparent' }}
							disableRipple
							disableFocusRipple
							disableElevation
						>
							<img
								className='brand-logo'
								src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
								alt=''
							/>
						</Button>
					</div>
				</Toolbar>
			</AppBar>
			{claimConfirmationRequest &&
			claimConfirmationRequest.status !== 'Pending Response' ? (
				<SuccessMessage type={'invalid-request'} />
			) : (
				<>
					{success ? (
						<SuccessMessage type={'claim-confirmed'} />
					) : (
						<div className={classes.content}>
							<Backdrop text='Processing Information...' open={open} />
							<div className='header' style={{ marginBottom: 32 }}>
								<Typography variant='h1' color='textPrimary'>
									{getHeader()}
								</Typography>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginTop: 16,
									}}
								>
									<Typography
										style={{ textTransform: 'uppercase' }}
										variant='h5'
										color='textPrimary'
									>
										Requested by{' '}
										{`${claimConfirmationRequest.agentInfo.firstName} ${claimConfirmationRequest.agentInfo.lastName}`}
									</Typography>
									<div style={{ marginLeft: 16 }}>
										<IconButton
											component={Link}
											target='_blank'
											href={`tel:${claimConfirmationRequest.agentInfo.mobileNo}`}
											size='small'
										>
											<WhatsAppIcon />
										</IconButton>
										<IconButton
											component={Link}
											href={`mailto:${claimConfirmationRequest.agentInfo.email}`}
											size='small'
										>
											<MailOutlineIcon />
										</IconButton>
									</div>
								</div>
							</div>
							<div className='body' style={{ marginBottom: 32 }}>
								<Typography
									style={{ textTransform: 'uppercase' }}
									variant='h3'
									color='textPrimary'
								>
									Claim Details
								</Typography>
								<Divider
									style={{
										backgroundColor: theme.palette.primary.light,
										height: 2,
										marginTop: 16,
										marginBottom: 16,
									}}
								/>
								<Grid
									container
									spacing={2}
									direction='row'
									alignItems='stretch'
									style={{ marginBottom: 16 }}
								>
									<Grid item xs={12}>
										<Typography color='textPrimary' variant='body2'>
											Total Claimable Amount
										</Typography>
										<Typography
											color='textPrimary'
											variant='body1'
											style={{ marginTop: 8 }}
										>
											{claimConfirmationRequest.claimRequestId.responseData &&
												claimConfirmationRequest.claimRequestId.responseData
													.claimAmt !== undefined &&
												currencyFormatter.format(
													claimConfirmationRequest.claimRequestId.responseData
														.claimAmt
												)}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography color='textPrimary' variant='body2'>
											Claim Notes
										</Typography>
										<Typography
											color='textPrimary'
											variant='body1'
											style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
										>
											{claimConfirmationRequest.claimRequestId.responseData &&
											claimConfirmationRequest.claimRequestId.responseData
												.insurerNotes !== undefined
												? claimConfirmationRequest.claimRequestId.responseData
														.insurerNotes
												: 'N/A'}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography color='textPrimary' variant='body2'>
											Attachment
										</Typography>
										{claimConfirmationRequest.claimRequestId && (
											<Chip
												style={{ margin: 0, marginTop: 8 }}
												icon={
													<div>
														<FileIcon
															labelUppercase
															extension={fileType(
																claimConfirmationRequest.claimRequestId
																	.responseData.responseDocument.displayName
															)}
															{...defaultStyles[
																fileType(
																	claimConfirmationRequest.claimRequestId
																		.responseData.responseDocument.displayName
																)
															]}
														/>
													</div>
												}
												label={`${claimConfirmationRequest.claimRequestId.responseData.responseDocument.documentName} (${claimConfirmationRequest.claimRequestId.responseData.responseDocument.displayName})`}
												className={classes.chip}
												onClick={() =>
													window.open(
														claimConfirmationRequest.claimRequestId.responseData
															.responseDocument.url,
														'_blank'
													)
												}
												variant='outlined'
												color='primary'
											/>
										)}
									</Grid>
								</Grid>
								<Typography
									style={{ textTransform: 'uppercase' }}
									variant='h3'
									color='textPrimary'
								>
									Bank Details
								</Typography>
								<Divider
									style={{
										backgroundColor: theme.palette.primary.light,
										height: 2,
										marginTop: 16,
										marginBottom: 16,
									}}
								/>
								<Grid container spacing={4}>
									<Grid item xs={12} sm={6} md={3}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Payment Method
										</Typography>
										<Select
											native
											name='paymentMethod'
											value={formData.paymentMethod}
											onChange={handleInputChange}
											variant='filled'
											fullWidth
											autoWidth={true}
											margin='dense'
											style={{ marginTop: 8, marginBottom: 4 }}
										>
											<option value='ibanking'>
												Bank Transfer / Internet Banking
											</option>
											<option value='paynow'>PayNow</option>
										</Select>
									</Grid>
								</Grid>
								{formData.paymentMethod === 'ibanking' && (
									<>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={6} md={3}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Bank
												</Typography>
												<TextField
													name='bank'
													value={formData.bank}
													onChange={(e) => handleInputChange(e)}
													variant='filled'
													fullWidth
													margin='dense'
													error={!!errorMessages[`bank`]}
													helperText={
														errorMessages[`bank`] && errorMessages[`bank`]
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={6} md={3}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Beneficiary's Name
												</Typography>
												<TextField
													name='beneficiaryName'
													value={formData.beneficiaryName}
													onChange={(e) => handleInputChange(e)}
													variant='filled'
													fullWidth
													margin='dense'
													error={!!errorMessages[`beneficiaryName`]}
													helperText={
														errorMessages[`beneficiaryName`] &&
														errorMessages[`beneficiaryName`]
													}
												/>
											</Grid>
										</Grid>
									</>
								)}
								<Grid container spacing={4}>
									<Grid item xs={12} sm={6} md={3}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											{formData.paymentMethod === 'ibanking'
												? 'Account No.'
												: 'Mobile No. / NRIC / UEN'}
										</Typography>
										<TextField
											name='accountNo'
											value={formData.accountNo}
											onChange={(e) => handleInputChange(e)}
											variant='filled'
											fullWidth
											margin='dense'
											error={!!errorMessages[`accountNo`]}
											helperText={
												errorMessages[`accountNo`] && errorMessages[`accountNo`]
											}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<Typography
											color='textPrimary'
											variant='body2'
											align='left'
										>
											Notes
										</Typography>
										<TextField
											name='clientNotes'
											value={formData.clientNotes}
											onChange={(e) => handleInputChange(e)}
											placeholder='Enter Notes'
											variant='filled'
											fullWidth
											margin='dense'
											multiline
											minRows={4}
											error={!!errorMessages[`clientNotes`]}
											helperText={
												errorMessages[`clientNotes`] &&
												errorMessages[`clientNotes`]
											}
										/>
									</Grid>
								</Grid>
							</div>
							<div
								className='form-footer'
								style={{ display: 'flex', alignItems: 'center' }}
							>
								<Button
									style={{
										marginLeft: 'auto',
									}}
									variant='contained'
									color='primary'
									disabled={isFormInvalid || loading || open}
									onClick={handleSubmit}
								>
									Submit
								</Button>
							</div>
						</div>
					)}
				</>
			)}
			{hasAuth && <SessionTimeout onTimeout={() => setHasAuth(false)} />}
		</>
	);
}
