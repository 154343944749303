import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	tableRow: {},
	tableCell: {
		padding: 6,
	},
	tableCellSelected: {
		padding: 6,
		color: theme.palette.tertiary.main,
		fontWeight: 600,
	},
}));

const getStatus = (status, subStatus) => {
	switch (status) {
		case 'Approved In-Principle':
		case 'In-Progress':
			return <div style={{ color: '#f09106' }}>{subStatus}</div>;
		case 'Completed':
			return <div style={{ color: '#7fa572' }}>{status}</div>;
		case 'Expired':
			return <div style={{ color: '#f00606' }}>{status}</div>;
	}
};

const DataRow = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { data, handleCheckbox } = props;
	return (
		<TableRow
			className={classes.tableRow}
			hover
			selected={data.checked}
			key={data._id}
			onClick={(e) => {
				let target = e.target.closest('td');
				if (target === null) {
					return;
				}
				if (target.cellIndex === 0 || target.cellIndex === undefined) {
					return;
				}

				history.push(`/transactions/${data._id}`);
			}}
		>
			<TableCell className='checkBox' style={{ padding: 6 }}>
				<Checkbox
					checked={data.checked}
					onChange={(e) => handleCheckbox(e, data._id)}
					inputProps={{
						'aria-label': 'primary checkbox',
					}}
				/>
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				TXN-{data.transactionNo}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				{data.transactionType}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				{data.insuranceType}
				{data.insuranceSubType && <> ({data.insuranceSubType})</>}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				{data.client.contactName} <br /> {data.client.companyName}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				{getStatus(data.status, data.subStatus)}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				{data.latestActivity && data.latestActivity.content}
			</TableCell>
		</TableRow>
	);
};

export default DataRow;
