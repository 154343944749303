import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Overview from './overview';

function TabPanel(props) {
	const { children, value, selected, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== selected}
			id={`full-width-tabpanel-${selected}`}
			aria-labelledby={`full-width-tab-${selected}`}
			{...other}
		>
			{value === selected && <Box p={3}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		borderTop: '1px solid rgb(237, 242, 247)',
	},
}));

export default function Subscription(props) {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<>
			<div className={classes.root}>
				<div style={{ width: '95%', height: '95%' }}>
					<Overview />
				</div>
			</div>
			{/* <div className={classes.root}>
				<Tabs
					value={selected}
					
					aria-label='full width tabs example'
				>
					{options.map((option, index) => (
						<Tab
							classes={{ root: classes.tabRoot }}
							key={option.endpoint}
							label={option.label}
							value={option.endpoint}
							component={Link}
							to={'/settings/subscription/' + option.endpoint}
							{...a11yProps(index)}
						/>
					))}
				</Tabs>
			</div>
			<Divider /> */}

			{/* <Box width='100%' style={{ height: 'inherit', overflowY: 'auto' }}>
				<TabPanel
					value={'overview'}
					index={0}
					selected={selected}
					dir={theme.direction}
				>
					<Overview />
				</TabPanel>
			</Box> */}
		</>
	);
}
