import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '../../../../components/Tooltip';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../config/api';
import CompanyInfo from './ProfileTab/companyInfo.js';
import Contacts from './ProfileTab/contacts.js';
import Transactions from './TransactionsTab';
import { updateRecentlyViewed } from '../../../../utils/storageHelper';
import { Company } from '../../../../utils/validators/schemas';
import {
	Company as CompanyIcon,
	Edit,
	Save,
} from '../../../../components/Icons/index.js';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.secondary,
		height: 'inherit',
	},
	tabs: {},
	tabArea: {
		height: 560,
		display: 'block',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	tabAreaMobile: {
		display: 'block',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	listItemRoot: {
		paddingLeft: 24,
		paddingRight: 24,
		'&$selected': {
			backgroundColor: 'rgba(183, 198, 243, 0.2)',
			'&:hover': {
				backgroundColor: 'rgba(183, 198, 243, 0.2)',
			},
		},
	},
	listItemButton: {
		'&:hover': {
			backgroundColor: 'rgba(183, 198, 243, 0.2)',
		},
	},
}));

export default function CompanyProfile(props) {
	const {
		viewContacts,
		companyId,
		editable,
		setEditable,
		setTriggerListUpdate,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const paneRef = useRef();
	const [value, setValue] = useState(0);
	const [company, setCompany] = useState({});
	const [ongoingTransactions, setOngoingTransactions] = useState([]);
	const [completedTransactions, setCompletedTransactions] = useState([]);
	const [open0, setOpen0] = useState(viewContacts ? false : true);
	const [open1, setOpen1] = useState(viewContacts ? true : false);
	const [loading, setLoading] = useState(true);

	const [errorMessages, setErrorMessages] = useState({});
	const [triggerPaneUpdate, setTriggerPaneUpdate] = useState(false);

	const getCompanyData = (companyId) => {
		setLoading(true);
		api
			.get('companies/' + companyId)
			.then((response) => {
				setCompany(response.data);
				updateRecentlyViewed({
					key: response.data.createdBy,
					value: {
						_id: response.data._id,
						name: response.data.companyName,
						type: 'company',
					},
				});
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getTransactions = async (companyId) => {
		setLoading(true);
		await api
			.get('transactions/company/' + companyId)
			.then((response) => {
				if (response.data.length > 0) {
					let ongoingList = response.data.filter(
						(transaction) => transaction.status !== 'Completed'
					);
					let completedList = response.data.filter(
						(transaction) => transaction.status === 'Completed'
					);
					setOngoingTransactions(ongoingList);
					setCompletedTransactions(completedList);
				} else {
					setOngoingTransactions([]);
					setCompletedTransactions([]);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		getCompanyData(companyId);
		getTransactions(companyId);
	}, [companyId, triggerPaneUpdate]);

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};

	const handleExpand = (panel) => {
		switch (panel) {
			case 0:
				return setOpen0(!open0);
			case 1:
				return setOpen1(!open1);
			default:
				return;
		}
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;
		setCompany((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleDateChange = (target, date) => {
		setCompany((prev) => ({
			...prev,
			[target]: date,
		}));
	};

	const togglePaneUpdate = () => {
		if (!triggerPaneUpdate) {
			setTriggerPaneUpdate(true);
		}
		setTriggerPaneUpdate(!triggerPaneUpdate);
	};

	const handleValidation = () => {
		let result = Company.validate(company, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			console.log(error.details);
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = () => {
		console.log('handleSave');
		let valid = handleValidation();

		if (valid) {
			setLoading(true);
			console.log('send data');
			api.put('companies/' + company._id, company).then((res) => {
				if (res.data.ok) {
					setErrorMessages({});
					setEditable(false);
					setTriggerListUpdate(true);
					setLoading(false);
					togglePaneUpdate();
					toast.success('Company updated!');
				} else {
					toast.error(res.data.error);
					setLoading(false);
				}
			});
		} else {
			toast.error('An error has occured!');
		}
	};

	return (
		<div className={classes.root} ref={paneRef}>
			<AppBar
				elevation={1}
				position='static'
				style={{ color: theme.palette.text.disabled, backgroundColor: 'unset' }}
			>
				<Tabs
					value={value}
					onChange={handleChangeTab}
					variant='fullWidth'
					aria-label='full width tabs example'
				>
					<Tab className={classes.tabs} label='Profile' {...a11yProps(0)} />
					<Tab
						className={classes.tabs}
						label='Transactions'
						{...a11yProps(1)}
					/>
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0} dir={theme.direction}>
				<Box style={{ padding: 24 }} display='flex'>
					<Box style={{ margin: 'auto' }}>
						<Avatar style={{ width: 64, height: 64 }}>
							<CompanyIcon
								style={{ fontSize: 32 }}
								colorcode={theme.palette.primary.contrastText}
							/>
						</Avatar>
					</Box>
					<Box flexGrow={1} style={{ margin: 'auto 24px' }}>
						<>
							<Typography color='textPrimary' variant='h3' align='left'>
								{loading ? (
									<Skeleton variant='rect' animation='wave' />
								) : (
									<>{company.companyName}</>
								)}
							</Typography>
							<Typography
								style={{ marginTop: 6 }}
								color='textSecondary'
								variant='body1'
								align='left'
							>
								{loading ? (
									<Skeleton variant='rect' animation='wave' />
								) : (
									<>{company.uen}</>
								)}
							</Typography>
						</>
					</Box>
					<Box style={{ margin: 'auto' }}>
						<div>
							{editable ? (
								<Button
									variant='contained'
									color='primary'
									startIcon={
										<Save
											style={{ fontSize: 24 }}
											colorcode={theme.palette.primary.contrastText}
										/>
									}
									onClick={() => handleSave()}
								>
									Save
								</Button>
							) : (
								<Button
									variant='outlined'
									color='primary'
									startIcon={
										<Edit
											style={{ fontSize: 24 }}
											colorcode={theme.palette.tertiary.main}
										/>
									}
									onClick={() => setEditable(true)}
								>
									Edit Profile
								</Button>
							)}
						</div>
					</Box>
				</Box>
				<div
					className={
						isDesktopOrLaptop ? classes.tabArea : classes.tabAreaMobile
					}
				>
					<List disablePadding>
						<Divider />
						<ListItem
							classes={{
								root: classes.listItemRoot,
								button: classes.listItemButton,
							}}
							button
							onClick={() => handleExpand(0)}
						>
							<ListItemText
								primary='Company Information'
								primaryTypographyProps={{
									style: { textTransform: 'uppercase', fontWeight: 600 },
								}}
							/>
							{open0 ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse
							in={open0}
							timeout='auto'
							unmountOnExit
							style={{ padding: 24 }}
						>
							<CompanyInfo
								loading={loading}
								company={company}
								editable={editable}
								handleInputChange={handleInputChange}
								handleDateChange={handleDateChange}
								errorMessages={errorMessages}
							/>
						</Collapse>
						<Divider />
						<ListItem
							classes={{
								root: classes.listItemRoot,
								button: classes.listItemButton,
							}}
							button
							onClick={() => handleExpand(1)}
						>
							<ListItemText
								style={{ flex: 'unset', marginRight: 8 }}
								primary='Associated Contacts'
								primaryTypographyProps={{
									style: { textTransform: 'uppercase', fontWeight: 600 },
								}}
							/>
							<Tooltip
								content={
									<Typography variant='body1' color='textPrimary'>
										An Associated Contact is a Contact that is part of this
										Company.
									</Typography>
								}
							/>
							{open1 ? (
								<ExpandLess style={{ marginLeft: 'auto' }} />
							) : (
								<ExpandMore style={{ marginLeft: 'auto' }} />
							)}
						</ListItem>
						<Collapse in={open1} timeout='auto' unmountOnExit>
							<Contacts
								paneRef={paneRef.current}
								loading={loading}
								companyId={company._id}
								associatedContacts={company.associatedContacts}
								setTriggerListUpdate={setTriggerListUpdate}
								togglePaneUpdate={togglePaneUpdate}
								editable={editable}
							/>
						</Collapse>
					</List>
				</div>
			</TabPanel>
			<TabPanel value={value} index={1} dir={theme.direction}>
				<Transactions
					loading={loading}
					id={companyId}
					ongoingTransactions={ongoingTransactions}
					completedTransactions={completedTransactions}
					clientName={company.companyName}
				/>
			</TabPanel>
		</div>
	);
}
