import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	DoubleArrowCollapse,
	DoubleArrowExpand,
	Edit,
	EmailSent,
	RecentActivites,
} from '../../components/Icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TreeView from './TreeView';

function getTimeDifference(date) {
	let difference =
		moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(
			moment(date, 'DD/MM/YYYY HH:mm:ss')
		) / 1000;

	if (difference < 60) return `${Math.floor(difference)} seconds ago`;
	else if (difference < 3599)
		return `${Math.floor(difference / 60)} minutes ago`;
	else if (difference < 86399)
		return `${Math.floor(difference / 3660)} hour(s) ago`;
	else if (difference < 86400 * 30)
		return `${Math.floor(difference / 86400)} day(s) ago`;
	else if (difference < 86400 * 30 * 12)
		return `${Math.floor(difference / 86400 / 30)} month(s) ago`;
	else return `${moment(date).format('DD/MM/YYYY')}`;
}

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'inline-block',
		height: '100%',
		backgroundColor: 'unset',
		width: '100%',
		borderRadius: 8,
		boxShadow: '0px 4px 12px rgb(0 0 0 / 16%), 0px 0px 4px rgb(0 0 0 / 16%)',
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	table: {
		width: '100%',
		height: 'calc(100% - 56px)',
		display: 'inline-block',
		overflowX: 'hidden',
		overflowY: 'auto',
	},
	tableRow: {
		display: 'flex',
		width: '100%',
		height: '100%',
		'&:not(:first-child)': {
			marginTop: 16,
		},
	},
}));

function CustomTableRow(props) {
	const { activity, lastItem } = props;
	const theme = useTheme();
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	const toggleExpand = () => {
		setExpanded(!expanded);
	};

	const expandable = activity.auditLog !== undefined;

	return (
		<>
			<TableRow key={activity._id} className={classes.tableRow}>
				<TableCell
					style={{
						padding: 0,
						borderBottom: 'none',
					}}
				>
					{expandable ? (
						<Edit style={{ fontSize: 24 }} />
					) : (
						<EmailSent style={{ fontSize: 24 }} />
					)}
				</TableCell>
				<TableCell
					style={{
						padding: 0,
						borderBottom: 'none',
						margin: '0px 12px',
					}}
				>
					<Typography variant='subtitle1'>{activity.content}</Typography>
				</TableCell>
				<TableCell
					style={{
						padding: 0,
						borderBottom: 'none',
						minWidth: 120,
						marginLeft: 'auto',
					}}
				>
					<Typography variant='subtitle1' color='textSecondary'>
						{moment(new Date(activity.createdAt)).format('DD/MM/YYYY')}
					</Typography>
					<Typography variant='subtitle1' color='textSecondary'>
						{getTimeDifference(new Date(activity.createdAt))}
					</Typography>
				</TableCell>
			</TableRow>
			{expandable && (
				<TableRow
					style={{
						height: '100%',
					}}
				>
					<TableCell
						style={{ padding: '0px 0px 0px 40px', borderBottom: 'unset' }}
					>
						<Collapse in={expanded} unmountOnExit>
							<TreeView
								data={activity.auditLog}
								depth={0}
								expanded={expanded}
							/>
						</Collapse>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								cursor: 'pointer',
								width: 'fit-content',
							}}
							onClick={toggleExpand}
						>
							{expanded ? (
								<DoubleArrowCollapse colorcode={theme.palette.tertiary.main} />
							) : (
								<DoubleArrowExpand colorcode={theme.palette.tertiary.main} />
							)}
							<Typography
								style={{ color: theme.palette.tertiary.main, marginLeft: 4 }}
								variant='subtitle1'
							>
								{expanded ? 'Hide changes' : 'Show changes'}
							</Typography>
						</div>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}

export default function ActivityViewer(props) {
	const classes = useStyles();
	const [activities, setActivities] = useState([]);

	useEffect(() => {
		let preparedActivities = props.activities
			.map((activity) => {
				if (activity.auditLog !== undefined) {
					const newAuditLog = {};
					Object.keys(activity.auditLog).forEach((key) => {
						if (isNaN(key)) {
							newAuditLog[key] = activity.auditLog[key];
						} else {
							const newKey = `Quote ${parseInt(key) + 1}`;
							newAuditLog[newKey] = activity.auditLog[key];
						}
					});

					activity.auditLog = newAuditLog;
				}
				return activity;
			})
			.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

		setActivities(preparedActivities);
	}, [props]);

	return (
		<Card className={classes.content}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					padding: 24,
					height: 58,
				}}
			>
				<RecentActivites style={{ marginRight: 8, fontSize: 24 }} />
				<Typography color='textPrimary' variant='h4' align='left'>
					RECENT ACTIVITIES
				</Typography>
			</div>
			<Divider />

			{props.loading ? (
				<CircularProgress />
			) : (
				<>
					<table className={classes.table} cellSpacing='0'>
						<tbody style={{ display: 'inherit', width: '100%', padding: 24 }}>
							{activities.map((activity, index) => {
								return (
									<CustomTableRow
										key={activity._id}
										activity={activity}
										lastItem={activities.length - 1 === index}
									/>
								);
							})}
						</tbody>
					</table>
				</>
			)}
		</Card>
	);
}
