import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddCommentOutlinedIcon from '@material-ui/icons/AddCommentOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined';
import ReplyOutlinedIcon from '@material-ui/icons/ReplyOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Joi from 'joi';
import { Link as RouterLink } from 'react-router-dom';
import api from '../../../../../../config/api';
import { getPolicyDocument } from '../../../../../../utils/formHelper';
import Tooltip from '../../../../../../components/Tooltip';
import FileInfoCard from '../../../../../../components/FileInfoCard';
import FileUploader from '../../../../../../components/FileUploader';
import { getDiff } from '../../../../../../utils/formHelper';
import { ClaimInfo } from '../../../../../../utils/validators/schemas';
import TransactionModal from '../../../../../../components/Modals/transactions';
import { Edit, Save } from '../../../../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: 120,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	statusBar: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px 24px',
	},
}));

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

const initFormData = {
	accepted: true,
	claimAmt: null,
	incidentDate: null,
	insurerNotes: '',
	fileData: null,
};

const claimRejReasonList = ['Not applicable for claim', 'Other'];

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

const stepMapping = {
	'In-Progress:Pending Claim Info': true,
	'Approved In-Principle:Pending Claim Processing': true,
	'Completed:Claim Processed': true,
	'Completed:Claim Declined': true,
	Default: false,
};

function enableClaimInfo(transaction) {
	const { status, subStatus } = transaction;
	const key = `${status}:${subStatus}`;
	return stepMapping[key] || stepMapping['Default'];
}

export default function ClaimDetailsViewer(props) {
	const {
		transaction,
		setTransaction,
		setTriggerUpdate,
		setTriggerUpdateActivities,
	} = props;
	const classes = useStyles();
	const theme = useTheme();

	const isFirstEntry = transaction.subStatus === 'Pending Claim Info';

	const [optionData, setOptionData] = useState([]);
	const [editable, setEditable] = useState(false);
	const [currentData, setCurrentData] = useState(
		!isEmpty(transaction.claimData) ? cloneDeep(transaction.claimData) : null
	);
	const [updatedData, setUpdatedData] = useState(
		!isEmpty(transaction.claimData) ? cloneDeep(transaction.claimData) : null
	);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);

	const hasClaimData = () => {
		if (isEmpty(transaction.claimData)) {
			if (updatedData) {
				return true;
			}
			return false;
		} else {
			return true;
		}
	};

	const getPrincipalList = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('principals/list/active')
			.then((res) => {
				setOptionData(res.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		getPrincipalList();
	}, []);

	const getTextColor = (accepted) => {
		if (accepted) {
			return '#40BE79';
		} else {
			return '#F16063';
		}
	};

	const getStatusBarColor = (accepted) => {
		if (accepted) {
			return 'rgba(102, 203, 148, 0.2)';
		} else {
			return '#FCE9E9';
		}
	};

	const getStatusText = (accepted) => {
		if (accepted) {
			return 'Principal accepted the claim request';
		} else {
			return 'Principal declined the claim request';
		}
	};

	const handleFileChange = (e, files) => {
		e.preventDefault();
		setUpdatedData((prev) => ({
			...prev,
			fileData: files,
		}));
	};

	const handleClaimDataResponseChange = () => {
		setUpdatedData((prev) => ({
			...prev,
			accepted: !prev.accepted,
			claimAmt: null,
			incidentDate: null,
			insurerNotes: '',
			fileData: null,
			rejReason: '',
			rejRemarks: '',
		}));
	};

	const handleInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;

		const keys = name.split('.');
		const updateValue =
			type === 'number' && value !== '' ? parseFloat(value) : value;

		const updateNestedData = (data, keyIndex = 0) => {
			if (keyIndex === keys.length - 1) {
				return { ...data, [keys[keyIndex]]: updateValue };
			} else {
				return {
					...data,
					[keys[keyIndex]]: updateNestedData(
						data[keys[keyIndex]],
						keyIndex + 1
					),
				};
			}
		};

		setUpdatedData((prev) => {
			if (keys[0] === 'principalId') {
				let val = optionData.find((principal) => principal._id === value);
				return {
					...prev,
					principalId: val ? val._id : '',
					principalName: val ? val.shortName : '',
				};
			} else {
				return updateNestedData(prev);
			}
		});
	};

	const handleDateChange = (target, date) => {
		let formattedDate = moment(date).toISOString();
		setUpdatedData((prev) => ({
			...prev,
			[target]: formattedDate,
		}));
	};

	const handleValidation = () => {
		let errorData = {};
		let dataToValidate = {};
		let schemaObject = {};
		let result = {};
		let isValid = true;

		dataToValidate['claimData'] = updatedData;
		schemaObject['claimData'] = ClaimInfo;

		if (isFirstEntry && updatedData.accepted && isEmpty(updatedData.fileData)) {
			errorData['claimDocument'] = 'Claim Document is required';
			isValid = false;
		}

		let schema = Joi.object(schemaObject);
		result = schema.validate(dataToValidate, {
			abortEarly: false,
		});

		if (result.error === undefined && isValid) {
			setErrorMessages({});
			return true;
		} else {
			if (result.error) {
				console.log(result.error.details);
				for (let item of result.error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			console.log(errorData);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = () => {
		if (!loading) {
			console.log('handleSave');
			setLoading(true);

			let valid = handleValidation();
			if (valid) {
				let changedData = !isFirstEntry
					? getDiff(updatedData, currentData)
					: null;

				console.log(changedData);

				if (!changedData && !isFirstEntry) {
					setErrorMessages({});
					setEditable(false);
					setLoading(false);
				} else {
					try {
						const data = new FormData();
						let json = cloneDeep(updatedData);

						if (json.accepted && !isEmpty(json.fileData)) {
							data.append(
								'Claim Document',
								json.fileData[0],
								json.fileData[0].name
							);
							delete json.fileData;
						}

						let formData = {
							updatedFields: {
								claimData: json,
							},
							auditLog: changedData,
						};

						console.log(formData);
						data.append('json', JSON.stringify(formData));

						if (isFirstEntry) {
							api
								.put(
									`transactions/${transaction._id}/closeDirectPurchaseTransaction`,
									data
								)
								.then((res) => {
									if (res.data.ok) {
										setErrorMessages({});
										setEditable(false);
										setTriggerUpdate(true);
										toast.success('Claim Info saved!');
									} else {
										toast.error(res.data.error);
									}
								});
						} else {
							api.put('transactions/' + transaction._id, data).then((res) => {
								if (res.data.ok) {
									setErrorMessages({});
									setEditable(false);
									setTriggerUpdate(true);
									toast.success('Claim Info updated!');
								} else {
									toast.error(res.data.error);
								}
							});
						}
					} catch (error) {
						console.log(error);
						toast.error(error);
					} finally {
						setLoading(false);
					}
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	const handleCancel = () => {
		if (!currentData) {
			setUpdatedData(null);
		} else {
			setUpdatedData(cloneDeep(currentData));
		}
		setEditable(false);
	};

	const initClaimData = () => {
		setUpdatedData(cloneDeep(initFormData));
		setEditable(true);
	};

	const handleModalOpen = (body) => {
		setModalType(body);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalType(null);
	};

	const getActions = () => {
		if (editable) {
			return (
				<>
					<Grid item>
						<Button
							onClick={() => handleCancel()}
							variant='outlined'
							color='primary'
							className={classes.button}
						>
							Cancel
						</Button>
					</Grid>
					<Grid item>
						<Button
							onClick={() => handleSave()}
							variant='contained'
							color='primary'
							className={classes.button}
							startIcon={
								<Save
									style={{ fontSize: 24 }}
									colorcode={theme.palette.primary.contrastText}
								/>
							}
						>
							Save
						</Button>
					</Grid>
				</>
			);
		} else {
			return (
				<>
					<Grid item>
						<Button
							onClick={() => handleModalOpen('notifyClaim')}
							variant='contained'
							color='primary'
							className={classes.button}
						>
							Notify Client
						</Button>
					</Grid>
					<Grid item>
						<Button
							onClick={() => setEditable(true)}
							variant='outlined'
							color='primary'
							className={classes.button}
							startIcon={<Edit style={{ fontSize: 24 }} />}
						>
							Edit Details
						</Button>
					</Grid>
				</>
			);
		}
	};

	return (
		<>
			{enableClaimInfo(transaction) ? (
				<>
					{!hasClaimData() ? (
						<div className={classes.root}>
							<Button
								variant='contained'
								color='primary'
								startIcon={<AddCircleOutlineIcon />}
								onClick={() => initClaimData()}
							>
								Enter Claim Details
							</Button>
						</div>
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
							}}
						>
							{/* {!editable && ( */}
							<div
								className={classes.statusBar}
								style={{
									backgroundColor: getStatusBarColor(updatedData.accepted),
								}}
							>
								<Typography
									variant='body2'
									style={{
										color: getTextColor(updatedData.accepted),
									}}
								>
									Status: {getStatusText(updatedData.accepted)}
								</Typography>
							</div>
							{/* )} */}
							<div style={{ padding: '16px 24px', width: '100%' }}>
								{!isFirstEntry && (
									<Grid
										container
										direction='row'
										alignItems='center'
										justifyContent='flex-end'
										spacing={1}
									>
										{getActions()}
									</Grid>
								)}
								<Grid container spacing={4} style={{ paddingTop: 8 }}>
									{transaction.isDirectPurchase && isFirstEntry && editable && (
										<>
											<Grid item xs={12}>
												<Typography
													style={{ marginBottom: 8 }}
													color='textPrimary'
													variant='body2'
													align='left'
												>
													{`Was the Claim Request accepted by ${transaction.refTransaction.transactionId.policyData.principalName}?`}
												</Typography>
												<div className='switcher'>
													<div className='fieldset'>
														<input
															type='radio'
															name='accepted'
															value='true'
															id='accept'
															onClick={() => handleClaimDataResponseChange()}
															onChange={(e) => {}}
															checked={updatedData.accepted}
														/>
														<label htmlFor='accept'>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<CheckIcon
																	style={{ marginRight: 6, width: 16 }}
																/>
																Accept
															</div>
														</label>
														<input
															type='radio'
															name='accepted'
															value='false'
															id='decline'
															onClick={() => handleClaimDataResponseChange()}
															onChange={(e) => {}}
															checked={!updatedData.accepted}
														/>
														<label htmlFor='decline'>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<CloseIcon
																	style={{ marginRight: 6, width: 16 }}
																/>
																Decline
															</div>
														</label>
														<span className='cd-switch'></span>
													</div>
												</div>
											</Grid>
										</>
									)}
									{updatedData.accepted ? (
										<>
											<Grid item xs={12} sm={4}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Claim Amount
												</Typography>
												{editable ? (
													<TextField
														name='claimAmt'
														value={updatedData.claimAmt ?? ''}
														type='number'
														onChange={(e) => handleInputChange(e)}
														placeholder='Enter Claim Amount'
														variant='filled'
														fullWidth
														margin='dense'
														InputProps={{
															startAdornment: (
																<InputAdornment
																	style={{ marginTop: 0 }}
																	position='start'
																>
																	<AttachMoneyOutlinedIcon />
																</InputAdornment>
															),
														}}
														error={!!errorMessages[`claimData.claimAmt`]}
														helperText={
															errorMessages[`claimData.claimAmt`] &&
															errorMessages[`claimData.claimAmt`]
														}
													/>
												) : (
													<Typography
														color='textPrimary'
														variant='body1'
														align='left'
														style={{ marginTop: 8 }}
													>
														{updatedData.claimAmt &&
															currencyFormatter.format(updatedData.claimAmt)}
													</Typography>
												)}
											</Grid>
											<Grid item xs={12} sm={4}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Incident Date
												</Typography>
												{editable ? (
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<KeyboardDatePicker
															name='incidentDate'
															disableToolbar
															placeholder='DD/MM/YYYY'
															inputVariant='filled'
															openTo='year'
															views={['year', 'month', 'date']}
															InputProps={{
																style: { color: 'unset' },
															}}
															format='dd/MM/yyyy'
															margin='dense'
															fullWidth
															value={
																updatedData.incidentDate
																	? updatedData.incidentDate
																	: null
															}
															autoOk
															variant='inline'
															invalidDateMessage='Invalid Date Format'
															onChange={(date) =>
																handleDateChange('incidentDate', date)
															}
															error={!!errorMessages[`claimData.incidentDate`]}
															helperText={
																errorMessages[`claimData.incidentDate`] &&
																errorMessages[`claimData.incidentDate`]
															}
														/>
													</MuiPickersUtilsProvider>
												) : (
													<Typography
														color='textPrimary'
														variant='body1'
														align='left'
														style={{ marginTop: 8 }}
													>
														{updatedData.incidentDate
															? moment(updatedData.incidentDate).format(
																	'DD/MM/YYYY'
															  )
															: null}
													</Typography>
												)}
											</Grid>
											<Grid item xs={12}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Notes
												</Typography>
												{editable ? (
													<TextField
														name='insurerNotes'
														value={updatedData.insurerNotes || ''}
														onChange={(e) => handleInputChange(e)}
														InputProps={{
															style: { color: 'unset' },
														}}
														variant={'filled'}
														fullWidth
														margin='dense'
														multiline
														minRows={4}
														error={!!errorMessages[`claimData.insurerNotes`]}
														helperText={
															errorMessages[`claimData.insurerNotes`] &&
															errorMessages[`claimData.insurerNotes`]
														}
													/>
												) : (
													<Typography
														color='textPrimary'
														variant='body1'
														align='left'
														style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
													>
														{updatedData.insurerNotes
															? updatedData.insurerNotes
															: 'N/A'}
													</Typography>
												)}
											</Grid>
											<Grid item xs={12}>
												{isFirstEntry && editable && (
													<>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
															}}
														>
															<Typography
																style={{ marginRight: 8 }}
																color='textPrimary'
																variant='body2'
																align='left'
															>
																Upload Claim Document
															</Typography>
															<Tooltip content={printReqString()} />
														</div>
														<div
															style={{
																width: '100%',
																marginTop: 8,
																marginBottom: 4,
															}}
														>
															<FileUploader
																fileLimit={1}
																files={updatedData.fileData || null}
																handleFileChange={handleFileChange}
																formErrorMessage={
																	errorMessages[`claimDocument`] &&
																	errorMessages[`claimDocument`]
																}
															/>
														</div>
													</>
												)}
											</Grid>
										</>
									) : (
										<>
											<Grid item xs={4}>
												<Typography color='textPrimary' variant='body2'>
													Reason for Declining Request
												</Typography>
												{editable ? (
													<FormControl
														style={{ width: '100%' }}
														error={!!errorMessages[`claimData.rejReason`]}
													>
														<Select
															name='rejReason'
															value={updatedData.rejReason}
															onChange={(e) => handleInputChange(e)}
															variant='filled'
															fullWidth
															autoWidth={true}
															margin='dense'
															style={{ marginTop: 8, marginBottom: 4 }}
															error={!!errorMessages[`claimData.rejReason`]}
														>
															{claimRejReasonList.map((val, index) => {
																return (
																	<MenuItem key={index} value={val}>
																		{val}
																	</MenuItem>
																);
															})}
														</Select>
														<FormHelperText>
															{errorMessages[`claimData.rejReason`] &&
																errorMessages[`claimData.rejReason`]}
														</FormHelperText>
													</FormControl>
												) : (
													<Typography
														color='textPrimary'
														variant='body1'
														align='left'
														style={{ marginTop: 8 }}
													>
														{updatedData.rejReason}
													</Typography>
												)}
											</Grid>
											<Grid item xs={12}>
												<Typography color='textPrimary' variant='body2'>
													Remarks for Declining Request
												</Typography>
												{editable ? (
													<TextField
														name='rejRemarks'
														value={updatedData.rejRemarks || ''}
														multiline
														InputProps={{
															style: { color: 'unset' },
														}}
														onChange={(e) => handleInputChange(e)}
														variant={'filled'}
														fullWidth
														margin='dense'
														minRows={4}
														error={!!errorMessages[`claimData.rejRemarks`]}
														helperText={
															errorMessages[`claimData.rejRemarks`] &&
															errorMessages[`claimData.rejRemarks`]
														}
													/>
												) : (
													<Typography
														color='textPrimary'
														variant='body1'
														align='left'
														style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
													>
														{updatedData.rejRemarks}
													</Typography>
												)}
											</Grid>
										</>
									)}
								</Grid>
								{isFirstEntry && editable && (
									<>
										<Divider style={{ margin: '16px -24px' }} />
										<div
											id='footer-action-container'
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
											}}
										>
											<Button
												onClick={() => handleCancel()}
												variant='outlined'
												color='primary'
												className={classes.button}
											>
												Cancel
											</Button>
											<Button
												onClick={() => handleSave()}
												variant='contained'
												color='primary'
												className={classes.button}
												style={{ marginLeft: 8 }}
											>
												Save
											</Button>
										</div>
									</>
								)}
							</div>
						</div>
					)}
					{modalOpen && (
						<TransactionModal
							open={modalOpen}
							handleClose={handleModalClose}
							body={modalType}
							transactionId={transaction._id}
							setTriggerUpdate={setTriggerUpdate}
							size={modalType === 'sendRequestForClaims' ? 'md' : 'sm'}
						/>
					)}
				</>
			) : (
				<div className={classes.root}>
					<Typography color='textSecondary' variant='subtitle1'>
						This section will display information once Insurer has responded to
						the Claim Request.
					</Typography>
				</div>
			)}
		</>
	);
}
