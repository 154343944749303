import { Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Client, Underwriter } from './fieldList';

const useStyles = makeStyles((theme) => ({
	mapTable: {
		height: '45vh',
		borderSpacing: 0,
		borderRadius: 8,
	},
	tableHeader: {
		fontWeight: 600,
		padding: 24,
		background: theme.palette.background.secondary,
		textTransform: 'uppercase',
		borderBottom: `2px solid ${theme.palette.primary.light}`,
	},
	tableCell: {
		padding: 24,
		textAlign: 'center',
		borderBottom: 'unset',
	},
	arrow: {
		width: 45,
		borderBottom: 'unset',
	},
}));

const fieldMap = { Client, Underwriter };

const Step4 = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	const headerList = () => {
		return props.importData.map(function (currentHeader, i) {
			return (
				<TableRow
					key={i}
					style={{
						backgroundColor: !currentHeader.header && theme.palette.grey[300],
					}}
				>
					<TableCell className={classes.tableCell}>
						{currentHeader.field.length > 0 && !currentHeader.skip ? (
							<CheckCircleIcon
								style={{
									color: theme.palette.success.main,
									height: 24,
									width: 24,
								}}
							/>
						) : null}
					</TableCell>
					<TableCell
						className={classes.tableCell}
						style={{
							textAlign: 'left',
						}}
					>
						{currentHeader.header ? currentHeader.header : '[BLANK]'}
					</TableCell>
					<TableCell className={classes.arrow}>
						<ArrowForwardIcon />
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Select
							native
							name='field'
							disabled={!currentHeader.header || currentHeader.skip}
							value={currentHeader.field}
							onChange={(e) => props.onChangeMapping(e, i)}
							variant='outlined'
							fullWidth
							margin='dense'
						>
							{fieldMap[props.type]
								.filter(
									(field) =>
										!props.importData.some((row) => {
											if (
												row.field === '' ||
												row.field === currentHeader.field
											) {
												return false;
											} else {
												return field.value === row.field;
											}
										})
								)
								.map((option, index) => {
									if (option.value === 'contactGroup') {
										return (
											<optgroup key={option.value} label={option.display} />
										);
									}
									if (option.value === 'companyGroup') {
										return (
											<optgroup key={option.value} label={option.display} />
										);
									}
									return (
										<option key={option.value} value={option.value}>
											{option.display}
										</option>
									);
								})}
						</Select>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Checkbox
							checked={!currentHeader.header || currentHeader.skip}
							disabled={!currentHeader.header}
							onChange={(e) => props.onChangeCheckbox(e, i)}
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
					</TableCell>
				</TableRow>
			);
		});
	};

	const hasError = () => {
		if (
			props.errorMessages['noValidClient'] !== undefined ||
			props.errorMessages['invalidContact'] !== undefined ||
			props.errorMessages['invalidCompany'] !== undefined
		) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			<Typography variant='h3'>Assign {props.type} Fields</Typography>
			<Typography variant='body1' style={{ marginTop: 16 }}>
				Please assign your external file's data to the corresponding attributes
				in the CRM.
			</Typography>
			{hasError() && (
				<div
					style={{
						backgroundColor: '#FCE9E9',
						padding: '16px 24px',
						borderRadius: 4,
						marginTop: 24,
						marginBottom: 24,
					}}
				>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<HighlightOffIcon style={{ color: '#F60C36' }} />
						<div style={{ marginLeft: 16 }}>
							<Typography
								style={{ color: theme.palette.error.main, marginBottom: 8 }}
								variant='body2'
							>
								Error
							</Typography>
							{props.errorMessages['noValidClient'] !== undefined && (
								<Typography
									style={{ color: theme.palette.error.main }}
									variant='body1'
								>
									{props.errorMessages['noValidClient']}
								</Typography>
							)}
							{props.errorMessages['invalidContact'] !== undefined && (
								<Typography
									style={{ color: theme.palette.error.main }}
									variant='body1'
								>
									{props.errorMessages['invalidContact']}
								</Typography>
							)}
							{props.errorMessages['invalidCompany'] !== undefined && (
								<Typography
									style={{ color: theme.palette.error.main }}
									variant='body1'
								>
									{props.errorMessages['invalidCompany']}
								</Typography>
							)}
						</div>
					</div>
				</div>
			)}
			<Grid container spacing={4} style={{ marginTop: 16 }}>
				<Grid item xs={8}>
					<TableContainer className={classes.mapTable}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell
										className={classes.tableHeader}
										style={{
											textAlign: 'center',
										}}
									>
										Assigned
									</TableCell>
									<TableCell className={classes.tableHeader}>
										File Column Header
									</TableCell>
									<TableCell className={classes.tableHeader} />
									<TableCell className={classes.tableHeader}>
										Assign to
									</TableCell>
									<TableCell
										className={classes.tableHeader}
										style={{
											textAlign: 'center',
										}}
									>
										Don't Import
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody
								style={{ backgroundColor: theme.palette.secondary.light }}
							>
								{headerList()}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={4}>
					<Typography variant='body2'>How to assign fields</Typography>
					<Typography variant='body1' style={{ marginTop: 16 }}>
						"First Name" and "Contact Email" are required fields. If there are
						fields in the file that you do not wish to assign in the CRM, please
						select "Don't Import" checkbox next to the "Assign To" field.
					</Typography>
					<Typography variant='body1' style={{ marginTop: 16 }}>
						You may proceed with import once you have assigned your fields.
					</Typography>
					<Typography variant='body1' style={{ marginTop: 16 }}>
						This assignment may take a few minutes.
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default Step4;
