import { IconButton, Menu, MenuItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import firebase from 'firebase/compat/app';
import React, { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { Logout, Settings } from '../Icons';

const useStyles = makeStyles((theme) => ({
	headerMenu: {
		marginTop: theme.spacing(10),
	},
	headerMenuItem: {
		color: theme.palette.primary.main,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	profileMenu: {
		top: '0px !important',
		minWidth: 296,
		borderRadius: '0px 0px 4px 4px',
		background: theme.palette.background.default,
		boxShadow:
			'0px 0px 1px rgba(12, 26, 75, 0.2), 0px 1px 3px rgba(50, 50, 71, 0.1)',
	},
	profileMenuIcon: {
		marginRight: 16,
		fontSize: 24,
		color: theme.palette.primary.main,
	},
	profileMenuLink: {
		fontSize: '1rem',
		textDecoration: 'none',
		'&:hover': {
			cursor: 'pointer',
		},
		padding: '8px 24px',
	},
}));

const ProfileMenu = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const { isValidSubscription, user, isDesktopOrLaptop } = props;
	const [profileMenu, setProfileMenu] = useState(null);

	const handleSignOut = async (e) => {
		e.preventDefault();
		if (process.env.REACT_APP_ENVIRONMENT === 'demo') {
			window.location.href = 'https://www.uuoni.com';
		} else {
			await firebase
				.auth()
				.signOut()
				.then(function () {
					console.log('Successfully signed out.');
					history.push('/');
				})
				.catch(function (error) {
					console.log(error);
					console.log('An error occurred');
				});
		}
	};

	return (
		<>
			{isDesktopOrLaptop ? (
				<Button
					style={{
						height: 80,
						borderRadius: 0,
						width: 296,
						backgroundColor: profileMenu && theme.palette.primary.light,
					}}
					disableRipple
					disableFocusRipple
					disableElevation
					onClick={(e) => {
						setProfileMenu(e.currentTarget);
					}}
					startIcon={
						<Avatar
							style={{
								marginRight: 8,
							}}
							alt={user.userProfile.profile.firstName}
							src={
								user.userProfile.avatarImage !== null
									? `data:image/*;base64,${user.userProfile.avatarImage}`
									: ''
							}
						/>
					}
					endIcon={
						isDesktopOrLaptop && (
							<ExpandMore
								style={{
									marginLeft: 8,
									color: theme.palette.secondary.light,
								}}
							/>
						)
					}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							flexGrow: 1,
							overflowWrap: 'anywhere',
							textAlign: 'start',
						}}
					>
						{isEmpty(user.userProfile.profile) ? (
							<Typography
								variant='body1'
								style={{
									fontWeight: 500,
									color: theme.palette.secondary.light,
								}}
							>
								{user.authUser._delegate.email}
							</Typography>
						) : (
							<>
								<Typography
									variant='body1'
									style={{
										fontWeight: 500,
										textTransform: 'capitalize',
										color: theme.palette.secondary.light,
									}}
								>
									{user.userProfile.profile.firstName}{' '}
									{user.userProfile.profile.lastName}
								</Typography>
								<Typography
									variant='body1'
									style={{
										textTransform: 'none',
										color: theme.palette.secondary.dark,
									}}
								>
									{user.userProfile.profile.agentId && (
										<>Agent ID: {user.userProfile.profile.agentId}</>
									)}
								</Typography>
							</>
						)}
					</div>
				</Button>
			) : (
				<IconButton
					style={{
						backgroundColor: profileMenu && theme.palette.primary.light,
					}}
					size={isDesktopOrLaptop ? 'medium' : 'small'}
					onClick={(e) => {
						setProfileMenu(e.currentTarget);
					}}
				>
					<Avatar
						alt={user.userProfile.profile.firstName}
						src={
							user.userProfile.avatarImage !== null &&
							`data:image/*;base64,${user.userProfile.avatarImage}`
						}
					/>
				</IconButton>
			)}

			<Menu
				id='profile-menu'
				open={Boolean(profileMenu)}
				anchorEl={profileMenu}
				onClose={() => setProfileMenu(null)}
				className={classes.headerMenu}
				classes={{ paper: classes.profileMenu }}
				disableAutoFocusItem
			>
				{isValidSubscription && (
					<MenuItem
						className={classNames(
							classes.profileMenuLink,
							classes.headerMenuItem
						)}
						onClick={() => setProfileMenu(null)}
						component={Link}
						to={'/settings/general'}
						style={{ textDecoration: 'none' }}
					>
						<Settings
							className={classes.profileMenuIcon}
							colorcode={theme.palette.primary.main}
						/>{' '}
						Settings
					</MenuItem>
				)}
				<MenuItem
					className={classNames(
						classes.profileMenuLink,
						classes.headerMenuItem
					)}
					onClick={handleSignOut}
				>
					<Logout
						className={classes.profileMenuIcon}
						colorcode={theme.palette.primary.main}
					/>{' '}
					Sign Out
				</MenuItem>
			</Menu>
		</>
	);
};

export default ProfileMenu;
