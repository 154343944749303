import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		backgroundColor: theme.palette.primary.main,
		zIndex: theme.zIndex.drawer + 1,
		boxShadow: 'none',
	},
	alertBar: {
		borderRadius: 0,
		position: 'absolute',
		alignItems: 'center',
		top: 80,
		width: '100%',
		padding: '8px 24px',
		zIndex: theme.zIndex.drawer + 1,
		background: theme.palette.secondary.main,
		boxShadow: '0px 4px 12px rgb(0 0 0 / 16%), 0px 0px 4px rgb(0 0 0 / 16%)',
		color: theme.palette.info.main,
		fontWeight: 400,
		'& .MuiAlert-message': {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
		},
	},
	toolBar: {
		paddingLeft: 32,
		paddingRight: 32,
		minHeight: 80,
	},
	toolBarMobile: {
		paddingLeft: 24,
		paddingRight: 24,
		minHeight: 80,
	},
	resourceDrawer: {
		display: 'contents',
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: 280,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		backgroundColor: theme.palette.secondary.light,
		boxShadow: '0px 4px 4px rgb(0 0 0 / 25%), 0px 2px 8px rgb(0 0 0 / 16%)',
	},
	drawerPaper: {
		width: 280,
	},
	drawerMobile: {
		display: 'contents',
	},

	drawerContainer: {
		overflow: 'auto',
	},
	drawerHeader: {},
	drawerOpen: {
		width: 280,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
		backgroundColor: theme.palette.secondary.light,
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: 88,
		backgroundColor: theme.palette.secondary.light,
	},
	menuButton: {
		marginRight: 20,
	},
	menuButtonMobile: {
		marginRight: 12,
	},
	nested: {
		paddingLeft: 32,
		paddingTop: 16,
		paddingBottom: 16,
	},
	content: {
		position: 'relative',
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),

		marginTop: 80,
		overflowY: 'auto',
		height: 'calc(100vh - 80px)',
	},
	contentShift: {
		position: 'relative',
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
		marginTop: 80,
		overflowY: 'auto',
		height: 'calc(100vh - 80px)',
	},
	landingContent: {
		flexGrow: 1,
		height: '100vh',
	},
	paddingOverride: {
		paddingLeft: 32,
		paddingRight: 32,
		paddingTop: 16,
		paddingBottom: 16,
	},
	paddingOverrideSub: {
		paddingLeft: 32,
		paddingTop: 16,
		paddingBottom: 16,
	},
	grow: {
		marginLeft: 16,
	},
	growMobile: {
		marginLeft: 4,
	},
	headerIcon: {
		fontSize: 32,
		color: theme.palette.background.default,
	},
	headerIconMobile: {
		fontSize: 28,
		color: theme.palette.background.default,
	},
	toastContainerAuth: {
		top: '96px !important',
		right: '24px !important',
	},
}));
