import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React, { useRef, useEffect, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Delete } from '../../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	tableRow: {
		backgroundColor: theme.palette.background.default,
	},
	fileInput: {
		display: 'none',
	},
}));

function validateFileExtension(name) {
	if (!name) {
		return false;
	}

	let validExtensions = [
		'xls',
		'xlsx',
		'xlsm',
		'csv',
		'doc',
		'docx',
		'pdf',
		'txt',
		'jpg',
		'jpeg',
		'png',
		'bmp',
		'avi',
		'mp4',
		'mpeg',
		'webm',
	];

	const fileExtension = name.split('.').pop().toLowerCase();

	if (validExtensions.includes(fileExtension)) {
		return true;
	} else {
		return false;
	}
}

function validateFileType(type) {
	if (!type) {
		return false;
	}

	let validTypes = [
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.ms-excel',
		'text/csv',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/pdf',
		'text/plain',
		'image/jpeg',
		'image/jpg',
		'image/png',
		'image/bmp',
		'video/x-msvideo',
		'video/mp4',
		'video/mpeg',
		'video/webm',
	];

	if (validTypes.includes(type)) {
		return true;
	} else {
		return false;
	}
}

export default function DocumentRow(props) {
	const { item, handleRemoveDocument, handleDocumentChange, formErrorMessage } =
		props;
	const [open, setOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const classes = useStyles();
	const theme = useTheme();
	const fileInputRef = useRef();

	useEffect(() => {
		if (formErrorMessage) {
			setErrorMessage(formErrorMessage);
		}
	}, [props]);

	const handleFiles = (files) => {
		if (!files || files.length === 0) {
			return;
		}

		if (files.length > 1) {
			setErrorMessage(`Only 1 file is permitted`);
		} else {
			let result = validateFile(files[0]);
			if (result.valid) {
				setErrorMessage('');
				handleDocumentChange(item.id, files[0]);
			} else {
				setErrorMessage(result.errorMessage);
			}
		}
	};

	const getAcceptedTypes = (documentType) => {
		switch (documentType) {
			case 'Document': {
				return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf, text/plain';
			}
			case 'Photo': {
				return 'image/jpeg, image/jpg, image/png, image/bmp';
			}
			case 'Video': {
				return 'video/x-msvideo, video/mp4, video/mpeg, video/webm';
			}
			default: {
				return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf, text/plain';
			}
		}
	};

	const validateFile = (file) => {
		let valid = true;
		let errorMessage = '';
		let validFile =
			validateFileExtension(file.name) && validateFileType(file.type);
		let validSize = file.size < 52428800;

		if (!validFile) {
			valid = false;
			errorMessage = 'Invalid file type';
		} else if (!validSize) {
			valid = false;
			errorMessage = 'File size cannot exceed 50mb';
		}

		return {
			valid: valid,
			errorMessage: errorMessage,
		};
	};

	const removeFile = (e) => {
		e.preventDefault();
		setErrorMessage('');
		fileInputRef.current.value = null;
		handleDocumentChange(item.id, null);
	};

	const fileSize = (size) => {
		if (size === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const i = Math.floor(Math.log(size) / Math.log(k));
		return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
	};

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	return (
		<>
			<TableRow className={classes.tableRow}>
				<TableCell style={{ width: '20%' }} component='th' scope='row'>
					<Typography color='textPrimary' variant='body1'>
						{item.name}
					</Typography>
				</TableCell>
				<TableCell style={{ width: '70%' }}>
					<input
						ref={fileInputRef}
						className={classes.fileInput}
						id={item.id}
						type='file'
						onChange={(e) => handleFiles(e.target.files)}
					/>
					{item.fileData ? (
						<label htmlFor={item.id} style={{ cursor: 'pointer' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: 24 }}>
									<FileIcon
										labelUppercase
										extension={fileType(item.fileData.name)}
										{...defaultStyles[fileType(item.fileData.name)]}
									/>
								</div>
								<Typography
									style={{ width: 320, marginLeft: 16 }}
									color='textPrimary'
									variant='body1'
								>
									{item.fileData.name}
								</Typography>
								<Typography
									style={{ width: 80, marginLeft: 16 }}
									color='textSecondary'
									variant='body1'
								>
									{fileSize(item.fileData.size)}
								</Typography>
								<Typography
									style={{ marginLeft: 16 }}
									onClick={(e) => removeFile(e)}
									color='error'
									variant='body1'
									align='right'
								>
									X
								</Typography>
							</div>
						</label>
					) : (
						<label htmlFor={item.id}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								{errorMessage && (
									<Typography
										color='error'
										variant='body1'
										style={{ marginRight: 32 }}
									>
										{errorMessage}
									</Typography>
								)}
								<Button variant='outlined' color='primary' component='span'>
									Upload a File
								</Button>
							</div>
						</label>
					)}
				</TableCell>
				<TableCell style={{ width: '10%', textAlign: 'center' }}>
					<IconButton
						aria-label='expand row'
						size='small'
						onClick={() => handleRemoveDocument(item.id)}
					>
						<Delete
							style={{ fontSize: 24 }}
							colorcode={theme.palette.text.secondary}
						/>
					</IconButton>
				</TableCell>
			</TableRow>
		</>
	);
}
