import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, {
	createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import api from '../../config/api';
import { Tag } from '../Icons';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: 0,
		margin: 0,
		marginTop: 16,
	},
	paper: {
		width: 562,
		padding: 32,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
	chip: {
		margin: theme.spacing(0.5),
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.background.default,
	},
}));

const filter = createFilterOptions();

const TagMenu = (props) => {
	const {
		variant,
		open,
		anchorEl,
		position,
		type,
		action,
		selectedTag,
		handleAddTag,
		handleUpdateTag,
		closeTagMenu,
		errorMessages,
		formTags,
		handleUpdateTags,
	} = props;

	const [tagList, setTagList] = useState([]);
	const [tagLabel, setTagLabel] = useState('');
	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [selectedTags, setSelectedTags] = useState([]);
	const [inputErrorMessages, setInputErrorMessages] = useState('');
	const [loading, setLoading] = useState(false);
	const classes = useStyles();
	const theme = useTheme();

	const getData = async () => {
		try {
			setLoading(true);
			const res = await api.get('settings/tags');
			if (res.data.ok && res.data.data.length > 0) {
				setTagList(res.data.data);
			}
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (variant !== 'mini') {
			getData();
		}
	}, [variant]);

	useEffect(() => {
		if (inputValue) {
			setInputValue('');
		}
	}, []);

	useEffect(() => {
		if (selectedTag) {
			setTagLabel(selectedTag);
		} else {
			setTagLabel('');
		}
	}, [selectedTag]);

	useEffect(() => {
		if (
			(type === 'likes' || type === 'dislikes') &&
			formTags[type] !== undefined
		) {
			setSelectedTags(formTags[type]);
		}
	}, [type]);

	const saveTag = async (tag) => {
		try {
			setLoading(true);
			let updatedTags = [...tagList];
			updatedTags.push(tag);
			await api.put('settings/tags', updatedTags).then((res) => {
				if (res.data.ok) {
					setTagList(updatedTags);
					setTagLabel('');
				}
			});
		} catch (error) {
			console.log('error', error);
		} finally {
			setLoading(false);
		}
	};

	const handleInputChange = (e) => {
		setTagLabel(e.target.value);
	};

	const handleChange = (option) => {
		if (!option) return;

		let updatedSelectedTags = [...selectedTags];
		let newTag = '';

		if (typeof option === 'string') {
			newTag = option.trim();
		} else if (option && option.inputValue) {
			newTag = option.inputValue.trim();
			saveTag(newTag);
		} else {
			newTag = option.label;
		}

		if (
			updatedSelectedTags.some(
				(tag) => String(tag).toLowerCase() === String(newTag).toLowerCase()
			)
		) {
			return setInputErrorMessages(
				'Tag exists. Please enter another tag name.'
			);
		} else {
			updatedSelectedTags.push(newTag);
		}

		setInputErrorMessages('');
		setValue(null);
		setInputValue('');
		setSelectedTags(updatedSelectedTags);
	};

	const handleDeleteTag = (tag) => {
		let updatedSelectedTags = [...selectedTags].filter((item) => item !== tag);
		setSelectedTags(updatedSelectedTags);
	};

	const options = tagList.map((option) => {
		const firstLetter = option.charAt(0).toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
			label: option,
		};
	});

	if (variant === 'mini') {
		return (
			<Dialog
				open={open}
				onClose={closeTagMenu}
				hideBackdrop
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<div className={classes.paper}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography color='textPrimary' variant='h3'>
							{action === 'add' && 'Create a new tag'}
							{action === 'update' && 'Update an existing tag'}
						</Typography>
						<IconButton style={{ marginLeft: 'auto' }} onClick={closeTagMenu}>
							<CloseIcon />
						</IconButton>
					</div>
					<Typography
						color='textPrimary'
						variant='body2'
						style={{ marginTop: 16 }}
					>
						Tag Name
					</Typography>
					<TextField
						id='standard-full-width'
						value={tagLabel}
						placeholder='Enter tag name'
						fullWidth
						margin='dense'
						InputLabelProps={{
							shrink: true,
						}}
						variant='filled'
						onChange={(e) => handleInputChange(e)}
						error={!!errorMessages[`tagLabel`]}
						helperText={errorMessages[`tagLabel`] && errorMessages[`tagLabel`]}
					/>
					<div
						style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}
					>
						{action === 'add' && (
							<Button
								onClick={() => {
									handleAddTag(tagLabel.trim(), type);
								}}
								disabled={tagLabel.trim().length === 0}
								variant='contained'
								color='primary'
								className={classes.button}
								startIcon={
									<Tag
										style={{ fontSize: 24 }}
										colorcode={
											tagLabel.trim().length === 0
												? theme.palette.grey[500]
												: theme.palette.background.default
										}
									/>
								}
							>
								Create
							</Button>
						)}
						{action === 'update' && (
							<Button
								onClick={() => {
									handleUpdateTag(tagLabel.trim(), type);
								}}
								disabled={tagLabel.trim().length === 0}
								variant='contained'
								color='primary'
								className={classes.button}
								startIcon={
									<Tag
										style={{ fontSize: 24 }}
										colorcode={
											tagLabel.trim().length === 0
												? theme.palette.grey[500]
												: theme.palette.background.default
										}
									/>
								}
							>
								Update
							</Button>
						)}
					</div>
				</div>
			</Dialog>
		);
	}
	return (
		<Popper
			open={open}
			anchorEl={anchorEl}
			placement={position}
			disablePortal={false}
			modifiers={{
				flip: {
					enabled: false,
				},
				preventOverflow: {
					enabled: true,
					boundariesElement: 'scrollParent',
				},
				offset: {
					enabled: true,
					offset: '0, 30',
				},
			}}
		>
			<div className={classes.paper}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography color='textPrimary' variant='h3'>
						{`Client's ${type === 'likes' ? 'Likes' : 'Dislikes'}`}
						{/* Client's {type === 'likes' ? 'Likes' : 'Dislikes'} */}
					</Typography>
					<IconButton style={{ marginLeft: 'auto' }} onClick={closeTagMenu}>
						<CloseIcon />
					</IconButton>
				</div>
				<Typography
					color='textPrimary'
					variant='body2'
					style={{ marginTop: 16, marginBottom: 8 }}
				>
					Search or Create a Tag
				</Typography>

				<Autocomplete
					value={value}
					onChange={(event, newValue) => {
						handleChange(newValue);
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					filterOptions={(options, params) => {
						const filtered = filter(options, params);
						if (
							params.inputValue !== '' &&
							!filtered.some(
								(option) =>
									option.label.toLowerCase() === inputValue.trim().toLowerCase()
							)
						) {
							filtered.push({
								inputValue: params.inputValue,
								label: `Add "${params.inputValue}"`,
							});
						}
						return filtered;
					}}
					selectOnFocus
					blurOnSelect
					clearOnBlur
					clearOnEscape
					handleHomeEndKeys
					id='free-solo-with-text-demo'
					loading={loading}
					options={options.sort(
						(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
					)}
					noOptionsText=''
					getOptionLabel={(option) => {
						if (typeof option === 'string') {
							return option;
						}
						if (option.inputValue) {
							return option.inputValue;
						}
						return option.label;
					}}
					renderOption={(option) => option.label}
					renderInput={(params) => (
						<TextField
							{...params}
							variant='filled'
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{loading ? (
											<CircularProgress color='inherit' size={20} />
										) : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
							error={!!errorMessages[`tagLabel`] || !!inputErrorMessages}
							helperText={
								(inputErrorMessages && inputErrorMessages) ||
								(errorMessages[`tagLabel`] && errorMessages[`tagLabel`])
							}
						/>
					)}
				/>

				<Divider style={{ margin: '32px 0px' }} />
				<Typography color='textPrimary' variant='body2'>
					Selected Tags
				</Typography>
				<ul className={classes.root}>
					{selectedTags.length > 0 &&
						selectedTags.map((tag, index) => {
							return (
								<li style={{ float: 'left' }} key={index}>
									<Chip
										label={tag}
										className={classes.chip}
										onDelete={() => handleDeleteTag(tag)}
										color='primary'
									/>
								</li>
							);
						})}
				</ul>
				<Divider style={{ margin: '24px 0px' }} />
				<div style={{ display: 'flex' }}>
					<Button
						onClick={() => {
							setSelectedTags([]);
						}}
						variant='outlined'
						color='primary'
						className={classes.button}
					>
						Clear Selected
					</Button>
					<Button
						style={{ marginLeft: 'auto' }}
						onClick={() => {
							handleUpdateTags(selectedTags, type);
						}}
						variant='contained'
						color='primary'
						className={classes.button}
					>
						Update
					</Button>
				</div>
			</div>
		</Popper>
	);
};

export default TagMenu;
