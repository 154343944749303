import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import Mappings from '../../Data/mappings.json';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	ActivityCollapse,
	ActivityDetailArrow,
	ActivityExpand,
} from '../../Icons';

export default function TreeView({ data, depth }) {
	const theme = useTheme();
	const [expanded, setExpanded] = useState({});

	const toggleExpand = (key) => {
		setExpanded((prevState) => ({
			...prevState,
			[key]: !prevState[key],
		}));
	};

	const getIndentation = () => {
		return depth * 32;
	};

	return (
		<>
			{Object.entries(data).map(([key, value], index) => {
				const isObject =
					typeof value === 'object' &&
					!value.hasOwnProperty('old') &&
					!value.hasOwnProperty('new');

				return (
					<React.Fragment key={index}>
						{isObject && (
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									marginLeft: getIndentation(),
								}}
							>
								<ActivityDetailArrow style={{ fontSize: 24 }} />
								<Typography
									variant='subtitle1'
									style={{
										margin: '0px 8px',

										fontWeight: 600,
									}}
								>
									{Mappings[key] ? Mappings[key] : key}
								</Typography>
								{expanded[key] ? (
									<ActivityCollapse
										style={{ fontSize: 24 }}
										colorcode={theme.palette.tertiary.main}
										onClick={() => toggleExpand(key)}
									/>
								) : (
									<ActivityExpand
										style={{ fontSize: 24 }}
										colorcode={theme.palette.tertiary.main}
										onClick={() => toggleExpand(key)}
									/>
								)}
							</div>
						)}
						{isObject && (
							<Collapse in={expanded[key]} unmountOnExit>
								<TreeView data={value} depth={depth + 1} />
							</Collapse>
						)}
						{!isObject && (
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									marginLeft: getIndentation(),
								}}
							>
								<ActivityDetailArrow style={{ fontSize: 24 }} />
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginLeft: 8,
									}}
								>
									<Typography
										variant='subtitle1'
										style={{
											fontWeight: 600,
										}}
									>
										{`${Mappings[key] ? Mappings[key] : key}: `}
									</Typography>
									{value.old || value.new ? (
										<>
											<Typography variant='subtitle1'>
												{`From: "${value.old}"`}
											</Typography>
											{/* <Typography variant='subtitle1'>{`  `}</Typography> */}
											<Typography variant='subtitle1'>
												{`To: "${value.new}"`}
											</Typography>
										</>
									) : (
										<Typography variant='subtitle1'>{`"${value}"`}</Typography>
									)}
								</div>
							</div>
						)}
					</React.Fragment>
				);
			})}
		</>
	);
}
