import Backdrop from '../../../components/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AdPanel from '../../../components/AdPanel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import api from '../../../config/apiPublic';
import { Login as LoginSchema } from '../../../utils/validators/schemas';
import { useMediaQuery } from 'react-responsive';
import { Preview, PreviewHide } from '../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	button: {
		width: '100%',
		height: 48,
		margin: '24px 0px',
	},
}));

const INITIAL_STATE = {
	email: '',
	password: '',
};

export default function ClientLogin(props) {
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const { reqType, reqId, hasAuth, setHasAuth } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [formData, setFormData] = useState(INITIAL_STATE);
	const [error, setError] = useState(null);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const authenticateUser = () => {
		setLoading(true);
		let info = {
			reqType: reqType,
			reqId: reqId,
			email: formData.email,
			password: formData.password,
		};

		api
			.post('auth-user', info)
			.then((res) => {
				if (res.data.auth) {
					setHasAuth(true);
					setErrorMessages({});
					toast.success('Logged in successfully!');
				} else {
					setError(res.data.error);
				}
			})
			.catch((error) => {
				console.error('Authentication error:', error);
				setError('An error occurred during authentication.');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const toggleShowPassword = () => {
		if (!showPassword) {
			setShowPassword(true);
		}
		setShowPassword(!showPassword);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let valid = handleValidation();

		if (valid) {
			authenticateUser();
		} else {
			setError('An error has occured!');
		}
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;

		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const validateForm = () => {
		return (
			formData.email.length > 0 &&
			formData.email.includes('@') &&
			formData.password.length > 0
		);
	};

	const handleValidation = () => {
		let result = LoginSchema.validate(
			{
				email: formData.email,
				password: formData.password,
			},
			{ abortEarly: false, convert: false }
		);

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			console.log(error.details);
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	return (
		<>
			<Backdrop text='Logging in...' open={loading} />
			<Grid
				container
				direction={isDesktopOrLaptop ? 'row' : 'column'}
				justifyContent='center'
				alignItems='stretch'
				style={{ height: isDesktopOrLaptop && '100vh' }}
			>
				<Grid item style={{ display: !isDesktopOrLaptop && 'none' }} xs={4}>
					<AdPanel type={'external'} />
				</Grid>
				<Grid item xs={isDesktopOrLaptop ? 8 : 12}>
					{isDesktopOrLaptop ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								padding: 40,
								alignItems: 'center',
							}}
						>
							<div
								style={{
									width: '100%',
									flexGrow: 1,
								}}
							></div>
							<Typography variant='h1' style={{ textAlign: 'center' }}>
								Welcome to Uuoni
							</Typography>
							<div style={{ flexGrow: 1, width: '50%', minWidth: 340 }}>
								<div
									style={{
										textAlign: 'center',
										width: '90%',
										marginTop: 24,
										marginBottom: 40,
										marginLeft: 'auto',
										marginRight: 'auto',
									}}
								>
									<Typography color='textSecondary' variant='body1'>
										A Temporary Password has been sent to your email/phone.
									</Typography>
									<Typography color='textSecondary' variant='body1'>
										Please enter the Temporary Password in the field below to
										verify before proceeding to the Confirmation Page.
									</Typography>
								</div>
								<form
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}
									onSubmit={(e) => handleSubmit(e)}
								>
									{error && (
										<div
											style={{
												backgroundColor: '#FCE9E9',
												padding: '16px 24px',
												borderRadius: 4,
												marginBottom: 24,
											}}
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<HighlightOffIcon style={{ color: '#F60C36' }} />
												<div style={{ marginLeft: 16 }}>
													<Typography
														style={{
															color: theme.palette.error.main,
															marginBottom: 8,
														}}
														variant='body2'
													>
														Error
													</Typography>
													<Typography
														style={{ color: theme.palette.error.main }}
														variant='body1'
													>
														{error}
													</Typography>
												</div>
											</div>
										</div>
									)}
									<div style={{ width: '75%' }}>
										<Typography color='textPrimary' variant='body2'>
											Email
										</Typography>
										<TextField
											name='email'
											type='email'
											value={formData.email}
											onChange={(e) => handleInputChange(e)}
											variant='filled'
											fullWidth
											margin='dense'
											autoFocus
											error={!!errorMessages[`email`]}
											helperText={
												errorMessages[`email`] && errorMessages[`email`]
											}
										/>
										<Typography
											style={{ marginTop: 24 }}
											color='textPrimary'
											variant='body2'
										>
											Password
										</Typography>
										<TextField
											name='password'
											value={formData.password}
											onChange={(e) => handleInputChange(e)}
											variant='filled'
											fullWidth
											margin='dense'
											type={showPassword ? 'text' : 'password'}
											autoComplete='current-password'
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={toggleShowPassword}
														>
															{showPassword ? <Preview /> : <PreviewHide />}
														</IconButton>
													</InputAdornment>
												),
											}}
											error={!!errorMessages[`password`]}
											helperText={
												errorMessages[`password`] && errorMessages[`password`]
											}
										/>
										<Button
											className={classes.button}
											disabled={loading}
											type='submit'
											variant='contained'
											color='primary'
										>
											Log In
										</Button>
										<Typography variant='subtitle1' color='textSecondary'>
											This site is protected by Uuoni’s{' '}
											<a
												style={{ color: theme.palette.tertiary.main }}
												href='https://www.uuoni.com/terms'
												target='_blank'
												rel='noopener'
											>
												Terms of Use
											</a>{' '}
											and{' '}
											<a
												style={{ color: theme.palette.tertiary.main }}
												href='https://www.uuoni.com/privacy'
												target='_blank'
												rel='noopener'
											>
												Privacy Policy
											</a>
											.
										</Typography>
									</div>
								</form>
							</div>
							<div
								style={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									position: 'relative',
									justifyContent: 'flex-end',
									margin: '0px auto',
									height: 30,
								}}
							>
								<Typography variant='body1' color='textSecondary'>
									© {new Date().getFullYear()} Uuoni Pte Ltd, All rights
									reserved.
								</Typography>
							</div>
						</div>
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								padding: '32px 24px',
							}}
						>
							<img
								style={{ minWidth: 100, maxWidth: 184, marginBottom: 24 }}
								src={`${process.env.REACT_APP_CDN_URL}/logo-brand-og.png`}
								alt='brand-logo'
							/>
							<Typography variant='h1'>Welcome to Uuoni</Typography>
							<div
								style={{
									marginTop: 8,
									marginBottom: 24,
								}}
							>
								<Typography color='textSecondary' variant='body1'>
									A Temporary Password has been sent to your email/phone.
								</Typography>
								<Typography color='textSecondary' variant='body1'>
									Please enter the Temporary Password in the field below to
									verify before proceeding to the Confirmation Page.
								</Typography>
							</div>
							<div style={{ maxWidth: 340, alignSelf: 'center' }}>
								<form
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}
									onSubmit={(e) => handleSubmit(e)}
								>
									{error && (
										<div
											style={{
												backgroundColor: '#FCE9E9',
												padding: '16px 24px',
												borderRadius: 4,
												marginBottom: 24,
											}}
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<HighlightOffIcon style={{ color: '#F60C36' }} />
												<div style={{ marginLeft: 16 }}>
													<Typography
														style={{
															color: theme.palette.error.main,
															marginBottom: 8,
														}}
														variant='body2'
													>
														Error
													</Typography>
													<Typography
														style={{ color: theme.palette.error.main }}
														variant='body1'
													>
														{error}
													</Typography>
												</div>
											</div>
										</div>
									)}
									<div>
										<Typography color='textPrimary' variant='body2'>
											Email
										</Typography>
										<TextField
											name='email'
											type='email'
											value={formData.email}
											onChange={(e) => handleInputChange(e)}
											variant='filled'
											fullWidth
											margin='dense'
											autoFocus
											error={!!errorMessages[`email`]}
											helperText={
												errorMessages[`email`] && errorMessages[`email`]
											}
										/>
										<Typography
											style={{ marginTop: 24 }}
											color='textPrimary'
											variant='body2'
										>
											Password
										</Typography>
										<TextField
											name='password'
											value={formData.password}
											onChange={(e) => handleInputChange(e)}
											variant='filled'
											fullWidth
											margin='dense'
											type={showPassword ? 'text' : 'password'}
											autoComplete='current-password'
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={toggleShowPassword}
														>
															{showPassword ? <Preview /> : <PreviewHide />}
														</IconButton>
													</InputAdornment>
												),
											}}
											error={!!errorMessages[`password`]}
											helperText={
												errorMessages[`password`] && errorMessages[`password`]
											}
										/>
										<Button
											className={classes.button}
											disabled={loading}
											type='submit'
											variant='contained'
											color='primary'
										>
											Log In
										</Button>
										<Typography variant='subtitle1' color='textSecondary'>
											This site is protected by Uuoni’s{' '}
											<a
												style={{ color: theme.palette.tertiary.main }}
												href='https://www.uuoni.com/terms'
												target='_blank'
												rel='noopener'
											>
												Terms of Use
											</a>{' '}
											and{' '}
											<a
												style={{ color: theme.palette.tertiary.main }}
												href='https://www.uuoni.com/privacy'
												target='_blank'
												rel='noopener'
											>
												Privacy Policy
											</a>
											.
										</Typography>
									</div>
								</form>
							</div>
						</div>
					)}
				</Grid>
			</Grid>
		</>
	);
}
