import Avatar from '@material-ui/core/Avatar';
import Backdrop from '../../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../config/api';
import { AuthUserContext } from '../../../components/Session';
import SuccessMessage from '../../../components/SuccessMessage';
import '../styles.css';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({}));

const getAvatar = (str) => {
	return str.charAt(0).toUpperCase();
};

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

export default function SendRequestForProcessingQuote(props) {
	const { open, handleClose, body, transactionId, data } = props;
	const classes = useStyles();
	const [selectionList, setSelectionList] = useState(data);
	const [expand, setExpand] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [successType, setSuccessType] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);
			try {
				let formData = {
					type: 'Quote',
					requestId: data._id,
					quotation: { ...data.quotation },
				};
				api
					.put(
						`transactions/${transactionId}/sendRequestForProcessing`,
						formData
					)
					.then((res) => {
						setSuccess(true);
						if (res.data.ok) {
							toast.success('Request sent successfully!');
							setSuccessType('request-sent-for-processing');
							props.setTriggerUpdate(true);
						} else {
							toast.error(res.data.error);
							setSuccessType('request-error');
						}
					});
			} catch (error) {
				console.log(error);
				toast.error(error);
			} finally {
				setLoading(false);
			}
		}
	};

	if (success)
		return (
			<SuccessMessage
				type={successType}
				handleClose={handleClose}
				handleBack={handleClose}
			/>
		);
	return (
		<AuthUserContext.Consumer>
			{(credentials) => (
				<>
					<Backdrop text='Loading Data...' open={loading} />
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							style={{ textTransform: 'uppercase' }}
							color='textPrimary'
							variant='h3'
						>
							Process Quotation
						</Typography>
						<IconButton
							style={{ marginLeft: 'auto', padding: 0 }}
							disableRipple
							disableFocusRipple
							disableTouchRipple
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>
					</div>
					<Typography
						variant='body1'
						color='textPrimary'
						style={{ marginTop: 24, marginBottom: 24 }}
					>
						You are about to send a request to process the selected quotation.
						Are you sure you want to proceed?
					</Typography>
					<Grid
						container
						direction='row'
						alignItems='stretch'
						spacing={4}
						style={{ marginBottom: 16 }}
					>
						<Grid item xs={12}>
							<Grid container spacing={2} direction='column'>
								<Grid item>
									<div style={{ display: 'flex', alignItems: 'baseline' }}>
										<Typography color='textPrimary' variant='body2'>
											From
										</Typography>
										<Typography
											style={{ margin: '0px 8px' }}
											color='textPrimary'
											variant='body2'
										>
											:
										</Typography>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<Typography color='textPrimary' variant='body2'>
												{`${credentials.userProfile.profile.firstName} ${credentials.userProfile.profile.lastName}`}
											</Typography>
											<Typography
												style={{ marginLeft: 8 }}
												color='textPrimary'
												variant='body1'
											>
												(mail-service@mg.uuoni.com)
											</Typography>
										</div>
									</div>
								</Grid>
								<Grid item>
									<div style={{ display: 'flex', alignItems: 'baseline' }}>
										<Typography color='textPrimary' variant='body2'>
											To
										</Typography>
										<Typography
											style={{ margin: '0px 8px' }}
											color='textPrimary'
											variant='body2'
										>
											:
										</Typography>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{data.recipients.map((recipient, index) => (
												<div
													key={index}
													style={{ display: 'flex', alignItems: 'center' }}
												>
													<Typography color='textPrimary' variant='body2'>
														{recipient.name}
													</Typography>
													<Typography
														style={{ marginLeft: 8 }}
														color='textPrimary'
														variant='body1'
													>
														({recipient.email})
													</Typography>
												</div>
											))}
										</div>
									</div>
								</Grid>
								{data.ccRecipients.length > 0 && (
									<Grid item>
										<div style={{ display: 'flex', alignItems: 'baseline' }}>
											<Typography color='textPrimary' variant='body2'>
												Cc
											</Typography>
											<Typography
												style={{ margin: '0px 8px' }}
												color='textPrimary'
												variant='body2'
											>
												:
											</Typography>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												{data.ccRecipients.map((recipient, index) => (
													<div
														key={index}
														style={{ display: 'flex', alignItems: 'center' }}
													>
														<Typography color='textPrimary' variant='body2'>
															{recipient.name}
														</Typography>
														<Typography
															style={{ marginLeft: 8 }}
															color='textPrimary'
															variant='body1'
														>
															({recipient.email})
														</Typography>
													</div>
												))}
											</div>
										</div>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography
								style={{ textTransform: 'uppercase' }}
								color='textPrimary'
								variant='h4'
							>
								{data.quotation.quoteName}
							</Typography>
							<Divider style={{ margin: '16px 0px' }} />
							<Grid container direction='row' alignItems='stretch' spacing={2}>
								<Grid item xs={12} sm={6} md={4}>
									<Typography color='textPrimary' variant='body2'>
										Period of Insurance
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										{data.quotation &&
											data.quotation.startDate !== undefined &&
											data.quotation.endDate !== undefined &&
											`${moment(data.quotation.startDate).format(
												'DD/MM/YYYY'
											)} - ${moment(data.quotation.endDate).format(
												'DD/MM/YYYY'
											)} `}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<Typography color='textPrimary' variant='body2'>
										Total Premium
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										{data.quotation &&
											data.quotation.premiumAmt !== undefined &&
											currencyFormatter.format(data.quotation.premiumAmt)}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<Typography color='textPrimary' variant='body2'>
										Sum Insured
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										{data.quotation?.sumInsured
											? currencyFormatter.format(data.quotation.sumInsured)
											: 'Refer to Quote Document'}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography color='textPrimary' variant='body2'>
										Notes
									</Typography>
									<Typography
										style={{ whiteSpace: 'pre-wrap' }}
										color='textPrimary'
										variant='body1'
									>
										{data.quotation.notes ? data.quotation.notes : 'N/A'}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: 24,
						}}
					>
						<Button onClick={handleClose} variant='outlined' color='primary'>
							Cancel
						</Button>
						<Button
							onClick={(e) => handleSubmit(e)}
							variant='contained'
							color='primary'
						>
							Send
						</Button>
					</div>
				</>
			)}
		</AuthUserContext.Consumer>
	);
}
