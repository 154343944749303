import Joi from 'joi';

const Company = Joi.object({
	companyName: Joi.string()
		.label('Company Name')
		.trim()
		.min(1)
		.max(50)
		.required(),
	uen: Joi.string().label('UEN').trim().min(1).max(50).required(),
	website: Joi.string()
		.label('Website')
		.trim()
		.pattern(
			new RegExp(
				/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/i
			)
		)
		.optional()
		.allow('')
		.messages({
			'string.pattern.base': 'Invalid Website',
		}),
	industryName: Joi.string()
		.label('Industry Name')
		.trim()
		.min(1)
		.max(100)
		.optional()
		.allow(''),
	industryCode: Joi.string()
		.label('Industry Code')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	companyEmail: Joi.string()
		.label('Email')
		.trim()
		.email({ tlds: { allow: false } })
		.optional()
		.allow(''),
	officeNo: Joi.string()
		.label('Office Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Office Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	faxNo: Joi.string()
		.label('Fax Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Fax Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	companyAddress: Joi.string()
		.label('Address')
		.trim()
		.min(1)
		.max(100)
		.optional()
		.allow(''),
	companyUnitNo: Joi.string()
		.label('Unit Number')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	companyPostalCode: Joi.string()
		.label('Postal Code')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
}).unknown(true);

export default Company;
