import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';

import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../config/api';

const dataset = [
	{ name: 'NTUC', email: 'ntuc@ntuc.com', hotline: 21396219, contactCount: 4 },
	{
		name: 'Liberty',
		email: 'liberty@liberty.com',
		hotline: 21396219,
		contactCount: 2,
	},
	{
		name: 'HL Assurance',
		email: 'hl@hl.com',
		hotline: 21396219,
		contactCount: 5,
	},
	{
		name: 'Liberty',
		email: 'liberty@liberty.com',
		hotline: 21396219,
		contactCount: 2,
	},
	{
		name: 'HL Assurance',
		email: 'hl@hl.com',
		hotline: 21396219,
		contactCount: 5,
	},
];

const COLORS = [
	'#7E909A',
	'#274C77',
	'#6096BA',
	'#71A5DE',
	'#A3CEF1',
	'#CAE3F7',
];

const DataRow = (props) => {
	const theme = useTheme();
	const { index, data } = props;
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginTop: index !== 0 && 16,
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography
					style={{ width: 'fit-content' }}
					variant='body2'
					color='textPrimary'
					component={Link}
					href={'/settings/principals'}
				>
					{data.shortName}
				</Typography>
				{data.email && (
					<Typography
						style={{ color: theme.palette.tertiary.main, width: 'fit-content' }}
						variant='body1'
						component={Link}
						href={`mailto:${data.email}`}
					>
						{data.email}
					</Typography>
				)}
				{data.hotline && (
					<Typography
						style={{ color: theme.palette.tertiary.main, width: 'fit-content' }}
						variant='body1'
						component={Link}
						href={`tel:${data.hotline}`}
					>
						{data.hotline}
					</Typography>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '16px 0px',
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
		borderRadius: 4,
	},
}));

export default function Principals() {
	const classes = useStyles();
	const theme = useTheme();

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const getData = () => {
		if (!loading) {
			setLoading(true);
			setData(null);
		}
		api
			.get('widgets/principals')
			.then((response) => {
				if (response.data.ok) {
					setData(response.data.data);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className={classes.paper}>
			<Typography
				style={{ padding: '0px 16px' }}
				variant='h4'
				color='textPrimary'
			>
				Representing Principals
			</Typography>
			{!data ? (
				<div style={{ display: 'flex', width: '100%', height: '100%' }}>
					{loading ? (
						<CircularProgress style={{ margin: 'auto' }} />
					) : (
						<Typography
							style={{ margin: 'auto' }}
							variant='body1'
							color='textPrimary'
						>
							No Data Available
						</Typography>
					)}
				</div>
			) : (
				<div
					style={{
						padding: 16,
						overflowY: 'auto',
					}}
				>
					{data.map((row, index) => (
						<DataRow key={index} index={index} data={row} />
					))}
				</div>
			)}
		</div>
	);
}
