import React from 'react';
import Typography from '@material-ui/core/Typography';
import ImportFileUploader from './ImportFileUploader';

const Step2 = (props) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography variant='h3'>Upload {props.type} File</Typography>
			<Typography variant='body1' style={{ marginTop: 16 }}>
				Please ensure that your formatted file is in .xlsx or .csv format only.
			</Typography>
			<ImportFileUploader
				selectedFiles={props.selectedFiles}
				setSelectedFiles={props.setSelectedFiles}
				formErrorMessage={
					props.errorMessages['selectedFiles']
						? props.errorMessages['selectedFiles']
						: ''
				}
			/>
		</div>
	);
};

export default Step2;
