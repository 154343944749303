import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Skeleton from '@material-ui/lab/Skeleton';
import Hidden from '@material-ui/core/Hidden';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState, useContext } from 'react';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import NavPrompt from '../../../components/NavPrompt';
import CountryList from '../../../components/Data/countries.json';
import api from '../../../config/api';
import { AuthUserContext } from '../../../components/Session';
import { User } from '../../../utils/validators/schemas';
import { Delete, Save } from '../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	large: {
		width: 116,
		height: 116,
	},
	deleteIcon: {
		position: 'absolute',
		top: 116,
		left: 132,
	},
	input: {
		display: 'none',
	},
	skeleton: {
		width: '100%',
	},
	skeletonCircle: {
		width: 116,
		height: 116,
	},
}));

const initState = {
	firstName: '',
	middleName: '',
	lastName: '',
	dob: '',
	gender: '',
	mobileNo: '',
	nationality: '',
	avatar: '',
	title: '',
};

export default function ProfileInfo(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { userProfile, setUserProfile } = useContext(AuthUserContext);

	const [formData, setFormData] = useState(userProfile.profile);
	const [loading, setLoading] = useState(false);

	const [errorMessages, setErrorMessages] = useState({});

	const [modified, setModified] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setModified(true);
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleDateChange = (name, date) => {
		setModified(true);
		setFormData((prev) => ({
			...prev,
			[name]: date,
		}));
	};

	const validateFile = (file) => {
		const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];
		let valid = true;
		let errorMessage = '';

		if (validTypes.indexOf(file.type) === -1) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (!file.name.match(/.(jpg|jpeg|png|bmp)$/i)) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (file.size > 2097152) {
			valid = false;
			errorMessage = 'File size cannot exceed 2mb';
		}

		return {
			valid: valid,
			errorMessage: errorMessage,
		};
	};

	const handleChangeImage = async (e) => {
		try {
			const { name, files } = e.target;
			setLoading(true);

			let result = validateFile(files[0]);
			if (result.valid) {
				const form = new FormData();
				form.append('Avatar', files[0], files[0].name);

				await api.put('settings/profile/avatar', form).then((res) => {
					toast.success('Avatar updated!');
					setUserProfile((prev) => ({
						...prev,
						avatarImage: res.data.avatarImage,
						profile: {
							...prev.profile,
							avatar: res.data.avatar,
						},
					}));

					setLoading(false);
				});
			} else {
				toast.error(result.errorMessage);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
			setLoading(false);
		}
	};

	const handleRemoveImage = async (e) => {
		try {
			setLoading(true);

			let data = {
				fileKey: formData.avatar,
			};

			await api.delete('settings/profile/avatar', data).then((res) => {
				toast.success('Avatar removed!');
				setUserProfile((prev) => ({
					...prev,
					avatarImage: null,
					profile: {
						...prev.profile,
						avatar: null,
					},
				}));

				setLoading(false);
			});
		} catch (error) {
			console.log('error', error);
			setLoading(false);
		}
	};

	const handleValidation = () => {
		let result = User.validate(formData, { abortEarly: false, convert: false });

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = async (e) => {
		try {
			e.preventDefault();
			setLoading(true);

			let valid = handleValidation();

			if (valid) {
				let data = {
					firstName: formData.firstName,
					middleName: formData.middleName,
					lastName: formData.lastName,
					dob: formData.dob,
					gender: formData.gender,
					mobileNo: formData.mobileNo,
					nationality: formData.nationality,
					title: formData.title,
					agentId: formData.agentId,
				};

				await api.put('settings/profile', data).then((res) => {
					if (res.data.ok) {
						setErrorMessages({});
						setLoading(false);
						setModified(false);
						setUserProfile((prev) => ({
							...prev,
							profile: {
								...prev.profile,
								...data,
							},
						}));
						toast.success('Profile updated!');
					} else {
						setLoading(false);
						toast.error(res.data.error);
					}
				});
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
			setLoading(false);
		}
	};

	return (
		<>
			<NavPrompt when={modified} />
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Grid container spacing={3}>
					<Grid item container alignItems='center' spacing={3}>
						<div style={{ position: 'relative', padding: 12 }}>
							<div className={classes.avatarContainer}>
								<input
									accept='image/jpeg, image/jpg, image/png, image/bmp'
									className={classes.input}
									id='icon-button-file'
									type='file'
									name='avatar'
									onChange={
										process.env.REACT_APP_ENVIRONMENT !== 'demo'
											? handleChangeImage
											: undefined
									}
								/>
								<label htmlFor='icon-button-file'>
									<IconButton component='span'>
										{loading ? (
											<Skeleton
												className={classes.skeletonCircle}
												variant='circle'
												animation='wave'
											/>
										) : (
											<Avatar
												src={
													userProfile.avatarImage !== null
														? `data:image/*;base64,${userProfile.avatarImage}`
														: 'Avatar'
												}
												className={classes.large}
											/>
										)}
									</IconButton>
								</label>
								{!loading && userProfile.avatarImage !== null && (
									<IconButton
										className={classes.deleteIcon}
										disableFocusRipple
										disableRipple
										size='small'
										onClick={
											process.env.REACT_APP_ENVIRONMENT !== 'demo'
												? (e) => handleRemoveImage(e)
												: undefined
										}
									>
										<Delete
											style={{ fontSize: 24 }}
											colorcode={theme.palette.text.secondary}
										/>
									</IconButton>
								)}
							</div>
						</div>
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Title
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='title'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={formData.title ? formData.title : ''}
								error={!!errorMessages[`title`]}
								helperText={errorMessages[`title`] && errorMessages[`title`]}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Agent / License Number
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='agentId'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={formData.agentId ? formData.agentId : ''}
								error={!!errorMessages[`agentId`]}
								helperText={
									errorMessages[`agentId`] && errorMessages[`agentId`]
								}
							/>
						)}
					</Grid>
					{/* <Hidden smDown> */}
					<Box width='100%' />
					{/* </Hidden> */}
					<Grid item xs={3}>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								First Name
							</Typography>
							<Typography
								style={{ marginLeft: 2 }}
								color='error'
								variant='body2'
								align='right'
							>
								*
							</Typography>
						</Grid>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='firstName'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={formData.firstName ? formData.firstName : ''}
								error={!!errorMessages[`firstName`]}
								helperText={
									errorMessages[`firstName`] && errorMessages[`firstName`]
								}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Middle Name
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='middleName'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={formData.middleName ? formData.middleName : ''}
								error={!!errorMessages[`middleName`]}
								helperText={
									errorMessages[`middleName`] && errorMessages[`middleName`]
								}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								Last Name
							</Typography>
							<Typography
								style={{ marginLeft: 2 }}
								color='error'
								variant='body2'
								align='right'
							>
								*
							</Typography>
						</Grid>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='lastName'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={formData.lastName ? formData.lastName : ''}
								error={!!errorMessages[`lastName`]}
								helperText={
									errorMessages[`lastName`] && errorMessages[`lastName`]
								}
							/>
						)}
					</Grid>
					<Box width='100%' />
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Date of Birth
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									name='dob'
									disableToolbar
									format='dd/MM/yyyy'
									disableFuture
									inputVariant='filled'
									openTo='year'
									views={['year', 'month', 'date']}
									margin='dense'
									fullWidth
									value={formData.dob ? formData.dob : null}
									autoOk
									variant='inline'
									maxDate={new Date()}
									invalidDateMessage='Invalid Date Format'
									onChange={(date) => handleDateChange('dob', date)}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									// keyboardIcon={props.editable ? <EventIcon /> : null}
									error={!!errorMessages[`dob`]}
									helperText={errorMessages[`dob`] && errorMessages[`dob`]}
								/>
							</MuiPickersUtilsProvider>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2'>
							Gender
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<Select
								native
								name='gender'
								variant='filled'
								fullWidth
								autoWidth={true}
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={formData.gender ? formData.gender : ''}
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option value=''></option>
								<option value='male'>Male</option>
								<option value='female'>Female</option>
							</Select>
						)}
					</Grid>
					<Box width='100%' />
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Mobile Number
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='mobileNo'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={formData.mobileNo ? formData.mobileNo : ''}
								error={!!errorMessages[`mobileNo`]}
								helperText={
									errorMessages[`mobileNo`] && errorMessages[`mobileNo`]
								}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2'>
							Nationality
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<Select
								native
								name='nationality'
								variant='filled'
								fullWidth
								autoWidth={true}
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={formData.nationality ? formData.nationality : ''}
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option key={0} value=''></option>
								{CountryList.sort(
									(a, b) => -b.label.localeCompare(a.label)
								).map((country, index) => {
									return (
										<option key={index} value={country.code}>
											{country.label}
										</option>
									);
								})}
							</Select>
						)}
					</Grid>
				</Grid>
				<div
					className='formFooter'
					style={{ display: 'flex', marginTop: 32, marginBottom: 32 }}
				>
					<Button
						// style={{ marginLeft: 'auto' }}
						variant='contained'
						color='primary'
						// className={classes.button}
						disabled={!modified}
						startIcon={
							<Save
								style={{ fontSize: 24 }}
								colorcode={
									!modified
										? theme.palette.grey[500]
										: theme.palette.background.paper
								}
							/>
						}
						onClick={(e) => handleSave(e)}
					>
						Save
					</Button>
				</div>
			</div>
		</>
	);
}
