import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useMediaQuery } from 'react-responsive';
import CountryList from '../../components/Data/countries.json';

const Step2 = (props) => {
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
	const { profile, setProfile, errorMessages } = props;

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;
		setProfile((prev) => ({ ...prev, [name]: value }));
	};

	return (
		<>
			<Typography variant='h1'>About you</Typography>
			<Typography
				variant='body1'
				style={{
					marginTop: isDesktopOrLaptop ? 24 : 16,
					marginBottom: isDesktopOrLaptop ? 48 : 32,
				}}
			>
				Please fill-in your basic information to setup your profile in Uuoni.
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} md={4}>
					<Typography color='textPrimary' variant='body2'>
						First Name
					</Typography>
					<TextField
						name='firstName'
						value={profile.firstName}
						onChange={handleInputChange}
						placeholder='Enter First Name'
						variant='filled'
						fullWidth
						margin='dense'
						error={!!errorMessages[`firstName`]}
						helperText={
							errorMessages[`firstName`] && errorMessages[`firstName`]
						}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<Typography color='textPrimary' variant='body2'>
						Last Name
					</Typography>
					<TextField
						name='lastName'
						value={profile.lastName}
						onChange={handleInputChange}
						placeholder='Enter Last Name'
						variant='filled'
						fullWidth
						margin='dense'
						error={!!errorMessages[`lastName`]}
						helperText={errorMessages[`lastName`] && errorMessages[`lastName`]}
					/>
				</Grid>
				{isDesktopOrLaptop && <Grid item md={4} />}
				<Grid item xs={12} md={4}>
					<Typography color='textPrimary' variant='body2'>
						Mobile Number
					</Typography>
					<TextField
						name='mobileNo'
						value={profile.mobileNo}
						onChange={handleInputChange}
						placeholder='Enter Mobile No'
						variant='filled'
						fullWidth
						margin='dense'
						error={!!errorMessages[`mobileNo`]}
						helperText={errorMessages[`mobileNo`] && errorMessages[`mobileNo`]}
					/>
				</Grid>
				{isDesktopOrLaptop && <Grid item md={8} />}
				<Grid item xs={12} md={4}>
					<FormControl
						style={{ width: '100%' }}
						error={!!errorMessages[`nationality`]}
					>
						<Typography color='textPrimary' variant='body2'>
							Country
						</Typography>
						<Select
							native
							name='nationality'
							value={profile.nationality}
							onChange={handleInputChange}
							variant='filled'
							fullWidth
							autoWidth={true}
							margin='dense'
							style={{ marginTop: 8, marginBottom: 4 }}
						>
							<option key={0} value=''></option>
							{CountryList.sort((a, b) => -b.label.localeCompare(a.label)).map(
								(country, index) => {
									return (
										<option key={index} value={country.code}>
											{country.label}
										</option>
									);
								}
							)}
						</Select>
						<FormHelperText>
							{errorMessages[`nationality`] && errorMessages[`nationality`]}
						</FormHelperText>
					</FormControl>
				</Grid>
			</Grid>
		</>
	);
};

export default Step2;
