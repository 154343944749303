import Backdrop from '../../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FileUploader from '../../../components/FileUploader';
import api from '../../../config/api';
import { CloseTransaction as CloseTransactionSchema } from '../../../utils/validators/schemas';
import '../styles.css';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({}));

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

export default function CloseTransaction(props) {
	const initFormData = {
		policyNo: '',
		commission: 0,
		fileData: null,
	};
	const { open, handleClose, body, transactionId, data } = props;
	const classes = useStyles();
	const theme = useTheme();

	const [expand, setExpand] = useState(false);
	const [formData, setFormData] = useState(initFormData);
	const [commissionValue, setCommissionValue] = useState('');
	const [commissionPercentage, setCommissionPercentage] = useState('');
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);

	const handleCommissionValueChange = (val) => {
		let percentage = (val / data) * 100;
		setCommissionPercentage(parseFloat(percentage).toFixed(2));
		setCommissionValue(val);
		setFormData((prev) => ({
			...prev,
			commission: val,
		}));
	};

	const handleCommissionPercentageChange = (val) => {
		let value = data * (val / 100);
		setCommissionPercentage(val);
		setCommissionValue(parseFloat(value).toFixed(2));
		setFormData((prev) => ({
			...prev,
			commission: parseFloat(value).toFixed(2),
		}));
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleFileChange = (e, files) => {
		e.preventDefault();
		setFormData((prev) => ({
			...prev,
			fileData: files,
		}));
	};

	const handleValidation = () => {
		let hasFile = !!formData.fileData;
		let data = {
			policyNo: formData.policyNo,
			commission: formData.commission,
		};

		let result = CloseTransactionSchema.validate(data, {
			abortEarly: false,
		});

		const { error } = result;
		if (!error && hasFile) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			if (error?.details !== undefined) {
				for (let item of error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			if (!hasFile) {
				errorData['policyDocument'] = 'Policy Document is required';
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);

			let valid = handleValidation();

			if (valid) {
				const data = new FormData();
				data.append(
					'Policy Document',
					formData.fileData[0],
					formData.fileData[0].name
				);
				let json = cloneDeep(formData);
				delete json.fileData;
				console.log(JSON.stringify(json, null, 4));
				data.append('json', JSON.stringify(json));

				try {
					api
						.put(`transactions/${transactionId}/closeTransaction`, data)
						.then((res) => {
							if (res.data.ok) {
								toast.success('Transaction Closed!');
								setLoading(false);
								props.setTriggerUpdate(true);
								handleClose();
							} else {
								toast.error(res.data.error);
								setLoading(false);
								handleClose();
							}
						});
				} catch (error) {
					console.log(error);
					toast.error(error);
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	return (
		<>
			<Backdrop text='Loading Data...' open={loading} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Close Transaction
				</Typography>
			</div>
			<Typography
				color='textPrimary'
				style={{ marginTop: 24, marginBottom: 24 }}
			>
				Fill up closed policy case details and agent's commission attributed.
			</Typography>
			<Grid container spacing={4} style={{ paddingBottom: 10 }}>
				<Grid item xs={12}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Total Premium
					</Typography>
					<Typography
						style={{ marginTop: 8 }}
						color='textPrimary'
						variant='body1'
						align='left'
					>
						{currencyFormatter.format(data)}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Policy Number
					</Typography>
					<TextField
						name='policyNo'
						value={formData.policyNo}
						onChange={(e) => handleInputChange(e)}
						placeholder='Enter Policy No.'
						variant='filled'
						fullWidth
						margin='dense'
						error={!!errorMessages[`policyNo`]}
						helperText={errorMessages[`policyNo`] && errorMessages[`policyNo`]}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Agent's Commission
					</Typography>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							name='commissionValue'
							value={commissionValue}
							onChange={(e) => handleCommissionValueChange(e.target.value)}
							variant='filled'
							fullWidth
							margin='dense'
							InputProps={{
								startAdornment: (
									<InputAdornment style={{ marginTop: 0 }} position='start'>
										<AttachMoneyOutlinedIcon />
									</InputAdornment>
								),
							}}
							error={!!errorMessages[`commission`]}
							helperText={
								errorMessages[`commission`] && errorMessages[`commission`]
							}
						/>
						<TextField
							style={{ marginLeft: 8 }}
							name='commissionPercentage'
							value={commissionPercentage}
							onChange={(e) => handleCommissionPercentageChange(e.target.value)}
							variant='filled'
							fullWidth
							margin='dense'
							InputProps={{
								endAdornment: <InputAdornment position='end'>%</InputAdornment>,
							}}
						/>
					</div>
				</Grid>
				<Grid item xs={12}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Upload Policy Document
					</Typography>
					<div
						style={{
							width: '100%',
							marginTop: 8,
							marginBottom: 4,
						}}
					>
						<FileUploader
							fileLimit={1}
							files={formData.fileData}
							handleFileChange={handleFileChange}
							formErrorMessage={
								errorMessages[`policyDocument`] &&
								errorMessages[`policyDocument`]
							}
						/>
					</div>
				</Grid>
			</Grid>

			<div style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginRight: 'auto' }}
					onClick={handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={(e) => handleSubmit(e)}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
		</>
	);
}
