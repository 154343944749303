import { Card } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { PDFViewer, usePDF } from '@react-pdf/renderer';
import React from 'react';
import Invoice from '../../../../components/Invoice';
import ContextMenu from './ContextMenu';
import { Preview } from '../../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'inline-block',
		height: '100%',
		backgroundColor: 'unset',
		width: '100%',
		borderRadius: 8,
		boxShadow: '0px 4px 12px rgb(0 0 0 / 16%), 0px 0px 4px rgb(0 0 0 / 16%)',
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
}));

export default function InvoicePreviewer(props) {
	const classes = useStyles();
	const { invoice, setTriggerUpdate } = props;
	const [instance, update] = usePDF({
		document: <Invoice formData={invoice} />,
	});

	return (
		<Card className={classes.content}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					padding: 24,
					height: 58,
				}}
			>
				<Preview style={{ marginRight: 8, fontSize: 24 }} />
				<Typography
					style={{ flexGrow: 1 }}
					color='textPrimary'
					variant='h4'
					align='left'
				>
					INVOICE PREVIEW
				</Typography>
				<ContextMenu
					invoice={invoice}
					invoiceDocument={instance.blob}
					setTriggerUpdate={setTriggerUpdate}
				/>
			</div>
			<Divider />
			<div style={{ width: '100%', height: '100%' }}>
				<PDFViewer style={{ width: '100%', height: '100%' }}>
					<Invoice formData={invoice} />
				</PDFViewer>
			</div>
		</Card>
	);
}
