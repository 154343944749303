import Backdrop from '../../components/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Login.css';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import firebase from 'firebase/compat/app';
import React, { useState, useEffect } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Link as RouterLink, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { AuthUserContext } from '../../components/Session';
import { Login as LoginSchema } from '../../utils/validators/schemas';
import { useMediaQuery } from 'react-responsive';
import { Preview, PreviewHide } from '../../components/Icons';

const styles = (theme) => ({
	input: {
		width: '100%',
		maxWidth: '460px',
		height: '50px',
		marginBottom: '2vh',
	},
	button: {
		width: '100%',
		height: 48,
		margin: '24px 0px',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
	},
	firebaseUi: {
		marginTop: 40,
	},
	firebaseUiMobile: {},
	checkbox: {
		'&:hover': {
			backgroundColor: 'transparent !important',
		},
	},
});

const LoginPage = () => <LoginForm />;

const INITIAL_STATE = {
	email: '',
	password: '',
};

const Login = (props) => {
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const { classes, theme } = props;
	const [formData, setFormData] = useState({
		...INITIAL_STATE,
	});
	const [rememberMe, setRememberMe] = useState(false);
	const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
	const [error, setError] = useState(null);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	// Listen to the Firebase Auth state and set the local state.
	useEffect(() => {
		const unregisterAuthObserver = firebase
			.auth()
			.onAuthStateChanged((user) => {
				if (user) {
					if (
						process.env.REACT_APP_ENVIRONMENT !== 'production' &&
						process.env.REACT_APP_ENVIRONMENT !== 'demo'
					) {
						console.log('user found');
						console.log(user);
					}
					setIsSignedIn(!!user);
				}
			});
		return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
	}, []);

	const toggleShowPassword = () => {
		if (!showPassword) {
			setShowPassword(true);
		}
		setShowPassword(!showPassword);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		let valid = handleValidation();

		if (valid) {
			props.firebase
				.doSignInWithEmailAndPassword(
					formData.email,
					formData.password,
					rememberMe
				)
				.then(() => {
					setErrorMessages({});
				})
				.catch((err) => {
					setError(
						"The email or password you've entered is incorrect. Please try again."
					);
					setLoading(false);
				});
		} else {
			setError(
				"The email or password you've entered is incorrect. Please try again."
			);
			setLoading(false);
		}
	};

	const onChange = (event) => {
		const { name, value } = event.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const uiConfig = {
		signInFlow: 'popup',
		signInSuccessUrl: '/dashboard',
		signInOptions: [
			firebase.auth.GoogleAuthProvider.PROVIDER_ID,
			// firebase.auth.EmailAuthProvider.PROVIDER_ID,
		],
		callbacks: {
			signInSuccessWithAuthResult: () => false,
		},
	};

	const handleValidation = () => {
		let result = LoginSchema.validate(
			{
				email: formData.email,
				password: formData.password,
			},
			{ abortEarly: false, convert: false }
		);

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	if (!isSignedIn) {
		return (
			<>
				<Backdrop text='Logging in...' open={loading} />
				{isDesktopOrLaptop ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							padding: 40,
							alignItems: 'center',
						}}
					>
						<div
							style={{
								width: '100%',
								flexGrow: 1,
							}}
						>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Typography
									style={{ marginLeft: 'auto' }}
									color='textSecondary'
									variant='body1'
								>
									Don't have an account?
								</Typography>
								<Button
									style={{ marginLeft: 16 }}
									component={RouterLink}
									to='/register'
									variant='outlined'
									color='primary'
								>
									Sign Up
								</Button>
							</div>
						</div>
						<Typography variant='h1' style={{ textAlign: 'center' }}>
							Log in to your account
						</Typography>
						<div className={classes.firebaseUi}>
							<StyledFirebaseAuth
								uiConfig={uiConfig}
								firebaseAuth={firebase.auth()}
							/>
						</div>
						<div style={{ flexGrow: 1, width: '50%', minWidth: 340 }}>
							<form
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
								onSubmit={(e) => onSubmit(e)}
							>
								<div className='login-divider' style={{ width: '75%' }}>
									<span className='dividerText'>Or sign in with</span>
								</div>
								{error && (
									<div
										style={{
											backgroundColor: '#FCE9E9',
											padding: '16px 24px',
											borderRadius: 4,
											marginBottom: 24,
										}}
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<HighlightOffIcon style={{ color: '#F60C36' }} />
											<div style={{ marginLeft: 16 }}>
												<Typography
													style={{
														color: theme.palette.error.main,
														marginBottom: 8,
													}}
													variant='body2'
												>
													Error
												</Typography>
												<Typography
													style={{ color: theme.palette.error.main }}
													variant='body1'
												>
													{error}
												</Typography>
											</div>
										</div>
									</div>
								)}
								<div style={{ width: '75%' }}>
									<Typography color='textPrimary' variant='body2'>
										Email
									</Typography>
									<TextField
										name='email'
										value={formData.email}
										onChange={(e) => onChange(e)}
										variant='filled'
										fullWidth
										margin='dense'
										autoFocus
										error={!!errorMessages[`email`]}
										helperText={
											errorMessages[`email`] && errorMessages[`email`]
										}
									/>
									<Typography
										style={{ marginTop: 24 }}
										color='textPrimary'
										variant='body2'
									>
										Password
									</Typography>
									<TextField
										name='password'
										value={formData.password}
										onChange={(e) => onChange(e)}
										variant='filled'
										fullWidth
										margin='dense'
										type={showPassword ? 'text' : 'password'}
										autoComplete='current-password'
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={toggleShowPassword}
													>
														{showPassword ? <Preview /> : <PreviewHide />}
													</IconButton>
												</InputAdornment>
											),
										}}
										error={!!errorMessages[`password`]}
										helperText={
											errorMessages[`password`] && errorMessages[`password`]
										}
									/>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<FormControlLabel
												checked={rememberMe}
												control={
													<Checkbox
														style={{ padding: '0px 9px' }}
														classes={{ root: classes.checkbox }}
														color='primary'
														disableRipple
														disableFocusRipple
													/>
												}
												label={
													<Typography color='textPrimary' variant='subtitle1'>
														Remember Me
													</Typography>
												}
												name='rememberMe'
												labelPlacement='end'
												onChange={(e) => setRememberMe(e.target.checked)}
											/>
										</div>
										<Link
											component={RouterLink}
											style={{ color: theme.palette.tertiary.main }}
											variant='subtitle1'
											to={'/forgot-password'}
										>
											Forgot Password?
										</Link>
									</div>
									<Button
										className={classes.button}
										disabled={loading}
										type='submit'
										variant='contained'
										color='primary'
									>
										Log In
									</Button>
									<Typography variant='subtitle1' color='textSecondary'>
										This site is protected by Uuoni’s{' '}
										<a
											style={{ color: theme.palette.tertiary.main }}
											href='https://www.uuoni.com/terms'
											target='_blank'
											rel='noopener'
										>
											Terms of Use
										</a>{' '}
										and{' '}
										<a
											style={{ color: theme.palette.tertiary.main }}
											href='https://www.uuoni.com/privacy'
											target='_blank'
											rel='noopener'
										>
											Privacy Policy
										</a>
										.
									</Typography>
								</div>
							</form>
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								position: 'relative',
								justifyContent: 'flex-end',
								margin: '0px auto',
								height: 30,
							}}
						>
							<Typography variant='body1' color='textSecondary'>
								© {new Date().getFullYear()} Uuoni Pte Ltd, All rights reserved.
							</Typography>
						</div>
					</div>
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							padding: '32px 24px',
						}}
					>
						<Typography variant='h1'>Log in to your account</Typography>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Typography color='textSecondary' variant='body1'>
								Don't have an account?
							</Typography>
							<Button
								style={{ padding: 8 }}
								component={RouterLink}
								to='/register'
								variant='text'
								color='primary'
							>
								Sign Up
							</Button>
						</div>
						<div style={{ maxWidth: 340, alignSelf: 'center' }}>
							<div className='firebaseUiMobile'>
								<StyledFirebaseAuth
									uiConfig={uiConfig}
									firebaseAuth={firebase.auth()}
								/>
							</div>
							<form
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
								onSubmit={(e) => onSubmit(e)}
							>
								<div
									className='login-divider'
									style={{ width: '100%', margin: '16px 0px' }}
								>
									<span className='dividerText'>Or sign in with</span>
								</div>
								{error && (
									<div
										style={{
											backgroundColor: '#FCE9E9',
											padding: '16px 24px',
											borderRadius: 4,
											marginBottom: 24,
										}}
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<HighlightOffIcon style={{ color: '#F60C36' }} />
											<div style={{ marginLeft: 16 }}>
												<Typography
													style={{
														color: theme.palette.error.main,
														marginBottom: 8,
													}}
													variant='body2'
												>
													Error
												</Typography>
												<Typography
													style={{ color: theme.palette.error.main }}
													variant='body1'
												>
													{error}
												</Typography>
											</div>
										</div>
									</div>
								)}
								<div>
									<Typography color='textPrimary' variant='body2'>
										Email
									</Typography>
									<TextField
										name='email'
										value={formData.email}
										onChange={(e) => onChange(e)}
										variant='filled'
										fullWidth
										margin='dense'
										autoFocus
										error={!!errorMessages[`email`]}
										helperText={
											errorMessages[`email`] && errorMessages[`email`]
										}
									/>
									<Typography
										style={{ marginTop: 24 }}
										color='textPrimary'
										variant='body2'
									>
										Password
									</Typography>
									<TextField
										name='password'
										value={formData.password}
										onChange={(e) => onChange(e)}
										variant='filled'
										fullWidth
										margin='dense'
										type={showPassword ? 'text' : 'password'}
										autoComplete='current-password'
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={toggleShowPassword}
													>
														{showPassword ? <Preview /> : <PreviewHide />}
													</IconButton>
												</InputAdornment>
											),
										}}
										error={!!errorMessages[`password`]}
										helperText={
											errorMessages[`password`] && errorMessages[`password`]
										}
									/>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<FormControlLabel
												checked={rememberMe}
												control={
													<Checkbox
														style={{ padding: '0px 9px' }}
														classes={{ root: classes.checkbox }}
														color='primary'
														disableRipple
														disableFocusRipple
													/>
												}
												label={
													<Typography color='textPrimary' variant='subtitle1'>
														Remember Me
													</Typography>
												}
												name='rememberMe'
												labelPlacement='end'
												onChange={(e) => setRememberMe(e.target.checked)}
											/>
										</div>
										<Link
											component={RouterLink}
											style={{ color: theme.palette.tertiary.main }}
											variant='subtitle1'
											to={'/forgot-password'}
										>
											Forgot Password?
										</Link>
									</div>
									<Button
										className={classes.button}
										style={{ margin: '32px 0px 24px 0px' }}
										disabled={loading}
										type='submit'
										variant='contained'
										color='primary'
									>
										Log In
									</Button>
									<Typography variant='subtitle1' color='textSecondary'>
										This site is protected by Uuoni’s{' '}
										<a
											style={{ color: theme.palette.tertiary.main }}
											href='https://www.uuoni.com/terms'
											target='_blank'
											rel='noopener'
										>
											Terms of Use
										</a>{' '}
										and{' '}
										<a
											style={{ color: theme.palette.tertiary.main }}
											href='https://www.uuoni.com/privacy'
											target='_blank'
											rel='noopener'
										>
											Privacy Policy
										</a>
										.
									</Typography>
								</div>
							</form>
						</div>
					</div>
				)}
			</>
		);
	} else {
		return (
			<>
				<Backdrop text='Logging in...' open={loading} />
				<AuthUserContext.Consumer>
					{(credentials) => {
						if (
							credentials.authUser &&
							credentials.verified &&
							credentials.userProfile.onboarded
						)
							return <Redirect to='/dashboard' />;
						if (
							credentials.authUser &&
							credentials.verified &&
							!credentials.userProfile.onboarded
						)
							return <Redirect to='/onboarding' />;
						if (credentials.authUser && !credentials.verified)
							return <Redirect to='/register' />;
					}}
				</AuthUserContext.Consumer>
			</>
		);
	}
};

const LoginForm = compose(
	withRouter,
	withFirebase,
	withStyles(styles, { withTheme: true })
)(Login);

export default LoginPage;

export { LoginForm };
