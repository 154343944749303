import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { differenceInSeconds } from 'date-fns';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import matchSorter from 'match-sorter';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Joi from 'joi';
import { useMediaQuery } from 'react-responsive';
import api from '../../../../../../../config/api';
import { AuthUserContext } from '../../../../../../../components/Session';
import ContextMenu from './ContextMenu';
import TransactionModal from '../../../../../../../components/Modals/transactions';
import { getDiff } from '../../../../../../../utils/formHelper';

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const useStyles = makeStyles((theme) => ({
	statusBar: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px 24px',
		margin: '-16px -24px 16px -24px',
	},
}));

const rejReasonList = ['No suitable product available', 'High Risk', 'Other'];

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

export default function RequestItem(props) {
	const { requestData, handleRequestListUpdate, setTriggerUpdate } = props;
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

	const [currentData, setCurrentData] = useState(cloneDeep(requestData));
	const [updatedData, setUpdatedData] = useState(cloneDeep(requestData));
	const [editable, setEditable] = useState(false);
	const [responseEditable, setResponseEditable] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);

	useEffect(() => {
		setCurrentData(cloneDeep(requestData));
		setUpdatedData(cloneDeep(requestData));
	}, [props]);

	const handleEditable = () => {
		setExpanded(true);
		setEditable(true);
	};

	const handleInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;

		const keys = name.split('.');
		const updateValue =
			type === 'number' && value !== '' ? parseFloat(value) : value;

		const updateNestedData = (data, keyIndex = 0) => {
			if (keyIndex === keys.length - 1) {
				return { ...data, [keys[keyIndex]]: updateValue };
			} else {
				return {
					...data,
					[keys[keyIndex]]: updateNestedData(
						data[keys[keyIndex]],
						keyIndex + 1
					),
				};
			}
		};

		setUpdatedData((prev) => updateNestedData(prev));
	};

	const initResponse = (e) => {
		e.preventDefault();
		setUpdatedData((prev) => ({
			...prev,
			responseData: {
				accepted: true,
			},
		}));
		setResponseEditable(true);
	};

	const handleResponseChange = () => {
		setUpdatedData((prev) => {
			if (!prev.responseData.accepted) {
				return {
					...prev,
					responseData: {
						...prev.responseData,
						accepted: true,
					},
				};
			} else {
				return {
					...prev,
					responseData: {
						...prev.responseData,
						accepted: false,
					},
				};
			}
		});
	};

	const toggleExpanded = () => {
		if (!expanded) {
			setExpanded(true);
		}
		setExpanded(!expanded);
	};

	const getTextColor = (status) => {
		if (status === 'Acknowledged') return '#40BE79';
		if (
			status === 'Expired' ||
			status === 'Declined' ||
			status === 'Email failed'
		)
			return '#F16063';
		if (status === 'Pending Response') return '#A8701D';
		return '';
	};

	const getStatusBarColor = (status) => {
		if (status === 'Acknowledged') return 'rgba(102, 203, 148, 0.2)';
		if (
			status === 'Expired' ||
			status === 'Declined' ||
			status === 'Email failed'
		)
			return '#FCE9E9';
		if (status === 'Pending Response') return '#F7C16F';
		return '';
	};

	const getStatusText = (status) => {
		if (status === 'Acknowledged')
			return 'Principal accepted the request to provide quote';
		if (status === 'Declined') return 'Principal declined to provide quote';
		if (status === 'Expired') return 'Expired';
		if (status === 'Email failed') return 'Email failed';
		if (status === 'Pending Response')
			return 'Pending Underwriter to provide quote';
		return '';
	};

	const handleResponseValidation = () => {
		let errorData = {};
		let dataToValidate = {};
		let schemaObject = {};
		let result = {};
		let isValid = true;

		if (!updatedData.responseData?.accepted) {
			dataToValidate['responseData'] = {
				rejReason: updatedData.responseData?.rejReason,
				rejRemarks: updatedData.responseData?.rejRemarks,
			};
			schemaObject['responseData'] = Joi.object({
				rejReason: Joi.string()
					.label('Reject Reason')
					.trim()
					.min(1)
					.max(50)
					.required(),
				rejRemarks: Joi.string()
					.label('Reject Remarks')
					.trim()
					.min(1)
					.max(1000)
					.when('rejReason', {
						is: 'Other',
						then: Joi.required(),
						otherwise: Joi.optional().allow(''),
					}),
			}).unknown(true);
		}

		let schema = Joi.object(schemaObject);
		result = schema.validate(dataToValidate, {
			abortEarly: false,
			convert: false,
		});

		if (result.error === undefined && isValid) {
			setErrorMessages({});
			return true;
		} else {
			if (result.error) {
				console.log(result.error.details);
				for (let item of result.error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			console.log(errorData);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleResponseSave = () => {
		if (!loading) {
			console.log('handleResponseSave');
			setLoading(true);

			let valid = handleResponseValidation();
			if (valid) {
				let changedData = getDiff(updatedData, currentData);
				console.log(changedData);

				if (!changedData) {
					setErrorMessages({});
					setEditable(false);
					setLoading(false);
				} else {
					try {
						let data = {
							updatedFields: {
								status: updatedData.responseData?.accepted
									? 'Acknowledged'
									: 'Declined',
								respondDate: new Date(),
								responseData: {
									...updatedData.responseData,
									accepted: updatedData.responseData?.accepted,
								},
							},
							auditLog: changedData,
						};

						console.log(data);

						api
							.put(`requests/${requestData._id}/submit-response`, data)
							.then((res) => {
								if (res.data.ok) {
									setErrorMessages({});
									setResponseEditable(false);
									handleRequestListUpdate(requestData._id, res.data.data);

									toast.success('Quote Request responded!');
								} else {
									toast.error(res.data.error);
								}
							});
					} catch (error) {
						console.log(error);
						toast.error(error);
					} finally {
						setLoading(false);
					}
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	const handleResponseCancel = () => {
		setUpdatedData(cloneDeep(currentData));
		setResponseEditable(false);
	};

	const handleValidation = () => {
		let errorData = {};
		let dataToValidate = {};
		let schemaObject = {};
		let result = {};
		let isValid = true;

		dataToValidate['request'] = {
			requestSubject: updatedData.requestSubject,
			requestMessage: updatedData.requestMessage,
		};
		schemaObject['request'] = Joi.object({
			requestSubject: Joi.string()
				.label('Subject')
				.trim()
				.min(1)
				.max(250)
				.required(),
			requestMessage: Joi.string()
				.label('Message')
				.trim()
				.min(1)
				.max(1000)
				.required(),
		}).unknown(true);

		let schema = Joi.object(schemaObject);
		result = schema.validate(dataToValidate, {
			abortEarly: false,
			convert: false,
		});

		if (result.error === undefined && isValid) {
			setErrorMessages({});
			return true;
		} else {
			if (result.error) {
				console.log(result.error.details);
				for (let item of result.error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			console.log(errorData);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = () => {
		if (!loading) {
			console.log('handleSave');
			setLoading(true);

			let changedData = getDiff(
				{
					requestSubject: updatedData.requestSubject,
					requestMessage: updatedData.requestMessage,
				},
				{
					requestSubject: currentData.requestSubject,
					requestMessage: currentData.requestMessage,
				}
			);

			console.log(changedData);

			if (!changedData) {
				setErrorMessages({});
				setEditable(false);
				setLoading(false);
			} else {
				let valid = handleValidation();
				if (valid) {
					try {
						let data = {
							updatedFields: {
								requestSubject: updatedData.requestSubject,
								requestMessage: updatedData.requestMessage,
							},
							auditLog: changedData,
						};

						console.log(data);

						api.put(`requests/${requestData._id}`, data).then((res) => {
							if (res.data.ok) {
								setErrorMessages({});
								setEditable(false);
								handleRequestListUpdate(requestData._id, res.data.data);

								toast.success('Quote Request updated!');
							} else {
								toast.error(res.data.error);
							}
						});
					} catch (error) {
						console.log(error);
						toast.error(error);
					} finally {
						setLoading(false);
					}
				} else {
					toast.error('An error has occured!');
					setLoading(false);
				}
			}
		}
	};

	const handleCancel = () => {
		setUpdatedData(cloneDeep(currentData));
		setEditable(false);
	};

	const handleModalOpen = (body) => {
		setModalType(body);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalType(null);
	};

	if (isEmpty(requestData)) return null;

	return (
		<AuthUserContext.Consumer>
			{(credentials) => (
				<>
					{!responseEditable && (
						<div
							className={classes.statusBar}
							style={{
								backgroundColor:
									differenceInSeconds(
										new Date(),
										new Date(updatedData.requestExpiryDate)
									) > 0 && updatedData.status === 'Pending Response'
										? getStatusBarColor('Expired')
										: getStatusBarColor(updatedData.status),
							}}
						>
							<Typography
								variant='body2'
								style={{
									color:
										differenceInSeconds(
											new Date(),
											new Date(updatedData.requestExpiryDate)
										) > 0 && updatedData.status === 'Pending Response'
											? getTextColor('Expired')
											: getTextColor(updatedData.status),
								}}
							>
								Status:{' '}
								{differenceInSeconds(
									new Date(),
									new Date(updatedData.requestExpiryDate)
								) > 0 && updatedData.status === 'Pending Response'
									? 'Expired'
									: getStatusText(updatedData.status)}
							</Typography>
						</div>
					)}
					<Grid
						container
						direction='row'
						alignItems='stretch'
						justifyContent='space-between'
						spacing={2}
					>
						<Grid
							item
							container
							direction='row'
							alignItems='center'
							spacing={2}
							xs={7}
						>
							{updatedData.status !== 'Pending Response' &&
							updatedData.status !== 'Email failed' ? (
								<>
									<Grid item xs={12}>
										<Typography color='textPrimary' variant='body2'>
											Responded On
										</Typography>
										<Typography color='textPrimary' variant='body1'>
											{moment(updatedData.respondDate).format('DD/MM/YYYY')}
										</Typography>
									</Grid>
									{updatedData.status === 'Declined' && (
										<>
											<Grid item xs={12}>
												<Typography color='textPrimary' variant='body2'>
													Reason for Declining Request
												</Typography>
												{editable ? (
													<FormControl
														style={{ width: '100%' }}
														error={!!errorMessages[`responseData.rejReason`]}
													>
														<Select
															name='responseData.rejReason'
															value={updatedData.responseData?.rejReason}
															onChange={(e) => handleInputChange(e)}
															variant='filled'
															fullWidth
															autoWidth={true}
															margin='dense'
															style={{ marginTop: 8, marginBottom: 4 }}
															error={!!errorMessages[`responseData.rejReason`]}
														>
															{rejReasonList.map((val, index) => {
																return (
																	<MenuItem key={index} value={val}>
																		{val}
																	</MenuItem>
																);
															})}
														</Select>
														<FormHelperText>
															{errorMessages[`responseData.rejReason`] &&
																errorMessages[`responseData.rejReason`]}
														</FormHelperText>
													</FormControl>
												) : (
													<Typography
														color='textPrimary'
														variant='body1'
														align='left'
														style={{ marginTop: 8 }}
													>
														{updatedData.responseData?.rejReason}
													</Typography>
												)}
											</Grid>
											<Grid item xs={12}>
												<Typography color='textPrimary' variant='body2'>
													Remarks for Declining Request
												</Typography>
												{editable ? (
													<TextField
														name='responseData.rejRemarks'
														value={updatedData.responseData?.rejRemarks || ''}
														multiline
														InputProps={{
															style: { color: 'unset' },
														}}
														onChange={(e) => handleInputChange(e)}
														variant={'filled'}
														fullWidth
														margin='dense'
														minRows={4}
														error={!!errorMessages[`responseData.rejRemarks`]}
														helperText={
															errorMessages[`responseData.rejRemarks`] &&
															errorMessages[`responseData.rejRemarks`]
														}
													/>
												) : (
													<Typography
														color='textPrimary'
														variant='body1'
														align='left'
														style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
													>
														{updatedData.responseData?.rejRemarks || 'N/A'}
													</Typography>
												)}
											</Grid>
										</>
									)}
								</>
							) : (
								<>
									{responseEditable ? (
										<>
											<Grid item xs={12}>
												<Typography
													style={{ marginBottom: 8 }}
													color='textPrimary'
													variant='body2'
													align='left'
												>
													{`Was the Quotation Request accepted by ${updatedData.principalName}?`}
												</Typography>
												<div className='switcher'>
													<div className='fieldset'>
														<input
															type='radio'
															name='accepted'
															value='true'
															id='accept'
															onClick={() => handleResponseChange()}
															onChange={(e) => {}}
															checked={updatedData.responseData?.accepted}
														/>
														<label htmlFor='accept'>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<CheckIcon
																	style={{ marginRight: 6, width: 16 }}
																/>
																Accept
															</div>
														</label>
														<input
															type='radio'
															name='accepted'
															value='false'
															id='decline'
															onClick={() => handleResponseChange()}
															onChange={(e) => {}}
															checked={!updatedData.responseData?.accepted}
														/>
														<label htmlFor='decline'>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<CloseIcon
																	style={{ marginRight: 6, width: 16 }}
																/>
																Decline
															</div>
														</label>
														<span className='cd-switch'></span>
													</div>
												</div>
											</Grid>
											{!updatedData.responseData?.accepted && (
												<>
													<Grid item xs={12}>
														<Typography color='textPrimary' variant='body2'>
															Reason for Declining Request
														</Typography>
														<FormControl
															style={{ width: '100%' }}
															error={!!errorMessages[`responseData.rejReason`]}
														>
															<Select
																name='responseData.rejReason'
																value={updatedData.responseData?.rejReason}
																onChange={(e) => handleInputChange(e)}
																variant='filled'
																fullWidth
																autoWidth={true}
																margin='dense'
																style={{ marginTop: 8, marginBottom: 4 }}
																error={
																	!!errorMessages[`responseData.rejReason`]
																}
															>
																{rejReasonList.map((val, index) => {
																	return (
																		<MenuItem key={index} value={val}>
																			{val}
																		</MenuItem>
																	);
																})}
															</Select>
															<FormHelperText>
																{errorMessages[`responseData.rejReason`] &&
																	errorMessages[`responseData.rejReason`]}
															</FormHelperText>
														</FormControl>
													</Grid>
													<Grid item xs={12}>
														<Typography color='textPrimary' variant='body2'>
															Remarks for Declining Request
														</Typography>
														<TextField
															name='responseData.rejRemarks'
															value={updatedData.responseData?.rejRemarks || ''}
															multiline
															InputProps={{
																style: { color: 'unset' },
															}}
															onChange={(e) => handleInputChange(e)}
															variant={'filled'}
															fullWidth
															margin='dense'
															minRows={4}
															error={!!errorMessages[`responseData.rejRemarks`]}
															helperText={
																errorMessages[`responseData.rejRemarks`] &&
																errorMessages[`responseData.rejRemarks`]
															}
														/>
													</Grid>
												</>
											)}
										</>
									) : (
										<>
											<Grid item xs={12} md={6} lg={3}>
												<Typography color='textPrimary' variant='body2'>
													Request Date
												</Typography>
												<Typography color='textPrimary' variant='body1'>
													{moment(updatedData.requestDate).format('DD/MM/YYYY')}
												</Typography>
											</Grid>
											<Grid item xs={12} md={6} lg={4}>
												<Typography color='textPrimary' variant='body2'>
													Request Expiry Date
												</Typography>
												<Typography color='textPrimary' variant='body1'>
													{moment(updatedData.requestExpiryDate).format(
														'DD/MM/YYYY'
													)}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<div style={{ display: 'flex' }}>
													<Typography
														style={{ width: 48, textAlign: 'end' }}
														color='textPrimary'
														variant='body2'
													>
														From
													</Typography>
													<Typography color='textPrimary' variant='body2'>
														:
													</Typography>
													<Typography
														style={{ margin: '0px 8px' }}
														color='textPrimary'
														variant='body2'
													>
														{`${credentials.userProfile.profile.firstName} ${credentials.userProfile.profile.lastName}`}
													</Typography>
													<Typography color='textSecondary' variant='body1'>
														{`(mail-service@mg.uuoni.com)`}
													</Typography>
												</div>
											</Grid>
											<Grid item xs={12}>
												<div style={{ display: 'flex' }}>
													<Typography
														style={{ width: 48, textAlign: 'end' }}
														color='textPrimary'
														variant='body2'
													>
														To
													</Typography>
													<Typography color='textPrimary' variant='body2'>
														:
													</Typography>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															marginLeft: 8,
														}}
													>
														{updatedData.recipients.map((recipient, index) => (
															<div
																key={index}
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<Typography color='textPrimary' variant='body2'>
																	{recipient.name}
																</Typography>
																<Typography
																	style={{
																		marginLeft: 8,
																		color: theme.palette.text.secondary,
																	}}
																	variant='body1'
																>
																	({recipient.email})
																</Typography>
															</div>
														))}
													</div>
												</div>
											</Grid>
											<Grid item xs={12}>
												<div style={{ display: 'flex' }}>
													<Typography
														style={{ width: 48, textAlign: 'end' }}
														color='textPrimary'
														variant='body2'
													>
														Cc
													</Typography>
													<Typography color='textPrimary' variant='body2'>
														:
													</Typography>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															marginLeft: 8,
														}}
													>
														{updatedData.ccRecipients.length > 0 ? (
															<>
																{updatedData.ccRecipients.map(
																	(recipient, index) => (
																		<div
																			key={index}
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																			}}
																		>
																			<Typography
																				color='textPrimary'
																				variant='body2'
																			>
																				{recipient.name}
																			</Typography>
																			<Typography
																				style={{
																					marginLeft: 8,
																					color: theme.palette.text.secondary,
																				}}
																				variant='body1'
																			>
																				({recipient.email})
																			</Typography>
																		</div>
																	)
																)}
															</>
														) : (
															<Typography
																style={{ color: theme.palette.text.secondary }}
																variant='body1'
															>
																N/A
															</Typography>
														)}
													</div>
												</div>
											</Grid>
										</>
									)}
								</>
							)}
						</Grid>
						<Grid item xs={5}>
							<Grid
								container
								direction='row'
								alignItems='center'
								justifyContent='flex-end'
								spacing={1}
							>
								{responseEditable ? (
									<>
										<Button
											onClick={() => handleResponseCancel()}
											variant='outlined'
											color='primary'
											className={classes.button}
										>
											Cancel
										</Button>
										<Button
											onClick={() => handleResponseSave()}
											variant='contained'
											color='primary'
											className={classes.button}
											style={{ marginLeft: 8 }}
										>
											Save
										</Button>
									</>
								) : (
									<>
										{editable ? (
											<>
												<Button
													onClick={() => handleCancel()}
													variant='outlined'
													color='primary'
													className={classes.button}
												>
													Cancel
												</Button>
												<Button
													onClick={() => handleSave()}
													variant='contained'
													color='primary'
													className={classes.button}
													style={{ marginLeft: 8 }}
												>
													Save
												</Button>
											</>
										) : (
											<>
												{(updatedData.status === 'Pending Response' ||
													updatedData.status === 'Email failed') && (
													<>
														{!responseEditable && (
															<Grid item>
																<Button
																	onClick={(e) => initResponse(e)}
																	variant='contained'
																	color='primary'
																	className={classes.button}
																>
																	Enter Response
																</Button>
															</Grid>
														)}
														<Grid item>
															<ContextMenu
																setEditable={handleEditable}
																handleModalOpen={() =>
																	handleModalOpen('resendRequest')
																}
															/>
														</Grid>
													</>
												)}
											</>
										)}
									</>
								)}
							</Grid>
						</Grid>
						{!responseEditable && (
							<Grid item xs={12}>
								<Collapse
									in={
										updatedData.status !== 'Pending Response' ? expanded : true
									}
								>
									<Grid
										container
										direction='row'
										alignItems='center'
										spacing={2}
									>
										{updatedData.status !== 'Pending Response' && (
											<>
												<Grid item xs={12} md={6} lg={3}>
													<Typography color='textPrimary' variant='body2'>
														Request Date
													</Typography>
													<Typography color='textPrimary' variant='body1'>
														{moment(updatedData.requestDate).format(
															'DD/MM/YYYY'
														)}
													</Typography>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Typography color='textPrimary' variant='body2'>
														Request Expiry Date
													</Typography>
													<Typography color='textPrimary' variant='body1'>
														{moment(updatedData.requestExpiryDate).format(
															'DD/MM/YYYY'
														)}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<div style={{ display: 'flex' }}>
														<Typography
															style={{ width: 48, textAlign: 'end' }}
															color='textPrimary'
															variant='body2'
														>
															From
														</Typography>
														<Typography color='textPrimary' variant='body2'>
															:
														</Typography>
														<Typography
															style={{ margin: '0px 8px' }}
															color='textPrimary'
															variant='body2'
														>
															{`${credentials.userProfile.profile.firstName} ${credentials.userProfile.profile.lastName}`}
														</Typography>
														<Typography color='textSecondary' variant='body1'>
															{`(mail-service@mg.uuoni.com)`}
														</Typography>
													</div>
												</Grid>
												<Grid item xs={12}>
													<div style={{ display: 'flex' }}>
														<Typography
															style={{ width: 48, textAlign: 'end' }}
															color='textPrimary'
															variant='body2'
														>
															To
														</Typography>
														<Typography color='textPrimary' variant='body2'>
															:
														</Typography>
														<div
															style={{
																display: 'flex',
																flexDirection: 'column',
																marginLeft: 8,
															}}
														>
															{updatedData.recipients.map(
																(recipient, index) => (
																	<div
																		key={index}
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																		}}
																	>
																		<Typography
																			color='textPrimary'
																			variant='body2'
																		>
																			{recipient.name}
																		</Typography>
																		<Typography
																			style={{
																				marginLeft: 8,
																				color: theme.palette.text.secondary,
																			}}
																			variant='body1'
																		>
																			({recipient.email})
																		</Typography>
																	</div>
																)
															)}
														</div>
													</div>
												</Grid>
												<Grid item xs={12}>
													<div style={{ display: 'flex' }}>
														<Typography
															style={{ width: 48, textAlign: 'end' }}
															color='textPrimary'
															variant='body2'
														>
															Cc
														</Typography>
														<Typography color='textPrimary' variant='body2'>
															:
														</Typography>
														<div
															style={{
																display: 'flex',
																flexDirection: 'column',
																marginLeft: 8,
															}}
														>
															{updatedData.ccRecipients.length > 0 ? (
																<>
																	{updatedData.ccRecipients.map(
																		(recipient, index) => (
																			<div
																				key={index}
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																			>
																				<Typography
																					color='textPrimary'
																					variant='body2'
																				>
																					{recipient.name}
																				</Typography>
																				<Typography
																					style={{
																						marginLeft: 8,
																						color: theme.palette.text.secondary,
																					}}
																					variant='body1'
																				>
																					({recipient.email})
																				</Typography>
																			</div>
																		)
																	)}
																</>
															) : (
																<Typography
																	style={{
																		color: theme.palette.text.secondary,
																	}}
																	variant='body1'
																>
																	N/A
																</Typography>
															)}
														</div>
													</div>
												</Grid>
											</>
										)}
										<Grid item xs={10}>
											<Typography color='textPrimary' variant='body2'>
												Subject
											</Typography>
											{editable ? (
												<TextField
													name='requestSubject'
													value={updatedData.requestSubject || ''}
													onChange={(e) => handleInputChange(e)}
													InputProps={{
														style: { color: 'unset' },
													}}
													variant={'filled'}
													fullWidth
													margin='dense'
													error={!!errorMessages[`request.requestSubject`]}
													helperText={
														errorMessages[`request.requestSubject`] &&
														errorMessages[`request.requestSubject`]
													}
												/>
											) : (
												<Typography
													color='textPrimary'
													variant='body1'
													align='left'
													style={{ marginTop: 8 }}
												>
													{updatedData.requestSubject}
												</Typography>
											)}
										</Grid>
										<Grid item xs={10}>
											<Typography color='textPrimary' variant='body2'>
												Message
											</Typography>
											{editable ? (
												<TextField
													name='requestMessage'
													value={updatedData.requestMessage || ''}
													multiline
													InputProps={{
														style: { color: 'unset' },
													}}
													onChange={(e) => handleInputChange(e)}
													variant='filled'
													fullWidth
													margin='dense'
													minRows={4}
													error={!!errorMessages[`request.requestMessage`]}
													helperText={
														errorMessages[`request.requestMessage`] &&
														errorMessages[`request.requestMessage`]
													}
												/>
											) : (
												<Typography
													color='textPrimary'
													variant='body1'
													align='left'
													style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
												>
													{updatedData.requestMessage}
												</Typography>
											)}
										</Grid>
									</Grid>
								</Collapse>
								{updatedData.status !== 'Pending Response' && (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer',
											width: 'fit-content',
											marginTop: expanded && 8,
										}}
										onClick={toggleExpanded}
									>
										<Typography
											style={{
												color: theme.palette.tertiary.main,
												marginRight: 4,
											}}
											variant='subtitle1'
										>
											{expanded ? 'Hide Quote Request' : 'Show Quote Request'}
										</Typography>
									</div>
								)}
							</Grid>
						)}
					</Grid>
					{modalOpen && (
						<TransactionModal
							open={modalOpen}
							handleClose={handleModalClose}
							body={modalType}
							transactionId={requestData.transactionId}
							data={requestData}
							setTriggerUpdate={setTriggerUpdate}
							size={modalType === 'sendRequestForClaims' ? 'md' : 'sm'}
						/>
					)}
				</>
			)}
		</AuthUserContext.Consumer>
	);
}
