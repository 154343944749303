import Avatar from '@material-ui/core/Avatar';
import Backdrop from '../../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../config/api';
import '../styles.css';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({}));

const getAvatar = (str) => {
	return str.charAt(0).toUpperCase();
};

export default function ResendRequest(props) {
	const { open, handleClose, body, transactionId, data } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [selectionList, setSelectionList] = useState(data);
	const [expand, setExpand] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);
			try {
				api
					.post(`requests/resend/${data.requestType}/${data._id}`)
					.then((res) => {
						if (res.data.ok) {
							toast.success('Request sent successfully!');
							setLoading(false);
							props.setTriggerUpdate(true);
							handleClose();
						} else {
							toast.error(res.data.error);
							setLoading(false);
							handleClose();
						}
					});
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		}
	};

	const getType = () => {
		switch (data.requestType) {
			case 'quoteRequest': {
				return 'Quotation Request';
			}
			case 'claimRequest': {
				return 'Claim Request';
			}
			case 'renewalRequest': {
				return 'Renewal Request';
			}
		}
	};

	return (
		<>
			<Backdrop text='Loading Data...' open={loading} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Resend {getType()}
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					disableRipple
					disableFocusRipple
					disableTouchRipple
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<Typography
				variant='body1'
				color='textPrimary'
				style={{ marginTop: 24, marginBottom: 24 }}
			>
				You are about to resend the {getType()}. Are you sure you want to
				proceed?
			</Typography>
			<Grid
				container
				direction='row'
				alignItems='stretch'
				spacing={2}
				style={{ marginBottom: 16 }}
			>
				<Grid item xs={12}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Avatar aria-label='recipe' className={classes.avatar}>
							{getAvatar(data.principalName)}
						</Avatar>
						<Typography
							style={{ marginLeft: 8 }}
							color='textPrimary'
							variant='body2'
						>
							{data.principalName}
						</Typography>
					</div>
				</Grid>
				<Grid item xs={12}>
					<Grid container direction='row' alignItems='center'>
						<Grid item xs={12}>
							{data.recipients.map((recipient, index) => (
								<div
									key={index}
									style={{ display: 'flex', alignItems: 'center' }}
								>
									<Typography color='textPrimary' variant='body2'>
										{recipient.name}
									</Typography>
									<Typography
										style={{ marginLeft: 8 }}
										color='textPrimary'
										variant='body1'
									>
										({recipient.email})
									</Typography>
								</div>
							))}
							{data.ccRecipients?.map((recipient, index) => (
								<div
									key={index}
									style={{ display: 'flex', alignItems: 'center' }}
								>
									<Typography color='textSecondary' variant='body2'>
										{recipient.name}
									</Typography>
									<Typography
										style={{
											marginLeft: 8,
											color: theme.palette.text.secondary,
										}}
										variant='body1'
									>
										({recipient.email})
									</Typography>
								</div>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<div
				style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}
			>
				<Button
					style={{ marginRight: 16 }}
					onClick={handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={(e) => handleSubmit(e)}
					variant='contained'
					color='primary'
				>
					Proceed
				</Button>
			</div>
		</>
	);
}
