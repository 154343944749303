import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	tableRow: {},
	tableCell: {
		padding: 6,
	},
	tableCellSelected: {
		padding: 6,
		color: theme.palette.tertiary.main,
		fontWeight: 600,
	},
}));

const getColor = (status) => {
	switch (status) {
		case 'Paid':
			return '#7fa572';
		case 'Overdue':
			return '#f00606';
		case 'Pending':
			return '#f09106';
	}
};

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

const DataRow = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { data, handleCheckbox } = props;
	return (
		<TableRow
			className={classes.tableRow}
			hover
			selected={data.checked}
			key={data._id}
			onClick={(e) => {
				let target = e.target.closest('td');
				if (target === null) {
					return;
				}
				if (target.cellIndex === 0 || target.cellIndex === undefined) {
					return;
				}

				history.push(`/invoices/${data._id}`);
			}}
		>
			<TableCell className='checkBox' style={{ padding: 6 }}>
				<Checkbox
					checked={data.checked}
					onChange={(e) => handleCheckbox(e, data._id)}
					inputProps={{
						'aria-label': 'primary checkbox',
					}}
				/>
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				INV-{data.invoiceNo}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				TXN-{data.transactionNo}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				{data.client.contactName}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				{moment(data.createdAt).format('DD/MM/YYYY')}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				{currencyFormatter.format(data.amount)}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked,
				})}
			>
				<div style={{ color: getColor(data.status) }}>{data.status}</div>
			</TableCell>
		</TableRow>
	);
};

export default DataRow;
