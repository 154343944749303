import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import Typography from '@material-ui/core/Typography';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import CountryList from '../Data/countries.json';
import IndustryList from '../Data/industries.json';
import Tooltip from '../Tooltip';

export default function CustomTextField(props) {
	const {
		existing,
		type,
		isNumber,
		isCurrency,
		fieldName,
		value,
		label,
		required,
		disableFuture,
		handleChange,
		errorMessage,
		optionData,
		tooltipContent,
	} = props;

	const getOptions = (fieldName) => {
		switch (fieldName) {
			case 'gender':
				return (
					<>
						<option value='male'>Male</option>
						<option value='female'>Female</option>
					</>
				);
			case 'nationality':
				return (
					<>
						{CountryList.sort((a, b) => -b.label.localeCompare(a.label)).map(
							(country, index) => {
								return (
									<option key={index} value={country.code}>
										{country.label}
									</option>
								);
							}
						)}
					</>
				);
			case 'maritalStatus':
				return (
					<>
						<option value='single'>Single</option>
						<option value='married'>Married</option>
					</>
				);
			case 'industryName':
				return (
					<>
						{IndustryList.map((industry, index) => (
							<option key={index} value={industry.section}>
								{industry.name}
							</option>
						))}
					</>
				);
			case 'principalId':
				return (
					<>
						{optionData &&
							optionData.map((principal, index) => {
								return (
									<option key={index} value={principal._id}>
										{principal.shortName}
									</option>
								);
							})}
					</>
				);
			case 'paymentMethod':
				return (
					<>
						<option value='ibanking'>Bank Transfer / Internet Banking</option>
						<option value='paynow'>PayNow</option>
					</>
				);
			default:
				return null;
		}
	};

	const getField = (type) => {
		switch (type) {
			case 'input':
				return (
					<>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								{label}
							</Typography>
							{required && (
								<Typography
									style={{ marginLeft: 2 }}
									color='error'
									variant='body2'
									align='right'
								>
									*
								</Typography>
							)}
							{tooltipContent && (
								<div style={{ height: 0, marginLeft: 8 }}>
									<Tooltip content={tooltipContent} />
								</div>
							)}
						</Grid>
						<TextField
							name={fieldName}
							value={value}
							onChange={(e) => handleChange(e)}
							placeholder={`Enter ${label}`}
							variant='filled'
							type={isNumber ? 'number' : 'text'}
							fullWidth
							margin='dense'
							InputProps={
								isCurrency && {
									startAdornment: (
										<InputAdornment style={{ marginTop: 0 }} position='start'>
											<AttachMoneyOutlinedIcon />
										</InputAdornment>
									),
								}
							}
							error={!!errorMessage}
							helperText={errorMessage && errorMessage}
						/>
					</>
				);
			case 'input-multiline':
				return (
					<>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								{label}
							</Typography>
							{required && (
								<Typography
									style={{ marginLeft: 2 }}
									color='error'
									variant='body2'
									align='right'
								>
									*
								</Typography>
							)}
							{tooltipContent && (
								<div style={{ marginLeft: 8 }}>
									<Tooltip content={tooltipContent} />
								</div>
							)}
						</Grid>
						<TextField
							name={fieldName}
							value={value}
							onChange={(e) => handleChange(e)}
							placeholder={`Enter ${label}`}
							variant='filled'
							fullWidth
							margin='dense'
							multiline
							minRows={4}
							error={!!errorMessage}
							helperText={errorMessage && errorMessage}
						/>
					</>
				);
			case 'select':
				return (
					<>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								{label}
							</Typography>
							{required && (
								<Typography
									style={{ marginLeft: 2 }}
									color='error'
									variant='body2'
									align='right'
								>
									*
								</Typography>
							)}
							{tooltipContent && (
								<div style={{ marginLeft: 8 }}>
									<Tooltip content={tooltipContent} />
								</div>
							)}
						</Grid>
						<FormControl style={{ width: '100%' }} error={!!errorMessage}>
							<Select
								native
								name={fieldName}
								value={value}
								onChange={(e) => handleChange(e)}
								variant='filled'
								fullWidth
								autoWidth={true}
								margin='dense'
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option key={0} value=''></option>
								{getOptions(fieldName)}
							</Select>
							<FormHelperText>{errorMessage && errorMessage}</FormHelperText>
						</FormControl>
					</>
				);
			case 'date':
				return (
					<>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								{label}
							</Typography>
							{required && (
								<Typography
									style={{ marginLeft: 2 }}
									color='error'
									variant='body2'
									align='right'
								>
									*
								</Typography>
							)}
							{tooltipContent && (
								<div style={{ marginLeft: 8 }}>
									<Tooltip content={tooltipContent} />
								</div>
							)}
						</Grid>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								name={fieldName}
								disableToolbar
								placeholder='DD/MM/YYYY'
								inputVariant='filled'
								format='dd/MM/yyyy'
								disableFuture={disableFuture}
								openTo='year'
								views={['year', 'month', 'date']}
								margin='dense'
								fullWidth
								value={value ? value : null}
								autoOk
								variant='inline'
								invalidDateMessage='Invalid Date Format'
								onChange={(date) => handleChange(fieldName, date)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								error={!!errorMessage}
								helperText={errorMessage && errorMessage}
							/>
						</MuiPickersUtilsProvider>
					</>
				);
			default:
				return null;
		}
	};

	return (
		<>
			{existing ? (
				<>
					<Typography color='textPrimary' variant='body2'>
						{label}
					</Typography>
					<Typography
						color='textPrimary'
						variant='body1'
						align='left'
						style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
					>
						{type === 'date'
							? value
								? moment(value).format('DD/MM/YYYY')
								: null
							: value}
					</Typography>
				</>
			) : (
				<>{getField(type)}</>
			)}
		</>
	);
}
