import Box from '@material-ui/core/Box';
import Backdrop from '../../../components/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReplayIcon from '@material-ui/icons/Replay';
import SaveIcon from '@material-ui/icons/Save';
import { differenceInYears } from 'date-fns';
import { initial } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
	Link as RouterLink,
	useHistory,
	useParams,
	Prompt,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import NavPrompt from '../../../components/NavPrompt';
import api from '../../../config/api';
import CompanyForm from './companyForm';

import ContactForm from './contactForm';
import { nanoid } from 'nanoid';
import { isEmpty, cloneDeep } from 'lodash';
import Joi from 'joi';
import { Contact, Company } from '../../../utils/validators/schemas';
import { Save } from '../../../components/Icons';

const initialContactData = {
	data: {
		firstName: '',
		middleName: '',
		lastName: '',
		dob: null,
		gender: '',
		maritalStatus: '',
		idNo: '',
		nationality: '',
		email: '',
		mobileNo: '',
		homeNo: '',
		address: '',
		unitNo: '',
		postalCode: '',
		source: '',
		sourceInfo: '',
		notes: '',
		tags: {
			likes: [],
			dislikes: [],
		},
	},
	companies: [
		{
			id: nanoid(),
			isPrimary: true,
			isExisting: false,
			existingId: '',
			formData: {
				companyName: '',
				uen: '',
				website: '',
				establishDate: null,
				industryName: '',
				industryCode: '',
				companyEmail: '',
				officeNo: '',
				faxNo: '',
				companyAddress: '',
				companyUnitNo: '',
				companyPostalCode: '',
			},
		},
	],
};

const initialCompanyData = {
	data: {
		companyName: '',
		uen: '',
		website: '',
		establishDate: null,
		industryName: '',
		industryCode: '',
		companyEmail: '',
		officeNo: '',
		faxNo: '',
		companyAddress: '',
		companyUnitNo: '',
		companyPostalCode: '',
	},
	contacts: [
		{
			id: nanoid(),
			isPrimary: true,
			isExisting: false,
			existingId: '',
			formData: {
				firstName: '',
				middleName: '',
				lastName: '',
				dob: null,
				gender: '',
				maritalStatus: '',
				idNo: '',
				nationality: '',
				email: '',
				mobileNo: '',
				homeNo: '',
				address: '',
				unitNo: '',
				postalCode: '',
			},
		},
	],
};

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.secondary,
		width: '100%',
		marginTop: 20,
	},
}));

export default function CreateClient(props) {
	const { type } = useParams();
	const classes = useStyles();
	const theme = useTheme();

	let history = useHistory();

	const [formData, setFormData] = useState(
		type === 'company'
			? cloneDeep(initialCompanyData)
			: cloneDeep(initialContactData)
	);

	const [errorMessages, setErrorMessages] = useState({});
	const [errorTabs, setErrorTabs] = useState([false, false, false]);
	const [open, setOpen] = useState(false);
	const [modified, setModified] = useState(false);

	const handleValidation = () => {
		let result = {};

		if (type === 'contact') {
			let validateCompanies = formData.companies.some((company) =>
				Object.values(company.formData).some(Boolean)
			);
			if (validateCompanies) {
				let schema = Joi.object({
					data: Contact,
					companies: Joi.array().items(
						Joi.object({
							formData: Company,
						}).unknown(true)
					),
				});
				result = schema.validate(formData, {
					abortEarly: false,
					convert: false,
				});
			} else {
				let schema = Joi.object({
					data: Contact,
				}).unknown(true);
				result = schema.validate(formData, {
					abortEarly: false,
					convert: false,
				});
			}
		}
		if (type === 'company') {
			let validateContacts = formData.contacts.some((contact) =>
				Object.values(contact.formData).some(Boolean)
			);
			if (validateContacts) {
				let schema = Joi.object({
					data: Company,
					contacts: Joi.array().items(
						Joi.object({
							formData: Contact,
						}).unknown(true)
					),
				});
				result = schema.validate(formData, {
					abortEarly: false,
					convert: false,
				});
			} else {
				let schema = Joi.object({
					data: Company,
				}).unknown(true);
				result = schema.validate(formData, {
					abortEarly: false,
					convert: false,
				});
			}
		}
		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			const updatedErrorTabs = [false, false, false];
			for (let item of error.details) {
				const key = item.path.join('.');

				const message = item.message;
				errorData[key] = message;
				if (item.path[0] === 'data') {
					updatedErrorTabs[0] = true;
				}
				if (item.path[2] === 'formData') {
					updatedErrorTabs[1] = true;
				}
				if (item.context.key === 'notes' || item.context.key === 'sourceInfo') {
					updatedErrorTabs[2] = true;
				}
			}
			setErrorTabs(updatedErrorTabs);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleReset = () => {
		setFormData(
			type === 'company'
				? cloneDeep(initialCompanyData)
				: cloneDeep(initialContactData)
		);
		setErrorMessages({});
		setModified(false);
	};

	const handleFormUpdate = (key, value) => {
		setFormData((prev) => ({
			...prev,
			[key]: value,
		}));
		setModified(true);
	};

	const handleSave = async (e) => {
		try {
			e.preventDefault();
			setOpen(true);

			let valid = handleValidation();

			if (valid) {
				let endpointUrl;
				let redirectUrl;
				if (type === 'contact') {
					endpointUrl = 'contacts';
					redirectUrl = '/contacts';
				}
				if (type === 'company') {
					endpointUrl = 'companies';
					redirectUrl = '/companies';
				}
				await api.post(endpointUrl, formData).then((res) => {
					if (res.data.ok) {
						setFormData(
							type === 'company' ? initialCompanyData : initialContactData
						);

						setErrorMessages({});
						setModified(false);
						setTimeout(() => {
							toast.success('Client created!');
							setOpen(false);
							history.push(redirectUrl);
						}, 2000);
					} else {
						toast.error(res.data.error);
						setOpen(false);
					}
				});
			} else {
				toast.error('An error has occured!');
				setOpen(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<>
			<NavPrompt when={modified} />
			<Backdrop text='Creating Client...' open={open} />
			<Breadcrumbs separator='›' aria-label='breadcrumb'>
				<Typography color='inherit'>Clients</Typography>
				<Link
					component={RouterLink}
					color='inherit'
					to={type === 'company' ? '/companies' : '/contacts'}
				>
					{type === 'company' ? 'Companies' : 'Contacts'}
				</Link>
				<Typography color='primary'>
					New {type === 'company' ? 'Company' : 'Contact'}
				</Typography>
			</Breadcrumbs>
			<Typography variant='h2' style={{ margin: '24px 0px 0px 0px' }}>
				New {type === 'company' ? 'Company' : 'Contact'}
			</Typography>
			{/* <Typography variant='body1' style={{ margin: '24px 0px 0px 0px' }}>
				All fields marked with an asterisk (*) are <b>mandatory</b>.
			</Typography> */}
			<div style={{ display: 'flex', margin: '24px 0px 0px 0px' }}>
				<Typography color='textPrimary' variant='body1'>
					All fields marked with an asterisk (
					<span style={{ color: theme.palette.error.main }}>*</span>) are{' '}
					<b>mandatory</b>.
				</Typography>
			</div>
			<div className={classes.root}>
				{type === 'company' ? (
					<CompanyForm
						formData={formData}
						handleFormUpdate={handleFormUpdate}
						errorMessages={errorMessages}
						errorTabs={errorTabs}
					/>
				) : (
					<ContactForm
						formData={formData}
						handleFormUpdate={handleFormUpdate}
						errorMessages={errorMessages}
						errorTabs={errorTabs}
					/>
				)}
			</div>
			<div className='formFooter' style={{ display: 'flex', marginTop: 24 }}>
				<Button
					variant='outlined'
					color='primary'
					component={RouterLink}
					to={type === 'company' ? '/companies' : '/contacts'}
				>
					Cancel
				</Button>
				<Button
					style={{ marginLeft: 'auto', marginRight: 8 }}
					variant='outlined'
					color='primary'
					startIcon={<ReplayIcon />}
					onClick={() => handleReset()}
				>
					Clear All
				</Button>
				<Button
					variant='contained'
					color='primary'
					startIcon={
						<Save
							style={{ fontSize: 24 }}
							colorcode={theme.palette.primary.contrastText}
						/>
					}
					onClick={(e) => handleSave(e)}
				>
					Save
				</Button>
			</div>
		</>
	);
}
