import { Logtail } from '@logtail/browser';
import React from 'react';

const logtail = new Logtail(process.env.REACT_APP_LOG_SOURCE_TOKEN);

// The Context
const LoggerContext = React.createContext(logtail);

// Logger Provider
const LoggerProvider = ({ children }) => {
	return (
		<LoggerContext.Provider value={logtail}>{children}</LoggerContext.Provider>
	);
};

// Logger Consumer
const LoggerConsumer = ({ children }) => {
	return (
		<LoggerContext.Consumer>
			{(context) => {
				if (context === undefined) {
					throw new Error('LoggerConsumer must be used within LoggerProvider');
				}
				return children(context);
			}}
		</LoggerContext.Consumer>
	);
};

// useLogger Hook
const useLogger = () => {
	const context = React.useContext(LoggerContext);
	if (context === undefined)
		throw new Error('useLogger must be used within LoggerProvider');
	return context;
};

export { LoggerProvider, LoggerConsumer, useLogger, LoggerContext };
