import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import ClientLogin from '../views/External/ClientLogin';
import Calendar from '../views/Calendar';
import Companies from '../views/Clients/Companies';
import Contacts from '../views/Clients/Contacts';
import CreateClient from '../views/Clients/CreateClient';
import Dashboard from '../views/Dashboard';
import ClaimRequest from '../views/External/ClaimRequest';
import ConfirmClaim from '../views/External/ConfirmClaim';
// import CancellationRequest from '../views/External/CancellationRequest';
import FileRequest from '../views/External/FileRequest';
import QuoteRequest from '../views/External/QuoteRequest';
import QuoteSelection from '../views/External/QuoteSelection';
import ImportClients from '../views/Import';
import Invoices from '../views/Invoices';
import CreateInvoice from '../views/Invoices/CreateInvoice';
// import EditInvoice from '../views/Invoices/EditInvoice';
import InvoiceDetail from '../views/Invoices/InvoiceDetail';
import Login from '../views/Login';
import Notifications from '../views/Notifications';
import Onboarding from '../views/Onboarding';
import Register from '../views/Register';
// import Reports from '../views/Reports';
import Settings from '../views/Settings';
import Subscription from '../views/Settings/subscription/overview';
// import Test from '../views/Test/admin';
// import Team from '../views/Team';
import Transactions from '../views/Transactions';
import CreateTransaction from '../views/Transactions/CreateTransaction';
import TransactionDetail from '../views/Transactions/TransactionDetail';
import ForgotPassword from '../views/ForgotPassword';
// import VerifyUser from '../views/VerifyUser';
import { useUserProfile } from '../components/Session';

// import NotFound from '../views/NotFound';

const PublicRoutes = () => (
	<Switch>
		<Route exact path='/'>
			<Redirect to='/login' />
		</Route>
		<Route path='/register' exact component={Register} />
		<Route path='/login' exact component={Login} />
		<Route path='/forgot-password' exact component={ForgotPassword} />
		{/* <Route path='/verify-user' exact component={VerifyUser} /> */}
		<Route path='/file-request/:id' exact component={FileRequest} />
		<Route path='/quote-selection/:id' exact component={QuoteSelection} />
		{/* <Route path='/quote-request/:id' exact component={QuoteRequest} />
		<Route path='/claim-request/:id' exact component={ClaimRequest} /> */}
		<Route path='/confirm-claim/:id' exact component={ConfirmClaim} />
		<Redirect to='/login' />
	</Switch>
);

const PrivateRoutes = (props) => {
	const { isValidSubscription } = props;
	// const userProfile = useUserProfile();

	// const hasPermission = (requiredPermissions) => {
	// 	// Aggregate all permissions from user's roles
	// 	const allPermissions = userProfile.roles.reduce((acc, role) => {
	// 		return acc.concat(role.permissions);
	// 	}, []);

	// 	// Check if the user has any of the required permissions
	// 	let hasPermission = false;
	// 	if (requiredPermissions?.length > 0) {
	// 		hasPermission = requiredPermissions.some((permission) =>
	// 			allPermissions.includes(permission)
	// 		);
	// 	} else {
	// 		hasPermission = false;
	// 	}

	// 	console.log(`hasPermission ${requiredPermissions} > ${hasPermission}`);
	// 	return hasPermission;
	// };

	const SecuredRoute = ({
		component: Component,
		// requiredPermissions,
		path,
		...rest
	}) => {
		if (!isValidSubscription && path !== '/manage-subscription') {
			return <Redirect to='/manage-subscription' />;
		}
		if (isValidSubscription && path === '/manage-subscription') {
			return <Redirect to='/settings/subscription' />;
		}

		return (
			<Route
				{...rest}
				render={
					(props) => (
						// hasPermission(requiredPermissions) ? (
						<Component {...props} />
					)
					// ) : (
					// 	<Redirect to='/dashboard' />
					// )
				}
			/>
		);
	};

	return (
		<Switch>
			{/* <Route exact path='/'>
				<Redirect to='/dashboard' />
			</Route> */}
			<Route path='/onboarding' exact component={Onboarding} />
			{/* <SecuredRoute path='/calendar' exact component={Calendar} /> */}
			<SecuredRoute
				path='/dashboard'
				exact
				component={Dashboard}
				// requiredPermissions={['Dashboard-Full', 'Dashboard-ViewOnly']}
			/>
			<SecuredRoute
				path='/notifications'
				exact
				component={Notifications}
				// requiredPermissions={['AppUser']}
			/>
			<SecuredRoute
				path='/create-client/:type'
				exact
				component={CreateClient}
				// requiredPermissions={['CRM-Full']}
			/>
			<SecuredRoute
				path='/contacts/:id?'
				exact
				component={Contacts}
				// requiredPermissions={['CRM-Full', 'CRM-ViewOnly']}
			/>
			<SecuredRoute
				path='/companies/:id?'
				exact
				component={Companies}
				// requiredPermissions={['CRM-Full', 'CRM-ViewOnly']}
			/>
			<SecuredRoute
				path='/import/clients'
				exact
				component={ImportClients}
				// requiredPermissions={['CRM-Full']}
			/>
			<SecuredRoute
				path='/create-invoice/:id'
				exact
				component={CreateInvoice}
				// requiredPermissions={['Invoice-Full']}
			/>
			<SecuredRoute
				path='/invoices'
				exact
				component={Invoices}
				// requiredPermissions={['Invoice-Full', 'Invoice-ViewOnly']}
			/>
			<SecuredRoute
				path='/invoices/:id'
				exact
				component={InvoiceDetail}
				// requiredPermissions={['Invoice-Full', 'Invoice-ViewOnly']}
			/>
			<SecuredRoute
				path='/create-transaction'
				exact
				component={CreateTransaction}
				// requiredPermissions={['Transaction-Full']}
			/>
			<SecuredRoute
				path='/transactions'
				exact
				component={Transactions}
				// requiredPermissions={['Transaction-Full', 'Transaction-ViewOnly']}
			/>
			<SecuredRoute
				path='/transactions/:id'
				exact
				component={TransactionDetail}
				requiredPermissions={['Transaction-Full', 'Transaction-ViewOnly']}
			/>
			<SecuredRoute
				path='/settings/:type?/:subType?'
				exact
				component={Settings}
				// requiredPermissions={['AppUser']}
			/>
			{/* <SecuredRoute path='/reports' exact component={Reports} /> */}
			<SecuredRoute
				path='/manage-subscription'
				exact
				component={Subscription}
				// requiredPermissions={['AppUser']}
			/>
			{/* <SecuredRoute path='/team' exact component={Team} /> */}
			{/* <Route path='/test' exact component={Test} /> */}
			{/* Finally, catch all unmatched routes */}
			<Redirect to='/dashboard' />
		</Switch>
	);
};

export default { PublicRoutes, PrivateRoutes };
