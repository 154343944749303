import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../config/api';

const actions = [
	{
		value: '/create-transaction',
		label: 'Create a Transaction',
		group: 'Actions',
	},
	{
		value: '/create-client/contact',
		label: 'Create a Contact',
		group: 'Actions',
	},
	{
		value: '/create-client/company',
		label: 'Create a Company',
		group: 'Actions',
	},
	{ value: '/import/clients', label: 'Import Clients', group: 'Actions' },
	{
		value: '/settings/customization/email-templates',
		label: 'Customize Email Templates',
		group: 'Actions',
	},
	{
		value: '/settings/customization/invoice',
		label: 'Customize Invoice Settings',
		group: 'Actions',
	},
	{
		value: '/settings/customization/tags',
		label: 'Customize Tags',
		group: 'Actions',
	},
	{
		value: '/settings/principals/representing-principals',
		label: 'Customize Representing Principals',
		group: 'Actions',
	},
	{
		value: '/settings/principals/contacts',
		label: 'Customize Principal Contacts',
		group: 'Actions',
	},
	{
		value: '/settings/general/profile',
		label: 'Customize Profile',
		group: 'Actions',
	},
	{
		value: '/settings/general/org-profile',
		label: 'Customize Company Profile',
		group: 'Actions',
	},
];

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: 1201,
		'& .MuiAutocomplete-option:active': {
			color: theme.palette.tertiary.main,
			fontWeight: 500,
			backgroundColor: `${theme.palette.tertiary.light}5e`,
		},
		'& .MuiAutocomplete-option[data-focus="true"]': {
			color: theme.palette.tertiary.main,
			fontWeight: 500,
			backgroundColor: `${theme.palette.tertiary.light}5e`,
		},
	},
	textfield: {
		backgroundColor: theme.palette.background.default,
		borderRadius: 4,
		'& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
			borderRadius: 4,
		},
	},
}));

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement='bottom' />;
};

export default function GlobalSearch(props) {
	const classes = useStyles();
	const theme = useTheme();
	let history = useHistory();
	const { isDesktopOrLaptop, setSearchOpen } = props;
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [actionOptions, setActionOptions] = useState(actions);
	const [val, setVal] = useState(null);
	const [inputVal, setInputVal] = useState('');
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const searchActions = (val) => {
		let searchVal = val.replace(/\s+/g, ' ').toLowerCase();
		if (searchVal) {
			let results = [...actions].filter((obj) => {
				return Object.keys(obj).find((k) =>
					obj[k].replace(/\s+/g, ' ').toLowerCase().includes(searchVal)
				);
			});

			if (results) {
				setActionOptions(results);
			}
		}
	};

	const getData = async (val) => {
		if (loading || !val) {
			return;
		}

		if (!loading) {
			setLoading(true);
			try {
				let results = await api.get('search/global', {
					params: { value: val },
				});

				setOptions(results.data.data);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (!open) {
			setOptions([]);
			setActionOptions(actions);
		}
	}, [open]);

	const handler = useRef(debounce(getData, 500));

	const handleInputChange = (val) => {
		setInputVal(val);
		if (val.length >= 1) {
			searchActions(val);
			handler.current(val);
		} else {
			setOptions([]);
			setActionOptions(actions);
			handler.current('');
		}
	};

	const handleChange = (option) => {
		if (option === null) return;
		setInputVal('');
		if (!isDesktopOrLaptop) {
			setSearchOpen(false);
		}
		history.push(option.value);
	};

	const resolvedOptions = [...actionOptions, ...options];

	return (
		<Autocomplete
			id='data-search'
			style={{ zIndex: 1201, width: '100%' }}
			freeSolo={val?.length ? false : true}
			open={open}
			onOpen={() => {
				if (resolvedOptions.length === 0) {
					if (open) setOpen(false);
				} else {
					if (!open) setOpen(true);
				}
			}}
			onInputChange={(_, value) => {
				if (value.length === 0) {
					if (open) setOpen(false);
				} else {
					if (!open) setOpen(true);
				}
			}}
			onClose={() => {
				setOpen(false);
			}}
			onChange={(e, newValue) => {
				handleChange(newValue);
			}}
			getOptionLabel={(option) => option.label || ''}
			options={resolvedOptions}
			groupBy={(option) => option.group}
			filterOptions={(options, { inputValue }) =>
				options.filter((option) => {
					if (option.data) {
						return Object.values(option.data).some((val) => {
							if (val && typeof val === 'string') {
								return val.toLowerCase().includes(inputValue);
							} else {
								return true;
							}
						});
					} else {
						return true;
					}
				})
			}
			loading={loading}
			forcePopupIcon={false}
			openOnFocus={false}
			noOptionsText=''
			clearOnBlur
			clearOnEscape
			renderInput={(params) => (
				<TextField
					{...params}
					className={classes.textfield}
					value={inputVal}
					placeholder='Search'
					variant='filled'
					onChange={(e) => {
						handleInputChange(e.target.value);
					}}
					InputProps={{
						...params.InputProps,
						style: {
							flexWrap: 'nowrap',
						},
						disableUnderline: true,
						startAdornment: (
							<SearchIcon
								style={{
									marginLeft: 16,
									marginRight: -8,
									color: theme.palette.grey[500],
								}}
							/>
						),
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color='inherit' size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
					error={!!errorMessage}
					helperText={errorMessage && errorMessage}
				/>
			)}
			PopperComponent={CustomPopper}
		/>
	);
}
