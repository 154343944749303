import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import FileRequestViewer from './FileRequestViewer';
import RequestViewer from './RequestViewer';
import ClaimDetailsViewer from './ClaimDetailsViewer';
import PolicyDetailsViewer from './PolicyDetailsViewer';
import ExistingPolicyDetailsViewer from './ExistingPolicyDetailsViewer';

const Accordion = withStyles({
	root: {
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',

		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		padding: '0px 24px',
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: 0,

		backgroundColor: theme.palette.background.default,
	},
}))(MuiAccordionDetails);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({}));

export default function DetailsViewer(props) {
	const {
		transaction,
		setTransaction,
		setTriggerUpdate,
		setTriggerUpdateActivities,
	} = props;
	const classes = useStyles(props);
	const theme = useTheme();

	const [expanded, setExpanded] = useState('');

	const hasClientRequest = !isEmpty(transaction.clientRequestId);
	const disableEdit =
		transaction.status === 'Completed' ||
		transaction.subStatus === 'Pending Policy Info';

	useEffect(() => {
		if (
			hasClientRequest &&
			transaction.clientRequestId?.status !== 'Responded'
		) {
			setExpanded('panel1');
		} else if (
			transaction.isDirectPurchase ||
			transaction.status === 'Completed'
		) {
			setExpanded('panel4');
		} else {
			setExpanded('panel2');
		}
	}, [props]);

	const getHeader = (type) => {
		switch (type) {
			case 'Claims':
				return 'Claim Info';
			case 'Quotation':
				return 'Policy';
			case 'Renewal':
				return 'Renewed Policy';
			default:
				return 'Unknown Header';
		}
	};

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<>
			{transaction.transactionType !== 'Quotation' && (
				<Accordion
					square
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 'panel0'}
					onChange={handleChange('panel0')}
				>
					<AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
						<Typography
							style={{
								textTransform: 'uppercase',
								marginRight: 'auto',
							}}
							color='textPrimary'
							variant='h4'
						>
							Existing Policy
						</Typography>
						{expanded === 'panel0' ? <ExpandLess /> : <ExpandMore />}
					</AccordionSummary>
					<AccordionDetails>
						<ExistingPolicyDetailsViewer
							transaction={transaction}
							setTransaction={setTransaction}
							setTriggerUpdate={setTriggerUpdate}
						/>
					</AccordionDetails>
				</Accordion>
			)}
			{hasClientRequest && (
				<Accordion
					square
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
				>
					<AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
						<Typography
							style={{
								textTransform: 'uppercase',
								marginRight: 'auto',
							}}
							color='textPrimary'
							variant='h4'
						>
							File Request
						</Typography>
						{expanded === 'panel1' ? <ExpandLess /> : <ExpandMore />}
					</AccordionSummary>
					<AccordionDetails>
						<FileRequestViewer
							transaction={transaction}
							setTransaction={setTransaction}
							clientRequest={transaction.clientRequestId}
							setTriggerUpdate={setTriggerUpdate}
							setTriggerUpdateActivities={setTriggerUpdateActivities}
							disableEdit={disableEdit}
						/>
					</AccordionDetails>
				</Accordion>
			)}
			{!transaction.isDirectPurchase && (
				<Accordion
					square
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 'panel2'}
					onChange={handleChange('panel2')}
				>
					<AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
						<Typography
							style={{
								textTransform: 'uppercase',
								marginRight: 'auto',
							}}
							color='textPrimary'
							variant='h4'
						>
							{transaction.transactionType === 'Claims'
								? 'Claim Request'
								: 'Quotations'}
						</Typography>
						{expanded === 'panel2' ? <ExpandLess /> : <ExpandMore />}
					</AccordionSummary>
					<AccordionDetails>
						<RequestViewer
							transaction={transaction}
							setTransaction={setTransaction}
							setTriggerUpdate={setTriggerUpdate}
							setTriggerUpdateActivities={setTriggerUpdateActivities}
							disableEdit={disableEdit}
						/>
					</AccordionDetails>
				</Accordion>
			)}
			{/* {transaction.transactionType === 'Claims' && (
				<Accordion
					square
					TransitionProps={{ unmountOnExit: true }}
					expanded={expanded === 'panel3'}
					onChange={handleChange('panel3')}
				>
					<AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
						<Typography
							style={{
								textTransform: 'uppercase',
								marginRight: 'auto',
							}}
							color='textPrimary'
							variant='h4'
						>
							Claim Request
						</Typography>
						{expanded === 'panel3' ? <ExpandLess /> : <ExpandMore />}
					</AccordionSummary>
					<AccordionDetails>
						{transaction.isDirectPurchase ? (
							<ClaimDetailsViewer
								transaction={transaction}
								setTransaction={setTransaction}
								setTriggerUpdate={setTriggerUpdate}
								setTriggerUpdateActivities={setTriggerUpdateActivities}
							/>
						) : (
							<RequestViewer
								transaction={transaction}
								setTransaction={setTransaction}
								setTriggerUpdate={setTriggerUpdate}
								setTriggerUpdateActivities={setTriggerUpdateActivities}
								disableEdit={disableEdit}
							/>
						)}
					</AccordionDetails>
				</Accordion>
			)} */}
			{/* {transaction.transactionType !== 'Claims' && ( */}
			<Accordion
				square
				TransitionProps={{ unmountOnExit: true }}
				expanded={expanded === 'panel4'}
				onChange={handleChange('panel4')}
			>
				<AccordionSummary aria-controls='panel4d-content' id='panel4d-header'>
					<Typography
						style={{
							textTransform: 'uppercase',
							marginRight: 'auto',
						}}
						color='textPrimary'
						variant='h4'
					>
						{getHeader(transaction.transactionType)}
					</Typography>
					{expanded === 'panel4' ? <ExpandLess /> : <ExpandMore />}
				</AccordionSummary>
				<AccordionDetails>
					{transaction.transactionType === 'Claims' ? (
						<ClaimDetailsViewer
							transaction={transaction}
							setTransaction={setTransaction}
							setTriggerUpdate={setTriggerUpdate}
							setTriggerUpdateActivities={setTriggerUpdateActivities}
						/>
					) : (
						<PolicyDetailsViewer
							transaction={transaction}
							setTransaction={setTransaction}
							setTriggerUpdate={setTriggerUpdate}
							setTriggerUpdateActivities={setTriggerUpdateActivities}
						/>
					)}
				</AccordionDetails>
			</Accordion>
			{/* )} */}
		</>
	);
}
