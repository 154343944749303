import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import TagMenu from '../../../components/TagMenu';
import SettingModal from '../../../components/Modals/settings';
import api from '../../../config/api';
import { toast } from 'react-toastify';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	chip: {
		backgroundColor: theme.palette.primary.light,
		'& .MuiChip-deleteIcon': {
			display: 'none',
		},
		'&:hover': {
			'& .MuiChip-deleteIcon': {
				display: 'block',
			},
		},
	},
	addTagChip: {
		color: theme.palette.tertiary.main,
		border: `1px solid ${theme.palette.tertiary.main}`,
	},
}));

const options = ['My Tags'];

export default function Tags(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [tags, setTags] = useState([]);
	const [orgTags, setOrgTags] = useState([]);
	const [open, setOpen] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [action, setAction] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(true);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedTag, setSelectedTag] = useState('');
	const [selectedType, setSelectedType] = useState('');
	const [errorMessages, setErrorMessages] = useState({});

	useEffect(() => {
		getTags();
	}, []);

	const getTags = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('settings/type/tags')
			.then((response) => {
				if (response.data.ok) {
					setTags(response.data.data.tags);
					setOrgTags(response.data.data.orgTags);
				}

				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const toggleDeleteDialog = (tag, type) => {
		if (!dialogOpen) {
			setDialogOpen(true);
			setSelectedTag(tag);
			setSelectedType(type);
		}
		setDialogOpen(!dialogOpen);
	};

	const openTagMenu = (e, action, tag) => {
		if (tag) {
			setSelectedTag(tag);
		}
		return setAnchorEl(e.currentTarget), setAction(action), setOpen(true);
	};

	const closeTagMenu = () => {
		return (
			setAnchorEl(null),
			setAction(''),
			setSelectedTag(''),
			setErrorMessages({}),
			setOpen(!open)
		);
	};

	const handleAddTag = (tagLabel, type) => {
		if (type === 'personal') {
			if (tags.indexOf(tagLabel) !== -1) {
				setErrorMessages((prev) => ({
					...prev,
					tagLabel: 'Tag exists. Please enter another tag name.',
				}));
			} else {
				let updatedTags = [...tags, tagLabel];

				setErrorMessages({});
				setTags(updatedTags);
				handleSaveTags(type, updatedTags);
			}
		}

		if (type === 'org') {
			if (orgTags.indexOf(tagLabel) !== -1) {
				setErrorMessages((prev) => ({
					...prev,
					tagLabel: 'Tag exists. Please enter another tag name.',
				}));
			} else {
				let updatedTags = [...orgTags, tagLabel];

				setErrorMessages({});
				setOrgTags(updatedTags);
				handleSaveTags(type, updatedTags);
			}
		}
	};

	const handleUpdateTag = (tagLabel, type) => {
		if (type === 'personal') {
			if (tags.indexOf(tagLabel) !== -1) {
				setErrorMessages((prev) => ({
					...prev,
					tagLabel: 'Tag exists. Please enter another tag name.',
				}));
			} else {
				let updatedTags = [...tags];
				let tagIndex = updatedTags.indexOf(selectedTag);
				updatedTags[tagIndex] = tagLabel;

				setErrorMessages({});
				setTags(updatedTags);

				api
					.post('settings/update-tag', {
						oldTag: selectedTag,
						newTag: tagLabel,
					})
					.then((res) => {
						if (res.data.ok) {
							toast.success('Tags updated!');
							closeTagMenu();
						} else {
							toast.error(res.data.error);
						}
					});
			}
		}

		if (type === 'org') {
			if (orgTags.indexOf(tagLabel) !== -1) {
				setErrorMessages((prev) => ({
					...prev,
					tagLabel: 'Tag exists. Please enter another tag name.',
				}));
			} else {
				let updatedTags = [...orgTags];
				let tagIndex = updatedTags.indexOf(selectedTag);
				updatedTags[tagIndex] = tagLabel;

				setErrorMessages({});
				setOrgTags(updatedTags);

				api
					.post('settings/organization/update-tag', {
						oldTag: selectedTag,
						newTag: tagLabel,
					})
					.then((res) => {
						if (res.data.ok) {
							toast.success('Tags updated!');
							closeTagMenu();
						} else {
							toast.error(res.data.error);
						}
					});
			}
		}
	};

	const handleDeleteTag = () => {
		if (!selectedType || !selectedTag) {
			return;
		} else {
			if (selectedType === 'personal') {
				let updatedTags = tags.filter((props) => props !== selectedTag);
				setTags(updatedTags);
				setDialogOpen(false);

				api.post('settings/delete-tag', { tag: selectedTag }).then((res) => {
					if (res.data.ok) {
						toast.success('Tags updated!');
					} else {
						toast.error(res.data.error);
					}
				});
			}

			if (selectedType === 'org') {
				let updatedTags = orgTags.filter((props) => props !== selectedTag);
				setOrgTags(updatedTags);
				setDialogOpen(false);

				api
					.post('settings/organization/delete-tag', { tag: selectedTag })
					.then((res) => {
						if (res.data.ok) {
							toast.success('Tags updated!');
						} else {
							toast.error(res.data.error);
						}
					});
			}
		}
	};

	const handleSaveTags = (type, dto) => {
		console.log('handleSave');
		closeTagMenu();
		if (type === 'personal') {
			api.put('settings/type/tags', dto).then((res) => {
				if (res.data.ok) {
					toast.success('Tags updated!');
				} else {
					toast.error(res.data.error);
				}
			});
		}
		if (type === 'org') {
			api.put('settings/organization/type/tags', dto).then((res) => {
				if (res.data.ok) {
					toast.success('Tags updated!');
				} else {
					toast.error(res.data.error);
				}
			});
		}
	};

	const handleChangeTab = (event, newValue) => {
		setSelectedIndex(newValue);
	};

	return (
		<div style={{ margin: -24 }}>
			<Typography variant='body1' style={{ margin: 24 }}>
				Tags serves as a storage of all your relationship attributes or
				associations that uniquely defines your clients.
			</Typography>
			<div className={classes.root}>
				<Tabs
					value={selectedIndex}
					onChange={handleChangeTab}
					aria-label='full width tabs example'
				>
					{options.map((option, index) => (
						<Tab
							classes={{ root: classes.tabRoot }}
							key={option}
							label={option}
							{...a11yProps(index)}
						/>
					))}
				</Tabs>
			</div>
			<Divider />
			<Box width='100%' style={{ height: 'inherit', overflowY: 'auto' }}>
				<TabPanel value={selectedIndex} index={0} dir={theme.direction}>
					{loading ? (
						<CircularProgress />
					) : (
						<>
							<Grid container className={classes.gridContainer} spacing={2}>
								{tags.map((tag, index) => (
									<Grid key={index} item>
										<Chip
											label={tag}
											className={classes.chip}
											color='primary'
											onClick={(e) => openTagMenu(e, 'update', tag)}
											onDelete={() => toggleDeleteDialog(tag, 'personal')}
										/>
									</Grid>
								))}

								<Grid key={'new'} item>
									<Chip
										label='Add Tag'
										classes={{ root: classes.addTagChip }}
										color='primary'
										onClick={(e) => openTagMenu(e, 'add')}
										variant='outlined'
									/>
								</Grid>
							</Grid>
							<TagMenu
								variant='mini'
								open={open}
								anchorEl={anchorEl}
								position='right'
								type={'personal'}
								action={action}
								selectedTag={selectedTag}
								handleAddTag={handleAddTag}
								handleUpdateTag={handleUpdateTag}
								closeTagMenu={() => closeTagMenu()}
								errorMessages={errorMessages}
							/>
						</>
					)}
				</TabPanel>
				{/* <TabPanel value={selectedIndex} index={1} dir={theme.direction}>
					{loading ? (
						<CircularProgress />
					) : (
						<>
							<Grid container className={classes.gridContainer} spacing={2}>
								{orgTags.map((tag, index) => (
									<Grid key={index} item>
										<Chip
											label={tag}
											className={classes.chip}
											color='primary'
											onClick={(e) => openTagMenu(e, 'update', tag)}
											onDelete={() => toggleDeleteDialog(tag, 'org')}
										/>
									</Grid>
								))}

								<Grid key={'new'} item>
									<Chip
										label='Add Tag'
										classes={{ root: classes.addTagChip }}

										color='primary'
										onClick={(e) => openTagMenu(e, 'add')}
										variant='outlined'
									/>
								</Grid>
							</Grid>
							<TagMenu
								variant='mini'
								open={open}
								anchorEl={anchorEl}
								position='right'
								type={'org'}
								action={action}
								selectedTag={selectedTag}
								handleAddTag={handleAddTag}
								handleUpdateTag={handleUpdateTag}
								closeTagMenu={() => closeTagMenu()}
								errorMessages={errorMessages}
							/>
						</>
					)}
				</TabPanel> */}
			</Box>
			<SettingModal
				open={dialogOpen}
				body={'delete-tag'}
				selectedTag={selectedTag}
				handleCancel={() => toggleDeleteDialog('', '')}
				handleConfirm={() => handleDeleteTag()}
				handleClose={() => toggleDeleteDialog('', '')}
			/>
		</div>
	);
}
