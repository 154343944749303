const Client = [
	{ value: '', display: 'Select an attribute' },
	{ value: 'contactGroup', display: 'Contact Fields' },
	{ value: 'firstName', display: 'First Name (Required)' },
	{ value: 'email', display: 'Email (Required)' },
	{ value: 'middleName', display: 'Middle Name' },
	{ value: 'lastName', display: 'Last Name' },
	{ value: 'dob', display: 'Date of Birth' },
	{ value: 'idNo', display: 'NRIC / FIN / Passport No.' },
	{ value: 'nationality', display: 'Nationality' },
	{ value: 'maritalStatus', display: 'Marital Status' },
	{ value: 'gender', display: 'Gender' },
	{ value: 'mobileNo', display: 'Mobile No.' },
	{ value: 'homeNo', display: 'Home No.' },
	{ value: 'address', display: 'Blk / House No. / Street Address' },
	{ value: 'unitNo', display: 'Unit No.' },
	{ value: 'postalCode', display: 'Postal Code' },
	{ value: 'source', display: 'Source' },
	// { value: 'sourceInfo', display: 'Source Info' },
	{ value: 'notes', display: 'Notes' },
	{ value: 'companyGroup', display: 'Company Fields' },
	{ value: 'companyName', display: 'Company Name' },
	{ value: 'uen', display: 'UEN' },
	{ value: 'website', display: 'Website' },
	// { value: 'jobTitle', display: 'Job Title' },
	// { value: 'yearsOperational', display: 'Years in Business' },
	{ value: 'establishDate', display: 'Established On' },
	{ value: 'industryName', display: 'Nature of Business' },
	{ value: 'companyEmail', display: 'Email' },
	{ value: 'officeNo', display: 'Office No.' },
	{ value: 'faxNo', display: 'Fax No.' },
	{ value: 'companyAddress', display: 'Street Address' },
	{ value: 'companyUnitNo', display: 'Unit No.' },
	{ value: 'companyPostalCode', display: 'Postal Code' },
];

const Underwriter = [
	{ value: '', display: 'Select an attribute' },
	{ value: 'firstName', display: 'First Name' },
	{ value: 'middleName', display: 'Middle Name' },
	{ value: 'lastName', display: 'Last Name' },
	{ value: 'email', display: 'Email' },
	{ value: 'contactNo', display: 'Contact No.' },
	{ value: 'principal', display: 'Principal' },
	{ value: 'status', display: 'Status' },
	{ value: 'remarks', display: 'Remarks' },
];

export { Client, Underwriter };
