import {
	PDFViewer,
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from '@react-pdf/renderer';
import moment from 'moment';
import React, { useState } from 'react';

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Helvetica',
		fontSize: 11,
		paddingTop: 30,
		paddingLeft: 30,
		paddingRight: 30,
		lineHeight: 1.5,
		flexDirection: 'column',
	},
	logo: {
		maxWidth: 250,
		maxHeight: 150,
		marginRight: 'auto',
	},
	divider: {
		marginTop: 16,
		marginBottom: 16,
		borderBottom: '1px solid #EEEEEE',
	},
	titleContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	titleContentContainer: {
		marginLeft: 'auto',
	},
	reportTitle: {
		marginLeft: 'auto',
		fontSize: 25,
		textAlign: 'right',
		textTransform: 'uppercase',
	},
	fromContainer: {
		flexDirection: 'column',
		marginTop: 8,
	},
	fromFieldContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	fromText: {
		fontSize: 12,
		fontStyle: 'bold',
	},
	fromLabel: {
		textAlign: 'right',
		marginRight: 12,
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	billToContainer: {
		flexDirection: 'column',
		width: '45%',
	},
	invoiceInfoContainer: {
		flexDirection: 'column',
		width: '45%',
		marginLeft: 'auto',
	},
	invoiceInfoFieldContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	invoiceInfoLabel: {
		textAlign: 'right',
		marginRight: 12,
		width: '70%',
	},
	invoiceInfoText: {
		fontSize: 12,
		fontStyle: 'bold',
		textAlign: 'left',
		width: '30%',
	},
	billTo: {
		paddingBottom: 3,
		textTransform: 'uppercase',
	},
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 16,
		marginBottom: 16,
	},
	tableHeader: {
		flexDirection: 'row',
		backgroundColor: '#5E80E6',
		color: '#FFFFFF',
		alignItems: 'center',
		height: 24,
		fontStyle: 'bold',
		flexGrow: 1,
		marginBottom: 16,
	},
	description: {
		width: '60%',
		paddingLeft: 8,
	},
	policyNo: {
		width: '20%',
		paddingLeft: 8,
	},
	premium: {
		width: '20%',
		paddingLeft: 8,
	},
	tableRow: {
		flexDirection: 'row',
		alignItems: 'baseline',
		borderBottom: '1px solid #EEEEEE',
		paddingTop: 8,
		paddingBottom: 8,
	},
	descriptionContainer: {
		width: '60%',
		paddingLeft: 8,
	},
	insuranceType: {
		fontSize: 12,
		fontStyle: 'bold',
	},
	descriptionFieldContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	descriptionLabel: {
		marginRight: 8,
		width: '35%',
	},
	descriptionText: {
		width: '65%',
	},
	footerContainer: {
		paddingLeft: 8,
		paddingRight: 8,
	},
	paymentMethodHeader: {
		flexDirection: 'row',
		backgroundColor: '#5E80E6',
		color: '#FFFFFF',
		alignItems: 'center',
		height: 24,
		fontStyle: 'bold',
		marginBottom: 8,
	},
	paymentMethodHeaderText: {},
	paymentMethod: {
		textDecoration: 'underline',
		fontWeight: 'bold',
	},
	paymentMethodInfoContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	paymentMethodInfoLabel: {
		fontStyle: 'bold',
		marginRight: 8,
	},
	paymentMethodInfoText: {},
	terms: {
		marginTop: 24,
		textDecoration: 'underline',
		fontWeight: 'bold',
	},
	termsText: {},
	totalLabel: {
		width: '80%',
		textAlign: 'right',
		paddingRight: 24,
	},
	totalText: {
		width: '20%',
		paddingLeft: 8,
	},
	qrImage: {
		width: 150,
		height: 150,
	},
});

const testData = {
	id: '5df3180a09ea16dc4b95f910',
	company: 'MANTRIX',
	email: 'client@example.com',
	mobileNo: '+6591234567',
	address: 'Example Road, Singapore',
	unitNo: '#03-03',
	postalCode: '812345',
	invoiceDate: new Date(),

	invoiceNo: 1,

	orgInfo: {
		companyName: 'Uuoni Pte Ltd',
		address: '71 Lorong 23 Geylang',
		unitNo: '#08-18',
		postalCode: 388386,
		uen: 'T09LL0001B',
	},
	profile: {
		firstName: 'Jason',
		lastName: 'Wong',
	},
	refTransaction: {
		transactionId: {
			_id: '623afaed2c4019002350e043',
			client: {
				contactId: '623afa8f2c4019002350def2',
				companyId: '623afa8f2c4019002350def5',
				contactName: 'Client Name',
				companyName: 'Client Company Pte Ltd',
			},
			insuranceType: 'Maid Insurance',
			insuranceSubType: '',
			policyData: {
				policyNo: 'Policy-12345',
				principalName: 'ABC Insurance Company',
				premiumAmt: 50,
				sumInsured: 500000,
				startDate: '2022-03-30T10:48:12.797+00:00',
				endDate: '2022-06-30T10:48:12.797+00:00',
				remarks: 'Cover for All accidents',
			},
		},
		transactionNo: '1',
	},
};

const InvoiceSample = (props) => {
	const { profile, settings } = props;
	const [invoiceData, setInvoiceData] = useState(testData);

	const getDueDate = (date, days) => {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	};

	return (
		<PDFViewer style={{ width: '100%', height: '100%' }}>
			<Document>
				<Page size='A4' style={styles.page}>
					<View style={styles.titleContainer}>
						{settings.orgLogo !== null && (
							<Image
								style={styles.logo}
								src={
									settings.orgLogo !== null &&
									`data:image/*;base64,${settings.orgLogo}`
								}
							/>
						)}
						<View style={styles.titleContentContainer}>
							<Text style={styles.reportTitle}>INVOICE</Text>
							<View style={styles.fromContainer}>
								<View style={styles.fromFieldContainer}>
									<Text style={styles.fromText}>
										{profile.orgProfile.companyName}
									</Text>
								</View>
								<View style={styles.fromFieldContainer}>
									<Text style={styles.fromText}>
										{profile.orgProfile.address}, {profile.orgProfile.unitNo}
									</Text>
								</View>
								<View style={styles.fromFieldContainer}>
									<Text style={styles.fromText}>
										Singapore {profile.orgProfile.postalCode}
									</Text>
								</View>
							</View>
							<View style={styles.fromContainer}>
								<View style={styles.fromFieldContainer}>
									<Text style={styles.fromLabel}>UEN:</Text>
									<Text style={styles.fromText}>{profile.orgProfile.uen}</Text>
								</View>
								<View style={styles.fromFieldContainer}>
									<Text style={styles.fromLabel}>Agent:</Text>
									<Text style={styles.fromText}>
										{profile.userProfile.firstName}{' '}
										{profile.userProfile.lastName}
									</Text>
								</View>
								<View style={styles.fromFieldContainer}>
									<Text style={styles.fromLabel}>Agent ID:</Text>
									<Text style={styles.fromText}>
										{profile.userProfile.agentId}
									</Text>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.divider} />
					<View style={styles.headerContainer}>
						<View style={styles.billToContainer}>
							<Text style={styles.billTo}>Bill To</Text>
							<Text>
								{invoiceData.refTransaction.transactionId.client.contactName}
							</Text>
							<Text>
								{invoiceData.refTransaction.transactionId.client.companyName}
							</Text>
							<Text>
								{invoiceData.address}, {invoiceData.unitNo}
							</Text>
							<Text>Singapore {invoiceData.postalCode}</Text>
							<Text>{invoiceData.email}</Text>
							<Text>{invoiceData.mobileNo}</Text>
						</View>

						<View style={styles.invoiceInfoContainer}>
							<View style={styles.invoiceInfoFieldContainer}>
								<Text style={styles.invoiceInfoLabel}>Invoice Number:</Text>
								<Text style={styles.invoiceInfoText}>
									INV-{invoiceData.invoiceNo}
								</Text>
							</View>
							<View style={styles.invoiceInfoFieldContainer}>
								<Text style={styles.invoiceInfoLabel}>Transaction Number:</Text>
								<Text style={styles.invoiceInfoText}>
									TXN-{invoiceData.refTransaction.transactionNo}
								</Text>
							</View>
							<View style={styles.invoiceInfoFieldContainer}>
								<Text style={styles.invoiceInfoLabel}>Invoice Date:</Text>
								<Text style={styles.invoiceInfoText}>
									{moment(invoiceData.invoiceDate).format('DD/MM/YYYY')}
								</Text>
							</View>
							<View style={styles.invoiceInfoFieldContainer}>
								<Text style={styles.invoiceInfoLabel}>Payment Due:</Text>
								<Text style={styles.invoiceInfoText}>
									{/* {moment(invoiceData.dueDate).format('DD/MM/YYYY')} */}
									{moment(
										getDueDate(invoiceData.invoiceDate, settings.paymentDue)
									).format('DD/MM/YYYY')}
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.tableContainer}>
						<View
							style={[styles.tableHeader, { backgroundColor: settings.color }]}
						>
							<Text style={styles.policyNo}>Policy Number</Text>
							<Text style={styles.description}>Description</Text>
							<Text style={styles.premium}>Premium</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={styles.policyNo}>
								{invoiceData.refTransaction.transactionId.policyData.policyNo}
							</Text>
							<View style={styles.descriptionContainer}>
								<Text style={styles.insuranceType}>
									{invoiceData.refTransaction.transactionId.insuranceType}
								</Text>
								<View style={styles.descriptionFieldContainer}>
									<Text style={styles.descriptionLabel}>Insured By:</Text>
									<Text style={styles.descriptionText}>
										{
											invoiceData.refTransaction.transactionId.policyData
												.principalName
										}
									</Text>
								</View>
								<View style={styles.descriptionFieldContainer}>
									<Text style={styles.descriptionLabel}>
										Period of Insurance:
									</Text>
									<Text style={styles.descriptionText}>
										{moment(
											invoiceData.refTransaction.transactionId.policyData
												.startDate
										).format('DD/MM/YYYY')}{' '}
										to{' '}
										{moment(
											invoiceData.refTransaction.transactionId.policyData
												.endDate
										).format('DD/MM/YYYY')}
									</Text>
								</View>
								<View style={styles.descriptionFieldContainer}>
									<Text style={styles.descriptionLabel}>Sum Insured:</Text>
									<Text style={styles.descriptionText}>
										{Number.parseFloat(
											invoiceData.refTransaction.transactionId.policyData
												.sumInsured
										) === 0
											? 'Refer to Policy Document'
											: Number.parseFloat(
													invoiceData.refTransaction.transactionId.policyData
														.sumInsured
											  ).toFixed(2)}
									</Text>
								</View>
								<View style={styles.descriptionFieldContainer}>
									<Text style={styles.descriptionLabel}>Remarks:</Text>
									<Text style={styles.descriptionText}>
										{
											invoiceData.refTransaction.transactionId.policyData
												.remarks
										}
									</Text>
								</View>
							</View>
							<Text style={styles.premium}>
								{invoiceData.refTransaction.transactionId.policyData.premiumAmt.toFixed(
									2
								)}
							</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={styles.totalLabel}>Total Premium (SGD)</Text>
							<Text style={styles.totalText}>
								{Number.parseFloat(
									invoiceData.refTransaction.transactionId.policyData.premiumAmt
								).toFixed(2)}
							</Text>
						</View>
					</View>
					<View
						style={[
							styles.paymentMethodHeader,
							{ backgroundColor: settings.color },
						]}
					>
						<Text style={styles.description}>Payment Method</Text>
					</View>
					<View style={styles.footerContainer}>
						<Text style={styles.paymentMethodHeaderText}>
							Transfer the amount due to any of the following payment methods
							listed below:
						</Text>
						<Text style={styles.paymentMethod}>
							Bank Transfer / Internet Banking
						</Text>
						<View style={styles.paymentMethodInfoContainer}>
							<Text style={styles.paymentMethodInfoLabel}>Bank:</Text>
							<Text style={styles.paymentMethodInfoText}>{settings.bank}</Text>
						</View>
						<View style={styles.paymentMethodInfoContainer}>
							<Text style={styles.paymentMethodInfoLabel}>
								Beneficiary Name:
							</Text>
							<Text style={styles.paymentMethodInfoText}>
								{settings.beneficiaryName}
							</Text>
						</View>
						<View style={styles.paymentMethodInfoContainer}>
							<Text style={styles.paymentMethodInfoLabel}>Account Number:</Text>
							<Text style={styles.paymentMethodInfoText}>
								{settings.accountNo}
							</Text>
						</View>
						{settings.paynowEnabled && (
							<>
								<Text style={styles.paymentMethod}>PayNow</Text>
								<View style={styles.paymentMethodInfoContainer}>
									<Text style={styles.paymentMethodInfoLabel}>UEN:</Text>
									<Text style={styles.paymentMethodInfoText}>
										{settings.paynowId}
									</Text>
								</View>
								{(settings.qrImage || settings.previewImage) && (
									<View style={styles.paymentMethodInfoContainer}>
										<Text style={styles.paymentMethodInfoLabel}>QR:</Text>
										<Image
											style={styles.qrImage}
											src={
												settings.qrImage !== null
													? `data:image/*;base64,${settings.qrImage}`
													: settings.previewImage
											}
										/>
									</View>
								)}
							</>
						)}
						<Text style={styles.terms}>Terms & Conditions / Notes</Text>
						<Text style={styles.termsText}>{settings.terms}</Text>
					</View>
				</Page>
			</Document>
		</PDFViewer>
	);
};

export default InvoiceSample;
