import Backdrop from '../../../../../../../components/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { differenceInSeconds } from 'date-fns';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import matchSorter from 'match-sorter';
import { toast } from 'react-toastify';
import Joi from 'joi';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import api from '../../../../../../../config/api';
import { AuthUserContext } from '../../../../../../../components/Session';
import ContextMenu from './ContextMenu';
import TransactionModal from '../../../../../../../components/Modals/transactions';
import { getDiff } from '../../../../../../../utils/formHelper';

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const useStyles = makeStyles((theme) => ({
	statusBar: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px 24px',
	},
}));

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

export default function QuoteSelectionRequestItem(props) {
	const { request, quoteRequestList, setTriggerUpdate, disableEdit } = props;
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

	const [currentData, setCurrentData] = useState(cloneDeep(request));
	const [updatedData, setUpdatedData] = useState(cloneDeep(request));
	const [editable, setEditable] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);
	const [modalData, setModalData] = useState(null);

	const initializeData = () => {
		let initData = cloneDeep(request);
		setCurrentData(cloneDeep(initData));
		setUpdatedData(cloneDeep(initData));
	};

	useEffect(() => {
		initializeData();
	}, []);

	const handleInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;

		const keys = name.split('.');
		const updateValue =
			type === 'number' && value !== '' ? parseFloat(value) : value;

		const updateNestedData = (data, keyIndex = 0) => {
			if (keyIndex === keys.length - 1) {
				return { ...data, [keys[keyIndex]]: updateValue };
			} else {
				return {
					...data,
					[keys[keyIndex]]: updateNestedData(
						data[keys[keyIndex]],
						keyIndex + 1
					),
				};
			}
		};

		setUpdatedData((prev) => updateNestedData(prev));
	};

	const handleEditable = () => {
		setExpanded(true);
		setEditable(true);
	};

	const toggleExpanded = () => {
		if (!expanded) {
			setExpanded(true);
		}
		setExpanded(!expanded);
	};

	const getTextColor = (status) => {
		if (status === 'Responded') return '#40BE79';
		if (status === 'Expired' || status === 'Email failed') return '#F16063';
		if (status === 'Pending Response') return '#A8701D';
		return '';
	};

	const getStatusBarColor = (status) => {
		if (status === 'Responded') return 'rgba(102, 203, 148, 0.2)';
		if (status === 'Expired' || status === 'Email failed') return '#FCE9E9';
		if (status === 'Pending Response') return '#F7C16F';
		return '';
	};

	const getStatusText = (status) => {
		if (status === 'Responded')
			return 'Client has confirmed on a Quote. You may proceed to enter policy information after processing the insurance.';
		if (status === 'Expired') return 'Expired';
		if (status === 'Email failed') return 'Email failed';
		if (status === 'Pending Response')
			return 'Pending Client confirmation on a quote';
		return '';
	};

	const handleValidation = () => {
		let errorData = {};
		let dataToValidate = {};
		let schemaObject = {};
		let result = {};
		let isValid = true;

		dataToValidate['clientRequest'] = {
			requestSubject: updatedData.requestSubject,
			requestMessage: updatedData.requestMessage,
		};
		schemaObject['clientRequest'] = Joi.object({
			requestSubject: Joi.string()
				.label('Subject')
				.trim()
				.min(1)
				.max(250)
				.required(),
			requestMessage: Joi.string()
				.label('Message')
				.trim()
				.min(1)
				.max(1000)
				.required(),
		}).unknown(true);

		let schema = Joi.object(schemaObject);
		result = schema.validate(dataToValidate, {
			abortEarly: false,
			convert: false,
		});

		if (result.error === undefined && isValid) {
			setErrorMessages({});
			return true;
		} else {
			if (result.error) {
				console.log(result.error.details);
				for (let item of result.error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			console.log(errorData);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = () => {
		if (!loading) {
			console.log('handleSave');
			setLoading(true);

			let changedData = getDiff(updatedData, currentData);
			console.log(changedData);

			if (!changedData) {
				setErrorMessages({});
				setEditable(false);
				setLoading(false);
			} else {
				let valid = handleValidation();
				if (valid) {
					try {
						let data = {
							updatedFields: {
								requestSubject: updatedData.requestSubject,
								requestMessage: updatedData.requestMessage,
							},
							auditLog: changedData,
						};

						console.log(data);

						api
							.put(
								'client-requests/quote-selection-request/' + request._id,
								data
							)
							.then((res) => {
								if (res.data.ok) {
									setErrorMessages({});
									setEditable(false);
									setTriggerUpdate(true);
									toast.success('Quote Selection Request updated!');
								} else {
									toast.error(res.data.error);
								}
							});
					} catch (error) {
						console.log(error);
						toast.error(error);
					} finally {
						setLoading(false);
					}
				} else {
					toast.error('An error has occured!');
					setLoading(false);
				}
			}
		}
	};

	const handleCancel = () => {
		initializeData();
		setEditable(false);
	};

	const handleModalOpen = (body) => {
		switch (body) {
			case 'updateRecommendations':
				setModalData(quoteRequestList);
				break;
			case 'resendClientRequest':
				setModalData(request);
				break;
			default:
				setModalData(request);
				break;
		}

		setModalType(body);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalData(null);
		setModalType(null);
	};

	return (
		<AuthUserContext.Consumer>
			{(credentials) => (
				<>
					<Backdrop text='Saving Data...' open={loading} />
					<div>
						<div
							className={classes.statusBar}
							style={{
								backgroundColor:
									differenceInSeconds(
										new Date(),
										new Date(updatedData.requestExpiryDate)
									) > 0 && updatedData.status === 'Pending Response'
										? getStatusBarColor('Expired')
										: getStatusBarColor(updatedData.status),
							}}
						>
							<Typography
								variant='body2'
								style={{
									color:
										differenceInSeconds(
											new Date(),
											new Date(updatedData.requestExpiryDate)
										) > 0 && updatedData.status === 'Pending Response'
											? getTextColor('Expired')
											: getTextColor(updatedData.status),
								}}
							>
								Status:{' '}
								{differenceInSeconds(
									new Date(),
									new Date(updatedData.requestExpiryDate)
								) > 0 && updatedData.status === 'Pending Response'
									? 'Expired'
									: getStatusText(updatedData.status)}
							</Typography>
						</div>
						<Grid
							container
							direction='row'
							alignItems='stretch'
							justifyContent='space-between'
							style={{ padding: 24 }}
							spacing={2}
						>
							<Grid
								item
								container
								direction='row'
								alignItems='center'
								spacing={2}
								xs={7}
							>
								<Grid item xs={12} md={6} lg={3}>
									<Typography color='textPrimary' variant='body2'>
										Sent Date
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										{moment(updatedData.requestDate).format('DD/MM/YYYY')}
									</Typography>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<Typography color='textPrimary' variant='body2'>
										Link Expiry Date
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										{moment(updatedData.requestExpiryDate).format('DD/MM/YYYY')}
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={5}>
								<Grid
									container
									direction='row'
									alignItems='center'
									justifyContent='flex-end'
									spacing={1}
								>
									{editable ? (
										<>
											<Button
												onClick={() => handleCancel()}
												variant='outlined'
												color='primary'
												className={classes.button}
											>
												Cancel
											</Button>
											<Button
												onClick={() => handleSave()}
												variant='contained'
												color='primary'
												className={classes.button}
												style={{ marginLeft: 8 }}
											>
												Save
											</Button>
										</>
									) : (
										<>
											{!disableEdit && (
												<ContextMenu
													setEditable={handleEditable}
													handleModalOpen={handleModalOpen}
												/>
											)}
										</>
									)}
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Collapse in={expanded}>
									<Grid
										container
										direction='row'
										alignItems='center'
										spacing={2}
									>
										<Grid item xs={10}>
											<div style={{ display: 'flex' }}>
												<Typography
													style={{ width: 48, textAlign: 'end' }}
													color='textPrimary'
													variant='body2'
												>
													From
												</Typography>
												<Typography color='textPrimary' variant='body2'>
													:
												</Typography>
												<Typography
													style={{ margin: '0px 8px' }}
													color='textPrimary'
													variant='body1'
												>
													{`${credentials.userProfile.profile.firstName} ${credentials.userProfile.profile.lastName}`}
												</Typography>
												<Typography color='textSecondary' variant='body1'>
													{`(mail-service@mg.uuoni.com)`}
												</Typography>
											</div>
										</Grid>
										<Grid item xs={10}>
											<div style={{ display: 'flex' }}>
												<Typography
													style={{ width: 48, textAlign: 'end' }}
													color='textPrimary'
													variant='body2'
												>
													To
												</Typography>
												<Typography color='textPrimary' variant='body2'>
													:
												</Typography>
												<Typography
													style={{ margin: '0px 8px' }}
													color='textPrimary'
													variant='body1'
												>
													{updatedData.recipientName}
												</Typography>
												<Typography color='textSecondary' variant='body1'>
													{`(${updatedData.recipientEmail})`}
												</Typography>
											</div>
										</Grid>
										<Grid item xs={10}>
											<Typography color='textPrimary' variant='body2'>
												Subject
											</Typography>
											{editable ? (
												<TextField
													name='requestSubject'
													value={updatedData.requestSubject || ''}
													onChange={(e) => handleInputChange(e)}
													InputProps={{
														style: { color: 'unset' },
													}}
													variant={'filled'}
													fullWidth
													margin='dense'
													error={
														!!errorMessages[`clientRequest.requestSubject`]
													}
													helperText={
														errorMessages[`clientRequest.requestSubject`] &&
														errorMessages[`clientRequest.requestSubject`]
													}
												/>
											) : (
												<Typography
													color='textPrimary'
													variant='body1'
													align='left'
													style={{ marginTop: 8 }}
												>
													{updatedData.requestSubject}
												</Typography>
											)}
										</Grid>
										<Grid item xs={10}>
											<Typography color='textPrimary' variant='body2'>
												Message
											</Typography>
											{editable ? (
												<TextField
													name='requestMessage'
													value={updatedData.requestMessage || ''}
													multiline
													InputProps={{
														style: { color: 'unset' },
													}}
													onChange={(e) => handleInputChange(e)}
													variant='filled'
													fullWidth
													margin='dense'
													minRows={4}
													error={
														!!errorMessages[`clientRequest.requestMessage`]
													}
													helperText={
														errorMessages[`clientRequest.requestMessage`] &&
														errorMessages[`clientRequest.requestMessage`]
													}
												/>
											) : (
												<Typography
													color='textPrimary'
													variant='body1'
													align='left'
													style={{ marginTop: 8, whiteSpace: 'pre-line' }}
												>
													{updatedData.requestMessage}
												</Typography>
											)}
										</Grid>
									</Grid>
								</Collapse>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										cursor: 'pointer',
										width: 'fit-content',
										marginTop: expanded && 8,
									}}
									onClick={toggleExpanded}
								>
									<Typography
										style={{
											color: theme.palette.tertiary.main,
											marginRight: 4,
										}}
										variant='subtitle1'
									>
										{expanded ? 'View Less' : 'View More'}
									</Typography>
								</div>
							</Grid>
						</Grid>
					</div>
					{modalOpen && (
						<TransactionModal
							open={modalOpen}
							handleClose={handleModalClose}
							body={modalType}
							transactionId={request.transactionId}
							data={modalData}
							setTriggerUpdate={setTriggerUpdate}
							size={modalType === 'updateRecommendations' ? 'xl' : 'sm'}
						/>
					)}
				</>
			)}
		</AuthUserContext.Consumer>
	);
}
