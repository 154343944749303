import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import FileInfoCard from '../../../../components/FileInfoCard';
import InvoiceModal from '../../../../components/Modals/invoices';
import { Summary } from '../../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'inline-block',
		height: '100%',
		backgroundColor: 'unset',
		width: '100%',
		borderRadius: 8,
		boxShadow: '0px 4px 12px rgb(0 0 0 / 16%), 0px 0px 4px rgb(0 0 0 / 16%)',
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
}));

function isEmptyObj(obj) {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

export default function SummaryViewer(props) {
	const { invoice, setTriggerUpdate } = props;
	const classes = useStyles();
	const theme = useTheme();

	const [data, setData] = useState(invoice);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);
	const [modalData, setModalData] = useState(null);

	const handleModalOpen = (body, obj) => {
		setModalType(body);
		setModalData(obj);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalType(null);
		setModalData(null);
	};

	const getStatus = (invoice) => {
		switch (invoice.status) {
			case 'Paid':
				return (
					<div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
						<span
							style={{
								marginRight: 8,
								height: 12,
								width: 12,
								backgroundColor: '#7fa572',
								borderRadius: '50%',
								display: 'inline-block',
							}}
						></span>
						<Typography color='textPrimary' variant='body1' align='left'>
							Paid
						</Typography>
					</div>
				);
			case 'Pending': {
				if (new Date(invoice.dueDate) < new Date()) {
					return (
						<div
							style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
						>
							<span
								style={{
									marginRight: 8,
									height: 12,
									width: 12,
									backgroundColor: '#f00606',
									borderRadius: '50%',
									display: 'inline-block',
								}}
							></span>
							<Typography color='textPrimary' variant='body1' align='left'>
								Overdue
							</Typography>
						</div>
					);
				} else {
					return (
						<div
							style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
						>
							<span
								style={{
									marginRight: 8,
									height: 12,
									width: 12,
									backgroundColor: '#f09106',
									borderRadius: '50%',
									display: 'inline-block',
								}}
							></span>
							<Typography color='textPrimary' variant='body1' align='left'>
								Pending
							</Typography>
						</div>
					);
				}
			}
		}
	};

	return (
		<Card className={classes.content}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					padding: 24,
					height: 58,
				}}
			>
				<Summary style={{ marginRight: 8, fontSize: 24 }} />
				<Typography color='textPrimary' variant='h4' align='left'>
					SUMMARY
				</Typography>
				{data.status === 'Pending' && (
					<Button
						style={{ marginLeft: 'auto' }}
						variant='contained'
						color='primary'
						onClick={() =>
							handleModalOpen('recordPayment', data.policyData.premiumAmt)
						}
					>
						Record Payment
					</Button>
				)}
			</div>
			<Divider />
			<div style={{ padding: 16 }}>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					{data.transactionNo && (
						<Grid item xs={12}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Reference Transaction Number
							</Typography>
							<Link
								style={{ marginTop: 8 }}
								component={RouterLink}
								color='primary'
								to={'/transactions/' + data.transactionId}
							>
								TXN-{data.transactionNo}
							</Link>
						</Grid>
					)}
					<Grid item xs={12}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Payment Status
						</Typography>
						{getStatus(data)}
					</Grid>
					{data.status === 'Paid' && (
						<>
							<Grid item xs={12}>
								<Typography color='textPrimary' variant='body2' align='left'>
									Payment Date
								</Typography>
								<Typography
									color='textPrimary'
									variant='body1'
									align='left'
									style={{ marginTop: 8 }}
								>
									{moment(data.paymentDate).format('DD/MM/YYYY')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography color='textPrimary' variant='body2' align='left'>
									Payment Method
								</Typography>
								<Typography
									color='textPrimary'
									variant='body1'
									align='left'
									style={{ marginTop: 8 }}
								>
									{data.paymentMethod}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography color='textPrimary' variant='body2' align='left'>
									Payment Notes
								</Typography>
								<Typography
									color='textPrimary'
									variant='body1'
									align='left'
									style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
								>
									{data.paymentNotes}
								</Typography>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Amount Due
						</Typography>
						<Typography
							color='textPrimary'
							variant='body1'
							align='left'
							style={{ marginTop: 8 }}
						>
							{currencyFormatter.format(data.policyData.premiumAmt)}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Due On
						</Typography>
						<Typography
							color='textPrimary'
							variant='body1'
							align='left'
							style={{ marginTop: 8 }}
						>
							{moment(data.dueDate).format('DD/MM/YYYY')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Client
						</Typography>
						<div
							style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}
						>
							<Link
								style={{
									display: 'inline-block',
								}}
								component={RouterLink}
								color='primary'
								to={'/contacts/' + data.clientData.contact._id}
							>
								{data.clientData.contactName}
							</Link>
							{data.clientData.company && (
								<Link
									style={{
										display: 'inline-block',
									}}
									component={RouterLink}
									color='primary'
									to={'/companies/' + data.clientData.company._id}
								>
									{data.clientData.companyName}
								</Link>
							)}
						</div>
					</Grid>
					<Grid item xs={12}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Class of Insurance
						</Typography>
						<Typography
							color='textPrimary'
							variant='body1'
							align='left'
							style={{ marginTop: 8 }}
						>
							{data.insuranceType}
							{data.insuranceSubType && <> ({data.insuranceSubType})</>}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Notes
						</Typography>
						<Typography
							color='textPrimary'
							variant='body1'
							align='left'
							style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
						>
							{data.policyData.remarks}
						</Typography>
					</Grid>
					{data.paymentDocument && (
						<Grid item xs={12}>
							<Typography
								color='textPrimary'
								variant='body2'
								align='left'
								style={{ marginBottom: 8 }}
							>
								Payment Document
							</Typography>
							<FileInfoCard file={data.paymentDocument} />
						</Grid>
					)}
				</Grid>
			</div>
			{modalOpen && (
				<InvoiceModal
					open={modalOpen}
					handleClose={handleModalClose}
					body={modalType}
					invoiceId={invoice._id}
					data={modalData}
					setTriggerUpdate={setTriggerUpdate}
				/>
			)}
		</Card>
	);
}
