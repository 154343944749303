import AppBar from '@material-ui/core/AppBar';
import Backdrop from '../../../components/Backdrop';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tooltip from '../../../components/Tooltip';
import FileUploader from '../../../components/FileUploader';
import SuccessMessage from '../../../components/SuccessMessage';
import api from '../../../config/apiPublic';
import useStyles from '../styles';
import Loader from '../../../components/Loader';
import { ClaimRequest as ClaimRequestSchema } from '../../../utils/validators/schemas';

const claimRejReasonList = ['Not applicable for claim', 'Other'];

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

const initFormData = {
	accepted: true,
	claimAmt: null,
	insurerNotes: '',
	rejReason: claimRejReasonList[0],
	rejRemarks: '',
};

export default function ClaimRequest(props) {
	const { id } = useParams();
	const classes = useStyles();
	const theme = useTheme();
	const [claimRequest, setClaimRequest] = useState(null);
	const [formData, setFormData] = useState(initFormData);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(true);

	const getData = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get(`external/requests/claimRequest/${id}`)
			.then((res) => {
				setClaimRequest(res.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				setLoading(false);
				console.log(error);
			});
	};

	useEffect(() => {
		getData();
	}, []);

	const handleDecline = (e) => {
		setFormData((prev) => ({
			...prev,
			accepted: false,
		}));
	};

	const handleCancel = (e) => {
		setFormData((prev) => ({
			...prev,
			accepted: true,
		}));
	};

	const handleInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;

		setFormData((prev) => ({
			...prev,
			[name]: type === 'number' && value !== '' ? parseFloat(value) : value,
		}));
	};

	const handleFileChange = (e, files) => {
		e.preventDefault();
		setFormData((prev) => ({
			...prev,
			claimDocument: files,
		}));
	};

	const handleValidation = () => {
		let hasFile = formData.accepted ? !!formData.claimDocument : true;
		let formattedFormData;

		if (formData.accepted) {
			formattedFormData = {
				accepted: formData.accepted,
				claimAmt: formData.claimAmt,
				insurerNotes: formData.insurerNotes,
			};
		} else {
			formattedFormData = {
				accepted: formData.accepted,
				rejReason: formData.rejReason,
				rejRemarks: formData.rejRemarks,
			};
		}

		let result = ClaimRequestSchema.validate(formattedFormData, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		console.log(result);
		console.log(error);
		console.log(!error && hasFile);
		if (!error && hasFile) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			if (error?.details !== undefined) {
				for (let item of error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			if (!hasFile) {
				errorData['claimDocument'] = 'Claim Document is required';
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (open) return;
		setOpen(true);

		let valid = handleValidation();

		if (valid) {
			const data = new FormData();
			let json = cloneDeep(formData);

			if (formData.accepted) {
				data.append(
					'Claim Document',
					formData.claimDocument[0],
					formData.claimDocument[0].name
				);
				delete json.claimDocument;
			}

			console.log(JSON.stringify(json, null, 4));
			data.append('json', JSON.stringify(json));
			try {
				api.put(`external/requests/claimRequest/${id}`, data).then((res) => {
					if (res.data.ok) {
						setErrorMessages({});
						setSuccess(true);
						setOpen(false);
					} else {
						setSuccess(false);
						toast.error(res.data.error);
						setOpen(false);
					}
				});
			} catch (error) {
				console.log(error);
				setSuccess(false);
				toast.error(error);
				setOpen(false);
			}
		} else {
			toast.error('An error has occured!');
			setOpen(false);
		}
	};

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	const getHeader = () => {
		return (
			<>
				Claim Request - {claimRequest.insuranceType}
				{claimRequest.insuranceSubType && (
					<> ({claimRequest.insuranceSubType})</>
				)}{' '}
				- {claimRequest.client.contactName}
			</>
		);
	};

	if (loading) return <Loader size='lg' />;
	if (!loading && !claimRequest)
		return (
			<>
				<AppBar position='fixed' className={classes.appBar}>
					<Toolbar className={classes.toolBar} disableGutters>
						<div className='brandContainer'>
							<Button
								target='_blank'
								href='https://www.uuoni.com'
								style={{ padding: 0, backgroundColor: 'transparent' }}
								disableRipple
								disableFocusRipple
								disableElevation
							>
								<img
									className='brand-logo'
									src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
									alt=''
								/>
							</Button>
						</div>
					</Toolbar>
				</AppBar>
				<SuccessMessage type={'request-not-found'} />
			</>
		);
	return (
		<>
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar className={classes.toolBar} disableGutters>
					<div className='brandContainer'>
						<Button
							target='_blank'
							href='https://www.uuoni.com'
							style={{ padding: 0, backgroundColor: 'transparent' }}
							disableRipple
							disableFocusRipple
							disableElevation
						>
							<img
								className='brand-logo'
								src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
								alt=''
							/>
						</Button>
					</div>
				</Toolbar>
			</AppBar>
			{claimRequest && claimRequest.status !== 'Pending Response' ? (
				<SuccessMessage type={'invalid-request'} />
			) : (
				<>
					{success ? (
						<SuccessMessage type={'request-responded'} />
					) : (
						<div className={classes.content}>
							<Backdrop text='Processing Information...' open={open} />
							<div className='header' style={{ marginBottom: 32 }}>
								<Typography variant='h1' color='textPrimary'>
									{getHeader()}
								</Typography>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginTop: 16,
									}}
								>
									<Typography
										style={{ textTransform: 'uppercase' }}
										variant='h5'
										color='textPrimary'
									>
										Requested by{' '}
										{`${claimRequest.agentInfo.firstName} ${claimRequest.agentInfo.lastName}`}
									</Typography>
									<div style={{ marginLeft: 16 }}>
										<IconButton
											component={Link}
											target='_blank'
											href={`tel:${claimRequest.agentInfo.mobileNo}`}
											size='small'
										>
											<WhatsAppIcon />
										</IconButton>
										<IconButton
											component={Link}
											href={`mailto:${claimRequest.agentInfo.email}`}
											size='small'
										>
											<MailOutlineIcon />
										</IconButton>
									</div>
								</div>
								<div style={{ display: 'flex', margin: '24px 0px' }}>
									<Typography color='textPrimary' variant='body1'>
										Please check files uploaded and the information filled. All
										mandatory fields are marked in red asterisk (
									</Typography>
									<Typography color='error' variant='body1'>
										*
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										)
									</Typography>
								</div>
							</div>
							{formData.accepted && (
								<>
									<div className='body' style={{ marginBottom: 32 }}>
										<Typography
											style={{ textTransform: 'uppercase' }}
											variant='h3'
											color='textPrimary'
										>
											Submitted Documents
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
										<div style={{ marginBottom: 24 }}>
											{claimRequest &&
												claimRequest.supportingDocuments?.map((doc, index) => (
													<Chip
														key={index}
														icon={
															<div>
																<FileIcon
																	labelUppercase
																	extension={fileType(doc.displayName)}
																	{...defaultStyles[fileType(doc.displayName)]}
																/>
															</div>
														}
														label={`${doc.documentName} (${doc.displayName})`}
														className={classes.chip}
														onClick={() => window.open(doc.url, '_blank')}
														variant='outlined'
														color='primary'
													/>
												))}
										</div>
										<Typography
											style={{ textTransform: 'uppercase' }}
											variant='h3'
											color='textPrimary'
										>
											Claim Details
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={6} md={3}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Total Claimable Amount
												</Typography>
												<TextField
													name='claimAmt'
													value={formData.claimAmt ?? ''}
													type='number'
													onChange={(e) => handleInputChange(e)}
													placeholder='Enter Claim Amount'
													variant='filled'
													fullWidth
													margin='dense'
													InputProps={{
														startAdornment: (
															<InputAdornment
																style={{ marginTop: 0 }}
																position='start'
															>
																<AttachMoneyOutlinedIcon />
															</InputAdornment>
														),
													}}
													error={!!errorMessages[`claimAmt`]}
													helperText={
														errorMessages[`claimAmt`] &&
														errorMessages[`claimAmt`]
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} md={6}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Notes
												</Typography>
												<TextField
													name='insurerNotes'
													value={formData.insurerNotes}
													onChange={(e) => handleInputChange(e)}
													placeholder='Enter Notes'
													variant='filled'
													fullWidth
													margin='dense'
													multiline
													minRows={4}
													error={!!errorMessages[`insurerNotes`]}
													helperText={
														errorMessages[`insurerNotes`] &&
														errorMessages[`insurerNotes`]
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} md={6}>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Typography
														style={{ marginRight: 8 }}
														color='textPrimary'
														variant='body2'
														align='left'
													>
														Upload Claim Documents
													</Typography>
													<Tooltip content={printReqString()} />
												</div>
												<div
													style={{
														width: '100%',
														marginTop: 8,
														marginBottom: 4,
													}}
												>
													<FileUploader
														fileLimit={1}
														files={formData.claimDocument}
														handleFileChange={handleFileChange}
														formErrorMessage={
															errorMessages[`claimDocument`] &&
															errorMessages[`claimDocument`]
														}
													/>
												</div>
											</Grid>
										</Grid>
									</div>
									<div
										className='form-footer'
										style={{ display: 'flex', alignItems: 'center' }}
									>
										<Button
											className={classes.buttonAccept}
											variant='contained'
											disabled={loading || open}
											onClick={handleSubmit}
										>
											Acknowledge
										</Button>
										<Button
											className={classes.buttonReject}
											style={{ marginLeft: 16 }}
											variant='contained'
											disabled={loading || open}
											onClick={handleDecline}
										>
											Decline
										</Button>
										{/* <Button
											style={{ marginLeft: 16 }}
											variant='outlined'
											color='primary'
											disabled={loading || open}
											onClick={handleDecline}
										>
											Exit Session
										</Button> */}
									</div>
								</>
							)}
							{!formData.accepted && (
								<>
									<div className='body' style={{ marginBottom: 32 }}>
										<Typography
											style={{ textTransform: 'uppercase' }}
											variant='h3'
											color='textPrimary'
										>
											Decline Request
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
										<Grid container spacing={4}>
											<Grid item xs={12} sm={6} md={3}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Please select a reason for declining this request
												</Typography>
												<FormControl style={{ width: '100%' }}>
													<Select
														name='rejReason'
														value={formData.rejReason}
														onChange={(e) => handleInputChange(e)}
														variant='filled'
														fullWidth
														autoWidth={true}
														margin='dense'
														style={{ marginTop: 8, marginBottom: 4 }}
													>
														{claimRejReasonList.map((val, index) => {
															return (
																<MenuItem key={index} value={val}>
																	{val}
																</MenuItem>
															);
														})}
													</Select>
													<FormHelperText>
														{errorMessages[`rejReason`] &&
															errorMessages[`rejReason`]}
													</FormHelperText>
												</FormControl>
											</Grid>
										</Grid>
										<Grid container spacing={4}>
											<Grid item xs={12} md={6}>
												<Typography
													color='textPrimary'
													variant='body2'
													align='left'
												>
													Remarks
												</Typography>
												<TextField
													name='rejRemarks'
													value={formData.rejRemarks}
													onChange={(e) => handleInputChange(e)}
													placeholder='Enter Remarks'
													variant='filled'
													fullWidth
													margin='dense'
													multiline
													minRows={4}
													error={!!errorMessages[`rejRemarks`]}
													helperText={
														errorMessages[`rejRemarks`] &&
														errorMessages[`rejRemarks`]
													}
												/>
											</Grid>
										</Grid>
									</div>
									<div
										className='form-footer'
										style={{ display: 'flex', alignItems: 'center' }}
									>
										<Button
											className={classes.buttonReject}
											variant='contained'
											disabled={loading || open}
											onClick={handleSubmit}
										>
											Decline
										</Button>
										<Button
											style={{ marginLeft: 16 }}
											variant='outlined'
											color='primary'
											disabled={loading || open}
											onClick={handleCancel}
										>
											Cancel
										</Button>
									</div>
								</>
							)}
						</div>
					)}
				</>
			)}
		</>
	);
}
