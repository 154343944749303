import Joi from 'joi';

const Invoice = Joi.object({
	dueDate: Joi.date().label('Due Date').iso().min('now').required(),
	address: Joi.string().label('Address').trim().min(1).max(100).required(),
	unitNo: Joi.string().label('Unit Number').trim().min(1).max(50).required(),
	postalCode: Joi.string()
		.label('Postal Code')
		.trim()
		.min(1)
		.max(50)
		.required(),
	mobileNo: Joi.string()
		.label('Mobile Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Mobile Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	officeNo: Joi.string()
		.label('Office Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Office Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	terms: Joi.string()
		.label('Terms')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default Invoice;
