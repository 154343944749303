import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import api from '../../config/api';

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

function getTimeDifference(date) {
	let difference =
		moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(
			moment(date, 'DD/MM/YYYY HH:mm:ss')
		) / 1000;

	if (difference < 60) return `${Math.floor(difference)} seconds ago`;
	else if (difference < 3599)
		return `${Math.floor(difference / 60)} minutes ago`;
	else if (difference < 86399)
		return `${Math.floor(difference / 3660)} hour(s) ago`;
	else if (difference < 86400 * 30)
		return `${Math.floor(difference / 86400)} day(s) ago`;
	else if (difference < 86400 * 30 * 12)
		return `${Math.floor(difference / 86400 / 30)} month(s) ago`;
	else return `${moment(date).format('DD/MM/YYYY')}`;
}

const useStyles = makeStyles((theme) => ({
	tabs: {
		height: 58,
		minWidth: 10,
	},
	notificationList: {
		maxHeight: '85%',
		overflowX: 'hidden',
		overflowY: 'scroll',
		display: 'flex',
		flexDirection: 'column',
	},
}));

const Notifications = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const [notificationList, setNotificationList] = useState([]);
	const [renderList, setRenderList] = useState([]);
	const [value, setValue] = useState(0);
	const [triggerUpdate, setTriggerUpdate] = useState(true);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (triggerUpdate) {
			getNotifications();
		}
	}, [triggerUpdate]);

	useEffect(() => {
		if (value === 1) {
			let list = notificationList.filter((notification) => !notification.seen);
			setRenderList(list);
		} else {
			setRenderList(notificationList);
		}
	}, [value]);

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};

	const handleListItemClick = (id) => {
		if (process.env.REACT_APP_ENVIRONMENT !== 'demo') {
			updateNotifications(id);
		}
	};

	const getNotifications = async () => {
		try {
			if (!loading) {
				setLoading(true);
			}
			const response = await api.get('notifications/all');
			setNotificationList(response.data);
			setRenderList(response.data);
			setTriggerUpdate(false);
			setLoading(false);
		} catch (error) {
			console.log('error', error);
		}
	};

	const updateNotifications = async (id) => {
		try {
			await api.put('notifications/seen/' + id);
			setTriggerUpdate(true);
		} catch (error) {
			console.log('error', error);
		}
	};

	const markAllAsRead = async () => {
		try {
			await api.post('notifications/seen-all');
			setTriggerUpdate(true);
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<>
			<Typography variant='h2' style={{ marginBottom: 24 }}>
				Notifications
			</Typography>
			<Button
				style={{ marginBottom: 8, fontSize: '1rem' }}
				variant='text'
				color='primary'
				className={classes.button}
				startIcon={<CheckIcon style={{ fontSize: 24 }} />}
				onClick={() => markAllAsRead()}
			>
				Mark all as read
			</Button>
			<Tabs
				value={value}
				onChange={handleChangeTab}
				aria-label='full width tabs example'
			>
				<Tab className={classes.tabs} label='ALL' {...a11yProps(0)} />
				<Tab
					className={classes.tabs}
					label={
						notificationList.length > 0
							? `UNREAD (${
									notificationList.filter((notification) => !notification.seen)
										.length
							  })`
							: 'UNREAD'
					}
					{...a11yProps(1)}
				/>
			</Tabs>
			<Divider style={{ margin: '0px -24px' }} />
			<div
				className={classes.notificationList}
				style={{ width: isDesktopOrLaptop && '60%' }}
			>
				{loading ? (
					<>
						{[...Array(10)].map((_, i) => (
							<ListItem
								key={i}
								style={{
									textDecoration: 'none',
									height: 80,
									alignItems: 'center',
								}}
								alignItems='flex-start'
							>
								<ListItemText
									primary={<Skeleton variant='rect' animation='wave' />}
									secondary={<Skeleton variant='rect' animation='wave' />}
								/>
							</ListItem>
						))}
					</>
				) : renderList.length ? (
					renderList.map((notification, index) => (
						<React.Fragment key={index}>
							<ListItem
								key={index}
								button
								component={Link}
								to={notification.targetUrl}
								style={{
									textDecoration: 'none',
									alignItems: 'center',
								}}
								alignItems='flex-start'
								onClick={() => handleListItemClick(notification._id)}
							>
								<ListItemText
									primary={
										<Typography variant='body1' color='textPrimary'>
											{notification.content}
										</Typography>
									}
									secondary={
										<Typography
											variant='subtitle1'
											style={{
												marginTop: 8,
												fontWeight: 500,
												color: theme.palette.tertiary.main,
											}}
										>
											{getTimeDifference(new Date(notification.createdAt))}
										</Typography>
									}
								/>
								{!notification.seen && (
									<ListItemIcon
										style={{ marginTop: 0, color: theme.palette.tertiary.main }}
									>
										<FiberManualRecordIcon />
									</ListItemIcon>
								)}
							</ListItem>
						</React.Fragment>
					))
				) : (
					<ListItem key={'empty'} alignItems='flex-start'>
						<ListItemText primary='No notifications found!' />
					</ListItem>
				)}
			</div>
		</>
	);
};

export default Notifications;
