import Backdrop from '../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FilterMenu from '../../components/FilterMenu';
import api from '../../config/api';
import { filterData } from '../../utils/filterData';
import DataRow from './transactionRow';
import { toast } from 'react-toastify';
import TransactionModal from '../../components/Modals/transactions';
import { Delete, TransactionNew } from '../../components/Icons';

const useStyles = makeStyles((theme) => ({
	button: {},
	content: {
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	deleteIcon: {
		padding: 0,
		marginLeft: 16,
	},
	deleteText: {
		color: theme.palette.primary.main,
	},
}));

const columns = [
	{ label: 'Transaction No.', name: 'transactionNo', type: 'number' },
	{ label: 'Transaction Type', name: 'transactionType', type: 'string' },
	{ label: 'Insurance Type', name: 'insuranceType', type: 'string' },
	{ label: 'Insurance Sub-type', name: 'insuranceSubType', type: 'string' },
	{ label: 'Client', name: 'client', type: 'object' },
	{ label: 'Status', name: 'status', type: 'string' },
];

const initFilter = {
	processor: 'And',
	column: columns[0].name,
	type: columns[0].type,
	operator:
		columns[0].type === 'string' || columns[0].type === 'array'
			? 'contains'
			: '=',
	value: '',
};

export default function Transactions() {
	const classes = useStyles();
	const theme = useTheme();

	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [backdropOpen, setBackdropOpen] = useState(false);

	const [transactions, setTransactions] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [editable, setEditable] = useState(false);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogBody, setDialogBody] = useState(0);
	const [filterList, setFilterList] = useState([initFilter]);
	const [sortOptions, setSortOptions] = useState({});
	const [viewTags, setViewTags] = useState(false);
	const [triggerListUpdate, setTriggerListUpdate] = useState(true);

	useEffect(() => {
		if (triggerListUpdate) getTransactions();
	}, [triggerListUpdate]);

	const getTransactions = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('transactions')
			.then((response) => {
				if (response.data.length > 0) {
					let bulkList = response.data
						.map((transaction, index) => {
							return { ...transaction, checked: false };
						})
						.sort((a, b) => {
							return (
								new Date(b.latestActivity.createdAt) -
								new Date(a.latestActivity.createdAt)
							);
						});
					setTransactions(bulkList);
				}
				setTriggerListUpdate(false);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleDelete = () => {
		setDialogOpen(false);
		setBackdropOpen(true);
		let checked = transactions.filter((props) => props.checked);
		let ids = checked.map((item) => item._id);
		api.post('transactions/delete/bulk-delete', ids).then((res) => {
			if (res.data.ok) {
				toast.success('Transaction(s) deleted!');
				setTriggerListUpdate(true);
				setCheckAll(false);
				setPage(0);
				setBackdropOpen(false);
			} else {
				toast.error('An error has occured!');
			}
		});
	};

	const handleCancel = () => {
		setDialogOpen(false);

		setEditable(false);
	};

	const toggleDialog = (body) => {
		if (!dialogOpen) {
			return setDialogBody(body), setDialogOpen(true);
		}
		setDialogOpen(!dialogOpen);
	};

	const totalChecked = () => {
		return transactions.filter((props) => props.checked).length;
	};

	const handleCheckAll = (e) => {
		let updatedTransactions = [...transactions];
		updatedTransactions.forEach(
			(transaction) => (transaction.checked = e.target.checked)
		);
		setCheckAll(e.target.checked);
		setTransactions(updatedTransactions);
	};

	const handleCheckbox = (e, index) => {
		let updatedTransactions = [...transactions];
		updatedTransactions.find((x) => x._id === index).checked = e.target.checked;
		setTransactions(updatedTransactions);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleSearch = (e) => {
		setPage(0);
		setSearchValue(e.target.value);
	};

	const handleClearSearch = () => {
		setPage(0);
		setSearchValue('');
	};

	const handleEditable = () => {
		setEditable(true);
	};

	const searchData = (data) => {
		if (searchValue) {
			let val = searchValue.trim().replace(/\s+/g, ' ').toLowerCase();
			let results = data.filter((item) => {
				return Object.keys(item).some((key) => {
					if (key === 'transactionNo') {
						return String(`TXN-${item[key]}`)
							.toLowerCase()
							.includes(val.toLowerCase());
					} else if (key === 'client') {
						return Object.keys(item[key]).some((subKey) => {
							return (
								typeof item[key][subKey] === 'string' &&
								item[key][subKey].toLowerCase().includes(val.toLowerCase())
							);
						});
					} else {
						return (
							typeof item[key] === 'string' &&
							item[key].toLowerCase().includes(val.toLowerCase())
						);
					}
				});
			});
			return results;
		}
		return data;
	};

	const handleSort = (key) => {
		setPage(0);
		setSortOptions((prevState) => ({ [key]: !prevState[key] }));
	};

	const sortData = (data) => {
		let sortedData = data;
		Object.keys(sortOptions).forEach((e) => {
			if (e === 'transactionNo') {
				return sortedData.sort(compareNumberByDesc(e, sortOptions[e]));
			}
			if (e === 'contactName' || e === 'companyName') {
				return sortedData.sort(compareObjByDesc(e, sortOptions[e]));
			}
			if (e === 'latestActivity') {
				if (sortOptions[e]) {
					return sortedData.sort((a, b) => {
						return (
							new Date(b.latestActivity.createdAt) -
							new Date(a.latestActivity.createdAt)
						);
					});
				} else {
					return sortedData.sort((a, b) => {
						return (
							new Date(a.latestActivity.createdAt) -
							new Date(b.latestActivity.createdAt)
						);
					});
				}
			}
			return sortedData.sort(compareByDesc(e, sortOptions[e]));
		});
		return sortedData;
	};

	const compareObjByDesc = (key, order) => {
		if (order) {
			return function (a, b) {
				if (a['client'][key] === undefined) return 1;
				if (b['client'][key] === undefined) return -1;
				if (a['client'][key] === b['client'][key]) return 0;
				return a['client'][key].toLowerCase() < b['client'][key].toLowerCase()
					? -1
					: 1;
			};
		} else {
			return function (a, b) {
				if (a['client'][key] === undefined) return 1;
				if (b['client'][key] === undefined) return -1;
				if (a['client'][key] === b['client'][key]) return 0;
				return a['client'][key].toLowerCase() > b['client'][key].toLowerCase()
					? -1
					: 1;
			};
		}
	};

	const compareNumberByDesc = (key, order) => {
		if (order) {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key] - b[key];
			};
		} else {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return b[key] - a[key];
			};
		}
	};

	const compareByDesc = (key, order) => {
		if (order) {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key].toLowerCase() < b[key].toLowerCase() ? -1 : 1;
			};
		} else {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key].toLowerCase() > b[key].toLowerCase() ? -1 : 1;
			};
		}
	};

	const getFilteredTransactions = () => {
		let initialList = transactions;
		let sortedTransactions = sortData(initialList);
		let filteredTransactions = filterData(sortedTransactions, filterList);
		let searchedTransactions = searchData(filteredTransactions);
		return searchedTransactions;
	};

	const filteredTransactions = getFilteredTransactions();

	const emptyRows =
		rowsPerPage -
		Math.min(rowsPerPage, filteredTransactions.length - page * rowsPerPage);

	const responseList = () => {
		return filteredTransactions
			.slice(page * 10, page * 10 + 10)
			.map((currentTransaction) => (
				<DataRow
					key={currentTransaction._id}
					data={currentTransaction}
					handleCheckbox={handleCheckbox}
				/>
			));
	};

	return (
		<>
			<Backdrop text='Deleting Transaction(s)...' open={backdropOpen} />
			<Breadcrumbs separator='›' aria-label='breadcrumb'>
				<Typography color='inherit'>Transactions</Typography>
				<Typography color='primary'>Transaction Overview</Typography>
			</Breadcrumbs>
			<Typography variant='h2' style={{ margin: '24px 0px 0px 0px' }}>
				Transaction Overview
			</Typography>
			<div margin='auto'>
				<div className='actionBar'>
					<Box display='flex' alignItems='center'>
						<Box flexGrow={1}>
							<Button
								component={Link}
								to={'/create-transaction'}
								color='primary'
								className={classes.button}
								startIcon={
									<TransactionNew colorcode={theme.palette.tertiary.main} />
								}
							>
								New Transaction
							</Button>
						</Box>
					</Box>
				</div>
				<div className='tableBar'>
					<Box display='flex' alignItems='center'>
						<Box style={{ width: 400 }}>
							<TextField
								style={{ margin: 0 }}
								value={searchValue}
								onChange={(e) => handleSearch(e)}
								placeholder='Search Transactions'
								variant='filled'
								fullWidth
								margin='dense'
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											style={{ marginTop: 0, color: theme.palette.grey[500] }}
										>
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												onClick={handleClearSearch}
												disableFocusRipple
												disableRipple
												style={{
													backgroundColor: 'transparent',
													display: !searchValue && 'none',
												}}
											>
												<ClearIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Box style={{ marginLeft: 16 }}>
							<FilterMenu
								columns={columns}
								filterList={filterList}
								setFilterList={setFilterList}
							/>
						</Box>
					</Box>
				</div>
				<div className='pagination'>
					<TablePagination
						className='pagination'
						component='div'
						count={filteredTransactions.length}
						rowsPerPageOptions={[]}
						rowsPerPage={rowsPerPage}
						page={page}
						SelectProps={{
							inputProps: {
								'aria-label': 'rows per page',
							},
							native: true,
						}}
						onPageChange={handleChangePage}
					/>
				</div>
				<div className={classes.content}>
					<Box
						display='flex'
						style={{
							overflow: 'auto',
							border: '1px solid #edf2f7',
							borderRadius: 8,
						}}
					>
						<Box style={{ width: '100%' }}>
							{totalChecked() > 0 && (
								<div
									style={{
										display: 'flex',
										width: '100%',
										height: 40,
										alignItems: 'center',
										backgroundColor:
											totalChecked() > 0
												? theme.palette.tertiary.light
												: theme.palette.background.paper,
									}}
								>
									{totalChecked() > 0 && (
										<div style={{ paddingLeft: 20, display: 'flex' }}>
											<Typography
												className={classes.deleteText}
												variant='body1'
												align='left'
											>
												{totalChecked()} Selected
											</Typography>
											<IconButton
												className={classes.deleteIcon}
												onClick={() => toggleDialog('deleteTransactions')}
											>
												<Delete colorcode={theme.palette.text.secondary} />
											</IconButton>
										</div>
									)}
								</div>
							)}
							<table className='tableContent' cellSpacing='0'>
								<thead>
									<tr
										style={{
											height: 52,
											backgroundColor: theme.palette.background.paper,
										}}
									>
										<th className='checkBox'>
											<Checkbox
												checked={checkAll}
												onChange={(e) => handleCheckAll(e)}
												inputProps={{
													'aria-label': 'primary checkbox',
												}}
											/>
										</th>
										<th onClick={() => handleSort('transactionNo')}>
											<div className='aligner'>
												<div className='text'>Transaction Number</div>
												<div className='icon'>
													{sortOptions['transactionNo'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('transactionType')}>
											<div className='aligner'>
												<div className='text'>Transaction Type</div>
												<div className='icon'>
													{sortOptions['transactionType'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('insuranceType')}>
											<div className='aligner'>
												<div className='text'>Insurance</div>
												<div className='icon'>
													{sortOptions['insuranceType'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('contactName')}>
											<div className='aligner'>
												<div className='text'>Client</div>
												<div className='icon'>
													{sortOptions['contactName'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('status')}>
											<div className='aligner'>
												<div className='text'>Status</div>
												<div className='icon'>
													{sortOptions['status'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th
											style={{ minWidth: 300 }}
											onClick={() => handleSort('latestActivity')}
										>
											<div className='aligner'>
												<div className='text'>Latest Activity</div>
												<div className='icon'>
													{sortOptions['latestActivity'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{loading ? (
										<tr
											style={{
												height: 640,
											}}
										>
											<td
												colSpan={7}
												className='noResults'
												style={{
													textAlign: 'center',
													border: '1px solid #edf2f7',
												}}
											>
												<CircularProgress size={80} />
											</td>
										</tr>
									) : (
										<>
											{responseList()}
											{emptyRows > 0 && emptyRows < 10 && (
												<tr
													style={{
														height: 64 * emptyRows,
													}}
												>
													<td
														colSpan={7}
														style={{
															border: '1px solid #edf2f7',
														}}
													/>
												</tr>
											)}
										</>
									)}
									{!filteredTransactions.length && !loading && (
										<tr
											style={{
												height: 640,
											}}
										>
											<td
												colSpan={7}
												className='noResults'
												style={{
													textAlign: 'center',
													border: '1px solid #edf2f7',
												}}
											>
												No Results Found
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</Box>
					</Box>
				</div>
				<TransactionModal
					open={dialogOpen}
					body={dialogBody}
					handleCancel={() => handleCancel()}
					handleConfirm={() => handleDelete()}
					handleClose={() => toggleDialog(0)}
				/>
			</div>
		</>
	);
}
