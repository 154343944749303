import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import Skeleton from '@material-ui/lab/Skeleton';
import { differenceInCalendarDays, format } from 'date-fns';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	selected: {
		'&.Mui-selected, &.Mui-selected:hover': {
			backgroundColor: theme.palette.tertiary.hover,
		},
	},
	tableCell: {
		padding: 6,
	},
	tableCellSelected: {
		padding: 6,
		color: theme.palette.tertiary.main,
		fontWeight: 600,
	},
	tableRow: {
		'&:hover, &:focus': {
			backgroundColor: theme.palette.tertiary.hover,
		},
	},
}));

const DataRow = (props) => {
	const classes = useStyles();
	const { data, transactionType, handleSelectTransaction, selected } = props;

	const generateTransactionInfo = () => {
		return (
			<>
				<Typography variant='body2'>TXN-{data.transactionNo}</Typography>
				<Typography variant='body1'>
					{data.client.contactName} - {data.insuranceType}
					{data.insuranceSubType && <> ({data.insuranceSubType})</>} - Policy
					No. {data.policyData.policyNo}
				</Typography>
			</>
		);
	};

	const generateDaysToExpiry = () => {
		if (
			differenceInCalendarDays(new Date(data.policyData.endDate), new Date()) >=
			0
		) {
			return (
				<>
					{differenceInCalendarDays(
						new Date(data.policyData.endDate),
						new Date()
					)}{' '}
					days
				</>
			);
		} else {
			return (
				<>
					<Typography
						color='textPrimary'
						variant='body1'
						align='left'
						style={{ color: '#f00606' }}
					>
						Expired
					</Typography>
				</>
			);
		}
	};

	if (props.loading) {
		return (
			<TableRow className={classes.tableRow} key={data}>
				<TableCell style={{ width: '60%' }}>
					<Skeleton style={{ height: 'unset' }} animation='wave' />
				</TableCell>
				<TableCell style={{ width: '15%' }}>
					<Skeleton style={{ height: 'unset' }} animation='wave' />
				</TableCell>
				<TableCell style={{ width: '15%' }}>
					<Skeleton style={{ height: 'unset' }} animation='wave' />
				</TableCell>
				<TableCell style={{ width: '10%' }}>
					<Skeleton style={{ height: 'unset' }} animation='wave' />
				</TableCell>
			</TableRow>
		);
	}
	return (
		<TableRow
			hover
			className={classes.tableRow}
			selected={selected}
			key={data._id}
			onClick={(e) => handleSelectTransaction(e, data)}
		>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: selected,
				})}
				style={{ width: '60%', padding: '8px 8px 8px 24px' }}
			>
				{generateTransactionInfo()}
			</TableCell>
			{transactionType === 'Claims' ? (
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.tableCellSelected]: selected,
					})}
					style={{ width: '30%' }}
				>
					{`${format(
						new Date(data.policyData.startDate),
						'dd/MM/yyyy'
					)} - ${format(new Date(data.policyData.endDate), 'dd/MM/yyyy')}`}
				</TableCell>
			) : (
				<>
					<TableCell
						className={clsx(classes.tableCell, {
							[classes.tableCellSelected]: selected,
						})}
						style={{ width: '15%' }}
					>
						{format(new Date(data.policyData.endDate), 'dd/MM/yyyy')}
					</TableCell>
					<TableCell
						className={clsx(classes.tableCell, {
							[classes.tableCellSelected]: selected,
						})}
						style={{ width: '15%' }}
					>
						{generateDaysToExpiry()}
					</TableCell>
				</>
			)}
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: selected,
				})}
				style={{ width: '10%', textAlign: 'center' }}
			>
				{selected && <CheckIcon />}
			</TableCell>
		</TableRow>
	);
};

export default DataRow;
