import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import classNames from 'classnames';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({}));

export default function ContextMenu(props) {
	const classes = useStyles();
	const [menu, setMenu] = useState(null);

	return (
		<div>
			<Button
				onClick={(e) => {
					setMenu(e.currentTarget);
				}}
				variant='outlined'
				color='primary'
				endIcon={<ExpandMoreOutlinedIcon />}
			>
				More Actions
			</Button>
			<Menu
				id='menu'
				open={Boolean(menu)}
				anchorEl={menu}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				onClose={() => setMenu(null)}
				className={classes.headerMenu}
				classes={{ paper: classes.menu }}
				disableAutoFocusItem
			>
				<MenuItem
					className={classNames(classes.menuLink, classes.headerMenuItem)}
					onClick={() => {
						setMenu(null);
						props.setEditable(true);
					}}
					style={{ textDecoration: 'none' }}
				>
					Edit Request
				</MenuItem>

				<MenuItem
					className={classNames(classes.menuLink, classes.headerMenuItem)}
					onClick={() => {
						setMenu(null);
						props.handleModalOpen();
					}}
					style={{ textDecoration: 'none' }}
				>
					Resend Request
				</MenuItem>
			</Menu>
		</div>
	);
}
