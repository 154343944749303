import Joi from 'joi';

const Quote = Joi.object({
	expiryDate: Joi.date().label('Expiry Date').iso().min('now').required(),
	startDate: Joi.date()
		.label('Start Date')
		.iso()
		.less(Joi.ref('endDate'))
		.messages({
			'date.less': 'Start Date must be less than End Date',
		})
		.required(),
	endDate: Joi.date().label('End Date').iso().required(),
	premiumAmt: Joi.number()
		.label('Total Premium')
		.min(0)
		.precision(2)
		.required(),
	sumInsured: Joi.number().label('Sum Insured').min(0).precision(2).required(),
	notes: Joi.string()
		.label('Notes')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
	recommendationRemarks: Joi.string()
		.label('Recommendation Remarks')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default Quote;
