import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default function ExperienceMessage(props) {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '10%',
				margin: 'auto',
				boxSizing: 'unset',
			}}
		>
			<img
				className='brand-logo'
				src={`${process.env.REACT_APP_CDN_URL}/logo.png`}
				alt='logo'
				style={{ height: 144, marginBottom: 24 }}
			/>
			<Typography
				color='textPrimary'
				variant='h3'
				align='center'
				style={{ fontWeight: 400, marginBottom: 32 }}
			>
				Uuoni is best experienced on a desktop! Stay tuned for our
				mobile-optimized platform.
			</Typography>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Button
					variant='contained'
					color='primary'
					onClick={() => props.setHideExperienceMessage(true)}
				>
					Proceed to Uuoni
				</Button>
			</div>
		</div>
	);
}
