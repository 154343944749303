import Backdrop from '../../../../../../components/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { differenceInSeconds } from 'date-fns';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Joi from 'joi';
import Tooltip from '../../../../../../components/Tooltip';
import FileUploader from '../../../../../../components/FileUploader';
import FileInfoCard from '../../../../../../components/FileInfoCard';
import TransactionModal from '../../../../../../components/Modals/transactions';
import { AuthUserContext } from '../../../../../../components/Session';
import api from '../../../../../../config/api';
import { getDiff } from '../../../../../../utils/formHelper';
import { ProposalForm } from '../../../../../../utils/validators/schemas';
import {
	Delete,
	Edit,
	Remarks,
	Resend,
	Save,
} from '../../../../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	statusBar: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px 24px',
	},
}));

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

function Row(props) {
	const {
		index,
		item,
		handleDocumentChange,
		handleRemoveDocumentRemarks,
		handleRemoveDocument,
		errorMessages,
	} = props;
	const [open, setOpen] = useState(item.remarks ? true : false);
	const classes = useStyles();

	const toggleDocumentRemarks = () => {
		if (open) {
			handleRemoveDocumentRemarks(item.key);
			setOpen(!open);
		} else {
			setOpen(true);
		}
	};

	const getErrorMessage = () => {
		if (errorMessages[`supportingDocuments.${index}.documentName`]) {
			return errorMessages[`supportingDocuments.${index}.documentName`];
		}
		if (errorMessages[`supportingDocuments.${index}`]) {
			return errorMessages[`supportingDocuments.${index}`];
		}
		return null;
	};

	return (
		<Grid container direction='row' alignItems='center' spacing={1}>
			<Grid item xs={4}>
				<TextField
					name='documentName'
					value={item.documentName || ''}
					onChange={(e) => handleDocumentChange(e, item.key)}
					placeholder='Enter document name'
					variant='filled'
					fullWidth
					margin='dense'
					error={
						!!errorMessages[`supportingDocuments.${index}.documentName`] ||
						!!errorMessages[`supportingDocuments.${index}`]
					}
					helperText={getErrorMessage()}
				/>
			</Grid>
			{/* <Collapse in={open} timeout='auto' unmountOnExit> */}
			{open && (
				<Grid item xs={6}>
					<TextField
						name='remarks'
						value={item.remarks || ''}
						onChange={(e) => handleDocumentChange(e, item.key)}
						placeholder='Enter remarks'
						variant='filled'
						fullWidth
						margin='dense'
						error={!!errorMessages[`supportingDocuments.${index}.remarks`]}
						helperText={
							errorMessages[`supportingDocuments.${index}.remarks`] &&
							errorMessages[`supportingDocuments.${index}.remarks`]
						}
					/>
				</Grid>
			)}
			<Grid item xs={1}>
				<div style={{ display: 'flex', justifyContent: 'space-around' }}>
					<IconButton
						aria-label='expand row'
						size='small'
						onClick={() => toggleDocumentRemarks()}
					>
						<Remarks style={{ fontSize: 24 }} />
					</IconButton>
					<IconButton
						aria-label='expand row'
						size='small'
						onClick={() => handleRemoveDocument(item.key)}
					>
						<Delete style={{ fontSize: 24 }} />
					</IconButton>
				</div>
			</Grid>
		</Grid>
	);
}

export default function FileRequestViewer(props) {
	const {
		transaction,
		setTransaction,
		clientRequest,
		setTriggerUpdate,
		setTriggerUpdateActivities,
		disableEdit,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState(0);
	const [optionData, setOptionData] = useState([]);
	const [responseList, setResponseList] = useState([]);
	const [pendingList, setPendingList] = useState([]);
	const [respondedList, setRespondedList] = useState([]);
	const [expand, setExpand] = useState(false);
	const [editable, setEditable] = useState(false);
	const [currentData, setCurrentData] = useState(cloneDeep(clientRequest));
	const [updatedData, setUpdatedData] = useState(cloneDeep(clientRequest));
	const [errorMessages, setErrorMessages] = useState({});
	const [documents, setDocuments] = useState([]);

	const [loading, setLoading] = useState(false);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);

	const initializeData = () => {
		let initData = cloneDeep(clientRequest);
		if (initData.fileList.length > 0) {
			const fileList = initData.fileList.map((file) => ({
				...file,
				key: nanoid(5),
			}));

			initData = {
				...initData,
				fileList: fileList,
			};
			setDocuments(fileList);
		}
		setCurrentData(cloneDeep(initData));
		setUpdatedData(cloneDeep(initData));
	};

	useEffect(() => {
		initializeData();
	}, []);

	useEffect(() => {
		handleFileListChange(documents);
	}, [documents]);

	const handleValidation = () => {
		let errorData = {};
		let dataToValidate = {};
		let schemaObject = {};
		let result = {};
		let isValid = true;

		if (updatedData.hasProposalForm) {
			if (updatedData.proposalForm.method === 'url') {
				dataToValidate['proposalForm'] = {
					url: updatedData.proposalForm.url,
					instructions: updatedData.proposalForm.instructions,
				};
				schemaObject['proposalForm'] = ProposalForm;
			} else {
				if (
					isEmpty(currentData.proposalForm.documentId) &&
					isEmpty(updatedData.proposalForm.fileData)
				) {
					errorData['proposalForm'] = 'Unfilled Proposal Form is required!';
					isValid = false;
				}
			}
		}

		if (updatedData.fileList.length > 0) {
			dataToValidate['supportingDocuments'] = updatedData.fileList;
			schemaObject['supportingDocuments'] = Joi.array()
				.items(
					Joi.object({
						documentName: Joi.string()
							.label('Document Name')
							.trim()
							.min(1)
							.max(50)
							.required(),

						remarks: Joi.string()
							.label('Remarks')
							.trim()
							.min(1)
							.max(1000)
							.optional()
							.allow(''),
					}).unknown(true)
				)
				.unique((a, b) => a.documentName === b.documentName);
		}

		dataToValidate['clientRequest'] = {
			requestSubject: updatedData.requestSubject,
			requestMessage: updatedData.requestMessage,
		};
		schemaObject['clientRequest'] = Joi.object({
			requestSubject: Joi.string()
				.label('Subject')
				.trim()
				.min(1)
				.max(250)
				.required(),
			requestMessage: Joi.string()
				.label('Message')
				.trim()
				.min(1)
				.max(1000)
				.required(),
		}).unknown(true);

		let schema = Joi.object(schemaObject);
		result = schema.validate(dataToValidate, {
			abortEarly: false,
			convert: false,
		});

		if (result.error === undefined && isValid) {
			setErrorMessages({});
			return true;
		} else {
			if (result.error) {
				console.log(result.error.details);
				for (let item of result.error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			console.log(errorData);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = () => {
		if (!loading) {
			console.log('handleSave');
			setLoading(true);

			let valid = handleValidation();
			if (valid) {
				let changedData = getDiff(updatedData, currentData);
				console.log(changedData);

				if (!changedData) {
					setErrorMessages({});
					setEditable(false);
					setLoading(false);
				} else {
					try {
						const data = new FormData();
						let json = cloneDeep(updatedData);

						if (json.hasProposalForm && !isEmpty(json.proposalForm.fileData)) {
							data.append(
								json.transactionType === 'Claims'
									? 'Claim Form'
									: 'Proposal Form',
								json.proposalForm.fileData[0],
								json.proposalForm.fileData[0].name
							);
							delete json.proposalForm.fileData;
						}

						let formData = {
							updatedFields: {
								requestSubject: json.requestSubject,
								requestMessage: json.requestMessage,
								fileList: json.fileList,
								...(json.hasProposalForm && {
									proposalForm: json.proposalForm,
								}),
							},
							auditLog: changedData,
						};

						console.log(formData);
						data.append('json', JSON.stringify(formData));

						api
							.put('client-requests/file-request/' + clientRequest._id, data)
							.then((res) => {
								if (res.data.ok) {
									setErrorMessages({});
									setEditable(false);
									setTriggerUpdate(true);
									toast.success('File Request updated!');
								} else {
									toast.error(res.data.error);
								}
							});
					} catch (error) {
						console.log(error);
						toast.error(error);
					} finally {
						setLoading(false);
					}
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	const handleCancel = () => {
		initializeData();
		setEditable(false);
	};

	const handleProposalFormFileChange = (e, files) => {
		e.preventDefault();
		setUpdatedData((prev) => ({
			...prev,
			proposalForm: {
				...prev.proposalForm,
				fileData: files,
			},
		}));
	};

	const handleAddDocument = () => {
		let updatedDocuments = [...documents];
		let newDocument = {
			documentName: '',
			remarks: '',
			key: nanoid(5),
		};
		updatedDocuments.push(newDocument);
		setDocuments(updatedDocuments);
	};

	const handleRemoveDocument = (key) => {
		let updatedDocuments = [...documents].filter((el, index) => {
			return el.key !== key;
		});
		setDocuments(updatedDocuments);
	};

	const handleRemoveDocumentRemarks = (key) => {
		let updatedDocuments = [...documents];
		let updatedIndex = updatedDocuments.findIndex((doc) => doc.key === key);
		updatedDocuments[updatedIndex]['remarks'] = '';
		setDocuments(updatedDocuments);
	};

	const handleDocumentChange = (e, key) => {
		const {
			target: { value, name },
		} = e;
		let updatedDocuments = [...documents];
		let updatedIndex = updatedDocuments.findIndex((doc) => doc.key === key);
		updatedDocuments[updatedIndex][name] = value;
		setDocuments(updatedDocuments);
	};

	const handleInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;

		const keys = name.split('.');
		const updateValue =
			type === 'number' && value !== '' ? parseFloat(value) : value;

		const updateNestedData = (data, keyIndex = 0) => {
			if (keyIndex === keys.length - 1) {
				return { ...data, [keys[keyIndex]]: updateValue };
			} else {
				return {
					...data,
					[keys[keyIndex]]: updateNestedData(
						data[keys[keyIndex]],
						keyIndex + 1
					),
				};
			}
		};

		setUpdatedData((prev) => {
			if (keys[0] === 'principalId') {
				let val = optionData.find((principal) => principal._id === value);
				return {
					...prev,
					principalId: val ? val._id : '',
					principalName: val ? val.shortName : '',
				};
			} else {
				return updateNestedData(prev);
			}
		});
	};

	const handleDateChange = (target, date) => {
		setUpdatedData((prev) => ({
			...prev,
			[target]: date,
		}));
	};

	const handleFileListChange = (documents) => {
		setUpdatedData((prev) => ({
			...prev,
			fileList: documents,
		}));
	};

	const getAvatar = (str) => {
		return str.charAt(0).toUpperCase();
	};

	const getTextColor = (status) => {
		if (status === 'Responded') return '#40BE79';
		if (status === 'Expired' || status === 'Email failed') return '#F16063';
		if (status === 'Pending Response') return '#A8701D';
		return '';
	};

	const getStatusBarColor = (status) => {
		if (status === 'Responded') return 'rgba(102, 203, 148, 0.2)';
		if (status === 'Expired' || status === 'Email failed') return '#FCE9E9';
		if (status === 'Pending Response') return '#F7C16F';
		return '';
	};

	const handleModalOpen = (body) => {
		setModalType(body);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalType(null);
	};

	const getActions = () => {
		if (editable) {
			return (
				<>
					<Grid item>
						<Button
							onClick={() => handleCancel()}
							variant='outlined'
							color='primary'
							className={classes.button}
						>
							Cancel
						</Button>
					</Grid>
					<Grid item>
						<Button
							onClick={() => handleSave()}
							variant='contained'
							color='primary'
							className={classes.button}
							startIcon={
								<Save
									style={{ fontSize: 24 }}
									colorcode={theme.palette.primary.contrastText}
								/>
							}
						>
							Save
						</Button>
					</Grid>
				</>
			);
		} else {
			return (
				<>
					<Grid item>
						<Button
							onClick={() => handleModalOpen('resendClientRequest')}
							variant='outlined'
							color='primary'
							className={classes.button}
							startIcon={
								<Resend
									style={{ fontSize: 24 }}
									colorcode={theme.palette.tertiary.main}
								/>
							}
						>
							Resend Request
						</Button>
					</Grid>
					<Grid item>
						<Button
							onClick={() => setEditable(true)}
							variant='contained'
							color='primary'
							className={classes.button}
							startIcon={
								<Edit
									style={{ fontSize: 24 }}
									colorcode={theme.palette.primary.contrastText}
								/>
							}
						>
							Edit Request
						</Button>
					</Grid>
				</>
			);
		}
	};

	return (
		<AuthUserContext.Consumer>
			{(credentials) => (
				<>
					<Backdrop text='Saving Data...' open={loading} />
					<div>
						<div
							className={classes.statusBar}
							style={{
								backgroundColor:
									differenceInSeconds(
										new Date(),
										new Date(updatedData.requestExpiryDate)
									) > 0 && updatedData.status === 'Pending Response'
										? getStatusBarColor('Expired')
										: getStatusBarColor(updatedData.status),
							}}
						>
							<Typography
								variant='body2'
								style={{
									color:
										differenceInSeconds(
											new Date(),
											new Date(updatedData.requestExpiryDate)
										) > 0 && updatedData.status === 'Pending Response'
											? getTextColor('Expired')
											: getTextColor(updatedData.status),
								}}
							>
								Status:{' '}
								{differenceInSeconds(
									new Date(),
									new Date(updatedData.requestExpiryDate)
								) > 0 && updatedData.status === 'Pending Response'
									? 'Expired'
									: updatedData.status}
							</Typography>
						</div>
						<Grid
							container
							direction='row'
							alignItems='stretch'
							justifyContent='space-between'
							style={{ padding: '16px 24px' }}
							spacing={2}
						>
							<Grid
								item
								container
								direction='row'
								alignItems='center'
								spacing={2}
								xs={7}
							>
								<Grid item xs={12} md={6} lg={3}>
									<Typography color='textPrimary' variant='body2'>
										Request Date
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										{moment(updatedData.requestDate).format('DD/MM/YYYY')}
									</Typography>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<Typography color='textPrimary' variant='body2'>
										Request Expiry Date
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										{moment(updatedData.requestExpiryDate).format('DD/MM/YYYY')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<div style={{ display: 'flex' }}>
										<Typography
											style={{ width: 48, textAlign: 'end' }}
											color='textPrimary'
											variant='body2'
										>
											From
										</Typography>
										<Typography color='textPrimary' variant='body2'>
											:
										</Typography>
										<Typography
											style={{ margin: '0px 8px' }}
											color='textPrimary'
											variant='body1'
										>
											{`${credentials.userProfile.profile.firstName} ${credentials.userProfile.profile.lastName}`}
										</Typography>
										<Typography color='textSecondary' variant='body1'>
											{`(mail-service@mg.uuoni.com)`}
										</Typography>
									</div>
								</Grid>
								<Grid item xs={12}>
									<div style={{ display: 'flex' }}>
										<Typography
											style={{ width: 48, textAlign: 'end' }}
											color='textPrimary'
											variant='body2'
										>
											To
										</Typography>
										<Typography color='textPrimary' variant='body2'>
											:
										</Typography>
										<Typography
											style={{ margin: '0px 8px' }}
											color='textPrimary'
											variant='body1'
										>
											{updatedData.recipientName}
										</Typography>
										<Typography color='textSecondary' variant='body1'>
											{`(${updatedData.recipientEmail})`}
										</Typography>
									</div>
								</Grid>
							</Grid>
							<Grid item xs={5}>
								<Grid
									container
									direction='row'
									alignItems='center'
									justifyContent='flex-end'
									spacing={1}
								>
									{!disableEdit && getActions()}
								</Grid>
							</Grid>
							<Grid
								item
								container
								direction='row'
								alignItems='center'
								spacing={2}
								xs={12}
							>
								<Grid item xs={10}>
									<Typography color='textPrimary' variant='body2'>
										Subject
									</Typography>
									{editable ? (
										<TextField
											name='requestSubject'
											value={updatedData.requestSubject || ''}
											onChange={(e) => handleInputChange(e)}
											InputProps={{
												style: { color: 'unset' },
											}}
											variant={'filled'}
											fullWidth
											margin='dense'
											error={!!errorMessages[`clientRequest.requestSubject`]}
											helperText={
												errorMessages[`clientRequest.requestSubject`] &&
												errorMessages[`clientRequest.requestSubject`]
											}
										/>
									) : (
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
											style={{ marginTop: 8 }}
										>
											{updatedData.requestSubject}
										</Typography>
									)}
								</Grid>
								<Grid item xs={10}>
									<Typography color='textPrimary' variant='body2'>
										Message
									</Typography>
									{editable ? (
										<TextField
											name='requestMessage'
											value={updatedData.requestMessage || ''}
											multiline
											InputProps={{
												style: { color: 'unset' },
											}}
											onChange={(e) => handleInputChange(e)}
											variant='filled'
											fullWidth
											margin='dense'
											minRows={4}
											error={!!errorMessages[`clientRequest.requestMessage`]}
											helperText={
												errorMessages[`clientRequest.requestMessage`] &&
												errorMessages[`clientRequest.requestMessage`]
											}
										/>
									) : (
										<Typography
											color='textPrimary'
											variant='body1'
											align='left'
											style={{ marginTop: 8 }}
										>
											{updatedData.requestMessage}
										</Typography>
									)}
								</Grid>
								<Grid item xs={12}>
									<Typography color='textPrimary' variant='body2'>
										List of requested documents
									</Typography>
									{updatedData.hasProposalForm && (
										<>
											{updatedData.proposalForm.method === 'upload' ? (
												<div
													className={classes.docField}
													style={{ marginTop: 8 }}
												>
													<Grid item xs={10}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
															}}
														>
															<Typography
																color='textPrimary'
																variant='body2'
																style={{ marginRight: 8 }}
															>
																Unfilled Proposal Form
															</Typography>
															<Tooltip content={printReqString()} />
														</div>
														<div
															style={{
																width: '100%',
																marginTop: 8,
																marginBottom: 24,
															}}
														>
															{editable ? (
																<FileUploader
																	fileLimit={1}
																	files={updatedData.proposalForm.fileData}
																	handleFileChange={
																		handleProposalFormFileChange
																	}
																	formErrorMessage={
																		errorMessages[`proposalForm`] &&
																		errorMessages[`proposalForm`]
																	}
																/>
															) : (
																<>
																	{updatedData.proposalForm !== null && (
																		<FileInfoCard
																			file={updatedData.proposalForm}
																		/>
																	)}
																</>
															)}
														</div>
													</Grid>
												</div>
											) : (
												<>
													<div
														className={classes.docField}
														style={{ marginTop: 8 }}
													>
														<Grid item xs={10}>
															<div
																style={{
																	display: 'flex',
																	flexDirection: editable ? 'column' : 'row',
																	alignItems: 'flex-start',
																	overflowWrap: 'anywhere',
																}}
															>
																<Typography
																	style={{
																		whiteSpace: 'nowrap',
																		marginRight: 8,
																	}}
																	color='textPrimary'
																	variant='body2'
																>
																	{updatedData.transactionType === 'Claims'
																		? 'Claim Form URL:'
																		: 'Proposal Form URL:'}
																</Typography>
																{editable ? (
																	<TextField
																		name='proposalForm.url'
																		value={updatedData.proposalForm.url || ''}
																		onChange={(e) => handleInputChange(e)}
																		InputProps={{
																			style: { color: 'unset' },
																		}}
																		variant={'filled'}
																		fullWidth
																		margin='dense'
																		error={!!errorMessages[`proposalForm.url`]}
																		helperText={
																			errorMessages[`proposalForm.url`] &&
																			errorMessages[`proposalForm.url`]
																		}
																	/>
																) : (
																	<Typography
																		color='textPrimary'
																		variant='body1'
																	>
																		{updatedData.proposalForm.url}
																	</Typography>
																)}
															</div>
														</Grid>
													</div>
													<div
														className={classes.docField}
														style={{ marginTop: 8 }}
													>
														<Grid item xs={10}>
															<div
																style={{
																	display: 'flex',
																	flexDirection: editable ? 'column' : 'row',
																	alignItems: 'flex-start',
																	overflowWrap: 'anywhere',
																}}
															>
																{editable ? (
																	<>
																		<Typography
																			style={{
																				whiteSpace: 'nowrap',
																				marginRight: 8,
																			}}
																			color='textPrimary'
																			variant='body2'
																		>
																			Instructions:
																		</Typography>
																		<TextField
																			name='proposalForm.instructions'
																			value={
																				updatedData.proposalForm.instructions ||
																				''
																			}
																			multiline
																			onChange={(e) => handleInputChange(e)}
																			placeholder='Provide instructions for your client on which forms to download or fill-up via the link provided'
																			variant='filled'
																			fullWidth
																			margin='dense'
																			minRows={4}
																			error={
																				!!errorMessages[
																					`proposalForm.instructions`
																				]
																			}
																			helperText={
																				errorMessages[
																					`proposalForm.instructions`
																				] &&
																				errorMessages[
																					`proposalForm.instructions`
																				]
																			}
																		/>
																	</>
																) : (
																	<Typography
																		color='textSecondary'
																		variant='body1'
																		style={{
																			overflowWrap: 'anywhere',
																			whiteSpace: 'pre-wrap',
																		}}
																	>
																		{updatedData.proposalForm.instructions}
																	</Typography>
																)}
															</div>
														</Grid>
													</div>
												</>
											)}
										</>
									)}
									{editable ? (
										<div className={classes.docField} style={{ marginTop: 8 }}>
											{documents.map((file, index) => (
												<Row
													key={file.key}
													index={index}
													item={file}
													handleRemoveDocument={handleRemoveDocument}
													handleRemoveDocumentRemarks={
														handleRemoveDocumentRemarks
													}
													handleDocumentChange={handleDocumentChange}
													errorMessages={errorMessages}
												/>
											))}
											<Button
												style={{ padding: 8 }}
												color='primary'
												onClick={handleAddDocument}
												className={classes.button}
												startIcon={<AddCircleOutlineOutlinedIcon />}
											>
												Add Document
											</Button>
										</div>
									) : (
										<>
											{documents.map((file, index) => (
												<div
													key={index}
													className={classes.docField}
													style={{ marginTop: 8 }}
												>
													<Grid item xs={10}>
														<Typography color='textPrimary' variant='body2'>
															{file.documentName}
														</Typography>
														<Typography
															style={{
																overflowWrap: 'anywhere',
																whiteSpace: 'pre-wrap',
																marginTop: 8,
															}}
															color='textSecondary'
															variant='body1'
														>
															{file.remarks}
														</Typography>
													</Grid>
												</div>
											))}
										</>
									)}
								</Grid>
							</Grid>
						</Grid>
					</div>
					{modalOpen && (
						<TransactionModal
							open={modalOpen}
							handleClose={handleModalClose}
							body={modalType}
							transactionId={transaction._id}
							data={clientRequest}
							setTriggerUpdate={setTriggerUpdate}
							size={modalType === 'sendRequestForClaims' ? 'md' : 'sm'}
						/>
					)}
				</>
			)}
		</AuthUserContext.Consumer>
	);
}
