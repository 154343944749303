import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	menu: {
		marginTop: theme.spacing(7),
	},
}));

export default function ActionMenu(props) {
	const classes = useStyles();

	return (
		<Menu
			id='principal-menu'
			open={Boolean(props.menu)}
			anchorEl={props.menu}
			onClose={() => {
				props.setPrincipalForm(props.initForm);
				props.setMenuContext(null);
				props.setMenu(null);
			}}
			classes={{ paper: classes.menu }}
			disableAutoFocusItem
		>
			<MenuItem
				onClick={() => {
					props.setMenu(null);
					props.toggleDialog('editPrincipal');
				}}
			>
				Edit Principal
			</MenuItem>
			{props.menuContext === 'Deactivate' && (
				<MenuItem
					onClick={() => {
						props.setMenu(null);
						props.toggleDialog('deactivatePrincipal');
					}}
				>
					Deactivate
				</MenuItem>
			)}
			{props.menuContext === 'Reactivate' && (
				<MenuItem
					onClick={() => {
						props.setMenu(null);
						props.toggleDialog('reactivatePrincipal');
					}}
				>
					Reactivate
				</MenuItem>
			)}
		</Menu>
	);
}
