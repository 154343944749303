import React, { useState, useEffect } from 'react';
import {
	ComposedChart,
	Cell,
	Line,
	Scatter,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	ReferenceLine,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../config/api';

const COLORS = [
	'#7E909A',
	'#274C77',
	'#6096BA',
	'#71A5DE',
	'#A3CEF1',
	'#CAE3F7',
];

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: 16,
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
		borderRadius: 4,
	},
	filterMenu: {
		padding: 16,
	},
}));

export default function SalesTargets() {
	const classes = useStyles();
	const theme = useTheme();

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [frequency, setFrequency] = useState('yearly');
	const [anchorEl, setAnchorEl] = useState(null);

	const initFilters = (dataset) => {
		let sortedList = [...dataset].sort((a, b) => b.Premium - a.Premium);
		let filterList = sortedList.map((data, index) => {
			if (index < 3) {
				return {
					...data,
					checked: true,
				};
			} else {
				return {
					...data,
					checked: false,
				};
			}
		});
		setData(filterList);
	};

	const getData = () => {
		if (!loading) {
			setLoading(true);
			setData(null);
		}
		api
			.get('widgets/sales-targets/?freq=' + frequency)
			.then((response) => {
				if (response.data.ok) {
					initFilters(response.data.data);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, [frequency]);

	const labelFormatter = (value) => {
		return '$' + value.toLocaleString();
	};

	const getDataset = () => {
		let filteredDataset = data.filter((obj) => obj.checked === true);
		return filteredDataset;
	};

	return (
		<div className={classes.paper}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography variant='h4' color='textPrimary'>
					Sales Targets
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					onClick={(e) => setAnchorEl(e.currentTarget)}
				>
					<TuneOutlinedIcon />
				</IconButton>
				<Popover
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					open={Boolean(anchorEl)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<div className={classes.filterMenu}>
						<div>
							<Typography variant='body2' color='textSecondary'>
								Frequency
							</Typography>
							<Select
								native
								name='frequency'
								value={frequency}
								onChange={(e) => setFrequency(e.target.value)}
								variant='filled'
								fullWidth
								margin='dense'
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option key={0} value='yearly'>
									Yearly
								</option>
								<option key={1} value='monthly'>
									Monthly
								</option>
								<option key={2} value='weekly'>
									Weekly
								</option>
								<option key={3} value='daily'>
									Today
								</option>
							</Select>
						</div>
					</div>
				</Popover>
			</div>
			{!data || getDataset().length <= 0 ? (
				<div style={{ display: 'flex', width: '100%', height: '100%' }}>
					{loading ? (
						<CircularProgress style={{ margin: 'auto' }} />
					) : (
						<Typography
							style={{ margin: 'auto' }}
							variant='body1'
							color='textPrimary'
						>
							No Data Available
						</Typography>
					)}
				</div>
			) : (
				<ResponsiveContainer width='100%' height='100%'>
					<ComposedChart
						width={500}
						height={500}
						data={getDataset()}
						margin={{
							top: 32,
							right: 32,

							left: 32,
						}}
					>
						<XAxis dataKey='principalName' type='category' />
						<YAxis
							type='number'
							tickFormatter={(value) => '$' + value.toLocaleString()}
						/>
						<Tooltip
							wrapperStyle={{ zIndex: 10 }}
							formatter={(value) => '$' + value.toLocaleString()}
						/>
						<Legend
							formatter={(value, entry, index) => (
								<span style={{ color: '#000000' }}>{value}</span>
							)}
							iconType='circle'
							iconSize={10}
							payload={[
								{
									id: 'Premium',
									value: 'Premium',
									type: 'circle',
									color: '#000000',
								},
								{
									id: 'Target',
									value: 'Target',
									type: 'line',
									color: '#000000',
								},
							]}
						/>
						<Bar
							dataKey='Premium'
							maxBarSize={56}
							label={{ position: 'top', formatter: labelFormatter }}
						>
							{getDataset().map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={COLORS[index + (index + 1)]}
								/>
							))}
						</Bar>
						{getDataset().map((entry, index) => (
							<ReferenceLine
								key={`line-${index}`}
								y={entry.Target}
								stroke={COLORS[index + (index + 1)]}
								strokeWidth={2}
								strokeDasharray='3 3'
							/>
						))}
						<Line
							dataKey='Target'
							dot={false}
							activeDot={false}
							strokeWidth={0}
						/>
					</ComposedChart>
				</ResponsiveContainer>
			)}
		</div>
	);
}
