import Backdrop from '../../components/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '../../components/Tooltip';
import firebase from 'firebase/compat/app';
import React, { useState, useEffect } from 'react';
import { ReactComponent as Verification } from '../../assets/SignUp_Verification.svg';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {
	Link as RouterLink,
	Redirect,
	withRouter,
	useHistory,
} from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { AuthUserContext } from '../../components/Session';
import { toast } from 'react-toastify';
import api from '../../config/api';
import { Register as RegisterSchema } from '../../utils/validators/schemas';
import { useMediaQuery } from 'react-responsive';
import { Preview, PreviewHide } from '../../components/Icons';

const styles = (theme) => ({
	input: {
		width: '100%',
		maxWidth: '460px',
		height: '50px',
		marginBottom: '2vh',
	},
	button: {
		width: '100%',
		height: 48,
		margin: '24px 0px',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
	},
	firebaseUi: {
		marginTop: 40,
	},
});

const printReqString = () => {
	let base = 'Password must meet the following requirements:';
	let mapped = requirements
		.map((req) => {
			return `- ${req}`;
		})
		.join('\n');
	return <div style={{ whiteSpace: 'pre-line' }}>{`${base}\n${mapped}`}</div>;
};

const requirements = [
	'Be at least 8 characters long',
	'At least 1 uppercase character',
	'At least 1 lowercase character',
	'At least 1 number',
	"At least 1 special character (e.g. !”#$%&'()*+,-./:;?@[\\]^_`{|}~)",
];

const RegisterPage = () => <RegisterForm />;

const INITIAL_STATE = {
	email: '',
	passwordOne: '',
	passwordTwo: '',
};

const Register = (props) => {
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const { classes, theme } = props;
	const history = useHistory();
	const [formData, setFormData] = useState({
		...INITIAL_STATE,
	});
	const [error, setError] = useState(null);
	const [errorMessages, setErrorMessages] = useState({});
	const [timeLeft, setTimeLeft] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		if (timeLeft === 0) {
			setTimeLeft(null);
		}

		// exit early when we reach 0
		if (!timeLeft) return;

		// save intervalId to clear the interval when the
		// component re-renders
		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		// clear interval on re-render to avoid memory leaks
		return () => clearInterval(intervalId);
		// add timeLeft as a dependency to re-rerun the effect
		// when we update it
	}, [timeLeft]);

	const toggleShowPassword = () => {
		if (!showPassword) {
			setShowPassword(true);
		}
		setShowPassword(!showPassword);
	};

	const handleSignOut = async (e) => {
		e.preventDefault();

		await firebase
			.auth()
			.signOut()
			.then(function () {
				console.log('Successfully signed out.');
				history.push('/login');
			})
			.catch(function (error) {
				console.log(error);
				console.log('An error occurred');
			});
	};

	const onSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		let valid = handleValidation();

		if (valid) {
			const { email, passwordOne } = formData;

			props.firebase
				.doCreateUserWithEmailAndPassword(email, passwordOne)
				.then((authUser) => {
					props.firebase
						.doSendEmailVerification()
						.then(() => {
							// Verification email sent.
							const newUser = {
								email: email,
								firebaseUid: authUser.user.uid,
							};
							api.post('register', newUser).then((res) => {
								console.log(res.data);
								setFormData((prev) => ({
									...prev,
									...INITIAL_STATE,
								}));
								setLoading(false);
								setErrorMessages({});
							});
						})
						.catch(function (error) {
							setError('An error has occured!');
							setLoading(false);
						});
				})
				.catch((error) => {
					setError('An error has occured!');
					setLoading(false);
				});
		} else {
			setError('An error has occured!');
			setLoading(false);
		}
	};

	const resendVerificationEmail = () => {
		if (loading) {
			return;
		} else {
			setLoading(true);
			props.firebase
				.doSendEmailVerification()
				.then(() => {
					toast.success('Verification email sent!');
					setLoading(false);
					setTimeLeft(30);
				})
				.catch(function (error) {
					setLoading(false);
					toast.error('An error has occurred!');
					setTimeLeft(30);
				});
		}
	};

	const onChange = (event) => {
		const { name, value } = event.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const uiConfig = {
		signInFlow: 'popup',
		signInSuccessUrl: '/dashboard',
		signInOptions: [
			firebase.auth.GoogleAuthProvider.PROVIDER_ID,
			// firebase.auth.EmailAuthProvider.PROVIDER_ID,
		],
		callbacks: {
			signInSuccessWithAuthResult: () => false,
		},
	};

	const handleValidation = () => {
		let result = RegisterSchema.validate(
			{
				email: formData.email,
				passwordOne: formData.passwordOne,
				passwordTwo: formData.passwordTwo,
			},
			{ abortEarly: false, convert: false }
		);

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	return (
		<AuthUserContext.Consumer>
			{(credentials) => {
				if (
					credentials.authUser &&
					credentials.verified &&
					credentials.userProfile.onboarded
				)
					return <Redirect to='/dashboard' />;
				if (
					credentials.authUser &&
					credentials.verified &&
					!credentials.userProfile.onboarded
				)
					return <Redirect to='/onboarding' />;
				return (
					<>
						<Backdrop text='Registering...' open={loading} />
						{isDesktopOrLaptop ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									height: '100%',
									padding: 40,
									alignItems: 'center',
								}}
							>
								<div
									style={{
										width: '100%',
									}}
								>
									<div
										style={{
											display:
												credentials.authUser && !credentials.verified
													? 'none'
													: 'flex',
											alignItems: 'center',
										}}
									>
										<Typography
											style={{ marginLeft: 'auto' }}
											color='textSecondary'
											variant='body1'
										>
											Already have an account?
										</Typography>
										<Button
											style={{ marginLeft: 16 }}
											component={RouterLink}
											to='/login'
											variant='outlined'
											color='primary'
										>
											Log In
										</Button>
									</div>
								</div>
								{credentials.authUser && !credentials.verified ? (
									<>
										<div style={{ width: 500 }}>
											<Verification />
										</div>
										<Typography variant='h1' style={{ textAlign: 'center' }}>
											Verify your email address
										</Typography>
										<div
											style={{
												flexGrow: 1,
												textAlign: 'center',
												marginTop: 24,
												width: '50%',
												minWidth: 340,
											}}
										>
											<div>
												<Typography variant='body1'>
													We have sent a verification email to the entered email
													address.
												</Typography>
												<Typography
													variant='body1'
													style={{
														marginTop: 8,
													}}
												>
													If you have not received the verification email,
													please check your "Spam" folder. To have another
													verification email send to you, click on "Resend
													Email' below.
												</Typography>
											</div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													margin: 'auto',
													marginTop: 8,
												}}
											>
												<Button
													className={classes.button}
													variant='outlined'
													color='primary'
													onClick={handleSignOut}
												>
													Back to Login
												</Button>
												<Button
													className={classes.button}
													style={{ marginLeft: 16 }}
													variant='contained'
													color='primary'
													disabled={timeLeft && timeLeft > 0 ? true : false}
													onClick={() => resendVerificationEmail()}
												>
													{timeLeft && timeLeft > 0
														? `Try again in ${timeLeft}`
														: 'Resend Email'}
												</Button>
											</div>
										</div>
									</>
								) : (
									<>
										<div style={{ flex: '0 0 24px' }} />
										<Typography variant='h1' style={{ textAlign: 'center' }}>
											Create your account
										</Typography>
										<div className={classes.firebaseUi}>
											<StyledFirebaseAuth
												uiConfig={uiConfig}
												firebaseAuth={firebase.auth()}
											/>
										</div>
										<div style={{ flexGrow: 1, width: '50%', minWidth: 340 }}>
											<form
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
												}}
												onSubmit={onSubmit}
											>
												<div className='login-divider' style={{ width: '75%' }}>
													<span className='dividerText'>Or sign up with</span>
												</div>
												{error && (
													<div
														style={{
															backgroundColor: '#FCE9E9',
															padding: '16px 24px',
															borderRadius: 4,
															marginBottom: 24,
														}}
													>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
															}}
														>
															<HighlightOffIcon style={{ color: '#F60C36' }} />
															<div style={{ marginLeft: 16 }}>
																<Typography
																	style={{
																		color: theme.palette.error.main,
																		marginBottom: 8,
																	}}
																	variant='body2'
																>
																	Error
																</Typography>
																<Typography
																	style={{ color: theme.palette.error.main }}
																	variant='body1'
																>
																	{/* {error.message} */}
																	An error occurred!
																</Typography>
															</div>
														</div>
													</div>
												)}
												<div style={{ width: '75%' }}>
													<Typography color='textPrimary' variant='body2'>
														Email
													</Typography>
													<TextField
														name='email'
														value={formData.email}
														onChange={(e) => onChange(e)}
														variant='filled'
														fullWidth
														margin='dense'
														autoFocus
														error={!!errorMessages[`email`]}
														helperText={
															errorMessages[`email`] && errorMessages[`email`]
														}
													/>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															marginTop: 24,
														}}
													>
														<Typography
															style={{ marginRight: 8 }}
															color='textPrimary'
															variant='body2'
														>
															Password
														</Typography>
														<Tooltip content={printReqString()} />
													</div>
													<TextField
														name='passwordOne'
														value={formData.passwordOne}
														onChange={(e) => onChange(e)}
														variant='filled'
														fullWidth
														margin='dense'
														type={showPassword ? 'text' : 'password'}
														InputProps={{
															endAdornment: (
																<InputAdornment position='end'>
																	<IconButton
																		aria-label='toggle password visibility'
																		onClick={toggleShowPassword}
																	>
																		{showPassword ? (
																			<Preview />
																		) : (
																			<PreviewHide />
																		)}
																	</IconButton>
																</InputAdornment>
															),
														}}
														error={!!errorMessages[`passwordOne`]}
														helperText={
															errorMessages[`passwordOne`] &&
															errorMessages[`passwordOne`]
														}
													/>
													<Typography
														style={{ marginTop: 24 }}
														color='textPrimary'
														variant='body2'
													>
														Confirm Password
													</Typography>
													<TextField
														name='passwordTwo'
														value={formData.passwordTwo}
														onChange={(e) => onChange(e)}
														variant='filled'
														fullWidth
														margin='dense'
														type={showPassword ? 'text' : 'password'}
														InputProps={{
															endAdornment: (
																<InputAdornment position='end'>
																	<IconButton
																		aria-label='toggle password visibility'
																		onClick={toggleShowPassword}
																	>
																		{showPassword ? (
																			<Preview />
																		) : (
																			<PreviewHide />
																		)}
																	</IconButton>
																</InputAdornment>
															),
														}}
														error={!!errorMessages[`passwordTwo`]}
														helperText={
															errorMessages[`passwordTwo`] &&
															errorMessages[`passwordTwo`]
														}
													/>
													<Button
														className={classes.button}
														disabled={loading}
														type='submit'
														variant='contained'
														color='primary'
													>
														Sign Up
													</Button>
													<Typography variant='subtitle1' color='textSecondary'>
														By clicking on the “Sign Up” button, you are
														creating an account, and you agree to Uuoni’s{' '}
														<a
															style={{ color: theme.palette.tertiary.main }}
															href='https://www.uuoni.com/terms'
															target='_blank'
															rel='noopener'
														>
															Terms of Use
														</a>{' '}
														and{' '}
														<a
															style={{ color: theme.palette.tertiary.main }}
															href='https://www.uuoni.com/privacy'
															target='_blank'
															rel='noopener'
														>
															Privacy Policy
														</a>
														.
													</Typography>
												</div>
											</form>
											{/* <div style={{ flex: '0 0 24px', flexGrow: 1 }} /> */}
										</div>
									</>
								)}
								<div
									style={{
										width: '100%',
										display: 'flex',
										alignItems: 'center',
										position: 'relative',
										justifyContent: 'flex-end',
										margin: '0px auto',
										height: 30,
									}}
								>
									<Typography variant='body1' color='textSecondary'>
										© {new Date().getFullYear()} Uuoni Pte Ltd, All rights
										reserved.
									</Typography>
								</div>
							</div>
						) : (
							<>
								{credentials.authUser && !credentials.verified ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											height: '100%',
											padding: '32px 24px',
											alignItems: 'center',
										}}
									>
										<div style={{ width: 300 }}>
											<Verification />
										</div>
										<Typography variant='h1' style={{ textAlign: 'center' }}>
											Verify your email address
										</Typography>
										<div
											style={{
												textAlign: 'center',
												marginTop: 24,
												maxWidth: 340,
											}}
										>
											<div>
												<Typography variant='body1'>
													We have sent a verification email to the entered email
													address.
												</Typography>
												<Typography
													variant='body1'
													style={{
														marginTop: 8,
													}}
												>
													If you have not received the verification email,
													please check your "Spam" folder. To have another
													verification email send to you, click on "Resend
													Email' below.
												</Typography>
											</div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Button
													className={classes.button}
													variant='outlined'
													color='primary'
													onClick={handleSignOut}
												>
													Back to Login
												</Button>
												<Button
													className={classes.button}
													style={{ marginLeft: 16 }}
													variant='contained'
													color='primary'
													disabled={timeLeft && timeLeft > 0 ? true : false}
													onClick={() => resendVerificationEmail()}
												>
													{timeLeft && timeLeft > 0
														? `Try again in ${timeLeft}`
														: 'Resend Email'}
												</Button>
											</div>
										</div>
									</div>
								) : (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											height: '100%',
											padding: '32px 24px',
										}}
									>
										<Typography variant='h1'>Create your account</Typography>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<Typography color='textSecondary' variant='body1'>
												Already have an account?
											</Typography>
											<Button
												style={{ padding: 8 }}
												component={RouterLink}
												to='/login'
												variant='text'
												color='primary'
											>
												Log In
											</Button>
										</div>
										<div style={{ maxWidth: 340, alignSelf: 'center' }}>
											<div className='firebaseUiMobile'>
												<StyledFirebaseAuth
													uiConfig={uiConfig}
													firebaseAuth={firebase.auth()}
												/>
											</div>
											<form
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
												}}
												onSubmit={onSubmit}
											>
												<div
													className='login-divider'
													style={{
														width: '100%',
														margin: '16px 0px',
													}}
												>
													<span className='dividerText'>Or sign up with</span>
												</div>
												{error && (
													<div
														style={{
															backgroundColor: '#FCE9E9',
															padding: '16px 24px',
															borderRadius: 4,
															marginBottom: 24,
														}}
													>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
															}}
														>
															<HighlightOffIcon style={{ color: '#F60C36' }} />
															<div style={{ marginLeft: 16 }}>
																<Typography
																	style={{
																		color: theme.palette.error.main,
																		marginBottom: 8,
																	}}
																	variant='body2'
																>
																	Error
																</Typography>
																<Typography
																	style={{ color: theme.palette.error.main }}
																	variant='body1'
																>
																	An error occurred!
																</Typography>
															</div>
														</div>
													</div>
												)}
												<div>
													<Typography color='textPrimary' variant='body2'>
														Email
													</Typography>
													<TextField
														name='email'
														value={formData.email}
														onChange={(e) => onChange(e)}
														variant='filled'
														fullWidth
														margin='dense'
														autoFocus
														error={!!errorMessages[`email`]}
														helperText={
															errorMessages[`email`] && errorMessages[`email`]
														}
													/>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															marginTop: 24,
														}}
													>
														<Typography
															style={{ marginRight: 8 }}
															color='textPrimary'
															variant='body2'
														>
															Password
														</Typography>
														<Tooltip content={printReqString()} />
													</div>
													<TextField
														name='passwordOne'
														value={formData.passwordOne}
														onChange={(e) => onChange(e)}
														variant='filled'
														fullWidth
														margin='dense'
														type={showPassword ? 'text' : 'password'}
														InputProps={{
															endAdornment: (
																<InputAdornment position='end'>
																	<IconButton
																		aria-label='toggle password visibility'
																		onClick={toggleShowPassword}
																	>
																		{showPassword ? (
																			<Preview />
																		) : (
																			<PreviewHide />
																		)}
																	</IconButton>
																</InputAdornment>
															),
														}}
														error={!!errorMessages[`passwordOne`]}
														helperText={
															errorMessages[`passwordOne`] &&
															errorMessages[`passwordOne`]
														}
													/>
													<Typography
														style={{ marginTop: 24 }}
														color='textPrimary'
														variant='body2'
													>
														Confirm Password
													</Typography>
													<TextField
														name='passwordTwo'
														value={formData.passwordTwo}
														onChange={(e) => onChange(e)}
														variant='filled'
														fullWidth
														margin='dense'
														type={showPassword ? 'text' : 'password'}
														InputProps={{
															endAdornment: (
																<InputAdornment position='end'>
																	<IconButton
																		aria-label='toggle password visibility'
																		onClick={toggleShowPassword}
																	>
																		{showPassword ? (
																			<Preview />
																		) : (
																			<PreviewHide />
																		)}
																	</IconButton>
																</InputAdornment>
															),
														}}
														error={!!errorMessages[`passwordTwo`]}
														helperText={
															errorMessages[`passwordTwo`] &&
															errorMessages[`passwordTwo`]
														}
													/>
													<Button
														className={classes.button}
														style={{ margin: '32px 0px 24px 0px' }}
														disabled={loading}
														type='submit'
														variant='contained'
														color='primary'
													>
														Sign Up
													</Button>
													<Typography variant='subtitle1' color='textSecondary'>
														By clicking on the “Sign Up” button, you are
														creating an account, and you agree to Uuoni’s{' '}
														<a
															style={{ color: theme.palette.tertiary.main }}
															href='https://www.uuoni.com/terms'
															target='_blank'
															rel='noopener'
														>
															Terms of Use
														</a>{' '}
														and{' '}
														<a
															style={{ color: theme.palette.tertiary.main }}
															href='https://www.uuoni.com/privacy'
															target='_blank'
															rel='noopener'
														>
															Privacy Policy
														</a>
														.
													</Typography>
												</div>
											</form>
										</div>
									</div>
								)}
							</>
						)}
					</>
				);
			}}
		</AuthUserContext.Consumer>
	);
};

const RegisterForm = compose(
	withRouter,
	withFirebase,
	withStyles(styles, { withTheme: true })
)(Register);

export default RegisterPage;

export { RegisterForm };
