import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import NavPrompt from '../../../../components/NavPrompt';
import PrincipalList from '../../../../components/Data/principalData.json';
import api from '../../../../config/api';
import { Underwriter } from '../../../../utils/validators/schemas';
import { Save } from '../../../../components/Icons';

const useStyles = makeStyles((theme) => ({}));

const getPrincipalNameByCode = (code) => {
	let principal = PrincipalList.find((item) => item.code === code);
	return principal.name;
};

export default function UnderwriterForm(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [underwriter, setUnderwriter] = useState(props.underwriter);
	const [principals, setPrincipals] = useState([]);

	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(true);
	const [modified, setModified] = useState(false);

	useEffect(() => {
		getData();
	}, [props]);

	const getData = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('principals/list/active')
			.then((res) => {
				setPrincipals(res.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleValidation = () => {
		let result = Underwriter.validate(underwriter, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	useEffect(() => {
		if (!loading && !modified) {
			setModified(true);
		}
	}, [underwriter]);

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;
		if (name === 'principalId') {
			let val = principals.find((principal) => principal._id === value);
			if (val) {
				setUnderwriter((prev) => ({
					...prev,
					principalId: val._id,
					principalName: val.shortName,
				}));
			} else {
				setUnderwriter((prev) => ({
					...prev,
					principalId: '',
					principalName: '',
				}));
			}
		} else {
			setUnderwriter((prev) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	const handleSave = async (e) => {
		try {
			e.preventDefault();
			setLoading(true);

			let valid = handleValidation();

			if (valid) {
				if (props.action === 'add') {
					api.post('underwriters', underwriter).then((res) => {
						if (res.data.ok) {
							setErrorMessages({});
							setLoading(false);
							setModified(false);
							toast.success('Contact created!');
							props.handleReturn();
						} else {
							setLoading(false);
							toast.error(res.data.error);
						}
					});
				} else {
					api
						.put('underwriters/' + underwriter._id, underwriter)
						.then((res) => {
							if (res.data.ok) {
								setErrorMessages({});
								setLoading(false);
								setModified(false);
								toast.success('Contact updated!');
								props.handleReturn();
							} else {
								setLoading(false);
								toast.error(res.data.error);
							}
						});
				}
			} else {
				setLoading(false);
				toast.error('An error has occured!');
			}
		} catch (error) {
			console.log('error', error);
			setLoading(false);
		}
	};

	return (
		<>
			<NavPrompt when={modified} />
			<div
				style={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}
			>
				<Typography variant='h3' style={{ marginBottom: 24 }}>
					{props.action === 'add' ? 'Add New' : 'Update'} Contact
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={3}>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2'>
								Company
							</Typography>
							<Typography
								style={{ marginLeft: 2 }}
								color='error'
								variant='body2'
								align='right'
							>
								*
							</Typography>
						</Grid>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<FormControl
								style={{ width: '100%' }}
								error={!!errorMessages[`principalId`]}
							>
								<Select
									native
									name='principalId'
									variant='filled'
									autoWidth={true}
									fullWidth
									margin='dense'
									onChange={(e) => handleInputChange(e)}
									value={underwriter.principalId}
									style={{ marginTop: 8, marginBottom: 4 }}
								>
									<option key={0} value=''></option>
									{principals.map((principal, index) => {
										return (
											<option key={index} value={principal._id}>
												{principal.shortName}
											</option>
										);
									})}
								</Select>
								<FormHelperText>
									{errorMessages[`principalId`] && errorMessages[`principalId`]}
								</FormHelperText>
							</FormControl>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Department
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='department'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleInputChange(e)}
								value={underwriter.department}
								error={!!errorMessages[`department`]}
								helperText={
									errorMessages[`department`] && errorMessages[`department`]
								}
							/>
						)}
					</Grid>
					<Box width='100%' />
					<Grid item xs={3}>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								First Name
							</Typography>
							<Typography
								style={{ marginLeft: 2 }}
								color='error'
								variant='body2'
								align='right'
							>
								*
							</Typography>
						</Grid>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='firstName'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleInputChange(e)}
								value={underwriter.firstName}
								error={!!errorMessages[`firstName`]}
								helperText={
									errorMessages[`firstName`] && errorMessages[`firstName`]
								}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Middle Name
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='middleName'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleInputChange(e)}
								value={underwriter.middleName}
								error={!!errorMessages[`middleName`]}
								helperText={
									errorMessages[`middleName`] && errorMessages[`middleName`]
								}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Last Name
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='lastName'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleInputChange(e)}
								value={underwriter.lastName}
								error={!!errorMessages[`lastName`]}
								helperText={
									errorMessages[`lastName`] && errorMessages[`lastName`]
								}
							/>
						)}
					</Grid>
					<Box width='100%' />
					<Grid item xs={3}>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								Email
							</Typography>
							<Typography
								style={{ marginLeft: 2 }}
								color='error'
								variant='body2'
								align='right'
							>
								*
							</Typography>
						</Grid>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='email'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleInputChange(e)}
								value={underwriter.email}
								error={!!errorMessages[`email`]}
								helperText={errorMessages[`email`] && errorMessages[`email`]}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Mobile No.
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='mobileNo'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleInputChange(e)}
								value={underwriter.mobileNo}
								error={!!errorMessages[`mobileNo`]}
								helperText={
									errorMessages[`mobileNo`] && errorMessages[`mobileNo`]
								}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Office No.
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='officeNo'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleInputChange(e)}
								value={underwriter.officeNo}
								error={!!errorMessages[`officeNo`]}
								helperText={
									errorMessages[`officeNo`] && errorMessages[`officeNo`]
								}
							/>
						)}
					</Grid>
				</Grid>
				<div
					className='formFooter'
					style={{ display: 'flex', marginTop: 32, marginBottom: 32 }}
				>
					<Button
						onClick={() => props.handleReturn()}
						color='primary'
						variant='outlined'
					>
						Cancel
					</Button>
					<Button
						style={{ marginLeft: 8 }}
						variant='contained'
						color='primary'
						disabled={loading}
						startIcon={
							<Save
								style={{ fontSize: 24 }}
								colorcode={
									loading
										? theme.palette.grey[500]
										: theme.palette.background.paper
								}
							/>
						}
						onClick={(e) => handleSave(e)}
					>
						{props.action === 'add' ? 'Add' : 'Update'}
					</Button>
				</div>
			</div>
		</>
	);
}
