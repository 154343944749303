import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Import from './import';
import Principals from './principals';
import Underwriters from './underwriters';

function TabPanel(props) {
	const { children, value, selected, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== selected}
			id={`full-width-tabpanel-${selected}`}
			aria-labelledby={`full-width-tab-${selected}`}
			{...other}
		>
			{value === selected && <Box p={3}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginLeft: 24,
	},
	tabRoot: {
		minWidth: 10,
	},
}));

const options = [
	{
		label: 'Representing Principals',
		endpoint: 'representing-principals',
	},
	{
		label: 'Account Service Contacts',
		endpoint: 'contacts',
	},
];

export default function PrincipalSettings(props) {
	const { subType } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [selected, setSelected] = useState('representing-principals');

	useEffect(() => {
		if (subType) {
			if (options.some((option) => subType === option.endpoint))
				setSelected(subType);
		} else {
			setSelected('representing-principals');
		}
	}, [props]);

	return (
		<>
			<div className={classes.root}>
				<Tabs value={selected} aria-label='full width tabs example'>
					{options.map((option, index) => (
						<Tab
							classes={{ root: classes.tabRoot }}
							key={option.endpoint}
							label={option.label}
							value={option.endpoint}
							component={Link}
							to={'/settings/principals/' + option.endpoint}
							{...a11yProps(index)}
						/>
					))}
				</Tabs>
			</div>
			<Divider />

			<Box width='100%' style={{ height: 'inherit', overflowY: 'auto' }}>
				<TabPanel
					value={'representing-principals'}
					index={0}
					selected={selected}
					dir={theme.direction}
				>
					<Principals />
				</TabPanel>
				<TabPanel
					value={'contacts'}
					index={1}
					selected={selected}
					dir={theme.direction}
				>
					<Underwriters />
				</TabPanel>
				{/* <TabPanel value={'import-underwriters'} index={2} selected={selected} dir={theme.direction}>
					<Import />
				</TabPanel> */}
			</Box>
		</>
	);
}
