import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import '../styles.css';

export default function deleteTransactions(props) {
	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Delete Transaction(s)
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					disableRipple
					disableFocusRipple
					disableTouchRipple
					onClick={props.handleClose}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<Typography
				variant='body1'
				color='textPrimary'
				style={{ marginTop: 24, marginBottom: 24 }}
			>
				Are you sure you want to delete the selected transaction(s)?
			</Typography>
			<Typography
				variant='body1'
				color='textPrimary'
				style={{ marginTop: 24, color: props.theme.palette.error.main }}
			>
				Note: This action cannot be undone and all related{' '}
				<b>Requests and Invoices</b> will be deleted.
			</Typography>
			<div
				style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}
			>
				<Button
					style={{ marginRight: 16 }}
					onClick={props.handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={props.handleConfirm}
					variant='contained'
					style={{
						backgroundColor: props.theme.palette.error.main,
						color: props.theme.palette.background.default,
					}}
				>
					Delete
				</Button>
			</div>
		</>
	);
}
