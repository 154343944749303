const { isEmpty, isEqual, isPlainObject } = require('lodash');

const isDate = (value) => {
	// Check if it's a Date object and convert it to ISO string
	if (value instanceof Date) {
		return !isNaN(value.getTime()) && value.toISOString() !== 'Invalid Date';
	}

	// Check if it's a valid date string
	if (typeof value === 'string') {
		const parsedDate = new Date(value);

		// Ensure the parsed date is valid and can be converted to an ISO string
		return (
			!isNaN(parsedDate.getTime()) &&
			parsedDate.toISOString() !== 'Invalid Date'
		);
	}

	return false;
};

const compareArray = (parentKey, oldList, newList) => {
	const comparison = {};

	oldList.forEach((oldItem) => {
		const key = oldItem.key;
		const newItem = newList.find((item) => item.key === key);

		if (!newItem) {
			if (parentKey === 'fileList') {
				comparison[oldItem.documentName] = {
					action: 'Removed',
					value: oldItem,
				};
			} else {
				comparison[key] = { action: 'Removed', value: oldItem };
			}
		} else {
			const changes = {};

			for (const prop in oldItem) {
				if (oldItem[prop] !== newItem[prop]) {
					changes[prop] = {
						old: oldItem[prop],
						new: newItem[prop],
					};
				}
			}

			if (Object.keys(changes).length > 0) {
				if (parentKey === 'fileList') {
					comparison[newItem.documentName] = changes;
				} else {
					comparison[key] = changes;
				}
			}
		}
	});

	newList.forEach((newItem) => {
		const key = newItem.key;
		const oldItem = oldList.find((item) => item.key === key);

		if (!oldItem) {
			if (parentKey === 'fileList') {
				comparison[newItem.documentName] = { action: 'Added', value: newItem };
			} else {
				comparison[key] = { action: 'Added', value: newItem };
			}
		}
	});
	return comparison;
};

const diff = (updated, current) => {
	// Top level Array-to-Array comparison logic (i.e. for Quotations)
	if (Array.isArray(updated) && Array.isArray(current)) {
		const results = {};

		updated.forEach((quote) => {
			const quoteIndex = current.findIndex(
				(currentQuote) => currentQuote.quoteName === quote.quoteName
			);

			if (quoteIndex === -1) {
				results[quote.quoteName] = {
					action: 'Added',
					// value: quote,
				};
			} else {
				const differences = Object.keys(quote).reduce((result, key) => {
					if (key === 'open' || key === 'id') {
						// Ignore open state
						return result;
					}
					if (key === 'fileData') {
						result[key] = { action: 'Updated' };
					} else if (!isEqual(quote[key], current[quoteIndex][key])) {
						if (isDate(quote[key]) || isDate(current[quoteIndex][key])) {
							const updatedDate = new Date(quote[key]);
							const currentDate = new Date(current[quoteIndex][key]);
							if (updatedDate.getTime() !== currentDate.getTime()) {
								result[key] = {
									old: current[quoteIndex][key],
									new: updatedDate.toISOString(),
								};
							}
						} else {
							result[key] = { old: current[quoteIndex][key], new: quote[key] };
						}
					}
					return result;
				}, {});

				if (Object.keys(differences).length > 0) {
					// Add logic to handle changes here
					results[quote.quoteName] = differences;
				}
			}
		});

		current.forEach((quote) => {
			const quoteIndex = updated.findIndex(
				(updatedQuote) => updatedQuote.quoteName === quote.quoteName
			);

			if (quoteIndex === -1) {
				results[quote.quoteName] = {
					action: 'Removed',
					// value: quote,
				};
			}
		});

		// Return the comparison result or perform additional actions based on added/removed quotes
		return results;
	}

	// If current is empty or undefined, treat all values in updated as new
	if (!current || Object.keys(current).length === 0) {
		return Object.keys(updated).reduce((result, key) => {
			if (key === 'open' || key === 'id') {
				// Ignore open state
				return result;
			}

			const value = updated[key];

			if (Array.isArray(value)) {
				result[key] = compareArray(key, [], value); // Pass an empty array as current[key]
			} else if (isPlainObject(value)) {
				result[key] = diff(value, {}); // Pass an empty object as current[key]
			} else {
				result[key] = value; // Treat all as new values
			}

			return result;
		}, {});
	}

	// Continue with the original object-to-object comparison logic
	return Object.keys(updated).reduce((result, key) => {
		if (key === 'open' || key === 'id') {
			// Ignore open state
			return result;
		}

		const value = updated[key];
		const currentValue = current[key];

		if (Array.isArray(value)) {
			result[key] = compareArray(key, currentValue || [], value);
		} else if (isPlainObject(value)) {
			result[key] = diff(value, currentValue || {});
		} else if (!isEqual(value, currentValue)) {
			if (key === 'fileData') {
				result['Unfilled Form'] = { action: 'Updated' };
			} else if (isDate(value) || isDate(currentValue)) {
				const updatedDate = new Date(value);
				const currentDate = new Date(currentValue);
				if (updatedDate.getTime() !== currentDate.getTime()) {
					result[key] = { old: currentValue, new: updatedDate.toISOString() };
				}
			} else {
				result[key] = { old: currentValue, new: value };
			}
		}
		return result;
	}, {});
};

const removeEmpty = (obj) => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (key === 'fileData') {
				return;
			}
			if (obj[key] === null || obj[key] === undefined || key === 'key') {
				delete obj[key];
			} else if (typeof obj[key] === 'object') {
				removeEmpty(obj[key]);
				if (Object.keys(obj[key]).length === 0) {
					delete obj[key];
				}
			}
		}
	}
	return obj;
};

const getDiff = (updatedData, currentData) => {
	if (!updatedData || !currentData) {
		return null;
	}

	let changedData = removeEmpty(diff(updatedData, currentData));

	if (isEmpty(changedData)) {
		return null;
	}

	return changedData;
};

const getPolicyDocument = (source, policyDocuments) => {
	if (!policyDocuments || !source) {
		return null;
	}

	if (source === 'External') {
		return policyDocuments.find(
			(doc) => doc.documentName === 'External Policy Document'
		);
	}

	return policyDocuments.find((doc) => doc.documentName === 'Policy Document');
};

module.exports = { getDiff, getPolicyDocument };
