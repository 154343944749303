import Joi from 'joi';

const PolicyInfo = Joi.object({
	policyNo: Joi.string()
		.label('Policy Number')
		.trim()
		.min(1)
		.max(50)
		.required(),

	principalId: Joi.string().label('Insurer').trim().min(1).max(50).required(),

	premiumAmt: Joi.number()
		.label('Total Premium')
		.min(0)
		.precision(2)
		.required(),

	sumInsured: Joi.number().label('Sum Insured').min(0).precision(2).required(),

	startDate: Joi.date()
		.label('Start Date')
		.iso()
		.less(Joi.ref('endDate'))
		.messages({
			'date.less': 'Start Date must be less than End Date',
		})
		.required(),

	endDate: Joi.date().label('End Date').iso().required(),

	commission: Joi.number()
		.label("Agent's Commission")
		.min(0)
		.precision(2)
		.required(),

	remarks: Joi.string()
		.label('Remarks')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default PolicyInfo;
