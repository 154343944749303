import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PricingTable from './PricingTable';
import './styles.css';

const useStyles = makeStyles((theme) => ({
	heroText: {
		width: '100%',
		marginBottom: 16,
		'@media screen and (min-width: 768px)': {
			width: '55%',
			margin: '24px auto',
			textAlign: 'center',
		},
	},
}));

export default function PricingPlan(props) {
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const classes = useStyles();
	const theme = useTheme();
	const {
		yearly,
		setYearly,
		quantity,
		setQuantity,
		setSelectedPlan,
		isExistingCustomer,
	} = props;

	const toggleYearly = () => {
		if (!yearly) {
			setYearly(true);
		}
		setYearly(!yearly);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography variant='h2' className={classes.heroText}>
				Build and nurture relationships, close transactions faster and measure
				successes that comes along.
			</Typography>
			<div
				className='cd-pricing-switcher'
				style={{ marginBottom: !isDesktopOrLaptop && 16 }}
			>
				<p className='fieldset'>
					<input
						type='radio'
						name='duration'
						value='monthly'
						id='monthly'
						onClick={() => toggleYearly()}
						onChange={(e) => {}}
						checked={!yearly}
					/>
					<label htmlFor='monthly'>Pay Monthly</label>
					<input
						type='radio'
						name='duration'
						value='yearly'
						id='yearly'
						onClick={() => toggleYearly()}
						onChange={(e) => {}}
						checked={yearly}
					/>
					<label htmlFor='yearly'>Pay Yearly</label>
					<span className='cd-switch'></span>
				</p>
			</div>

			<PricingTable
				yearly={yearly}
				setSelectedPlan={setSelectedPlan}
				quantity={quantity}
				setQuantity={setQuantity}
				isExistingCustomer={isExistingCustomer}
			/>

			<Typography
				variant='subtitle1'
				style={{
					margin: '16px auto 0',
					textAlign: 'center',
					color: theme.palette.text.secondary,
				}}
			>
				*You will not be charged until your free trial expires. We will notify
				you 7 days before trial expiry. No commitments, you can cancel anytime.
			</Typography>
		</div>
	);
}
