import Joi from 'joi';

const Register = Joi.object({
	email: Joi.string()
		.label('Email')
		.trim()
		.email({ tlds: { allow: false } })
		.required(),
	passwordOne: Joi.string()
		.label('Password')
		.trim()
		.pattern(
			new RegExp(
				'^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
			)
		)
		.required()
		.messages({
			'string.pattern.base': 'Invalid Password',
			'any.ref': 'Passwords do not match',
		}),
	passwordTwo: Joi.string()
		.required()
		.valid(Joi.ref('passwordOne'))
		.messages({ 'any.only': 'Passwords do not match' }),
}).with('passwordOne', 'passwordTwo');

export default Register;
