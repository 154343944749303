import Joi from 'joi';

const AssociatedContact = Joi.object({
	firstName: Joi.string().label('First Name').trim().min(1).max(50).required(),
	lastName: Joi.string()
		.label('Last Name')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	email: Joi.string()
		.label('Email')
		.trim()
		.email({ tlds: { allow: false } })
		.required(),
	mobileNo: Joi.string()
		.label('Mobile Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Mobile Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
}).unknown(true);

export default AssociatedContact;
