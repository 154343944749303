import React, { useState, useEffect } from 'react';
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../config/api';
import { useMediaQuery } from 'react-responsive';

const COLORS = [
	'#7E909A',
	'#274C77',
	'#6096BA',
	'#71A5DE',
	'#A3CEF1',
	'#CAE3F7',
];

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: 16,
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
		borderRadius: 4,
	},
	filterMenu: {
		padding: 16,
	},
}));

export default function GrossPremium() {
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [frequency, setFrequency] = useState('yearly');
	const [anchorEl, setAnchorEl] = useState(null);

	const initFilters = (dataset) => {
		let sortedList = [...dataset].sort((a, b) => b.total - a.total);
		let filterList = sortedList.map((data, index) => {
			if (index < 3) {
				return {
					...data,
					checked: true,
				};
			} else {
				return {
					...data,
					checked: false,
				};
			}
		});
		setData(filterList);
	};

	const getData = () => {
		if (!loading) {
			setLoading(true);
			setData(null);
		}
		api
			.get('widgets/gross-premium/?freq=' + frequency)
			.then((response) => {
				if (response.data.ok) {
					initFilters(response.data.data);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, [frequency]);

	const handleFilterChange = (e, index) => {
		let updatedData = [...data];
		updatedData.find((el) => el.name === e.target.name).checked =
			e.target.checked;
		setData(updatedData);
	};

	const labelFormatter = (value) => {
		return '$' + value.toLocaleString();
	};

	const getDataset = () => {
		let filteredDataset = data.filter((obj) => obj.checked === true);
		return filteredDataset;
	};

	const getItems = () => {
		const items = [];
		const uniqObj = {};
		let filteredDataset = data.filter((obj) => obj.checked === true);

		filteredDataset.forEach((entry) => {
			Object.keys(entry).forEach((item) => {
				if (!uniqObj[item]) items.push(item);
				uniqObj[item] = true;
			});
		});

		items.splice(
			items.findIndex((item) => item === 'name'),
			1
		);

		items.splice(
			items.findIndex((item) => item === 'checked'),
			1
		);

		items.splice(
			items.findIndex((item) => item === 'total'),
			1
		);

		return items;
	};

	return (
		<div className={classes.paper}>
			{/* <ResponsiveContainer width='100%' height='100%'> */}
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography variant='h4' color='textPrimary'>
					Gross Premium
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					onClick={(e) => setAnchorEl(e.currentTarget)}
				>
					<TuneOutlinedIcon />
				</IconButton>
				<Popover
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					open={Boolean(anchorEl)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<div className={classes.filterMenu}>
						<div>
							<Typography variant='body2' color='textSecondary'>
								Frequency
							</Typography>
							<Select
								native
								name='frequency'
								value={frequency}
								onChange={(e) => setFrequency(e.target.value)}
								variant='filled'
								fullWidth
								margin='dense'
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option key={0} value='yearly'>
									Yearly
								</option>
								<option key={1} value='monthly'>
									Monthly
								</option>
								<option key={2} value='weekly'>
									Weekly
								</option>
								<option key={3} value='daily'>
									Today
								</option>
							</Select>
						</div>
						<div style={{ marginTop: 16 }}>
							<Typography variant='body2' color='textSecondary'>
								Select Insurance Types
							</Typography>
							<FormControl component='fieldset' className={classes.formControl}>
								{/* <FormLabel component='legend'>Select Insurance Types</FormLabel> */}
								<FormGroup
									style={{
										maxHeight: 300,
										overflowY: 'auto',
										flexWrap: 'unset',
									}}
								>
									{data &&
										data.map((obj, index) => (
											<FormControlLabel
												key={index}
												control={
													<Checkbox
														checked={obj.checked}
														onChange={(e) => handleFilterChange(e, index)}
														name={obj.name}
													/>
												}
												label={obj.name}
											/>
										))}
								</FormGroup>
								{/* <FormHelperText>Be careful</FormHelperText> */}
							</FormControl>
						</div>
					</div>
				</Popover>
			</div>
			{!data || getDataset().length <= 0 ? (
				<div style={{ display: 'flex', width: '100%', height: '100%' }}>
					{loading ? (
						<CircularProgress style={{ margin: 'auto' }} />
					) : (
						<Typography
							style={{ margin: 'auto' }}
							variant='body1'
							color='textPrimary'
						>
							No Data Available
						</Typography>
					)}
				</div>
			) : (
				<ResponsiveContainer>
					<BarChart
						width={500}
						height={500}
						data={getDataset()}
						margin={{
							top: 32,
							right: isDesktopOrLaptop ? 0 : 32,

							left: 32,
						}}
					>
						<XAxis dataKey='name' />
						<YAxis tickFormatter={(value) => '$' + value.toLocaleString()} />
						<Tooltip formatter={(value) => '$' + value.toLocaleString()} />
						<Legend
							formatter={(value, entry, index) => (
								<span style={{ color: '#000000' }}>{value}</span>
							)}
							iconType='circle'
							iconSize={10}
							layout={isDesktopOrLaptop ? 'vertical' : 'horizontal'}
							align={isDesktopOrLaptop ? 'right' : 'center'}
							verticalAlign={isDesktopOrLaptop ? 'center' : 'bottom'}
							wrapperStyle={
								isDesktopOrLaptop && {
									width: '35%',
									paddingLeft: 32,
									top: '50%',
									transform: 'translate(0, -50%)',
								}
							}
						/>
						{getItems().map((itemName, index) => (
							<Bar
								key={index}
								dataKey={itemName}
								stackId='a'
								maxBarSize={72}
								fill={COLORS[index + (index + 1)]}
								label={
									index + 1 === getItems().length && {
										position: 'top',
										formatter: labelFormatter,
									}
								}
							/>
						))}
					</BarChart>
				</ResponsiveContainer>
			)}
		</div>
	);
}
