import Joi from 'joi';

const Organization = Joi.object({
	name: Joi.string()
		.label('Organization Name')
		.trim()
		.min(1)
		.max(50)
		.required(),
	uen: Joi.string().label('UEN').trim().min(1).max(50).required(),
	website: Joi.string()
		.label('Website')
		.trim()
		.pattern(
			new RegExp(
				/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/i
			)
		)
		.optional()
		.allow('', null)
		.messages({
			'string.pattern.base': 'Invalid Website',
		}),
	email: Joi.string()
		.label('Email')
		.trim()
		.email({ tlds: { allow: false } })
		.optional()
		.allow('', null),
	officeNo: Joi.string()
		.label('Office Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Office Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow('', null),
	faxNo: Joi.string()
		.label('Fax Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Fax Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow('', null),
	address: Joi.string()
		.label('Address')
		.trim()
		.min(1)
		.max(100)
		.optional()
		.allow('', null),
	city: Joi.string().label('City').trim().min(1).max(50).optional().allow(''),
	unitNo: Joi.string()
		.label('Unit Number')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow('', null),
	postalCode: Joi.string()
		.label('Postal Code')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow('', null),
}).unknown(true);

export default Organization;
