import React, { useEffect, useState } from 'react';
import DataGrid from 'react-data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	tableRow: {},
	tableCell: {
		padding: 6,
	},
}));

const Step3 = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const [columns, setColumns] = useState([]);

	useEffect(() => {
		generateColumns();
	}, []);

	const generateColumns = () => {
		let updatedColumns = [...columns];
		props.headers.map(function (currentHeader, i) {
			let column = { key: currentHeader, name: currentHeader };
			updatedColumns = [...updatedColumns, column];
		});
		setColumns(updatedColumns);
	};

	const EmptyRowsRenderer = () => {
		return <div style={{ textAlign: 'center' }}>No Rows found.</div>;
	};

	const emptyRows = 5 - Math.min(5, props.previewList.length);

	return (
		<>
			<Typography variant='h3'>Preview of Uploaded File</Typography>
			<Typography variant='body1' style={{ marginTop: 16 }}>
				Only the first 5 rows are shown. Please ensure that your external file's
				Header is in the first row.
			</Typography>
			<div style={{ marginTop: 32, width: '100%', overflowX: 'auto' }}>
				<table className='tableContent' cellSpacing='0'>
					<thead>
						<tr
							style={{
								height: 52,
								backgroundColor: theme.palette.background.paper,
							}}
						>
							{columns.map((column, index) => (
								<th
									key={index}
									style={{
										backgroundColor: !column.name && theme.palette.grey[200],
									}}
								>
									<div className='aligner'>
										<div className='text'>
											{column.name ? column.name : '[BLANK]'}
										</div>
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{props.previewList.map((record, index) => (
							<TableRow className={classes.tableRow} key={index}>
								{Object.entries(record).map(([key, value], index) => (
									<TableCell
										className={classes.tableCell}
										style={{
											backgroundColor: !value && theme.palette.grey[200],
										}}
										key={index}
									>
										{value ? value : '[BLANK]'}
									</TableCell>
								))}
							</TableRow>
						))}
						{emptyRows > 0 && emptyRows < 5 && (
							<tr
								style={{
									height: 64 * emptyRows,
								}}
							>
								<td
									colSpan={columns.length}
									style={{
										border: '1px solid #edf2f7',
									}}
								/>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Step3;
