import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../config/api';
import { getFromLS } from '../../../utils/storageHelper';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',

		padding: '16px 0px',
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
		borderRadius: 4,
	},
	tableContainer: {
		maxHeight: '85%',
	},
	tableHeader: {
		backgroundColor: theme.palette.background.paper,
		padding: '8px 16px',
	},
	tableCell: {
		padding: '8px 16px',
		borderBottom: 'unset',
	},
}));

export default function RecentlyViewed(props) {
	const { uid } = props;

	const classes = useStyles();
	const theme = useTheme();

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!loading) {
			setLoading(true);
		}
		let list = getFromLS(`recentlyViewed-${uid}`);
		if (list) {
			setData(list.reverse());
		}
		setLoading(false);
	}, []);

	const getLink = (obj) => {
		switch (obj.type) {
			case 'contact':
				return (
					<Link
						component={RouterLink}
						color='primary'
						to={'/contacts/' + obj._id}
					>
						{obj.name}
					</Link>
				);
			case 'company':
				return (
					<Link
						component={RouterLink}
						color='primary'
						to={'/companies/' + obj._id}
					>
						{obj.name}
					</Link>
				);
			case 'transaction':
				return (
					<Link
						component={RouterLink}
						color='primary'
						to={'/transactions/' + obj._id}
					>
						TXN-{obj.name}
					</Link>
				);
			case 'invoice':
				return (
					<Link
						component={RouterLink}
						color='primary'
						to={'/invoices/' + obj._id}
					>
						INV-{obj.name}
					</Link>
				);
		}
	};

	const recentList = () => {
		return data.map((obj, i) => {
			return (
				<TableRow key={i} style={{ height: 'unset' }}>
					<TableCell className={classes.tableCell}>{getLink(obj)}</TableCell>
					<TableCell className={classes.tableCell}>
						<Typography
							style={{ textTransform: 'capitalize' }}
							color='textPrimary'
							variant='body1'
							align='left'
						>
							{obj.type}
						</Typography>
					</TableCell>
				</TableRow>
			);
		});
	};

	return (
		<div className={classes.paper}>
			<Typography
				style={{ padding: '0px 16px 16px 16px' }}
				variant='h4'
				color='textPrimary'
			>
				Recently Viewed
			</Typography>
			{!data ? (
				<div style={{ display: 'flex', width: '100%', height: '100%' }}>
					{loading ? (
						<CircularProgress style={{ margin: 'auto' }} />
					) : (
						<Typography
							style={{ margin: 'auto' }}
							variant='body1'
							color='textPrimary'
						>
							No Data Available
						</Typography>
					)}
				</div>
			) : (
				<div style={{ width: '100%', height: '100%' }}>
					<TableContainer className={classes.tableContainer}>
						<Table stickyHeader>
							<TableHead>
								<TableRow style={{ height: 'unset' }}>
									<TableCell
										className={classes.tableHeader}
										style={{ width: '40%' }}
									>
										Item
									</TableCell>
									<TableCell
										className={classes.tableHeader}
										style={{ width: '20%' }}
									>
										Type
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>{recentList()}</TableBody>
						</Table>
					</TableContainer>
				</div>
			)}
		</div>
	);
}
