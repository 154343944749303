import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { cloneDeep } from 'lodash';
import matchSorter from 'match-sorter';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../config/api';
import DataSearch from '../DataSearch';
import {
	AssociatedCompany,
	AssociatedContact,
} from '../../utils/validators/schemas';
import { combineNames } from '../../utils/combineNames';
import { Company, Delete } from '../Icons';

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const useStyles = makeStyles((theme) => ({
	paper: {
		width: 560,
		padding: 32,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
}));

const str2bool = (value) => {
	if (value && typeof value === 'string') {
		if (value.toLowerCase() === 'true') return true;
		if (value.toLowerCase() === 'false') return false;
	}
	return value;
};

const initCompanyForm = {
	companyName: '',
	uen: '',
};

const initContactForm = {
	firstName: '',
	lastName: '',
	email: '',
	mobileNo: '',
};

const AddAssociatedCompany = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const [existing, setExisting] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [formData, setFormData] = useState(cloneDeep(initCompanyForm));
	const [isPrimaryCompany, setIsPrimaryCompany] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});

	const handleExisting = (e) => {
		let val = str2bool(e.target.value);
		setExisting(val);
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;

		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleValidation = () => {
		let result = AssociatedCompany.validate(formData, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			console.log('handleSubmit');
			if (!existing) {
				let valid = handleValidation();

				if (valid) {
					let companyData = {
						data: formData,
					};
					await api.post('companies', companyData).then(async (res) => {
						if (res.data.ok) {
							toast.success('Company created!');
							let assocData = {
								contactId: props.contactId,
								companyId: res.data.data._id,

								isPrimaryCompany: isPrimaryCompany,
								clientType: 'contact',
							};
							await api.post('associations', assocData).then((res) => {
								if (res.data.ok) {
									toast.success('Association created!');
									props.setOpen(false);
									props.setTriggerListUpdate(true);
									props.togglePaneUpdate();
								} else {
									toast.error(res.data.error);
								}
							});
						} else {
							toast.error(res.data.error);
						}
					});
				} else {
					toast.error('An error has occured!');
				}
			} else {
				let assocData = {
					contactId: props.contactId,
					companyId: selectedCompany._id,

					isPrimaryCompany: isPrimaryCompany,
					clientType: 'contact',
				};
				await api.post('associations', assocData).then((res) => {
					if (res.data.ok) {
						toast.success('Association created!');
						props.setOpen(false);
						props.setTriggerListUpdate(true);
						props.togglePaneUpdate();
					} else {
						toast.error(res.data.error);
					}
				});
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Add Associated Company
				</Typography>
			</div>
			<div style={{ display: 'flex', width: '100%', marginTop: 24 }}>
				<div>
					<FormControl component='fieldset'>
						<RadioGroup
							aria-label='existing'
							name='existing'
							value={existing}
							onChange={(e) => handleExisting(e)}
						>
							<FormControlLabel
								value={false}
								control={<Radio color='primary' />}
								label='New Company'
							/>
							<FormControlLabel
								value={true}
								control={<Radio color='primary' />}
								label='Existing Company'
							/>
						</RadioGroup>
					</FormControl>
				</div>
			</div>
			<div style={{ width: '100%', marginTop: 16 }}>
				{existing ? (
					<DataSearch
						type='company'
						handleSelectRecord={(option, type) => setSelectedCompany(option)}
					/>
				) : (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography color='textPrimary' variant='body1'>
									Company Name
								</Typography>
								<TextField
									name='companyName'
									value={formData.companyName}
									onChange={handleInputChange}
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`companyName`]}
									helperText={
										errorMessages[`companyName`] && errorMessages[`companyName`]
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography color='textPrimary' variant='body1'>
									Unique Entity Number (UEN)
								</Typography>
								<TextField
									name='uen'
									value={formData.uen}
									onChange={handleInputChange}
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`uen`]}
									helperText={errorMessages[`uen`] && errorMessages[`uen`]}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</div>
			{existing && selectedCompany !== null && (
				<>
					<div style={{ width: '100%', marginTop: 16 }}>
						<div
							style={{
								display: 'flex',
								width: '100%',
								alignItems: 'center',
							}}
						>
							<Avatar aria-label='recipe' className={classes.avatar}>
								<Company
									style={{ fontSize: 24 }}
									colorcode={theme.palette.primary.contrastText}
								/>
							</Avatar>
							<div>
								<Typography
									style={{ marginLeft: 16 }}
									variant='body2'
									color='textPrimary'
								>
									{selectedCompany.companyName}
								</Typography>
								<Typography
									style={{ marginLeft: 16 }}
									variant='body1'
									color='textSecondary'
								>
									UEN: {selectedCompany.uen}
								</Typography>
							</div>
							<IconButton
								style={{ marginLeft: 'auto' }}
								onClick={() => setSelectedCompany(null)}
							>
								<Delete style={{ fontSize: 24 }} />
							</IconButton>
						</div>
					</div>
				</>
			)}
			<div style={{ width: '100%', marginTop: 16 }}>
				<FormControlLabel
					checked={isPrimaryCompany}
					control={
						<Checkbox color='primary' disableRipple disableFocusRipple />
					}
					label={
						<Typography color='textPrimary' variant='body1'>
							Set as Primary Company
						</Typography>
					}
					name='isPrimaryCompany'
					labelPlacement='end'
					onChange={(e) => setIsPrimaryCompany(e.target.checked)}
				/>
			</div>
			<div style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginRight: 'auto' }}
					onClick={props.handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					variant='contained'
					disabled={existing ? selectedCompany === null : false}
					onClick={handleSubmit}
					color='primary'
				>
					Add
				</Button>
			</div>
		</>
	);
};

const AddAssociatedContact = (props) => {
	const classes = useStyles();
	const [existing, setExisting] = useState(false);
	const [selectedContact, setSelectedContact] = useState(null);
	const [formData, setFormData] = useState(cloneDeep(initContactForm));
	const [isPrimaryContact, setIsPrimaryContact] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});

	const handleExisting = (e) => {
		let val = str2bool(e.target.value);
		setExisting(val);
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;

		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleValidation = () => {
		let result = AssociatedContact.validate(formData, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			console.log('handleSubmit');
			if (!existing) {
				let valid = handleValidation();

				if (valid) {
					let contactData = {
						data: formData,
					};
					await api.post('contacts', contactData).then(async (res) => {
						if (res.data.ok) {
							toast.success('Contact created!');
							let assocData = {
								contactId: res.data.data._id,
								companyId: props.companyId,
								isPrimaryContact: isPrimaryContact,

								clientType: 'company',
							};
							await api.post('associations', assocData).then((res) => {
								if (res.data.ok) {
									toast.success('Association created!');
									props.setOpen(false);
									props.setTriggerListUpdate(true);
									props.togglePaneUpdate();
								} else {
									toast.error(res.data.error);
								}
							});
						} else {
							toast.error(res.data.error);
						}
					});
				} else {
					toast.error('An error has occured!');
				}
			} else {
				let assocData = {
					contactId: selectedContact._id,
					companyId: props.companyId,
					isPrimaryContact: isPrimaryContact,

					clientType: 'company',
				};
				await api.post('associations', assocData).then((res) => {
					if (res.data.ok) {
						toast.success('Association created!');
						props.setOpen(false);
						props.setTriggerListUpdate(true);
						props.togglePaneUpdate();
					} else {
						toast.error(res.data.error);
					}
				});
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Add Associated Contact
				</Typography>
			</div>
			<div style={{ display: 'flex', width: '100%', marginTop: 24 }}>
				<div>
					<FormControl component='fieldset'>
						<RadioGroup
							aria-label='existing'
							name='existing'
							value={existing}
							onChange={(e) => handleExisting(e)}
						>
							<FormControlLabel
								value={false}
								control={<Radio color='primary' />}
								label='New Contact'
							/>
							<FormControlLabel
								value={true}
								control={<Radio color='primary' />}
								label='Existing Contact'
							/>
						</RadioGroup>
					</FormControl>
				</div>
			</div>
			<div style={{ width: '100%', marginTop: 16 }}>
				{existing ? (
					<DataSearch
						type='contact'
						handleSelectRecord={(option, type) => setSelectedContact(option)}
					/>
				) : (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Typography color='textPrimary' variant='body2'>
									First Name
								</Typography>
								<TextField
									name='firstName'
									value={formData.firstName}
									onChange={handleInputChange}
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`firstName`]}
									helperText={
										errorMessages[`firstName`] && errorMessages[`firstName`]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color='textPrimary' variant='body2'>
									Last Name
								</Typography>
								<TextField
									name='lastName'
									value={formData.lastName}
									onChange={handleInputChange}
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`lastName`]}
									helperText={
										errorMessages[`lastName`] && errorMessages[`lastName`]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color='textPrimary' variant='body2'>
									Email
								</Typography>
								<TextField
									name='email'
									value={formData.email}
									onChange={handleInputChange}
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`email`]}
									helperText={errorMessages[`email`] && errorMessages[`email`]}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography color='textPrimary' variant='body2'>
									Mobile
								</Typography>
								<TextField
									name='mobileNo'
									value={formData.mobileNo}
									onChange={handleInputChange}
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`mobileNo`]}
									helperText={
										errorMessages[`mobileNo`] && errorMessages[`mobileNo`]
									}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</div>
			{existing && selectedContact !== null && (
				<>
					<div
						style={{
							display: 'flex',
							width: '100%',
							marginTop: 16,
							alignItems: 'center',
						}}
					>
						<Avatar aria-label='recipe' className={classes.avatar}>
							{selectedContact.firstName.charAt(0).toUpperCase()}
						</Avatar>
						<div>
							<Typography
								style={{ marginLeft: 16 }}
								variant='body2'
								color='textPrimary'
							>
								{combineNames({
									fName: selectedContact.firstName,
									mName:
										selectedContact.middleName !== undefined
											? selectedContact.middleName
											: null,
									lName:
										selectedContact.lastName !== undefined
											? selectedContact.lastName
											: null,
								})}
							</Typography>
							<Typography
								style={{ marginLeft: 16 }}
								variant='body1'
								color='textSecondary'
							>
								Email: {selectedContact.email}
							</Typography>
						</div>
						<IconButton
							style={{ marginLeft: 'auto' }}
							onClick={() => setSelectedContact(null)}
						>
							<Delete style={{ fontSize: 24 }} />
						</IconButton>
					</div>
				</>
			)}
			<div style={{ width: '100%', marginTop: 16 }}>
				<FormControlLabel
					checked={isPrimaryContact}
					control={
						<Checkbox color='primary' disableRipple disableFocusRipple />
					}
					label={
						<Typography color='textPrimary' variant='body1'>
							Set as Primary Contact
						</Typography>
					}
					name='isPrimaryContact'
					labelPlacement='end'
					onChange={(e) => setIsPrimaryContact(e.target.checked)}
				/>
			</div>
			<div style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginRight: 'auto' }}
					onClick={props.handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					variant='contained'
					disabled={existing ? selectedContact === null : false}
					onClick={handleSubmit}
					color='primary'
				>
					Add
				</Button>
			</div>
		</>
	);
};

const getBody = (props) => {
	switch (props.body) {
		case 'addAssociatedCompany':
			return AddAssociatedCompany(props);
		case 'addAssociatedContact':
			return AddAssociatedContact(props);
		default:
			return 'Unknown modal';
	}
};

const AssociationModal = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Popper
			open={props.open}
			anchorEl={props.anchorEl}
			placement='left'
			disablePortal={false}
			modifiers={{
				flip: {
					enabled: false,
				},
				preventOverflow: {
					enabled: true,
					boundariesElement: 'scrollParent',
				},
				offset: {
					enabled: true,
					offset: '0, 30',
				},
			}}
		>
			<div className={classes.paper}>{getBody({ ...props, theme: theme })}</div>
		</Popper>
	);
};

export default AssociationModal;
