import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import EventIcon from '@material-ui/icons/Event';
import Skeleton from '@material-ui/lab/Skeleton';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import IndustryList from '../../../../../components/Data/industries.json';

const useStyles = makeStyles((theme) => ({}));

const getIndustry = (val) => {
	let obj = IndustryList.find((item) => item.section === val);
	if (obj !== undefined) {
		return obj.name;
	} else {
		return val;
	}
};

export default function CompanyInfo(props) {
	const {
		loading,
		company,
		editable,
		handleInputChange,
		handleDateChange,
		errorMessages,
	} = props;
	const classes = useStyles();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

	return (
		<Grid container spacing={isDesktopOrLaptop ? 3 : 2}>
			<Grid item xs={12} md={6} lg={4}>
				<Grid container justifyContent='flex-start'>
					<Typography color='textPrimary' variant='body2' align='left'>
						Company Name
					</Typography>
					{editable && (
						<Typography
							style={{ marginLeft: 2 }}
							color='error'
							variant='body2'
							align='right'
						>
							*
						</Typography>
					)}
				</Grid>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='companyName'
								value={company.companyName || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`companyName`]}
								helperText={
									errorMessages[`companyName`] && errorMessages[`companyName`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{company.companyName}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Grid container justifyContent='flex-start'>
					<Typography color='textPrimary' variant='body2' align='left'>
						Unique Entity No. (UEN)
					</Typography>
					{editable && (
						<Typography
							style={{ marginLeft: 2 }}
							color='error'
							variant='body2'
							align='right'
						>
							*
						</Typography>
					)}
				</Grid>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='uen'
								value={company.uen || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`uen`]}
								helperText={errorMessages[`uen`] && errorMessages[`uen`]}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{company.uen}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Website
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='website'
								value={company.website || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`website`]}
								helperText={
									errorMessages[`website`] && errorMessages[`website`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{company.website}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Nature of Business
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<Select
								native
								name='industryName'
								value={company.industryName || ''}
								inputProps={{
									style: { color: 'unset' },
								}}
								onChange={handleInputChange}
								variant='filled'
								fullWidth
								autoWidth={true}
								margin='dense'
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option key={0} value=''></option>
								{IndustryList.map((industry, index) => (
									<option key={index} value={industry.section}>
										{industry.name}
									</option>
								))}
							</Select>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{company.industryName ? getIndustry(company.industryName) : ''}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Established On
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							name='establishDate'
							disableToolbar
							disabled={editable ? false : true}
							placeholder={editable ? 'DD/MM/YYYY' : ''}
							inputVariant={editable ? 'filled' : 'standard'}
							openTo='year'
							views={['year', 'month', 'date']}
							InputProps={{
								disableUnderline: !editable,
								style: { color: 'unset' },
							}}
							format='dd/MM/yyyy'
							disableFuture
							margin='dense'
							fullWidth
							value={company.establishDate ? company.establishDate : null}
							autoOk
							variant='inline'
							invalidDateMessage='Invalid Date Format'
							onChange={(date) => handleDateChange('establishDate', date)}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							keyboardIcon={editable ? <EventIcon /> : null}
							error={!!errorMessages[`establishDate`]}
							helperText={
								errorMessages[`establishDate`] && errorMessages[`establishDate`]
							}
						/>
					</MuiPickersUtilsProvider>
				)}
			</Grid>
			<Box width='100%' />
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Email
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='companyEmail'
								value={company.companyEmail || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`companyEmail`]}
								helperText={
									errorMessages[`companyEmail`] && errorMessages[`companyEmail`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
								component={Link}
								href={`mailto:${company.companyEmail}`}
							>
								{company.companyEmail}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Office No.
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='officeNo'
								value={company.officeNo || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`officeNo`]}
								helperText={
									errorMessages[`officeNo`] && errorMessages[`officeNo`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
								component={Link}
								href={`tel:${company.officeNo}`}
							>
								{company.officeNo}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Fax No.
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='faxNo'
								value={company.faxNo || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`faxNo`]}
								helperText={errorMessages[`faxNo`] && errorMessages[`faxNo`]}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{company.faxNo}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Street Address
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='companyAddress'
								value={company.companyAddress || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`companyAddress`]}
								helperText={
									errorMessages[`companyAddress`] &&
									errorMessages[`companyAddress`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{company.companyAddress}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Unit No.
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='companyUnitNo'
								value={company.companyUnitNo || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`companyUnitNo`]}
								helperText={
									errorMessages[`companyUnitNo`] &&
									errorMessages[`companyUnitNo`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{company.companyUnitNo}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Postal Code
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='companyPostalCode'
								value={company.companyPostalCode || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`companyPostalCode`]}
								helperText={
									errorMessages[`companyPostalCode`] &&
									errorMessages[`companyPostalCode`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{company.companyPostalCode}
							</Typography>
						)}
					</>
				)}
			</Grid>
		</Grid>
	);
}
