import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../config/api';

const useStyles = makeStyles((theme) => ({
	skeleton: {
		marginTop: 8,
		marginBottom: 4,
		borderRadius: 4,
		height: 40,
		width: '100%',
	},
	skeletonLargeText: {
		marginTop: 8,
		marginBottom: 4,
		borderRadius: 4,
		height: 152,

		width: '100%',
	},
	skeletonCircle: {
		width: 180,
		height: 180,
	},
}));

const initState = {
	facebookUrl: '',
	twitterUrl: '',
	instagramUrl: '',
	linkedinUrl: '',
	youtubeUrl: '',
	website: '',
	coverNote: '',
	attributes: {
		transactions: true,
		premiumGenerated: true,
		specializations: true,
	},
};

function CircularProgressWithLabel(props) {
	return (
		<Box position='relative' display='inline-flex'>
			<CircularProgress variant='determinate' {...props} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position='absolute'
				display='flex'
				alignItems='center'
				justifyContent='center'
			>
				<Typography
					variant='caption'
					component='div'
					color='textSecondary'
				>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

export default function BizCardInfo(props) {
	const classes = useStyles();
	const [formData, setFormData] = useState(initState);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});
	const [triggerUpdate, setTriggerUpdate] = useState(true);

	const getData = async () => {
		try {
			setLoading(true);
			const res = await api.get('settings/bizcard');
			if (res.data.ok) {
				setFormData(res.data.data);
			}
		} catch (error) {
			console.log('error', error);
		} finally {
			setTriggerUpdate(false);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (triggerUpdate) getData();
	}, [triggerUpdate]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSwitchChange = (e) => {
		const { name, checked } = e.target;
		setFormData((prev) => ({
			...prev,
			attributes: {
				...prev.attributes,
				[name]: checked,
			},
		}));
	};

	const handleSave = async (e) => {
		try {
			e.preventDefault();
			let isError = false;
			let errorMessages = {};

			if (!isError) {
				setLoading(true);

				await api.put('settings/bizcard', formData).then((res) => {
					if (res.data.ok) {
						setError(false);
						setErrorMessages({});
						setLoading(false);
						toast.success('e-Business Card updated!');
					} else {
						setError(true);
						setErrorMessages(res.data.error);
						setLoading(false);
						toast.error(res.data.error);
					}
				});
			} else {
				setError(true);
				setErrorMessages(errorMessages);
				toast.error('An error has occured!');
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	const getAttributes = (attributes) => {
		const getName = (key) => {
			switch (key) {
				case 'transactions': {
					return 'Transactions Completed';
				}
				case 'premiumGenerated': {
					return 'Premium Generated';
				}
				case 'specializations': {
					return 'Specialized Insurance Types';
				}
			}
		};

		return Object.entries(attributes).map(([key, value], index) => (
			<React.Fragment key={index}>
				<Typography color='textPrimary' variant='body1' align='left'>
					{getName(key)}
				</Typography>
				<Switch
					className={classes.switch}
					color='primary'
					name={key}
					checked={value}
					onChange={(e) => handleSwitchChange(e)}
					inputProps={{ 'aria-label': 'primary checkbox' }}
				/>
			</React.Fragment>
		));
	};

	return (
		<div>
			<Grid container spacing={4}>
				<Grid item xs={4}>
					<Typography color='textPrimary' variant='h3' align='left'>
						Social Media
					</Typography>
					<Grid container spacing={2} direction='column'>
						<Grid item>
							<Grid container justifyContent='flex-start'>
								<Typography color='textPrimary' variant='body1' align='left'>
									Facebook
								</Typography>
							</Grid>
							{loading ? (
								<Skeleton
									className={classes.skeleton}
									variant='rect'
									animation='wave'
								/>
							) : (
								<TextField
									name='facebookUrl'
									variant='outlined'
									fullWidth
									margin='dense'
									onChange={(e) => handleChange(e)}
									value={formData.facebookUrl}
								/>
							)}
						</Grid>
						<Grid item>
							<Grid container justifyContent='flex-start'>
								<Typography color='textPrimary' variant='body1' align='left'>
									Twitter
								</Typography>
							</Grid>
							{loading ? (
								<Skeleton
									className={classes.skeleton}
									variant='rect'
									animation='wave'
								/>
							) : (
								<TextField
									name='twitterUrl'
									variant='outlined'
									fullWidth
									margin='dense'
									onChange={(e) => handleChange(e)}
									value={formData.twitterUrl}
								/>
							)}
						</Grid>
						<Grid item>
							<Grid container justifyContent='flex-start'>
								<Typography color='textPrimary' variant='body1' align='left'>
									Instagram
								</Typography>
							</Grid>
							{loading ? (
								<Skeleton
									className={classes.skeleton}
									variant='rect'
									animation='wave'
								/>
							) : (
								<TextField
									name='instagramUrl'
									variant='outlined'
									fullWidth
									margin='dense'
									onChange={(e) => handleChange(e)}
									value={formData.instagramUrl}
								/>
							)}
						</Grid>
						<Grid item>
							<Grid container justifyContent='flex-start'>
								<Typography color='textPrimary' variant='body1' align='left'>
									LinkedIn
								</Typography>
							</Grid>
							{loading ? (
								<Skeleton
									className={classes.skeleton}
									variant='rect'
									animation='wave'
								/>
							) : (
								<TextField
									name='linkedinUrl'
									variant='outlined'
									fullWidth
									margin='dense'
									onChange={(e) => handleChange(e)}
									value={formData.linkedinUrl}
								/>
							)}
						</Grid>
						<Grid item>
							<Grid container justifyContent='flex-start'>
								<Typography color='textPrimary' variant='body1' align='left'>
									Youtube
								</Typography>
							</Grid>
							{loading ? (
								<Skeleton
									className={classes.skeleton}
									variant='rect'
									animation='wave'
								/>
							) : (
								<TextField
									name='youtubeUrl'
									variant='outlined'
									fullWidth
									margin='dense'
									onChange={(e) => handleChange(e)}
									value={formData.youtubeUrl}
								/>
							)}
						</Grid>
						<Grid item>
							<Grid container justifyContent='flex-start'>
								<Typography color='textPrimary' variant='body1' align='left'>
									Personal Website
								</Typography>
							</Grid>
							{loading ? (
								<Skeleton
									className={classes.skeleton}
									variant='rect'
									animation='wave'
								/>
							) : (
								<TextField
									name='website'
									variant='outlined'
									fullWidth
									margin='dense'
									onChange={(e) => handleChange(e)}
									value={formData.website}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={4}>
					<Typography color='textPrimary' variant='h3' align='left'>
						Business Card Settings
					</Typography>
					<Grid container spacing={2} direction='column'>
						<Grid item>
							<Grid container justifyContent='flex-start'>
								<Typography color='textPrimary' variant='body1' align='left'>
									Email Cover Note
								</Typography>
							</Grid>
							{loading ? (
								<Skeleton
									className={classes.skeletonLargeText}
									variant='rect'
									animation='wave'
								/>
							) : (
								<TextField
									name='coverNote'
									variant='outlined'
									fullWidth
									multiline
									minRows={4}
									margin='dense'
									onChange={(e) => handleChange(e)}
									value={formData.coverNote}
								/>
							)}
						</Grid>
						<Grid item>
							<Grid container justifyContent='flex-start'>
								<Typography color='textPrimary' variant='body1' align='left'>
									Performance Information
								</Typography>
							</Grid>
							{getAttributes(formData.attributes)}
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={4}>
					<Grid container justifyContent='flex-start'>
						<Typography color='textPrimary' variant='h3' align='left'>
							Completion Rate
						</Typography>
						<CircularProgressWithLabel value={50} />
					</Grid>
				</Grid>
			</Grid>
			<div className='formFooter' style={{ display: 'flex', marginTop: 30 }}>
				<Button
					style={{ marginLeft: 'auto' }}
					variant='contained'
					color='primary'
					startIcon={<SaveIcon />}
					onClick={(e) => handleSave(e)}
				>
					Save
				</Button>
			</div>
		</div>
	);
}
