import Backdrop from '../../components/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './ForgotPassword.css';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import Joi from 'joi';
import { useMediaQuery } from 'react-responsive';

const styles = (theme) => ({
	input: {
		width: '100%',
		maxWidth: '460px',
		height: '50px',
		marginBottom: '2vh',
	},
	button: {
		width: '100%',

		height: 48,
		margin: '32px 0px',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
	},
	firebaseUi: {
		marginTop: 40,
	},
	checkbox: {
		'&:hover': {
			backgroundColor: 'transparent !important',
		},
	},
});

const ForgotPasswordPage = () => <ForgotPasswordForm />;

const ForgotPassword = (props) => {
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const { classes, theme } = props;
	const [email, setEmail] = useState('');
	const [emailSent, setEmailSent] = useState(false);
	const [error, setError] = useState(null);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);

	const onSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		let valid = handleValidation();

		if (valid) {
			props.firebase
				.doPasswordReset(email)
				.then(() => {
					setEmailSent(true);
					setErrorMessages({});
					setError(null);
					setEmail('');
					setLoading(false);
				})
				.catch((err) => {
					if (err.code === 'auth/user-not-found') {
						setEmailSent(true);
						setErrorMessages({});
						setError(null);
						setEmail('');
					} else {
						setError('An error has occured!');
					}
					setLoading(false);
				});
		} else {
			setError('An error has occured!');
			setLoading(false);
		}
	};

	const onEmailChange = (event) => {
		const { name, value } = event.target;
		setEmail(value);
	};

	const handleValidation = () => {
		const schema = Joi.object({
			email: Joi.string()
				.label('Email')
				.email({ tlds: { allow: false } })
				.required(),
		});

		let result = schema.validate(
			{
				email: email,
			},
			{ abortEarly: false, convert: false }
		);

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	return (
		<>
			<Backdrop text='Loading Data...' open={loading} />
			{isDesktopOrLaptop ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						padding: 40,
						alignItems: 'center',
					}}
				>
					<div
						style={{
							width: '100%',
							flexGrow: 1,
						}}
					>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Typography
								style={{ marginLeft: 'auto' }}
								color='textSecondary'
								variant='body1'
							>
								Don't have an account?
							</Typography>
							<Button
								style={{ marginLeft: 16 }}
								component={RouterLink}
								to='/register'
								variant='outlined'
								color='primary'
							>
								Sign Up
							</Button>
						</div>
					</div>
					<Typography variant='h1' style={{ textAlign: 'center' }}>
						{emailSent ? 'Check your email' : 'Reset your password'}
					</Typography>
					<div style={{ flexGrow: 1, width: '75%' }}>
						<form
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
							onSubmit={(e) => onSubmit(e)}
						>
							<div
								style={{
									maxWidth: 460,
									width: '75%',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<Typography
									style={{ textAlign: 'center', margin: '40px 0px' }}
									color='textPrimary'
									variant='body1'
								>
									{emailSent
										? `If that email address is in our database, we will send you an email with instructions on how to reset your password.`
										: 'To reset your password, enter the email address you use to sign in.'}
								</Typography>
								{error && (
									<div
										style={{
											backgroundColor: '#FCE9E9',
											padding: '16px 24px',
											borderRadius: 4,
											marginBottom: 24,
										}}
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<HighlightOffIcon style={{ color: '#F60C36' }} />
											<div style={{ marginLeft: 16 }}>
												<Typography
													style={{
														color: theme.palette.error.main,
														marginBottom: 8,
													}}
													variant='body2'
												>
													Error
												</Typography>
												<Typography
													style={{ color: theme.palette.error.main }}
													variant='body1'
												>
													{error}
												</Typography>
											</div>
										</div>
									</div>
								)}
								{!emailSent ? (
									<>
										<Typography color='textPrimary' variant='body2'>
											Email
										</Typography>
										<TextField
											name='email'
											type='email'
											value={email}
											onChange={(e) => onEmailChange(e)}
											variant='filled'
											fullWidth
											margin='dense'
											autoFocus
											error={!!errorMessages[`email`]}
											helperText={
												errorMessages[`email`] && errorMessages[`email`]
											}
										/>
										<Button
											className={classes.button}
											disabled={loading}
											type='submit'
											variant='contained'
											color='primary'
										>
											Get reset link
										</Button>
									</>
								) : (
									<Button
										style={{ marginLeft: 'auto', marginRight: 'auto' }}
										component={RouterLink}
										to={'/login'}
										variant='contained'
										color='primary'
									>
										Back to Login
									</Button>
								)}
							</div>
						</form>
					</div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							position: 'relative',
							justifyContent: 'flex-end',
							margin: '0px auto',
							height: 30,
						}}
					>
						<Typography variant='body1' color='textSecondary'>
							© {new Date().getFullYear()} Uuoni Pte Ltd, All rights reserved.
						</Typography>
					</div>
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						padding: '32px 24px',
					}}
				>
					<Typography variant='h1'>
						{emailSent ? 'Check your email' : 'Reset your password'}
					</Typography>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography color='textSecondary' variant='body1'>
							Don't have an account?
						</Typography>
						<Button
							style={{ padding: 8 }}
							component={RouterLink}
							to='/register'
							variant='text'
							color='primary'
						>
							Sign Up
						</Button>
					</div>
					<form
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignSelf: 'center',
							maxWidth: 340,
						}}
						onSubmit={(e) => onSubmit(e)}
					>
						<Typography
							style={{ margin: '16px 0px' }}
							color='textPrimary'
							variant='body1'
						>
							{emailSent
								? `If that email address is in our database, we will send you an email with instructions on how to reset your password.`
								: 'To reset your password, enter the email address you use to sign in.'}
						</Typography>
						{error && (
							<div
								style={{
									backgroundColor: '#FCE9E9',
									padding: '16px 24px',
									borderRadius: 4,
									marginBottom: 24,
								}}
							>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<HighlightOffIcon style={{ color: '#F60C36' }} />
									<div style={{ marginLeft: 16 }}>
										<Typography
											style={{
												color: theme.palette.error.main,
												marginBottom: 8,
											}}
											variant='body2'
										>
											Error
										</Typography>
										<Typography
											style={{ color: theme.palette.error.main }}
											variant='body1'
										>
											{error}
										</Typography>
									</div>
								</div>
							</div>
						)}
						{!emailSent ? (
							<>
								<Typography color='textPrimary' variant='body2'>
									Email
								</Typography>
								<TextField
									name='email'
									type='email'
									value={email}
									onChange={(e) => onEmailChange(e)}
									variant='filled'
									fullWidth
									margin='dense'
									autoFocus
									error={!!errorMessages[`email`]}
									helperText={errorMessages[`email`] && errorMessages[`email`]}
								/>
								<Button
									className={classes.button}
									disabled={loading}
									type='submit'
									variant='contained'
									color='primary'
								>
									Get reset link
								</Button>
							</>
						) : (
							<Button
								className={classes.button}
								component={RouterLink}
								to={'/login'}
								variant='contained'
								color='primary'
							>
								Back to Login
							</Button>
						)}
					</form>
				</div>
			)}
		</>
	);
};

const ForgotPasswordForm = compose(
	withRouter,
	withFirebase,
	withStyles(styles, { withTheme: true })
)(ForgotPassword);

export default ForgotPasswordPage;

export { ForgotPasswordForm };
