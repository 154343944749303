import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import ClaimRequestItem from './ClaimRequestItem';
import TransactionModal from '../../../../../../../components/Modals/transactions';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: 120,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 16,
	},
}));

export default function ClaimRequestView(props) {
	const {
		transaction,
		setTransaction,
		setTriggerUpdate,
		setTriggerUpdateActivities,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const [principals, setPrincipals] = useState([]);
	const [value, setValue] = useState(0);
	const [loading, setLoading] = useState(false);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);

	const handleModalOpen = (body) => {
		setModalType(body);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalType(null);
	};

	if (loading || isEmpty(transaction.requests))
		return (
			<div className={classes.root}>
				{loading ? (
					<CircularProgress />
				) : (
					<Button
						onClick={() =>
							handleModalOpen(
								transaction.source === 'External'
									? 'sendRequestForExternalClaims'
									: 'sendRequestForClaims'
							)
						}
						variant='contained'
						color='primary'
						className={classes.button}
						startIcon={<AddCircleOutlineIcon />}
					>
						Request Claim
					</Button>
				)}
				{modalOpen && (
					<TransactionModal
						open={modalOpen}
						handleClose={handleModalClose}
						body={modalType}
						transactionId={transaction._id}
						data={transaction}
						setTriggerUpdate={setTriggerUpdate}
						size={modalType === 'sendRequestForClaims' ? 'md' : 'sm'}
					/>
				)}
			</div>
		);

	return (
		<div
			id='claim-request-view'
			style={{ flexDirection: 'column', width: '100%' }}
		>
			<ClaimRequestItem
				request={transaction.requests[0]}
				setTriggerUpdate={setTriggerUpdate}
				setTriggerUpdateActivities={setTriggerUpdateActivities}
			/>
		</div>
	);
}
