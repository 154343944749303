import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import api from '../../config/api';
import { Download } from '../Icons';

const useStyles = makeStyles((theme) => ({
	cardContent: {
		display: 'flex',
		alignItems: 'center',
		padding: 10,
		'&:last-child': {
			padding: 10,
		},
	},
}));

const FileInfoCard = (props) => {
	const { file } = props;
	const classes = useStyles();
	const [loading, setLoading] = useState(false);

	const downloadDocument = (e) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);
		}
		api
			.get(`documents/${file.documentId}?action=download`)
			.then((response) => {
				if (response.data === 'file not found') {
					setLoading(false);
					return alert('Error retrieving file!');
				}
				window.open(response.data, '_blank');
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const fileSize = (size) => {
		if (size === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const i = Math.floor(Math.log(size) / Math.log(k));
		return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
	};

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	if (!file)
		return (
			<Typography variant='body1' color='textPrimary' component='span'>
				File not found!
			</Typography>
		);

	return (
		<Card className={classes.cardContainer}>
			<CardContent classes={{ root: classes.cardContent }}>
				<div style={{ width: 24 }}>
					<FileIcon
						labelUppercase
						extension={fileType(file.displayName)}
						{...defaultStyles[fileType(file.displayName)]}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginLeft: 20,
					}}
				>
					<Typography variant='body1' color='textPrimary' component='span'>
						{file.documentName} ({file.displayName})
					</Typography>
					<Typography variant='body1' color='textSecondary' component='span'>
						{fileSize(file.size)}
					</Typography>
				</div>
				{loading ? (
					<CircularProgress />
				) : (
					<IconButton
						style={{ marginLeft: 'auto' }}
						aria-label='expand row'
						size='small'
						onClick={(e) => downloadDocument(e)}
					>
						<Download style={{ fontSize: 24 }} />
					</IconButton>
				)}
			</CardContent>
		</Card>
	);
};

export default FileInfoCard;
