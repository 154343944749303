import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import moment from 'moment';
import React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import {
	claimFields,
	companyFields,
	contactFields,
	policyFields,
	quotationFields,
} from '../../../../components/Data/fieldList.json';
import { combineNames } from '../../../../utils/combineNames';
import {
	ChecklistDocument,
	Company,
	Contacts,
	Documents,
	Instruction,
	Transaction,
	URL,
} from '../../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	paper: {
		height: '80vh',
		padding: 24,
		borderRadius: 8,
		backgroundColor: theme.palette.background.secondary,
		overflowWrap: 'anywhere',
	},
	field: {
		display: 'flex',
		alignItems: 'baseline',
		width: '100%',
		whiteSpace: 'pre-wrap',
	},
	docField: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: 16,
		marginBottom: 16,
	},
	docName: {
		display: 'flex',
		minWidth: 240,
		maxWidth: 240,
	},
	docIcon: {
		minWidth: 16,
		maxWidth: 16,
		height: 24,
		margin: '0px 4px',
	},
}));

export default function Summary(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { formData, requestType } = props;

	const fileSize = (size) => {
		if (size === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const i = Math.floor(Math.log(size) / Math.log(k));
		return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
	};

	const getIcon = (type) => {
		switch (type) {
			case 'Document':
				return <ChecklistDocument style={{ fontSize: 24 }} />;
			case 'Photo':
				return <PhotoOutlinedIcon />;
			case 'Video':
				return <VideocamOutlinedIcon />;
			default:
				return <InsertDriveFileOutlinedIcon />;
		}
	};

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	const getFields = (contentType, data) => {
		switch (contentType) {
			case 'transactionDetails':
				return (
					<>
						<div className={classes.field}>
							<Typography
								style={{ minWidth: 240 }}
								color='textPrimary'
								variant='body2'
							>
								Transaction:
							</Typography>
							<Typography color='textPrimary' variant='body1'>
								{formData.transactionType}
							</Typography>
						</div>
						<div className={classes.field}>
							<Typography
								style={{ minWidth: 240 }}
								color='textPrimary'
								variant='body2'
							>
								Insurance:
							</Typography>
							<div>
								<Typography color='textPrimary' variant='body1'>
									{formData.selectedInsuranceType.insuranceType}
									{formData.selectedInsuranceType.insuranceSubType && (
										<> ({formData.selectedInsuranceType.insuranceSubType})</>
									)}
								</Typography>
							</div>
						</div>
						{formData.selectedInsuranceType.isCommercial && (
							<div className={classes.field}>
								<Typography
									style={{ minWidth: 240 }}
									color='textPrimary'
									variant='body2'
								>
									Company Info:
								</Typography>
								<Typography color='textPrimary' variant='body1'>
									{formData.companyData.companyName}{' '}
									{`(UEN: ${formData.companyData.uen})`}
								</Typography>
							</div>
						)}
						<div className={classes.field}>
							<Typography
								style={{ minWidth: 240 }}
								color='textPrimary'
								variant='body2'
							>
								Contact Info:
							</Typography>
							<Typography color='textPrimary' variant='body1'>
								{`${combineNames({
									fName: formData.contactData.firstName,
									mName:
										formData.contactData.middleName !== undefined
											? formData.contactData.middleName
											: null,
									lName:
										formData.contactData.lastName !== undefined
											? formData.contactData.lastName
											: null,
								})} (${formData.contactData.email})`}
							</Typography>
						</div>
					</>
				);
			case 'contactData':
				return (
					<>
						{contactFields.map(
							(field, index) =>
								formData.contactData[field.name] !== undefined &&
								formData.contactData[field.name] !== null &&
								formData.contactData[field.name] !== '' &&
								field.type !== 'block' && (
									<div key={index} className={classes.field}>
										<Typography
											style={{ minWidth: 240 }}
											color='textPrimary'
											variant='body2'
										>
											{field.label}:
										</Typography>
										<Typography color='textPrimary' variant='body1'>
											{field.type === 'date'
												? formData.contactData[field.name]
													? moment(formData.contactData[field.name]).format(
															'DD/MM/YYYY'
													  )
													: null
												: formData.contactData[field.name]}
										</Typography>
									</div>
								)
						)}
					</>
				);
			case 'companyData':
				return (
					<>
						{companyFields.map(
							(field, index) =>
								formData.companyData[field.name] !== undefined &&
								formData.companyData[field.name] !== null &&
								formData.companyData[field.name] !== '' &&
								field.type !== 'block' && (
									<div key={index} className={classes.field}>
										<Typography
											style={{ minWidth: 240 }}
											color='textPrimary'
											variant='body2'
										>
											{field.label}:
										</Typography>
										<Typography color='textPrimary' variant='body1'>
											{field.type === 'date'
												? formData.companyData[field.name]
													? moment(formData.companyData[field.name]).format(
															'DD/MM/YYYY'
													  )
													: null
												: formData.companyData[field.name]}
										</Typography>
									</div>
								)
						)}
					</>
				);
			case 'claimData':
				return (
					<>
						{formData.claimData.accepted ? (
							<>
								{claimFields.map(
									(field, index) =>
										formData.claimData[field.name] !== undefined &&
										formData.claimData[field.name] !== null &&
										formData.claimData[field.name] !== '' &&
										field.type !== 'block' && (
											<div key={index} className={classes.field}>
												<Typography
													style={{ minWidth: 240 }}
													color='textPrimary'
													variant='body2'
												>
													{field.label}:
												</Typography>
												<Typography color='textPrimary' variant='body1'>
													{field.name === 'principalId' ? (
														formData.claimData['principalName']
													) : (
														<>
															{field.type === 'date'
																? formData.claimData[field.name]
																	? moment(
																			formData.claimData[field.name]
																	  ).format('DD/MM/YYYY')
																	: null
																: field.name === 'sumInsured'
																? formData.claimData['sumInsured']
																	? formData.claimData['sumInsured']
																	: 'Refer to Policy Document'
																: formData.claimData[field.name]}
														</>
													)}
												</Typography>
											</div>
										)
								)}
								{formData.claimData.fileData &&
									formData.claimData.fileData.length &&
									[...formData.claimData.fileData].map((file, index) => (
										<div key={index} className={classes.docField}>
											<div className={classes.docName}>
												<div className={classes.docIcon}>
													<FileIcon
														labelUppercase
														extension={fileType(file.name)}
														{...defaultStyles[fileType(file.name)]}
													/>
												</div>
												<Typography
													style={{
														marginLeft: 8,
													}}
													color='textPrimary'
													variant='body2'
												>
													Claim Document
												</Typography>
											</div>
											<Typography
												style={{ minWidth: 240, maxWidth: 240 }}
												color='textPrimary'
												variant='body1'
											>
												{file.name}
											</Typography>
											<Typography
												style={{ marginLeft: 16 }}
												color='textSecondary'
												variant='body1'
											>
												{fileSize(file.size)}
											</Typography>
										</div>
									))}
							</>
						) : (
							<>
								<div className={classes.field}>
									<Typography
										style={{ minWidth: 240 }}
										color='textPrimary'
										variant='body2'
									>
										Decline Reason:
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										{formData.claimData.rejReason}
									</Typography>
								</div>
								<div className={classes.field}>
									<Typography
										style={{ minWidth: 240 }}
										color='textPrimary'
										variant='body2'
									>
										Decline Remarks:
									</Typography>
									<Typography
										style={{ whiteSpace: 'pre-wrap' }}
										color='textPrimary'
										variant='body1'
									>
										{formData.claimData.rejRemarks}
									</Typography>
								</div>
							</>
						)}
					</>
				);
			case 'policyData':
				return (
					<>
						{policyFields.map(
							(field, index) =>
								formData.policyData[field.name] !== undefined &&
								formData.policyData[field.name] !== null &&
								formData.policyData[field.name] !== '' &&
								field.type !== 'block' && (
									<div key={index} className={classes.field}>
										{field.name !== 'endDate' && (
											<Typography
												style={{ minWidth: 240 }}
												color='textPrimary'
												variant='body2'
											>
												{field.name === 'startDate'
													? `Period of Insurance:`
													: `${field.label}:`}
											</Typography>
										)}
										{field.name === 'startDate' ? (
											<Typography color='textPrimary' variant='body1'>
												{`${moment(formData.policyData[field.name]).format(
													'DD/MM/YYYY'
												)} - ${moment(formData.policyData['endDate']).format(
													'DD/MM/YYYY'
												)}`}
											</Typography>
										) : (
											<>
												{field.name !== 'endDate' && (
													<Typography color='textPrimary' variant='body1'>
														{field.name === 'principalId' ? (
															formData.policyData['principalName']
														) : (
															<>
																{field.type === 'date'
																	? formData.policyData[field.name]
																		? moment(
																				formData.policyData[field.name]
																		  ).format('DD/MM/YYYY')
																		: null
																	: field.name === 'sumInsured'
																	? formData.policyData['sumInsured']
																		? formData.policyData['sumInsured']
																		: 'Refer to Policy Document'
																	: formData.policyData[field.name]}
															</>
														)}
													</Typography>
												)}
											</>
										)}
									</div>
								)
						)}
						{formData.policyData.fileData &&
							formData.policyData.fileData.length &&
							[...formData.policyData.fileData].map((file, index) => (
								<div key={index} className={classes.docField}>
									<div className={classes.docName}>
										<div className={classes.docIcon}>
											<FileIcon
												labelUppercase
												extension={fileType(file.name)}
												{...defaultStyles[fileType(file.name)]}
											/>
										</div>
										<Typography
											style={{
												marginLeft: 8,
											}}
											color='textPrimary'
											variant='body2'
										>
											Policy Document
										</Typography>
									</div>
									<Typography
										style={{ minWidth: 240, maxWidth: 240 }}
										color='textPrimary'
										variant='body1'
									>
										{file.name}
									</Typography>
									<Typography
										style={{ marginLeft: 16 }}
										color='textSecondary'
										variant='body1'
									>
										{fileSize(file.size)}
									</Typography>
								</div>
							))}
					</>
				);
			case 'prevPolicyData':
				return (
					<>
						{policyFields.map(
							(field, index) =>
								formData.prevPolicyData[field.name] !== undefined &&
								formData.prevPolicyData[field.name] !== null &&
								formData.prevPolicyData[field.name] !== '' &&
								field.type !== 'block' && (
									<div key={index} className={classes.field}>
										{field.name !== 'endDate' && (
											<Typography
												style={{ minWidth: 240 }}
												color='textPrimary'
												variant='body2'
											>
												{field.name === 'startDate'
													? `Period of Insurance:`
													: `${field.label}:`}
											</Typography>
										)}
										{field.name === 'startDate' ? (
											<Typography color='textPrimary' variant='body1'>
												{`${moment(formData.prevPolicyData[field.name]).format(
													'DD/MM/YYYY'
												)} - ${moment(
													formData.prevPolicyData['endDate']
												).format('DD/MM/YYYY')}`}
											</Typography>
										) : (
											<>
												{field.name !== 'endDate' && (
													<Typography color='textPrimary' variant='body1'>
														{field.name === 'principalId' ? (
															formData.prevPolicyData['principalName']
														) : (
															<>
																{field.type === 'date'
																	? formData.prevPolicyData[field.name]
																		? moment(
																				formData.prevPolicyData[field.name]
																		  ).format('DD/MM/YYYY')
																		: null
																	: field.name === 'sumInsured'
																	? formData.prevPolicyData['sumInsured']
																		? formData.prevPolicyData['sumInsured']
																		: 'Refer to Policy Document'
																	: formData.prevPolicyData[field.name]}
															</>
														)}
													</Typography>
												)}
											</>
										)}
									</div>
								)
						)}
						{formData.source === 'External'
							? [...formData.prevPolicyData.fileData].map((file, index) => (
									<div className={classes.docField} key={index}>
										<div className={classes.docName}>
											<div className={classes.docIcon}>
												<FileIcon
													labelUppercase
													extension={fileType(file.name)}
													{...defaultStyles[fileType(file.name)]}
												/>
											</div>
											<Typography
												style={{
													marginLeft: 8,
												}}
												color='textPrimary'
												variant='body2'
											>
												Policy Document
											</Typography>
										</div>
										<Typography
											style={{ minWidth: 240, maxWidth: 240 }}
											color='textPrimary'
											variant='body1'
										>
											{file.name}
										</Typography>
										<Typography
											style={{ marginLeft: 16 }}
											color='textSecondary'
											variant='body1'
										>
											{fileSize(file.size)}
										</Typography>
									</div>
							  ))
							: [...formData.prevPolicyData.policyDocuments].map(
									(file, index) => (
										<div className={classes.docField} key={index}>
											<div className={classes.docName}>
												<div className={classes.docIcon}>
													<FileIcon
														labelUppercase
														extension={fileType(file.displayName)}
														{...defaultStyles[fileType(file.displayName)]}
													/>
												</div>
												<Typography
													style={{
														marginLeft: 8,
													}}
													color='textPrimary'
													variant='body2'
												>
													{file.documentName}
												</Typography>
											</div>
											<Typography
												style={{ minWidth: 240, maxWidth: 240 }}
												color='textPrimary'
												variant='body1'
											>
												{file.displayName}
											</Typography>
											<Typography
												style={{ marginLeft: 16 }}
												color='textSecondary'
												variant='body1'
											>
												{fileSize(file.size)}
											</Typography>
										</div>
									)
							  )}
					</>
				);
			case 'quotationData':
				return (
					<>
						{quotationFields.map(
							(field, index) =>
								data[field.name] !== undefined &&
								data[field.name] !== null &&
								data[field.name] !== '' &&
								field.type !== 'block' && (
									<div key={index} className={classes.field}>
										{field.name !== 'endDate' && (
											<Typography
												style={{ minWidth: 240 }}
												color='textPrimary'
												variant='body2'
											>
												{field.name === 'startDate'
													? `Period of Insurance:`
													: `${field.label}:`}
											</Typography>
										)}
										{field.name === 'startDate' ? (
											<Typography color='textPrimary' variant='body1'>
												{`${moment(data[field.name]).format(
													'DD/MM/YYYY'
												)} - ${moment(data['endDate']).format('DD/MM/YYYY')}`}
											</Typography>
										) : (
											<>
												{field.name !== 'endDate' && (
													<Typography color='textPrimary' variant='body1'>
														{field.type === 'date'
															? data[field.name]
																? moment(data[field.name]).format('DD/MM/YYYY')
																: null
															: field.name === 'sumInsured'
															? data['sumInsured']
																? data['sumInsured']
																: 'Refer to Policy Document'
															: data[field.name]}
													</Typography>
												)}
											</>
										)}
									</div>
								)
						)}
						{data.fileData &&
							data.fileData.length &&
							[...data.fileData].map((file, index) => (
								<div key={index} className={classes.docField}>
									<div className={classes.docName}>
										<div className={classes.docIcon}>
											<FileIcon
												labelUppercase
												extension={fileType(file.name)}
												{...defaultStyles[fileType(file.name)]}
											/>
										</div>
										<Typography
											style={{
												marginLeft: 8,
											}}
											color='textPrimary'
											variant='body2'
										>
											Quotation Document
										</Typography>
									</div>
									<Typography
										style={{ minWidth: 240, maxWidth: 240 }}
										color='textPrimary'
										variant='body1'
									>
										{file.name}
									</Typography>
									<Typography
										style={{ marginLeft: 16 }}
										color='textSecondary'
										variant='body1'
									>
										{fileSize(file.size)}
									</Typography>
								</div>
							))}
					</>
				);
			case 'supportingDocuments':
				return (
					<>
						{requestType === 'clientRequest' && formData.hasProposalForm && (
							<div className={classes.docField}>
								{formData.proposalForm.method === 'url' ? (
									<>
										<div className={classes.docName}>
											{getIcon('Document')}
											<Typography
												style={{
													marginLeft: 8,
												}}
												color='textPrimary'
												variant='body2'
											>
												{formData.transactionType === 'Claims'
													? 'Claim'
													: 'Proposal'}{' '}
												Form
											</Typography>
										</div>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<URL style={{ fontSize: 24 }} />
												<Typography
													style={{ marginLeft: 8, overflowWrap: 'anywhere' }}
													color='textPrimary'
													variant='body1'
												>
													{formData.proposalForm.url}
												</Typography>
											</div>
											{formData.proposalForm.instructions && (
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														marginTop: 16,
													}}
												>
													<Instruction style={{ fontSize: 24 }} />
													<Typography
														style={{
															marginLeft: 8,
															overflowWrap: 'anywhere',
														}}
														color='textPrimary'
														variant='body1'
													>
														{formData.proposalForm.instructions}
													</Typography>
												</div>
											)}
										</div>
									</>
								) : formData.proposalForm.fileData !== null &&
								  formData.proposalForm.fileData.length ? (
									[...formData.proposalForm.fileData].map((file, index) => (
										<React.Fragment key={index}>
											<div className={classes.docName}>
												<div className={classes.docIcon}>
													<FileIcon
														labelUppercase
														extension={fileType(file.name)}
														{...defaultStyles[fileType(file.name)]}
													/>
												</div>
												<Typography
													style={{
														marginLeft: 8,
													}}
													color='textPrimary'
													variant='body2'
												>
													{formData.transactionType === 'Claims'
														? 'Claim'
														: 'Proposal'}{' '}
													Form
												</Typography>
											</div>
											<Typography
												style={{ minWidth: 240, maxWidth: 240 }}
												color='textPrimary'
												variant='body1'
											>
												{file.name}
											</Typography>
											<Typography
												style={{ marginLeft: 16 }}
												color='textSecondary'
												variant='body1'
											>
												{fileSize(file.size)}
											</Typography>
										</React.Fragment>
									))
								) : (
									<>
										{formData.proposalForm.fileData && (
											<>
												<div className={classes.docName}>
													<div className={classes.docIcon}>
														<FileIcon
															labelUppercase
															extension={fileType(
																formData.proposalForm.fileData.name
															)}
															{...defaultStyles[
																fileType(formData.proposalForm.fileData.name)
															]}
														/>
													</div>
													<Typography
														style={{
															marginLeft: 8,
														}}
														color='textPrimary'
														variant='body2'
													>
														{formData.transactionType === 'Claims'
															? 'Claim'
															: 'Proposal'}{' '}
														Form
													</Typography>
												</div>
												<Typography
													style={{ minWidth: 240, maxWidth: 240 }}
													color='textPrimary'
													variant='body1'
												>
													{formData.proposalForm.fileData.name}
												</Typography>
												<Typography
													style={{ marginLeft: 16 }}
													color='textSecondary'
													variant='body1'
												>
													{fileSize(formData.proposalForm.fileData.size)}
												</Typography>
											</>
										)}
									</>
								)}
							</div>
						)}
						{formData.supportingDocuments.map((doc, index) => {
							return requestType === 'clientRequest' ? (
								<div key={index} className={classes.docField}>
									<div className={classes.docName}>
										{getIcon(doc.type)}
										<Typography
											style={{
												marginLeft: 8,
											}}
											color='textPrimary'
											variant='body2'
										>
											{doc.name}
										</Typography>
									</div>
									<Typography
										style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}
										color='textPrimary'
										variant='body1'
									>
										{doc.remarks}
									</Typography>
								</div>
							) : (
								<div key={index} className={classes.docField}>
									<div className={classes.docName}>
										<div className={classes.docIcon}>
											<FileIcon
												labelUppercase
												extension={fileType(doc.fileData.name)}
												{...defaultStyles[fileType(doc.fileData.name)]}
											/>
										</div>
										<Typography
											style={{
												marginLeft: 8,
											}}
											color='textPrimary'
											variant='body2'
										>
											{doc.name}
										</Typography>
									</div>
									<Typography
										style={{ minWidth: 240, maxWidth: 240 }}
										color='textPrimary'
										variant='body1'
									>
										{doc.fileData.name}
									</Typography>
									<Typography
										style={{ marginLeft: 16 }}
										color='textSecondary'
										variant='body1'
									>
										{fileSize(doc.fileData.size)}
									</Typography>
								</div>
							);
						})}
					</>
				);
			default:
				return <></>;
		}
	};

	const getContent = (requestType) => {
		switch (requestType) {
			case 'clientRequest':
				return (
					<>
						<div style={{ marginBottom: 16 }}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: 8,
								}}
							>
								<Transaction style={{ marginRight: 8, fontSize: 24 }} />
								<Typography
									style={{ textTransform: 'uppercase' }}
									color='textPrimary'
									variant='body2'
								>
									Transaction Details
								</Typography>
							</div>
							<div>{getFields('transactionDetails')}</div>
						</div>
						<div style={{ marginBottom: 16 }}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: 8,
								}}
							>
								<Documents style={{ marginRight: 8, fontSize: 24 }} />
								<Typography
									style={{ textTransform: 'uppercase' }}
									color='textPrimary'
									variant='body2'
								>
									List of requested documents
								</Typography>
							</div>
							<div>{getFields('supportingDocuments')}</div>
						</div>
					</>
				);
			case 'transaction':
				return (
					<>
						{formData.selectedInsuranceType.isCommercial && (
							<div style={{ marginBottom: 16 }}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: 8,
									}}
								>
									<Company style={{ marginRight: 8, fontSize: 24 }} />
									<Typography
										style={{ textTransform: 'uppercase' }}
										color='textPrimary'
										variant='body2'
									>
										Company Information
									</Typography>
								</div>
								<div>{getFields('companyData')}</div>
							</div>
						)}
						<div style={{ marginBottom: 16 }}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: 8,
								}}
							>
								<Contacts style={{ marginRight: 8, fontSize: 24 }} />
								<Typography
									style={{ textTransform: 'uppercase' }}
									color='textPrimary'
									variant='body2'
								>
									Personal Information
								</Typography>
							</div>
							<div>{getFields('contactData')}</div>
						</div>
						<div style={{ marginBottom: 16 }}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: 8,
								}}
							>
								<Documents style={{ marginRight: 8, fontSize: 24 }} />
								<Typography
									style={{ textTransform: 'uppercase' }}
									color='textPrimary'
									variant='body2'
								>
									Uploaded Documents
								</Typography>
							</div>
							<div>{getFields('supportingDocuments')}</div>
						</div>
						{formData.isSelfGenerated && (
							<div style={{ marginBottom: 16 }}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: 8,
									}}
								>
									<ChecklistDocument style={{ marginRight: 8, fontSize: 24 }} />
									<Typography
										style={{ textTransform: 'uppercase' }}
										color='textPrimary'
										variant='body2'
									>
										Quotation Information
									</Typography>
								</div>
								<div>
									{formData.quotationData.map((quotation) => {
										return (
											<div key={quotation.id} style={{ marginBottom: 16 }}>
												<div className={classes.field}>
													<Typography
														style={{ minWidth: 240 }}
														color='textPrimary'
														variant='body2'
													>
														Insurer:
													</Typography>
													<Typography
														style={{ minWidth: 240, maxWidth: 240 }}
														color='textPrimary'
														variant='body1'
													>
														{quotation.principalName}
													</Typography>
													{quotation.recommended && (
														<Chip
															style={{
																marginLeft: 16,
																color: theme.palette.background.secondary,
																backgroundColor: theme.palette.success.main,
															}}
															label='Recommended'
														/>
													)}
												</div>
												<div className={classes.field}>
													<Typography
														style={{ minWidth: 240 }}
														color='textPrimary'
														variant='body2'
													>
														Quotation Name:
													</Typography>
													<Typography
														style={{ minWidth: 240, maxWidth: 240 }}
														color='textPrimary'
														variant='body1'
													>
														{quotation.quoteName}
													</Typography>
												</div>
												{getFields('quotationData', quotation)}
												{quotation.recommended && (
													<div className={classes.field}>
														<Typography
															style={{ minWidth: 240 }}
															color='textPrimary'
															variant='body2'
														>
															Recommendation Remarks:
														</Typography>
														<Typography
															style={{
																minWidth: 240,
																maxWidth: 240,
																overflowWrap: 'anywhere',
																whiteSpace: 'pre-wrap',
															}}
															color='textPrimary'
															variant='body1'
														>
															{quotation.recommendationRemarks}
														</Typography>
													</div>
												)}
											</div>
										);
									})}
								</div>
							</div>
						)}
						{formData.isDirectPurchase && (
							<div style={{ marginBottom: 16 }}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: 8,
									}}
								>
									<ChecklistDocument style={{ marginRight: 8, fontSize: 24 }} />
									<Typography
										style={{ textTransform: 'uppercase' }}
										color='textPrimary'
										variant='body2'
									>
										{formData.transactionType === 'Claims'
											? 'Claim Information'
											: 'Policy Information'}
									</Typography>
								</div>
								<div>
									{formData.transactionType === 'Claims'
										? getFields('claimData')
										: getFields('policyData')}
								</div>
							</div>
						)}
						{formData.transactionType !== 'Quotation' && (
							<div style={{ marginBottom: 16 }}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: 8,
									}}
								>
									<ChecklistDocument style={{ marginRight: 8, fontSize: 24 }} />
									<Typography
										style={{ textTransform: 'uppercase' }}
										color='textPrimary'
										variant='body2'
									>
										Previous Policy Information
									</Typography>
								</div>
								<div>{getFields('prevPolicyData')}</div>
							</div>
						)}
					</>
				);
			default:
				return <></>;
		}
	};

	return (
		<Paper className={classes.paper} elevation={0}>
			<Typography color='textPrimary' variant='body2'>
				SUMMARY OF TRANSACTION DETAILS
			</Typography>
			<Divider
				style={{
					backgroundColor: theme.palette.primary.light,
					height: 2,
					marginTop: 16,
					marginBottom: 16,
				}}
			/>
			<div
				id='content'
				style={{ maxHeight: 'calc(100% - 56px)', overflow: 'auto' }}
			>
				{getContent(requestType)}
			</div>
		</Paper>
	);
}
