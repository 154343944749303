import { Badge, useTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import matchSorter from 'match-sorter';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import Tooltip from '../../../../components/Tooltip';
import ChecklistData from '../../../../components/Data/suggestedChecklist.json';
import DataSearch from '../../../../components/DataSearch';
import FileUploader from '../../../../components/FileUploader';
import SuggestedChecklist from '../../../../components/Modals/checklist';
import { combineNames } from '../../../../utils/combineNames';
import {
	Company,
	Contacts,
	Delete,
	Remarks,
	URL,
	UploadDocument,
} from '../../../../components/Icons';

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const useStyles = makeStyles((theme) => ({
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	paper: {
		padding: 24,
		borderRadius: 8,
	},
	tableRow: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	methodButton: {
		borderRadius: 8,
		padding: 16,
	},
	methodButtonIcon: {},
	badge: {
		alignItems: 'center',
		flexDirection: 'column',
	},
	badgeIcon: {
		color: '#7FA572',
		backgroundColor: '#fafafa',
		padding: 'unset',
	},
	offset: {
		right: -15,
		top: -15,
	},
}));

function isEmpty(obj) {
	for (var i in obj) return false;
	return true;
}

const initClientForm = {
	firstName: '',
	lastName: '',
	email: '',
	mobileNo: '',
};

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

function Row(props) {
	const {
		index,
		item,
		handleDocumentChange,
		handleRemoveDocument,
		errorMessages,
	} = props;
	const [open, setOpen] = React.useState(item.remarks ? true : false);
	const classes = useStyles();

	return (
		<>
			<TableRow className={classes.tableRow}>
				<TableCell component='th' scope='row'>
					<TextField
						name='name'
						value={item.name}
						onChange={(e) => handleDocumentChange(e, index)}
						placeholder='Enter document name'
						variant='filled'
						fullWidth
						margin='dense'
						error={!!errorMessages[`supportingDocuments.${index}.name`]}
						helperText={
							errorMessages[`supportingDocuments.${index}.name`] &&
							errorMessages[`supportingDocuments.${index}.name`]
						}
					/>
				</TableCell>
				{/* <TableCell>
					<Select
						native
						name='type'
						value={item.type}
						inputProps={{
							style: { color: 'unset' },
						}}
						onChange={(e) => handleDocumentChange(e, index)}
						variant='filled'
						fullWidth
						margin='dense'
						style={{ marginTop: 8, marginBottom: 4 }}
					>
						{typeList.map((type, index) => {
							return (
								<option key={index} value={type}>
									{type}
								</option>
							);
						})}
					</Select>
				</TableCell> */}
				<TableCell>
					<div style={{ display: 'flex', justifyContent: 'space-around' }}>
						<IconButton
							aria-label='expand row'
							size='small'
							onClick={() => setOpen(!open)}
						>
							<Remarks style={{ fontSize: 24 }} />
						</IconButton>
						<IconButton
							aria-label='expand row'
							size='small'
							onClick={() => handleRemoveDocument(index)}
						>
							<Delete style={{ fontSize: 24 }} />
						</IconButton>
					</div>
				</TableCell>
			</TableRow>
			{/* {open && ( */}
			<TableRow style={{ height: 'unset' }}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box margin={1}>
							<TextField
								name='remarks'
								value={item.remarks}
								multiline
								onChange={(e) => handleDocumentChange(e, index)}
								placeholder='Enter remarks'
								variant='filled'
								fullWidth
								margin='dense'
								minRows={4}
								error={!!errorMessages[`supportingDocuments.${index}.remarks`]}
								helperText={
									errorMessages[`supportingDocuments.${index}.remarks`] &&
									errorMessages[`supportingDocuments.${index}.remarks`]
								}
							/>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			{/* )} */}
		</>
	);
}

const str2bool = (value) => {
	if (value && typeof value === 'string') {
		if (value.toLowerCase() === 'true') return true;
		if (value.toLowerCase() === 'false') return false;
	}
	return value;
};

export default function RequestDocuments(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { formData, handleFormDataChange, errorMessages } = props;

	const [open, setOpen] = useState(false);
	const [checklist, setChecklist] = useState([]);
	const [changeContact, setChangeContact] = useState(false);
	const [val, setVal] = useState(null);
	const [documents, setDocuments] = useState(formData.supportingDocuments);

	useEffect(() => {
		let checklistItems = [];

		ChecklistData.find((list) => {
			if (list.name === formData.selectedInsuranceType.insuranceType) {
				if (formData.selectedInsuranceType.insuranceSubType !== '') {
					let index = list.subTypes.findIndex((subType) => {
						return (
							subType.name === formData.selectedInsuranceType.insuranceSubType
						);
					});
					checklistItems = list.subTypes[index][formData.transactionType];
				} else {
					checklistItems = list[formData.transactionType];
				}
			}
		});

		setChecklist(checklistItems);
		if (documents.length === 0) {
			let list = checklistItems
				.map((item) => {
					if (item.name !== 'Proposal Form' && item.name !== 'Claim Form')
						return { ...item, id: nanoid() };
				})
				.filter(Boolean);
			setDocuments(list);
		}
	}, []);

	useEffect(() => {
		handleFormDataChange('supportingDocuments', documents);
	}, [documents]);

	const handleExistingChange = (e, type) => {
		let val = str2bool(e.target.value);
		let updatedForm = { existing: val };
		if (type === 'contactData') {
			handleFormDataChange('contactData', updatedForm);
		}
		if (type === 'companyData') {
			handleFormDataChange('companyData', updatedForm);
			handleFormDataChange('contactData', { existing: false });
		}
	};

	const handleSelectRecord = (record, type) => {
		if (type === 'contact') {
			let updatedForm = formData.contactData;
			let mergedForm = { _id: record._id, ...updatedForm, ...record };
			handleFormDataChange('contactData', mergedForm);
		}
		if (type === 'company') {
			let updatedForm = formData.companyData;
			let mergedForm = { _id: record._id, ...updatedForm, ...record };
			handleFormDataChange('companyData', mergedForm);
			handleFormDataChange('contactData', { existing: false });
		}
	};

	const handleChangeContact = (e) => {
		let val = str2bool(e.target.value);
		setChangeContact(val);
	};

	const handleClientFormChange = (e, type) => {
		const { name, value } = e.target;
		if (type === 'contactData') {
			let updatedForm = formData.contactData;
			updatedForm[name] = value;
			handleFormDataChange('contactData', updatedForm);
		}
		if (type === 'companyData') {
			let updatedForm = formData.companyData;
			updatedForm[name] = value;
			handleFormDataChange('companyData', updatedForm);
		}
	};

	const handleMethodChange = (val) => {
		let updatedForm = formData.proposalForm;
		updatedForm['method'] = val;
		handleFormDataChange('proposalForm', updatedForm);
	};

	const handleMethodInfoChange = (e) => {
		const { name, value } = e.target;
		let updatedForm = formData.proposalForm;
		updatedForm[name] = value;
		handleFormDataChange('proposalForm', updatedForm);
	};

	const handleAddDocument = () => {
		let updatedDocuments = [...documents];
		let newDocument = {
			name: '',
			type: 'Document',
			remarks: '',
			fileData: null,
			id: nanoid(),
		};
		updatedDocuments.push(newDocument);
		setDocuments(updatedDocuments);
	};

	const handleRemoveDocument = (key) => {
		let updatedDocuments = [...documents].filter((el, index) => {
			return index !== key;
		});
		setDocuments(updatedDocuments);
	};

	const handleDocumentChange = (e, index) => {
		const {
			target: { value, name },
		} = e;
		let updatedDocuments = [...documents];
		updatedDocuments[index][name] = value;
		setDocuments(updatedDocuments);
	};

	const handleProposalFormFileChange = (e, files) => {
		e.preventDefault();
		let updatedForm = formData.proposalForm;
		updatedForm.fileData = files;
		handleFormDataChange('proposalForm', updatedForm);
	};

	const handleHasProposalFormChange = (e) => {
		let val = str2bool(e.target.value);
		handleFormDataChange('hasProposalForm', val);
	};

	const validateCompany = () => {
		if (formData.companyData.existing && isEmpty(formData.companyData._id)) {
			return false;
		} else if (
			(!formData.companyData.existing && !formData.companyData.companyName) ||
			!formData.companyData.uen
		) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<>
			<Typography variant='h3' style={{ margin: '32px 0px' }}>
				TRANSACTION DETAILS
			</Typography>
			{/* <Grid container spacing={4} direction='row'>
				<Grid item xs={12} md={7}>
				</Grid>
			</Grid> */}
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '100%',
					marginBottom: 16,
				}}
			>
				<Typography
					style={{ minWidth: 192 }}
					color='textPrimary'
					variant='body2'
				>
					Transaction Type
				</Typography>
				<Typography color='textPrimary' variant='body1'>
					{formData.transactionType}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'baseline',
					width: '100%',
					marginBottom: 16,
				}}
			>
				<Typography
					style={{ minWidth: 192 }}
					color='textPrimary'
					variant='body2'
				>
					Insurance
				</Typography>
				<Typography color='textPrimary' variant='body1'>
					{formData.selectedInsuranceType.insuranceType}
					{formData.selectedInsuranceType.insuranceSubType && (
						<> ({formData.selectedInsuranceType.insuranceSubType})</>
					)}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'flex-start',
					width: '100%',
					marginBottom: 16,
				}}
			>
				<Typography
					style={{ minWidth: 192 }}
					color='textPrimary'
					variant='body2'
				>
					Requesting From
				</Typography>
				{formData.transactionType === 'Quotation' ? (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{formData.selectedInsuranceType.isCommercial ? (
							<>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '100%',
										marginBottom: 16,
									}}
								>
									<div
										style={{ display: 'flex', width: '100%', marginBottom: 8 }}
									>
										<FormControl style={{ width: 200 }} component='fieldset'>
											<RadioGroup
												aria-label='existing'
												name='existing'
												value={formData.companyData.existing}
												onChange={(e) => handleExistingChange(e, 'companyData')}
											>
												<FormControlLabel
													style={{ margin: '0px 0px 8px 0px' }}
													value={false}
													control={
														<Radio
															style={{ padding: '0px 8px 0px 0px' }}
															color='primary'
														/>
													}
													label='New Company'
												/>
												<FormControlLabel
													style={{ margin: '0px 0px 8px 0px' }}
													value={true}
													control={
														<Radio
															style={{ padding: '0px 8px 0px 0px' }}
															color='primary'
														/>
													}
													label='Existing Company'
												/>
											</RadioGroup>
										</FormControl>
										<div style={{ width: 400, marginLeft: 24 }}>
											{formData.companyData.existing ? (
												<DataSearch
													type='company'
													handleSelectRecord={handleSelectRecord}
													errorMessage={
														errorMessages[`companyData`] &&
														errorMessages[`companyData`]
													}
												/>
											) : (
												<Grid container spacing={2}>
													<Grid item xs={12} sm={6}>
														<Typography color='textPrimary' variant='body2'>
															Company Name
														</Typography>
														<TextField
															name='companyName'
															value={formData.companyData.companyName}
															onChange={(e) =>
																handleClientFormChange(e, 'companyData')
															}
															variant='filled'
															fullWidth
															margin='dense'
															error={!!errorMessages[`companyData.companyName`]}
															helperText={
																errorMessages[`companyData.companyName`] &&
																errorMessages[`companyData.companyName`]
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<Typography color='textPrimary' variant='body2'>
															UEN
														</Typography>
														<TextField
															name='uen'
															value={formData.companyData.uen}
															onChange={(e) =>
																handleClientFormChange(e, 'companyData')
															}
															variant='filled'
															fullWidth
															margin='dense'
															error={!!errorMessages[`companyData.uen`]}
															helperText={
																errorMessages[`companyData.uen`] &&
																errorMessages[`companyData.uen`]
															}
														/>
													</Grid>
												</Grid>
											)}
										</div>
									</div>
									<div
										style={{
											display: formData.companyData._id ? 'flex' : 'none',
											width: '100%',
											alignItems: 'center',
										}}
									>
										<Avatar>
											<Company
												style={{ fontSize: 24 }}
												colorcode={theme.palette.primary.contrastText}
											/>
										</Avatar>
										<div>
											<Typography
												style={{ marginLeft: 16 }}
												variant='body2'
												color='textPrimary'
											>
												{formData.companyData.companyName}
											</Typography>
											<Typography
												style={{ marginLeft: 16 }}
												variant='body1'
												color='textSecondary'
											>
												UEN: {formData.companyData.uen}
											</Typography>
										</div>
									</div>
								</div>
								<div
									style={{
										display: validateCompany() ? 'flex' : 'none',
										flexDirection: 'column',
										width: '100%',
									}}
								>
									<div
										style={{ display: 'flex', width: '100%', marginBottom: 8 }}
									>
										<FormControl style={{ width: 200 }} component='fieldset'>
											<RadioGroup
												aria-label='existing'
												name='existing'
												value={formData.contactData.existing}
												onChange={(e) => handleExistingChange(e, 'contactData')}
											>
												<FormControlLabel
													style={{ margin: '0px 0px 8px 0px' }}
													value={false}
													control={
														<Radio
															style={{ padding: '0px 8px 0px 0px' }}
															color='primary'
														/>
													}
													label='New Contact'
												/>
												<FormControlLabel
													style={{ margin: '0px 0px 8px 0px' }}
													value={true}
													control={
														<Radio
															style={{ padding: '0px 8px 0px 0px' }}
															color='primary'
														/>
													}
													label='Existing Contact'
												/>
											</RadioGroup>
										</FormControl>
										<div style={{ width: 400, marginLeft: 24 }}>
											{formData.contactData.existing ? (
												<>
													{formData.companyData.existing ? (
														<Autocomplete
															id='data-search'
															value={val}
															onChange={(e, newValue) => {
																handleSelectRecord(newValue.value, 'contact');
															}}
															options={
																formData.companyData.associatedContacts
																	? formData.companyData.associatedContacts
																	: []
															}
															noOptionsText='No Associated Contacts Found'
															getOptionSelected={(option, value) =>
																option.value._id === value._id
															}
															getOptionLabel={(option) => option.label || ''}
															renderInput={(params) => (
																<TextField
																	{...params}
																	variant='filled'
																	placeholder='Search or Select a Contact'
																	error={!!errorMessages[`contactData`]}
																	helperText={
																		errorMessages[`contactData`] &&
																		errorMessages[`contactData`]
																	}
																/>
															)}
														/>
													) : (
														<DataSearch
															type='contact'
															handleSelectRecord={handleSelectRecord}
															errorMessage={
																errorMessages[`contactData`] &&
																errorMessages[`contactData`]
															}
														/>
													)}
												</>
											) : (
												<Grid container spacing={2}>
													<Grid item xs={12} sm={6}>
														<Typography color='textPrimary' variant='body2'>
															First Name
														</Typography>
														<TextField
															name='firstName'
															value={formData.contactData.firstName}
															onChange={(e) =>
																handleClientFormChange(e, 'contactData')
															}
															variant='filled'
															fullWidth
															margin='dense'
															error={!!errorMessages[`contactData.firstName`]}
															helperText={
																errorMessages[`contactData.firstName`] &&
																errorMessages[`contactData.firstName`]
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<Typography color='textPrimary' variant='body2'>
															Last Name
														</Typography>
														<TextField
															name='lastName'
															value={formData.contactData.lastName}
															onChange={(e) =>
																handleClientFormChange(e, 'contactData')
															}
															variant='filled'
															fullWidth
															margin='dense'
															error={!!errorMessages[`contactData.lastName`]}
															helperText={
																errorMessages[`contactData.lastName`] &&
																errorMessages[`contactData.lastName`]
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<Typography color='textPrimary' variant='body2'>
															Email
														</Typography>
														<TextField
															name='email'
															value={formData.contactData.email}
															onChange={(e) =>
																handleClientFormChange(e, 'contactData')
															}
															variant='filled'
															fullWidth
															margin='dense'
															error={!!errorMessages[`contactData.email`]}
															helperText={
																errorMessages[`contactData.email`] &&
																errorMessages[`contactData.email`]
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<Typography color='textPrimary' variant='body2'>
															Mobile
														</Typography>
														<TextField
															name='mobileNo'
															value={formData.contactData.mobileNo}
															onChange={(e) =>
																handleClientFormChange(e, 'contactData')
															}
															variant='filled'
															fullWidth
															margin='dense'
															error={!!errorMessages[`contactData.mobileNo`]}
															helperText={
																errorMessages[`contactData.mobileNo`] &&
																errorMessages[`contactData.mobileNo`]
															}
														/>
													</Grid>
												</Grid>
											)}
										</div>
									</div>
									<div
										style={{
											display: formData.contactData._id ? 'flex' : 'none',
											width: '100%',
											alignItems: 'center',
										}}
									>
										<Avatar>
											<Contacts
												style={{ fontSize: 24, marginLeft: 1 }}
												colorcode={theme.palette.primary.contrastText}
											/>
										</Avatar>
										<div>
											<Typography
												style={{ marginLeft: 16 }}
												variant='body2'
												color='textPrimary'
											>
												{combineNames({
													fName: formData.contactData.firstName,
													mName:
														formData.contactData.middleName !== undefined
															? formData.contactData.middleName
															: null,
													lName:
														formData.contactData.lastName !== undefined
															? formData.contactData.lastName
															: null,
												})}
											</Typography>
											<Typography
												style={{ marginLeft: 16 }}
												variant='body1'
												color='textSecondary'
											>
												{formData.contactData.email}
											</Typography>
										</div>
									</div>
								</div>
							</>
						) : (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
								}}
							>
								<div
									style={{ display: 'flex', width: '100%', marginBottom: 8 }}
								>
									<FormControl style={{ width: 200 }} component='fieldset'>
										<RadioGroup
											aria-label='existing'
											name='existing'
											value={formData.contactData.existing}
											onChange={(e) => handleExistingChange(e, 'contactData')}
										>
											<FormControlLabel
												style={{ margin: '0px 0px 8px 0px' }}
												value={false}
												control={
													<Radio
														style={{ padding: '0px 8px 0px 0px' }}
														color='primary'
													/>
												}
												label='New Contact'
											/>
											<FormControlLabel
												style={{ margin: '0px 0px 8px 0px' }}
												value={true}
												control={
													<Radio
														style={{ padding: '0px 8px 0px 0px' }}
														color='primary'
													/>
												}
												label='Existing Contact'
											/>
										</RadioGroup>
									</FormControl>
									<div style={{ width: 400, marginLeft: 24 }}>
										{formData.contactData.existing ? (
											<DataSearch
												type='contact'
												handleSelectRecord={handleSelectRecord}
												errorMessage={
													errorMessages[`contactData`] &&
													errorMessages[`contactData`]
												}
											/>
										) : (
											<Grid container spacing={2}>
												<Grid item xs={12} sm={6}>
													<Typography color='textPrimary' variant='body2'>
														First Name
													</Typography>
													<TextField
														name='firstName'
														value={formData.contactData.firstName}
														onChange={(e) =>
															handleClientFormChange(e, 'contactData')
														}
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`contactData.firstName`]}
														helperText={
															errorMessages[`contactData.firstName`] &&
															errorMessages[`contactData.firstName`]
														}
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Typography color='textPrimary' variant='body2'>
														Last Name
													</Typography>
													<TextField
														name='lastName'
														value={formData.contactData.lastName}
														onChange={(e) =>
															handleClientFormChange(e, 'contactData')
														}
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`contactData.lastName`]}
														helperText={
															errorMessages[`contactData.lastName`] &&
															errorMessages[`contactData.lastName`]
														}
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Typography color='textPrimary' variant='body2'>
														Email
													</Typography>
													<TextField
														name='email'
														value={formData.contactData.email}
														onChange={(e) =>
															handleClientFormChange(e, 'contactData')
														}
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`contactData.email`]}
														helperText={
															errorMessages[`contactData.email`] &&
															errorMessages[`contactData.email`]
														}
													/>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Typography color='textPrimary' variant='body2'>
														Mobile
													</Typography>
													<TextField
														name='mobileNo'
														value={formData.contactData.mobileNo}
														onChange={(e) =>
															handleClientFormChange(e, 'contactData')
														}
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`contactData.mobileNo`]}
														helperText={
															errorMessages[`contactData.mobileNo`] &&
															errorMessages[`contactData.mobileNo`]
														}
													/>
												</Grid>
											</Grid>
										)}
									</div>
								</div>
								<div
									style={{
										display: formData.contactData._id ? 'flex' : 'none',
										width: '100%',
										alignItems: 'center',
									}}
								>
									<Avatar>
										<Contacts
											style={{ fontSize: 24, marginLeft: 1 }}
											colorcode={theme.palette.primary.contrastText}
										/>
									</Avatar>
									<div>
										<Typography
											style={{ marginLeft: 16 }}
											variant='body2'
											color='textPrimary'
										>
											{combineNames({
												fName: formData.contactData.firstName,
												mName:
													formData.contactData.middleName !== undefined
														? formData.contactData.middleName
														: null,
												lName:
													formData.contactData.lastName !== undefined
														? formData.contactData.lastName
														: null,
											})}
										</Typography>
										<Typography
											style={{ marginLeft: 16 }}
											variant='body1'
											color='textSecondary'
										>
											{formData.contactData.email}
										</Typography>
									</div>
								</div>
							</div>
						)}
					</div>
				) : (
					<>
						{formData.selectedInsuranceType.isCommercial ? (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div
									style={{
										display: formData.companyData._id ? 'flex' : 'none',
										width: '100%',
										alignItems: 'center',
										marginBottom: 16,
									}}
								>
									<Avatar>
										<Company
											style={{ fontSize: 24 }}
											colorcode={theme.palette.primary.contrastText}
										/>
									</Avatar>
									<div>
										<Typography
											style={{ marginLeft: 16 }}
											variant='body2'
											color='textPrimary'
										>
											{formData.companyData.companyName}
										</Typography>
										<Typography
											style={{ marginLeft: 16 }}
											variant='body1'
											color='textSecondary'
										>
											UEN: {formData.companyData.uen}
										</Typography>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: 8,
									}}
								>
									<Typography
										style={{ marginRight: 32 }}
										variant='body2'
										color='textPrimary'
									>
										{`Has the Contact changed for this ${formData.transactionType}?`}
									</Typography>
									<FormControl component='fieldset'>
										<RadioGroup
											style={{ flexDirection: 'row' }}
											aria-label='changeContact'
											name='changeContact'
											value={changeContact}
											onChange={handleChangeContact}
										>
											<FormControlLabel
												value={true}
												control={<Radio color='primary' />}
												label='Yes'
											/>
											<FormControlLabel
												value={false}
												control={<Radio color='primary' />}
												label='No'
											/>
										</RadioGroup>
									</FormControl>
								</div>
								<div
									style={{
										display: validateCompany() ? 'flex' : 'none',
										flexDirection: 'column',
										width: '100%',
									}}
								>
									{changeContact && (
										<div
											style={{
												display: 'flex',
												width: '100%',
												marginBottom: 8,
											}}
										>
											<FormControl style={{ width: 200 }} component='fieldset'>
												<RadioGroup
													aria-label='existing'
													name='existing'
													value={formData.contactData.existing}
													onChange={(e) =>
														handleExistingChange(e, 'contactData')
													}
												>
													<FormControlLabel
														style={{ margin: '0px 0px 8px 0px' }}
														value={false}
														control={
															<Radio
																style={{ padding: '0px 8px 0px 0px' }}
																color='primary'
															/>
														}
														label='New Contact'
													/>
													<FormControlLabel
														style={{ margin: '0px 0px 8px 0px' }}
														value={true}
														control={
															<Radio
																style={{ padding: '0px 8px 0px 0px' }}
																color='primary'
															/>
														}
														label='Existing Contact'
													/>
												</RadioGroup>
											</FormControl>
											<div style={{ width: 400, marginLeft: 24 }}>
												{formData.contactData.existing ? (
													<>
														{formData.companyData.existing ? (
															<Autocomplete
																id='data-search'
																value={val}
																onChange={(e, newValue) => {
																	handleSelectRecord(newValue.value, 'contact');
																}}
																options={
																	formData.companyData.associatedContacts
																		? formData.companyData.associatedContacts
																		: []
																}
																noOptionsText='No Associated Contacts Found'
																getOptionSelected={(option, value) =>
																	option.value._id === value._id
																}
																getOptionLabel={(option) => option.label || ''}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		variant='filled'
																		placeholder='Search or Select a Contact'
																		error={!!errorMessages[`contactData`]}
																		helperText={
																			errorMessages[`contactData`] &&
																			errorMessages[`contactData`]
																		}
																	/>
																)}
															/>
														) : (
															<DataSearch
																type='contact'
																handleSelectRecord={handleSelectRecord}
																errorMessage={
																	errorMessages[`contactData`] &&
																	errorMessages[`contactData`]
																}
															/>
														)}
													</>
												) : (
													<Grid container spacing={2}>
														<Grid item xs={12} sm={6}>
															<Typography color='textPrimary' variant='body2'>
																First Name
															</Typography>
															<TextField
																name='firstName'
																value={formData.contactData.firstName}
																onChange={(e) =>
																	handleClientFormChange(e, 'contactData')
																}
																variant='filled'
																fullWidth
																margin='dense'
																error={!!errorMessages[`contactData.firstName`]}
																helperText={
																	errorMessages[`contactData.firstName`] &&
																	errorMessages[`contactData.firstName`]
																}
															/>
														</Grid>
														<Grid item xs={12} sm={6}>
															<Typography color='textPrimary' variant='body2'>
																Last Name
															</Typography>
															<TextField
																name='lastName'
																value={formData.contactData.lastName}
																onChange={(e) =>
																	handleClientFormChange(e, 'contactData')
																}
																variant='filled'
																fullWidth
																margin='dense'
																error={!!errorMessages[`contactData.lastName`]}
																helperText={
																	errorMessages[`contactData.lastName`] &&
																	errorMessages[`contactData.lastName`]
																}
															/>
														</Grid>
														<Grid item xs={12} sm={6}>
															<Typography color='textPrimary' variant='body2'>
																Email
															</Typography>
															<TextField
																name='email'
																value={formData.contactData.email}
																onChange={(e) =>
																	handleClientFormChange(e, 'contactData')
																}
																variant='filled'
																fullWidth
																margin='dense'
																error={!!errorMessages[`contactData.email`]}
																helperText={
																	errorMessages[`contactData.email`] &&
																	errorMessages[`contactData.email`]
																}
															/>
														</Grid>
														<Grid item xs={12} sm={6}>
															<Typography color='textPrimary' variant='body2'>
																Mobile
															</Typography>
															<TextField
																name='mobileNo'
																value={formData.contactData.mobileNo}
																onChange={(e) =>
																	handleClientFormChange(e, 'contactData')
																}
																variant='filled'
																fullWidth
																margin='dense'
																error={!!errorMessages[`contactData.mobileNo`]}
																helperText={
																	errorMessages[`contactData.mobileNo`] &&
																	errorMessages[`contactData.mobileNo`]
																}
															/>
														</Grid>
													</Grid>
												)}
											</div>
										</div>
									)}
									<div
										style={{
											display: formData.contactData._id ? 'flex' : 'none',
											width: '100%',
											alignItems: 'center',
										}}
									>
										<Avatar>
											<Contacts
												style={{ fontSize: 24, marginLeft: 1 }}
												colorcode={theme.palette.primary.contrastText}
											/>
										</Avatar>
										<div>
											<Typography
												style={{ marginLeft: 16 }}
												variant='body2'
												color='textPrimary'
											>
												{combineNames({
													fName: formData.contactData.firstName,
													mName:
														formData.contactData.middleName !== undefined
															? formData.contactData.middleName
															: null,
													lName:
														formData.contactData.lastName !== undefined
															? formData.contactData.lastName
															: null,
												})}
											</Typography>
											<Typography
												style={{ marginLeft: 16 }}
												variant='body1'
												color='textSecondary'
											>
												{formData.contactData.email}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div
									style={{
										display: formData.contactData._id ? 'flex' : 'none',
										width: '100%',
										alignItems: 'center',
									}}
								>
									<Avatar>
										<Contacts
											style={{ fontSize: 24, marginLeft: 1 }}
											colorcode={theme.palette.primary.contrastText}
										/>
									</Avatar>
									<div>
										<Typography
											style={{ marginLeft: 16 }}
											variant='body2'
											color='textPrimary'
										>
											{combineNames({
												fName: formData.contactData.firstName,
												mName:
													formData.contactData.middleName !== undefined
														? formData.contactData.middleName
														: null,
												lName:
													formData.contactData.lastName !== undefined
														? formData.contactData.lastName
														: null,
											})}
										</Typography>
										<Typography
											style={{ marginLeft: 16 }}
											variant='body1'
											color='textSecondary'
										>
											{formData.contactData.email}
										</Typography>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</div>
			<div style={{ marginBottom: 24 }}>
				<Typography variant='h3' style={{ margin: '32px 0px' }}>
					DOCUMENTS TO REQUEST
				</Typography>
				<Typography color='textPrimary' variant='body1'>
					Kindly check the list of documents below, you may add and/or remove
					any document(s) in the list.
				</Typography>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						margin: '8px 0px',
					}}
				>
					<Typography
						style={{ marginRight: 32 }}
						variant='body2'
						color='textPrimary'
					>
						Do you need a Proposal Form to be filled up by your Client?
					</Typography>
					<FormControl component='fieldset'>
						<RadioGroup
							style={{ flexDirection: 'row' }}
							aria-label='hasProposalForm'
							name='hasProposalForm'
							value={formData.hasProposalForm}
							onChange={handleHasProposalFormChange}
						>
							<FormControlLabel
								value={true}
								control={<Radio color='primary' />}
								label='Yes'
							/>
							<FormControlLabel
								value={false}
								control={<Radio color='primary' />}
								label='No'
							/>
						</RadioGroup>
					</FormControl>
				</div>
				{formData.hasProposalForm && (
					<div style={{ width: '60%' }}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-evenly',
								width: '50%',
								margin: 24,
							}}
						>
							<Button
								className={classes.methodButton}
								classes={{
									label: classes.methodButtonLabel,
									startIcon: classes.methodButtonIcon,
								}}
								variant='outlined'
								color={
									formData.proposalForm.method === 'url' ? 'primary' : 'default'
								}
								onClick={() => handleMethodChange('url')}
							>
								<Badge
									className={classes.badge}
									classes={{
										anchorOriginTopRightRectangular: classes.offset,
										colorPrimary: classes.badgeIcon,
									}}
									badgeContent={
										formData.proposalForm.method === 'url' ? (
											<CheckCircleIcon style={{ height: 24, width: 24 }} />
										) : null
									}
									color='primary'
									overlap='rectangular'
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
								>
									<URL
										style={{ fontSize: 24 }}
										colorcode={
											formData.proposalForm.method === 'url' &&
											theme.palette.tertiary.main
										}
									/>
									<Typography variant='body1'>Website URL</Typography>
								</Badge>
							</Button>
							<Button
								className={classes.methodButton}
								classes={{
									label: classes.methodButtonLabel,
									startIcon: classes.methodButtonIcon,
								}}
								variant='outlined'
								color={
									formData.proposalForm.method === 'upload'
										? 'primary'
										: 'default'
								}
								onClick={() => handleMethodChange('upload')}
							>
								<Badge
									className={classes.badge}
									classes={{
										anchorOriginTopRightRectangular: classes.offset,
										colorPrimary: classes.badgeIcon,
									}}
									badgeContent={
										formData.proposalForm.method === 'upload' ? (
											<CheckCircleIcon style={{ height: 24, width: 24 }} />
										) : null
									}
									color='primary'
									overlap='rectangular'
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
								>
									<UploadDocument
										style={{ fontSize: 24 }}
										colorcode={
											formData.proposalForm.method === 'upload' &&
											theme.palette.tertiary.main
										}
									/>
									<Typography variant='body1'>Upload Form</Typography>
								</Badge>
							</Button>
						</div>
						{formData.proposalForm.method === 'upload' ? (
							<div
								style={{
									marginBottom: 24,
								}}
							>
								<div style={{ marginBottom: 8, display: 'flex' }}>
									<div style={{ marginLeft: 'auto' }}>
										<Tooltip content={printReqString()} />
									</div>
								</div>
								<FileUploader
									fileLimit={1}
									files={formData.proposalForm.fileData}
									handleFileChange={handleProposalFormFileChange}
									formErrorMessage={
										errorMessages[`proposalForm`] &&
										errorMessages[`proposalForm`]
									}
								/>
							</div>
						) : (
							<div style={{ marginBottom: 24 }}>
								<Typography color='textPrimary' variant='body2'>
									Link to Download Proposal Form
								</Typography>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										position: 'relative',
										width: '60%',
										marginBottom: 8,
									}}
								>
									<TextField
										name='url'
										value={formData.proposalForm.url}
										onChange={(e) => handleMethodInfoChange(e)}
										placeholder='e.g. www.example.com/link-to-proposal-form'
										variant='filled'
										fullWidth
										margin='dense'
										error={!!errorMessages[`proposalForm.url`]}
										helperText={
											errorMessages[`proposalForm.url`] &&
											errorMessages[`proposalForm.url`]
										}
									/>
								</div>
								<Typography color='textPrimary' variant='body2'>
									Proposal Form Download Instructions
								</Typography>
								<TextField
									name='instructions'
									value={formData.proposalForm.instructions}
									multiline
									onChange={(e) => handleMethodInfoChange(e)}
									placeholder='Provide instructions for your client on which forms to download or fill-up via the link provided'
									variant='filled'
									fullWidth
									margin='dense'
									minRows={10}
									error={!!errorMessages[`proposalForm.instructions`]}
									helperText={
										errorMessages[`proposalForm.instructions`] &&
										errorMessages[`proposalForm.instructions`]
									}
								/>
							</div>
						)}
					</div>
				)}
				<div style={{ width: '60%' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							marginBottom: 8,
						}}
					>
						<Typography
							style={{ flexGrow: 1 }}
							color='textPrimary'
							variant='body2'
						>
							List of Supporting Documents
						</Typography>
						<Button
							color='primary'
							startIcon={<PlaylistAddCheckOutlinedIcon />}
							onClick={() => setOpen(true)}
						>
							View Suggested Checklist
						</Button>
					</div>
					<TableContainer
						style={{
							minHeight: 350,
							maxHeight: 350,
							boxShadow: '0px 1px 2px 0px #0000001a',
							borderRadius: 8,
						}}
					>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell
										style={{ background: theme.palette.background.paper }}
									>
										<Typography color='textPrimary' variant='h5'>
											DOCUMENT NAME
										</Typography>
									</TableCell>
									{/* <TableCell style={{ background: theme.palette.background.paper }}>
										<Typography color='textPrimary' variant='h5'>
											DOCUMENT TYPE
										</Typography>
									</TableCell> */}
									<TableCell
										style={{ background: theme.palette.background.paper }}
									></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{formData.supportingDocuments.map((item, index) => (
									<Row
										key={index}
										index={index}
										item={item}
										handleRemoveDocument={handleRemoveDocument}
										handleDocumentChange={handleDocumentChange}
										errorMessages={errorMessages}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Button
						color='primary'
						onClick={handleAddDocument}
						className={classes.button}
						startIcon={<AddCircleOutlineOutlinedIcon />}
					>
						Add Document
					</Button>
				</div>
			</div>
			<SuggestedChecklist
				open={open}
				handleClose={() => setOpen(false)}
				checklist={checklist}
			/>
		</>
	);
}
