import Joi from 'joi';

const Login = Joi.object({
	email: Joi.string()
		.label('Email')
		.trim()
		.email({ tlds: { allow: false } })
		.required(),
	password: Joi.string().label('Password').trim().min(8).max(30).required(),
	// password: Joi.string().label('').trim().pattern(new RegExp('^[a-zA-Z0-9]{8,30}$')).required(),
});

export default Login;
