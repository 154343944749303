import Backdrop from '../../../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import FilterMenu from '../../../../components/FilterMenu';
import PrincipalModal from '../../../../components/Modals/principals';
import api from '../../../../config/api';
import { filterData } from '../../../../utils/filterData';
import DataRow from './dataRow';
import UnderwriterForm from './underwriterForm';
import { ContactAdd, Delete, Export } from '../../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	button: {},
	content: {
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	contentShift: {
		marginRight: 0,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawer: {
		width: 700,
		flexShrink: 0,
	},
	deleteIcon: {
		padding: 0,
		marginLeft: 16,
	},
	deleteText: {
		color: theme.palette.primary.main,
	},
}));

const columns = [
	{ label: 'First Name', name: 'firstName', type: 'string' },
	{ label: 'Last Name', name: 'lastName', type: 'string' },
	{ label: 'Email', name: 'email', type: 'string' },
	{ label: 'Mobile No.', name: 'mobileNo', type: 'string' },
	{ label: 'Office No.', name: 'officeNo', type: 'string' },
	{ label: 'Company', name: 'principalName', type: 'string' },
	{ label: 'Department', name: 'department', type: 'string' },
];

const initFilter = {
	processor: 'And',
	column: columns[0].name,
	type: columns[0].type,
	operator:
		columns[0].type === 'string' || columns[0].type === 'array'
			? 'contains'
			: '=',
	value: '',
};

const initForm = {
	firstName: '',
	middleName: '',
	lastName: '',
	email: '',
	mobileNo: '',
	officeNo: '',
	principal: '',
	department: '',
	status: false,
};

export default function Underwriters(props) {
	const classes = useStyles();
	const theme = useTheme();
	const csvLink = useRef();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [selectedUnderwriter, setSelectedUnderwriter] = useState(initForm);
	const [underwriters, setUnderwriters] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [editable, setEditable] = useState(false);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogBody, setDialogBody] = useState('confirmDeleteUnderwriters');
	const [filterList, setFilterList] = useState([initFilter]);
	const [sortOptions, setSortOptions] = useState({});
	const [viewTags, setViewTags] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});
	const [triggerListUpdate, setTriggerListUpdate] = useState(true);

	const [action, setAction] = useState(null);

	useEffect(() => {
		if (triggerListUpdate) getUnderwriters();
	}, [triggerListUpdate]);

	const getUnderwriters = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('underwriters')
			.then((response) => {
				if (response.data.data.length > 0) {
					let bulkList = response.data.data.map((underwriter, index) => {
						return { ...underwriter, checked: false };
					});
					setUnderwriters(bulkList);
				}
				setTriggerListUpdate(false);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleDelete = () => {
		setDialogOpen(false);
		setBackdropOpen(true);
		let checked = underwriters.filter((props) => props.checked);
		let ids = checked.map((item) => item._id);
		api.post('underwriters/bulk-delete', ids).then((res) => {
			if (res.data.ok) {
				toast.success('Contact(s) deleted!');
				setTriggerListUpdate(true);
				setError(false);
				setErrorMessages({});
				setCheckAll(false);
				setPage(0);
				setBackdropOpen(false);
			} else {
				toast.error('An error has occured!');
			}
		});
	};

	const toggleDialog = () => {
		if (!dialogOpen) {
			return setDialogOpen(true);
		}
		setDialogOpen(!dialogOpen);
	};

	const handleReturn = () => {
		setAction(null);
		setSelectedUnderwriter(initForm);
		setTriggerListUpdate(true);
	};

	const handleCancel = () => {
		setDialogOpen(false);
	};

	const totalChecked = () => {
		return underwriters.filter((props) => props.checked).length;
	};

	const handleCheckAll = (e) => {
		let updatedUnderwriters = [...underwriters];
		updatedUnderwriters.forEach(
			(underwriter) => (underwriter.checked = e.target.checked)
		);
		setCheckAll(e.target.checked);
		setUnderwriters(updatedUnderwriters);
	};

	const handleCheckbox = (e, index) => {
		let updatedUnderwriters = [...underwriters];
		updatedUnderwriters.find((x) => x._id === index).checked = e.target.checked;
		setUnderwriters(updatedUnderwriters);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleSearch = (e) => {
		setPage(0);
		setSearchValue(e.target.value);
	};

	const handleClearSearch = () => {
		setPage(0);
		setSearchValue('');
	};

	const searchData = (data) => {
		if (searchValue) {
			let val = searchValue.replace(/\s+/g, ' ').toLowerCase();
			let results = data.filter((item) => {
				return Object.keys(item).some((key) => {
					if (
						key === 'checked' ||
						key === '_id' ||
						key === 'principalId' ||
						key === '__v' ||
						key === 'createdBy' ||
						key === 'orgId'
					)
						return;
					if (typeof item[key] === 'boolean') {
						return String(item[key]).toLowerCase().includes(val.toLowerCase());
					} else {
						return String(item[key]).toLowerCase().includes(val.toLowerCase());
					}
				});
			});
			return results;
		}
		return data;
	};

	const handleExportData = () => {
		csvLink.current.link.click();
	};

	const handleSort = (key) => {
		setPage(0);
		setSortOptions((prevState) => ({ [key]: !prevState[key] }));
	};

	const sortData = (data) => {
		let sortedData = data;
		Object.keys(sortOptions).forEach((e) => {
			return sortedData.sort(compareByDesc(e, sortOptions[e]));
		});
		return sortedData;
	};

	const compareByDesc = (key, order) => {
		if (order) {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key].toLowerCase() < b[key].toLowerCase() ? -1 : 1;
			};
		} else {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key].toLowerCase() > b[key].toLowerCase() ? -1 : 1;
			};
		}
	};

	const getFilteredUnderwriters = () => {
		let initialList = underwriters;
		let sortedUnderwriters = sortData(initialList);
		let filteredUnderwriters = filterData(sortedUnderwriters, filterList);
		let searchedUnderwriters = searchData(filteredUnderwriters);
		return searchedUnderwriters;
	};

	const filteredUnderwriters = getFilteredUnderwriters();

	const emptyRows =
		rowsPerPage -
		Math.min(rowsPerPage, filteredUnderwriters.length - page * rowsPerPage);

	const responseList = () => {
		return filteredUnderwriters
			.slice(page * 5, page * 5 + 5)
			.map((currentUnderwriter) => (
				<DataRow
					key={currentUnderwriter._id}
					data={currentUnderwriter}
					handleCheckbox={handleCheckbox}
					setAction={setAction}
					setSelectedUnderwriter={setSelectedUnderwriter}
				/>
			));
	};

	const generateFileName = () => {
		let generatedDate = moment(new Date()).format('DD-MM-YYYY');
		return `principal-contact-data-${generatedDate}.csv`;
	};

	const generateExportData = () => {
		let data = cloneDeep(filteredUnderwriters).map((row, index) => {
			delete row._id;
			delete row.createdBy;
			delete row.orgId;
			delete row.__v;
			delete row.checked;
			return row;
		});
		return data;
	};

	if (action)
		return (
			<UnderwriterForm
				action={action}
				handleReturn={handleReturn}
				underwriter={selectedUnderwriter}
			/>
		);
	return (
		<>
			<Backdrop text='Deleting Contact(s)...' open={backdropOpen} />
			<div margin='auto'>
				<div className='actionBar' style={{ marginTop: 0 }}>
					<Box display='flex' alignItems='center'>
						<Box>
							<Button
								onClick={() => setAction('add')}
								color='primary'
								className={classes.button}
								startIcon={
									<ContactAdd colorcode={theme.palette.tertiary.main} />
								}
							>
								New Contact
							</Button>
						</Box>
						<Box flexGrow={1}>
							<Button
								color='primary'
								className={classes.button}
								startIcon={<Export colorcode={theme.palette.tertiary.main} />}
								onClick={() => handleExportData()}
							>
								Export Contacts
							</Button>
							<CSVLink
								data={generateExportData()}
								filename={generateFileName()}
								className='hidden'
								ref={csvLink}
								target='_blank'
							/>
						</Box>
					</Box>
				</div>
				<div className='tableBar'>
					<Box display='flex' alignItems='center'>
						<Box style={{ width: 400 }}>
							<TextField
								style={{ margin: 0 }}
								value={searchValue}
								onChange={(e) => handleSearch(e)}
								placeholder='Search Contacts'
								variant='filled'
								fullWidth
								margin='dense'
								InputProps={{
									startAdornment: (
										<InputAdornment
											position='start'
											style={{ marginTop: 0, color: theme.palette.grey[500] }}
										>
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												onClick={handleClearSearch}
												disableFocusRipple
												disableRipple
												style={{
													backgroundColor: 'transparent',
													display: !searchValue && 'none',
												}}
											>
												<ClearIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Box style={{ marginLeft: 16 }}>
							<FilterMenu
								columns={columns}
								filterList={filterList}
								setFilterList={setFilterList}
							/>
						</Box>
					</Box>
				</div>
				<div className='pagination'>
					<TablePagination
						className='pagination'
						component='div'
						count={filteredUnderwriters.length}
						rowsPerPageOptions={[]}
						rowsPerPage={rowsPerPage}
						page={page}
						SelectProps={{
							inputProps: {
								'aria-label': 'rows per page',
							},
							native: true,
						}}
						onPageChange={handleChangePage}
					/>
				</div>
				<div
					className={clsx(classes.content, {
						[classes.contentShift]: open,
					})}
				>
					<Box
						display='flex'
						style={{
							overflow: 'auto',
							border: '1px solid #edf2f7',
							borderRadius: 8,
						}}
					>
						<Box style={{ width: '100%' }}>
							{totalChecked() > 0 && (
								<div
									style={{
										display: 'flex',
										width: '100%',
										height: 40,
										alignItems: 'center',
										backgroundColor:
											totalChecked() > 0
												? theme.palette.tertiary.light
												: theme.palette.background.paper,
									}}
								>
									{totalChecked() > 0 && (
										<div style={{ paddingLeft: 20, display: 'flex' }}>
											<Typography
												className={classes.deleteText}
												variant='body1'
												align='left'
											>
												{totalChecked()} Selected
											</Typography>
											<IconButton
												className={classes.deleteIcon}
												onClick={() => toggleDialog()}
											>
												<Delete colorcode={theme.palette.text.secondary} />
											</IconButton>
										</div>
									)}
								</div>
							)}
							<table className='tableContent' cellSpacing='0'>
								<thead>
									<tr
										style={{
											height: 52,
											backgroundColor: theme.palette.background.paper,
										}}
									>
										<th className='checkBox'>
											<Checkbox
												checked={checkAll}
												onChange={(e) => handleCheckAll(e)}
												inputProps={{
													'aria-label': 'primary checkbox',
												}}
											/>
										</th>
										<th onClick={() => handleSort('firstName')}>
											<div className='aligner'>
												<div className='text'>Name</div>
												<div className='icon'>
													{sortOptions['firstName'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('email')}>
											<div className='aligner'>
												<div className='text'>Email</div>
												<div className='icon'>
													{sortOptions['email'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('mobileNo')}>
											<div className='aligner'>
												<div className='text'>Office / Mobile</div>
												<div className='icon'>
													{sortOptions['mobileNo'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('principalName')}>
											<div className='aligner'>
												<div className='text'>Company</div>
												<div className='icon'>
													{sortOptions['principalName'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										<th onClick={() => handleSort('department')}>
											<div className='aligner'>
												<div className='text'>Department</div>
												<div className='icon'>
													{sortOptions['department'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th>
										{/* <th onClick={() => handleSort('status')}>
											<div className='aligner'>
												<div className='text'>Status</div>
												<div className='icon'>
													{sortOptions['status'] ? (
														<ArrowDropUpIcon />
													) : (
														<ArrowDropDownIcon />
													)}
												</div>
											</div>
										</th> */}
									</tr>
								</thead>
								<tbody>
									{loading ? (
										<tr
											style={{
												height: 320,
											}}
										>
											<td
												colSpan={6}
												className='noResults'
												style={{
													textAlign: 'center',
													border: '1px solid #edf2f7',
												}}
											>
												<CircularProgress size={80} />
											</td>
										</tr>
									) : (
										<>
											{responseList()}
											{emptyRows > 0 && emptyRows < 5 && (
												<tr
													style={{
														height: 64 * emptyRows,
													}}
												>
													<td
														colSpan={6}
														style={{
															border: '1px solid #edf2f7',
														}}
													/>
												</tr>
											)}
										</>
									)}
									{!filteredUnderwriters.length && !loading && (
										<tr
											style={{
												height: 320,
											}}
										>
											<td
												colSpan={6}
												className='noResults'
												style={{
													textAlign: 'center',
													border: '1px solid #edf2f7',
												}}
											>
												No Results Found
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</Box>
						{/* <div className={classes.drawer}>
						<UnderwriterProfile
							viewTags={viewTags}
							underwriter={selectedUnderwriter}
							editable={editable}

							handleDeleteTag={handleDeleteTag}
							handleSave={handleSave}
							handleEditable={handleEditable}
							handleInputChange={handleInputChange}
							handleDateChange={handleDateChange}
							handleAddTag={handleAddTag}
						/>
					</div> */}
					</Box>
				</div>
				<PrincipalModal
					open={dialogOpen}
					body={dialogBody}
					handleCancel={() => handleCancel()}
					handleConfirm={() => handleDelete()}
					handleClose={() => toggleDialog()}
				/>
			</div>
		</>
	);
}
