import DateFnsUtils from '@date-io/date-fns';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventIcon from '@material-ui/icons/Event';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import IndustryList from '../../../components/Data/industries.json';
import api from '../../../config/api';
import CompanyData from '../companyData.json';
import ContactData from '../contactData.json';
import matchSorter from 'match-sorter';
import DataSearch from '../../../components/DataSearch';
import { Delete } from '../../../components/Icons';

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const getIndustry = (val) => {
	let obj = IndustryList.find((item) => item.section === val);
	if (obj !== undefined) {
		return obj.name;
	} else {
		return val;
	}
};

const useStyles = makeStyles((theme) => ({
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	card: {
		marginBottom: 16,
		backgroundColor: theme.palette.background.default,
	},
	cardContent: {
		padding: 24,
		paddingTop: 0,
	},
}));

export default function AssociatedCompanyForm(props) {
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const [open, setOpen] = useState(true);
	const [company, setCompany] = useState(props.company);
	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState([]);

	const prepareData = () => {
		setLoading(true);
		setCompanies(CompanyData);
		setLoading(false);
	};

	const getContacts = () => {
		setLoading(true);
		api
			.get('companies')
			.then((response) => {
				setCompanies(response.data);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		if (company.isExisting) {
			prepareData();
		}
	}, [company.isExisting]);

	const toggleOpen = () => {
		if (!open) {
			setOpen(true);
		}
		setOpen(!open);
	};

	return (
		<Card className={classes.card}>
			<CardHeader
				title={
					<>
						<div
							style={{
								display: 'flex',
								alignItems: isDesktopOrLaptop && 'center',
								flexDirection: isDesktopOrLaptop ? 'row' : 'column',
							}}
						>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<IconButton
									className={clsx(classes.expand, {
										[classes.expandOpen]: open,
									})}
									style={{ marginRight: 8 }}
									onClick={() => toggleOpen()}
									aria-expanded={open}
									aria-label='show more'
								>
									<ExpandMoreIcon />
								</IconButton>
								{company.formData.companyName ? (
									<Typography color='textPrimary' variant='h5'>
										{company.formData.companyName}
									</Typography>
								) : (
									`[Name of Company ${props.index}]`
								)}
							</div>
							{isDesktopOrLaptop ? (
								<>
									{company.isPrimary && (
										<Chip
											style={{
												marginLeft: 16,
												color: theme.palette.background.secondary,
												backgroundColor: theme.palette.tertiary.main,
											}}
											label='Primary Company'
										/>
									)}
									<div style={{ marginLeft: 'auto' }}>
										<Button
											style={{ marginRight: 8 }}
											variant='outlined'
											color='primary'
											disabled={company.isPrimary}
											onClick={() => props.handleChangePrimary(company.id)}
										>
											Set as Primary
										</Button>
										<Button
											color='primary'
											disabled={props.disableRemove}
											startIcon={
												<Delete
													style={{ fontSize: 24 }}
													colorcode={
														props.disableRemove
															? theme.palette.grey[500]
															: theme.palette.tertiary.main
													}
												/>
											}
											onClick={() => props.handleRemoveCompany(company.id)}
										>
											Remove
										</Button>
									</div>
								</>
							) : (
								<div
									style={{
										marginLeft: 'auto',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									{company.isPrimary ? (
										<Chip
											style={{
												marginLeft: 16,
												width: 'fit-content',
												color: theme.palette.background.secondary,
												backgroundColor: theme.palette.tertiary.main,
											}}
											label='Primary Company'
										/>
									) : (
										<Button
											style={{ marginLeft: 16, width: 'fit-content' }}
											variant='outlined'
											color='primary'
											disabled={company.isPrimary}
											onClick={() => props.handleChangePrimary(company.id)}
										>
											Set as Primary
										</Button>
									)}
									<IconButton
										style={{
											color:
												!props.disableRemove && theme.palette.tertiary.main,
										}}
										disabled={props.disableRemove}
										onClick={() => props.handleRemoveCompany(company.id)}
									>
										<Delete
											style={{ fontSize: 24 }}
											colorcode={
												props.disableRemove
													? theme.palette.grey[500]
													: theme.palette.tertiary.main
											}
										/>
									</IconButton>
								</div>
							)}
						</div>
					</>
				}
			/>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<CardContent classes={{ root: classes.cardContent }}>
					<FormControl style={{ marginBottom: 16 }} component='fieldset'>
						<RadioGroup
							aria-label='existing'
							name='isExisting'
							value={company.isExisting}
							onChange={(e) => props.handleChangeExisting(e, props.index)}
						>
							<FormControlLabel
								value={false}
								control={<Radio color='primary' />}
								label='New Company'
							/>
							<FormControlLabel
								value={true}
								control={<Radio color='primary' />}
								label='Existing Company'
							/>
						</RadioGroup>
					</FormControl>
					{company.isExisting && (
						<div style={{ width: 330, marginBottom: 24 }}>
							<DataSearch
								type='company'
								handleSelectRecord={props.handleSelectCompany}
								index={props.index}
							/>
							{/* {props.showDuplicateMessage && (
											<Typography style={{marginLeft: 2}} color='error' variant='body2'>
												Selected option already chosen
											</Typography>
										)} */}
						</div>
					)}
					{(!company.isExisting || company.existingId) && (
						<Grid container spacing={4}>
							<Grid item xs={12} sm={4}>
								<Grid container justifyContent='flex-start'>
									<Typography color='textPrimary' variant='body2' align='left'>
										Company Name
									</Typography>
									<Typography
										style={{ marginLeft: 2 }}
										color='error'
										variant='body2'
										align='right'
									>
										*
									</Typography>
								</Grid>
								<TextField
									name='companyName'
									value={company.formData.companyName}
									onChange={(e) =>
										props.handleCompanyInputChange(e, props.index)
									}
									placeholder={!company.existingId ? 'Enter Company Name' : ''}
									disabled={!company.existingId ? false : true}
									InputProps={{
										disableUnderline: !!company.existingId,
										style: { color: 'unset' },
									}}
									variant={!company.existingId ? 'filled' : 'standard'}
									fullWidth
									margin='dense'
									error={
										!!props.errorMessages[
											`companies.${props.index}.formData.companyName`
										]
									}
									helperText={
										props.errorMessages[
											`companies.${props.index}.formData.companyName`
										]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Grid container justifyContent='flex-start'>
									<Typography color='textPrimary' variant='body2' align='left'>
										Unique Entity Number (UEN)
									</Typography>
									<Typography
										style={{ marginLeft: 2 }}
										color='error'
										variant='body2'
										align='right'
									>
										*
									</Typography>
								</Grid>
								<TextField
									name='uen'
									value={company.formData.uen}
									onChange={(e) =>
										props.handleCompanyInputChange(e, props.index)
									}
									placeholder={!company.existingId ? 'Enter UEN' : ''}
									disabled={!company.existingId ? false : true}
									InputProps={{
										disableUnderline: !!company.existingId,
										style: { color: 'unset' },
									}}
									variant={!company.existingId ? 'filled' : 'standard'}
									fullWidth
									margin='dense'
									error={
										!!props.errorMessages[
											`companies.${props.index}.formData.uen`
										]
									}
									helperText={
										props.errorMessages[`companies.${props.index}.formData.uen`]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Typography color='textPrimary' variant='body2'>
									Website
								</Typography>
								<TextField
									name='website'
									value={company.formData.website}
									onChange={(e) =>
										props.handleCompanyInputChange(e, props.index)
									}
									placeholder={!company.existingId ? 'E.g. www.xyz.com' : ''}
									disabled={!company.existingId ? false : true}
									InputProps={{
										disableUnderline: !!company.existingId,
										style: { color: 'unset' },
									}}
									variant={!company.existingId ? 'filled' : 'standard'}
									fullWidth
									margin='dense'
									error={
										!!props.errorMessages[
											`companies.${props.index}.formData.website`
										]
									}
									helperText={
										props.errorMessages[
											`companies.${props.index}.formData.website`
										]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Typography color='textPrimary' variant='body2'>
									Nature of Business
								</Typography>
								<>
									{company.existingId ? (
										<TextField
											name='industryName'
											value={
												company.formData.industryName
													? getIndustry(company.formData.industryName)
													: ''
											}
											disabled={true}
											InputProps={{
												disableUnderline: true,
												style: {
													color: 'unset',
												},
											}}
											variant='standard'
											fullWidth
											margin='dense'
											error={
												!!props.errorMessages[
													`companies.${props.index}.formData.industryName`
												]
											}
											helperText={
												props.errorMessages[
													`companies.${props.index}.formData.industryName`
												]
											}
										/>
									) : (
										<FormControl style={{ width: '100%' }}>
											<Select
												native
												name='industryName'
												value={company.formData.industryName}
												onChange={(e) =>
													props.handleCompanyInputChange(e, props.index)
												}
												variant='filled'
												fullWidth
												autoWidth={true}
												margin='dense'
												style={{ marginTop: 8, marginBottom: 4 }}
												error={
													!!props.errorMessages[
														`companies.${props.index}.formData.industryName`
													]
												}
											>
												<option key={0} value=''></option>
												{IndustryList.map((industry, index) => (
													<option key={index} value={industry.section}>
														{industry.name}
													</option>
												))}
											</Select>
											<FormHelperText>
												{
													props.errorMessages[
														`companies.${props.index}.formData.industryName`
													]
												}
											</FormHelperText>
										</FormControl>
									)}
								</>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Typography color='textPrimary' variant='body2'>
									Established On
								</Typography>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										name='establishDate'
										disableToolbar
										placeholder={!company.existingId ? 'DD/MM/YYYY' : ''}
										inputVariant={!company.existingId ? 'filled' : 'standard'}
										disabled={!company.existingId ? false : true}
										InputProps={{
											disableUnderline: !!company.existingId,
											style: { color: 'unset' },
										}}
										format='dd/MM/yyyy'
										disableFuture
										openTo='year'
										views={['year', 'month', 'date']}
										margin='dense'
										fullWidth
										value={
											company.formData.establishDate
												? company.formData.establishDate
												: null
										}
										autoOk
										variant='inline'
										invalidDateMessage='Invalid Date Format'
										onChange={(date) =>
											props.handleCompanyDateChange(
												props.index,
												'establishDate',
												date
											)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										keyboardIcon={!company.existingId && <EventIcon />}
										error={
											!!props.errorMessages[
												`companies.${props.index}.formData.establishDate`
											]
										}
										helperText={
											props.errorMessages[
												`companies.${props.index}.formData.establishDate`
											]
										}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Grid item sm={4} />
							<Grid item xs={12} sm={4}>
								<Typography color='textPrimary' variant='body2' align='left'>
									Email
								</Typography>
								<TextField
									name='companyEmail'
									value={company.formData.companyEmail}
									onChange={(e) =>
										props.handleCompanyInputChange(e, props.index)
									}
									placeholder={
										!company.existingId ? "Enter Client's Email Address" : ''
									}
									disabled={!company.existingId ? false : true}
									InputProps={{
										disableUnderline: !!company.existingId,
										style: { color: 'unset' },
									}}
									variant={!company.existingId ? 'filled' : 'standard'}
									fullWidth
									margin='dense'
									error={
										!!props.errorMessages[
											`companies.${props.index}.formData.companyEmail`
										]
									}
									helperText={
										props.errorMessages[
											`companies.${props.index}.formData.companyEmail`
										]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Typography color='textPrimary' variant='body2'>
									Office
								</Typography>
								<TextField
									name='officeNo'
									value={company.formData.officeNo}
									onChange={(e) =>
										props.handleCompanyInputChange(e, props.index)
									}
									placeholder={
										!company.existingId ? "Enter Company's Office Number" : ''
									}
									disabled={!company.existingId ? false : true}
									InputProps={{
										disableUnderline: !!company.existingId,
										style: { color: 'unset' },
									}}
									variant={!company.existingId ? 'filled' : 'standard'}
									fullWidth
									margin='dense'
									error={
										!!props.errorMessages[
											`companies.${props.index}.formData.officeNo`
										]
									}
									helperText={
										props.errorMessages[
											`companies.${props.index}.formData.officeNo`
										]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Typography color='textPrimary' variant='body2'>
									Fax
								</Typography>
								<TextField
									name='faxNo'
									value={company.formData.faxNo}
									onChange={(e) =>
										props.handleCompanyInputChange(e, props.index)
									}
									placeholder={
										!company.existingId ? "Enter Company's Fax Number" : ''
									}
									disabled={!company.existingId ? false : true}
									InputProps={{
										disableUnderline: !!company.existingId,
										style: { color: 'unset' },
									}}
									variant={!company.existingId ? 'filled' : 'standard'}
									fullWidth
									margin='dense'
									error={
										!!props.errorMessages[
											`companies.${props.index}.formData.faxNo`
										]
									}
									helperText={
										props.errorMessages[
											`companies.${props.index}.formData.faxNo`
										]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Typography color='textPrimary' variant='body2'>
									Address
								</Typography>
								<TextField
									name='companyAddress'
									value={company.formData.companyAddress}
									onChange={(e) =>
										props.handleCompanyInputChange(e, props.index)
									}
									placeholder={
										!company.existingId
											? "Enter Client's Blk / House No. / Street Address"
											: ''
									}
									disabled={!company.existingId ? false : true}
									InputProps={{
										disableUnderline: !!company.existingId,
										style: { color: 'unset' },
									}}
									variant={!company.existingId ? 'filled' : 'standard'}
									fullWidth
									margin='dense'
									error={
										!!props.errorMessages[
											`companies.${props.index}.formData.companyAddress`
										]
									}
									helperText={
										props.errorMessages[
											`companies.${props.index}.formData.companyAddress`
										]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Typography color='textPrimary' variant='body2'>
									Floor / Unit Number
								</Typography>
								<TextField
									name='companyUnitNo'
									value={company.formData.companyUnitNo}
									onChange={(e) =>
										props.handleCompanyInputChange(e, props.index)
									}
									placeholder={!company.existingId ? 'Enter Unit No.' : ''}
									disabled={!company.existingId ? false : true}
									InputProps={{
										disableUnderline: !!company.existingId,
										style: { color: 'unset' },
									}}
									variant={!company.existingId ? 'filled' : 'standard'}
									fullWidth
									margin='dense'
									error={
										!!props.errorMessages[
											`companies.${props.index}.formData.companyUnitNo`
										]
									}
									helperText={
										props.errorMessages[
											`companies.${props.index}.formData.companyUnitNo`
										]
									}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Typography color='textPrimary' variant='body2'>
									Postal / Zip Code
								</Typography>
								<TextField
									name='companyPostalCode'
									value={company.formData.companyPostalCode}
									onChange={(e) =>
										props.handleCompanyInputChange(e, props.index)
									}
									placeholder={!company.existingId ? 'Enter Postal Code' : ''}
									disabled={!company.existingId ? false : true}
									InputProps={{
										disableUnderline: !!company.existingId,
										style: { color: 'unset' },
									}}
									variant={!company.existingId ? 'filled' : 'standard'}
									fullWidth
									margin='dense'
									error={
										!!props.errorMessages[
											`companies.${props.index}.formData.companyPostalCode`
										]
									}
									helperText={
										props.errorMessages[
											`companies.${props.index}.formData.companyPostalCode`
										]
									}
								/>
							</Grid>
						</Grid>
					)}
				</CardContent>
			</Collapse>
		</Card>
	);
}
