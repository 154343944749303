import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import api from '../../../../config/api';
import SummaryTable from './SummaryTable';
import { AuthUserContext } from '../../../../components/Session';
import DefaultTemplateList from '../../../../components/Data/emailTemplates.json';
import EmailPreviewer from '../../../../components/EmailPreviewer';
import { combineNames } from '../../../../utils/combineNames';
import { Preview } from '../../../../components/Icons';

const useStyles = makeStyles((theme) => ({}));

function getTemplateType(transactionType) {
	switch (transactionType) {
		case 'Quotation': {
			return 'quoteRequest';
		}
		case 'Claims': {
			return 'claimRequest';
		}
		case 'Renewal': {
			return 'quoteRequest';
		}
	}
}

export default function Summary(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { formData, handleFormDataChange, errorMessages } = props;
	const [contacts, setContacts] = useState([]);
	const [availableContacts, setAvailableContacts] = useState([]);
	const [selectedContacts, setSelectedContacts] = useState([]);
	const [selectedCCContacts, setSelectedCCContacts] = useState([]);
	const [emailData, setEmailData] = useState({});
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		handleFormDataChange('selectedContacts', selectedContacts);
	}, [selectedContacts]);

	useEffect(() => {
		handleFormDataChange('selectedCCContacts', selectedCCContacts);
	}, [selectedCCContacts]);

	useEffect(() => {
		setAvailableContacts(contacts);
	}, [contacts]);

	useEffect(() => {
		if (
			!formData.isDirectPurchase ||
			(formData.isDirectPurchase && !formData.haveDocuments)
		) {
			if (formData.haveDocuments) {
				getUnderwriters();
			}
			if (!formData.subject || !formData.message) {
				getEmailTemplate();
			}
		}
	}, []);

	const getUnderwriters = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('underwriters')
			.then((response) => {
				if (response.data.data.length > 0) {
					let filteredContactList = response.data.data
						.map((option) => {
							if (formData.transactionType === 'Claims') {
								if (
									formData.prevPolicyData.principalName === option.principalName
								) {
									return {
										principalName: option.principalName,
										...option,
									};
								} else {
									return null;
								}
							} else {
								return {
									principalName: option.principalName,
									...option,
								};
							}
						})
						.filter((option) => option !== null);
					setContacts(filteredContactList);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getEmailTemplate = () => {
		if (!loading) {
			setLoading(true);
		}

		let templateType = '';
		if (!formData.haveDocuments) {
			templateType = 'fileRequest';
		} else if (formData.isSelfGenerated) {
			templateType = 'quoteSelection';
		} else {
			templateType = getTemplateType(formData.transactionType);
		}

		api
			.get('email-templates/' + templateType)
			.then((response) => {
				if (response.data.ok) {
					handleFormDataChange('subject', response.data.data.subject);
					handleFormDataChange('message', response.data.data.message);
				} else {
					let templateData = getDefaultTemplate(templateType);
					handleFormDataChange('subject', templateData.subject);
					handleFormDataChange('message', templateData.message);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getDefaultTemplate = (templateType) => {
		let templateData = DefaultTemplateList.find((item) => {
			return item.name === templateType;
		});
		return templateData;
	};

	const togglePreview = () => {
		if (!open) {
			getPreviewData();
		} else {
			setOpen(false);
		}
	};

	const getPreviewData = async () => {
		if (!loading) {
			setLoading(true);
		}

		let templateType = '';
		if (!formData.haveDocuments) {
			templateType = 'fileRequest';
		} else if (formData.isSelfGenerated) {
			templateType = 'quoteSelection';
		} else {
			templateType = getTemplateType(formData.transactionType);
		}

		api
			.post('preview-template/' + templateType, {
				subject: formData.subject,
				message: formData.message,
			})
			.then((response) => {
				if (response.data.ok) {
					setEmailData(response.data.data);
				} else {
					setEmailData({});
				}
				setLoading(false);
				setOpen(true);
			})
			.catch(function (error) {
				console.log(error);
				setEmailData({});
				setLoading(false);
				setOpen(false);
			});
	};

	const handleContactsChange = (event, newValue) => {
		setSelectedContacts(newValue);
		updateAvailableContacts(newValue, selectedCCContacts);
	};

	const handleCCContactsChange = (event, newValue) => {
		setSelectedCCContacts(newValue);
		updateAvailableContacts(selectedContacts, newValue);
	};

	const updateAvailableContacts = (selectedTo, selectedCC) => {
		const updatedAvailableContacts = contacts.filter(
			(contact) =>
				!selectedTo.find((selected) => selected.email === contact.email) &&
				!selectedCC.find((selected) => selected.email === contact.email)
		);
		setAvailableContacts(updatedAvailableContacts);
	};

	return (
		<AuthUserContext.Consumer>
			{(credentials) => (
				<>
					<Grid
						style={{ marginBottom: 24 }}
						container
						spacing={4}
						direction='row'
						justifyContent='center'
						alignItems='stretch'
					>
						{(!formData.isDirectPurchase ||
							(formData.isDirectPurchase && !formData.haveDocuments)) && (
							<Grid item xs={12} md={5}>
								<Grid container spacing={2} direction='column'>
									<Grid item>
										<Typography color='textPrimary' variant='body2'>
											From:
										</Typography>
										<TextField
											name='from'
											disabled
											variant='filled'
											fullWidth
											margin='dense'
											InputProps={{
												startAdornment: (
													<Chip
														key={credentials.authUser._delegate.uid}
														tabIndex={-1}
														label={`${credentials.userProfile.profile.firstName} ${credentials.userProfile.profile.lastName} <mail-service@mg.uuoni.com>`}
														className={classes.chip}
													/>
												),
											}}
										/>
									</Grid>
									<Grid item>
										<Typography color='textPrimary' variant='body2'>
											To:
										</Typography>
										{formData.haveDocuments && !formData.isSelfGenerated ? (
											<Autocomplete
												id='search-contacts'
												style={{ marginTop: 8, marginBottom: 4 }}
												value={selectedContacts}
												onChange={handleContactsChange}
												fullWidth
												multiple
												disableCloseOnSelect
												filterSelectedOptions
												options={availableContacts.sort(
													(a, b) =>
														-b.principalName.localeCompare(a.principalName)
												)}
												noOptionsText=''
												groupBy={(option) => option.principalName}
												getOptionSelected={(option, value) =>
													option.email === value.email
												}
												getOptionLabel={(option) => {
													let name = combineNames({
														fName: option.firstName,
														mName:
															option.middleName !== undefined
																? option.middleName
																: null,
														lName:
															option.lastName !== undefined
																? option.lastName
																: null,
													});

													return `${name} (${option.email})`;
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														variant='filled'
														placeholder={
															selectedContacts.length === 0
																? 'Search for a Contact'
																: ''
														}
														InputProps={{
															...params.InputProps,
															endAdornment: null,
														}}
														disabled={loading}
														error={!!errorMessages[`selectedContacts`]}
														helperText={
															errorMessages[`selectedContacts`] &&
															errorMessages[`selectedContacts`]
														}
													/>
												)}
											/>
										) : (
											<TextField
												name='to'
												disabled
												variant='filled'
												fullWidth
												margin='dense'
												InputProps={{
													startAdornment: (
														<Chip
															key={formData.contactData._id}
															tabIndex={-1}
															label={`${combineNames({
																fName: formData.contactData.firstName,
																mName:
																	formData.contactData.middleName !== undefined
																		? formData.contactData.middleName
																		: null,
																lName:
																	formData.contactData.lastName !== undefined
																		? formData.contactData.lastName
																		: null,
															})} (${formData.contactData.email})`}
															className={classes.chip}
														/>
													),
												}}
											/>
										)}
									</Grid>
									{formData.haveDocuments && !formData.isSelfGenerated && (
										<Grid item>
											<Typography color='textPrimary' variant='body2'>
												CC:
											</Typography>
											<Autocomplete
												id='search-cc-contacts'
												style={{ marginTop: 8, marginBottom: 4 }}
												value={selectedCCContacts}
												onChange={handleCCContactsChange}
												fullWidth
												multiple
												disableCloseOnSelect
												filterSelectedOptions
												options={availableContacts.sort(
													(a, b) =>
														-b.principalName.localeCompare(a.principalName)
												)}
												noOptionsText=''
												groupBy={(option) => option.principalName}
												getOptionSelected={(option, value) =>
													option.email === value.email
												}
												getOptionLabel={(option) => {
													let name = combineNames({
														fName: option.firstName,
														mName:
															option.middleName !== undefined
																? option.middleName
																: null,
														lName:
															option.lastName !== undefined
																? option.lastName
																: null,
													});

													return `${name} (${option.email})`;
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														variant='filled'
														placeholder={
															selectedCCContacts.length === 0
																? 'Search for a Contact'
																: ''
														}
														InputProps={{
															...params.InputProps,
															endAdornment: null,
														}}
														disabled={loading}
														error={!!errorMessages[`selectedCCContacts`]}
														helperText={
															errorMessages[`selectedCCContacts`] &&
															errorMessages[`selectedCCContacts`]
														}
													/>
												)}
											/>
										</Grid>
									)}
									<Grid item>
										<Typography color='textPrimary' variant='body2'>
											Subject
										</Typography>
										<TextField
											name='subject'
											value={formData.subject}
											onChange={(e) =>
												handleFormDataChange('subject', e.target.value)
											}
											variant='filled'
											fullWidth
											margin='dense'
											disabled={loading}
											error={!!errorMessages[`subject`]}
											helperText={
												errorMessages[`subject`] && errorMessages[`subject`]
											}
										/>
									</Grid>
									<Grid item>
										<Typography color='textPrimary' variant='body2'>
											Message
										</Typography>
										<TextField
											name='message'
											value={formData.message}
											multiline
											onChange={(e) =>
												handleFormDataChange('message', e.target.value)
											}
											variant='filled'
											fullWidth
											margin='dense'
											minRows={10}
											disabled={loading}
											error={!!errorMessages[`message`]}
											helperText={
												errorMessages[`message`] && errorMessages[`message`]
											}
										/>
									</Grid>
									<Grid item>
										<Button
											variant='outlined'
											color='primary'
											startIcon={
												<Preview
													style={{ fontSize: 24 }}
													colorcode={theme.palette.tertiary.main}
												/>
											}
											onClick={() => togglePreview()}
										>
											Preview
										</Button>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item xs={12} md={7}>
							<SummaryTable
								formData={formData}
								requestType={
									formData.haveDocuments ? 'transaction' : 'clientRequest'
								}
							/>
						</Grid>
					</Grid>
					<EmailPreviewer
						open={open}
						handleClose={() => togglePreview()}
						emailData={emailData}
					/>
				</>
			)}
		</AuthUserContext.Consumer>
	);
}
