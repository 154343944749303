import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import api from '../../config/api';
import { combineNames } from '../../utils/combineNames';

export default function DataSearch(props) {
	const { type, handleSelectRecord, index, errorMessage } = props;
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [val, setVal] = useState(null);
	const [inputVal, setInputVal] = useState('');
	const [loading, setLoading] = useState(false);

	const getData = async (val) => {
		if (loading || !val) {
			return;
		}
		if (!loading) {
			setLoading(true);
			try {
				let results = null;
				if (type === 'contact') {
					let response = await api.get('search/contacts', {
						params: { value: val },
					});
					let filteredData = [...response.data.data].map((contact, index) => {
						let label = combineNames({
							fName: contact.firstName,
							mName:
								contact.middleName !== undefined ? contact.middleName : null,
							lName: contact.lastName !== undefined ? contact.lastName : null,
						});
						return { value: contact, label: label };
					});
					results = filteredData;
				}
				if (type === 'company') {
					let response = await api.get('search/companies', {
						params: { value: val },
					});
					let filteredData = [...response.data.data].map((company, index) => {
						let label = `${company.companyName}`;
						return { value: company, label: label };
					});
					results = filteredData;
				}
				if (results) {
					setOptions(results);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	const handler = useRef(debounce(getData, 500));

	const handleInputChange = (val) => {
		setInputVal(val);
		if (val.length >= 1) {
			handler.current(val);
		} else {
			setOptions([]);
			handler.current('');
		}
	};

	const handleChange = (option) => {
		if (option === null) return;
		setVal(option.value);
		if (index !== undefined) handleSelectRecord(option.value, type, index);
		else handleSelectRecord(option.value, type);
	};

	const getPlaceholderText = (type) => {
		switch (type) {
			case 'contact':
				return 'Search or Select a Contact';
			case 'company':
				return 'Search or Select a Company';
			default:
				return 'Search or Select a Client';
		}
	};

	return (
		<Autocomplete
			id='data-search'
			value={val}
			freeSolo={val?.length ? false : true}
			open={open}
			onOpen={() => {
				if (options.length === 0) {
					if (open) setOpen(false);
				} else {
					if (!open) setOpen(true);
				}
			}}
			onInputChange={(_, value) => {
				if (value.length === 0) {
					if (open) setOpen(false);
				} else {
					if (!open) setOpen(true);
				}
			}}
			onClose={() => {
				setOpen(false);
			}}
			onChange={(e, newValue) => {
				handleChange(newValue);
			}}
			getOptionSelected={(option, value) => option.value._id === value._id}
			getOptionLabel={(option) => option.label || ''}
			options={options}
			noOptionsText=''
			filterOptions={(options, { inputValue }) =>
				options.filter((option) => {
					if (option) {
						return Object.values(option).some((val) => {
							if (val && typeof val === 'string') {
								return val.toLowerCase().includes(inputValue);
							} else {
								return true;
							}
						});
					} else {
						return true;
					}
				})
			}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder={getPlaceholderText(type)}
					variant='filled'
					onChange={(e) => {
						// dont fire API if the user delete or not entered anything
						if (e.target.value !== '' || e.target.value !== null) {
							handleInputChange(e.target.value);
						}
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color='inherit' size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
					error={!!errorMessage}
					helperText={errorMessage && errorMessage}
				/>
			)}
		/>
	);
}
