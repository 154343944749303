import Joi from 'joi';

const AssociatedCompany = Joi.object({
	companyName: Joi.string()
		.label('Company Name')
		.trim()
		.min(1)
		.max(50)
		.required(),
	uen: Joi.string().label('UEN').trim().min(1).max(50).required(),
}).unknown(true);

export default AssociatedCompany;
