import React, { useState, useEffect, useContext } from 'react';
import { withFirebase } from '../Firebase';
import AuthUserContext from './context';
import Loader from '../Loader';
import api from '../../config/api';

const withAuthentication = (Component) => {
	const WithAuthentication = ({ firebase }) => {
		const [authUser, setAuthUser] = useState(null);
		const [verified, setVerified] = useState(false);
		const [userProfile, setUserProfile] = useState({});
		const [loading, setLoading] = useState(true);

		useEffect(() => {
			const handleAuthStateChanged = (authUser) => {
				if (authUser && authUser.emailVerified) {
					api.get('user-profile').then((res) => {
						if (res.data.ok) {
							setAuthUser(authUser);
							setVerified(authUser.emailVerified);
							setUserProfile(res.data.data);
							setLoading(false);
						} else if (res.data.error === 'Profile not available') {
							console.log('User not found, register new User');
							console.log(authUser);
							const newUser = {
								email: authUser.email,
								firebaseUid: authUser.uid,
								// onboarded: false,
							};
							api.post('register', newUser).then((res) => {
								if (res.data.ok) {
									api.get('user-profile').then((res) => {
										if (res.data.ok) {
											setAuthUser(authUser);
											setVerified(authUser.emailVerified);
											setUserProfile(res.data.data);
											setLoading(false);
										}
									});
								}
							});
						}
					});
				} else {
					setAuthUser(authUser);
					setVerified(false);
					setUserProfile({});
					// onboarded: false,
					setLoading(false);
				}
			};

			if (process.env.REACT_APP_ENVIRONMENT !== 'demo') {
				const listener = firebase.auth.onAuthStateChanged(
					handleAuthStateChanged
				);
				return () => listener(); // Cleanup function for useEffect
			} else {
				api.get('user-profile').then((res) => {
					setAuthUser({
						_delegate: {
							uid: 'oVTHj3dVOLOo05QuA1zovMM7WeH3',
							email: 'davidmix@email-temp.com',
						},
					});
					setVerified(true);
					setUserProfile(res.data.data);
					setLoading(false);
				});
			}
		}, [firebase]);

		if (loading) {
			return (
				<div style={{ height: '100vh' }}>
					<Loader size='lg' />
				</div>
			);
		}

		return (
			<AuthUserContext.Provider
				value={{
					authUser,
					verified,
					userProfile,
					setUserProfile,
				}}
			>
				<Component />
			</AuthUserContext.Provider>
		);
	};

	return withFirebase(WithAuthentication);
};

export default withAuthentication;
