import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 32,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
}));

const NavPrompt = (props) => {
	const { when, isRoute = true } = props;
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();

	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState('');

	const unblockRef = useRef();

	useEffect(() => {
		unblockRef.current = history.block((location) => {
			if (when) {
				setCurrentPath(location.pathname);
				setShowPrompt(true);
				return false;
			}
			return true;
		});
		return () => {
			unblockRef.current && unblockRef.current();
		};
	}, [when]);

	function handleOk() {
		if (unblockRef) {
			unblockRef.current();
		}
		setShowPrompt(false);
		history.push(currentPath);
	}

	return (
		showPrompt && (
			<>
				<Dialog
					open={showPrompt}
					onClose={() => setShowPrompt(false)}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<div className={classes.paper}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{isRoute ? (
								<Typography
									style={{ textTransform: 'uppercase' }}
									color='textPrimary'
									variant='h3'
								>
									Confirm Navigation
								</Typography>
							) : (
								<Typography
									style={{ textTransform: 'uppercase' }}
									color='textPrimary'
									variant='h3'
								>
									Unsaved Changes
								</Typography>
							)}
						</div>
						{isRoute ? (
							<div style={{ marginTop: 24, marginBottom: 24 }}>
								<Typography
									variant='body1'
									color='textPrimary'
									style={{ marginBottom: 8 }}
								>
									Are you sure you want to navigate away from this page?
								</Typography>
								<Typography variant='body1' color='textPrimary'>
									The changes you made will be lost if you navigate away from
									this page.
								</Typography>
							</div>
						) : (
							<div style={{ marginTop: 24, marginBottom: 24 }}>
								<Typography variant='body1' color='textPrimary'>
									You have unsaved changes.
								</Typography>
								<Typography variant='body1' color='textPrimary'>
									Do you want to discard the changes?
								</Typography>
							</div>
						)}
						{isRoute ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: 24,
								}}
							>
								<Button
									style={{ marginRight: 8 }}
									onClick={() => setShowPrompt(false)}
									variant='outlined'
									color='primary'
								>
									Stay on this page
								</Button>
								<Button onClick={handleOk} variant='contained' color='primary'>
									Leave this page
								</Button>
							</div>
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginTop: 24,
								}}
							>
								<Button
									style={{ marginRight: 8 }}
									onClick={() => setShowPrompt(false)}
									variant='outlined'
									color='primary'
								>
									No, keep editing
								</Button>
								<Button
									style={{
										backgroundColor: theme.palette.error.main,
										color: theme.palette.background.default,
									}}
									onClick={handleOk}
									variant='contained'
									color='primary'
								>
									Yes, discard changes
								</Button>
							</div>
						)}
					</div>
				</Dialog>
			</>
		)
	);
};

export default NavPrompt;
