import {
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Helvetica',
		fontSize: 11,
		paddingTop: 30,
		paddingLeft: 30,
		paddingRight: 30,
		lineHeight: 1.5,
		flexDirection: 'column',
	},
	logo: {
		maxWidth: 250,
		maxHeight: 150,
		marginRight: 'auto',
	},
	divider: {
		marginTop: 16,
		marginBottom: 16,
		borderBottom: '1px solid #EEEEEE',
	},
	titleContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	titleContentContainer: {
		marginLeft: 'auto',
	},
	reportTitle: {
		marginLeft: 'auto',
		fontSize: 25,
		textAlign: 'right',
		textTransform: 'uppercase',
	},
	fromContainer: {
		flexDirection: 'column',
		marginTop: 8,
	},
	fromFieldContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	fromText: {
		fontSize: 12,
		fontStyle: 'bold',
	},
	fromLabel: {
		textAlign: 'right',
		marginRight: 12,
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	billToContainer: {
		flexDirection: 'column',
		width: '45%',
	},
	invoiceInfoContainer: {
		flexDirection: 'column',
		width: '45%',
		marginLeft: 'auto',
	},
	invoiceInfoFieldContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	invoiceInfoLabel: {
		textAlign: 'right',
		marginRight: 12,
		width: '70%',
	},
	invoiceInfoText: {
		fontSize: 12,
		fontStyle: 'bold',
		textAlign: 'left',
		width: '30%',
	},
	billTo: {
		paddingBottom: 3,
		textTransform: 'uppercase',
	},
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 16,
		marginBottom: 16,
	},
	tableHeader: {
		flexDirection: 'row',
		backgroundColor: '#5E80E6',
		color: '#FFFFFF',
		alignItems: 'center',
		height: 24,
		fontStyle: 'bold',
		flexGrow: 1,
		marginBottom: 16,
	},
	description: {
		width: '60%',
		paddingLeft: 8,
	},
	policyNo: {
		width: '20%',
		paddingLeft: 8,
	},
	premium: {
		width: '20%',
		paddingLeft: 8,
	},
	tableRow: {
		flexDirection: 'row',
		alignItems: 'baseline',
		borderBottom: '1px solid #EEEEEE',
		paddingTop: 8,
		paddingBottom: 8,
	},
	descriptionContainer: {
		width: '60%',
		paddingLeft: 8,
	},
	insuranceType: {
		fontSize: 12,
		fontStyle: 'bold',
	},
	descriptionFieldContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	descriptionLabel: {
		marginRight: 8,
		width: '35%',
	},
	descriptionText: {
		width: '65%',
	},
	footerContainer: {
		paddingLeft: 8,
		paddingRight: 8,
	},
	paymentMethodHeader: {
		flexDirection: 'row',
		backgroundColor: '#5E80E6',
		color: '#FFFFFF',
		alignItems: 'center',
		height: 24,
		fontStyle: 'bold',
		marginBottom: 8,
	},
	paymentMethodHeaderText: {},
	paymentMethod: {
		textDecoration: 'underline',
		fontWeight: 'bold',
	},
	paymentMethodInfoContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	paymentMethodInfoLabel: {
		fontStyle: 'bold',
		marginRight: 8,
	},
	paymentMethodInfoText: {},
	terms: {
		marginTop: 24,
		textDecoration: 'underline',
		fontWeight: 'bold',
	},
	termsText: {},
	totalLabel: {
		width: '80%',
		textAlign: 'right',
		paddingRight: 24,
	},
	totalText: {
		width: '20%',
		paddingLeft: 8,
	},
	qrImage: {
		width: 150,
		height: 150,
	},
});

const Invoice = (props) => {
	const { formData } = props;

	return (
		<Document>
			<Page size='A4' style={styles.page}>
				<View style={styles.titleContainer}>
					{formData.orgProfile.orgLogo !== null && (
						<Image
							style={styles.logo}
							src={
								formData.orgProfile.orgLogo !== null &&
								`data:image/*;base64,${formData.orgProfile.orgLogo}`
							}
						/>
					)}
					<View style={styles.titleContentContainer}>
						<Text style={styles.reportTitle}>INVOICE</Text>
						<View style={styles.fromContainer}>
							<View style={styles.fromFieldContainer}>
								<Text style={styles.fromText}>{formData.orgProfile.name}</Text>
							</View>
							<View style={styles.fromFieldContainer}>
								<Text style={styles.fromText}>
									{formData.orgProfile.address}, {formData.orgProfile.unitNo}
								</Text>
							</View>
							<View style={styles.fromFieldContainer}>
								<Text style={styles.fromText}>
									Singapore {formData.orgProfile.postalCode}
								</Text>
							</View>
						</View>
						<View style={styles.fromContainer}>
							<View style={styles.fromFieldContainer}>
								<Text style={styles.fromLabel}>UEN:</Text>
								<Text style={styles.fromText}>{formData.orgProfile.uen}</Text>
							</View>
							<View style={styles.fromFieldContainer}>
								<Text style={styles.fromLabel}>Agent:</Text>
								<Text style={styles.fromText}>
									{formData.userProfile.firstName}{' '}
									{formData.userProfile.lastName}
								</Text>
							</View>
							<View style={styles.fromFieldContainer}>
								<Text style={styles.fromLabel}>Agent ID:</Text>
								<Text style={styles.fromText}>
									{formData.userProfile.agentId}
								</Text>
							</View>
						</View>
					</View>
				</View>
				<View style={styles.divider} />
				<View style={styles.headerContainer}>
					<View style={styles.billToContainer}>
						<Text style={styles.billTo}>Bill To</Text>
						<Text>{formData.clientData.contactName}</Text>
						<Text>{formData.clientData.companyName}</Text>
						{formData.isCommercial ? (
							<>
								<Text>
									{formData.clientData.company.companyAddress},{' '}
									{formData.clientData.company.companyUnitNo}
								</Text>
								<Text>
									Singapore {formData.clientData.company.companyPostalCode}
								</Text>
							</>
						) : (
							<>
								<Text>
									{formData.clientData.contact.address},{' '}
									{formData.clientData.contact.unitNo}
								</Text>
								<Text>Singapore {formData.clientData.contact.postalCode}</Text>
							</>
						)}
						<Text>{formData.clientData.contact.email}</Text>
						{formData.isCommercial ? (
							<Text>{formData.clientData.company.officeNo}</Text>
						) : (
							<Text>{formData.clientData.contact.mobileNo}</Text>
						)}
					</View>

					<View style={styles.invoiceInfoContainer}>
						<View style={styles.invoiceInfoFieldContainer}>
							<Text style={styles.invoiceInfoLabel}>Invoice Number:</Text>
							<Text style={styles.invoiceInfoText}>
								INV-{formData.invoiceNo}
							</Text>
						</View>
						<View style={styles.invoiceInfoFieldContainer}>
							<Text style={styles.invoiceInfoLabel}>Transaction Number:</Text>
							<Text style={styles.invoiceInfoText}>
								TXN-{formData.transactionNo}
							</Text>
						</View>
						<View style={styles.invoiceInfoFieldContainer}>
							<Text style={styles.invoiceInfoLabel}>Invoice Date:</Text>
							<Text style={styles.invoiceInfoText}>
								{moment(formData.createdAt).format('DD/MM/YYYY')}
							</Text>
						</View>
						<View style={styles.invoiceInfoFieldContainer}>
							<Text style={styles.invoiceInfoLabel}>Payment Due:</Text>
							<Text style={styles.invoiceInfoText}>
								{moment(formData.dueDate).format('DD/MM/YYYY')}
							</Text>
						</View>
					</View>
				</View>
				<View style={styles.tableContainer}>
					<View
						style={[
							styles.tableHeader,
							{ backgroundColor: formData.settings.color },
						]}
					>
						<Text style={styles.policyNo}>Policy Number</Text>
						<Text style={styles.description}>Description</Text>
						<Text style={styles.premium}>Premium</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={styles.policyNo}>{formData.policyData.policyNo}</Text>
						<View style={styles.descriptionContainer}>
							<Text style={styles.insuranceType}>
								{formData.insuranceType}{' '}
								{formData.insuranceSubType !== '' &&
									`(${formData.insuranceSubType})`}
							</Text>
							<View style={styles.descriptionFieldContainer}>
								<Text style={styles.descriptionLabel}>Insured By:</Text>
								<Text style={styles.descriptionText}>
									{formData.policyData.principalName}
								</Text>
							</View>
							<View style={styles.descriptionFieldContainer}>
								<Text style={styles.descriptionLabel}>
									Period of Insurance:
								</Text>
								<Text style={styles.descriptionText}>
									{moment(formData.policyData.startDate).format('DD/MM/YYYY')}{' '}
									to {moment(formData.policyData.endDate).format('DD/MM/YYYY')}
								</Text>
							</View>
							<View style={styles.descriptionFieldContainer}>
								<Text style={styles.descriptionLabel}>Sum Insured:</Text>
								<Text style={styles.descriptionText}>
									{Number.parseFloat(formData.policyData.sumInsured) === 0
										? 'Refer to Policy Document'
										: Number.parseFloat(formData.policyData.sumInsured).toFixed(
												2
										  )}
								</Text>
							</View>
							<View style={styles.descriptionFieldContainer}>
								<Text style={styles.descriptionLabel}>Remarks:</Text>
								<Text style={styles.descriptionText}>
									{formData.policyData.remarks}
								</Text>
							</View>
						</View>
						<Text style={styles.premium}>
							{Number.parseFloat(formData.policyData.premiumAmt).toFixed(2)}
						</Text>
					</View>
					<View style={styles.tableRow}>
						<Text style={styles.totalLabel}>Total Premium (SGD)</Text>
						<Text style={styles.totalText}>
							{Number.parseFloat(formData.policyData.premiumAmt).toFixed(2)}
						</Text>
					</View>
				</View>
				<View
					style={[
						styles.paymentMethodHeader,
						{ backgroundColor: formData.settings.color },
					]}
				>
					<Text style={styles.description}>Payment Method</Text>
				</View>
				<View style={styles.footerContainer}>
					<Text style={styles.paymentMethodHeaderText}>
						Transfer the amount due to any of the following payment methods
						listed below:
					</Text>
					<Text style={styles.paymentMethod}>
						Bank Transfer / Internet Banking
					</Text>
					<View style={styles.paymentMethodInfoContainer}>
						<Text style={styles.paymentMethodInfoLabel}>Bank:</Text>
						<Text style={styles.paymentMethodInfoText}>
							{formData.settings.bank}
						</Text>
					</View>
					<View style={styles.paymentMethodInfoContainer}>
						<Text style={styles.paymentMethodInfoLabel}>Beneficiary Name:</Text>
						<Text style={styles.paymentMethodInfoText}>
							{formData.settings.beneficiaryName}
						</Text>
					</View>
					<View style={styles.paymentMethodInfoContainer}>
						<Text style={styles.paymentMethodInfoLabel}>Account Number:</Text>
						<Text style={styles.paymentMethodInfoText}>
							{formData.settings.accountNo}
						</Text>
					</View>
					{formData.settings.paynowEnabled && (
						<>
							<Text style={styles.paymentMethod}>PayNow</Text>
							<View style={styles.paymentMethodInfoContainer}>
								<Text style={styles.paymentMethodInfoLabel}>UEN:</Text>
								<Text style={styles.paymentMethodInfoText}>
									{formData.settings.paynowId}
								</Text>
							</View>
							{formData.settings.qrImage && (
								<View style={styles.paymentMethodInfoContainer}>
									<Text style={styles.paymentMethodInfoLabel}>QR:</Text>
									<Image
										style={styles.qrImage}
										src={
											formData.settings.qrImage !== null &&
											`data:image/*;base64,${formData.settings.qrImage}`
										}
									/>
								</View>
							)}
						</>
					)}
					<Text style={styles.terms}>Terms & Conditions / Notes</Text>
					<Text style={styles.termsText}>{formData.settings.terms}</Text>
				</View>
			</Page>
		</Document>
	);
};

export default Invoice;
