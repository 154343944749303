import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
	docField: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: 16,
	},
	paper: {
		minWidth: 560,
		padding: 24,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
}));

export default function SuggestedChecklist(props) {
	const classes = useStyles();
	const { open, handleClose, checklist } = props;

	useEffect(() => {
		console.log(checklist);
	}, []);

	const getIcon = (type) => {
		switch (type) {
			case 'Document':
				return <InsertDriveFileOutlinedIcon />;
			case 'Photo':
				return <PhotoOutlinedIcon />;
			case 'Video':
				return <VideocamOutlinedIcon />;
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<div className={classes.paper}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography
						style={{ textTransform: 'uppercase' }}
						color='textPrimary'
						variant='h3'
					>
						Suggested Checklist
					</Typography>
					<IconButton
						style={{ marginLeft: 'auto', padding: 0 }}
						disableRipple
						disableFocusRipple
						disableTouchRipple
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				</div>
				<div style={{ marginTop: 24 }}>
					{checklist.map((doc, index) => (
						<div key={index} className={classes.docField}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{getIcon(doc.type)}
								<Typography
									style={{ minWidth: 230, marginLeft: 24 }}
									color='textPrimary'
									variant='subtitle2'
								>
									{doc.name}
								</Typography>
							</div>
						</div>
					))}
				</div>
			</div>
		</Dialog>
	);
}
