import Joi from 'joi';

const Contact = Joi.object({
	firstName: Joi.string().label('First Name').trim().min(1).max(50).required(),
	middleName: Joi.string()
		.label('Middle Name')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	lastName: Joi.string()
		.label('Last Name')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),

	gender: Joi.string()
		.label('Gender')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	maritalStatus: Joi.string()
		.label('Marital Status')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	idNo: Joi.string()
		.label('NRIC / FIN / Passport No.')
		.trim()
		.min(1)
		.max(20)
		.optional()
		.allow(''),
	nationality: Joi.string()
		.label('Nationality')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	email: Joi.string()
		.label('Email')
		.trim()
		.email({ tlds: { allow: false } })
		.required(),
	mobileNo: Joi.string()
		.label('Mobile')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Mobile" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	homeNo: Joi.string()
		.label('Home')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Home" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	address: Joi.string()
		.label('Address')
		.trim()
		.min(1)
		.max(100)
		.optional()
		.allow(''),
	unitNo: Joi.string()
		.label('Floor / Unit Number')
		.trim()
		.min(1)
		.max(10)
		.optional()
		.allow(''),
	postalCode: Joi.string()
		.label('Postal / Zip Code')
		.trim()
		.min(1)
		.max(10)
		.optional()
		.allow(''),
	source: Joi.string()
		.label('Source')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	sourceInfo: Joi.string()
		.label('Source Info')
		.trim()
		.min(1)
		.max(1000)
		.when('source', {
			is: 'Other',
			then: Joi.required(),
			otherwise: Joi.optional().allow(''),
		}),
	notes: Joi.string()
		.label('Notes')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default Contact;
