import DateFnsUtils from '@date-io/date-fns';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CountryList from '../../../components/Data/countries.json';
import SourceTypes from '../../../components/Data/sourceTypes.json';
import TagMenu from '../../../components/TagMenu';
import AssociatedCompanyForm from './associatedCompanyForm';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		margin: 0,
	},
	likeChip: {
		margin: theme.spacing(0.5),
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.background.default,
		'& .MuiChip-deleteIcon': {
			display: 'none',
		},
		'&:hover': {
			'& .MuiChip-deleteIcon': {
				display: 'block',
			},
		},
	},
	dislikeChip: {
		margin: theme.spacing(0.5),
		backgroundColor: theme.palette.text.secondary,
		color: theme.palette.background.default,
		'& .MuiChip-deleteIcon': {
			display: 'none',
		},
		'&:hover': {
			'& .MuiChip-deleteIcon': {
				display: 'block',
			},
		},
	},
	addTagChip: {
		margin: theme.spacing(0.5),
		color: theme.palette.tertiary.main,
		border: `1px solid ${theme.palette.tertiary.main}`,
	},
	tabPanel: {
		width: '80%',
		minHeight: 600,
	},
	tabPanelMobile: {
		minHeight: 600,
	},
}));

const str2bool = (value) => {
	if (value && typeof value === 'string') {
		if (value.toLowerCase() === 'true') return true;
		if (value.toLowerCase() === 'false') return false;
	}
	return value;
};

export default function ContactForm(props) {
	const { formData, handleFormUpdate, errorMessages, errorTabs } = props;
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [tagType, setTagType] = useState(null);
	const [value, setValue] = useState(0);

	const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);
	const [tagErrorMessages, setTagErrorMessages] = useState({});
	const likeContainerRef = useRef();
	const dislikeContainerRef = useRef();

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;

		let updatedData = formData.data;
		updatedData[name] = value;

		handleFormUpdate('data', updatedData);
	};

	const handleDateChange = (target, date) => {
		let updatedData = formData.data;
		updatedData[target] = date;

		handleFormUpdate('data', updatedData);
	};

	const handleAddCompany = () => {
		let updatedCompanies = [...formData.companies];
		let newCompanyData = {
			id: nanoid(),
			isPrimary: false,
			isExisting: false,
			existingId: '',
			formData: {
				companyName: '',
				uen: '',
				website: '',
				establishDate: null,
				industryName: '',
				industryCode: '',
				companyEmail: '',
				officeNo: '',
				faxNo: '',
				companyAddress: '',
				companyUnitNo: '',
				companyPostalCode: '',
			},
		};
		updatedCompanies.push(newCompanyData);

		handleFormUpdate('companies', updatedCompanies);
	};

	const handleRemoveCompany = (id) => {
		if (formData.companies.length === 1) return;
		let updatedCompanies = [...formData.companies].filter((el) => el.id !== id);
		if (updatedCompanies.length === 1) {
			updatedCompanies[0].isPrimary = true;
		}

		handleFormUpdate('companies', updatedCompanies);
	};

	const handleSelectCompany = (value, type, index) => {
		let updatedCompanies = [...formData.companies];
		let selected = updatedCompanies.some(
			(company) => company.existingId === value._id
		);
		if (selected) {
			return;
		} else {
			updatedCompanies[index]['formData'] = value;
			updatedCompanies[index].existingId = value._id;

			handleFormUpdate('companies', updatedCompanies);
		}
	};

	const handleCompanyInputChange = (e, index) => {
		const {
			target: { value, name },
		} = e;
		let updatedCompanies = [...formData.companies];
		updatedCompanies[index]['formData'][name] = value;

		handleFormUpdate('companies', updatedCompanies);
	};

	const handleCompanyDateChange = (index, target, date) => {
		let updatedCompanies = [...formData.companies];
		updatedCompanies[index]['formData'][target] = date;

		handleFormUpdate('companies', updatedCompanies);
	};

	const handleChangePrimary = (id) => {
		let updatedCompanies = [...formData.companies];
		updatedCompanies.forEach((el) =>
			el.id === id ? (el.isPrimary = true) : (el.isPrimary = false)
		);

		handleFormUpdate('companies', updatedCompanies);
	};

	const handleChangeExisting = (e, index) => {
		let val = str2bool(e.target.value);
		let updatedCompanies = [...formData.companies];
		if (!val) {
			updatedCompanies[index]['formData'] = {
				companyName: '',
				uen: '',
				website: '',
				establishDate: null,
				industryName: '',
				industryCode: '',
				companyEmail: '',
				officeNo: '',
				faxNo: '',
				companyAddress: '',
				companyUnitNo: '',
				companyPostalCode: '',
			};
			updatedCompanies[index].existingId = '';
		}
		updatedCompanies[index]['isExisting'] = val;

		handleFormUpdate('companies', updatedCompanies);
	};

	const handleUpdateTags = (tags, type) => {
		let exists = false;
		if (type === 'likes') {
			exists = [...formData.data.tags.dislikes].some((item) =>
				tags.includes(item)
			);
		}
		if (type === 'dislikes') {
			exists = [...formData.data.tags.likes].some((item) =>
				tags.includes(item)
			);
		}

		if (exists) {
			setTagErrorMessages((prev) => ({
				...prev,
				tagLabel: 'Some of the tags has already been assigned before!',
			}));
		} else {
			let updatedData = formData.data;
			let updatedTags = {
				likes: type === 'likes' ? [...tags] : [...updatedData.tags.likes],
				dislikes:
					type === 'dislikes' ? [...tags] : [...updatedData.tags.dislikes],
			};

			updatedData['tags'] = updatedTags;
			setTagErrorMessages((prev) => {
				const { tagLabel, ...errorMessages } = prev;
				return errorMessages;
			});
			handleFormUpdate('data', updatedData);
			closeTagMenu();
		}
	};

	const openTagMenu = (e, type) => {
		return (
			setAnchorEl(
				type === 'likes'
					? likeContainerRef.current
					: dislikeContainerRef.current
			),
			setOpen(true),
			setTagType(type)
		);
	};

	const closeTagMenu = () => {
		return setAnchorEl(null), setOpen(!open), setTagType(null);
	};

	const getTags = (type) => {
		let tags = [];
		if (formData.data.tags[type].length > 0) {
			tags = formData.data.tags[type].map((data, index) => (
				<li style={{ float: 'left' }} key={index}>
					<Chip
						label={data}
						className={
							type === 'likes' ? classes.likeChip : classes.dislikeChip
						}
						color='primary'
					/>
				</li>
			));
		}
		tags.push(
			<li style={{ float: 'left' }} key='key'>
				<Chip
					label='Add Tag'
					classes={{ root: classes.addTagChip }}
					color='primary'
					onClick={(e) => openTagMenu(e, type)}
					variant='outlined'
				/>
			</li>
		);
		return tags;
	};

	return (
		<>
			<AppBar
				elevation={1}
				position='static'
				style={{ color: theme.palette.text.disabled, backgroundColor: 'unset' }}
			>
				<Tabs
					value={value}
					onChange={handleChangeTab}
					variant='fullWidth'
					aria-label='full width tabs example'
				>
					<Tab
						label={
							errorTabs[0] === true ? (
								<div>
									Personal Information
									<WarningIcon
										style={{
											verticalAlign: 'middle',
											marginLeft: 20,
											color: theme.palette.error.light,
										}}
									/>
								</div>
							) : (
								'Personal Information'
							)
						}
						{...a11yProps(0)}
					/>
					<Tab
						label={
							errorTabs[1] === true ? (
								<div>
									Company Information
									<WarningIcon
										style={{
											verticalAlign: 'middle',
											marginLeft: 20,
											color: theme.palette.error.light,
										}}
									/>
								</div>
							) : (
								'Company Information'
							)
						}
						{...a11yProps(1)}
					/>
					<Tab
						label={
							errorTabs[2] === true ? (
								<div>
									Relationship
									<WarningIcon
										style={{
											verticalAlign: 'middle',
											marginLeft: 20,
											color: theme.palette.error.light,
										}}
									/>
								</div>
							) : (
								'Relationship'
							)
						}
						{...a11yProps(2)}
					/>
				</Tabs>
			</AppBar>
			<TabPanel
				className={
					isDesktopOrLaptop ? classes.tabPanel : classes.tabPanelMobile
				}
				value={value}
				index={0}
				dir={theme.direction}
			>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={4}>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								First Name
							</Typography>
							<Typography
								style={{ marginLeft: 2 }}
								color='error'
								variant='body1'
								align='right'
							>
								*
							</Typography>
						</Grid>
						<TextField
							name='firstName'
							value={formData.data.firstName}
							onChange={handleInputChange}
							placeholder="Enter Client's First Name"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.firstName`]}
							helperText={
								errorMessages[`data.firstName`] &&
								errorMessages[`data.firstName`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Middle Name
						</Typography>
						<TextField
							name='middleName'
							value={formData.data.middleName}
							onChange={handleInputChange}
							placeholder="Enter Client's Middle Name"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.middleName`]}
							helperText={
								errorMessages[`data.middleName`] &&
								errorMessages[`data.middleName`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Last Name
						</Typography>
						<TextField
							name='lastName'
							value={formData.data.lastName}
							onChange={handleInputChange}
							placeholder="Enter Client's Last Name"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.lastName`]}
							helperText={
								errorMessages[`data.lastName`] && errorMessages[`data.lastName`]
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Date of Birth
						</Typography>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								name='dob'
								disableToolbar
								placeholder='DD/MM/YYYY'
								inputVariant='filled'
								openTo='year'
								views={['year', 'month', 'date']}
								format='dd/MM/yyyy'
								disableFuture
								margin='dense'
								fullWidth
								value={formData.data.dob ? formData.data.dob : null}
								autoOk
								variant='inline'
								maxDate={new Date()}
								invalidDateMessage='Invalid Date Format'
								onChange={(date) => handleDateChange('dob', date)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Gender
						</Typography>
						<Select
							native
							name='gender'
							value={formData.data.gender}
							onChange={handleInputChange}
							variant='filled'
							fullWidth
							autoWidth={true}
							margin='dense'
							style={{ marginTop: 8, marginBottom: 4 }}
						>
							<option value=''></option>
							<option value='male'>Male</option>
							<option value='female'>Female</option>
						</Select>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Marital Status
						</Typography>
						<Select
							native
							name='maritalStatus'
							value={formData.data.maritalStatus}
							onChange={handleInputChange}
							variant='filled'
							fullWidth
							autoWidth={true}
							margin='dense'
							style={{ marginTop: 8, marginBottom: 4 }}
						>
							<option value=''></option>
							<option value='single'>Single</option>
							<option value='married'>Married</option>
						</Select>
					</Grid>
				</Grid>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							NRIC / FIN / Passport No.
						</Typography>
						<TextField
							name='idNo'
							value={formData.data.idNo}
							onChange={handleInputChange}
							placeholder="Enter Client's NRIC / FIN / Passport No."
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.idNo`]}
							helperText={
								errorMessages[`data.idNo`] && errorMessages[`data.idNo`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Nationality
						</Typography>
						<Select
							native
							name='nationality'
							value={formData.data.nationality}
							onChange={handleInputChange}
							variant='filled'
							fullWidth
							autoWidth={true}
							margin='dense'
							style={{ marginTop: 8, marginBottom: 4 }}
						>
							<option key={0} value=''></option>
							{CountryList.sort((a, b) => -b.label.localeCompare(a.label)).map(
								(country, index) => {
									return (
										<option key={index} value={country.code}>
											{country.label}
										</option>
									);
								}
							)}
						</Select>
					</Grid>
				</Grid>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={4}>
						<Grid container justifyContent='flex-start'>
							<Typography color='textPrimary' variant='body2' align='left'>
								Email
							</Typography>
							<Typography
								style={{ marginLeft: 2 }}
								color='error'
								variant='body1'
								align='right'
							>
								*
							</Typography>
						</Grid>
						<TextField
							name='email'
							value={formData.data.email}
							onChange={handleInputChange}
							placeholder="Enter Client's Email Address"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.email`]}
							helperText={
								errorMessages[`data.email`] && errorMessages[`data.email`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Mobile
						</Typography>
						<TextField
							name='mobileNo'
							value={formData.data.mobileNo}
							onChange={handleInputChange}
							placeholder="Enter Client's Mobile Number"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.mobileNo`]}
							helperText={
								errorMessages[`data.mobileNo`] && errorMessages[`data.mobileNo`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Home
						</Typography>
						<TextField
							name='homeNo'
							value={formData.data.homeNo}
							onChange={handleInputChange}
							placeholder="Enter Client's Home Number"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.homeNo`]}
							helperText={
								errorMessages[`data.homeNo`] && errorMessages[`data.homeNo`]
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Address
						</Typography>
						<TextField
							name='address'
							value={formData.data.address}
							onChange={handleInputChange}
							placeholder="Enter Client's Blk / House No. / Street Address"
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.address`]}
							helperText={
								errorMessages[`data.address`] && errorMessages[`data.address`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Floor / Unit Number
						</Typography>
						<TextField
							name='unitNo'
							value={formData.data.unitNo}
							onChange={handleInputChange}
							placeholder='Enter Unit No.'
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.unitNo`]}
							helperText={
								errorMessages[`data.unitNo`] && errorMessages[`data.unitNo`]
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography color='textPrimary' variant='body2'>
							Postal / Zip Code
						</Typography>
						<TextField
							name='postalCode'
							value={formData.data.postalCode}
							onChange={handleInputChange}
							placeholder='Enter Postal Code'
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`data.postalCode`]}
							helperText={
								errorMessages[`data.postalCode`] &&
								errorMessages[`data.postalCode`]
							}
						/>
					</Grid>
				</Grid>
			</TabPanel>
			<TabPanel
				className={classes.tabPanelMobile}
				value={value}
				index={1}
				dir={theme.direction}
			>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{formData.companies?.map((company, index) => (
						<AssociatedCompanyForm
							key={company.id}
							index={index}
							company={company}
							errorMessages={errorMessages}
							handleChangePrimary={handleChangePrimary}
							handleChangeExisting={handleChangeExisting}
							handleSelectCompany={handleSelectCompany}
							handleRemoveCompany={handleRemoveCompany}
							handleCompanyInputChange={handleCompanyInputChange}
							handleCompanyDateChange={handleCompanyDateChange}
							disableRemove={formData.companies.length === 1}
							showDuplicateMessage={showDuplicateMessage}
						/>
					))}
					<Button
						style={{ alignSelf: 'flex-start' }}
						color='primary'
						startIcon={<AddCircleOutlineIcon />}
						onClick={handleAddCompany}
					>
						Add Company
					</Button>
				</div>
			</TabPanel>
			<TabPanel
				className={
					isDesktopOrLaptop ? classes.tabPanel : classes.tabPanelMobile
				}
				value={value}
				index={2}
				dir={theme.direction}
			>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<Typography color='textPrimary' variant='body2'>
							Where did you get to know this client?
						</Typography>
						<Select
							native
							name='source'
							value={formData.data.source}
							onChange={handleInputChange}
							variant='filled'
							fullWidth
							autoWidth={true}
							margin='dense'
							style={{ marginTop: 8, marginBottom: 4 }}
						>
							<option key={0} value=''></option>
							{SourceTypes.map((type, index) => {
								return (
									<option key={index} value={type}>
										{type}
									</option>
								);
							})}
						</Select>
					</Grid>
					{formData.data.source === 'Other' && (
						<Grid item xs={12} sm={12} md={7} lg={7}>
							<Grid container justifyContent='flex-start'>
								<Typography color='textPrimary' variant='body2'>
									Please Specify
								</Typography>
								<Typography
									style={{ marginLeft: 2 }}
									color='error'
									variant='body1'
									align='right'
								>
									*
								</Typography>
							</Grid>
							<TextField
								name='sourceInfo'
								value={formData.data.sourceInfo}
								onChange={handleInputChange}
								variant='filled'
								fullWidth
								margin='dense'
								error={!!errorMessages[`data.sourceInfo`]}
								helperText={
									errorMessages[`data.sourceInfo`] &&
									errorMessages[`data.sourceInfo`]
								}
							/>
						</Grid>
					)}
				</Grid>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={12} md={5} lg={5}>
						<Typography color='textPrimary' variant='body2'>
							Client's Likes
						</Typography>
						<Paper
							ref={likeContainerRef}
							elevation={0}
							style={{ height: 200, overflowY: 'auto', padding: 16 }}
						>
							<ul className={classes.root}>{getTags('likes')}</ul>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12} md={5} lg={5}>
						<Typography color='textPrimary' variant='body2'>
							Client's Dislikes
						</Typography>
						<Paper
							ref={dislikeContainerRef}
							elevation={0}
							style={{ height: 200, overflowY: 'auto', padding: 16 }}
						>
							<ul className={classes.root}>{getTags('dislikes')}</ul>
						</Paper>
					</Grid>
				</Grid>
				<Grid container spacing={4} style={{ paddingBottom: 10 }}>
					<Grid item xs={12} sm={12} md={10} lg={10}>
						<Typography color='textPrimary' variant='body2'>
							Notes
						</Typography>
						<TextField
							name='notes'
							value={formData.data.notes}
							multiline
							onChange={handleInputChange}
							placeholder='Enter any additional notes for this client'
							variant='filled'
							fullWidth
							margin='dense'
							minRows={4}
							error={!!errorMessages[`data.notes`]}
							helperText={
								errorMessages[`data.notes`] && errorMessages[`data.notes`]
							}
						/>
					</Grid>
				</Grid>
				<TagMenu
					open={open}
					anchorEl={anchorEl}
					position='right'
					type={tagType}
					handleUpdateTags={handleUpdateTags}
					closeTagMenu={() => closeTagMenu()}
					errorMessages={tagErrorMessages}
					formTags={formData.data.tags}
				/>
			</TabPanel>
		</>
	);
}
