import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '../Icons';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 32,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
}));

const EmailPreviewer = (props) => {
	const { open, handleClose, emailData } = props;
	const classes = useStyles();
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={false}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<div className={classes.paper}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography
						style={{ textTransform: 'uppercase' }}
						color='textPrimary'
						variant='h3'
					>
						Preview
					</Typography>
					<IconButton
						style={{ marginLeft: 'auto', padding: 0 }}
						disableRipple
						disableFocusRipple
						disableTouchRipple
						onClick={handleClose}
					>
						<Close />
					</IconButton>
				</div>
				<div style={{ marginTop: 24 }}>
					<Typography color='textPrimary' variant='body2'>
						Subject
					</Typography>
					<Typography color='textPrimary' variant='body1'>
						{emailData.subject ? emailData.subject : ''}
					</Typography>
				</div>
				<div style={{ marginTop: 16 }}>
					<Typography color='textPrimary' variant='body2'>
						Message
					</Typography>
					<div style={{ marginTop: 8, height: '60vh', overflowY: 'scroll' }}>
						{ReactHtmlParser(emailData.html)}
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default EmailPreviewer;
