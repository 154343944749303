import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getPolicyDocument } from '../../../../../../utils/formHelper';
import Tooltip from '../../../../../../components/Tooltip';
import FileInfoCard from '../../../../../../components/FileInfoCard';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '16px 24px',
	},
}));

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

const initFormData = {
	policyNo: '',
	principalId: '',
	principalName: '',
	premiumAmt: null,
	sumInsured: null,
	startDate: null,
	endDate: null,
	commission: null,
	remarks: '',
	fileData: null,
};

export default function ExistingPolicyDetailsViewer(props) {
	const { transaction, setTransaction, setTriggerUpdate } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const [existingPolicyData, setExistingPolicyData] = useState(initFormData);

	useEffect(() => {
		if (transaction.source === 'External') {
			setExistingPolicyData(transaction.prevPolicyData);
		} else {
			setExistingPolicyData(
				transaction.refTransaction.transactionId.policyData
			);
		}
	}, [props]);

	return (
		<div className={classes.root}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={4}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Insurer
					</Typography>
					<Typography
						color='textPrimary'
						variant='body1'
						align='left'
						style={{ marginTop: 8 }}
					>
						{existingPolicyData.principalName}
					</Typography>
				</Grid>
				<Box width='100%' />
				<Grid item xs={12} sm={4}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Policy Number
					</Typography>
					<Typography
						color='textPrimary'
						variant='body1'
						align='left'
						style={{ marginTop: 8 }}
					>
						{existingPolicyData.policyNo}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Period of Insurance
					</Typography>
					<Typography
						color='textPrimary'
						variant='body1'
						align='left'
						style={{ marginTop: 8 }}
					>
						{`${moment(existingPolicyData.startDate).format(
							'DD/MM/YYYY'
						)} - ${moment(existingPolicyData.endDate).format('DD/MM/YYYY')}`}
					</Typography>
				</Grid>
				<Box width='100%' />
				<Grid item xs={12} sm={4}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Typography
							style={{ marginRight: 8 }}
							color='textPrimary'
							variant='body2'
							align='left'
						>
							Total Premium
						</Typography>
						<Tooltip
							content={`Enter Premium inclusive of Goods & Services Tax (GST) if
							applicable. This Premium will be reflected in your Invoice
							to your Client.`}
						/>
					</div>
					<Typography
						color='textPrimary'
						variant='body1'
						align='left'
						style={{ marginTop: 8 }}
					>
						{existingPolicyData.premiumAmt &&
							currencyFormatter.format(existingPolicyData.premiumAmt)}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Typography
							style={{ marginRight: 8 }}
							color='textPrimary'
							variant='body2'
							align='left'
						>
							Sum Insured
						</Typography>
						<Tooltip
							content={`Enter "0" if Sum Insured is available in the
										Quotation/Policy Document`}
						/>
					</div>
					<Typography
						color='textPrimary'
						variant='body1'
						align='left'
						style={{ marginTop: 8 }}
					>
						{existingPolicyData.sumInsured
							? currencyFormatter.format(existingPolicyData.sumInsured)
							: 'Refer to Policy Document'}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Agent's Commission
					</Typography>
					<Typography
						color='textPrimary'
						variant='body1'
						align='left'
						style={{ marginTop: 8 }}
					>
						{existingPolicyData.commission &&
							currencyFormatter.format(existingPolicyData.commission)}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography color='textPrimary' variant='body2' align='left'>
						Remarks
					</Typography>
					<Typography
						color='textPrimary'
						variant='body1'
						align='left'
						style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
					>
						{existingPolicyData.remarks ? existingPolicyData.remarks : 'N/A'}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						style={{ marginRight: 8 }}
						color='textPrimary'
						variant='body2'
						align='left'
					>
						Policy Document
					</Typography>
					<div
						style={{
							width: '100%',
							marginTop: 8,
							marginBottom: 4,
						}}
					>
						<FileInfoCard
							file={
								transaction.source === 'External'
									? getPolicyDocument(
											transaction.source,
											transaction.policyDocuments
									  )
									: getPolicyDocument(
											transaction.source,
											transaction.refTransaction.transactionId.policyDocuments
									  )
							}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}
