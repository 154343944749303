const primary = '#1B5998';
const secondary = '#E8EBF7';
const tertiary = '#5E80E6';
const warning = '#F7C16F';
const error = '#F16063';
const success = '#66CB94';
const info = '#4A93DC';
const dark = '#222222';

const lightenRate = 7.5;
const darkenRate = 15;

export default {
	breakpoints: {
		values: {
			xs: 0,
			sm: 768,
			md: 1024,
			lg: 1280,
		},
	},
	palette: {
		primary: {
			main: primary,
			light: '#2372C3',
			dark: '#13406D',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: secondary,
			light: '#F1F3FA',
			dark: '#C2CAEA',
			contrastText: '#505780',
		},
		tertiary: {
			main: tertiary,
			light: '#B7C6F3',
			dark: '#5371CC',
			contrastText: '#FFFFFF',
			hover: '#DCE2EE',
		},
		warning: {
			main: warning,
			light: '#FAD69F',
			dark: '#A8701D',
			contrastText: '#FFFFFF',
		},
		error: {
			main: error,
			light: '#F58F91',
			dark: '#ED3135',
			contrastText: '#FFFFFF',
		},
		success: {
			main: success,
			light: '#8CD8AF',
			dark: '#40BE79',
			contrastText: '#FFFFFF',
		},
		info: {
			main: info,
			light: '#75ACE4',
			dark: '#2779CC',
			contrastText: '#FFFFFF',
		},
		dark: {
			main: dark,
			light: '#505780',
			dark: '#101225',
			contrastText: '#FFFFFF',
		},
		text: {
			primary: '#222222',
			secondary: '#718096',
			disabled: '#718096',
			hint: '#B9B9B9',
			contrastText: '#FFFFFF',
		},

		grey: {
			50: '#FAFAFA',
			100: '#F7FAFC',
			200: '#EDF2F7',
			300: '#E2E8F0',
			400: '#CBD5E0',
			500: '#A0AEC0',
			600: '#718096',
			700: '#4A5568',
			800: '#2D3748',
			900: '#1A202C',
		},
		background: {
			default: '#FFFFFF',
			paper: '#F7FAFC',
			secondary: '#F1F3FA',
			light: '#D5D5DC',
			dark: '#17171B',
		},
	},
	customShadows: {
		widget:
			'0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
		widgetDark:
			'0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
		widgetWide:
			'0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
	},
	typography: {
		fontFamily: ['Poppins'].join(','),
		h1: {
			fontSize: '2.25rem',
			'@media (max-width:768px)': {
				fontSize: '1.75rem',
				lineHeight: '40px',
			},
			fontWeight: 600,
		},
		h2: {
			fontSize: '1.75rem',
			'@media (max-width:768px)': {
				fontSize: '1.5rem',
				lineHeight: '36px',
			},
			fontWeight: 600,
		},
		h3: {
			fontSize: '1.25rem',
			'@media (max-width:768px)': {
				fontSize: '1.125rem',
				lineHeight: '28px',
			},
			fontWeight: 600,
		},
		h4: {
			fontSize: '1.125rem',
			fontWeight: 600,
		},
		h5: {
			fontSize: '1rem',
			fontWeight: 600,
		},
		h6: {
			fontSize: '0.875rem',
			fontWeight: 600,
		},
		body1: {
			fontSize: '1rem',
			fontWeight: 400,
		},
		body2: {
			fontSize: '1rem',
			fontWeight: 600,
			lineHeight: 1.5,
			letterSpacing: '0.00938em',
		},
		subtitle1: {
			fontSize: '0.875rem',
			fontWeight: 400,
		},
		subtitle2: {
			fontSize: '0.875rem',
			fontWeight: 600,
		},
		button: {
			fontSize: '0.75rem',
			fontWeight: 500,
		},
		caption: {
			fontSize: '0.75rem',
			fontWeight: 400,
		},
	},
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: '#4a4a4a80',
				zIndex: 0,
			},
		},
		MuiChip: {
			root: {
				height: 'unset',
				minHeight: 32,
				maxHeight: 38,
				borderRadius: 32,
				'& .MuiChip-icon': {
					color: 'currentcolor',
					minWidth: 16,
					maxWidth: 16,
					height: 20,
					marginLeft: 16,
					marginRight: 0,
				},
				'&.MuiChip-outlined .MuiChip-icon': {
					marginLeft: 16,
					marginRight: 0,
				},
			},
			label: {
				padding: '8px 16px',
			},
		},
		MuiSkeleton: {
			text: {
				height: 51,
				transform: 'unset',
				transformOrigin: 'unset',
				marginTop: 8,
				marginBottom: 4,
			},
			rect: {
				transform: 'unset',
				transformOrigin: 'unset',
				marginTop: 8,
				marginBottom: 4,
			},
		},
		MuiButton: {
			root: {
				padding: '8px 16px',
				fontSize: '0.875rem',
				borderRadius: 50,
				textTransform: 'unset',
			},
			containedPrimary: {
				color: '#F1F3FA',
			},
			outlined: {
				padding: '8px 16px',
			},
			outlinedPrimary: {
				color: tertiary,
				border: `1px solid ${tertiary}`,
				'&:hover': {
					border: `1px solid ${tertiary}`,
				},
			},
			text: {
				padding: '8px 16px',
			},
			textPrimary: {
				color: tertiary,
				'&:hover': {
					backgroundColor: 'unset',
				},
			},
		},
		MuiSelect: {
			icon: {
				color: '#B9B9B9',
			},
		},
		MuiAutocomplete: {
			inputRoot: {
				'&&[class*="MuiFilledInput-root"]': {
					padding: 0,
				},
				'&&[class*="MuiFilledInput-root"] $input': {
					padding: 16,
				},
			},
			groupLabel: {
				color: primary,
				backgroundColor: '#FFFFFF',
			},
			paper: {
				background: '#FFFFFF',
				boxShadow:
					'0px 0px 1px rgba(12, 26, 75, 0.2), 0px 1px 3px rgba(50, 50, 71, 0.1)',
				borderRadius: 4,
			},
		},
		MuiFilledInput: {
			root: {
				backgroundColor: '#F7FAFC',
				'&:hover': {
					backgroundColor: '#b7c6f35e',
				},
				'&.Mui-focused': {
					backgroundColor: '#b7c6f35e',
					'&:hover': {
						backgroundColor: '#b7c6f35e',
					},
				},
			},
			underline: {
				'&:hover:before': {
					borderBottom: `2px solid ${tertiary}`,
				},
				'&:before': {
					borderBottom: '2px solid #CBD5E0',
				},
				'&$disabled:before': {
					borderBottomStyle: 'solid',
				},
				'&.Mui-focused:after': {
					borderBottomColor: tertiary,
				},
				'&.Mui-error:after': {
					borderBottomColor: '#F58F91',
				},
			},
			inputMarginDense: {
				paddingTop: 16,
				paddingBottom: 16,
				padding: 16,
			},
		},
		MuiFormHelperText: {
			root: {
				fontWeight: 400,
			},
		},
		MuiMenu: {
			paper: {
				boxShadow:
					'0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
			},
		},
		MuiListItem: {
			root: {
				'&$selected': {
					backgroundColor: `${secondary} !important`,
					'&:hover': {
						backgroundColor: secondary,
					},
				},
			},
			button: {
				'&:hover': {
					backgroundColor: secondary,
				},
			},
		},
		MuiTouchRipple: {
			child: {
				backgroundColor: 'white',
			},
		},
		MuiTableRow: {
			root: {
				height: 64,
				'&$selected': {
					backgroundColor: 'rgba(183, 198, 243, 0.2)',
					'&:hover': {
						backgroundColor: 'rgba(183, 198, 243, 0.2)',
					},
				},
				'&$hover:hover': {
					backgroundColor: 'rgba(183, 198, 243, 0.2)',
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: '1rem',
				fontWeight: 400,
			},
		},
		MuiTabs: {
			root: {
				minHeight: 52,
			},
			indicator: {
				background: tertiary,
			},
		},
		MuiTab: {
			root: {
				fontSize: '1rem',
				fontWeight: 400,
				maxWidth: 'unset',
				'&$selected': {
					fontWeight: 600,
					color: tertiary,
				},
			},
			textColorInherit: {
				color: '#A0AEC0',
			},
		},
		MuiTablePagination: {
			root: {
				color: '#718096',
			},
			caption: {
				fontWeight: 400,
			},
		},
		MuiCheckbox: {
			colorSecondary: {
				color: '#BCB3E2',
				'&$checked': {
					color: 'rgb(94, 128, 230)',
				},
			},
		},
		MuiStepLabel: {
			label: {
				'&$active': {
					color: tertiary,
					fontWeight: 600,
				},
				'&$completed': {
					color: '#718096',
					fontWeight: 600,
				},
			},
		},
		MuiRadio: {
			colorPrimary: {
				'&$checked': {
					color: tertiary,
				},
			},
		},
		MuiTooltip: {
			tooltip: {
				padding: 16,
				borderRadius: 8,
				backgroundColor: '#C9DFF4',
				color: '#222222',
				fontSize: '0.875rem',
				fontWeight: 400,
				boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.06)',
			},
			arrow: {
				color: '#C9DFF4',
			},
		},
	},
};
