import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { PDFViewer, usePDF } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import Invoice from '../../../../components/Invoice';
import api from '../../../../config/api';
import DefaultTemplateList from '../../../../components/Data/emailTemplates.json';
import EmailPreviewer from '../../../../components/EmailPreviewer';
import { combineNames } from '../../../../utils/combineNames';
import { Preview } from '../../../../components/Icons';

const useStyles = makeStyles((theme) => ({}));

export default function InvoiceSummary(props) {
	const classes = useStyles();
	const theme = useTheme();
	const {
		formData,
		setFormData,
		handleInputChange,
		setPdfData,
		errorMessages,
	} = props;
	const [instance, update] = usePDF({
		document: <Invoice formData={formData} />,
	});
	const [emailData, setEmailData] = useState({});
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setPdfData(instance.blob);
	}, [props]);

	useEffect(() => {
		getEmailTemplate();
	}, []);

	const getEmailTemplate = () => {
		if (!loading) {
			setLoading(true);
		}

		let templateType = 'paymentRequest';
		api
			.get('email-templates/' + templateType)
			.then((response) => {
				if (response.data.ok) {
					setFormData((prev) => ({
						...prev,
						subject: response.data.data.subject,
						message: response.data.data.message,
					}));
				} else {
					let templateData = getDefaultTemplate(templateType);
					setFormData((prev) => ({
						...prev,
						subject: templateData.subject,
						message: templateData.message,
					}));
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getDefaultTemplate = (templateType) => {
		let templateData = DefaultTemplateList.find((item) => {
			return item.name === templateType;
		});
		return templateData;
	};

	const togglePreview = () => {
		if (!open) {
			getPreviewData();
		} else {
			setOpen(false);
		}
	};

	const getPreviewData = async () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.post('preview-template/paymentRequest', {
				subject: formData.subject,
				message: formData.message,
			})
			.then((response) => {
				if (response.data.ok) {
					setEmailData(response.data.data);
				} else {
					setEmailData({});
				}
				setLoading(false);
				setOpen(true);
			})
			.catch(function (error) {
				console.log(error);
				setEmailData({});
				setLoading(false);
				setOpen(false);
			});
	};

	return (
		<>
			<Grid
				style={{ marginTop: 24, marginBottom: 24 }}
				container
				spacing={4}
				direction='row'
				justifyContent='center'
				alignItems='stretch'
			>
				<Grid item xs={12} md={5}>
					<Grid container spacing={2} direction='column'>
						<Grid item>
							<Typography color='textPrimary' variant='body2'>
								To:
							</Typography>
							<TextField
								name='to'
								disabled
								variant='filled'
								fullWidth
								margin='dense'
								InputProps={{
									startAdornment: (
										<Chip
											key={formData.clientData.contact._id}
											tabIndex={-1}
											label={`${combineNames({
												fName: formData.clientData.contact.firstName,
												mName:
													formData.clientData.contact.middleName !== undefined
														? formData.clientData.contact.middleName
														: null,
												lName:
													formData.clientData.contact.lastName !== undefined
														? formData.clientData.contact.lastName
														: null,
											})} (${formData.clientData.contact.email})`}
											className={classes.chip}
										/>
									),
								}}
							/>
						</Grid>
						<Grid item>
							<Typography color='textPrimary' variant='body2'>
								Subject
							</Typography>
							<TextField
								name='subject'
								value={formData.subject}
								onChange={(e) => handleInputChange(e)}
								variant='filled'
								fullWidth
								margin='dense'
								disabled={loading}
								error={!!errorMessages[`subject`]}
								helperText={
									errorMessages[`subject`] && errorMessages[`subject`]
								}
							/>
						</Grid>
						<Grid item>
							<Typography color='textPrimary' variant='body2'>
								Message
							</Typography>
							<TextField
								name='message'
								value={formData.message}
								multiline
								onChange={(e) => handleInputChange(e)}
								variant='filled'
								fullWidth
								margin='dense'
								minRows={10}
								disabled={loading}
								error={!!errorMessages[`message`]}
								helperText={
									errorMessages[`message`] && errorMessages[`message`]
								}
							/>
						</Grid>
						<Grid item>
							<Button
								variant='outlined'
								color='primary'
								startIcon={
									<Preview
										style={{ fontSize: 24 }}
										colorcode={theme.palette.tertiary.main}
									/>
								}
								onClick={() => togglePreview()}
							>
								Preview
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={7}>
					<PDFViewer style={{ width: '100%', height: '65vh' }}>
						<Invoice formData={formData} />
					</PDFViewer>
				</Grid>
			</Grid>
			<EmailPreviewer
				open={open}
				handleClose={() => togglePreview()}
				emailData={emailData}
			/>
		</>
	);
}
