import DateFnsUtils from '@date-io/date-fns';
import Backdrop from '../../../components/Backdrop';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import NavPrompt from '../../../components/NavPrompt';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import SuccessMessage from '../../../components/SuccessMessage';
import api from '../../../config/api';
import Summary from './Summary';
import { Invoice, Email } from '../../../utils/validators/schemas';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 24,
		backgroundColor: theme.palette.secondary.light,
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	invoiceInfoContainer: {
		alignItems: 'center',
	},
	invoiceInfoHeader: {
		textAlign: 'right',
	},
	invoiceInfoField: {
		marginLeft: 16,
	},
}));

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

function getSteps() {
	return ['Prepare Invoice', 'Review and Submit'];
}

export default function CreateInvoice(props) {
	const { id } = useParams();
	const classes = useStyles();
	const theme = useTheme();
	const steps = getSteps();
	const [activeStep, setActiveStep] = useState(0);
	const [isStepInvalid, setIsStepInvalid] = useState(false);

	const [formData, setFormData] = useState({});
	const [pdfData, setPdfData] = useState(null);
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const [successInfo, setSuccessInfo] = useState(null);
	const [errorCode, setErrorCode] = useState('');
	const [errors, setErrors] = useState('');
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(true);
	const [modified, setModified] = useState(false);

	const getData = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('invoices/prepare/' + id)
			.then((response) => {
				if (!response.data.ok) {
					setActiveStep(2);
					setSuccess(false);
					setErrorCode(response.data.errorCode);
					setErrors(response.data.error);
					setLoading(false);
				} else {
					setFormData(response.data.data);

					setLoading(false);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getDueDate = (date, days) => {
		var result = new Date(date);
		result.setDate(result.getDate() + days);

		setFormData((prev) => ({
			...prev,
			dueDate: result,
		}));
	};

	useEffect(() => {
		getData();
	}, [id]);

	const handleValidation = () => {
		let result = {};
		if (activeStep === 0) {
			let data;
			if (formData.isCommercial) {
				data = {
					dueDate: formData.dueDate,
					address: formData.clientData.company.companyAddress,
					unitNo: formData.clientData.company.companyUnitNo,
					postalCode: formData.clientData.company.companyPostalCode,
					officeNo: formData.clientData.company.officeNo,
					terms: formData.settings.terms,
				};
			} else {
				data = {
					dueDate: formData.dueDate,
					address: formData.clientData.contact.address,
					unitNo: formData.clientData.contact.unitNo,
					postalCode: formData.clientData.contact.postalCode,
					mobileNo: formData.clientData.contact.mobileNo,
					terms: formData.settings.terms,
				};
			}
			result = Invoice.validate(data, { abortEarly: false, convert: false });
		}
		if (activeStep === 1) {
			result = Email.validate(
				{
					subject: formData.subject,
					message: formData.message,
				},
				{ abortEarly: false, convert: false }
			);
		}

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			console.log(error.details);
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleInputChange = (e, obj) => {
		const {
			target: { value, name },
		} = e;
		if (obj === 'contactData') {
			let updatedContactData = {
				...formData.clientData.contact,
				[name]: value,
			};
			setModified(true);
			return setFormData((prev) => ({
				...prev,
				clientData: {
					...prev.clientData,
					contact: updatedContactData,
				},
			}));
		} else if (obj === 'companyData') {
			let updatedCompanyData = {
				...formData.clientData.company,
				[name]: value,
			};
			setModified(true);
			return setFormData((prev) => ({
				...prev,
				clientData: {
					...prev.clientData,
					company: updatedCompanyData,
				},
			}));
		} else if (obj === 'settings') {
			let updatedSettings = {
				...formData.settings,
				[name]: value,
			};
			setModified(true);
			return setFormData((prev) => ({
				...prev,
				settings: updatedSettings,
			}));
		} else {
			setModified(true);
			setFormData((prev) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	const handleDateChange = (target, date) => {
		setModified(true);
		setFormData((prev) => ({
			...prev,
			[target]: date,
		}));
	};

	const handleNext = (e) => {
		e.preventDefault();
		setLoading(true);

		let valid = handleValidation();

		if (valid) {
			if (activeStep === steps.length - 1) {
				e.preventDefault();
				setOpen(true);
				const data = new FormData();

				let generatedDate = moment(new Date()).format('DD-MM-YYYY');

				data.append('Invoice', pdfData, `invoice-${generatedDate}.pdf`);

				let json = cloneDeep(formData);

				delete json.settings.qrImage;
				json.userProfile = {
					firstName: json.userProfile.firstName,
					lastName: json.userProfile.lastName,
					agentId: json.userProfile.agentId,
				};
				json.orgProfile = {
					name: json.orgProfile.name,
					address: json.orgProfile.address,
					unitNo: json.orgProfile.unitNo,
					postalCode: json.orgProfile.postalCode,
					uen: json.orgProfile.uen,
					logo: json.orgProfile.logo,
				};

				console.log(JSON.stringify(json, null, 4));
				data.append('json', JSON.stringify(json));

				try {
					api.post('invoices/' + id, data).then((res) => {
						setOpen(false);
						if (res.data.ok) {
							setModified(false);
							setSuccess(true);
							setErrorMessages({});
							setErrorCode('');
							setErrors('');
						} else {
							setSuccess(false);
							setErrorCode(res.data.errorCode);
							setErrors(res.data.error);
						}
						setActiveStep((prevActiveStep) => prevActiveStep + 1);
					});
				} catch (error) {
					console.log(error);
					setSuccess(false);
					setErrors(error);
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else {
			toast.error('An error has occured!');
		}
		setLoading(false);
	};

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<>
						<Breadcrumbs separator='›' aria-label='breadcrumb'>
							<Link component={RouterLink} color='inherit' to={'/transactions'}>
								Invoices
							</Link>
							<Typography color='primary'>New Invoice</Typography>
						</Breadcrumbs>
						<Typography variant='h2' style={{ margin: '24px 0px 0px 0px' }}>
							New Invoice for TXN-{formData.transactionNo}
						</Typography>
						<Paper
							className={classes.paper}
							style={{ marginTop: 24, marginBottom: 24 }}
							elevation={2}
						>
							<div className='invoice-header'>
								<Grid container style={{ paddingBottom: 16 }}>
									<Grid item xs={3}>
										<div className='bill-to'>
											<Typography color='textPrimary' variant='body2'>
												BILL TO
											</Typography>
											{formData.isCommercial && (
												<Typography
													style={{ margin: '16px 0px' }}
													color='textPrimary'
													variant='body2'
												>
													{formData.clientData.companyName}
												</Typography>
											)}
											<Typography
												style={{ margin: '16px 0px' }}
												color='textPrimary'
												variant={formData.isCommercial ? 'body1' : 'body2'}
											>
												{formData.clientData.contactName}
											</Typography>
											{formData.isCommercial ? (
												<>
													<TextField
														name='companyAddress'
														value={formData.clientData.company.companyAddress}
														onChange={(e) =>
															handleInputChange(e, 'companyData')
														}
														placeholder='Enter Company Address'
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`address`]}
														helperText={
															errorMessages[`address`] &&
															errorMessages[`address`]
														}
													/>
													<TextField
														name='companyUnitNo'
														value={formData.clientData.company.companyUnitNo}
														onChange={(e) =>
															handleInputChange(e, 'companyData')
														}
														placeholder='Enter Company Unit No'
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`unitNo`]}
														helperText={
															errorMessages[`unitNo`] && errorMessages[`unitNo`]
														}
													/>
													<TextField
														name='companyPostalCode'
														value={
															formData.clientData.company.companyPostalCode
														}
														onChange={(e) =>
															handleInputChange(e, 'companyData')
														}
														placeholder='Enter Company Postal Code'
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`postalCode`]}
														helperText={
															errorMessages[`postalCode`] &&
															errorMessages[`postalCode`]
														}
													/>
												</>
											) : (
												<>
													<TextField
														name='address'
														value={formData.clientData.contact.address}
														onChange={(e) =>
															handleInputChange(e, 'contactData')
														}
														placeholder='Enter Address'
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`address`]}
														helperText={
															errorMessages[`address`] &&
															errorMessages[`address`]
														}
													/>
													<TextField
														name='unitNo'
														value={formData.clientData.contact.unitNo}
														onChange={(e) =>
															handleInputChange(e, 'contactData')
														}
														placeholder='Enter Unit No'
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`unitNo`]}
														helperText={
															errorMessages[`unitNo`] && errorMessages[`unitNo`]
														}
													/>
													<TextField
														name='postalCode'
														value={formData.clientData.contact.postalCode}
														onChange={(e) =>
															handleInputChange(e, 'contactData')
														}
														placeholder='Enter Postal Code'
														variant='filled'
														fullWidth
														margin='dense'
														error={!!errorMessages[`postalCode`]}
														helperText={
															errorMessages[`postalCode`] &&
															errorMessages[`postalCode`]
														}
													/>
												</>
											)}
											<TextField
												name='email'
												value={formData.clientData.contact.email}
												onChange={(e) => handleInputChange(e, 'contactData')}
												placeholder='Enter Email'
												variant='filled'
												fullWidth
												margin='dense'
												disabled
												error={!!errorMessages[`email`]}
												helperText={
													errorMessages[`email`] && errorMessages[`email`]
												}
											/>
											{formData.isCommercial ? (
												<TextField
													name='officeNo'
													value={formData.clientData.company.officeNo}
													onChange={(e) => handleInputChange(e, 'companyData')}
													placeholder='Enter Office No'
													variant='filled'
													fullWidth
													margin='dense'
													error={!!errorMessages[`officeNo`]}
													helperText={
														errorMessages[`officeNo`] &&
														errorMessages[`officeNo`]
													}
												/>
											) : (
												<TextField
													name='mobileNo'
													value={formData.clientData.contact.mobileNo}
													onChange={(e) => handleInputChange(e, 'contactData')}
													placeholder='Enter Mobile No'
													variant='filled'
													fullWidth
													margin='dense'
													error={!!errorMessages[`mobileNo`]}
													helperText={
														errorMessages[`mobileNo`] &&
														errorMessages[`mobileNo`]
													}
												/>
											)}
										</div>
									</Grid>
									<Grid item xs={5}></Grid>
									<Grid item xs={4}>
										<div className='invoice-info'>
											<Grid container className={classes.invoiceInfoContainer}>
												<Grid item xs={6}>
													<Typography
														className={classes.invoiceInfoHeader}
														color='textPrimary'
														variant='body2'
													>
														Invoice Number:
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography
														className={classes.invoiceInfoField}
														color='textPrimary'
														variant='body1'
														align='left'
													>
														INV-{formData.invoiceNo}
													</Typography>
												</Grid>
											</Grid>
											<Grid container className={classes.invoiceInfoContainer}>
												<Grid item xs={6}>
													<Typography
														className={classes.invoiceInfoHeader}
														color='textPrimary'
														variant='body2'
													>
														Transaction Number:
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography
														className={classes.invoiceInfoField}
														color='textPrimary'
														variant='body1'
														align='left'
													>
														TXN-{formData.transactionNo}
													</Typography>
												</Grid>
											</Grid>
											<Grid container className={classes.invoiceInfoContainer}>
												<Grid item xs={6}>
													<Typography
														className={classes.invoiceInfoHeader}
														color='textPrimary'
														variant='body2'
													>
														Invoice Date:
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<Typography
														className={classes.invoiceInfoField}
														color='textPrimary'
														variant='body1'
														align='left'
													>
														{moment(new Date()).format('DD/MM/YYYY')}
													</Typography>
												</Grid>
											</Grid>
											<Grid container className={classes.invoiceInfoContainer}>
												<Grid item xs={6}>
													<Typography
														className={classes.invoiceInfoHeader}
														color='textPrimary'
														variant='body2'
													>
														Payment Due:
													</Typography>
												</Grid>
												<Grid item xs={6}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<KeyboardDatePicker
															className={classes.invoiceInfoField}
															name='dueDate'
															disableToolbar
															placeholder='DD/MM/YYYY'
															inputVariant='filled'
															format='dd/MM/yyyy'
															openTo='year'
															views={['year', 'month', 'date']}
															margin='dense'
															value={
																formData.dueDate
																	? formData.dueDate
																	: getDueDate(
																			new Date(),
																			formData.settings.paymentDue
																	  )
															}
															autoOk
															variant='inline'
															invalidDateMessage='Invalid Date Format'
															onChange={(date) =>
																handleDateChange('dueDate', date)
															}
															KeyboardButtonProps={{
																'aria-label': 'change date',
															}}
															error={!!errorMessages[`dueDate`]}
															helperText={
																errorMessages[`dueDate`] &&
																errorMessages[`dueDate`]
															}
														/>
													</MuiPickersUtilsProvider>
												</Grid>
											</Grid>
										</div>
									</Grid>
								</Grid>
							</div>
							<div className='invoice-table' style={{ margin: '16px 0px' }}>
								<Grid container>
									<Grid item xs={3}>
										<Typography color='textPrimary' variant='body2'>
											Policy Number
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<Typography color='textPrimary' variant='body2'>
											Description
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<Typography color='textPrimary' variant='body2'>
											Premium
										</Typography>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={3}>
												<Typography color='primary' variant='body2'>
													{formData.policyData.policyNo}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography
													style={{ marginBottom: 16 }}
													color='primary'
													variant='body2'
												>
													{formData.insuranceType}{' '}
													{formData.insuranceSubType !== '' &&
														`(${formData.insuranceSubType})`}
												</Typography>
												<Grid
													container
													className={classes.invoiceInfoContainer}
												>
													<Grid item xs={3}>
														<Typography color='textPrimary' variant='body2'>
															Insured By:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														<Typography
															className={classes.invoiceInfoField}
															color='textPrimary'
															variant='body1'
															align='left'
														>
															{formData.policyData.principalName}
														</Typography>
													</Grid>
												</Grid>
												<Grid
													container
													className={classes.invoiceInfoContainer}
												>
													<Grid item xs={3}>
														<Typography color='textPrimary' variant='body2'>
															Period of Insurance:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														<Typography
															className={classes.invoiceInfoField}
															color='textPrimary'
															variant='body1'
															align='left'
														>
															{moment(formData.policyData.startDate).format(
																'DD/MM/YYYY'
															)}{' '}
															-
															{moment(formData.policyData.endDate).format(
																'DD/MM/YYYY'
															)}
														</Typography>
													</Grid>
												</Grid>
												<Grid
													container
													className={classes.invoiceInfoContainer}
												>
													<Grid item xs={3}>
														<Typography color='textPrimary' variant='body2'>
															Sum Insured:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														<Typography
															className={classes.invoiceInfoField}
															color='textPrimary'
															variant='body1'
															align='left'
														>
															{formData.policyData.sumInsured
																? currencyFormatter.format(
																		formData.policyData.sumInsured
																  )
																: 'Refer to Policy Document'}
														</Typography>
													</Grid>
												</Grid>
												<Grid
													container
													className={classes.invoiceInfoContainer}
												>
													<Grid item xs={3}>
														<Typography color='textPrimary' variant='body2'>
															Remarks:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														<Typography
															className={classes.invoiceInfoField}
															style={{ whiteSpace: 'pre-wrap' }}
															color='textPrimary'
															variant='body1'
															align='left'
														>
															{formData.policyData.remarks}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={3}>
												<Typography color='primary' variant='body2'>
													{currencyFormatter.format(
														formData.policyData.premiumAmt
													)}
												</Typography>
											</Grid>
										</Grid>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={9}>
												<Typography
													style={{
														textAlign: 'right',
														marginRight: 16,
													}}
													color='textPrimary'
													variant='body2'
												>
													Total (SGD)
												</Typography>
											</Grid>
											<Grid item xs={3}>
												<Typography color='textPrimary' variant='body2'>
													{currencyFormatter.format(
														formData.policyData.premiumAmt
													)}
												</Typography>
											</Grid>
										</Grid>
										<Divider
											style={{
												backgroundColor: theme.palette.primary.light,
												height: 2,
												marginTop: 16,
												marginBottom: 16,
											}}
										/>
									</Grid>
								</Grid>
							</div>
							<div className='terms-table'>
								<Grid container style={{ paddingBottom: 16 }}>
									<Grid item xs={12}>
										<Typography color='textPrimary' variant='body2'>
											Terms & Conditions / Notes
										</Typography>
										<TextField
											name='terms'
											value={formData.settings.terms}
											onChange={(e) => handleInputChange(e, 'settings')}
											placeholder='Enter Notes'
											variant='filled'
											fullWidth
											margin='dense'
											multiline
											minRows={4}
											error={!!errorMessages[`terms`]}
											helperText={
												errorMessages[`terms`] && errorMessages[`terms`]
											}
										/>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</>
				);
			case 1:
				return (
					<>
						<Breadcrumbs separator='›' aria-label='breadcrumb'>
							<Link component={RouterLink} color='inherit' to={'/transactions'}>
								Invoices
							</Link>
							<Typography color='inherit'>New Invoice</Typography>
							<Typography color='primary'>Preview and Send</Typography>
						</Breadcrumbs>
						<Typography variant='h2' style={{ margin: '24px 0px 0px 0px' }}>
							Preview and Send
						</Typography>
						<Summary
							formData={formData}
							setFormData={setFormData}
							handleInputChange={handleInputChange}
							setPdfData={setPdfData}
							errorMessages={errorMessages}
						/>
					</>
				);
			case 2:
				return (
					<>
						{success ? (
							<SuccessMessage type={'invoice-sent'} successInfo={successInfo} />
						) : (
							<SuccessMessage
								type={errorCode}
								errorMessages={errors}
								handleBack={() =>
									setActiveStep((prevActiveStep) => prevActiveStep - 1)
								}
							/>
						)}
					</>
				);
			default:
				return 'Unknown step';
		}
	};

	if (loading) return <Loader size='lg' />;
	return (
		<>
			<NavPrompt when={modified} />
			<Backdrop
				text='Please wait a moment, we’re sending out your request...'
				open={open}
			/>
			<div className={classes.content}>{getStepContent(activeStep)}</div>
			<div
				className='form-footer'
				style={{ display: 'flex', alignItems: 'center' }}
			>
				{activeStep !== steps.length && (
					<>
						<Button
							style={{
								display: activeStep === 0 && 'none',
								alignItems: 'center',
								textDecoration: 'none',
							}}
							color='primary'
							variant='outlined'
							onClick={() =>
								setActiveStep((prevActiveStep) => prevActiveStep - 1)
							}
						>
							<NavigateBeforeIcon />
							<Typography>Back</Typography>
						</Button>
						<Button
							style={{
								alignItems: 'center',
								textDecoration: 'none',
								marginLeft: 'auto',
								marginRight: 16,
							}}
							component={RouterLink}
							color='primary'
							variant='outlined'
							to={'/transactions/' + id}
						>
							Cancel
						</Button>
						<Button
							variant='contained'
							color='primary'
							disabled={isStepInvalid || loading}
							onClick={handleNext}
						>
							{activeStep === steps.length - 1 ? 'Send' : 'Preview & Send'}
						</Button>
					</>
				)}
			</div>
		</>
	);
}
