import Backdrop from '../../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Preview, Send } from '../../../components/Icons';
import DefaultTemplateList from '../../../components/Data/emailTemplates.json';
import EmailPreviewer from '../../../components/EmailPreviewer';
import { AuthUserContext } from '../../../components/Session';
import api from '../../../config/api';
import { combineNames } from '../../../utils/combineNames';
import { Email } from '../../../utils/validators/schemas';
import '../styles.css';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({}));

function groupBy(arr, keyGetter) {
	const map = new Map();
	arr.forEach((item) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	const result = Array.from(map).map(([principalName, contacts]) => ({
		principalName,
		contacts,
	}));

	return result;
}

const getDefaultTemplate = (templateType) => {
	let templateData = DefaultTemplateList.find((item) => {
		return item.name === templateType;
	});
	return templateData;
};

export default function SendRequestForQuotations(props) {
	const initFormData = {
		subject: '',
		message: '',
	};
	const { open, handleClose, body, transactionId, data } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [formData, setFormData] = useState(initFormData);
	const [contacts, setContacts] = useState([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [emailData, setEmailData] = useState({});
	const [availableContacts, setAvailableContacts] = useState([]);
	const [selectedContacts, setSelectedContacts] = useState([]);
	const [selectedCCContacts, setSelectedCCContacts] = useState([]);
	const [errorMessages, setErrorMessages] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setAvailableContacts(contacts);
	}, [contacts]);

	useEffect(() => {
		getUnderwriters();
		getEmailTemplate();
	}, []);

	const getUnderwriters = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('underwriters')
			.then((response) => {
				if (response.data.data.length > 0) {
					let filteredContactList = response.data.data
						.map((option) => {
							if (data) {
								if (option.principalName === data) {
									return {
										principalName: option.principalName,
										...option,
									};
								} else {
									return null;
								}
							} else {
								return {
									principalName: option.principalName,
									...option,
								};
							}
						})
						.filter((option) => option !== null);
					setContacts(filteredContactList);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getEmailTemplate = () => {
		if (!loading) {
			setLoading(true);
		}

		let templateType = 'quoteRequest';
		api
			.get('email-templates/' + templateType)
			.then((response) => {
				if (response.data.ok) {
					setFormData((prev) => ({
						...prev,
						subject: response.data.data.subject,
						message: response.data.data.message,
					}));
				} else {
					let templateData = getDefaultTemplate(templateType);
					setFormData((prev) => ({
						...prev,
						subject: templateData.subject,
						message: templateData.message,
					}));
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const togglePreview = () => {
		if (!previewOpen) {
			getPreviewData();
		} else {
			setPreviewOpen(false);
		}
	};

	const getPreviewData = async () => {
		if (!loading) {
			setLoading(true);
		}

		api
			.post('preview-template/quoteRequest', {
				subject: formData.subject,
				message: formData.message,
			})
			.then((response) => {
				if (response.data.ok) {
					setEmailData(response.data.data);
				} else {
					setEmailData({});
				}
				setLoading(false);
				setPreviewOpen(true);
			})
			.catch(function (error) {
				console.log(error);
				setEmailData({});
				setLoading(false);
				setPreviewOpen(false);
			});
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleContactsChange = (event, newValue) => {
		setSelectedContacts(newValue);
		updateAvailableContacts(newValue, selectedCCContacts);
	};

	const handleCCContactsChange = (event, newValue) => {
		setSelectedCCContacts(newValue);
		updateAvailableContacts(selectedContacts, newValue);
	};

	const updateAvailableContacts = (selectedTo, selectedCC) => {
		const updatedAvailableContacts = contacts.filter(
			(contact) =>
				!selectedTo.find((selected) => selected.email === contact.email) &&
				!selectedCC.find((selected) => selected.email === contact.email)
		);
		setAvailableContacts(updatedAvailableContacts);
	};

	const handleValidation = () => {
		let errorData = {};
		let isValid = true;

		if (isEmpty(selectedContacts)) {
			errorData['selectedContacts'] = 'Please select at least one Contact';
			isValid = false;
		} else {
			let groupedContacts = groupBy(
				selectedContacts,
				(contact) => contact.principalName
			);

			if (selectedCCContacts.length > 0) {
				let groupedCCContacts = groupBy(
					selectedCCContacts,
					(contact) => contact.principalName
				);

				let errorStringList = [];
				groupedCCContacts.forEach((ccGroup) => {
					if (
						!groupedContacts.some(
							(group) => ccGroup.principalName === group.principalName
						)
					) {
						if (errorStringList.length < 1) {
							errorStringList.push(
								`Please select at least one Contact for ${ccGroup.principalName}`
							);
						} else {
							errorStringList.push(`${ccGroup.principalName}`);
						}
					}
				});

				if (errorStringList.length > 0) {
					errorData['selectedContacts'] = errorStringList.join(', ');
					isValid = false;
				}
			}
		}

		let result = Email.validate(
			{
				subject: formData.subject,
				message: formData.message,
			},
			{ abortEarly: false, convert: false }
		);

		const { error } = result;
		if (!error && isValid) {
			setErrorMessages({});
			return true;
		} else {
			if (error?.details !== undefined) {
				for (let item of error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);
			let valid = handleValidation();

			if (valid) {
				const uniquePrincipalNames = [
					...new Set([
						...selectedContacts.map((contact) => contact.principalName),
						...selectedCCContacts.map((contact) => contact.principalName),
					]),
				];

				const groupedContacts = selectedContacts.reduce((acc, contact) => {
					const { principalName } = contact;
					if (!acc[principalName]) {
						acc[principalName] = [];
					}
					acc[principalName].push(contact);
					return acc;
				}, {});

				const groupedCCContacts = selectedCCContacts.reduce((acc, contact) => {
					const { principalName } = contact;
					if (!acc[principalName]) {
						acc[principalName] = [];
					}
					acc[principalName].push(contact);
					return acc;
				}, {});

				const combinedContacts = uniquePrincipalNames.map((principalName) => {
					const allContacts = groupedContacts[principalName] || [];
					const allCCContacts = groupedCCContacts[principalName] || [];
					const principalId =
						allContacts[0]?.principalId ||
						allCCContacts[0]?.principalId ||
						null;

					return {
						principalName: principalName,
						principalId: principalId,
						contacts: allContacts,
						ccContacts: allCCContacts,
					};
				});

				let data = {
					...formData,
					combinedContacts: combinedContacts,
				};
				try {
					api
						.put(`transactions/${transactionId}/createRequests`, data)
						.then((res) => {
							if (res.data.ok) {
								toast.success('Request sent successfully!');
								setLoading(false);
								props.setTriggerUpdate(true);
								handleClose();
							} else {
								toast.error(res.data.error);
								setLoading(false);
								handleClose();
							}
						});
				} catch (error) {
					console.log(error);
					setLoading(false);
					toast.error(error);
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	return (
		<AuthUserContext.Consumer>
			{(credentials) => (
				<>
					<Backdrop text='Loading Data...' open={loading} />
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							style={{ textTransform: 'uppercase' }}
							color='textPrimary'
							variant='h3'
						>
							Submit Requests for Quotations
						</Typography>
					</div>
					<Grid
						style={{ marginTop: 24 }}
						container
						spacing={2}
						direction='column'
					>
						<Grid item>
							<Typography color='textPrimary' variant='body2'>
								From:
							</Typography>
							<TextField
								name='from'
								disabled
								variant='filled'
								fullWidth
								margin='dense'
								InputProps={{
									startAdornment: (
										<Chip
											key={credentials.authUser._delegate.uid}
											tabIndex={-1}
											label={`${credentials.userProfile.profile.firstName} ${credentials.userProfile.profile.lastName} <mail-service@mg.uuoni.com>`}
											className={classes.chip}
										/>
									),
								}}
							/>
						</Grid>
						<Grid item>
							<Typography color='textPrimary' variant='body2'>
								To:
							</Typography>
							<Autocomplete
								id='search-contacts'
								style={{ marginTop: 8, marginBottom: 4 }}
								value={selectedContacts}
								onChange={handleContactsChange}
								fullWidth
								multiple
								disableCloseOnSelect
								filterSelectedOptions
								options={availableContacts.sort(
									(a, b) => -b.principalName.localeCompare(a.principalName)
								)}
								noOptionsText=''
								groupBy={(option) => option.principalName}
								getOptionSelected={(option, value) =>
									option.email === value.email
								}
								getOptionLabel={(option) => {
									let name = combineNames({
										fName: option.firstName,
										mName:
											option.middleName !== undefined
												? option.middleName
												: null,
										lName:
											option.lastName !== undefined ? option.lastName : null,
									});

									return `${name} (${option.email})`;
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant='filled'
										placeholder={
											selectedContacts.length === 0
												? 'Search for a Contact'
												: ''
										}
										InputProps={{
											...params.InputProps,
											endAdornment: null,
										}}
										error={!!errorMessages[`selectedContacts`]}
										helperText={
											errorMessages[`selectedContacts`] &&
											errorMessages[`selectedContacts`]
										}
									/>
								)}
							/>
						</Grid>
						<Grid item>
							<Typography color='textPrimary' variant='body2'>
								CC:
							</Typography>
							<Autocomplete
								id='search-cc-contacts'
								style={{ marginTop: 8, marginBottom: 4 }}
								value={selectedCCContacts}
								onChange={handleCCContactsChange}
								fullWidth
								multiple
								disableCloseOnSelect
								filterSelectedOptions
								options={availableContacts.sort(
									(a, b) => -b.principalName.localeCompare(a.principalName)
								)}
								noOptionsText=''
								groupBy={(option) => option.principalName}
								getOptionSelected={(option, value) =>
									option.email === value.email
								}
								getOptionLabel={(option) => {
									let name = combineNames({
										fName: option.firstName,
										mName:
											option.middleName !== undefined
												? option.middleName
												: null,
										lName:
											option.lastName !== undefined ? option.lastName : null,
									});

									return `${name} (${option.email})`;
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant='filled'
										placeholder={
											selectedCCContacts.length === 0
												? 'Search for a Contact'
												: ''
										}
										InputProps={{
											...params.InputProps,
											endAdornment: null,
										}}
										error={!!errorMessages[`selectedCCContacts`]}
										helperText={
											errorMessages[`selectedCCContacts`] &&
											errorMessages[`selectedCCContacts`]
										}
									/>
								)}
							/>
						</Grid>
						<Grid item>
							<Typography color='textPrimary' variant='body2'>
								Subject
							</Typography>
							<TextField
								name='subject'
								value={formData.subject}
								onChange={(e) => handleInputChange(e)}
								variant='filled'
								fullWidth
								margin='dense'
								error={!!errorMessages[`subject`]}
								helperText={
									errorMessages[`subject`] && errorMessages[`subject`]
								}
							/>
						</Grid>
						<Grid item>
							<Typography color='textPrimary' variant='body2'>
								Message
							</Typography>
							<TextField
								name='message'
								value={formData.message}
								multiline
								onChange={(e) => handleInputChange(e)}
								variant='filled'
								fullWidth
								margin='dense'
								minRows={10}
								error={!!errorMessages[`message`]}
								helperText={
									errorMessages[`message`] && errorMessages[`message`]
								}
							/>
						</Grid>
						<Grid item>
							<Button
								variant='outlined'
								color='primary'
								startIcon={
									<Preview
										style={{ fontSize: 24 }}
										colorcode={theme.palette.tertiary.main}
									/>
								}
								onClick={() => togglePreview()}
							>
								Preview
							</Button>
						</Grid>
					</Grid>
					<div style={{ display: 'flex', marginTop: 40 }}>
						<Button
							style={{ marginRight: 'auto' }}
							onClick={handleClose}
							variant='outlined'
							color='primary'
						>
							Cancel
						</Button>
						<Button
							onClick={(e) => handleSubmit(e)}
							variant='contained'
							color='primary'
							startIcon={
								<Send colorcode={theme.palette.primary.contrastText} />
							}
						>
							Send
						</Button>
					</div>
					<EmailPreviewer
						open={previewOpen}
						handleClose={() => togglePreview()}
						emailData={emailData}
					/>
				</>
			)}
		</AuthUserContext.Consumer>
	);
}
