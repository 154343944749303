import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PrincipalList from '../Data/principalData.json';

const useStyles = makeStyles((theme) => ({
	paper: {
		minWidth: 600,
		padding: 32,
		backgroundColor: theme.palette.background.default,
	},
}));

const addPrincipal = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Add New Principal
			</Typography>
		</div>
		{/* <Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, marginBottom: 24 }}
		>
			Instruction Content
		</Typography> */}
		<Grid
			style={{ marginTop: 24 }}
			container
			direction='row'
			alignItems='center'
			spacing={2}
		>
			<Grid item xs={12} sm={7}>
				<Typography color='textPrimary' variant='body2'>
					Principal
				</Typography>
				<Select
					native
					name='code'
					variant='filled'
					autoWidth={true}
					margin='dense'
					onChange={(e) => props.handleChange(e)}
					value={props.principalForm.code}
					style={{ marginTop: 8, marginBottom: 4 }}
				>
					<option key={0} value='' />
					{PrincipalList.map((principal, index) => {
						return (
							<option key={index} value={principal.code}>
								{principal.name}
							</option>
						);
					})}
				</Select>
			</Grid>
			<Grid item xs={12} sm={5}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Active Date
				</Typography>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						name='employmentDate'
						disableToolbar
						format='dd/MM/yyyy'
						disableFuture
						inputVariant='filled'
						openTo='year'
						views={['year', 'month', 'date']}
						margin='dense'
						value={
							props.principalForm.employmentDate
								? props.principalForm.employmentDate
								: null
						}
						autoOk
						variant='inline'
						invalidDateMessage='Invalid Date Format'
						onChange={(date) => props.handleDateChange('employmentDate', date)}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</MuiPickersUtilsProvider>
			</Grid>
		</Grid>
		<div style={{ display: 'flex', marginTop: 24 }}>
			<Button
				style={{ marginRight: 'auto' }}
				onClick={props.handleClose}
				variant='outlined'
				color='primary'
			>
				Cancel
			</Button>
			<Button onClick={props.handleAdd} variant='contained' color='primary'>
				Add Principal
			</Button>
		</div>
	</>
);

const editPrincipal = (props) => {
	const {
		principalForm,
		handleChange,
		handleSave,
		handleClose,
		errorMessages,
	} = props;
	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Edit Principal
				</Typography>
			</div>
			<div style={{ marginTop: 24 }}>
				<Typography
					style={{ marginBottom: 16 }}
					variant='h4'
					color='textPrimary'
				>
					{principalForm.name}
				</Typography>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: 8,
					}}
				>
					<PeopleOutlineIcon />
					<Typography
						style={{ marginLeft: 16 }}
						variant='body1'
						color='textPrimary'
						component='p'
					>
						{principalForm.contactCount} Contacts
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: 8,
					}}
				>
					<CalendarTodayOutlinedIcon />
					<Typography
						style={{ marginLeft: 16 }}
						variant='body1'
						color='textPrimary'
						component='p'
					>
						{principalForm.status
							? `Active since ${new Date(
									principalForm.employmentDate
							  ).toLocaleDateString()}`
							: `Inactive since ${new Date(
									principalForm.inactiveDate
							  ).toLocaleDateString()}`}
					</Typography>
				</div>
			</div>
			<div style={{ marginTop: 32 }}>
				<Typography variant='h4' color='textPrimary'>
					Client Support Contact
				</Typography>
				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
				<Grid container direction='column' spacing={2}>
					<Grid item xs={6}>
						<Typography color='textPrimary' variant='body2'>
							Email
						</Typography>
						<TextField
							name='email'
							value={principalForm.email}
							onChange={(e) => handleChange(e)}
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`email`]}
							helperText={errorMessages[`email`] && errorMessages[`email`]}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Hotline
						</Typography>
						<TextField
							name='hotline'
							value={principalForm.hotline}
							onChange={(e) => handleChange(e)}
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`hotline`]}
							helperText={errorMessages[`hotline`] && errorMessages[`hotline`]}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography color='textPrimary' variant='body2'>
							Agent Code
						</Typography>
						<TextField
							name='code'
							value={principalForm.agentCodes.code}
							onChange={(e) => handleChange(e, 'agentCodes')}
							variant='filled'
							fullWidth
							margin='dense'
							error={!!errorMessages[`code`]}
							helperText={errorMessages[`code`] && errorMessages[`code`]}
						/>
					</Grid>
				</Grid>
			</div>
			<div style={{ marginTop: 24 }}>
				<Typography variant='h4' color='textPrimary'>
					Personal Sales Target
				</Typography>
				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
				<Grid container direction='column' spacing={2}>
					<Grid item xs={6}>
						<Typography color='textPrimary' variant='body2'>
							Monthly Sales Target
						</Typography>
						<TextField
							name='monthValue'
							value={principalForm.agentSalesTargets.monthValue ?? ''}
							type='number'
							onChange={(e) => handleChange(e, 'agentSalesTargets')}
							variant='filled'
							fullWidth
							margin='dense'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start' style={{ marginTop: 0 }}>
										<AttachMoneyOutlinedIcon />
									</InputAdornment>
								),
							}}
							error={!!errorMessages[`monthValue`]}
							helperText={
								errorMessages[`monthValue`] && errorMessages[`monthValue`]
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Yearly Sales Target
						</Typography>
						<TextField
							name='yearValue'
							value={principalForm.agentSalesTargets.yearValue ?? ''}
							type='number'
							onChange={(e) => handleChange(e, 'agentSalesTargets')}
							variant='filled'
							fullWidth
							margin='dense'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start' style={{ marginTop: 0 }}>
										<AttachMoneyOutlinedIcon />
									</InputAdornment>
								),
							}}
							error={!!errorMessages[`yearValue`]}
							helperText={
								errorMessages[`yearValue`] && errorMessages[`yearValue`]
							}
						/>
					</Grid>
				</Grid>
			</div>
			<div style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginRight: 'auto' }}
					onClick={handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={(e) => handleSave(e, principalForm._id)}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
		</>
	);
};

const deactivatePrincipal = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Deactivate Principal
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, marginBottom: 24 }}
		>
			Are you sure you want to deactivate the following principal?
		</Typography>
		<Typography color='textPrimary' variant='body2'>
			{props.principalForm.name}
		</Typography>
		<Typography color='textPrimary' variant='body1'>
			{props.principalForm.contactCount} contacts
		</Typography>
		<DialogActions style={{ marginTop: 24 }}>
			<Button onClick={props.handleClose} variant='outlined' color='primary'>
				Cancel
			</Button>
			<Button
				onClick={(e) => props.handleDeactivate(e, props.principalForm._id)}
				variant='contained'
				style={{
					backgroundColor: props.theme.palette.error.main,
					color: props.theme.palette.background.default,
				}}
			>
				Yes, deactivate
			</Button>
		</DialogActions>
	</>
);

const reactivatePrincipal = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Activate Principal
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, marginBottom: 24 }}
		>
			Are you sure you want to activate the following principal?
		</Typography>
		<Typography color='textPrimary' variant='body2'>
			{props.principalForm.name}
		</Typography>
		<Typography color='textPrimary' variant='body1'>
			{props.principalForm.contactCount} contacts
		</Typography>
		<DialogActions style={{ marginTop: 24 }}>
			<Button onClick={props.handleClose} variant='outlined' color='primary'>
				Cancel
			</Button>
			<Button
				onClick={(e) => props.handleReactivate(e, props.principalForm._id)}
				variant='contained'
				color='primary'
			>
				Yes, activate
			</Button>
		</DialogActions>
	</>
);

const warnReactivatePrincipal = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Reactivate Principal
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginTop: 24,
				marginBottom: 24,
			}}
		>
			<WarningOutlinedIcon
				style={{
					color: props.theme.palette.warning.main,
					height: 32,
					width: 32,
					marginRight: 16,
				}}
			/>
			<Typography variant='body2' color='textPrimary'>
				Max limit of representing principals
			</Typography>
		</div>
		<Typography variant='body1' color='textPrimary'>
			You have reach the maximum limit of number of active principals you can
			have.
		</Typography>
		<Typography style={{ marginTop: 8 }} variant='body1' color='textPrimary'>
			Please try again after deactivating existing principals.
		</Typography>
		<DialogActions style={{ marginTop: 24 }}>
			<Button onClick={props.handleClose} variant='contained' color='primary'>
				Okay
			</Button>
		</DialogActions>
	</>
);

const warnAddPrincipal = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Add New Principal
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginTop: 24,
				marginBottom: 24,
			}}
		>
			<WarningOutlinedIcon
				style={{
					color: props.theme.palette.warning.main,
					height: 32,
					width: 32,
					marginRight: 16,
				}}
			/>
			<Typography variant='body2' color='textPrimary'>
				Max limit of representing principals
			</Typography>
		</div>
		<Typography variant='body1' color='textPrimary'>
			You have reach the maximum limit of number of active principals you can
			have.
		</Typography>
		<Typography style={{ marginTop: 8 }} variant='body1' color='textPrimary'>
			Please try again after deactivating existing principals.
		</Typography>
		<DialogActions style={{ marginTop: 24 }}>
			<Button onClick={props.handleClose} variant='contained' color='primary'>
				Okay
			</Button>
		</DialogActions>
	</>
);

const confirmDeleteUnderwriters = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Delete Contact(s)
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, marginBottom: 24 }}
		>
			Are you sure you want to delete the selected contact(s)?
		</Typography>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, color: props.theme.palette.error.main }}
		>
			Note: This action cannot be undone.
		</Typography>
		<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
			<Button
				style={{ marginRight: 16 }}
				onClick={props.handleClose}
				variant='outlined'
				color='primary'
			>
				Cancel
			</Button>
			<Button
				onClick={props.handleConfirm}
				variant='contained'
				style={{
					backgroundColor: props.theme.palette.error.main,
					color: props.theme.palette.background.default,
				}}
			>
				Delete
			</Button>
		</div>
	</>
);

const getBody = (props) => {
	switch (props.body) {
		case 'addPrincipal':
			return addPrincipal(props);
		case 'editPrincipal':
			return editPrincipal(props);
		case 'deactivatePrincipal':
			return deactivatePrincipal(props);
		case 'reactivatePrincipal':
			return reactivatePrincipal(props);
		case 'warnReactivatePrincipal':
			return warnReactivatePrincipal(props);
		case 'warnAddPrincipal':
			return warnAddPrincipal(props);
		case 'confirmDeleteUnderwriters':
			return confirmDeleteUnderwriters(props);
		default:
			return 'Unknown modal';
	}
};

const DialogBox = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<div className={classes.paper}>
					{getBody({ ...props, theme: theme })}
				</div>
			</Dialog>
		</div>
	);
};

export default DialogBox;
