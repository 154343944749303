import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { combineNames } from '../../../utils/combineNames';
import { ThumbsDown, ThumbsUp } from '../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		listStyle: 'none',
		margin: 0,
	},
	tableRow: {},
	tableCell: {
		padding: 6,
	},
	tableCellSelected: {
		padding: 6,
		color: theme.palette.tertiary.main,
		fontWeight: 600,
	},
}));

const TagCount = (props) => {
	const { tags } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [likesCount, setLikesCount] = useState(0);
	const [dislikesCount, setDislikesCount] = useState(0);

	useEffect(() => {
		if (tags.likes !== undefined) {
			setLikesCount(tags.likes.length);
		}
		if (tags.dislikes !== undefined) {
			setDislikesCount(tags.dislikes.length);
		}
	}, [tags]);

	return (
		<li key='key' style={{ display: 'flex', alignItems: 'center' }}>
			<ThumbsUp
				style={{ fontSize: 24 }}
				colorcode={theme.palette.success.light}
			/>
			<span style={{ marginLeft: 8, marginRight: 20 }}>{likesCount}</span>
			<ThumbsDown
				style={{ fontSize: 24 }}
				colorcode={theme.palette.error.light}
			/>
			<span style={{ marginLeft: 8, marginRight: 20 }}>{dislikesCount}</span>
			{tags?.likes !== undefined &&
				tags.likes.length > 0 &&
				tags.likes.map((tag, index) => {
					return (
						<div key={index} style={{ display: 'none' }}>
							{tag}
						</div>
					);
				})}
			{tags?.dislikes !== undefined &&
				tags.dislikes.length > 0 &&
				tags.dislikes.map((tag, index) => {
					return (
						<div key={index} style={{ display: 'none' }}>
							{tag}
						</div>
					);
				})}
		</li>
	);
};

const DataRow = (props) => {
	const classes = useStyles();
	const { data, openPane, handleCheckbox, selected, open } = props;
	return (
		<TableRow
			className={classes.tableRow}
			hover
			selected={data.checked || selected}
			key={data._id}
			onClick={(e) => openPane(e, data)}
		>
			<TableCell className='checkBox' style={{ padding: 6 }}>
				<Checkbox
					checked={data.checked}
					onChange={(e) => handleCheckbox(e, data._id)}
					inputProps={{
						'aria-label': 'primary checkbox',
					}}
				/>
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
			>
				{combineNames({
					fName: data.firstName,
					mName: data.middleName !== undefined ? data.middleName : null,
					lName: data.lastName !== undefined ? data.lastName : null,
				})}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
			>
				{data.email}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
			>
				{data.mobileNo && `${data.mobileNo} (M)`} <br />{' '}
				{data.homeNo && `${data.homeNo} (H)`}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
				style={{ display: open && 'none' }}
			>
				{data.associatedCompanies.length > 0 &&
					data.associatedCompanies[0].companyId.companyName}
				{data.associatedCompanies.length > 1 && (
					<>
						<br />
						<a onClick={(e) => openPane(e, data)}>
							+ {data.associatedCompanies.length - 1} more
						</a>
					</>
				)}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
				style={{ display: open && 'none' }}
			>
				<div className={classes.root}>
					<TagCount tags={data.tags} />
				</div>
			</TableCell>
		</TableRow>
	);
};

export default DataRow;
