import React, { useState, useEffect } from 'react';
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../config/api';
import { useMediaQuery } from 'react-responsive';

const COLORS = [
	'#7E909A',
	'#274C77',
	'#6096BA',
	'#71A5DE',
	'#A3CEF1',
	'#CAE3F7',
];

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',

		padding: 16,
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
		borderRadius: 4,
	},
	filterMenu: {
		padding: 16,
	},
}));

export default function Transactions() {
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [frequency, setFrequency] = useState('yearly');
	const [anchorEl, setAnchorEl] = useState(null);

	const getData = () => {
		if (!loading) {
			setLoading(true);
			setData(null);
		}
		api
			.get('widgets/transactions/?freq=' + frequency)
			.then((response) => {
				if (response.data.ok) {
					setData(response.data.data);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, [frequency]);

	return (
		<div className={classes.paper}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography variant='h4' color='textPrimary'>
					Transactions
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					onClick={(e) => setAnchorEl(e.currentTarget)}
				>
					<TuneOutlinedIcon />
				</IconButton>
				<Popover
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					open={Boolean(anchorEl)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<div className={classes.filterMenu}>
						<div>
							<Typography variant='body2' color='textSecondary'>
								Frequency
							</Typography>
							<Select
								native
								name='frequency'
								value={frequency}
								onChange={(e) => setFrequency(e.target.value)}
								variant='filled'
								fullWidth
								margin='dense'
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option key={0} value='yearly'>
									Yearly
								</option>
								<option key={1} value='monthly'>
									Monthly
								</option>
								<option key={2} value='weekly'>
									Weekly
								</option>
								<option key={3} value='daily'>
									Today
								</option>
							</Select>
						</div>
					</div>
				</Popover>
			</div>
			{!data ? (
				<div style={{ display: 'flex', width: '100%', height: '100%' }}>
					{loading ? (
						<CircularProgress style={{ margin: 'auto' }} />
					) : (
						<Typography
							style={{ margin: 'auto' }}
							variant='body1'
							color='textPrimary'
						>
							No Data Available
						</Typography>
					)}
				</div>
			) : (
				<div
					style={{
						width: '90%',
						height: '100%',

						alignSelf: 'center',
					}}
				>
					<ResponsiveContainer>
						<BarChart
							layout='vertical'
							data={data}
							margin={{
								top: 32,
								right: isDesktopOrLaptop ? 0 : 32,
								left: 32,
							}}
						>
							<CartesianGrid strokeDasharray='3 3' horizontal={false} />
							<YAxis dataKey='transactionType' type='category' width={70} />
							<XAxis type='number' />
							<Tooltip />
							<Legend
								formatter={(value, entry, index) => (
									<span style={{ color: '#000000' }}>{value}</span>
								)}
								iconType='circle'
								iconSize={10}
								layout={isDesktopOrLaptop ? 'vertical' : 'horizontal'}
								align={isDesktopOrLaptop ? 'right' : 'center'}
								verticalAlign={isDesktopOrLaptop ? 'center' : 'bottom'}
								wrapperStyle={
									isDesktopOrLaptop && {
										paddingLeft: 32,
										top: '50%',
										transform: 'translate(0, -50%)',
									}
								}
							/>
							<Bar
								dataKey='Completed'
								stackId='a'
								maxBarSize={28}
								fill={COLORS[2]}
							/>
							<Bar
								dataKey='Pending Action'
								stackId='a'
								maxBarSize={28}
								fill={COLORS[3]}
							/>
							<Bar
								dataKey='Pending Response'
								stackId='a'
								maxBarSize={28}
								fill={COLORS[4]}
							/>
						</BarChart>
					</ResponsiveContainer>
				</div>
			)}
		</div>
	);
}
