import Backdrop from '../../../components/Backdrop';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import Check from '@material-ui/icons/Check';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import Joi from 'joi';
import { cloneDeep, isEmpty } from 'lodash';
import matchSorter from 'match-sorter';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import NavPrompt from '../../../components/NavPrompt';
import SuccessMessage from '../../../components/SuccessMessage';
import api from '../../../config/api';
import { combineNames } from '../../../utils/combineNames';
import {
	AssociatedCompany,
	AssociatedContact,
	ClaimInfo,
	Company,
	Contact,
	Email,
	PolicyInfo,
	ProposalForm,
	QuoteInfo,
	SupportingDocument,
} from '../../../utils/validators/schemas';
import TransactionForm from './Form';
import InitTransaction from './InitTransaction';
import RequestDocuments from './RequestDocuments';
import Summary from './Summary';
import { EmailSent, ProgressDetailsDocuments } from '../../../components/Icons';

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const CustomStepConnector = withStyles((theme) => ({
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
			background: theme.palette.success.main,
		},
	},
	completed: {
		'& $line': {
			background: theme.palette.success.main,
		},
	},
	line: {
		height: 10,
		border: 0,
		backgroundColor: theme.palette.grey[400],
		borderRadius: 1,
	},
}))(StepConnector);

const useCustomStepIconStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.grey[400],
		zIndex: 1,
		color: theme.palette.background.default,
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		background: theme.palette.tertiary.main,
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed: {
		background: theme.palette.success.main,
	},
}));

function CustomStepIcon(props) {
	const classes = useCustomStepIconStyles();
	const theme = useTheme();
	const { active, completed } = props;

	const icons = {
		1: <AssignmentTurnedInOutlinedIcon style={{ fontSize: 24 }} />,
		2: (
			<ProgressDetailsDocuments
				style={{ fontSize: 24, marginLeft: 1 }}
				colorcode={
					active ? theme.palette.background.default : theme.palette.grey[600]
				}
			/>
		),
		3: (
			<EmailSent
				style={{ fontSize: 24, marginLeft: -2 }}
				colorcode={
					active ? theme.palette.background.default : theme.palette.grey[600]
				}
			/>
		),
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{/* {icons[String(props.icon)]} */}
			{completed ? (
				<Check className={classes.completed} />
			) : (
				<>{icons[String(props.icon)]}</>
			)}
		</div>
	);
}

CustomStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({}));

function getSteps() {
	return ['INSURANCE SELECTION', 'DETAILS AND DOCUMENTS', 'REVIEW AND SEND'];
}

const initFormData = {
	haveDocuments: true,
	isDirectPurchase: false,
	source: 'Internal',
	transactionType: 'Quotation',
	selectedInsuranceType: {
		insuranceType: '',
		insuranceSubType: '',
		isCommercial: false,
	},
	selectedTransaction: null,
	contactData: {
		existing: false,
		_id: '',
		firstName: '',
		middleName: '',
		lastName: '',
		dob: null,
		idNo: '',
		nationality: '',
		maritalStatus: '',
		gender: '',
		email: '',
		mobileNo: '',
		homeNo: '',
		address: '',
		unitNo: '',
		postalCode: '',
	},
	companyData: {
		existing: false,
		_id: '',
		companyName: '',
		uen: '',
		website: '',
		// jobTitle: '',
		// yearsOperational: '',
		establishDate: null,
		industryName: '',
		companyEmail: '',
		officeNo: '',
		faxNo: '',
		companyAddress: '',
		companyUnitNo: '',
		companyPostalCode: '',
	},
	quotationData: [],
	policyData: {
		policyNo: '',
		principalId: '',
		principalName: '',
		premiumAmt: null,
		sumInsured: null,
		startDate: null,
		endDate: null,
		commission: null,
		remarks: '',
		fileData: null,
	},
	claimData: {
		accepted: true,
		claimAmt: null,
		incidentDate: null,
		insurerNotes: '',
		fileData: null,
	},
	prevPolicyData: {
		_id: '',
		policyNo: '',
		principalId: '',
		principalName: '',
		premiumAmt: null,
		sumInsured: null,
		startDate: null,
		endDate: null,
		commission: null,
		remarks: '',
		fileData: null,
		policyDocuments: [],
	},
	hasProposalForm: false,
	proposalForm: {
		method: 'url',
		url: '',
		instructions: '',
		fileData: null,
	},
	supportingDocuments: [],
	selectedContacts: [],
	selectedCCContacts: [],
	subject: '',
	message: '',
	isSelfGenerated: false,
};

function groupBy(arr, keyGetter) {
	const map = new Map();
	arr.forEach((item) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	const result = Array.from(map).map(([principalName, contacts]) => ({
		principalName,
		contacts,
	}));

	return result;
}

export default function CreateTransaction(props) {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();
	const steps = getSteps();
	const [action, setAction] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [isStepInvalid, setIsStepInvalid] = useState(false);
	const [type, setType] = useState(searchParams.get('type') || null);
	const [clientId, setClientId] = useState(searchParams.get('id') || null);
	const [formData, setFormData] = useState(cloneDeep(initFormData));
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const [successInfo, setSuccessInfo] = useState(null);
	const [errorMessages, setErrorMessages] = useState({});
	const [completedTabs, setCompletedTabs] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	]);
	const [errorTabs, setErrorTabs] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	]);
	const [errors, setErrors] = useState('');
	const [resetDocs, setResetDocs] = useState(true);
	const [loading, setLoading] = useState(false);
	const [modified, setModified] = useState(false);

	useEffect(() => {
		const getClientData = async (type, clientId) => {
			if (!loading) {
				setLoading(true);
			}
			try {
				let updatedData = formData;
				if (type === 'company') {
					const response = await api.get(`companies/${clientId}?lean=true`);
					let mergedCompanyData = {
						...updatedData.companyData,
						...response.data,
						existing: true,
					};
					let mergedData = {
						...updatedData,
						selectedInsuranceType: {
							isCommercial: true,
						},
						companyData: mergedCompanyData,
					};
					setFormData(mergedData);
				}
				if (type === 'contact') {
					const response = await api.get(`contacts/${clientId}?lean=true`);
					let mergedContactData = {
						...updatedData.contactData,
						...response.data,
						existing: true,
					};
					let mergedData = {
						...updatedData,
						contactData: mergedContactData,
					};
					setFormData(mergedData);
				}
				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		};

		if (activeStep === 0 && type && clientId) {
			getClientData(type, clientId);
		}
	}, [activeStep]);

	useEffect(() => {
		const reinitializeForm = () => {
			const initForm = cloneDeep(initFormData);
			setFormData(initForm);
			setModified(false);
		};

		if (activeStep === 0) {
			reinitializeForm();
		}
	}, [activeStep]);

	const getTransactionData = async () => {
		if (!loading) {
			setLoading(true);
		}
		try {
			const response = await api.get(
				'transactions/' + formData.selectedTransaction._id
			);
			let updatedData = formData;
			let mergedContactData = {
				...initFormData.contactData,
				...response.data.data.client.contactId,
				existing: true,
			};
			let mergedCompanyData = {
				...initFormData.companyData,
				...response.data.data.client.companyId,
				existing: true,
			};
			let mergedPolicyData = {
				...initFormData.prevPolicyData,
				...response.data.data.policyData,
				policyDocuments: response.data.data.policyDocuments,
			};
			let mergedData = {
				...updatedData,
				selectedInsuranceType: {
					insuranceType: response.data.data.insuranceType,
					insuranceSubType: response.data.data.insuranceSubType,
					isCommercial: response.data.data.isCommercial,
				},
				contactData: mergedContactData,
				companyData: mergedCompanyData,
				prevPolicyData: mergedPolicyData,
			};
			setFormData(mergedData);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (formData.selectedTransaction) {
			getTransactionData();
		} else {
			const initForm = cloneDeep(initFormData);
			setFormData((prev) => ({
				...prev,
				selectedInsuranceType: initForm.selectedInsuranceType,
				contactData: initForm.contactData,
				companyData: initForm.companyData,
				policyData: initForm.policyData,
				prevPolicyData: initForm.prevPolicyData,
				claimData: initForm.claimData,
			}));
		}
	}, [formData.selectedTransaction]);

	const handleValidation = () => {
		let errorData = {};
		let dataToValidate = {};
		let schemaObject = {};
		let result = {};
		let isValid = true;

		if (activeStep === 0) {
			if (
				(formData.transactionType === 'Claims' ||
					formData.transactionType === 'Renewal') &&
				formData.source === 'Internal'
			) {
				if (isEmpty(formData.selectedTransaction)) {
					errorData['selectedTransaction'] = 'Please select a Transaction';
					isValid = false;
				}
			} else {
				if (isEmpty(formData.selectedInsuranceType.insuranceType)) {
					errorData['selectedInsuranceType'] = 'Please select Insurance Type';
					isValid = false;
				} else {
					if (
						formData.selectedInsuranceType.insuranceType === 'Other Insurance'
					) {
						if (isEmpty(formData.selectedInsuranceType.insuranceSubType)) {
							errorData['insuranceSubType'] = 'Please enter Insurance Sub-type';
							isValid = false;
						}
					}
				}
			}
		}
		if (activeStep === 1) {
			if (!formData.haveDocuments) {
				if (formData.hasProposalForm) {
					if (formData.proposalForm.method === 'url') {
						dataToValidate['proposalForm'] = {
							url: formData.proposalForm.url,
							instructions: formData.proposalForm.instructions,
						};
						schemaObject['proposalForm'] = ProposalForm;
					} else {
						if (!formData.proposalForm.fileData) {
							errorData['proposalForm'] = 'Proposal Form is required!';
							isValid = false;
						}
					}
				}
				if (formData.selectedInsuranceType.isCommercial) {
					if (
						formData.companyData.existing &&
						isEmpty(formData.companyData._id)
					) {
						errorData['companyData'] = 'Please select a Company';
						isValid = false;
					} else {
						dataToValidate['companyData'] = {
							companyName: formData.companyData.companyName,
							uen: formData.companyData.uen,
						};
						schemaObject['companyData'] = AssociatedCompany;
					}
				}

				if (
					formData.contactData.existing &&
					isEmpty(formData.contactData._id)
				) {
					errorData['contactData'] = 'Please select a Contact';
					isValid = false;
				} else {
					dataToValidate['contactData'] = {
						firstName: formData.contactData.firstName,
						lastName: formData.contactData.lastName,
						email: formData.contactData.email,
						mobileNo: formData.contactData.mobileNo,
					};
					schemaObject['contactData'] = AssociatedContact;
				}
				if (formData.supportingDocuments.length > 0) {
					dataToValidate['supportingDocuments'] = formData.supportingDocuments;
					schemaObject['supportingDocuments'] =
						Joi.array().items(SupportingDocument);
				}
			} else {
				// Validate Contact/Company
				if (formData.selectedInsuranceType.isCommercial) {
					if (
						formData.companyData.existing &&
						isEmpty(formData.companyData._id)
					) {
						errorData['companyData'] = 'Please select a Company';
						isValid = false;
					} else {
						dataToValidate['companyData'] = formData.companyData;
						schemaObject['companyData'] = Company;
					}
				}

				if (
					formData.contactData.existing &&
					isEmpty(formData.contactData._id)
				) {
					errorData['contactData'] = 'Please select a Contact';
					isValid = false;
				} else {
					dataToValidate['contactData'] = formData.contactData;
					schemaObject['contactData'] = Contact;
				}

				// Validate Quotations
				if (formData.isSelfGenerated) {
					let validateQuotations = formData.quotationData.length > 0;
					if (!validateQuotations) {
						errorData['quotationData'] = 'Please enter at least one Quotation';
						isValid = false;
					} else {
						dataToValidate['quotationData'] = formData.quotationData;
						schemaObject['quotationData'] = Joi.array().items(QuoteInfo);
						formData.quotationData.forEach((quotation, index) => {
							if (isEmpty(quotation.fileData)) {
								errorData[`quotationData.${index}.quoteDocument`] =
									'Quote Document is required';
								isValid = false;
							}
						});
					}
				}

				// Validate Policy/Claim Data
				if (formData.isDirectPurchase) {
					if (formData.transactionType === 'Claims') {
						dataToValidate['claimData'] = formData.claimData;
						schemaObject['claimData'] = ClaimInfo;
						if (
							formData.claimData.accepted &&
							isEmpty(formData.claimData.fileData)
						) {
							errorData['claimData.claimDocument'] =
								'Claim Document is required';
							isValid = false;
						}
					} else {
						dataToValidate['policyData'] = formData.policyData;
						schemaObject['policyData'] = PolicyInfo;
						if (isEmpty(formData.policyData.fileData)) {
							errorData['policyData.policyDocument'] =
								'Policy Document is required';
							isValid = false;
						}
					}
				}

				// Validate Prev Policy Data
				if (
					formData.transactionType !== 'Quotation' &&
					formData.source === 'External'
				) {
					dataToValidate['prevPolicyData'] = formData.prevPolicyData;
					schemaObject['prevPolicyData'] = PolicyInfo;
					if (isEmpty(formData.prevPolicyData.fileData)) {
						errorData['prevPolicyData.prevPolicyDocument'] =
							'Policy Document is required';
						isValid = false;
					}
				}

				// Validate Supporting Documents
				if (formData.supportingDocuments.length > 0) {
					formData.supportingDocuments.forEach((doc) => {
						if (!doc.fileData) {
							errorData['supportingDocuments'] = {
								...errorData.supportingDocuments,
								[doc.id]: 'File Required!',
							};
							isValid = false;
						}
					});
				}
			}

			let schema = Joi.object(schemaObject);
			result = schema.validate(dataToValidate, {
				abortEarly: false,
				convert: false,
			});
		}
		if (activeStep === 2) {
			if (!formData.isDirectPurchase) {
				if (formData.haveDocuments && !formData.isSelfGenerated) {
					if (isEmpty(formData.selectedContacts)) {
						errorData['selectedContacts'] =
							'Please select at least one Contact';
						isValid = false;
					} else {
						let groupedContacts = groupBy(
							formData.selectedContacts,
							(contact) => contact.principalName
						);

						if (formData.selectedCCContacts.length > 0) {
							let groupedCCContacts = groupBy(
								formData.selectedCCContacts,
								(contact) => contact.principalName
							);

							let errorStringList = [];
							groupedCCContacts.forEach((ccGroup) => {
								if (
									!groupedContacts.some(
										(group) => ccGroup.principalName === group.principalName
									)
								) {
									if (errorStringList.length < 1) {
										errorStringList.push(
											`Please select at least one Contact for ${ccGroup.principalName}`
										);
									} else {
										errorStringList.push(`${ccGroup.principalName}`);
									}
								}
							});

							if (errorStringList.length > 0) {
								errorData['selectedContacts'] = errorStringList.join(', ');
								isValid = false;
							}
						}
					}
				}
				dataToValidate = {
					subject: formData.subject,
					message: formData.message,
				};
				result = Email.validate(dataToValidate, {
					abortEarly: false,
					convert: false,
				});
			}
		}

		console.log(result);

		if (result.error === undefined && isValid) {
			setErrorTabs([false, false, false, false, false, false, false]);
			setErrorMessages({});
			return true;
		} else {
			if (result.error) {
				for (let item of result.error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
			}
			const updatedErrorTabs = [
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			];
			Object.keys(errorData).forEach((key) => {
				if (key.includes('companyData')) {
					updatedErrorTabs[0] = true;
				}
				if (key.includes('contactData')) {
					updatedErrorTabs[1] = true;
				}
				if (key === 'supportingDocuments') {
					updatedErrorTabs[2] = true;
				}
				if (key.includes('quotationData')) {
					updatedErrorTabs[3] = true;
				}
				if (key.includes('claimData')) {
					updatedErrorTabs[4] = true;
				}
				if (key.includes('policyData')) {
					updatedErrorTabs[5] = true;
				}
				if (key.includes('prevPolicyData')) {
					updatedErrorTabs[6] = true;
				}
			});
			console.log(errorData);
			setErrorTabs(updatedErrorTabs);
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleFormDataChange = (field, value) => {
		if (field === 'transactionType') {
			setFormData((prev) => ({
				...prev,
				isDirectPurchase: false,
				isSelfGenerated: false,
				[field]: value,
			}));
			if (activeStep !== 0) setModified(true);
		} else if (field === 'source') {
			setFormData((prev) => ({
				...prev,
				selectedTransaction: null,
				isDirectPurchase: false,
				isSelfGenerated: false,
				[field]: value,
			}));
			if (activeStep !== 0) setModified(true);
		} else {
			setFormData((prev) => ({
				...prev,
				[field]: value,
			}));
			if (activeStep !== 0) setModified(true);
		}
	};

	const handleNext = (e) => {
		let valid = handleValidation();

		if (valid) {
			if (activeStep === steps.length - 1) {
				e.preventDefault();
				setOpen(true);
				const data = new FormData();

				if (formData.isDirectPurchase && formData.haveDocuments) {
					// Data Entry Flow
					let json = cloneDeep(formData);

					if (formData.supportingDocuments.length > 0) {
						formData.supportingDocuments.forEach((doc) => {
							data.append(doc.name, doc.fileData, doc.fileData.name);
						});
					}

					if (formData.transactionType === 'Claims') {
						if (
							formData.claimData.accepted &&
							!isEmpty(formData.claimData.fileData)
						) {
							data.append(
								'Claim Document',
								formData.claimData.fileData[0],
								formData.claimData.fileData[0].name
							);
						}
						delete json.claimData.fileData;
					} else {
						if (!isEmpty(formData.policyData.fileData)) {
							data.append(
								'Policy Document',
								formData.policyData.fileData[0],
								formData.policyData.fileData[0].name
							);
						}
						delete json.policyData.fileData;
					}

					if (
						formData.transactionType !== 'Quotation' &&
						formData.source === 'External'
					) {
						if (!isEmpty(formData.prevPolicyData.fileData)) {
							data.append(
								'External Policy Document',
								formData.prevPolicyData.fileData[0],
								formData.prevPolicyData.fileData[0].name
							);
						}
						delete json.prevPolicyData.fileData;
					}

					delete json.proposalForm.fileData;
					if (json.supportingDocuments.length > 0) {
						json.supportingDocuments.forEach((doc) => delete doc.fileData);
					}

					console.log(JSON.stringify(json, null, 4));
					data.append('json', JSON.stringify(json));

					try {
						api.post('transactions/direct', data).then((res) => {
							setOpen(false);
							if (res.data.ok) {
								setSuccess(true);
								setModified(false);
							} else {
								setSuccess(false);
								setErrors(res.data.error);
							}
							setActiveStep((prevActiveStep) => prevActiveStep + 1);
						});
					} catch (error) {
						console.log(error);
						setSuccess(false);
						setErrors(error);
						setActiveStep((prevActiveStep) => prevActiveStep + 1);
					}
				} else if (!formData.haveDocuments) {
					// Client Request Flow
					if (formData.hasProposalForm) {
						if (formData.proposalForm.method === 'upload') {
							if (formData.proposalForm.fileData.length) {
								data.append(
									formData.transactionType === 'Claims'
										? 'Claim Form'
										: 'Proposal Form',
									formData.proposalForm.fileData[0],
									formData.proposalForm.fileData[0].name
								);
							} else {
								data.append(
									formData.transactionType === 'Claims'
										? 'Claim Form'
										: 'Proposal Form',
									formData.proposalForm.fileData,
									formData.proposalForm.fileData.name
								);
							}
						}
					}

					let json = cloneDeep(formData);
					delete json.proposalForm.fileData;

					console.log(JSON.stringify(json, null, 4));
					data.append('json', JSON.stringify(json));
					try {
						api.post('transactions/client', data).then((res) => {
							setOpen(false);
							if (res.data.ok) {
								setSuccess(true);
								setModified(false);
							} else {
								setSuccess(false);
								setErrors(res.data.error);
							}
							setActiveStep((prevActiveStep) => prevActiveStep + 1);
						});
					} catch (error) {
						console.log(error);
						setSuccess(false);
						setErrors(error);
						setActiveStep((prevActiveStep) => prevActiveStep + 1);
					}
				} else {
					// Request Quotation / Quote Selection Process
					if (formData.supportingDocuments.length > 0) {
						formData.supportingDocuments.forEach((doc) => {
							data.append(doc.name, doc.fileData, doc.fileData.name);
						});
					}

					let json = cloneDeep(formData);

					if (!json.isSelfGenerated) {
						// Get unique principalName values from both lists
						const uniquePrincipalNames = [
							...new Set([
								...json.selectedContacts.map(
									(contact) => contact.principalName
								),
								...json.selectedCCContacts.map(
									(contact) => contact.principalName
								),
							]),
						];

						// Group selectedContacts by principalName
						const groupedContacts = json.selectedContacts.reduce(
							(acc, contact) => {
								const { principalName } = contact;
								if (!acc[principalName]) {
									acc[principalName] = [];
								}
								acc[principalName].push(contact);
								return acc;
							},
							{}
						);

						// Group selectedCCContacts by principalName
						const groupedCCContacts = json.selectedCCContacts.reduce(
							(acc, contact) => {
								const { principalName } = contact;
								if (!acc[principalName]) {
									acc[principalName] = [];
								}
								acc[principalName].push(contact);
								return acc;
							},
							{}
						);

						// Merge the two groups into a single data structure
						const combinedContacts = uniquePrincipalNames.map(
							(principalName) => {
								const allContacts = groupedContacts[principalName] || [];
								const allCCContacts = groupedCCContacts[principalName] || [];
								const principalId =
									allContacts[0]?.principalId ||
									allCCContacts[0]?.principalId ||
									null;

								return {
									principalName: principalName,
									principalId: principalId,
									contacts: allContacts,
									ccContacts: allCCContacts,
								};
							}
						);

						delete json.selectedContacts;
						delete json.selectedCCContacts;
						json.combinedContacts = combinedContacts;
					} else {
						// handle self-gen checks
						if (json.quotationData.length > 0) {
							json.quotationData.forEach((quotation) => {
								if (!isEmpty(quotation.fileData)) {
									data.append(
										`Quote_Document-${quotation.principalName}-${quotation.quoteName}`,
										quotation.fileData[0],
										quotation.fileData[0].name
									);
								}
								delete quotation.open;
								delete quotation.fileData;
							});
						}
					}

					if (
						formData.transactionType !== 'Quotation' &&
						formData.source === 'External'
					) {
						data.append(
							'External Policy Document',
							formData.prevPolicyData.fileData[0],
							formData.prevPolicyData.fileData[0].name
						);

						delete json.prevPolicyData.fileData;
					}

					delete json.proposalForm.fileData;

					if (json.supportingDocuments.length > 0) {
						json.supportingDocuments.forEach((doc) => delete doc.fileData);
					}

					console.log(JSON.stringify(json, null, 4));
					data.append('json', JSON.stringify(json));
					try {
						if (json.isSelfGenerated) {
							api.post('transactions/self-generate', data).then((res) => {
								setOpen(false);
								if (res.data.ok) {
									setSuccess(true);
									setModified(false);
									if (process.env.REACT_APP_ENVIRONMENT !== 'demo') {
										setSuccessInfo(res.data.data);
									}
								} else {
									setSuccess(false);
									setErrors(res.data.error);
								}
								setActiveStep((prevActiveStep) => prevActiveStep + 1);
							});
						} else {
							api.post('transactions/insurer', data).then((res) => {
								setOpen(false);
								if (res.data.ok) {
									setSuccess(true);
									setModified(false);
									if (process.env.REACT_APP_ENVIRONMENT !== 'demo') {
										setSuccessInfo(res.data.data);
									}
								} else {
									setSuccess(false);
									setErrors(res.data.error);
								}
								setActiveStep((prevActiveStep) => prevActiveStep + 1);
							});
						}
					} catch (error) {
						console.log(error);
						setSuccess(false);
						setErrors(error);
						setActiveStep((prevActiveStep) => prevActiveStep + 1);
					}
				}
			} else {
				if (activeStep === 0) {
					setResetDocs(true);
				}
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else {
			toast.error('An error has occured!');
		}
	};

	const handleBack = () => {
		if (activeStep === 2) {
			setResetDocs(false);
		}
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleCancel = () => {
		history.push(`/transactions/`);
	};

	const getHeader = () => {
		if (activeStep === 0) {
			if (!loading && type === 'contact') {
				let label = combineNames({
					fName: formData.contactData.firstName,
					mName:
						formData.contactData.middleName !== undefined
							? formData.contactData.middleName
							: null,
					lName:
						formData.contactData.lastName !== undefined
							? formData.contactData.lastName
							: null,
				});
				return <>{`New Transaction - ${label}`}</>;
			}
			if (!loading && type === 'company') {
				return <>{`New Transaction - ${formData.companyData.companyName}`}</>;
			}
			return <>{`New Transaction`}</>;
		}
		if (
			(formData.transactionType === 'Claims' ||
				formData.transactionType === 'Renewal') &&
			formData.source === 'Internal'
		) {
			return (
				<>
					{formData.selectedInsuranceType.insuranceSubType
						? `${formData.transactionType} for TXN-${formData.selectedTransaction.transactionNo} - ${formData.selectedInsuranceType.insuranceType} (${formData.selectedInsuranceType.insuranceSubType})`
						: `${formData.transactionType} for TXN-${formData.selectedTransaction.transactionNo} - ${formData.selectedInsuranceType.insuranceType}`}
				</>
			);
		} else
			return (
				<>
					{formData.selectedInsuranceType.insuranceSubType
						? `${formData.transactionType} - ${formData.selectedInsuranceType.insuranceType} (${formData.selectedInsuranceType.insuranceSubType})`
						: `${formData.transactionType} - ${formData.selectedInsuranceType.insuranceType}`}
				</>
			);
	};

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<>
						<Breadcrumbs separator='›' aria-label='breadcrumb'>
							<Link component={RouterLink} color='inherit' to={'/transactions'}>
								Transactions
							</Link>
							<Typography color='primary'>New Transaction</Typography>
						</Breadcrumbs>
						<Typography variant='h2' style={{ margin: '24px 0px' }}>
							{getHeader()}
						</Typography>
						<Typography color='textPrimary' variant='body1'>
							Select transaction type to proceed with the selected insurance
							policy for your client.
						</Typography>
						<Backdrop text='Loading Transaction Data...' open={loading} />
						<InitTransaction
							type={type}
							clientId={clientId}
							formData={formData}
							handleFormDataChange={handleFormDataChange}
							errorMessages={errorMessages}
						/>
					</>
				);
			case 1:
				return (
					<>
						{formData.haveDocuments ? (
							<>
								<Breadcrumbs separator='›' aria-label='breadcrumb'>
									<Link
										component={RouterLink}
										color='inherit'
										to={'/transactions'}
									>
										Transactions
									</Link>
									<Typography color='primary'>New Transaction</Typography>
								</Breadcrumbs>
								<Typography variant='h2' style={{ margin: '24px 0px 0px 0px' }}>
									{getHeader()}
								</Typography>
								<Stepper
									style={{ background: 'unset', padding: '32px 0px' }}
									activeStep={activeStep}
									alternativeLabel
									connector={<CustomStepConnector />}
								>
									{steps.map((label) => (
										<Step key={label}>
											<StepLabel StepIconComponent={CustomStepIcon}>
												{label === 'REVIEW AND SEND' &&
												formData.isDirectPurchase &&
												formData.haveDocuments
													? 'REVIEW AND SAVE'
													: label}
											</StepLabel>
										</Step>
									))}
								</Stepper>
								<Typography variant='h3'>DETAILS AND DOCUMENTS</Typography>
								<div style={{ display: 'flex', margin: '24px 0px' }}>
									<Typography color='textPrimary' variant='body1'>
										Please fill up all the mandatory fields (
									</Typography>
									<Typography color='error' variant='body1'>
										*
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										)
									</Typography>
								</div>
								<TransactionForm
									selectedClient={type && clientId ? true : false}
									formData={formData}
									handleFormDataChange={handleFormDataChange}
									errorMessages={errorMessages}
									setErrorMessages={setErrorMessages}
									errorTabs={errorTabs}
									completedTabs={completedTabs}
									resetDocs={resetDocs}
								/>
							</>
						) : (
							<>
								<Breadcrumbs separator='›' aria-label='breadcrumb'>
									<Link
										component={RouterLink}
										color='inherit'
										to={'/transactions'}
									>
										Transactions
									</Link>
									<Typography color='inherit'>New Transaction</Typography>
									<Typography color='primary'>Request Documents</Typography>
								</Breadcrumbs>
								<Typography variant='h2' style={{ margin: '24px 0px' }}>
									Request Documents
								</Typography>
								<Typography color='textPrimary' variant='body1'>
									Begin this transaction by preparing request of information
									from your Client.
								</Typography>
								<RequestDocuments
									formData={formData}
									handleFormDataChange={handleFormDataChange}
									errorMessages={errorMessages}
								/>
							</>
						)}
					</>
				);
			case 2:
				return (
					<>
						{formData.haveDocuments ? (
							<>
								<Breadcrumbs separator='›' aria-label='breadcrumb'>
									<Link
										component={RouterLink}
										color='inherit'
										to={'/transactions'}
									>
										Transactions
									</Link>
									<Typography color='primary'>New Transaction</Typography>
								</Breadcrumbs>
								<Typography variant='h2' style={{ margin: '24px 0px 0px 0px' }}>
									{getHeader()}
								</Typography>
								<Stepper
									style={{ background: 'unset', padding: '32px 0px' }}
									activeStep={activeStep}
									alternativeLabel
									connector={<CustomStepConnector />}
								>
									{steps.map((label) => (
										<Step key={label}>
											<StepLabel StepIconComponent={CustomStepIcon}>
												{label === 'REVIEW AND SEND' &&
												formData.isDirectPurchase &&
												formData.haveDocuments
													? 'REVIEW AND SAVE'
													: label}
											</StepLabel>
										</Step>
									))}
								</Stepper>
								{formData.isDirectPurchase && formData.haveDocuments ? (
									<>
										<Typography variant='h3' style={{ marginBottom: 24 }}>
											REVIEW AND SAVE
										</Typography>
										<Typography
											style={{ marginBottom: 24 }}
											color='textPrimary'
											variant='body1'
										>
											Please review the transaction details and proceed to save
											it as a record.
										</Typography>
									</>
								) : (
									<>
										<Typography variant='h3' style={{ marginBottom: 24 }}>
											REVIEW AND SEND
										</Typography>
										<Typography
											style={{ marginBottom: 24 }}
											color='textPrimary'
											variant='body1'
										>
											Please review the transaction details before sending.
										</Typography>
									</>
								)}
								<Summary
									formData={formData}
									handleFormDataChange={handleFormDataChange}
									errorMessages={errorMessages}
								/>
							</>
						) : (
							<>
								<Breadcrumbs separator='›' aria-label='breadcrumb'>
									<Link
										component={RouterLink}
										color='inherit'
										to={'/transactions'}
									>
										Transactions
									</Link>
									<Typography color='inherit'>New Transaction</Typography>
									<Typography color='primary'>Request Documents</Typography>
								</Breadcrumbs>
								<Typography variant='h2' style={{ margin: '24px 0px' }}>
									Request Documents
								</Typography>
								<Typography
									style={{ marginBottom: 24 }}
									color='textPrimary'
									variant='body1'
								>
									This request will be forwarded to your client via email with a
									unique link that can be used to upload the requested documents
									and send it back to you.
								</Typography>
								<Summary
									formData={formData}
									handleFormDataChange={handleFormDataChange}
									errorMessages={errorMessages}
								/>
							</>
						)}
					</>
				);
			case 3:
				return (
					<>
						{formData.haveDocuments ? (
							<>
								{success ? (
									<>
										{formData.isDirectPurchase ? (
											<SuccessMessage
												type={'direct-purchase'}
												successInfo={successInfo}
											/>
										) : (
											<>
												{formData.transactionType === 'Claims' && (
													<SuccessMessage
														type={'claim-request-sent'}
														successInfo={successInfo}
													/>
												)}
												{formData.transactionType === 'Quotation' && (
													<SuccessMessage
														type={'quote-request-sent'}
														successInfo={successInfo}
													/>
												)}
												{formData.transactionType === 'Renewal' && (
													<SuccessMessage
														type={'renewal-request-sent'}
														successInfo={successInfo}
													/>
												)}
											</>
										)}
									</>
								) : (
									<SuccessMessage
										type={'request-error'}
										errorMessages={errors}
										handleBack={handleBack}
									/>
								)}
							</>
						) : (
							<>
								{success ? (
									<SuccessMessage type={'file-request-sent'} />
								) : (
									<SuccessMessage
										type={'request-error'}
										errorMessages={errors}
										handleBack={handleBack}
									/>
								)}
							</>
						)}
					</>
				);
			default:
				return 'Unknown step';
		}
	};

	const getStepContentMobile = (step) => {
		switch (step) {
			case 0:
				return (
					<>
						<Typography variant='h2'>{getHeader()}</Typography>
						<Typography color='textPrimary' variant='body1'>
							Select transaction type to proceed with the selected insurance
							policy for your client.
						</Typography>
						<Backdrop text='Loading Transaction Data...' open={loading} />
						<InitTransaction
							type={type}
							clientId={clientId}
							formData={formData}
							handleFormDataChange={handleFormDataChange}
							errorMessages={errorMessages}
						/>
					</>
				);
			case 1:
				return (
					<>
						{formData.haveDocuments ? (
							<>
								<Typography variant='h2'>{getHeader()}</Typography>
								<Stepper
									style={{ background: 'unset', padding: '32px 0px' }}
									activeStep={activeStep}
									alternativeLabel
									connector={<CustomStepConnector />}
								>
									{steps.map((label) => (
										<Step key={label}>
											<StepLabel StepIconComponent={CustomStepIcon}>
												{label === 'REVIEW AND SEND' &&
												formData.isDirectPurchase &&
												formData.haveDocuments
													? 'REVIEW AND SAVE'
													: label}
											</StepLabel>
										</Step>
									))}
								</Stepper>
								<Typography variant='h3'>DETAILS AND DOCUMENTS</Typography>
								<div style={{ display: 'flex', margin: '24px 0px' }}>
									<Typography color='textPrimary' variant='body1'>
										Please fill up all the mandatory fields (
									</Typography>
									<Typography color='error' variant='body1'>
										*
									</Typography>
									<Typography color='textPrimary' variant='body1'>
										)
									</Typography>
								</div>
								<TransactionForm
									selectedClient={type && clientId ? true : false}
									formData={formData}
									handleFormDataChange={handleFormDataChange}
									errorMessages={errorMessages}
									setErrorMessages={setErrorMessages}
									errorTabs={errorTabs}
									completedTabs={completedTabs}
									resetDocs={resetDocs}
								/>
							</>
						) : (
							<>
								<Typography variant='h2'>Request Documents</Typography>
								<Typography color='textPrimary' variant='body1'>
									Begin this transaction by preparing request of information
									from your Client.
								</Typography>
								<RequestDocuments
									formData={formData}
									handleFormDataChange={handleFormDataChange}
									errorMessages={errorMessages}
								/>
							</>
						)}
					</>
				);
			case 2:
				return (
					<>
						{formData.haveDocuments ? (
							<>
								<Typography variant='h2'>{getHeader()}</Typography>
								<Stepper
									style={{ background: 'unset', padding: '32px 0px' }}
									activeStep={activeStep}
									alternativeLabel
									connector={<CustomStepConnector />}
								>
									{steps.map((label) => (
										<Step key={label}>
											<StepLabel StepIconComponent={CustomStepIcon}>
												{label === 'REVIEW AND SEND' &&
												formData.isDirectPurchase &&
												formData.haveDocuments
													? 'REVIEW AND SAVE'
													: label}
											</StepLabel>
										</Step>
									))}
								</Stepper>
								{formData.isDirectPurchase && formData.haveDocuments ? (
									<>
										<Typography variant='h3' style={{ marginBottom: 24 }}>
											REVIEW AND SAVE
										</Typography>
										<Typography
											style={{ marginBottom: 24 }}
											color='textPrimary'
											variant='body1'
										>
											Please review the transaction details and proceed to save
											it as a record.
										</Typography>
									</>
								) : (
									<>
										<Typography variant='h3' style={{ marginBottom: 24 }}>
											REVIEW AND SEND
										</Typography>
										<Typography
											style={{ marginBottom: 24 }}
											color='textPrimary'
											variant='body1'
										>
											Please review the transaction details before sending.
										</Typography>
									</>
								)}
								<Summary
									formData={formData}
									handleFormDataChange={handleFormDataChange}
									errorMessages={errorMessages}
								/>
							</>
						) : (
							<>
								<Typography variant='h2'>Request Documents</Typography>
								<Typography
									style={{ marginBottom: 24 }}
									color='textPrimary'
									variant='body1'
								>
									This request will be forwarded to your client via email with a
									unique link that can be used to upload the requested documents
									and send it back to you.
								</Typography>
								<Summary
									formData={formData}
									handleFormDataChange={handleFormDataChange}
									errorMessages={errorMessages}
								/>
							</>
						)}
					</>
				);
			case 3:
				return (
					<>
						{formData.haveDocuments ? (
							<>
								{success ? (
									<>
										{formData.isDirectPurchase ? (
											<SuccessMessage
												type={'direct-purchase'}
												successInfo={successInfo}
											/>
										) : (
											<>
												{formData.transactionType === 'Claims' && (
													<SuccessMessage
														type={'claim-request-sent'}
														successInfo={successInfo}
													/>
												)}
												{formData.transactionType === 'Quotation' && (
													<SuccessMessage
														type={'quote-request-sent'}
														successInfo={successInfo}
													/>
												)}
												{formData.transactionType === 'Renewal' && (
													<SuccessMessage
														type={'renewal-request-sent'}
														successInfo={successInfo}
													/>
												)}
											</>
										)}
									</>
								) : (
									<SuccessMessage
										type={'request-error'}
										errorMessages={errors}
										handleBack={handleBack}
									/>
								)}
							</>
						) : (
							<>
								{success ? (
									<SuccessMessage type={'file-request-sent'} />
								) : (
									<SuccessMessage
										type={'request-error'}
										errorMessages={errors}
										handleBack={handleBack}
									/>
								)}
							</>
						)}
					</>
				);
			default:
				return 'Unknown step';
		}
	};

	return (
		<>
			<NavPrompt when={modified} />
			<Backdrop
				text='Please wait a moment, we’re sending out your request...'
				open={open}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: 'max-content',
					minHeight: '100%',
				}}
			>
				{isDesktopOrLaptop ? (
					<>
						{getStepContent(activeStep)}
						<div
							className='form-footer'
							style={{
								display: 'flex',
								alignItems: 'center',
								marginTop: 'auto',
							}}
						>
							{activeStep !== steps.length && (
								<>
									<Button
										style={{ display: activeStep === 0 && 'none' }}
										variant='outlined'
										color='primary'
										onClick={
											activeStep === 0 ? () => setAction(null) : handleBack
										}
										className={classes.backButton}
										startIcon={<NavigateBeforeIcon />}
									>
										Back
									</Button>
									<Button
										style={{
											display: activeStep === 0 && 'none',
											marginLeft: 'auto',
											marginRight: 16,
										}}
										variant='outlined'
										color='primary'
										onClick={handleCancel}
										className={classes.backButton}
									>
										Cancel
									</Button>
									<Button
										style={{
											marginLeft: activeStep === 0 && 'auto',
										}}
										variant='contained'
										color='primary'
										disabled={loading}
										onClick={handleNext}
										endIcon={
											activeStep !== steps.length - 1 && <NavigateNextIcon />
										}
									>
										{activeStep === steps.length - 1 ? (
											<>
												{formData.isDirectPurchase && formData.haveDocuments
													? 'Save'
													: 'Send'}
											</>
										) : (
											'Next'
										)}
									</Button>
								</>
							)}
						</div>
					</>
				) : (
					<>
						{getStepContentMobile(activeStep)}
						<div
							className='mobile-form-footer'
							style={{
								display: 'flex',
								alignItems: 'center',
								marginTop: 'auto',
							}}
						>
							{activeStep !== steps.length && (
								<>
									<Button
										style={{
											display: activeStep === 0 && 'none',
											width: '100%',
											marginRight: 16,
										}}
										variant='outlined'
										color='primary'
										onClick={
											activeStep === 0 ? () => setAction(null) : handleBack
										}
										className={classes.backButton}
									>
										Back
									</Button>
									{/* <Button
										style={{
											display: activeStep === 0 && 'none',
											width: '100%',
											marginLeft: 'auto',
											marginRight: 16,
										}}
										variant='outlined'
										color='primary'
										onClick={handleCancel}
										className={classes.backButton}
									>
										Cancel
									</Button> */}
									<Button
										style={{
											width: '100%',
										}}
										variant='contained'
										color='primary'
										disabled={loading}
										onClick={handleNext}
									>
										{activeStep === steps.length - 1 ? (
											<>
												{formData.isDirectPurchase && formData.haveDocuments
													? 'Save'
													: 'Send'}
											</>
										) : (
											'Next'
										)}
									</Button>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}
