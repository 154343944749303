import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import clsx from 'clsx';
import { combineNames } from '../../../utils/combineNames';

const useStyles = makeStyles((theme) => ({
	tableRow: {},
	tableCell: {
		padding: 6,
	},
	tableCellSelected: {
		padding: 6,
		color: theme.palette.tertiary.main,
		fontWeight: 600,
	},
}));

const DataRow = (props) => {
	const classes = useStyles();
	const { data, openPane, handleCheckbox, selected, open } = props;
	return (
		<TableRow
			className={classes.tableRow}
			hover
			selected={data.checked || selected}
			key={data._id}
			onClick={(e) => openPane(e, data)}
		>
			<TableCell className='checkBox' style={{ padding: 6 }}>
				<Checkbox
					checked={data.checked}
					onChange={(e) => handleCheckbox(e, data._id)}
					inputProps={{
						'aria-label': 'primary checkbox',
					}}
				/>
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
			>
				{data.companyName}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
			>
				{data.uen}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
			>
				{data.officeNo && `${data.officeNo} (O)`} <br />{' '}
				{data.faxNo && `${data.faxNo} (F)`}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
				style={{ display: open && 'none' }}
			>
				{data.website}
			</TableCell>
			<TableCell
				className={clsx(classes.tableCell, {
					[classes.tableCellSelected]: data.checked || selected,
				})}
				style={{ display: open && 'none' }}
			>
				{data.associatedContacts.length > 0 &&
					combineNames({
						fName: data.associatedContacts[0].contactId.firstName,
						mName:
							data.associatedContacts[0].contactId.middleName !== undefined
								? data.associatedContacts[0].contactId.middleName
								: null,
						lName:
							data.associatedContacts[0].contactId.lastName !== undefined
								? data.associatedContacts[0].contactId.lastName
								: null,
					})}
				{data.associatedContacts.length > 1 && (
					<>
						<br />
						<a onClick={(e) => openPane(e, data)}>
							+ {data.associatedContacts.length - 1} more
						</a>
					</>
				)}
			</TableCell>
		</TableRow>
	);
};

export default DataRow;
