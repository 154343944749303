import Button from '@material-ui/core/Button';
import Backdrop from '../../../components/Backdrop';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { differenceInCalendarDays } from 'date-fns';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React, { useState, useEffect } from 'react';
import Loader from '../../../components/Loader';
import { AuthUserContext } from '../../../components/Session';
import api from '../../../config/api';

const useStyles = makeStyles((theme) => ({
	chip: {
		marginLeft: 16,
		marginRight: 16,
	},
	warning: {
		backgroundColor: 'rgba(250, 214, 159, 0.4)',
		padding: '12px 24px',
		borderRadius: 4,
		marginBottom: 32,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		width: '98%',
	},
	container: {
		display: 'flex',
		width: '98%',
	},
	gridContainer: {
		marginBottom: 24,
	},
}));

export default function Overview(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [billingInfo, setBillingInfo] = useState({});
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getBillingInfo();
	}, []);

	const getBillingInfo = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('settings/subscription')
			.then((response) => {
				if (response.data.ok) {
					setBillingInfo(response.data.data);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const processPortalLink = (url, location) => {
		switch (location) {
			case 'updatePlan': {
				return `${url}/subscriptions/${billingInfo.subscriptionId}/update`;
			}
			case 'cancelPlan': {
				return `${url}/subscriptions/${billingInfo.subscriptionId}/cancel`;
			}
			case 'reactivatePlan': {
				return `${url}/subscriptions/${billingInfo.subscriptionId}/reactivate`;
			}
			// case 'updateSeats': {
			// 	return `${url}/subscriptions/${billingInfo.subscriptionId}/preview/${priceId}`;
			// }
			case 'manageBillingDetails': {
				return `${url}/customer/update`;
			}
			case 'updatePaymentMethod': {
				return `${url}/subscriptions/${billingInfo.subscriptionId}/update-payment-method/changePaymentMethodFromHome`;
			}
			default:
				return url;
		}
	};

	const fetchPortalSession = (location) => {
		if (!backdropOpen) {
			setBackdropOpen(true);
		}
		// const custId = 'cus_Jivafk1g71KWCB';
		console.log('fetching portal');

		return api
			.post('stripe-portal', {
				customerId: billingInfo.customerId,
			})
			.then((res) => {
				// console.log(res);
				// console.log(res.data.sessionUrl);
				let url = processPortalLink(res.data.sessionUrl, location);
				return (window.location = url);
				// return (window.location = res.data.sessionUrl);
			})
			.catch(function (error) {
				console.log(error);
				setBackdropOpen(false);
			});
	};

	const getStatusChip = (status) => {
		switch (status) {
			case 'active': {
				return (
					<Chip
						className={classes.chip}
						style={{
							color: theme.palette.background.secondary,
							backgroundColor: theme.palette.success.main,
						}}
						label='ACTIVE'
					/>
				);
			}
			case 'trialing': {
				return (
					<Chip
						className={classes.chip}
						style={{
							color: theme.palette.background.secondary,
							backgroundColor: theme.palette.warning.main,
						}}
						label='TRIAL'
					/>
				);
			}
			case 'pending cancellation': {
				return (
					<Chip
						className={classes.chip}
						style={{
							color: theme.palette.background.secondary,
							backgroundColor: theme.palette.error.main,
						}}
						label='CANCELLED'
					/>
				);
			}
			case 'paused': {
				return (
					<Chip
						className={classes.chip}
						style={{
							color: theme.palette.background.secondary,
							backgroundColor: theme.palette.error.main,
						}}
						label='PAUSED'
					/>
				);
			}
		}
	};

	const formatDate = (date) => {
		if (!date) {
			return date;
		}

		let diff = differenceInCalendarDays(new Date(date), new Date());
		if (diff <= 7 && diff > 1) {
			return <>{`${moment(date).format('MMM DD, YYYY')} (in ${diff} days)`}</>;
		} else if (diff <= 1 && diff > 0) {
			return <>{`${moment(date).format('MMM DD, YYYY')} (in ${diff} day)`}</>;
		} else {
			return moment(date).format('MMM DD, YYYY');
		}
	};

	if (loading)
		return (
			<div style={{ height: '100%' }}>
				<Loader size='lg' />
			</div>
		);
	return (
		<AuthUserContext.Consumer>
			{(credentials) => (
				<>
					<Backdrop text='Loading Data...' open={backdropOpen} />
					<div style={{ margin: 24 }}>
						{billingInfo.subscriptionStatus === 'pending cancellation' && (
							<div className={classes.warning}>
								<div
									style={{
										display: 'flex',
									}}
								>
									<ErrorOutlineIcon
										style={{
											color: theme.palette.warning.dark,
											width: 28,
											height: 28,
										}}
									/>
									<div style={{ marginLeft: 16 }}>
										<Typography
											style={{
												color: theme.palette.warning.dark,
												marginBottom: 8,
											}}
											variant='body2'
										>
											IMPORTANT
										</Typography>
										<Typography
											style={{
												color: theme.palette.warning.dark,
												marginBottom: 24,
											}}
											variant='body1'
										>
											{`You have cancelled your subscription, your account will remain active until your subscription period ends on `}
											<b>
												{moment(billingInfo.subscriptionExpiryDate).format(
													'MMM DD, YYYY'
												)}
											</b>
											{`. After that, you will no longer be able to use any of the platform's features.`}
										</Typography>
										<Typography
											style={{ color: theme.palette.warning.dark }}
											variant='body1'
										>
											{`To continue using Uuoni, please resume your subscription by clicking on 'Manage Subscription'.`}
										</Typography>
									</div>
								</div>
							</div>
						)}
						{billingInfo.subscriptionStatus === 'paused' && (
							<div className={classes.warning}>
								<div
									style={{
										display: 'flex',
									}}
								>
									<ErrorOutlineIcon
										style={{
											color: theme.palette.warning.dark,
											width: 28,
											height: 28,
										}}
									/>
									<div style={{ marginLeft: 16 }}>
										<Typography
											style={{
												color: theme.palette.warning.dark,
												marginBottom: 8,
											}}
											variant='body2'
										>
											IMPORTANT
										</Typography>
										<Typography
											style={{
												color: theme.palette.warning.dark,
												marginBottom: 24,
											}}
											variant='body1'
										>
											{`Your trial subscription has ended on `}
											<b>
												{moment(billingInfo.subscriptionExpiryDate).format(
													'MMM DD, YYYY'
												)}
											</b>
											{`.`}
										</Typography>
										<Typography
											style={{ color: theme.palette.warning.dark }}
											variant='body1'
										>
											{`To continue using Uuoni, please resume your subscription by clicking on 'Manage Subscription' and providing a payment method.`}
										</Typography>
									</div>
								</div>
							</div>
						)}
						<div className={classes.header}>
							<Typography variant='h3' style={{ textTransform: 'uppercase' }}>
								{billingInfo.plan} Plan
							</Typography>
							{getStatusChip(billingInfo.subscriptionStatus)}
							<Typography
								variant='h2'
								style={{ textTransform: 'uppercase', marginLeft: 'auto' }}
							>
								S${billingInfo.price}
							</Typography>
							<Typography variant='body1' style={{ marginLeft: 8 }}>
								{billingInfo.licenseTotal > 1
									? `user/${billingInfo.recurring}`
									: `per ${billingInfo.recurring}`}
							</Typography>
						</div>
						<Divider style={{ margin: '24px 0px' }} />
						<div className={classes.container}>
							<div style={{ marginRight: 'auto', width: '100%' }}>
								<Grid
									container
									className={classes.gridContainer}
									direction='row'
									alignItems='stretch'
									spacing={3}
								>
									<Grid item xs={6} md={4} lg={2}>
										<Typography
											color='textPrimary'
											variant='body2'
											style={{ marginBottom: 8 }}
										>
											Joined Date
										</Typography>
										<Typography color='textPrimary' variant='body1'>
											{formatDate(billingInfo.subscriptionStartDate)}
										</Typography>
									</Grid>
									{billingInfo.subscriptionStatus === 'trialing' && (
										<Grid item xs={6} md={4} lg={2}>
											<Typography
												color='textPrimary'
												variant='body2'
												style={{ marginBottom: 8 }}
											>
												Trial Expiring On
											</Typography>
											<Typography color='textPrimary' variant='body1'>
												{formatDate(billingInfo.subscriptionExpiryDate)}
											</Typography>
										</Grid>
									)}
									{!['pending cancellation', 'paused'].includes(
										billingInfo.subscriptionStatus
									) && (
										<Grid item xs={6} md={4} lg={2}>
											<Typography
												color='textPrimary'
												variant='body2'
												style={{ marginBottom: 8 }}
											>
												Renewal Date
											</Typography>
											<Typography color='textPrimary' variant='body1'>
												{formatDate(billingInfo.subscriptionExpiryDate)}
											</Typography>
											<div style={{ marginTop: 8 }}>
												<Link
													style={{
														color: theme.palette.tertiary.main,
														marginTop: 16,
														fontSize: '0.75rem',
														cursor: 'pointer',
													}}
													onClick={
														process.env.REACT_APP_ENVIRONMENT !== 'demo'
															? () => fetchPortalSession('cancelPlan')
															: undefined
													}
												>
													Cancel my subscription
												</Link>
											</div>
										</Grid>
									)}
									{billingInfo.subscriptionStatus ===
										'pending cancellation' && (
										<Grid item xs={6} md={4} lg={2}>
											<Typography
												color='textPrimary'
												variant='body2'
												style={{ marginBottom: 8 }}
											>
												Expiration Date
											</Typography>
											<Typography color='error' variant='body1'>
												{formatDate(billingInfo.subscriptionExpiryDate)}
											</Typography>
										</Grid>
									)}
									{billingInfo.subscriptionStatus === 'paused' && (
										<Grid item xs={6} md={4} lg={2}>
											<Typography
												color='textPrimary'
												variant='body2'
												style={{ marginBottom: 8 }}
											>
												Paused Date
											</Typography>
											<Typography color='error' variant='body1'>
												{formatDate(billingInfo.subscriptionExpiryDate)}
											</Typography>
										</Grid>
									)}
								</Grid>
								<Grid
									container
									className={classes.gridContainer}
									direction='row'
									alignItems='stretch'
									spacing={3}
								>
									<Grid item xs={6} md={4} lg={2}>
										<Typography
											color='textPrimary'
											variant='body2'
											style={{ marginBottom: 8 }}
										>
											Billing Cycle
										</Typography>
										<Typography
											color='textPrimary'
											variant='body1'
											style={{ textTransform: 'capitalize' }}
										>
											{billingInfo.recurring && `${billingInfo.recurring}ly`}
										</Typography>
									</Grid>
									<Grid item xs={6} md={4} lg={2}>
										<Typography
											color='textPrimary'
											variant='body2'
											style={{ marginBottom: 8 }}
										>
											Next Billing Date
										</Typography>
										<Typography color='textPrimary' variant='body1'>
											{!['pending cancellation', 'paused'].includes(
												billingInfo.subscriptionStatus
											)
												? `${moment(billingInfo.subscriptionExpiryDate).format(
														'MMM DD, YYYY'
												  )}`
												: `-`}
										</Typography>
									</Grid>
									<Grid item xs={12} md={12} lg={6}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												height: '100%',
											}}
										>
											<Button
												variant='outlined'
												color='primary'
												onClick={() => fetchPortalSession()}
												disabled={
													process.env.REACT_APP_ENVIRONMENT === 'demo' && true
												}
											>
												View Invoices
											</Button>
											<Button
												variant='outlined'
												color='primary'
												style={{
													marginLeft: 16,
												}}
												onClick={() =>
													fetchPortalSession('manageBillingDetails')
												}
												disabled={
													process.env.REACT_APP_ENVIRONMENT === 'demo' && true
												}
											>
												Manage Billing Details
											</Button>
										</div>
									</Grid>
								</Grid>
							</div>
							{credentials.authUser._delegate.uid === billingInfo.owner && (
								<>
									<Button
										variant='contained'
										color='primary'
										style={{
											marginLeft: 16,
											height: 'fit-content',
											minWidth: 186,
										}}
										onClick={() => fetchPortalSession()}
										disabled={
											process.env.REACT_APP_ENVIRONMENT === 'demo' && true
										}
									>
										Manage Subscription
									</Button>
								</>
							)}
						</div>
					</div>
				</>
			)}
		</AuthUserContext.Consumer>
	);
}
