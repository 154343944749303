import Joi from 'joi';

const User = Joi.object({
	firstName: Joi.string().label('First Name').trim().min(1).max(50).required(),
	middleName: Joi.string()
		.label('Middle Name')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	lastName: Joi.string().label('Last Name').trim().min(1).max(50).required(),

	gender: Joi.string()
		.label('Gender')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	mobileNo: Joi.string()
		.label('Mobile Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Mobile Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	nationality: Joi.string()
		.label('Nationality')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	title: Joi.string().label('Title').trim().min(1).max(50).optional().allow(''),
	agentId: Joi.string().label('Agent ID').trim().min(1).max(50).required(),
}).unknown(true);

export default User;
