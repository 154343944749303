import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ActivityViewer from '../../../components/ActivityViewer';
import SuccessMessage from '../../../components/SuccessMessage';
import Loader from '../../../components/Loader';
import api from '../../../config/api';
import InvoicePreviewer from './InvoicePreviewer';
import SummaryViewer from './SummaryViewer';
import { updateRecentlyViewed } from '../../../utils/storageHelper';

const useStyles = makeStyles((theme) => ({}));

export default function InvoiceDetail(props) {
	const { id } = useParams();
	const classes = useStyles();
	const theme = useTheme();

	const [invoice, setInvoice] = useState(null);
	const [activities, setActivities] = useState([]);
	const [invoiceLoading, setInvoiceLoading] = useState(true);
	const [activitiesLoading, setActivitiesLoading] = useState(true);
	const [triggerUpdate, setTriggerUpdate] = useState(false);
	const [triggerUpdateActivities, setTriggerUpdateActivities] = useState(false);

	const getInvoiceData = () => {
		if (!invoiceLoading) {
			setInvoiceLoading(true);
		}
		api
			.get('invoices/details/' + id)
			.then((response) => {
				if (response.data.ok) {
					let data = {
						_id: response.data.data._id,
						invoiceNo: response.data.data.invoiceNo,
						transactionNo: response.data.data.refTransaction.transactionNo,
						transactionId: response.data.data.refTransaction.transactionId._id,
						insuranceType: response.data.data.invoiceData.insuranceType,
						insuranceSubType: response.data.data.invoiceData.insuranceSubType,
						settings: response.data.data.invoiceData.settings,
						userProfile: response.data.data.invoiceData.userProfile,
						orgProfile: response.data.data.invoiceData.orgProfile,
						policyData: response.data.data.invoiceData.policyData,
						clientData: {
							contact: response.data.data.client.contactId,
							company: response.data.data.client.companyId,
							contactName: response.data.data.client.contactName,
							companyName: response.data.data.client.companyName,
						},
						status: response.data.data.status,
						createdAt: response.data.data.createdAt,
						dueDate: response.data.data.invoiceData.dueDate,
						subject: response.data.data.invoiceData.subject,
						message: response.data.data.invoiceData.message,
						paymentDate: response.data.data.paymentDate,
						paymentMethod: response.data.data.paymentMethod,
						paymentNotes: response.data.data.paymentNotes,
						paymentDocument: response.data.data.paymentDocument
							? response.data.data.paymentDocument
							: null,
					};

					setInvoice(data);
					setTriggerUpdate(false);
					updateRecentlyViewed({
						key: response.data.data.createdBy,
						value: {
							_id: response.data.data._id,
							name: response.data.data.invoiceNo,
							type: 'invoice',
						},
					});
				}
				setInvoiceLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getActivityData = () => {
		if (!activitiesLoading) {
			setActivitiesLoading(true);
		}
		api
			.get('activities/' + id)
			.then((response) => {
				if (response.data.ok) {
					setTriggerUpdateActivities(false);
					setActivities(response.data.data);
				}
				setActivitiesLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		getInvoiceData();
		getActivityData();
	}, [id]);

	useEffect(() => {
		if (triggerUpdate) {
			getInvoiceData();
			getActivityData();
		}
	}, [triggerUpdate]);

	useEffect(() => {
		if (triggerUpdateActivities) {
			getActivityData();
		}
	}, [triggerUpdateActivities]);

	if (invoiceLoading) return <Loader size='lg' />;
	if (!invoice) return <SuccessMessage type={'invalid-request'} />;

	return (
		<>
			<Breadcrumbs separator='›' aria-label='breadcrumb'>
				<Typography color='inherit'>Invoices</Typography>
				<Typography color='inherit'>Invoice Overview</Typography>
				<Typography color='primary'>INV-{invoice.invoiceNo}</Typography>
			</Breadcrumbs>
			<Button
				style={{
					marginTop: 24,
					marginBottom: 24,
				}}
				variant='outlined'
				component={RouterLink}
				color='primary'
				startIcon={<NavigateBeforeIcon />}
				to={'/invoices'}
			>
				Back
			</Button>
			<Typography variant='h2' style={{ marginBottom: 24 }}>
				INV-{invoice.invoiceNo}
			</Typography>
			<Grid
				container
				spacing={3}
				direction='row'
				justifyContent='center'
				alignItems='stretch'
			>
				<Grid style={{ height: '0%' }} item xs={12} lg={3}>
					<ActivityViewer activities={activities} loading={activitiesLoading} />
				</Grid>
				<Grid item xs={12} md={4} lg={3}>
					<SummaryViewer
						invoice={invoice}
						setTriggerUpdate={setTriggerUpdate}
					/>
				</Grid>
				<Grid style={{ minHeight: 640 }} item xs={12} md={8} lg={6}>
					<InvoicePreviewer
						invoice={invoice}
						setTriggerUpdate={setTriggerUpdate}
					/>
				</Grid>
			</Grid>
		</>
	);
}
