import Backdrop from '../../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../config/api';
import '../styles.css';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({}));

export default function ResendClientRequest(props) {
	const { open, handleClose, body, transactionId, data } = props;
	const classes = useStyles();

	const [expand, setExpand] = useState(false);
	const [loading, setLoading] = useState(false);

	const getType = () => {
		switch (data.requestType) {
			case 'fileRequest': {
				return 'File Request';
			}
			case 'quoteSelection': {
				return 'Quote Selection Request';
			}
			case 'confirmClaim': {
				return 'Claim Confirmation Request';
			}
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);
			try {
				api
					.post(`requests/resend/${data.requestType}/${data._id}`)
					.then((res) => {
						if (res.data.ok) {
							toast.success('Request sent successfully!');
							setLoading(false);
							props.setTriggerUpdate(true);
							handleClose();
						} else {
							toast.error(res.data.error);
							setLoading(false);
							handleClose();
						}
					});
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		}
	};

	return (
		<>
			<Backdrop text='Loading Data...' open={loading} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Resend {getType()}
				</Typography>
				<IconButton
					style={{ marginLeft: 'auto', padding: 0 }}
					disableRipple
					disableFocusRipple
					disableTouchRipple
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<Typography
				variant='body1'
				color='textPrimary'
				style={{ marginTop: 24, marginBottom: 24 }}
			>
				You are about to resend the {getType()}. Are you sure you want to
				proceed?
			</Typography>
			<div
				style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}
			>
				<Button
					style={{ marginRight: 16 }}
					onClick={handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={(e) => handleSubmit(e)}
					variant='contained'
					color='primary'
				>
					Proceed
				</Button>
			</div>
		</>
	);
}
