import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Box from '@material-ui/core/Box';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Overdue } from '../../../assets/Invoice_Overdue_v1.svg';
import { ReactComponent as Paid } from '../../../assets/Invoice_Paid_v1.svg';
import { ReactComponent as Pending } from '../../../assets/Invoice_Pending_v1.svg';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	card: {
		width: 490,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '2%',
	},
	circleText: {
		minHeight: 256,
		minWidth: 256,
		height: 256,
		width: 256,
		padding: 24,

		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '100%',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
}));

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

export default function InvoiceWidget(props) {
	const { invoices } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		prepareData();
	}, [props]);

	const prepareData = () => {
		let paidCount = 0;
		let paidAmount = 0;
		let pendingCount = 0;
		let pendingAmount = 0;
		let overdueCount = 0;
		let overdueAmount = 0;
		let totalCount = 0;
		let totalAmount = 0;

		invoices.forEach((invoice) => {
			switch (invoice.status) {
				case 'Paid': {
					paidCount++;
					paidAmount = paidAmount + parseFloat(invoice.amount);
					totalCount++;
					totalAmount = totalAmount + parseFloat(invoice.amount);
					break;
				}
				case 'Pending': {
					if (new Date(invoice.dueDate) < new Date()) {
						overdueCount++;
						overdueAmount = overdueAmount + parseFloat(invoice.amount);
					} else {
						pendingCount++;
						pendingAmount = pendingAmount + parseFloat(invoice.amount);
					}
					totalCount++;
					totalAmount = totalAmount + parseFloat(invoice.amount);
					break;
				}
			}
		});

		setData({
			paidCount: paidCount,
			paidAmount: paidAmount,
			pendingCount: pendingCount,
			pendingAmount: pendingAmount,
			overdueCount: overdueCount,
			overdueAmount: overdueAmount,
			totalCount: totalCount,
			totalAmount: totalAmount,
		});
	};

	if (loading) return <CircularProgress />;
	return (
		<>
			<Card
				className={classes.card}
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					width: '100%',
					padding: 24,
					margin: '24px 0px',
				}}
			>
				<Grid container spacing={4}>
					<Grid
						item
						xs={12}
						md={3}
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<div className={classes.circleText}>
							<Typography variant='h2' color='textPrimary'>
								{/* ${data.totalAmount} */}
								{currencyFormatter.format(data.totalAmount)}
							</Typography>
							<Typography
								style={{ textTransform: 'uppercase' }}
								variant='h3'
								color='textPrimary'
							>
								Total Invoice Amount
							</Typography>
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-evenly',
								width: '100%',
								height: '100%',
							}}
						>
							<Grid container spacing={1}>
								<Grid item xs={5}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<FiberManualRecordIcon
											style={{
												height: 22,
												marginRight: 8,
												color: theme.palette.success.main,
											}}
										/>
										<Typography
											style={{ minWidth: '50%' }}
											variant='h3'
											color='textPrimary'
										>
											Paid
										</Typography>
									</div>
								</Grid>
								<Grid item xs={5}>
									<Typography variant='h3' color='textPrimary'>
										{/* ${data.paidAmount} */}
										{currencyFormatter.format(data.paidAmount)}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={5}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<FiberManualRecordIcon
											style={{
												height: 22,
												marginRight: 8,
												color: theme.palette.warning.main,
											}}
										/>
										<Typography
											style={{ minWidth: '50%' }}
											variant='h3'
											color='textPrimary'
										>
											Pending
										</Typography>
									</div>
								</Grid>
								<Grid item xs={5}>
									<Typography variant='h3' color='textPrimary'>
										{/* ${data.pendingAmount} */}
										{currencyFormatter.format(data.pendingAmount)}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={5}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<FiberManualRecordIcon
											style={{
												height: 22,
												marginRight: 8,
												color: theme.palette.error.main,
											}}
										/>
										<Typography
											style={{ minWidth: '50%' }}
											variant='h3'
											color='textPrimary'
										>
											Overdue
										</Typography>
									</div>
								</Grid>
								<Grid item xs={5}>
									<Typography variant='h3' color='textPrimary'>
										{/* ${data.overdueAmount} */}
										{currencyFormatter.format(data.overdueAmount)}
									</Typography>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Box
						component={Grid}
						item
						md={1}
						display={{ xs: 'none', md: 'block' }}
					>
						<Divider style={{ margin: 'auto' }} orientation='vertical' />
					</Box>
					<Grid item xs={12} md={5}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-evenly',
								width: '100%',
								height: '100%',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								<div style={{ width: 64 }}>
									<Paid />
								</div>
								<Typography
									style={{ marginTop: 16, marginBottom: 24 }}
									variant='h3'
									color='textPrimary'
								>
									Paid
								</Typography>
								<Typography
									style={{ color: theme.palette.success.main }}
									variant='h3'
									color='textPrimary'
								>
									{data.paidCount}
								</Typography>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								<div style={{ width: 64 }}>
									<Pending />
								</div>
								<Typography
									style={{ marginTop: 16, marginBottom: 24 }}
									variant='h3'
									color='textPrimary'
								>
									Pending
								</Typography>
								<Typography
									style={{ color: theme.palette.warning.main }}
									variant='h3'
									color='textPrimary'
								>
									{data.pendingCount}
								</Typography>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								<div style={{ width: 64 }}>
									<Overdue />
								</div>
								<Typography
									style={{ marginTop: 16, marginBottom: 24 }}
									variant='h3'
									color='textPrimary'
								>
									Overdue
								</Typography>
								<Typography
									style={{ color: theme.palette.error.main }}
									variant='h3'
									color='textPrimary'
								>
									{data.overdueCount}
								</Typography>
							</div>
						</div>
					</Grid>
				</Grid>
			</Card>
		</>
	);
}
