import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const Step1 = (props) => {
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Typography variant='h3'>
						<u>Import Instructions</u>
					</Typography>
					<Typography variant='body1' style={{ marginTop: 16 }}>
						Your existing clients' details can only be uploaded to Uuoni in the
						following format: .xls, .xlsx, .csv
					</Typography>
					<Typography variant='body1' style={{ marginTop: 16 }}>
						Please ensure that import file column headers of your clients’
						details are on the first row of the sheet. Example, Row 1: Name,
						Address, Contact No. etc.
					</Typography>
					<Typography variant='body1' style={{ marginTop: 16 }}>
						If you are experiencing difficulties with your clients' details
						import, please{' '}
						<Link
							variant='body1'
							component={Link}
							href='https://www.uuoni.com/contact'
							target='_blank'
							rel='noopener'
						>
							contact us
						</Link>
						.
					</Typography>
					<Typography variant='body1' style={{ marginTop: 16 }}>
						Once you have prepared your file for import, click <b>Start</b> to
						begin the process!
					</Typography>
				</Grid>
				{/* <Grid item xs={6}>
					<div>
						<u>Visual info here</u>
					</div>
					<div>Visual info here</div>
				</Grid> */}
			</Grid>
		</>
	);
};

export default Step1;
