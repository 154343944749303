import axios from 'axios';

const instance = axios.create({
	// baseURL: '/api/v1/',
	// baseURL: 'https://app.uuoni.com/api/v1/',
	// baseURL: 'http://localhost:3001/api/v1/',
	baseURL: process.env.REACT_APP_APIDOMAIN_BASEURL,
});

instance.interceptors.request.use(
	async (config) => {
		if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
			console.log('Starting Request', config);
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use((response) => {
	if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
		console.log('Response:', response);
	}
	return response;
});

export default instance;
