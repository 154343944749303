import Avatar from '@material-ui/core/Avatar';
import Backdrop from '../../../../components/Backdrop';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import Tooltip from '../../../../components/Tooltip';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PrincipalList from '../../../../components/Data/principalData.json';
import api from '../../../../config/api';
import PrincipalModal from '../../../../components/Modals/principals';
import ActionMenu from './menu';
import { useUserProfile } from '../../../../components/Session';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.grey[100],
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	rootInactive: {
		backgroundColor: theme.palette.grey[300],
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	cardHeader: {
		height: '100%',
	},
	cardContent: {
		padding: 0,
		paddingLeft: 24,
		paddingRight: 24,
		'&:last-child': {
			paddingBottom: 24,
		},
	},
	cardContentNew: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		padding: 32,
	},
	avatar: {
		width: 46,
		height: 46,
	},
}));

const getPrincipalByCode = (code) => {
	let principal = PrincipalList.find((item) => item.code === code);
	return principal;
};

const initForm = {
	code: '',
	name: '',
	shortName: '',
	hotline: '',
	email: '',
	agentSalesTargets: {
		monthValue: 0,
		yearValue: 0,
	},
	agentCodes: {
		code: '',
	},
	employmentDate: '',
	inactiveDate: '',
	contactCount: 0,
	status: false,
};

export default function Principals(props) {
	const classes = useStyles();
	const theme = useTheme();
	const userProfile = useUserProfile();

	const [activeList, setActiveList] = useState([]);
	const [inactiveList, setInactiveList] = useState([]);
	const [principalForm, setPrincipalForm] = useState(initForm);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogBody, setDialogBody] = useState(0);
	const [loading, setLoading] = useState(true);
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [menu, setMenu] = useState(null);
	const [menuContext, setMenuContext] = useState(null);
	const [triggerListUpdate, setTriggerListUpdate] = useState(true);
	const [errorMessages, setErrorMessages] = useState({});

	useEffect(() => {
		if (triggerListUpdate) getData();
	}, [triggerListUpdate]);

	const getData = async () => {
		if (!loading) {
			setLoading(true);
		}
		try {
			const res = await api.get('principals');
			const active = [];
			const inactive = [];
			res.data.data.forEach((element) => {
				if (!element.status) {
					inactive.push(element);
				} else {
					active.push(element);
				}
			});
			setActiveList(active);
			setInactiveList(inactive);
			setTriggerListUpdate(false);
			setLoading(false);
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleCancel = () => {
		setDialogOpen(false);
		setPrincipalForm(initForm);
	};

	const toggleDialog = (body) => {
		if (!dialogOpen) {
			if (userProfile.professionType === 'GI') {
				if (body === 'reactivatePrincipal' && activeList.length >= 3) {
					return setDialogBody('warnReactivatePrincipal'), setDialogOpen(true);
				}
				if (body === 'addPrincipal' && activeList.length >= 3) {
					return setDialogBody('warnAddPrincipal'), setDialogOpen(true);
				}
			}
			return setDialogBody(body), setDialogOpen(true);
		}
		setDialogOpen(!dialogOpen);
	};

	const handleChangePrincipal = (principal) => {
		setPrincipalForm((prev) => ({
			...prev,
			...principal,
		}));
	};

	const handleChange = (e, field) => {
		const { name, value } = e.target;
		if (field && field === 'agentSalesTargets') {
			setPrincipalForm((prev) => ({
				...prev,
				agentSalesTargets: {
					...prev.agentSalesTargets,
					[name]: value,
				},
			}));
		} else if (field && field === 'agentCodes') {
			setPrincipalForm((prev) => ({
				...prev,
				agentCodes: {
					...prev.agentCodes,
					[name]: value,
				},
			}));
		} else {
			setPrincipalForm((prev) => ({
				...prev,
				[name]: value,
			}));
		}
	};

	const handleDateChange = (name, date) => {
		setPrincipalForm((prev) => ({
			...prev,
			[name]: date,
		}));
	};

	const handleAdd = () => {
		setDialogOpen(false);
		setBackdropOpen(true);
		let selectedPrincipal = getPrincipalByCode(principalForm.code);
		const updatedForm = {
			code: principalForm.code,
			name: selectedPrincipal.name,
			shortName: selectedPrincipal.shortName,
			employmentDate: principalForm.employmentDate,
			inactiveDate: principalForm.inactiveDate,
			status: true,
		};
		api.post('principals', updatedForm).then((res) => {
			toast.success('Principal added!');
			setPrincipalForm(initForm);
			setTriggerListUpdate(true);
			setBackdropOpen(false);
		});
	};

	const handleSave = (e, id) => {
		e.preventDefault();
		setDialogOpen(false);
		setBackdropOpen(true);
		const updatedForm = {
			email: principalForm.email,
			hotline: principalForm.hotline,
			agentSalesTargets: principalForm.agentSalesTargets,
			agentCodes: principalForm.agentCodes,
		};
		api.put('principals/' + id, updatedForm).then((res) => {
			toast.success('Principal updated!');
			setPrincipalForm(initForm);
			setTriggerListUpdate(true);
			setBackdropOpen(false);
		});
	};

	const handleReactivate = (e, id) => {
		e.preventDefault();
		setDialogOpen(false);
		setBackdropOpen(true);
		api.put('principals/' + id + '/activate').then((res) => {
			toast.success('Principal activated!');
			setPrincipalForm(initForm);
			setTriggerListUpdate(true);
			setBackdropOpen(false);
		});
	};

	const handleDeactivate = (e, id) => {
		e.preventDefault();
		setDialogOpen(false);
		setBackdropOpen(true);
		api.put('principals/' + id + '/deactivate').then((res) => {
			toast.success('Principal deactivated!');
			setPrincipalForm(initForm);
			setTriggerListUpdate(true);
			setBackdropOpen(false);
		});
	};

	return (
		<>
			<Backdrop text='Loading Data...' open={backdropOpen} />
			<div>
				<div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							variant='h3'
							style={{ textTransform: 'uppercase', marginRight: 8 }}
						>
							Representing (Active)
						</Typography>
						<Tooltip
							content={
								<Typography variant='body1' color='textPrimary'>
									Insurance companies currently representing
								</Typography>
							}
						/>
					</div>
					<div style={{ width: '95%', marginBottom: 32, marginTop: 16 }}>
						{loading ? (
							<CircularProgress />
						) : (
							<Grid container className={classes.gridContainer} spacing={2}>
								{activeList.map((principal, index) => (
									<Grid key={principal._id} item xs={12} md={6} lg={3}>
										<Card className={classes.root}>
											<CardHeader
												classes={{ root: classes.cardHeader }}
												avatar={
													<Avatar
														aria-label='recipe'
														className={classes.avatar}
														style={{
															backgroundColor: theme.palette.tertiary.main,
														}}
													>
														{principal.name.charAt(0).toUpperCase()}
													</Avatar>
												}
												action={
													<IconButton
														aria-label='settings'
														onClick={(e) => {
															handleChangePrincipal(principal);
															setMenuContext('Deactivate');
															setMenu(e.currentTarget);
														}}
													>
														<MoreHorizOutlinedIcon />
													</IconButton>
												}
												title={principal.name}
											/>
											<CardContent className={classes.cardContent}>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														marginBottom: 8,
													}}
												>
													<PeopleOutlineIcon />
													<Typography
														style={{ marginLeft: 16 }}
														variant='body1'
														color='textPrimary'
														component='p'
													>
														{principal.contactCount} Contacts
													</Typography>
												</div>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														marginBottom: 8,
													}}
												>
													<CalendarTodayOutlinedIcon />
													<Typography
														style={{ marginLeft: 16 }}
														variant='body1'
														color='textPrimary'
														component='p'
													>
														Active since{' '}
														{new Date(
															principal.employmentDate
														).toLocaleDateString()}
													</Typography>
												</div>
												{principal.email && (
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															marginBottom: 8,
														}}
													>
														<EmailOutlinedIcon />
														<Typography
															style={{
																marginLeft: 16,
																color: theme.palette.tertiary.main,
															}}
															variant='body1'
															component={Link}
															href={`mailto:${principal.email}`}
														>
															{principal.email}
														</Typography>
													</div>
												)}
												{principal.hotline && (
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<PhoneOutlinedIcon />
														<Typography
															style={{
																marginLeft: 16,
																color: theme.palette.tertiary.main,
															}}
															variant='body1'
															component={Link}
															href={`tel:${principal.hotline}`}
														>
															{principal.hotline}
														</Typography>
													</div>
												)}
											</CardContent>
										</Card>
									</Grid>
								))}

								<Grid key={'new'} item xs={12} md={6} lg={3}>
									<div
										className={classes.root}
										style={{
											border: `2px dashed ${theme.palette.tertiary.main}`,
											borderRadius: 4,
											backgroundColor: theme.palette.background.default,
											height: '100%',
										}}
									>
										<div
											className={classes.cardContentNew}
											onClick={() => toggleDialog('addPrincipal')}
										>
											<AddCircleOutlineOutlinedIcon
												style={{
													width: 50,
													height: 50,
													color: theme.palette.tertiary.main,
												}}
											/>
											<Typography
												style={{
													marginTop: 5,
													color: theme.palette.tertiary.main,
													cursor: 'default',
												}}
											>
												Add New Principal
											</Typography>
										</div>
									</div>
								</Grid>
							</Grid>
						)}
					</div>
				</div>

				<div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography
							variant='h3'
							style={{ textTransform: 'uppercase', marginRight: 8 }}
						>
							Previously Represented (Inactive)
						</Typography>
						<Tooltip
							content={
								<Typography variant='body1' color='textPrimary'>
									Insurance companies previously representing
								</Typography>
							}
						/>
					</div>
					<div style={{ width: '95%', marginBottom: 32, marginTop: 16 }}>
						{loading ? (
							<CircularProgress />
						) : (
							<Grid container className={classes.gridContainer} spacing={2}>
								{inactiveList.map((principal, index) => (
									<Grid key={principal._id} item xs={12} md={6} lg={3}>
										<Card className={classes.rootInactive}>
											<CardHeader
												classes={{ root: classes.cardHeader }}
												style={{ opacity: 0.5 }}
												avatar={
													<Avatar
														aria-label='recipe'
														className={classes.avatar}
														style={{
															backgroundColor: theme.palette.grey[500],
														}}
													>
														{principal.name.charAt(0).toUpperCase()}
													</Avatar>
												}
												action={
													<IconButton
														aria-label='settings'
														onClick={(e) => {
															handleChangePrincipal(principal);
															setMenuContext('Reactivate');
															setMenu(e.currentTarget);
														}}
													>
														<MoreHorizOutlinedIcon />
													</IconButton>
												}
												title={principal.name}
											/>
											<CardContent
												className={classes.cardContent}
												style={{ opacity: 0.5 }}
											>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														marginBottom: 8,
													}}
												>
													<PeopleOutlineIcon />
													<Typography
														style={{ marginLeft: 16 }}
														variant='body1'
														color='textSecondary'
														component='p'
													>
														{principal.contactCount} Contacts
													</Typography>
												</div>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														marginBottom: 8,
													}}
												>
													<CalendarTodayOutlinedIcon />
													<Typography
														style={{ marginLeft: 16 }}
														variant='body1'
														color='textSecondary'
														component='p'
													>
														Inactive since{' '}
														{new Date(
															principal.inactiveDate
														).toLocaleDateString()}
													</Typography>
												</div>

												{principal.email && (
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															marginBottom: 8,
														}}
													>
														<EmailOutlinedIcon />
														<Typography
															style={{
																marginLeft: 16,
																color: theme.palette.tertiary.main,
															}}
															variant='body1'
															component={Link}
															href={`mailto:${principal.email}`}
														>
															{principal.email}
														</Typography>
													</div>
												)}
												{principal.hotline && (
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<PhoneOutlinedIcon />
														<Typography
															style={{
																marginLeft: 16,
																color: theme.palette.tertiary.main,
															}}
															variant='body1'
															component={Link}
															href={`tel:${principal.hotline}`}
														>
															{principal.hotline}
														</Typography>
													</div>
												)}
											</CardContent>
										</Card>
									</Grid>
								))}
							</Grid>
						)}
					</div>
				</div>

				<PrincipalModal
					open={dialogOpen}
					body={dialogBody}
					principalForm={principalForm}
					handleCancel={handleCancel}
					handleClose={handleCancel}
					handleChange={handleChange}
					handleDateChange={handleDateChange}
					handleAdd={handleAdd}
					handleReactivate={handleReactivate}
					handleDeactivate={handleDeactivate}
					handleSave={handleSave}
					errorMessages={errorMessages}
				/>

				<ActionMenu
					menu={menu}
					setMenu={setMenu}
					menuContext={menuContext}
					setMenuContext={setMenuContext}
					toggleDialog={toggleDialog}
					setPrincipalForm={setPrincipalForm}
					initForm={initForm}
				/>
			</div>
		</>
	);
}
