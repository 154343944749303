import Joi from 'joi';

const Underwriter = Joi.object({
	firstName: Joi.string().label('First Name').trim().min(1).max(50).required(),
	middleName: Joi.string()
		.label('Middle Name')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	lastName: Joi.string()
		.label('Last Name')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	email: Joi.string()
		.label('Email')
		.trim()
		.email({ tlds: { allow: false } })
		.required(),
	mobileNo: Joi.string()
		.label('Mobile Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Mobile Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	officeNo: Joi.string()
		.label('Office Number')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Office Number" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	department: Joi.string()
		.label('Department')
		.trim()
		.min(1)
		.max(50)
		.optional()
		.allow(''),
	principalId: Joi.string().label('Company').trim().min(1).max(50).required(),
}).unknown(true);

export default Underwriter;
