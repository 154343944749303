const getFromLS = (key) => {
	let ls = null;
	if (global.localStorage) {
		ls = global.localStorage.getItem(key);
		return JSON.parse(ls);
	}
	return ls;
};

const saveToLS = (key, value) => {
	if (global.localStorage) {
		return global.localStorage.setItem(key, value);
	}
};

const updateRecentlyViewed = (obj) => {
	let key = `recentlyViewed-${obj.key}`;
	let list = getFromLS(key);
	let updatedList = [];
	if (!list) {
		updatedList.push(obj.value);
		return saveToLS(key, JSON.stringify(updatedList));
	} else {
		if (list.some((item) => item._id === obj.value._id)) {
			updatedList = list.filter((item) => item._id !== obj.value._id);
			updatedList.push(obj.value);
			return saveToLS(key, JSON.stringify(updatedList));
		}
		if (list.length === 10) {
			updatedList = list.slice(1);
			updatedList.push(obj.value);
			return saveToLS(key, JSON.stringify(updatedList));
		}
		list.push(obj.value);
		return saveToLS(key, JSON.stringify(list));
	}
};

export { getFromLS, saveToLS, updateRecentlyViewed };
