import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '../../../../components/Tooltip';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import { differenceInYears } from 'date-fns';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../config/api';
import Companies from './ProfileTab/companies.js';
import PersonalInfo from './ProfileTab/personalInfo.js';
import RelationshipInfo from './ProfileTab/relationship.js';
import Transactions from './TransactionsTab';
import { updateRecentlyViewed } from '../../../../utils/storageHelper';
import { Contact } from '../../../../utils/validators/schemas';
import { combineNames } from '../../../../utils/combineNames';
import {
	Contacts as ContactIcon,
	Edit,
	Save,
} from '../../../../components/Icons/index.js';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box style={{ padding: 0 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.secondary,
		height: 'inherit',
	},
	tabs: {},
	tabArea: {
		height: 560,

		display: 'block',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	tabAreaMobile: {
		display: 'block',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	listItemRoot: {
		paddingLeft: 24,
		paddingRight: 24,
		'&$selected': {
			backgroundColor: 'rgba(183, 198, 243, 0.2)',
			'&:hover': {
				backgroundColor: 'rgba(183, 198, 243, 0.2)',
			},
		},
	},
	listItemButton: {
		'&:hover': {
			backgroundColor: 'rgba(183, 198, 243, 0.2)',
		},
	},
}));

export default function ContactProfile(props) {
	const {
		viewTags,
		viewCompanies,
		contactId,
		editable,
		setEditable,
		setTriggerListUpdate,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const paneRef = useRef();
	const [value, setValue] = useState(0);
	const [contact, setContact] = useState({});
	const [ongoingTransactions, setOngoingTransactions] = useState([]);
	const [completedTransactions, setCompletedTransactions] = useState([]);
	const [open0, setOpen0] = useState(viewTags || viewCompanies ? false : true);
	const [open1, setOpen1] = useState(viewCompanies ? true : false);
	const [open2, setOpen2] = useState(viewTags ? true : false);
	const [loading, setLoading] = useState(true);

	const [errorMessages, setErrorMessages] = useState({});
	const [triggerPaneUpdate, setTriggerPaneUpdate] = useState(false);

	const getContactData = async (contactId) => {
		setLoading(true);
		await api
			.get('contacts/' + contactId)
			.then((response) => {
				setContact(response.data);
				updateRecentlyViewed({
					key: response.data.createdBy,
					value: {
						_id: response.data._id,
						name: [
							response.data.firstName,
							response.data.middleName,
							response.data.lastName,
						]
							.filter(Boolean)
							.join(' '),
						type: 'contact',
					},
				});
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getTransactions = async (contactId) => {
		setLoading(true);
		await api
			.get('transactions/contact/' + contactId)
			.then((response) => {
				if (response.data.length > 0) {
					let ongoingList = response.data.filter(
						(transaction) => transaction.status !== 'Completed'
					);
					let completedList = response.data.filter(
						(transaction) => transaction.status === 'Completed'
					);
					setOngoingTransactions(ongoingList);
					setCompletedTransactions(completedList);
				} else {
					setOngoingTransactions([]);
					setCompletedTransactions([]);
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		getContactData(contactId);
		getTransactions(contactId);
	}, [contactId, triggerPaneUpdate]);

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};

	const handleExpand = (panel) => {
		switch (panel) {
			case 0:
				return setOpen0(!open0);
			case 1:
				return setOpen1(!open1);
			case 2:
				return setOpen2(!open2);
			default:
				return;
		}
	};

	const getAge = (value) => {
		if (value) {
			return differenceInYears(new Date(), new Date(value));
		} else {
			return null;
		}
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;
		setContact((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleDateChange = (target, date) => {
		if (target === 'dob') {
			let ageValue = differenceInYears(new Date(), date);
			return setContact((prev) => ({
				...prev,
				[target]: date,
				age: ageValue,
			}));
		}
		setContact((prev) => ({
			...prev,
			[target]: date,
		}));
	};

	const togglePaneUpdate = () => {
		if (!triggerPaneUpdate) {
			setTriggerPaneUpdate(true);
		}
		setTriggerPaneUpdate(!triggerPaneUpdate);
	};

	const handleValidation = () => {
		let result = Contact.validate(contact, {
			abortEarly: false,
			convert: false,
		});

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			console.log(error.details);
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = () => {
		console.log('handleSave');
		let valid = handleValidation();

		if (valid) {
			setLoading(true);
			console.log('send data');
			api.put('contacts/' + contact._id, contact).then((res) => {
				if (res.data.ok) {
					setErrorMessages({});
					setEditable(false);
					setTriggerListUpdate(true);
					setLoading(false);
					togglePaneUpdate();
					toast.success('Contact updated!');
				} else {
					toast.error(res.data.error);
					setLoading(false);
				}
			});
		} else {
			toast.error('An error has occured!');
		}
	};

	const handleAddTag = (tagLabel, type) => {
		let exists = contact.tags.some((tag) => {
			if (tagLabel === tag.label) {
				return true;
			}
			return false;
		});

		if (exists) {
			setErrorMessages((prev) => ({
				...prev,
				tagLabel: 'Tag exists. Please enter another tag name.',
			}));
		} else {
			let newTag = { label: tagLabel, type: type };
			let updatedTags = [...contact.tags, newTag];
			setErrorMessages((prev) => {
				const { tagLabel, ...errorMessages } = prev;
				return errorMessages;
			});
			setContact((prev) => ({
				...prev,
				tags: updatedTags,
			}));
		}
	};

	const handleDeleteTag = (i) => {
		let updatedTags = contact.tags.filter((props, index) => index !== i);
		setContact((prev) => ({
			...prev,
			tags: updatedTags,
		}));
	};

	return (
		<div className={classes.root} ref={paneRef}>
			<AppBar
				elevation={1}
				position='static'
				style={{ color: theme.palette.text.disabled, backgroundColor: 'unset' }}
			>
				<Tabs
					value={value}
					onChange={handleChangeTab}
					variant='fullWidth'
					aria-label='full width tabs example'
				>
					<Tab className={classes.tabs} label='Profile' {...a11yProps(0)} />
					<Tab
						className={classes.tabs}
						label='Transactions'
						{...a11yProps(1)}
					/>
					{/* <Tab className={classes.tabs} label='Activities' {...a11yProps(2)} /> */}
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0} dir={theme.direction}>
				<Box style={{ padding: 24 }} display='flex'>
					<Box style={{ margin: 'auto' }}>
						<Avatar style={{ width: 64, height: 64 }}>
							<ContactIcon
								style={{ fontSize: 32 }}
								colorcode={theme.palette.primary.contrastText}
							/>
						</Avatar>
					</Box>
					<Box flexGrow={1} style={{ margin: 'auto 24px' }}>
						<>
							<Typography color='textPrimary' variant='h3' align='left'>
								{loading ? (
									<Skeleton variant='rect' animation='wave' />
								) : (
									<>
										{combineNames({
											fName: contact.firstName,
											mName:
												contact.middleName !== undefined
													? contact.middleName
													: null,
											lName:
												contact.lastName !== undefined
													? contact.lastName
													: null,
										})}
										{contact.dob ? `, ${getAge(contact.dob)}` : null}
									</>
								)}
							</Typography>
						</>
					</Box>
					<Box style={{ margin: 'auto' }}>
						<div>
							{editable ? (
								<Button
									variant='contained'
									color='primary'
									startIcon={
										<Save
											style={{ fontSize: 24 }}
											colorcode={theme.palette.primary.contrastText}
										/>
									}
									onClick={() => handleSave()}
								>
									Save
								</Button>
							) : (
								<Button
									variant='outlined'
									color='primary'
									startIcon={
										<Edit
											style={{ fontSize: 24 }}
											colorcode={theme.palette.tertiary.main}
										/>
									}
									onClick={() => setEditable(true)}
								>
									Edit Profile
								</Button>
							)}
						</div>
					</Box>
				</Box>
				<div
					className={
						isDesktopOrLaptop ? classes.tabArea : classes.tabAreaMobile
					}
				>
					<List disablePadding>
						<Divider />
						<ListItem
							classes={{
								root: classes.listItemRoot,
								button: classes.listItemButton,
							}}
							button
							onClick={() => handleExpand(0)}
						>
							<ListItemText
								primary='Personal Information'
								primaryTypographyProps={{
									style: { textTransform: 'uppercase', fontWeight: 600 },
								}}
							/>
							{open0 ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse
							in={open0}
							timeout='auto'
							unmountOnExit
							style={{ padding: 24 }}
						>
							<PersonalInfo
								loading={loading}
								contact={contact}
								editable={editable}
								handleInputChange={handleInputChange}
								handleDateChange={handleDateChange}
								errorMessages={errorMessages}
							/>
						</Collapse>
						<Divider />
						<ListItem
							classes={{
								root: classes.listItemRoot,
								button: classes.listItemButton,
							}}
							button
							onClick={() => handleExpand(1)}
						>
							<ListItemText
								style={{ flex: 'unset', marginRight: 8 }}
								primary='Associated Companies'
								primaryTypographyProps={{
									style: { textTransform: 'uppercase', fontWeight: 600 },
								}}
							/>
							<Tooltip
								content={
									<Typography variant='body1' color='textPrimary'>
										An Associated Company is a Company that your Contact is a
										part of.
									</Typography>
								}
							/>
							{open1 ? (
								<ExpandLess style={{ marginLeft: 'auto' }} />
							) : (
								<ExpandMore style={{ marginLeft: 'auto' }} />
							)}
						</ListItem>
						<Collapse in={open1} timeout='auto' unmountOnExit>
							<Companies
								paneRef={paneRef.current}
								loading={loading}
								contactId={contact._id}
								associatedCompanies={contact.associatedCompanies}
								setTriggerListUpdate={setTriggerListUpdate}
								togglePaneUpdate={togglePaneUpdate}
								editable={editable}
							/>
						</Collapse>
						<Divider />
						<ListItem
							classes={{
								root: classes.listItemRoot,
								button: classes.listItemButton,
							}}
							button
							onClick={() => handleExpand(2)}
						>
							<ListItemText
								primary='Relationship'
								primaryTypographyProps={{
									style: { textTransform: 'uppercase', fontWeight: 600 },
								}}
							/>
							{open2 ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse
							in={open2}
							timeout='auto'
							unmountOnExit
							style={{ padding: 24 }}
						>
							<RelationshipInfo
								paneRef={paneRef.current}
								loading={loading}
								contact={contact}
								setContact={setContact}
								editable={editable}
								handleInputChange={handleInputChange}
								handleDeleteTag={handleDeleteTag}
								handleAddTag={handleAddTag}
								errorMessages={errorMessages}
							/>
						</Collapse>
					</List>
				</div>
			</TabPanel>
			<TabPanel value={value} index={1} dir={theme.direction}>
				<Transactions
					loading={loading}
					id={contactId}
					ongoingTransactions={ongoingTransactions}
					completedTransactions={completedTransactions}
					clientName={contact.firstName}
				/>
			</TabPanel>
		</div>
	);
}
