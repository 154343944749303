import Joi from 'joi';

const CloseTransaction = Joi.object({
	policyNo: Joi.string()
		.label('Policy Number')
		.trim()
		.min(1)
		.max(50)
		.required(),
	commission: Joi.number()
		.label("Agent's Commission")
		.min(0)
		.precision(2)
		.required(),
}).unknown(true);

export default CloseTransaction;
