import Joi from 'joi';

const Recommendation = Joi.object({
	recommendationRemarks: Joi.string()
		.label('Recommendation Remarks')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default Recommendation;
