const PlansData = [
	{
		name: 'Budding Agent',
		enabled: true,
		recommended: false,
		priceMthId: process.env.REACT_APP_BUDDING_PRICEMTH,
		priceMth: 38,
		priceYrId: process.env.REACT_APP_BUDDING_PRICEYR,
		priceYr: 360,
		heading: 'Starter',
		subHeading:
			'Basic tools to get you started - good for new agents just getting into the trade',
		featureList: [
			{
				label: 'Customer Relationship Management',
				available: true,
			},
			{
				label: 'General Insurance Transaction Workflow',
				available: true,
			},
			{
				label: 'Notifications, Activities Tracking',
				available: true,
			},
			{
				label: 'Access to Client Success Support',
				available: true,
			},
			{
				label: 'Schedule Meetings',
				available: true,
			},
		],
		// addons: ['Invoice Management @ S$5 per month'],
	},
	{
		name: 'Aspiring Agent',
		enabled: false,
		recommended: false,
		priceMthId: process.env.REACT_APP_ASPIRING_PRICEMTH,
		priceMth: 55,
		priceYrId: process.env.REACT_APP_ASPIRING_PRICEYR,
		priceYr: 660,
		heading: 'Standard',
		subHeading:
			'Essential tools to hone your craft to the next level - great for agents that needs an organized system to manage their increased volume of clients and transactions',
		featureList: [
			{
				label: 'Includes Budding Agent Features',
				available: true,
			},
			{
				label: 'Client Relationship Intelligence',
				available: true,
			},
			{
				label: 'Reporting Tools',
				available: true,
			},
		],
		// addons: ['Invoice Management @ S$5 per month'],
	},
	{
		name: 'Manager',
		enabled: false,
		recommended: false,
		priceMthId: process.env.REACT_APP_MANAGER_PRICEMTH,
		priceMth: 72,
		priceYrId: process.env.REACT_APP_MANAGER_PRICEYR,
		priceYr: 864,
		heading: 'Team',
		subHeading: 'Grow your Team!',
		featureList: [
			{
				label: 'Includes Aspiring Agent Features for 2 - 5 Users',
				available: true,
			},
			{
				label:
					'Track Performance & Activities of your Agents or Associates within your Team',
				available: true,
			},
			{
				label: 'Collaborative tools for Team Management & Communication',
				available: true,
			},
		],
		// addons: ['Invoice Management @ S$5 per month'],
	},
	{
		name: 'Business',
		enabled: false,
		recommended: false,
		priceMth: 'X',
		priceYr: 'X',
		heading: 'Enterprise Agency',
		subHeading:
			'Premium, customizable functionality to scale and complete your organizational needs!',
		featureList: [
			{
				label: 'Includes Manager Features',
				available: true,
			},
			{
				label: 'API & Application Integration',
				available: true,
			},
			{
				label: 'Premium Support Packages',
				available: true,
			},
		],
		// addons: ['Invoice Management @ S$5 per month'],
	},
];

export default PlansData;
