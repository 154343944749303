import { intersectionWith, unionWith, isEqual } from 'lodash';

const filterColumn = (filter, data) => {
	let filteredColumn = [];
	if (filter.type === 'string') {
		switch (filter.operator) {
			case 'contains': {
				return (filteredColumn = data.filter((item) =>
					item[filter.column].toLowerCase().includes(filter.value.toLowerCase())
				));
			}
			case 'equals': {
				return (filteredColumn = data.filter(
					(item) =>
						item[filter.column].toLowerCase() === filter.value.toLowerCase()
				));
			}
			case 'is empty': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column].length === 0
				));
			}
			case 'is not empty': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column].length > 0
				));
			}
		}
	} else if (filter.type === 'object') {
		switch (filter.operator) {
			case 'contains': {
				return (filteredColumn = data.filter((item) => {
					return Object.keys(item[filter.column]).some((key) => {
						if (
							item[filter.column][key] !== undefined &&
							item[filter.column][key] !== null
						) {
							return item[filter.column][key]
								.toLowerCase()
								.includes(filter.value.toLowerCase());
						}
					});
				}));
			}
			case 'equals': {
				return (filteredColumn = data.filter((item) => {
					return Object.keys(item[filter.column]).some((key) => {
						if (
							item[filter.column][key] !== undefined &&
							item[filter.column][key] !== null
						) {
							return (
								item[filter.column][key].toLowerCase() ===
								filter.value.toLowerCase()
							);
						}
					});
				}));
			}
			case 'is empty': {
				return (filteredColumn = data.filter((item) => {
					return Object.keys(item[filter.column]).some((key) => {
						if (
							item[filter.column][key] !== undefined &&
							item[filter.column][key] !== null
						) {
							return item[filter.column][key].length === 0;
						}
					});
				}));
			}
			case 'is not empty': {
				return (filteredColumn = data.filter((item) => {
					return Object.keys(item[filter.column]).some((key) => {
						if (
							item[filter.column][key] !== undefined &&
							item[filter.column][key] !== null
						) {
							return item[filter.column][key].length > 0;
						}
					});
				}));
			}
		}
	} else if (filter.type === 'array') {
		switch (filter.operator) {
			case 'contains': {
				return (filteredColumn = data.filter((item) => {
					if (filter.column === 'tags') {
						return item[filter.column].some((val) =>
							val.label.toLowerCase().includes(filter.value.toLowerCase())
						);
					}
					return item[filter.column].some((val) =>
						val.toLowerCase().includes(filter.value.toLowerCase())
					);
				}));
			}
			case 'is empty': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column].length === 0
				));
			}
			case 'is not empty': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column].length > 0
				));
			}
		}
	} else {
		switch (filter.operator) {
			case '=': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column] == filter.value
				));
			}
			case '!=': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column] != filter.value
				));
			}
			case '>': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column] > filter.value
				));
			}
			case '>=': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column] >= filter.value
				));
			}
			case '<': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column] < filter.value
				));
			}
			case '<=': {
				return (filteredColumn = data.filter(
					(item) => item[filter.column] <= filter.value
				));
			}
			case 'is empty': {
				return (filteredColumn = data.filter(
					(item) =>
						item[filter.column] === undefined || item[filter.column] === null
				));
			}
			case 'is not empty': {
				return (filteredColumn = data.filter(
					(item) =>
						item[filter.column] !== undefined || item[filter.column] !== null
				));
			}
		}
	}
};

const filterData = (data, filters) => {
	let filteredData = [...data];
	let filterList = filters.filter((filter) => {
		if (filter.operator === 'is empty' || filter.operator === 'is not empty') {
			return filter;
		}
		return filter.value;
	});

	if (filterList.length > 0) {
		let processor = filterList[0].processor;
		let dataSets = filterList.map((filter) => {
			return filterColumn(filter, filteredData);
		});

		if (processor === 'And') {
			filteredData = intersectionWith(...dataSets, isEqual);
		}
		if (processor === 'Or') {
			filteredData = unionWith(...dataSets, isEqual);
		}
	}
	return filteredData;
};

export { filterData };
