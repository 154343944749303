import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import CustomTextField from '../../../../components/CustomTextField';
import {
	claimFields,
	companyFields,
	contactFields,
	policyFields,
} from '../../../../components/Data/fieldList.json';
import ChecklistData from '../../../../components/Data/suggestedChecklist.json';
import DataSearch from '../../../../components/DataSearch';
import FileInfoCard from '../../../../components/FileInfoCard';
import FileUploader from '../../../../components/FileUploader';
import SuggestedChecklist from '../../../../components/Modals/checklist';
import Tooltip from '../../../../components/Tooltip';
import api from '../../../../config/api';
import DocumentRow from './DocumentRow';
import QuotationCard from './QuotationCard';
import {
	ChecklistDocument,
	Company,
	Contacts,
	Documents,
} from '../../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.secondary.light,
		padding: 24,
		height: '100%',
	},
	listItem: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		background: theme.palette.grey[300],
		padding: 16,
		borderRadius: '8px 0px 0px 8px',
	},
	selected: {
		'&.Mui-selected': {
			backgroundColor: `${theme.palette.background.secondary} !important`,
		},
	},
	badge: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		'&:not(:first-child)': {
			marginTop: 8,
		},
	},
	badgeIconEmpty: {
		display: 'none',
	},
	badgeIconComplete: {
		color: theme.palette.success.main,
		backgroundColor: theme.palette.grey[50],
		padding: 'unset',
	},
	badgeIconError: {
		color: theme.palette.error.main,
		backgroundColor: theme.palette.grey[50],
		padding: 'unset',
	},
	documentRow: {
		'&:not(:first-child)': {
			marginTop: 8,
		},
	},
}));

const initDocFields = { name: '' };

const str2bool = (value) => {
	if (value && typeof value === 'string') {
		if (value.toLowerCase() === 'true') return true;
		if (value.toLowerCase() === 'false') return false;
	}
	return value;
};

const claimRejReasonList = ['Not applicable for claim', 'Other'];

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';
	let vidBase = 'Video';
	let vidSize = '- size must not exceed 50mb';
	let vidReq = '- .avi, .mp4, .mpeg, .webm';
	let imageBase = 'Image';
	let imageSize = '- size must not exceed 50mb';
	let imageReq = '- .jpg, .jpeg, .png, .bmp';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{vidBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${vidSize}\n${vidReq}`}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{imageBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${imageSize}\n${imageReq}`}
			</Typography>
			{/* {`${base}\n\n${docBase}\n${docSize}\n${docReq}\n
			${vidBase}\n${vidSize}\n${vidReq}\n
			${imageBase}\n${imageSize}\n${imageReq}\n`} */}
		</div>
	);
};

const typeList = ['Document', 'Photo', 'Video'];

const NavList = [
	{
		icon: <Company />,
		label: 'COMPANY INFORMATION',
	},
	{
		icon: <Contacts />,
		label: 'PERSONAL INFORMATION',
	},
	{
		icon: <Documents />,
		label: 'SUPPORTING DOCUMENTS',
	},
	{
		icon: <ChecklistDocument />,
		label: 'QUOTATIONS',
	},
	{
		icon: <ChecklistDocument />,
		label: 'CLAIM DETAILS',
	},
	{
		icon: <ChecklistDocument />,
		label: 'POLICY DETAILS',
	},
	{
		icon: <ChecklistDocument />,
		label: 'PREVIOUS POLICY DETAILS',
	},
];

const CustomListItem = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const {
		index,
		icon,
		label,
		tabIndex,
		isError,
		isCompleted,
		handleChangeTab,
	} = props;

	return (
		<Badge
			className={classes.badge}
			classes={{
				colorPrimary: isCompleted
					? classes.badgeIconComplete
					: classes.badgeIconEmpty,
				colorError: isError && classes.badgeIconError,
			}}
			badgeContent={
				<>
					{isError && <ErrorIcon style={{ height: 24, width: 24 }} />}
					{isCompleted && <CheckCircleIcon style={{ height: 24, width: 24 }} />}
				</>
			}
			color={isError ? 'error' : 'primary'}
			overlap='rectangular'
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			<ListItem
				className={classes.listItem}
				classes={{ selected: classes.selected }}
				button
				selected={tabIndex === index}
				onClick={() => handleChangeTab(index)}
			>
				<ListItemIcon
					style={{
						color:
							tabIndex === index
								? theme.palette.tertiary.main
								: theme.palette.grey[500],
						minWidth: 'unset',
						fontSize: 24,
					}}
				>
					{React.cloneElement(icon, {
						colorcode:
							tabIndex === index
								? theme.palette.tertiary.main
								: theme.palette.grey[500],
					})}
				</ListItemIcon>
				<ListItemText
					primary={label}
					primaryTypographyProps={{
						style: {
							color:
								tabIndex === index
									? theme.palette.tertiary.main
									: theme.palette.grey[500],
							fontWeight: tabIndex === index ? 600 : 400,
							fontSize: '0.875rem',
						},
					}}
				/>
			</ListItem>
		</Badge>
	);
};

export default function TransactionForm(props) {
	const classes = useStyles();
	const theme = useTheme();
	const inputRef = useRef([]);
	const handler = (idx) => (e) => {
		const next = inputRef.current[idx + 1];
		if (next) {
			next.focus();
		}
	};

	const {
		selectedClient,
		formData,
		handleFormDataChange,
		errorMessages,
		setErrorMessages,
		errorTabs,
		completedTabs,
		resetDocs,
	} = props;

	const [checklistOpen, setChecklistOpen] = useState(false);
	const [checklist, setChecklist] = useState([]);
	const [quotationData, setQuotationData] = useState(formData.quotationData);
	const [docFields, setDocFields] = useState(initDocFields);
	const [tabIndex, setTabIndex] = useState(
		formData.selectedInsuranceType.isCommercial ? 0 : 1
	);
	const [data, setData] = useState(props.data);
	const [errorTab, setErrorTab] = useState([true, false, false]);
	const [error, setError] = useState(false);

	const [schema, setSchema] = useState({});
	const [existingContact, setExistingContact] = useState(false);
	const [existingCompany, setExistingCompany] = useState(false);
	const [documents, setDocuments] = useState(formData.supportingDocuments);
	const [errorMessage, setErrorMessage] = useState('');
	const [changeContact, setChangeContact] = useState(false);
	const [loading, setLoading] = useState(false);
	const [optionData, setOptionData] = useState([]);
	const [val, setVal] = useState(null);

	useEffect(() => {
		let checklistItems = [];

		ChecklistData.find((list) => {
			if (list.name === formData.selectedInsuranceType.insuranceType) {
				if (formData.selectedInsuranceType.insuranceSubType !== '') {
					let index = list.subTypes.findIndex((subType) => {
						return (
							subType.name === formData.selectedInsuranceType.insuranceSubType
						);
					});
					checklistItems = list.subTypes[index][formData.transactionType];
				} else {
					checklistItems = list[formData.transactionType];
				}
			}
		});

		setChecklist(checklistItems);
		if (documents.length === 0 && resetDocs) {
			let list = checklistItems.map((item) => {
				return { ...item, id: nanoid() };
			});
			setDocuments(list);
		}
	}, []);

	useEffect(() => {
		handleFormDataChange('supportingDocuments', documents);
	}, [documents]);

	useEffect(() => {
		handleFormDataChange('quotationData', quotationData);
	}, [quotationData]);

	useEffect(() => {
		const getPrincipalList = () => {
			if (!loading) {
				setLoading(true);
			}
			api
				.get('principals/list/active')
				.then((res) => {
					setOptionData(res.data.data);
					if (formData.isSelfGenerated && quotationData.length < 1) {
						let quotationList = res.data.data.map((principal, index) => {
							return {
								id: nanoid(),
								quoteName: `Quote 1`,
								principalId: principal._id,
								principalName: principal.shortName,
								expiryDate: null,
								startDate: null,
								endDate: null,
								premiumAmt: null,
								sumInsured: null,
								notes: '',
								fileData: null,
								recommended: false,
								recommendationRemarks: '',
								open: index === 0 ? true : false,
							};
						});
						setQuotationData(quotationList);
					}
					setLoading(false);
				})
				.catch(function (error) {
					console.log(error);
				});
		};

		if (formData.isDirectPurchase || formData.isSelfGenerated) {
			getPrincipalList();
		}
	}, [formData.isDirectPurchase, formData.isSelfGenerated]);

	const handleChangeTab = (newValue) => {
		setTabIndex(newValue);
	};

	const handleExistingChange = (e, type) => {
		let val = str2bool(e.target.value);
		if (type === 'contact') {
			let updatedForm = {
				existing: val,
				_id: '',
				firstName: '',
				middleName: '',
				lastName: '',
				dob: null,
				idNo: '',
				nationality: '',
				maritalStatus: '',
				gender: '',
				email: '',
				mobileNo: '',
				homeNo: '',
				address: '',
				unitNo: '',
				postalCode: '',
			};
			handleFormDataChange('contactData', updatedForm);
		}
		if (type === 'company') {
			let updatedCompanyForm = {
				existing: val,
				_id: '',
				companyName: '',
				uen: '',
				website: '',

				establishDate: null,
				industryName: '',
				companyEmail: '',
				officeNo: '',
				faxNo: '',
				companyAddress: '',
				companyUnitNo: '',
				companyPostalCode: '',
			};
			if (!selectedClient) {
				let updatedContactForm = {
					existing: false,
					_id: '',
					firstName: '',
					middleName: '',
					lastName: '',
					dob: null,
					idNo: '',
					nationality: '',
					maritalStatus: '',
					gender: '',
					email: '',
					mobileNo: '',
					homeNo: '',
					address: '',
					unitNo: '',
					postalCode: '',
				};
				handleFormDataChange('contactData', updatedContactForm);
			}
			handleFormDataChange('companyData', updatedCompanyForm);
		}
	};

	const handleSelectRecord = (record, type) => {
		if (type === 'contact') {
			let updatedForm = formData.contactData;
			let mergedForm = Object.assign(updatedForm, record);
			mergedForm._id = record._id;
			handleFormDataChange('contactData', mergedForm);
		}
		if (type === 'company') {
			let updatedForm = formData.companyData;
			let mergedForm = Object.assign(updatedForm, record);
			mergedForm._id = record._id;

			if (!selectedClient) {
				let updatedContactForm = {
					existing: false,
					_id: '',
					firstName: '',
					middleName: '',
					lastName: '',
					dob: null,
					idNo: '',
					nationality: '',
					maritalStatus: '',
					gender: '',
					email: '',
					mobileNo: '',
					homeNo: '',
					address: '',
					unitNo: '',
					postalCode: '',
				};
				handleFormDataChange('contactData', updatedContactForm);
			}
			handleFormDataChange('companyData', mergedForm);
		}
	};

	const handleChangeContact = (e) => {
		let val = str2bool(e.target.value);
		setChangeContact(val);
	};

	const handleContactDataInputChange = (e) => {
		const {
			target: { value, name },
		} = e;
		let updatedForm = formData.contactData;
		updatedForm[name] = value;
		handleFormDataChange('contactData', updatedForm);
	};

	const handleContactDataDateChange = (target, date) => {
		let updatedForm = formData.contactData;
		let updatedDate = date;

		if (target === 'endDate') {
			updatedDate = new Date(date);
			updatedDate.setHours(23, 59, 59, 999); // Set to end of the day
		}

		updatedForm[target] = updatedDate;
		handleFormDataChange('contactData', updatedForm);
	};

	const handleCompanyDataInputChange = (e) => {
		const {
			target: { value, name },
		} = e;
		let updatedForm = formData.companyData;
		updatedForm[name] = value;
		handleFormDataChange('companyData', updatedForm);
	};

	const handleCompanyDataDateChange = (target, date) => {
		let updatedForm = formData.companyData;
		let updatedDate = date;

		if (target === 'endDate') {
			updatedDate = new Date(date);
			updatedDate.setHours(23, 59, 59, 999); // Set to end of the day
		}

		updatedForm[target] = updatedDate;
		handleFormDataChange('companyData', updatedForm);
	};

	const handleAddQuotation = () => {
		let updatedQuotationData = [...quotationData];
		let newQuotation = {
			id: nanoid(),
			quoteName: `Quote ${quotationData.length + 1}`,
			principalId: '',
			principalName: '',
			expiryDate: null,
			startDate: null,
			endDate: null,
			premiumAmt: null,
			sumInsured: null,
			notes: '',
			fileData: null,
			recommended: false,
			recommendationRemarks: '',
			open: true,
		};
		updatedQuotationData.push(newQuotation);
		setQuotationData(updatedQuotationData);
	};

	const handleClaimDataResponseChange = () => {
		let updatedForm = formData.claimData;
		if (!updatedForm.accepted) {
			updatedForm.accepted = true;
		} else {
			updatedForm.accepted = false;
		}
		updatedForm.claimAmt = null;
		updatedForm.incidentDate = null;
		updatedForm.insurerNotes = '';
		updatedForm.fileData = null;
		updatedForm.rejReason = '';
		updatedForm.rejRemarks = '';
		handleFormDataChange('claimData', updatedForm);
	};

	const handleClaimDataInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;
		let updatedForm = formData.claimData;
		if (name === 'principalId') {
			let val = optionData.find((principal) => principal._id === value);
			if (val) {
				updatedForm.principalId = val._id;
				updatedForm.principalName = val.shortName;
			} else {
				updatedForm.principalId = '';
				updatedForm.principalName = '';
			}
		} else {
			if (type === 'number' && value !== '') {
				updatedForm[name] = parseFloat(value);
			} else {
				updatedForm[name] = value;
			}
		}
		handleFormDataChange('claimData', updatedForm);
	};

	const handleClaimDataDateChange = (target, date) => {
		let updatedForm = formData.claimData;
		let updatedDate = date;

		if (target === 'endDate') {
			updatedDate = new Date(date);
			updatedDate.setHours(23, 59, 59, 999); // Set to end of the day
		}

		updatedForm[target] = updatedDate;
		handleFormDataChange('claimData', updatedForm);
	};

	const handlePolicyDataInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;
		let updatedForm = formData.policyData;
		if (name === 'principalId') {
			let val = optionData.find((principal) => principal._id === value);
			if (val) {
				updatedForm.principalId = val._id;
				updatedForm.principalName = val.shortName;
			} else {
				updatedForm.principalId = '';
				updatedForm.principalName = '';
			}
		} else {
			if (type === 'number' && value !== '') {
				updatedForm[name] = parseFloat(value);
			} else {
				updatedForm[name] = value;
			}
		}
		handleFormDataChange('policyData', updatedForm);
	};

	const handlePolicyDataDateChange = (target, date) => {
		let updatedForm = formData.policyData;
		let updatedDate = date;

		if (target === 'endDate') {
			updatedDate = new Date(date);
			updatedDate.setHours(23, 59, 59, 999); // Set to end of the day
		}

		updatedForm[target] = updatedDate;
		handleFormDataChange('policyData', updatedForm);
	};

	const handlePrevPolicyDataInputChange = (e) => {
		const {
			target: { value, type, name },
		} = e;
		let updatedForm = formData.prevPolicyData;
		if (name === 'principalId') {
			let val = optionData.find((principal) => principal._id === value);
			if (val) {
				updatedForm.principalId = val._id;
				updatedForm.principalName = val.shortName;
			} else {
				updatedForm.principalId = '';
				updatedForm.principalName = '';
			}
		} else {
			if (type === 'number' && value !== '') {
				updatedForm[name] = parseFloat(value);
			} else {
				updatedForm[name] = value;
			}
		}
		handleFormDataChange('prevPolicyData', updatedForm);
	};

	const handlePrevPolicyDataDateChange = (target, date) => {
		let updatedForm = formData.prevPolicyData;
		let updatedDate = date;

		if (target === 'endDate') {
			updatedDate = new Date(date);
			updatedDate.setHours(23, 59, 59, 999); // Set to end of the day
		}

		updatedForm[target] = updatedDate;
		handleFormDataChange('prevPolicyData', updatedForm);
	};

	const handleDocFieldsChange = (e) => {
		const {
			target: { value, name },
		} = e;

		setDocFields((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleAddDocument = () => {
		let updatedDocuments = [...documents];
		let newDocument = {
			name: docFields.name,

			fileData: null,
			id: nanoid(),
		};
		updatedDocuments.push(newDocument);
		setDocuments(updatedDocuments);
		setDocFields(initDocFields);
	};

	const handleRemoveDocument = (id) => {
		let updatedDocuments = [...documents].filter((el) => {
			return el.id !== id;
		});
		setDocuments(updatedDocuments);
	};

	const handleDocumentChange = (id, value) => {
		let updatedDocuments = [...documents];
		let updatedIndex = updatedDocuments.findIndex((doc) => doc.id === id);
		updatedDocuments[updatedIndex].fileData = value;
		setDocuments(updatedDocuments);
	};

	const handleProposalFormChange = (e, files) => {
		e.preventDefault();
		let updatedProposalForm = formData.proposalForm;
		if (files.length > 0) {
			if (files.length > 1) {
				setErrorMessages((prev) => ({
					...prev,
					proposalForm: `Only 1 file is permitted`,
				}));
			} else {
				let result = validateFile(files[0]);
				if (result.valid) {
					setErrorMessages((prev) => {
						const { proposalForm, ...errorMessages } = prev;
						return errorMessages;
					});
					updatedProposalForm.fileData = files[0];
				} else {
					setErrorMessages((prev) => ({
						...prev,
						proposalForm: result.errorMessage,
					}));
					updatedProposalForm.fileData = null;
				}
			}
		} else {
			updatedProposalForm.fileData = null;
		}
		handleFormDataChange('proposalForm', updatedProposalForm);
	};

	const validateFile = (file) => {
		let valid = true;
		let errorMessage = '';
		let validTypes = [
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.ms-excel',
			'text/csv',
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/pdf',
			'text/plain',
		];

		if (validTypes.indexOf(file.type) === -1) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (!file.name.match(/.(xls|xlsx|xlsm|csv|doc|docx|pdf|txt|)$/i)) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (file.size > 52428800) {
			valid = false;
			errorMessage = 'File size cannot exceed 50mb';
		}

		return {
			valid: valid,
			errorMessage: errorMessage,
		};
	};

	const handleClaimDocumentChange = (e, files) => {
		e.preventDefault();
		let updatedClaimData = formData.claimData;
		if (files.length > 0) {
			updatedClaimData.fileData = files;
		} else {
			updatedClaimData.fileData = null;
		}
		handleFormDataChange('claimData', updatedClaimData);
	};

	const handlePolicyDocumentChange = (e, files) => {
		e.preventDefault();
		let updatedPolicyData = formData.policyData;
		if (files.length > 0) {
			updatedPolicyData.fileData = files;
		} else {
			updatedPolicyData.fileData = null;
		}
		handleFormDataChange('policyData', updatedPolicyData);
	};

	const handlePrevPolicyDocumentChange = (e, files) => {
		e.preventDefault();
		let updatedPrevPolicyData = formData.prevPolicyData;
		if (files.length > 0) {
			updatedPrevPolicyData.fileData = files;
		} else {
			updatedPrevPolicyData.fileData = null;
		}
		handleFormDataChange('prevPolicyData', updatedPrevPolicyData);
	};

	const fileSize = (size) => {
		if (size === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const i = Math.floor(Math.log(size) / Math.log(k));
		return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
	};

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	const checkExisting = () => {
		if (formData.source === 'External') {
			return false;
		}
		if (formData.isDirectPurchase) {
			return false;
		}

		return true;
	};

	const getFieldSize = (field) => {
		let largeFields = ['notes', 'remarks'];
		if (largeFields.some((obj) => field.toLowerCase().includes(obj))) {
			return 12;
		} else {
			return 4;
		}
	};

	const sortQuotations = (quotationData) => {
		if (!quotationData) {
			return [];
		} else {
			return quotationData.sort((a, b) => {
				const nameComparison = a.principalName.localeCompare(b.principalName);
				if (nameComparison !== 0) {
					return nameComparison;
				}
				return a.quoteName.localeCompare(b.quoteName);
			});
		}
	};

	const getTabContent = (tabIndex) => {
		switch (tabIndex) {
			case 0:
				return (
					<div style={{ position: 'relative' }}>
						{!formData.selectedTransaction && (
							<>
								<Typography variant='body1' color='textPrimary'>
									The following company information to be entered is for a/an:
								</Typography>
								<FormControl
									component='fieldset'
									style={{ width: '50%', marginBottom: 24 }}
								>
									<RadioGroup
										aria-label='existingCompany'
										name='existing'
										value={formData.companyData.existing}
										onChange={(e) => handleExistingChange(e, 'company')}
									>
										<FormControlLabel
											value={false}
											control={<Radio color='primary' />}
											label='New Company'
										/>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<FormControlLabel
												style={{ width: '50%' }}
												value={true}
												control={<Radio color='primary' />}
												label='Existing Company'
											/>
											{formData.companyData.existing && (
												<>
													{formData.contactData.existing ? (
														<Autocomplete
															id='data-search'
															style={{ width: '100%', margin: 0 }}
															value={val}
															onChange={(e, newValue) => {
																handleSelectRecord(newValue.value, 'company');
															}}
															options={
																formData.contactData.associatedCompanies
																	? formData.contactData.associatedCompanies
																	: []
															}
															noOptionsText='No Associated Companies Found'
															getOptionSelected={(option, value) =>
																option.value._id === value._id
															}
															getOptionLabel={(option) => option.label || ''}
															renderInput={(params) => (
																<TextField
																	{...params}
																	variant='filled'
																	placeholder='Search or Select a Company'
																	error={!!errorMessages[`companyData`]}
																	helperText={
																		errorMessages[`companyData`] &&
																		errorMessages[`companyData`]
																	}
																/>
															)}
														/>
													) : (
														<div style={{ width: '100%', margin: 0 }}>
															<DataSearch
																type='company'
																handleSelectRecord={handleSelectRecord}
																errorMessage={
																	errorMessages[`companyData`] &&
																	errorMessages[`companyData`]
																}
															/>
														</div>
													)}
												</>
											)}
										</div>
									</RadioGroup>
								</FormControl>
							</>
						)}
						<Grid container spacing={4}>
							{companyFields.map((field, index) =>
								field.type === 'block' ? (
									<Grid key={index} item xs={4} />
								) : (
									<Grid key={index} item xs={4}>
										<CustomTextField
											existing={formData.companyData.existing}
											type={field.type}
											isNumber={field.isNumber}
											isCurrency={field.isCurrency}
											fieldName={field.name}
											value={formData.companyData[field.name] ?? ''}
											label={field.label}
											required={
												!formData.selectedInsuranceType.isCommercial
													? false
													: field.required
											}
											tooltipContent={
												field.tooltipContent && field.tooltipContent
											}
											disableFuture={field.disableFuture && field.disableFuture}
											handleChange={
												field.type === 'date'
													? handleCompanyDataDateChange
													: handleCompanyDataInputChange
											}
											errorMessage={errorMessages[`companyData.${field.name}`]}
										/>
									</Grid>
								)
							)}
						</Grid>
					</div>
				);
			case 1:
				return (
					<div style={{ position: 'relative' }}>
						{!formData.selectedTransaction ? (
							<>
								<Typography variant='body1' color='textPrimary'>
									The following personal information to be entered is for a/an:
								</Typography>
								<FormControl
									component='fieldset'
									style={{ width: '50%', marginBottom: 24 }}
								>
									<RadioGroup
										aria-label='existingContact'
										name='existing'
										value={formData.contactData.existing}
										onChange={(e) => handleExistingChange(e, 'contact')}
									>
										<FormControlLabel
											value={false}
											control={<Radio color='primary' />}
											label='New Contact'
										/>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<FormControlLabel
												style={{ width: '50%' }}
												value={true}
												control={<Radio color='primary' />}
												label='Existing Contact'
											/>
											{formData.contactData.existing && (
												<>
													{formData.companyData.existing ? (
														<Autocomplete
															id='data-search'
															style={{ width: '100%', margin: 0 }}
															value={val}
															onChange={(e, newValue) => {
																handleSelectRecord(newValue.value, 'contact');
															}}
															options={
																formData.companyData.associatedContacts
																	? formData.companyData.associatedContacts
																	: []
															}
															noOptionsText='No Associated Contacts Found'
															getOptionSelected={(option, value) =>
																option.value._id === value._id
															}
															getOptionLabel={(option) => option.label || ''}
															renderInput={(params) => (
																<TextField
																	{...params}
																	variant='filled'
																	placeholder='Search or Select a Contact'
																	error={!!errorMessages[`contactData`]}
																	helperText={
																		errorMessages[`contactData`] &&
																		errorMessages[`contactData`]
																	}
																/>
															)}
														/>
													) : (
														<div style={{ width: '100%', margin: 0 }}>
															<DataSearch
																type='contact'
																handleSelectRecord={handleSelectRecord}
																errorMessage={
																	errorMessages[`contactData`] &&
																	errorMessages[`contactData`]
																}
															/>
														</div>
													)}
												</>
											)}
										</div>
									</RadioGroup>
								</FormControl>
							</>
						) : (
							<>
								{formData.selectedInsuranceType.isCommercial ? (
									<>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												marginBottom: 8,
											}}
										>
											<Typography
												style={{ marginRight: 32 }}
												variant='body2'
												color='textPrimary'
											>
												{`Has the Contact changed for this ${formData.transactionType}?`}
											</Typography>
											<FormControl component='fieldset'>
												<RadioGroup
													style={{ flexDirection: 'row' }}
													aria-label='changeContact'
													name='changeContact'
													value={changeContact}
													onChange={handleChangeContact}
												>
													<FormControlLabel
														value={true}
														control={<Radio color='primary' />}
														label='Yes'
													/>
													<FormControlLabel
														value={false}
														control={<Radio color='primary' />}
														label='No'
													/>
												</RadioGroup>
											</FormControl>
										</div>
										{changeContact && (
											<FormControl
												component='fieldset'
												style={{ width: '50%', marginBottom: 24 }}
											>
												<RadioGroup
													aria-label='existingContact'
													name='existing'
													value={formData.contactData.existing}
													onChange={(e) => handleExistingChange(e, 'contact')}
												>
													<FormControlLabel
														value={false}
														control={<Radio color='primary' />}
														label='New Contact'
													/>
													<div
														style={{ display: 'flex', alignItems: 'center' }}
													>
														<FormControlLabel
															style={{ width: '50%' }}
															value={true}
															control={<Radio color='primary' />}
															label='Existing Contact'
														/>
														{formData.contactData.existing && (
															<>
																{formData.companyData.existing ? (
																	<Autocomplete
																		id='data-search'
																		style={{ width: '100%', margin: 0 }}
																		value={val}
																		onChange={(e, newValue) => {
																			handleSelectRecord(
																				newValue.value,
																				'contact'
																			);
																		}}
																		options={
																			formData.companyData.associatedContacts
																				? formData.companyData
																						.associatedContacts
																				: []
																		}
																		noOptionsText='No Associated Contacts Found'
																		getOptionSelected={(option, value) =>
																			option.value._id === value._id
																		}
																		getOptionLabel={(option) =>
																			option.label || ''
																		}
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				variant='filled'
																				placeholder='Search or Select a Contact'
																				error={!!errorMessages[`contactData`]}
																				helperText={
																					errorMessages[`contactData`] &&
																					errorMessages[`contactData`]
																				}
																			/>
																		)}
																	/>
																) : (
																	<div style={{ width: '100%', margin: 0 }}>
																		<DataSearch
																			type='contact'
																			handleSelectRecord={handleSelectRecord}
																			errorMessage={
																				errorMessages[`contactData`] &&
																				errorMessages[`contactData`]
																			}
																		/>
																	</div>
																)}
															</>
														)}
													</div>
												</RadioGroup>
											</FormControl>
										)}
									</>
								) : null}
							</>
						)}
						<Grid container spacing={4}>
							{contactFields.map((field, index) =>
								field.type === 'block' ? (
									<Grid key={index} item xs={4} />
								) : (
									<Grid key={index} item xs={4}>
										<CustomTextField
											existing={formData.contactData.existing}
											type={field.type}
											isNumber={field.isNumber}
											isCurrency={field.isCurrency}
											fieldName={field.name}
											value={formData.contactData[field.name] ?? ''}
											label={field.label}
											required={field.required}
											tooltipContent={
												field.tooltipContent && field.tooltipContent
											}
											disableFuture={field.disableFuture && field.disableFuture}
											handleChange={
												field.type === 'date'
													? handleContactDataDateChange
													: handleContactDataInputChange
											}
											errorMessage={errorMessages[`contactData.${field.name}`]}
										/>
									</Grid>
								)
							)}
						</Grid>
					</div>
				);
			case 2:
				return (
					<div style={{ paddingRight: '5%' }}>
						<Typography variant='body1' color='textPrimary'>
							Kindly upload the list of documents below to be processed for your
							application.
						</Typography>
						<Typography variant='body1' color='textPrimary'>
							If you have additional documents to upload, enter the document
							details and click on ‘Add to List’.
						</Typography>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								backgroundColor: theme.palette.info.main,
								height: 56,
								padding: 16,
								margin: '24px 0px',
							}}
						>
							<InfoOutlinedIcon
								style={{ color: theme.palette.secondary.light }}
							/>
							<Typography
								style={{ color: theme.palette.secondary.light, marginLeft: 8 }}
								variant='body1'
							>
								This list of documents is not exhaustive and may be revised in
								future updates.
							</Typography>
						</div>
						<Grid container spacing={3} alignItems='flex-end'>
							<Grid item xs={3}>
								<Typography color='textPrimary' variant='body2'>
									Document Name
								</Typography>
								<TextField
									name='name'
									value={docFields.name}
									onChange={(e) => handleDocFieldsChange(e)}
									placeholder='Enter Document Name'
									variant='filled'
									fullWidth
									margin='dense'
								/>
							</Grid>
							<Grid item xs={2}>
								<Button
									style={{ marginTop: 8, marginBottom: 8 }}
									variant='contained'
									color='primary'
									disabled={!docFields.name}
									onClick={() => handleAddDocument()}
								>
									Add to List
								</Button>
							</Grid>
						</Grid>
						<div
							style={{ display: 'flex', alignItems: 'center', width: '100%' }}
						>
							<Button
								style={{ marginLeft: 'auto' }}
								color='primary'
								startIcon={<PlaylistAddCheckOutlinedIcon />}
								onClick={() => setChecklistOpen(true)}
							>
								View Suggested Checklist
							</Button>
						</div>
						<TableContainer
							style={{
								minHeight: 350,
								maxHeight: 350,
								boxShadow: '0px 1px 2px 0px #0000001a',
								borderRadius: 8,
								overflowWrap: 'anywhere',
							}}
						>
							<Table stickyHeader>
								<TableHead
									style={{ background: theme.palette.background.paper }}
								>
									<TableRow>
										{/* <TableCell style={{ background: theme.palette.background.paper }}>
											<Typography color='textPrimary' variant='h5'>
												DOCUMENT NAME
											</Typography>
										</TableCell> */}
										<TableCell
											style={{
												background: theme.palette.background.paper,
											}}
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Typography
													style={{ marginRight: 8 }}
													color='textPrimary'
													variant='h5'
												>
													DOCUMENT NAME
												</Typography>
												<Tooltip content={printReqString()} />
											</div>
										</TableCell>
										<TableCell
											style={{ background: theme.palette.background.paper }}
										></TableCell>
										<TableCell
											style={{ background: theme.palette.background.paper }}
										></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{documents.map((item, index) => (
										<DocumentRow
											key={item.id}
											item={item}
											handleRemoveDocument={handleRemoveDocument}
											handleDocumentChange={handleDocumentChange}
											formErrorMessage={
												errorMessages?.supportingDocuments?.[item.id] !==
													undefined &&
												errorMessages[`supportingDocuments`][item.id]
											}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				);
			case 3:
				return (
					<div style={{ position: 'relative' }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{sortQuotations(quotationData).map((quotation, index) => (
								<QuotationCard
									key={quotation.id}
									index={index}
									errorMessages={errorMessages}
									quotation={quotation}
									quotationData={quotationData}
									setQuotationData={setQuotationData}
									disableRemove={quotationData.length === 1}
									optionData={optionData}
								/>
							))}
							<Button
								style={{ alignSelf: 'flex-start' }}
								color='primary'
								startIcon={<AddCircleOutlineIcon />}
								onClick={handleAddQuotation}
							>
								Add Quotation
							</Button>
						</div>
					</div>
				);
			case 4:
				return (
					<div style={{ position: 'relative' }}>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Typography
									style={{ marginBottom: 8 }}
									color='textPrimary'
									variant='body2'
									align='left'
								>
									{`Was the Claim Request accepted by ${formData.prevPolicyData.principalName}?`}
								</Typography>
								<div className='switcher'>
									<div className='fieldset'>
										<input
											type='radio'
											name='accepted'
											value='true'
											id='accept'
											onClick={() => handleClaimDataResponseChange()}
											onChange={(e) => {}}
											checked={formData.claimData.accepted}
										/>
										<label htmlFor='accept'>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<CheckIcon style={{ marginRight: 6, width: 16 }} />
												Accept
											</div>
										</label>
										<input
											type='radio'
											name='accepted'
											value='false'
											id='decline'
											onClick={() => handleClaimDataResponseChange()}
											onChange={(e) => {}}
											checked={!formData.claimData.accepted}
										/>
										<label htmlFor='decline'>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<CloseIcon style={{ marginRight: 6, width: 16 }} />
												Decline
											</div>
										</label>
										<span className='cd-switch'></span>
									</div>
								</div>
							</Grid>
							{formData.claimData.accepted ? (
								<>
									{claimFields.map((field, index) =>
										field.type === 'block' ? (
											<Grid key={index} item xs={4} />
										) : (
											<Grid
												key={index}
												item
												xs={
													field.name === 'insurerNotes' ||
													field.name === 'clientNotes'
														? 12
														: 4
												}
											>
												<CustomTextField
													existing={false}
													type={field.type}
													isNumber={field.isNumber}
													isCurrency={field.isCurrency}
													fieldName={field.name}
													value={formData.claimData[field.name] ?? ''}
													label={field.label}
													required={field.required}
													tooltipContent={
														field.tooltipContent && field.tooltipContent
													}
													disableFuture={
														field.disableFuture && field.disableFuture
													}
													handleChange={
														field.type === 'date'
															? handleClaimDataDateChange
															: handleClaimDataInputChange
													}
													errorMessage={
														errorMessages[`claimData.${field.name}`]
													}
													optionData={
														field.name === 'principalId' && optionData
													}
												/>
											</Grid>
										)
									)}
									<Grid item xs={12}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Typography
												style={{ marginRight: 8 }}
												color='textPrimary'
												variant='body2'
												align='left'
											>
												Upload Claim Document
											</Typography>
											<Tooltip content={printReqString()} />
										</div>
										<div
											style={{
												width: '100%',
												marginTop: 8,
												marginBottom: 4,
											}}
										>
											<FileUploader
												fileLimit={1}
												files={formData.claimData.fileData}
												handleFileChange={handleClaimDocumentChange}
												formErrorMessage={
													errorMessages[`claimData.claimDocument`] &&
													errorMessages[`claimData.claimDocument`]
												}
											/>
										</div>
									</Grid>
								</>
							) : (
								<>
									<Grid item xs={4}>
										<Typography color='textPrimary' variant='body2'>
											Reason for Declining Request
										</Typography>
										<FormControl
											style={{ width: '100%' }}
											error={!!errorMessages[`claimData.rejReason`]}
										>
											<Select
												name='rejReason'
												value={formData.claimData.rejReason}
												onChange={(e) => handleClaimDataInputChange(e)}
												variant='filled'
												fullWidth
												autoWidth={true}
												margin='dense'
												style={{ marginTop: 8, marginBottom: 4 }}
												error={!!errorMessages[`claimData.rejReason`]}
											>
												{claimRejReasonList.map((val, index) => {
													return (
														<MenuItem key={index} value={val}>
															{val}
														</MenuItem>
													);
												})}
											</Select>
											<FormHelperText>
												{errorMessages[`claimData.rejReason`] &&
													errorMessages[`claimData.rejReason`]}
											</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<Typography color='textPrimary' variant='body2'>
											Remarks for Declining Request
										</Typography>
										<TextField
											name='rejRemarks'
											value={formData.claimData.rejRemarks || ''}
											multiline
											InputProps={{
												style: { color: 'unset' },
											}}
											onChange={(e) => handleClaimDataInputChange(e)}
											variant={'filled'}
											fullWidth
											margin='dense'
											minRows={4}
											error={!!errorMessages[`claimData.rejRemarks`]}
											helperText={
												errorMessages[`claimData.rejRemarks`] &&
												errorMessages[`claimData.rejRemarks`]
											}
										/>
									</Grid>
								</>
							)}
						</Grid>
					</div>
				);
			case 5:
				return (
					<div style={{ position: 'relative' }}>
						<Grid container spacing={4}>
							{policyFields.map((field, index) =>
								field.type === 'block' ? (
									<Grid key={index} item xs={4} />
								) : (
									<Grid key={index} item xs={field.name === 'remarks' ? 12 : 4}>
										<CustomTextField
											existing={false}
											type={field.type}
											isNumber={field.isNumber}
											isCurrency={field.isCurrency}
											fieldName={field.name}
											value={formData.policyData[field.name] ?? ''}
											label={field.label}
											required={field.required}
											tooltipContent={
												field.tooltipContent && field.tooltipContent
											}
											disableFuture={field.disableFuture && field.disableFuture}
											handleChange={
												field.type === 'date'
													? handlePolicyDataDateChange
													: handlePolicyDataInputChange
											}
											errorMessage={errorMessages[`policyData.${field.name}`]}
											optionData={field.name === 'principalId' && optionData}
										/>
									</Grid>
								)
							)}
							<Grid item xs={12}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography
										style={{ marginRight: 8 }}
										color='textPrimary'
										variant='body2'
										align='left'
									>
										Upload Policy Document
									</Typography>
									<Tooltip content={printReqString()} />
								</div>
								<div
									style={{
										width: '100%',
										marginTop: 8,
										marginBottom: 4,
									}}
								>
									<FileUploader
										fileLimit={1}
										files={formData.policyData.fileData}
										handleFileChange={handlePolicyDocumentChange}
										formErrorMessage={
											errorMessages[`policyData.policyDocument`] &&
											errorMessages[`policyData.policyDocument`]
										}
									/>
								</div>
							</Grid>
						</Grid>
					</div>
				);
			case 6:
				return (
					<div style={{ position: 'relative' }}>
						<Grid container spacing={4}>
							{policyFields.map((field, index) =>
								field.type === 'block' ? (
									<Grid key={index} item xs={4} />
								) : (
									<Grid key={index} item xs={field.name === 'remarks' ? 12 : 4}>
										<CustomTextField
											existing={formData.source === 'External' ? false : true}
											type={field.type}
											isNumber={field.isNumber}
											isCurrency={field.isCurrency}
											fieldName={field.name}
											value={
												field.name === 'principalId' &&
												formData.source !== 'External'
													? formData.prevPolicyData['principalName']
													: formData.prevPolicyData[field.name] ?? ''
											}
											label={field.label}
											required={field.required}
											tooltipContent={
												field.tooltipContent && field.tooltipContent
											}
											disableFuture={field.disableFuture && field.disableFuture}
											handleChange={
												field.type === 'date'
													? handlePrevPolicyDataDateChange
													: handlePrevPolicyDataInputChange
											}
											errorMessage={
												errorMessages[`prevPolicyData.${field.name}`]
											}
											optionData={field.name === 'principalId' && optionData}
										/>
									</Grid>
								)
							)}
							<Grid item xs={12}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography
										style={{ marginRight: 8 }}
										color='textPrimary'
										variant='body2'
										align='left'
									>
										Upload Policy Document
									</Typography>
									<Tooltip content={printReqString()} />
								</div>
								<div
									style={{
										width: '100%',
										marginTop: 8,
										marginBottom: 4,
									}}
								>
									{formData.source === 'External' ? (
										<FileUploader
											fileLimit={1}
											files={formData.prevPolicyData.fileData}
											handleFileChange={handlePrevPolicyDocumentChange}
											formErrorMessage={
												errorMessages[`prevPolicyData.prevPolicyDocument`] &&
												errorMessages[`prevPolicyData.prevPolicyDocument`]
											}
										/>
									) : (
										<>
											{formData.prevPolicyData.policyDocuments !== undefined &&
												formData.prevPolicyData.policyDocuments.length > 0 && (
													<>
														{formData.prevPolicyData.policyDocuments.map(
															(doc, index) => (
																<div
																	className={classes.documentRow}
																	key={index}
																>
																	<FileInfoCard file={doc} />
																</div>
															)
														)}
													</>
												)}
										</>
									)}
								</div>
							</Grid>
						</Grid>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<Grid container style={{ marginBottom: 24 }}>
			<Grid item xs={1}>
				<List className={classes.list} disablePadding>
					{NavList.map((item, index) => {
						if (
							item.label === 'COMPANY INFORMATION' &&
							!formData.selectedInsuranceType.isCommercial
						) {
							return null;
						}
						if (item.label === 'QUOTATIONS' && !formData.isSelfGenerated) {
							return null;
						}

						if (!formData.isDirectPurchase) {
							if (
								item.label === 'POLICY DETAILS' ||
								item.label === 'CLAIM DETAILS'
							) {
								return null;
							}
						} else {
							if (
								item.label === 'CLAIM DETAILS' &&
								(formData.transactionType === 'Quotation' ||
									formData.transactionType === 'Renewal')
							) {
								return null;
							}
							if (
								item.label === 'POLICY DETAILS' &&
								formData.transactionType === 'Claims'
							) {
								return null;
							}
						}

						if (
							item.label === 'PREVIOUS POLICY DETAILS' &&
							formData.transactionType === 'Quotation'
						) {
							return null;
						}
						return (
							<CustomListItem
								key={index}
								index={index}
								icon={item.icon}
								label={item.label}
								tabIndex={tabIndex}
								isError={errorTabs[index]}
								isCompleted={completedTabs[index]}
								handleChangeTab={handleChangeTab}
							/>
						);
					})}
				</List>
			</Grid>
			<Grid item xs={10}>
				<div className={classes.root}>{getTabContent(tabIndex)}</div>
			</Grid>
			<SuggestedChecklist
				open={checklistOpen}
				handleClose={() => setChecklistOpen(false)}
				checklist={checklist}
			/>
		</Grid>
	);
}
