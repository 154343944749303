import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default function CustomTooltip(props) {
	const { content, placement } = props;

	return (
		<Tooltip
			style={{ backgroundColor: '#C9DFF4' }}
			title={content}
			placement={placement ? placement : 'top'}
			interactive
			arrow
		>
			<InfoOutlinedIcon
				style={{
					width: 24,
					height: 24,
					color: '#009AFF',
				}}
			/>
		</Tooltip>
	);
}
