import Joi from 'joi';

const InvoiceSettings = Joi.object({
	color: Joi.string()
		.label('Color')
		.trim()
		.pattern(/^#[0-9a-f]{3,6}$/i)
		.required(),
	paymentDue: Joi.number().label('Payment Due').min(1).max(365).required(),
	paymentReminder: Joi.number()
		.label('Payment Reminder')
		.min(1)
		.max(365)
		.required(),
	beneficiaryName: Joi.string()
		.label('Beneficiary Name')
		.trim()
		.min(1)
		.max(50)
		.required(),
	bank: Joi.string().label('Bank').trim().min(1).max(50).required(),
	accountNo: Joi.string()
		.label('Account Number')
		.trim()
		.min(1)
		.max(50)
		.required(),
	paynowEnabled: Joi.boolean().label('PayNow').required(),
	paynowId: Joi.string().label('PayNow Info').trim().min(1).max(50).required(),
	terms: Joi.string()
		.label('Terms')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default InvoiceSettings;
