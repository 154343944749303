import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
	Clients,
	Company,
	Contacts,
	Dashboard,
	Import,
	Invoice,
	Transaction,
} from '../../components/Icons';
import { useNavOpen } from '../../utils/contexts/navOpen';
import useStyles from './styles';

const menuList = [
	{
		key: 'dashboard',
		value: 'dashboard',
		display: 'Dashboard',
		icon: <Dashboard />,
		subMenu: false,
		subMenuItems: [],
		requireOrg: false,
	},
	// {
	// 	key: 'calendar',
	// 	value: 'calendar',
	// 	display: 'Calendar',
	// 	icon: <TodayOutlinedIcon />,
	// 	subMenu: false,
	// 	subMenuItems: [],
	//  requireOrg: false,
	// },
	{
		key: 'clients',
		value: 'contacts',
		display: 'Clients',
		icon: <Clients />,
		subMenu: true,
		subMenuItems: [
			{
				key: 'contacts',
				value: 'contacts',
				display: 'Contacts',
				icon: <Contacts />,
			},
			{
				key: 'companies',
				value: 'companies',
				display: 'Companies',
				icon: <Company />,
			},
			{
				key: 'import',
				value: 'import/clients',
				display: 'Import Clients',
				icon: <Import />,
			},
		],
		requireOrg: false,
	},
	{
		key: 'transactions',
		value: 'transactions',
		display: 'Transactions',
		icon: <Transaction />,
		subMenu: false,
		subMenuItems: [],
		requireOrg: false,
	},
	{
		key: 'invoices',
		value: 'invoices',
		display: 'Invoices',
		icon: <Invoice />,
		subMenu: false,
		subMenuItems: [],
		requireOrg: true,
	},
	// {
	// 	key: 'reports',
	// 	value: 'reports',
	// 	display: 'Reports',
	// 	icon: <BarChartOutlinedIcon />,
	// 	subMenu: false,
	// 	subMenuItems: [],
	//  requireOrg: false,
	// },
	// {
	// 	key: 'team',
	// 	value: 'team',
	// 	display: 'Team',
	// 	icon: <SupervisorAccountOutlinedIcon />,
	// 	subMenu: false,
	// 	subMenuItems: [],
	// 	forTeams: true,
	//  requireOrg: false,
	// },
];

const SideNav = (props) => {
	const location = useLocation();
	const { user, isDesktopOrLaptop } = props;
	const { navOpen, handleNavDrawerToggle } = useNavOpen();
	const classes = useStyles();
	const theme = useTheme();

	const [subMenus, setSubMenus] = useState({});

	const toggleSubMenu = (menu) => {
		setSubMenus((prevState) => ({ ...prevState, [menu]: !prevState[menu] }));
	};

	const getMenu = (items, isSubItem) => {
		return items.map((item) => {
			if (!item.subMenu) {
				if (!navOpen && isSubItem) return;
				return (
					// <div key={item.key}>
					<ListItem
						key={item.key}
						className={isSubItem ? classes.nested : ''}
						classes={{
							gutters: !isSubItem && classes.paddingOverride,
						}}
						button
						component={RouterLink}
						to={item.forTeams ? '/upgrade' : '/' + item.value}
						style={{
							textDecoration: 'none',
							display: !navOpen && 'flex',
							flexDirection: !navOpen && 'column',
							paddingLeft: !navOpen && 8,
							paddingRight: !navOpen && 8,
						}}
						disabled={item.requireOrg && !user.userProfile.hasOrganization}
						onClick={() => !isDesktopOrLaptop && handleNavDrawerToggle()}
						selected={location.pathname.includes(item.value)}
					>
						{item.icon && (
							<ListItemIcon
								style={{
									minWidth: !navOpen ? 'unset' : 38,
									fontSize: 24,
								}}
							>
								{React.cloneElement(item.icon, {
									colorcode: location.pathname.includes(item.value)
										? theme.palette.tertiary.main
										: theme.palette.primary.main,
								})}
							</ListItemIcon>
						)}
						<ListItemText
							style={{ margin: 0 }}
							primaryTypographyProps={{
								style: {
									color: location.pathname.includes(item.value)
										? theme.palette.tertiary.main
										: theme.palette.primary.main,
									fontWeight: location.pathname.includes(item.value)
										? 600
										: 400,
									fontSize: !navOpen && '0.625rem',
									// display: !navOpen && 'none',
								},
							}}
							secondaryTypographyProps={{
								style: {
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									fontWeight:
										!navOpen && location.pathname.includes(item.value)
											? 600
											: 400,
									fontSize: !navOpen && '0.625rem',
									// display: navOpen && 'none',
								},
							}}
							primary={navOpen && item.display}
							secondary={!navOpen && item.display}
						/>
						{item.forTeams
							? user.verified && (
									<Chip
										className={classes.chip}
										style={{ display: !navOpen && 'none' }}
										label='Upgrade'
										// component={RouterLink}
										// to='/upgrade'
										// clickable
									/>
							  )
							: null}
					</ListItem>
					// </div>
				);
			}
			return (
				<div key={item.key}>
					<ListItem
						classes={{
							gutters: classes.paddingOverrideSub,
						}}
						key={item.key}
						name={item.value}
						button
						component={!navOpen ? RouterLink : 'div'}
						to={'/' + item.value}
						style={{
							textDecoration: 'none',
							display: !navOpen && 'flex',
							flexDirection: !navOpen && 'column',
							paddingLeft: !navOpen && 8,
							paddingRight: !navOpen && 8,
						}}
						disabled={item.requireOrg && !user.userProfile.hasOrganization}
						selected={!navOpen ? location.pathname.includes(item.value) : false}
						onClick={() =>
							navOpen
								? toggleSubMenu(item.value)
								: !isDesktopOrLaptop && handleNavDrawerToggle()
						}
					>
						{item.icon && (
							<ListItemIcon
								style={{
									minWidth: !navOpen ? 'unset' : 38,
									fontSize: 24,
								}}
							>
								{React.cloneElement(item.icon, {
									colorcode:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
								})}
							</ListItemIcon>
						)}
						<ListItemText
							style={{ margin: 0 }}
							primaryTypographyProps={{
								style: {
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									fontWeight:
										!navOpen && location.pathname.includes(item.value)
											? 600
											: 400,
									fontSize: !navOpen && '0.625rem',
									// display: !navOpen && 'none',
								},
							}}
							secondaryTypographyProps={{
								style: {
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									fontWeight:
										!navOpen && location.pathname.includes(item.value)
											? 600
											: 400,
									fontSize: !navOpen && '0.625rem',
									// display: navOpen && 'none',
								},
							}}
							primary={navOpen && item.display}
							secondary={!navOpen && item.display}
						/>
						{subMenus[item.value] ? (
							<ExpandLess
								style={{
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									display: !navOpen && 'none',
								}}
							/>
						) : (
							<ExpandMore
								style={{
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									display: !navOpen && 'none',
								}}
							/>
						)}
					</ListItem>
					<Collapse in={subMenus[item.value]} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							{getMenu(item.subMenuItems, true)}
						</List>
					</Collapse>
				</div>
			);
		});
	};

	const getMobileMenu = (items, isSubItem) => {
		return items.map((item) => {
			if (!item.subMenu) {
				if (!navOpen && isSubItem) return;
				return (
					// <div key={item.key}>
					<ListItem
						key={item.key}
						className={isSubItem ? classes.nested : ''}
						classes={{
							gutters: !isSubItem && classes.paddingOverride,
						}}
						button
						component={RouterLink}
						to={item.forTeams ? '/upgrade' : '/' + item.value}
						style={{
							textDecoration: 'none',
							// display: !navOpen && 'flex',
							// flexDirection: !navOpen && 'column',
							// paddingLeft: !navOpen && 8,
							// paddingRight: !navOpen && 8,
						}}
						disabled={item.requireOrg && !user.userProfile.hasOrganization}
						onClick={() => !isDesktopOrLaptop && handleNavDrawerToggle()}
						selected={location.pathname.includes(item.value)}
					>
						{item.icon && (
							<ListItemIcon
								style={{
									minWidth: 38,
									fontSize: 24,
								}}
							>
								{React.cloneElement(item.icon, {
									colorcode: location.pathname.includes(item.value)
										? theme.palette.tertiary.main
										: theme.palette.primary.main,
								})}
							</ListItemIcon>
						)}
						<ListItemText
							style={{ margin: 0 }}
							primaryTypographyProps={{
								style: {
									color: location.pathname.includes(item.value)
										? theme.palette.tertiary.main
										: theme.palette.primary.main,
									fontWeight: location.pathname.includes(item.value)
										? 600
										: 400,
									// fontSize: !navOpen && '0.625rem',
									// display: !navOpen && 'none',
								},
							}}
							secondaryTypographyProps={{
								style: {
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									fontWeight:
										!navOpen && location.pathname.includes(item.value)
											? 600
											: 400,
									fontSize: !navOpen && '0.625rem',
									// display: navOpen && 'none',
								},
							}}
							primary={navOpen && item.display}
							secondary={!navOpen && item.display}
						/>
						{item.forTeams
							? user.verified && (
									<Chip
										className={classes.chip}
										style={{ display: !navOpen && 'none' }}
										label='Upgrade'
										// component={RouterLink}
										// to='/upgrade'
										// clickable
									/>
							  )
							: null}
					</ListItem>
					// </div>
				);
			}
			return (
				<div key={item.key}>
					<ListItem
						classes={{
							gutters: classes.paddingOverrideSub,
						}}
						key={item.key}
						name={item.value}
						button
						component={!navOpen ? RouterLink : 'div'}
						to={'/' + item.value}
						style={{
							textDecoration: 'none',
							// display: !navOpen && 'flex',
							// flexDirection: !navOpen && 'column',
							// paddingLeft: !navOpen && 8,
							// paddingRight: !navOpen && 8,
						}}
						disabled={item.requireOrg && !user.userProfile.hasOrganization}
						selected={!navOpen ? location.pathname.includes(item.value) : false}
						onClick={() =>
							navOpen
								? toggleSubMenu(item.value)
								: !isDesktopOrLaptop && handleNavDrawerToggle()
						}
					>
						{item.icon && (
							<ListItemIcon
								style={{
									minWidth: 38,
									fontSize: 24,
								}}
							>
								{React.cloneElement(item.icon, {
									colorcode:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
								})}
							</ListItemIcon>
						)}
						<ListItemText
							style={{ margin: 0 }}
							primaryTypographyProps={{
								style: {
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									fontWeight:
										!navOpen && location.pathname.includes(item.value)
											? 600
											: 400,
									// fontSize: !navOpen && '0.625rem',
									// display: !navOpen && 'none',
								},
							}}
							secondaryTypographyProps={{
								style: {
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									fontWeight:
										!navOpen && location.pathname.includes(item.value)
											? 600
											: 400,
									fontSize: !navOpen && '0.625rem',
									// display: navOpen && 'none',
								},
							}}
							primary={navOpen && item.display}
							secondary={!navOpen && item.display}
						/>
						{subMenus[item.value] ? (
							<ExpandLess
								style={{
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									display: !navOpen && 'none',
								}}
							/>
						) : (
							<ExpandMore
								style={{
									color:
										!navOpen && location.pathname.includes(item.value)
											? theme.palette.tertiary.main
											: theme.palette.primary.main,
									display: !navOpen && 'none',
								}}
							/>
						)}
					</ListItem>
					<Collapse in={subMenus[item.value]} timeout='auto' unmountOnExit>
						<List component='div' disablePadding>
							{getMenu(item.subMenuItems, true)}
						</List>
					</Collapse>
				</div>
			);
		});
	};

	return (
		<Drawer
			className={
				isDesktopOrLaptop
					? clsx(classes.drawer, {
							[classes.drawerOpen]: navOpen,
							[classes.drawerClose]: !navOpen,
					  })
					: classes.drawerMobile
			}
			variant={isDesktopOrLaptop ? 'permanent' : 'temporary'}
			anchor='left'
			open={navOpen}
			onClose={handleNavDrawerToggle}
			classes={{
				paper: isDesktopOrLaptop
					? clsx({
							[classes.drawerOpen]: navOpen,
							[classes.drawerClose]: !navOpen,
					  })
					: classes.drawerPaperMobile,
			}}
			ModalProps={{
				keepMounted: true, // Better open performance on mobile.
			}}
		>
			<Toolbar style={{ minHeight: 80 }} />
			<List style={{ padding: 0 }}>
				{isDesktopOrLaptop
					? getMenu(menuList, false)
					: getMobileMenu(menuList, false)}
			</List>
			{/* <Divider /> */}
			{!navOpen ? (
				<IconButton
					style={{
						// backgroundColor: 'transparent',
						padding: 16,
						margin: 'auto auto 8px auto',
					}}
					component={Link}
					href='https://www.uuoni.com/contact'
					target='_blank'
					rel='noopener'
					// disableFocusRipple
					// disableTouchRipple
				>
					<ContactSupportOutlinedIcon
						style={{ color: theme.palette.primary.main }}
					/>
				</IconButton>
			) : (
				<Link
					className={classes.paddingOverride}
					style={{
						// textDecoration: 'none',
						color: theme.palette.primary.main,
						fontWeight: 400,
						marginTop: 'auto',
						marginBottom: 8,
					}}
					variant='subtitle1'
					href='https://www.uuoni.com/contact'
					target='_blank'
					rel='noopener'
				>
					Have an issue? Contact Us!
				</Link>
			)}
		</Drawer>
	);
};

export default SideNav;
