import axios from 'axios';
import firebase from 'firebase/compat/app';
import { Logtail } from '@logtail/browser';
const logtail = new Logtail(process.env.REACT_APP_LOG_SOURCE_TOKEN);

const instance = axios.create({
	// baseURL: '/api/v1/',
	// baseURL: 'https://app.uuoni.com/api/v1/',
	// baseURL: 'http://localhost:3001/api/v1/',
	baseURL: process.env.REACT_APP_APIDOMAIN_BASEURL,
});

instance.interceptors.request.use(
	async (config) => {
		if (process.env.REACT_APP_ENVIRONMENT === 'demo') {
			config.headers.common = { Authorization: 'DEMO' };
			return config;
		}

		let token = await firebase.auth().currentUser.getIdToken(true);
		config.headers.common = { Authorization: `Bearer ${token}` };

		if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
			config.headers['request-startTime'] = new Date().getTime();
			if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
				logtail.debug('Starting Request', config);
			} else {
				console.log('Starting Request', config);
			}
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		if (
			process.env.REACT_APP_ENVIRONMENT !== 'production' &&
			process.env.REACT_APP_ENVIRONMENT !== 'demo'
		) {
			const currentTime = new Date().getTime();
			const startTime = response.config.headers['request-startTime'];
			if (currentTime - startTime > 3000) {
				console.warn('Response time exceeded 3s');
			}
			response.headers['request-duration'] = currentTime - startTime;
			console.log('Response:', response);
		}
		return response;
	},
	async (error) => {
		if (
			process.env.REACT_APP_ENVIRONMENT !== 'production' &&
			process.env.REACT_APP_ENVIRONMENT !== 'demo'
		) {
			if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
				logtail.error('Error:', error);
			}
			console.log('Error:', error);
		}
		const status = error.response?.status;
		if (status === 403) {
			window.location.href = '/payment';
		} else {
			return Promise.reject(error);
		}
	}
);

export default instance;
