import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		backgroundColor: theme.palette.primary.main,
		zIndex: theme.zIndex.drawer + 1,
		boxShadow: 'none',
	},
	toolBar: {
		paddingLeft: 32,
		paddingRight: 32,
		minHeight: 80,
	},
	resourceDrawer: {
		display: 'contents',
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: 280,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerPaper: {
		width: 280,
	},
	drawerMobile: {
		display: 'contents',
	},

	drawerContainer: {
		overflow: 'auto',
	},
	drawerHeader: {},
	drawerOpen: {
		width: 280,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	nested: {
		paddingLeft: theme.spacing(10),
	},
	content: {
		position: 'relative',
		flexGrow: 1,
		padding: 32,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),

		marginTop: 80,
		overflowY: 'auto',
		minHeight: 'calc(100vh - 80px)',
	},
	contentShift: {
		position: 'relative',
		flexGrow: 1,
		padding: 32,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
		marginTop: 80,
		overflowY: 'auto',
		height: 'calc(100vh - 80px)',
	},
	landingContent: {
		flexGrow: 1,
		height: '100vh',
	},
	paddingOverride: {
		paddingLeft: 24,
		paddingRight: 24,
	},
	paddingOverrideSub: {
		paddingLeft: 24,
	},
	cardContainer: {
		padding: 30,
	},
	card: {
		transition: 'all 0.25s',
		boxShadow: 'unset',
		'&:hover': {
			border: `1px solid ${theme.palette.tertiary.main} !important`,
			backgroundColor: `${theme.palette.secondary.main} !important`,
		},
		'&:focus': {
			border: `1px solid ${theme.palette.tertiary.main} !important`,
			backgroundColor: `${theme.palette.secondary.main} !important`,
		},
		'&:not(:first-child)': {
			marginTop: 16,
		},
	},
	cardContent: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
	cardContentRoot: { padding: 26 },
	cardText: {
		width: '70%',
		marginLeft: '5%',
	},
	cover: {
		width: 76,
		height: 104,
	},
	contentContainer: {
		width: '70%',
	},
	infoContainer: {
		padding: 25,
	},
	gridContainer: {
		flexGrow: 1,
	},
	paper: {
		height: 140,
		width: 100,
	},
	cardActionArea: {
		transition: 'all 0.25s',
	},
	radio: {
		'&:hover': {
			backgroundColor: 'transparent !important',
		},
	},
	fileCardContainer: {
		marginTop: 30,
		zIndex: 10,
	},
	fileCardContent: {
		display: 'flex',
		alignItems: 'center',
		padding: 16,
		'&:last-child': {
			paddingBottom: '16px !important',
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
	},
	buttonAccept: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.background.secondary,
		'&:hover': {
			backgroundColor: theme.palette.success.dark,
		},
	},
	buttonReject: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.background.secondary,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
	columnWrapper: {
		'&:not(:first-child)': {
			marginLeft: 24,
		},
	},
}));
