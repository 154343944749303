import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import Skeleton from '@material-ui/lab/Skeleton';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CountryList from '../../../../../components/Data/countries.json';

const useStyles = makeStyles((theme) => ({}));

const getNationality = (val) => {
	let obj = CountryList.find((item) => item.code === val);
	return obj.label;
};

export default function PersonalInfo(props) {
	const {
		loading,
		contact,
		editable,
		handleInputChange,
		handleDateChange,
		errorMessages,
	} = props;
	const classes = useStyles();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

	return (
		<Grid container spacing={isDesktopOrLaptop ? 3 : 2}>
			<Grid item xs={12} md={6} lg={4}>
				<Grid container justifyContent='flex-start'>
					<Typography color='textPrimary' variant='body2' align='left'>
						First Name
					</Typography>
					{editable && (
						<Typography
							style={{ marginLeft: 2 }}
							color='error'
							variant='body2'
							align='right'
						>
							*
						</Typography>
					)}
				</Grid>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='firstName'
								value={contact.firstName || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`firstName`]}
								helperText={
									errorMessages[`firstName`] && errorMessages[`firstName`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{contact.firstName}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Middle Name
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='middleName'
								value={contact.middleName || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`middleName`]}
								helperText={
									errorMessages[`middleName`] && errorMessages[`middleName`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{contact.middleName}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Grid container justifyContent='flex-start'>
					<Typography color='textPrimary' variant='body2' align='left'>
						Last Name
					</Typography>
					{editable && (
						<Typography
							style={{ marginLeft: 2 }}
							color='error'
							variant='body2'
							align='right'
						>
							*
						</Typography>
					)}
				</Grid>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='lastName'
								value={contact.lastName || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`lastName`]}
								helperText={
									errorMessages[`lastName`] && errorMessages[`lastName`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{contact.lastName}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					NRIC / FIN / Passport No.
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='idNo'
								value={contact.idNo || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`idNo`]}
								helperText={errorMessages[`idNo`] && errorMessages[`idNo`]}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{contact.idNo}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Date of Birth
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							name='dob'
							disableToolbar
							disabled={editable ? false : true}
							placeholder={editable ? 'DD/MM/YYYY' : ''}
							inputVariant={editable ? 'filled' : 'standard'}
							openTo='year'
							views={['year', 'month', 'date']}
							InputProps={{
								disableUnderline: !editable,
								style: { color: 'unset' },
							}}
							format='dd/MM/yyyy'
							disableFuture
							margin='dense'
							fullWidth
							value={contact.dob ? contact.dob : null}
							autoOk
							variant='inline'
							maxDate={new Date()}
							invalidDateMessage='Invalid Date Format'
							onChange={(date) => handleDateChange('dob', date)}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							keyboardIcon={editable ? <EventIcon /> : null}
							error={!!errorMessages[`dob`]}
							helperText={errorMessages[`dob`] && errorMessages[`dob`]}
						/>
					</MuiPickersUtilsProvider>
				)}
			</Grid>
			<Box width='100%' />
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Nationality
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<Select
								native
								name='nationality'
								value={contact.nationality || ''}
								inputProps={{
									style: { color: 'unset' },
								}}
								onChange={handleInputChange}
								variant='filled'
								fullWidth
								margin='dense'
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option key={0} value=''></option>
								{CountryList.sort(
									(a, b) => -b.label.localeCompare(a.label)
								).map((country, index) => {
									return (
										<option key={index} value={country.code}>
											{country.label}
										</option>
									);
								})}
							</Select>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{contact.nationality ? getNationality(contact.nationality) : ''}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2'>
					Marital Status
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<Select
								native
								name='maritalStatus'
								value={contact.maritalStatus || ''}
								inputProps={{
									style: { color: 'unset' },
								}}
								onChange={handleInputChange}
								variant='filled'
								fullWidth
								margin='dense'
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option value=''></option>
								<option value='single'>Single</option>
								<option value='married'>Married</option>
							</Select>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
									textTransform: 'capitalize',
								}}
							>
								{contact.maritalStatus ? contact.maritalStatus : ''}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2'>
					Gender
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<Select
								native
								name='gender'
								value={contact.gender || ''}
								inputProps={{
									style: { color: 'unset' },
								}}
								onChange={handleInputChange}
								variant='filled'
								fullWidth
								margin='dense'
								style={{ marginTop: 8, marginBottom: 4 }}
							>
								<option value=''></option>
								<option value='male'>Male</option>
								<option value='female'>Female</option>
							</Select>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
									textTransform: 'capitalize',
								}}
							>
								{contact.gender ? contact.gender : ''}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Grid container justifyContent='flex-start'>
					<Typography color='textPrimary' variant='body2' align='left'>
						Email
					</Typography>
					{editable && (
						<Typography
							style={{ marginLeft: 2 }}
							color='error'
							variant='body2'
							align='right'
						>
							*
						</Typography>
					)}
				</Grid>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='email'
								value={contact.email || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`email`]}
								helperText={errorMessages[`email`] && errorMessages[`email`]}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
								component={Link}
								href={`mailto:${contact.email}`}
							>
								{contact.email}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Mobile No.
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='mobileNo'
								value={contact.mobileNo || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`mobileNo`]}
								helperText={
									errorMessages[`mobileNo`] && errorMessages[`mobileNo`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
								component={Link}
								href={`tel:${contact.mobileNo}`}
							>
								{contact.mobileNo}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Home No.
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='homeNo'
								value={contact.homeNo || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`homeNo`]}
								helperText={errorMessages[`homeNo`] && errorMessages[`homeNo`]}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{contact.homeNo}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Street Address
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='address'
								value={contact.address || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`address`]}
								helperText={
									errorMessages[`address`] && errorMessages[`address`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{contact.address}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Unit No.
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='unitNo'
								value={contact.unitNo || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`unitNo`]}
								helperText={errorMessages[`unitNo`] && errorMessages[`unitNo`]}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{contact.unitNo}
							</Typography>
						)}
					</>
				)}
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Typography color='textPrimary' variant='body2' align='left'>
					Postal Code
				</Typography>
				{loading ? (
					<Skeleton variant='rect' animation='wave' />
				) : (
					<>
						{editable ? (
							<TextField
								name='postalCode'
								value={contact.postalCode || ''}
								onChange={handleInputChange}
								InputProps={{
									style: { color: 'unset' },
								}}
								variant={'filled'}
								fullWidth
								margin='dense'
								error={!!errorMessages[`postalCode`]}
								helperText={
									errorMessages[`postalCode`] && errorMessages[`postalCode`]
								}
							/>
						) : (
							<Typography
								color='textPrimary'
								variant='body1'
								align='left'
								style={{
									marginTop: 8,
									overflowWrap: 'anywhere',
									display: 'inline-block',
								}}
							>
								{contact.postalCode}
							</Typography>
						)}
					</>
				)}
			</Grid>
		</Grid>
	);
}
