import Backdrop from '../../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import TooltipComponent from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { toast } from 'react-toastify';
import api from '../../../config/api';
import { Recommendation } from '../../../utils/validators/schemas';
import '../styles.css';
import { Remarks, Remove, ThumbsUp } from '../../Icons';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3} style={{ padding: 0 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	chip: {
		maxWidth: 'inherit',
	},
	columnWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: 543,
		height: '100%',
		padding: 16,
		borderRadius: 4,
		border: '1.5px solid #B7C6F3',
		'&:not(:last-child)': {
			marginRight: 24,
		},
	},
	quoteCard: {
		display: 'flex',
		flexDirection: 'column',
		padding: 16,
		borderRadius: 4,
		background: theme.palette.secondary.light,
		'&:not(:first-child)': {
			marginTop: 16,
		},
	},
}));

const fileType = (fileName) => {
	return (
		fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
		fileName
	);
};

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

function QuoteCard(props) {
	const {
		index,
		requestId,
		quote,
		handleRemarksChange,
		handleChangeRecommended,
		errorMessages,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const [showRemarks, setShowRemarks] = useState(true);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!showRemarks || !quote.recommended) {
			handleRemarksChange('', requestId, quote.quoteName);
		}
	}, [showRemarks, quote.recommended]);

	const toggleRemarks = () => {
		if (!showRemarks) {
			setShowRemarks(true);
		}
		setShowRemarks(!showRemarks);
	};

	const downloadDocument = (e, document) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);
		}
		api
			.get(`documents/${document.documentId}?action=download`)
			.then((response) => {
				if (response.data === 'file not found') {
					setLoading(false);
					return alert('Error retrieving file!');
				}
				window.open(response.data, '_blank');
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<div className={classes.quoteCard}>
			<Grid container direction='row' alignItems='stretch' spacing={2}>
				<Grid
					item
					style={{ display: 'flex', alignItems: 'center', height: 48 }}
					xs={12}
				>
					<Typography style={{ flexGrow: 1 }} color='textPrimary' variant='h4'>
						Quotation {index + 1}
					</Typography>
					{quote.recommended ? (
						<Button
							style={{
								backgroundColor: theme.palette.success.main,
								color: theme.palette.background.default,
							}}
							onClick={() =>
								handleChangeRecommended(requestId, quote.quoteName)
							}
							variant='contained'
							startIcon={
								<ThumbsUp
									style={{ fontSize: 24 }}
									colorcode={theme.palette.primary.contrastText}
								/>
							}
						>
							Recommended
						</Button>
					) : (
						<TooltipComponent
							title='Click on this icon to recommend this Quotation to your Client'
							placement='top'
							interactive
							arrow
						>
							<IconButton
								style={{ padding: 0 }}
								disableRipple
								disableFocusRipple
								disableTouchRipple
								onClick={() =>
									handleChangeRecommended(requestId, quote.quoteName)
								}
							>
								<ThumbsUp
									style={{ fontSize: 24 }}
									colorcode={theme.palette.tertiary.main}
								/>
							</IconButton>
						</TooltipComponent>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					container
					direction='row'
					alignItems='stretch'
					spacing={2}
				>
					<Grid item xs={6}>
						<Typography color='textPrimary' variant='body2'>
							Quote Expiry Date
						</Typography>
						<Typography color='textPrimary' variant='body1'>
							{quote.expiryDate !== undefined &&
								moment(quote.expiryDate).format('DD/MM/YYYY')}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography color='textPrimary' variant='body2'>
							Period of Insurance
						</Typography>
						<Typography color='textPrimary' variant='body1'>
							{quote.startDate !== undefined &&
								quote.endDate !== undefined &&
								`${moment(quote.startDate).format('DD/MM/YYYY')} - ${moment(
									quote.endDate
								).format('DD/MM/YYYY')} `}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography color='textPrimary' variant='body2'>
							Total Premium
						</Typography>
						<Typography color='textPrimary' variant='body1'>
							{quote.premiumAmt !== undefined &&
								currencyFormatter.format(quote.premiumAmt)}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography color='textPrimary' variant='body2'>
							Sum Insured
						</Typography>
						<Typography color='textPrimary' variant='body1'>
							{quote.sumInsured
								? currencyFormatter.format(quote.sumInsured)
								: 'Refer to Quote Document'}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography color='textPrimary' variant='body2'>
							Notes
						</Typography>
						<Typography
							style={{ whiteSpace: 'pre-wrap' }}
							color='textPrimary'
							variant='body1'
						>
							{quote.notes ? quote.notes : 'N/A'}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{quote.responseDocument !== undefined && (
							<Chip
								icon={
									<div>
										<FileIcon
											labelUppercase
											extension={fileType(quote.responseDocument.displayName)}
											{...defaultStyles[
												fileType(quote.responseDocument.displayName)
											]}
										/>
									</div>
								}
								label={`${quote.responseDocument.documentName} (${quote.responseDocument.displayName})`}
								className={classes.chip}
								onClick={(e) => downloadDocument(e, quote.responseDocument)}
								variant='outlined'
								color='primary'
							/>
						)}
					</Grid>
					{quote.recommended && showRemarks && (
						<Grid item xs={12}>
							<Typography color='textPrimary' variant='body2'>
								Recommendation Remarks
							</Typography>
							<TextField
								name='remarks'
								value={quote.recommendationRemarks}
								onChange={(e) =>
									handleRemarksChange(
										e.target.value,
										requestId,
										quote.quoteName
									)
								}
								placeholder='Enter Remarks'
								variant='filled'
								fullWidth
								margin='dense'
								multiline
								minRows={4}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
			{quote.recommended && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						marginTop: 16,
					}}
				>
					<Button
						style={{ padding: 0, marginLeft: 4 }}
						color='primary'
						onClick={() => toggleRemarks()}
						startIcon={
							showRemarks ? (
								<Remove style={{ fontSize: 24 }} />
							) : (
								<Remarks style={{ fontSize: 24 }} />
							)
						}
					>
						{showRemarks
							? 'Remove Recommendation Remarks'
							: 'Add Recommendation Remarks'}
					</Button>
				</div>
			)}
		</div>
	);
}

function Column(props) {
	const {
		response,
		handleRemarksChange,
		handleChangeRecommended,
		errorMessages,
	} = props;
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div className={classes.columnWrapper}>
			<Typography variant='body2' style={{ textTransform: 'uppercase' }}>
				{response.principalName}
			</Typography>
			<Divider
				style={{ margin: '16px 0px', background: theme.palette.secondary.main }}
			/>
			<div id='card-container'>
				{response.responseData.quoteList.map((quote, index) => (
					<QuoteCard
						key={quote.quoteName}
						index={index}
						requestId={response._id}
						quote={quote}
						handleRemarksChange={handleRemarksChange}
						handleChangeRecommended={handleChangeRecommended}
						errorMessages={errorMessages}
					/>
				))}
			</div>
		</div>
	);
}

export default function UpdateRecommendations(props) {
	const { open, handleClose, body, transactionId, data } = props;
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const [requestList, setRequestList] = useState(data);
	const [errorMessages, setErrorMessages] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleChangeRecommended = (requestId, quoteName) => {
		let updatedRequestList = requestList.map((request) => {
			let updatedQuoteList = request.responseData.quoteList.map((quote) => {
				if (quote.quoteName === quoteName && request._id === requestId) {
					if (!quote.recommended) {
						return {
							...quote,
							recommended: true,
						};
					}
				}
				return {
					...quote,
					recommended: false,
				};
			});

			return {
				...request,
				responseData: {
					...request.responseData,
					quoteList: updatedQuoteList,
				},
			};
		});

		setRequestList(updatedRequestList);
	};

	const handleRemarksChange = (val, requestId, quoteName) => {
		let updatedRequestList = requestList.map((request) => {
			if (request._id === requestId) {
				let updatedQuoteList = request.responseData.quoteList.map((quote) => {
					if (quote.quoteName === quoteName) {
						return {
							...quote,
							recommendationRemarks: val,
						};
					}
					return quote;
				});
				return {
					...request,
					responseData: {
						...request.responseData,
						quoteList: updatedQuoteList,
					},
				};
			}
			return request;
		});
		setRequestList(updatedRequestList);
	};

	const handleValidation = () => {
		let updatedErrorMessages = [];
		requestList.forEach((request) => {
			let result = Recommendation.validate(request, {
				abortEarly: false,
				convert: false,
			});
			const { error } = result;
			if (!error) {
				updatedErrorMessages = updatedErrorMessages.filter(
					(item) => item._id !== request._id
				);
			} else {
				const errorData = {};
				for (let item of error.details) {
					const key = item.path.join('.');
					const message = item.message;
					errorData[key] = message;
				}
				updatedErrorMessages.push({
					_id: request._id,
					errors: errorData,
				});
			}
		});
		setErrorMessages(updatedErrorMessages);
		if (updatedErrorMessages.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!loading) {
			setLoading(true);
			let valid = handleValidation();

			if (valid) {
				try {
					let formData = requestList.map((request) => {
						return {
							requestId: request._id,
							principalName: request.principalName,
							quoteList: request.responseData.quoteList,
						};
					});
					api
						.put(
							`transactions/${transactionId}/updateRecommendations`,
							formData
						)
						.then((res) => {
							if (res.data.ok) {
								toast.success('Request sent successfully!');
								setLoading(false);
								props.setTriggerUpdate(true);
								handleClose();
							} else {
								toast.error(res.data.error);
								setLoading(false);
								handleClose();
							}
						});
				} catch (error) {
					console.log(error);
					toast.error(error);
				}
			} else {
				toast.error('An error has occured!');
				setLoading(false);
			}
		}
	};

	return (
		<>
			<Backdrop text='Loading Data...' open={loading} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography
					style={{ textTransform: 'uppercase' }}
					color='textPrimary'
					variant='h3'
				>
					Update Recommendations
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					overflowX: activeStep === 0 && 'auto',
					marginTop: 24,
				}}
			>
				{requestList.map((response, index) => (
					<Column
						key={response._id}
						response={response}
						handleRemarksChange={handleRemarksChange}
						handleChangeRecommended={handleChangeRecommended}
						errorMessages={errorMessages}
					/>
				))}
			</div>
			<div style={{ display: 'flex', marginTop: 24 }}>
				<Button
					style={{ marginRight: 'auto' }}
					onClick={handleClose}
					variant='outlined'
					color='primary'
				>
					Cancel
				</Button>
				<Button
					onClick={(e) => handleSubmit(e)}
					variant='contained'
					color='primary'
				>
					Save
				</Button>
			</div>
		</>
	);
}
