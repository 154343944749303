import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import PrincipalList from '../../components/Data/principalData.json';
import Tooltip from '../../components/Tooltip';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.secondary.light,
		marginBottom: 16,
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	card: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
		borderRadius: 8,

		'&:hover': {
			boxShadow: `0px 0px 0px 2px ${theme.palette.tertiary.main}`,
			backgroundColor: theme.palette.secondary.main,
		},
		'&:focus': {
			boxShadow: `0px 0px 0px 2px ${theme.palette.tertiary.main}`,
			backgroundColor: theme.palette.secondary.main,
		},
	},
	cardActive: {
		backgroundColor: theme.palette.secondary.light,
		boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
		borderRadius: 8,
		border: '2px solid theme.palette.tertiary.main',
	},
	cardHeader: {
		textTransform: 'uppercase',
	},
	cardHeaderAction: {
		marginTop: 0,
		marginRight: 0,
	},
	cardContent: {},
	cardContentActive: { color: theme.palette.tertiary.main },
}));

const TypeList = [
	{
		name: 'Life Insurance',
		content:
			'Content to be included regarding the profession in Uuoni platform',
		value: 'LI',
	},
	{
		name: 'General Insurance',
		content:
			'Content to be included regarding the profession in Uuoni platform',
		value: 'GI',
	},
	{
		name: 'IFA / Broker',
		content:
			'Content to be included regarding the profession in Uuoni platform',
		value: 'Broker',
	},
];

const printProfessionString = () => {
	let liBase = '1) LIFE INSURANCE';
	let giBase = '2) GENERAL INSURANCE';
	let ifaBase = '3) IFA / BROKERS';
	let liInstructions =
		'Select this option if you are an Appointed Representative (AR) of a Life Insurance Company.';
	let giInstructions =
		'Select this option if you are an Appointed Representative (AR) of at least one (1) Insurance Company.';
	let ifaInstructions =
		'Select this option if you are an Appointed Representative (AR) of an Independent Financial Advisory Firm / Broker.';
	let liLimits =
		'(Tied agent can only represent a single Life Insurance Company)';
	let giLimits = '(Tied agent represent up to 3 General Insurance Companies)';
	let ifaLimits =
		'(Advisor / Broker can only advise / sell products that are available in their distribution list)';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{liBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${liInstructions}\n${liLimits}`}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{giBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${giInstructions}\n${giLimits}`}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{ifaBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${ifaInstructions}\n${ifaLimits}`}
			</Typography>
		</div>
	);
};

const printAgentNoString = () => {
	let base = 'Please ensure that your Agent Registration Number is correct.';
	let eg = 'E.g. GIA Nominee No., RFN etc.';

	return <div style={{ whiteSpace: 'pre-line' }}>{`${base}\n${eg}`}</div>;
};

const PrincipalCard = (props) => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
	const classes = useStyles();
	const theme = useTheme();
	const {
		index,
		principal,
		handlePrincipalInputChange,
		handleRemovePrincipal,
		errorMessages,
	} = props;
	const [open, setOpen] = useState(true);

	const toggleOpen = () => {
		if (!open) {
			setOpen(true);
		}
		setOpen(!open);
	};

	return (
		<Card className={classes.root}>
			<CardHeader
				style={{ padding: !isMobile ? '24px 32px' : '2px 0px' }}
				title={
					<>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<IconButton
								className={clsx(classes.expand, {
									[classes.expandOpen]: open,
								})}
								style={{ marginRight: 8 }}
								onClick={() => toggleOpen()}
								aria-expanded={open}
								aria-label='show more'
							>
								<ExpandMoreIcon />
							</IconButton>
							<Typography color='textPrimary' variant='h4'>
								{principal.shortName}
							</Typography>
							{isDesktopOrLaptop ? (
								<Button
									style={{ marginLeft: 'auto' }}
									color='primary'
									startIcon={<RemoveCircleOutlineIcon />}
									onClick={() => handleRemovePrincipal(principal.id)}
								>
									Remove
								</Button>
							) : (
								<IconButton
									style={{
										color: theme.palette.tertiary.main,
										marginLeft: 'auto',
										marginRight: 8,
									}}
									aria-label='expand row'
									size='small'
									onClick={() => handleRemovePrincipal(principal.id)}
								>
									<RemoveCircleOutlineIcon />
								</IconButton>
							)}
						</div>
					</>
				}
			/>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<CardContent
					style={{
						width: isDesktopOrLaptop && '85%',
						padding: isDesktopOrLaptop && '0px 32px 32px',
						paddingTop: isDesktopOrLaptop && 8,
					}}
				>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Typography color='textPrimary' variant='body2'>
								First Name
							</Typography>
							<TextField
								name='firstName'
								value={principal.contact.firstName}
								onChange={(e) => handlePrincipalInputChange(e, index)}
								placeholder='Enter First Name'
								variant='filled'
								fullWidth
								margin='dense'
								error={!!errorMessages[`principals.${index}.contact.firstName`]}
								helperText={
									errorMessages[`principals.${index}.contact.firstName`]
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color='textPrimary' variant='body2'>
								Last Name
							</Typography>
							<TextField
								name='lastName'
								value={principal.contact.lastName}
								onChange={(e) => handlePrincipalInputChange(e, index)}
								placeholder='Enter Last Name'
								variant='filled'
								fullWidth
								margin='dense'
								error={!!errorMessages[`principals.${index}.contact.lastName`]}
								helperText={
									errorMessages[`principals.${index}.contact.lastName`]
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color='textPrimary' variant='body2'>
								Email
							</Typography>
							<TextField
								name='email'
								value={principal.contact.email}
								onChange={(e) => handlePrincipalInputChange(e, index)}
								placeholder='Enter Email'
								variant='filled'
								fullWidth
								margin='dense'
								error={!!errorMessages[`principals.${index}.contact.email`]}
								helperText={errorMessages[`principals.${index}.contact.email`]}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color='textPrimary' variant='body2'>
								Mobile Number
							</Typography>
							<TextField
								name='mobileNo'
								value={principal.contact.mobileNo}
								onChange={(e) => handlePrincipalInputChange(e, index)}
								placeholder='Enter Mobile Number'
								variant='filled'
								fullWidth
								margin='dense'
								error={!!errorMessages[`principals.${index}.contact.mobileNo`]}
								helperText={
									errorMessages[`principals.${index}.contact.mobileNo`]
								}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Collapse>
		</Card>
	);
};

const Step1 = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
	const {
		profile,
		setProfile,
		professionType,
		setProfessionType,
		principals,
		setPrincipals,
		errorMessages,
	} = props;

	const [inputVal, setInputVal] = useState('');

	const handleAddPrincipal = (option) => {
		if (option === null) return;

		let selectedPrincipal = {
			id: nanoid(),
			code: option.code,
			name: option.name,
			shortName: option.shortName,
			contact: {
				firstName: '',
				lastName: '',
				email: '',
				mobileNo: '',
			},
			open: true,
		};

		let updatedPrincipals = [...principals];
		updatedPrincipals.push(selectedPrincipal);

		setPrincipals(updatedPrincipals);
		setInputVal('');
	};

	const handleRemovePrincipal = (id) => {
		let updatedPrincipals = [...principals].filter(
			(principal) => principal.id !== id
		);
		setPrincipals(updatedPrincipals);
	};

	const handlePrincipalInputChange = (e, index) => {
		const {
			target: { value, name },
		} = e;
		let updatedPrincipals = [...principals];
		updatedPrincipals[index]['contact'][name] = value;

		setPrincipals(updatedPrincipals);
	};

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;

		setProfile((prev) => ({ ...prev, [name]: value }));
	};

	return (
		<>
			<Typography variant='h1'>Your Corporate Identity</Typography>
			<Typography
				variant='body1'
				style={{
					marginTop: !isMobile ? 24 : 16,
					marginBottom: !isMobile ? 48 : 32,
				}}
			>
				Please specify your professional identity to get setup for Uuoni.
			</Typography>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Typography style={{ marginRight: 8 }} variant='body2'>
					Profession Type
				</Typography>
				<Tooltip content={printProfessionString()} />
			</div>
			<Grid
				container
				className={classes.gridContainer}
				style={{ marginTop: 12, marginBottom: 12 }}
				spacing={!isMobile ? 6 : 3}
			>
				{TypeList.map((type, index) => (
					<Grid
						key={type.name}
						style={{ paddingTop: 0 }}
						item
						xs={12}
						md={6}
						lg={3}
					>
						<Card
							className={
								professionType === type.value
									? classes.cardActive
									: classes.card
							}
						>
							<CardActionArea onClick={(e) => setProfessionType(type.value)}>
								<CardHeader
									classes={{
										root: classes.cardHeader,
										action: classes.cardHeaderAction,
										content:
											professionType === type.value
												? classes.cardContentActive
												: classes.cardContent,
									}}
									action={
										<div style={{ padding: 0 }}>
											{professionType === type.value ? (
												<CheckCircleIcon
													style={{ color: theme.palette.tertiary.main }}
												/>
											) : (
												<RadioButtonUncheckedIcon
													style={{ color: 'rgba(5, 16, 51, 0.1)' }}
												/>
											)}
										</div>
									}
									title={type.name}
								/>
							</CardActionArea>
						</Card>
					</Grid>
				))}
			</Grid>
			<Grid container className={classes.gridContainer} spacing={3}>
				<Grid item xs={12} md={5}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography style={{ marginRight: 8 }} variant='body2'>
							Agent Number
						</Typography>
						<Tooltip content={printAgentNoString()} />
					</div>
					<TextField
						name='agentId'
						value={profile.agentId}
						onChange={handleInputChange}
						variant='filled'
						fullWidth
						placeholder='Enter Agent Number'
						margin='dense'
						error={!!errorMessages[`agentId`]}
						helperText={errorMessages[`agentId`] && errorMessages[`agentId`]}
					/>
				</Grid>
				{isDesktopOrLaptop && <Grid item md={7} />}
				<Grid item xs={12} md={7}>
					<Typography variant='body2'>Representing Principals</Typography>
					<Autocomplete
						style={{ marginTop: 8, marginBottom: 4 }}
						inputValue={inputVal}
						onChange={(event, newValue) => {
							handleAddPrincipal(newValue);
						}}
						fullWidth
						filterSelectedOptions
						clearOnBlur
						clearOnEscape
						id='select-principal'
						options={PrincipalList.filter(
							(val) =>
								principals.findIndex(
									(principal) => val.code === principal.code
								) === -1
						)}
						noOptionsText=''
						getOptionSelected={(option, value) => value.code === option.code}
						getOptionLabel={(option) => option.name || ''}
						renderInput={(params) => (
							<TextField
								{...params}
								variant='filled'
								placeholder='Search or Select a Principal'
								onChange={(e) => {
									if (e.target.value !== '' || e.target.value !== null) {
										setInputVal(e.target.value);
									}
								}}
								error={!!errorMessages[`selectedPrincipals`]}
								helperText={
									errorMessages[`selectedPrincipals`] &&
									errorMessages[`selectedPrincipals`]
								}
							/>
						)}
					/>
				</Grid>
				{isDesktopOrLaptop && <Grid item md={4} />}
				<Grid item xs={12} md={8}>
					<Box>
						{principals.length > 0 &&
							principals.map((principal, index) => (
								<PrincipalCard
									key={principal.code}
									index={index}
									principal={principal}
									handlePrincipalInputChange={handlePrincipalInputChange}
									handleRemovePrincipal={handleRemovePrincipal}
									errorMessages={errorMessages}
								/>
							))}
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default Step1;
