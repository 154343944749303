import Joi from 'joi';

const RecordPayment = Joi.object({
	paymentDate: Joi.date().label('Payment Date').iso().required(),
	paymentMethod: Joi.string()
		.label('Payment Method')
		.trim()
		.min(1)
		.max(50)
		.required(),
	paymentNotes: Joi.string()
		.label('Payment Notes')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),
}).unknown(true);

export default RecordPayment;
