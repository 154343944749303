import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import React from 'react';

const useStyles = makeStyles((theme) => ({}));

const Step5 = (props) => {
	const { importResults } = props;

	const classes = useStyles();
	const theme = useTheme();

	const printErrorRecords = () => {
		return importResults.errorArray.map((row, index) => (
			<Grid
				key={index}
				container
				spacing={2}
				wrap='nowrap'
				style={{ marginTop: index !== 0 && 8 }}
			>
				<Grid item style={{ display: 'flex' }}>
					<Typography variant='body2'>Row:</Typography>
					<Typography variant='body1' style={{ marginLeft: 8 }}>
						{row.excelRow}
					</Typography>
				</Grid>
				<Grid item style={{ display: 'flex' }}>
					<Typography variant='body2'>Remarks:</Typography>
					<div style={{ marginLeft: 8 }}>
						{row.remarks.length > 0 &&
							row.remarks.map((remark, index) => (
								<Typography key={index} variant='body1'>
									{remark}
								</Typography>
							))}
					</div>
				</Grid>
			</Grid>
		));
	};

	const printSkippedRecords = () => {
		return importResults.skipArray.map((row, index) => (
			<Grid
				key={index}
				container
				spacing={2}
				wrap='nowrap'
				style={{ marginTop: index !== 0 && 8 }}
			>
				<Grid item style={{ display: 'flex' }}>
					<Typography variant='body2'>Row:</Typography>
					<Typography variant='body1' style={{ marginLeft: 8 }}>
						{row.excelRow}
					</Typography>
				</Grid>
				<Grid item style={{ display: 'flex' }}>
					<Typography variant='body2'>Remarks:</Typography>
					<Typography variant='body1' style={{ marginLeft: 8 }}>
						{row.remarks}
					</Typography>
				</Grid>
			</Grid>
		));
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography variant='h3'>Import Complete</Typography>
			<div
				style={{
					height: '49vh',
					overflowY: 'auto',
					marginTop: 16,
					padding: 24,
					borderRadius: 8,
					backgroundColor: theme.palette.secondary.light,
				}}
			>
				{importResults && (
					<div style={{ display: 'flex', alignItems: 'center', padding: 24 }}>
						<CheckCircleIcon style={{ color: '#7FA572', marginRight: 16 }} />
						<Typography variant='body2'>
							{importResults.successCount} out of {importResults.totalCount}{' '}
							client records have been successfully imported
						</Typography>
					</div>
				)}
				{importResults && importResults.skipCount > 0 && (
					<>
						<Divider />
						<div style={{ display: 'flex', alignItems: 'center', padding: 24 }}>
							<WarningOutlinedIcon
								style={{ color: theme.palette.error.light, marginRight: 16 }}
							/>
							<Typography variant='body2'>
								{importResults.skipCount} out of {importResults.totalCount}{' '}
								client records skipped
							</Typography>
						</div>
					</>
				)}
				{importResults && importResults.errorCount > 0 && (
					<>
						<Divider />
						<div
							style={{ display: 'flex', alignItems: 'flex-start', padding: 24 }}
						>
							<WarningOutlinedIcon
								style={{ color: theme.palette.error.light, marginRight: 16 }}
							/>
							<div style={{ width: '70%' }}>
								<Typography variant='body2'>
									{importResults.errorCount} out of {importResults.totalCount}{' '}
									client records failed to be imported
								</Typography>
								<Typography variant='body1' style={{ color: 'F58F91' }}>
									Please review and address the list of records below and
									re-upload again
								</Typography>
							</div>
						</div>
					</>
				)}
				{importResults && importResults.skipCount > 0 && (
					<>
						<Divider />
						<div style={{ padding: 24 }}>
							<Typography variant='body2' style={{ marginBottom: 16 }}>
								Skipped Records
							</Typography>
							{printSkippedRecords()}
						</div>
					</>
				)}
				{importResults && importResults.errorCount > 0 && (
					<>
						<Divider />
						<div style={{ padding: 24 }}>
							<Typography variant='body2' style={{ marginBottom: 16 }}>
								Error Records
							</Typography>
							{printErrorRecords()}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Step5;
