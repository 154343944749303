import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: 9999,
		color: theme.palette.primary.contrastText,
		display: 'flex',
		flexDirection: 'column',
	},
}));

export default function CustomBackdrop(props) {
	const { text, open } = props;
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Backdrop className={classes.backdrop} open={open}>
			<CircularProgress color='inherit' style={{ width: 90, height: 90 }} />
			<Typography
				color='textPrimary'
				style={{
					padding: 25,
					fontWeight: 'bold',
					color: theme.palette.primary.contrastText,
				}}
			>
				{text}
			</Typography>
		</Backdrop>
	);
}
