import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from '../../components/Navigation';
import { withAuthentication } from '../../components/Session';
import { LoggerProvider } from '../../utils/contexts/logger';
import { base } from '../../themes/';

console.log(`AppVer: ${process.env.REACT_APP_VERSION}`);

const App = () => (
	<LoggerProvider>
		<ThemeProvider theme={base}>
			<BrowserRouter
				getUserConfirmation={() => {
					/* Empty callback to block the default browser prompt */
				}}
			>
				<DndProvider backend={MultiBackend} options={HTML5toTouch}>
					<Navigation />
				</DndProvider>
			</BrowserRouter>
		</ThemeProvider>
	</LoggerProvider>
);

export default withAuthentication(App);
