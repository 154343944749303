import { Button, IconButton, Popover, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ReplayIcon from '@material-ui/icons/Replay';
import TuneIcon from '@material-ui/icons/Tune';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	filterMenu: {
		padding: 15,
		minWidth: 265,
	},
	filterType: {
		fontWeight: 600,
		padding: '10px 0px',
	},
	resetFilter: {
		padding: 5,
		fontWeight: 500,
	},
}));

const textOperators = [
	{ value: 'contains', label: 'contains' },
	{ value: 'equals', label: 'equals' },
	{ value: 'is empty', label: 'is empty' },
	{ value: 'is not empty', label: 'is not empty' },
];

const arrayOperators = [
	{ value: 'contains', label: 'contains' },
	{ value: 'is empty', label: 'is empty' },
	{ value: 'is not empty', label: 'is not empty' },
];

const intOperators = [
	{ value: '=', label: '=' },
	{ value: '!=', label: '!=' },
	{ value: '>', label: '>' },
	{ value: '>=', label: '>=' },
	{ value: '<', label: '<' },
	{ value: '<=', label: '<=' },
	{ value: 'is empty', label: 'is empty' },
	{ value: 'is not empty', label: 'is not empty' },
];

const FilterMenu = (props) => {
	const initFilter = {
		processor: 'And',
		column: props.columns[0].name,
		type: props.columns[0].type,
		operator:
			props.columns[0].type === 'string' ||
			props.columns[0].type === 'object' ||
			props.columns[0].type === 'array'
				? 'contains'
				: '=',
		value: '',
	};

	const classes = useStyles({});
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleResetFilter = () => {
		props.setFilterList([initFilter]);
	};

	const handleAddFilter = () => {
		let updatedFilterList = [...props.filterList];
		let newFilter = {
			processor: props.filterList[0].processor,
			column: props.columns[0].name,
			type: props.columns[0].type,
			operator:
				props.columns[0].type === 'string' ||
				props.columns[0].type === 'object' ||
				props.columns[0].type === 'array'
					? 'contains'
					: '=',
			value: '',
		};

		updatedFilterList.push(newFilter);
		props.setFilterList(updatedFilterList);
	};

	const handleRemoveFilter = (key) => {
		let updatedFilterList = [...props.filterList].filter((el, index) => {
			return index !== key;
		});
		props.setFilterList(updatedFilterList);
	};

	const handleFilterChange = (e, index) => {
		const {
			target: { value, name },
		} = e;
		let updatedFilterList = [...props.filterList];

		if (name === 'processor') {
			updatedFilterList.forEach((filter) => (filter.processor = value));
		} else if (name === 'column') {
			updatedFilterList[index][name] = value;
			if (value === 'company' || value === 'contacts' || value === 'tags') {
				updatedFilterList[index].type = 'array';
			} else if (value === 'amount' || value === 'transactionNo') {
				updatedFilterList[index].type = 'number';
			} else if (value === 'client') {
				updatedFilterList[index].type = 'object';
			} else {
				updatedFilterList[index].type = 'string';
			}
			if (updatedFilterList[index].type !== 'number') {
				updatedFilterList[index].operator = 'contains';
			} else {
				updatedFilterList[index].operator = '=';
			}
		} else {
			updatedFilterList[index][name] = value;
		}
		props.setFilterList(updatedFilterList);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'filterMenu' : undefined;

	return (
		<div>
			<Button
				variant='outlined'
				color='primary'
				className={classes.button}
				startIcon={<TuneIcon />}
				onClick={handleClick}
			>
				Filters
			</Button>

			<Popover
				anchorEl={anchorEl}
				className={classes.columnsPopOver}
				id={id}
				onClose={handleClose}
				open={open}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<div className={classes.filterMenu}>
					<Button
						color='default'
						onClick={(e) => handleResetFilter()}
						className={classes.button}
						startIcon={<ReplayIcon />}
					>
						Reset Filter
					</Button>
					{props.filterList.map((filter, index) => (
						<Grid key={index} container spacing={3}>
							{props.filterList.length > 1 && (
								<Grid item xs={1}>
									<IconButton onClick={() => handleRemoveFilter(index)}>
										<ClearOutlinedIcon />
									</IconButton>
								</Grid>
							)}
							{index > 0 ? (
								<Grid item xs={2}>
									<Typography color='textPrimary' variant='body1' align='left'>
										Operators
									</Typography>
									<Select
										native
										name='processor'
										value={filter.processor}
										inputProps={{
											style: { color: 'unset' },
										}}
										onChange={(e) => handleFilterChange(e, index)}
										disabled={index > 1}
										fullWidth
										margin='dense'
										style={{ marginTop: 8, marginBottom: 4 }}
									>
										<option key={0} value='And'>
											And
										</option>
										<option key={1} value='Or'>
											Or
										</option>
									</Select>
								</Grid>
							) : (
								<Grid item xs={2} />
							)}
							<Grid item xs={3}>
								<Typography color='textPrimary' variant='body1' align='left'>
									Columns
								</Typography>
								<Select
									native
									name='column'
									value={filter.column}
									inputProps={{
										style: { color: 'unset' },
									}}
									onChange={(e) => handleFilterChange(e, index)}
									fullWidth
									margin='dense'
									style={{ marginTop: 8, marginBottom: 4 }}
								>
									{props.columns.map((column, index) => {
										return (
											<option key={index} value={column.name}>
												{column.label}
											</option>
										);
									})}
								</Select>
							</Grid>

							<Grid item xs={3}>
								<Typography color='textPrimary' variant='body1' align='left'>
									Operators
								</Typography>
								<Select
									native
									name='operator'
									value={filter.operator}
									inputProps={{
										style: { color: 'unset' },
									}}
									onChange={(e) => handleFilterChange(e, index)}
									fullWidth
									margin='dense'
									style={{ marginTop: 8, marginBottom: 4 }}
								>
									{(filter.type === 'string' || filter.type === 'object') && (
										<>
											{textOperators.map((operator, index) => {
												return (
													<option key={index} value={operator.value}>
														{operator.label}
													</option>
												);
											})}
										</>
									)}
									{filter.type === 'array' && (
										<>
											{arrayOperators.map((operator, index) => {
												return (
													<option key={index} value={operator.value}>
														{operator.label}
													</option>
												);
											})}
										</>
									)}
									{filter.type === 'number' && (
										<>
											{intOperators.map((operator, index) => {
												return (
													<option key={index} value={operator.value}>
														{operator.label}
													</option>
												);
											})}
										</>
									)}
								</Select>
							</Grid>

							<Grid item xs={3}>
								<Typography color='textPrimary' variant='body1' align='left'>
									Value
								</Typography>
								<TextField
									name='value'
									value={filter.value}
									onChange={(e) => handleFilterChange(e, index)}
									InputProps={{
										style: { color: 'unset' },
									}}
									disabled={
										filter.operator === 'is empty' ||
										filter.operator === 'is not empty'
											? true
											: false
									}
									fullWidth
									margin='dense'
								/>
							</Grid>
						</Grid>
					))}
					<Button
						color='default'
						onClick={handleAddFilter}
						className={classes.button}
						startIcon={<AddOutlinedIcon />}
					>
						Add Filter
					</Button>
				</div>
			</Popover>
		</div>
	);
};

export default FilterMenu;
