import Joi from 'joi';

const ClaimInfo = Joi.object({
	accepted: Joi.boolean().label('Accepted Flag').required(),
	claimAmt: Joi.number()
		.label('Claim Amount')
		.min(0)
		.precision(2)
		.when('accepted', {
			is: true,
			then: Joi.required(),
			otherwise: Joi.optional().allow(null),
		}),
	incidentDate: Joi.date()
		.label('Incident Date')
		.iso()
		.max('now')
		.when('accepted', {
			is: true,
			then: Joi.required(),
			otherwise: Joi.optional().allow(null),
		}),
	insurerNotes: Joi.string()
		.label('Insurer Notes')
		.trim()
		.min(1)
		.max(1000)
		.optional()
		.allow(''),

	rejReason: Joi.string()
		.label('Reject Reason')
		.trim()
		.min(1)
		.max(50)
		.when('accepted', {
			is: false,
			then: Joi.required(),
			otherwise: Joi.optional().allow(''),
		}),
	rejRemarks: Joi.string()
		.label('Reject Remarks')
		.trim()
		.min(1)
		.max(1000)
		.when('rejReason', {
			is: 'Other',
			then: Joi.required(),
			otherwise: Joi.optional().allow(''),
		}),
}).unknown(true);

export default ClaimInfo;
