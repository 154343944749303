import Joi from 'joi';

const Principal = Joi.object({
	name: Joi.string().label('Name').trim().min(1).max(1000).required(),
	shortName: Joi.string().label('Short Name').trim().min(1).max(50).required(),
	code: Joi.string().label('Code').trim().min(1).max(50).required(),
	monthValue: Joi.number()
		.label('Month Value')
		.min(0)
		.precision(2)
		.optional()
		.allow(''),
	yearValue: Joi.number()
		.label('Year Value')
		.min(0)
		.precision(2)
		.optional()
		.allow(''),
	hotline: Joi.string()
		.label('Hotline')
		.trim()
		.min(1)
		.max(50)
		.pattern(/^[0-9+\s#)(-]*$/, '')
		.messages({
			'string.pattern.base': `"Hotline" should only contain Numbers (0-9) and Symbols (#+-)`,
		})
		.optional()
		.allow(''),
	email: Joi.string()
		.label('Email')
		.trim()
		.email({ tlds: { allow: false } })
		.optional()
		.allow(''),
	employmentDate: Joi.date()
		.label('Employment Date')
		.iso()
		.optional()
		.allow(null),
}).unknown(true);

export default Principal;
