import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useState } from 'react';
import SourceTypes from '../../../../../components/Data/sourceTypes.json';
import TagMenu from '../../../../../components/TagMenu';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		marginTop: 8,
		marginBottom: 4,
	},
	likeChip: {
		margin: theme.spacing(0.5),
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.background.default,
	},
	dislikeChip: {
		margin: theme.spacing(0.5),
		backgroundColor: theme.palette.text.secondary,
		color: theme.palette.background.default,
	},
	addTagChip: {
		margin: theme.spacing(0.5),
		color: theme.palette.tertiary.main,
		border: `1px solid ${theme.palette.tertiary.main}`,
	},
}));

export default function RelationshipInfo(props) {
	const {
		paneRef,
		loading,
		contact,
		setContact,
		editable,
		handleInputChange,

		errorMessages,
	} = props;
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [tagType, setTagType] = useState(null);
	const [tagErrorMessages, setTagErrorMessages] = useState({});

	const openTagMenu = (e, type) => {
		return setAnchorEl(paneRef), setOpen(true), setTagType(type);
	};
	const closeTagMenu = () => {
		return setAnchorEl(null), setOpen(!open), setTagType(null);
	};

	const getTags = (type) => {
		let tags = [];
		if (type === 'likes' && contact.tags.likes !== undefined) {
			tags = contact.tags.likes.map((tag, index) => (
				<li style={{ float: 'left' }} key={index}>
					<Chip
						label={tag}
						className={classes.likeChip}
						onDelete={editable ? () => handleDeleteTag(tag, type) : undefined}
						color='primary'
					/>
				</li>
			));
		}
		if (type === 'dislikes' && contact.tags.dislikes !== undefined) {
			tags = contact.tags.dislikes.map((tag, index) => (
				<li style={{ float: 'left' }} key={index}>
					<Chip
						label={tag}
						className={classes.dislikeChip}
						onDelete={editable ? () => handleDeleteTag(tag, type) : undefined}
						color='primary'
					/>
				</li>
			));
		}
		if (editable) {
			tags.push(
				<li style={{ float: 'left' }} key='key'>
					<Chip
						label='Add Tag'
						className={classes.addTagChip}
						onClick={(e) => openTagMenu(e, type)}
						variant='outlined'
						color='primary'
					/>
				</li>
			);
		}
		return tags;
	};

	const handleUpdateTags = (tags, type) => {
		let exists = false;
		if (type === 'likes') {
			exists = [...contact.tags.dislikes].some((item) => tags.includes(item));
		}
		if (type === 'dislikes') {
			exists = [...contact.tags.likes].some((item) => tags.includes(item));
		}

		if (exists) {
			setTagErrorMessages((prev) => ({
				...prev,
				tagLabel: 'Some of the tags has already been assigned before!',
			}));
		} else {
			let updatedTags = {
				likes: type === 'likes' ? [...tags] : [...contact.tags.likes],
				dislikes: type === 'dislikes' ? [...tags] : [...contact.tags.dislikes],
			};

			setTagErrorMessages((prev) => {
				const { tagLabel, ...errorMessages } = prev;
				return errorMessages;
			});
			setContact((prev) => ({
				...prev,
				tags: updatedTags,
			}));
			closeTagMenu();
		}
	};

	const handleDeleteTag = (tag, type) => {
		let updatedTags = {
			likes:
				type === 'likes'
					? [...contact.tags.likes].filter((props) => props !== tag)
					: [...contact.tags.likes],
			dislikes:
				type === 'dislikes'
					? [...contact.tags.dislikes].filter((props) => props !== tag)
					: [...contact.tags.dislikes],
		};

		setContact((prev) => ({
			...prev,
			tags: updatedTags,
		}));
	};

	return (
		<>
			<Typography color='textPrimary' variant='body2' align='left'>
				Where did you get to know this contact?
			</Typography>
			{loading ? (
				<Skeleton variant='rect' animation='wave' />
			) : (
				<>
					{editable ? (
						<Select
							native
							name='source'
							value={contact.source || ''}
							onChange={handleInputChange}
							variant='filled'
							inputProps={{
								style: { color: 'unset' },
							}}
							fullWidth
							autoWidth={true}
							margin='dense'
							style={{ marginTop: 8, marginBottom: 4 }}
						>
							<option key={0} value=''></option>
							{SourceTypes.map((type, index) => {
								return (
									<option key={index} value={type}>
										{type}
									</option>
								);
							})}
						</Select>
					) : (
						<TextField
							name='source'
							value={contact.source || ''}
							disabled={true}
							InputProps={{
								disableUnderline: true,
								style: { color: 'unset' },
							}}
							variant='standard'
							fullWidth
							margin='dense'
						/>
					)}
				</>
			)}

			{contact.source && contact.source === 'Other' && (
				<>
					<Typography color='textPrimary' variant='body2' align='left'>
						Please Specify
					</Typography>
					{loading ? (
						<Skeleton variant='rect' animation='wave' />
					) : (
						<>
							{editable ? (
								<TextField
									name='sourceInfo'
									value={contact.sourceInfo || ''}
									onChange={handleInputChange}
									InputProps={{
										style: { color: 'unset' },
									}}
									variant={'filled'}
									fullWidth
									margin='dense'
									error={!!errorMessages[`sourceInfo`]}
									helperText={
										errorMessages[`sourceInfo`] && errorMessages[`sourceInfo`]
									}
								/>
							) : (
								<Typography
									color='textPrimary'
									variant='body1'
									align='left'
									style={{
										marginTop: 8,
										marginBottom: 4,
										overflowWrap: 'anywhere',
									}}
								>
									{contact.sourceInfo}
								</Typography>
							)}
						</>
					)}
				</>
			)}
			<Typography color='textPrimary' variant='body2' align='left'>
				Contact Likes
			</Typography>
			{loading ? (
				<Skeleton variant='rect' animation='wave' width='100%' height={40} />
			) : (
				<ul className={classes.root}>
					{contact.tags ? getTags('likes') : null}
				</ul>
			)}
			<Typography color='textPrimary' variant='body2' align='left'>
				Contact Dislikes
			</Typography>
			{loading ? (
				<Skeleton variant='rect' animation='wave' width='100%' height={40} />
			) : (
				<ul className={classes.root}>
					{contact.tags ? getTags('dislikes') : null}
				</ul>
			)}
			<Typography color='textPrimary' variant='body2' align='left'>
				Notes
			</Typography>
			{loading ? (
				<Skeleton variant='rect' animation='wave' width='100%' height={130} />
			) : (
				<TextField
					name='notes'
					value={contact.notes || ''}
					onChange={handleInputChange}
					disabled={editable ? false : true}
					InputProps={{
						disableUnderline: !editable,
						style: { color: 'unset' },
					}}
					multiline
					variant={editable ? 'filled' : 'standard'}
					fullWidth
					margin='dense'
					minRows={4}
					error={!!errorMessages[`notes`]}
					helperText={errorMessages[`notes`] && errorMessages[`notes`]}
				/>
			)}
			<TagMenu
				open={open}
				anchorEl={anchorEl}
				position='left'
				type={tagType}
				handleUpdateTags={handleUpdateTags}
				closeTagMenu={() => closeTagMenu()}
				errorMessages={tagErrorMessages}
				formTags={contact.tags}
			/>
		</>
	);
}
