import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	checkbox: {
		'&:hover': {
			backgroundColor: 'transparent !important',
		},
	},
}));

const Step3 = (props) => {
	const classes = useStyles();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
	const {
		orgProfile,
		setOrgProfile,
		noOrganization,
		setNoOrganization,
		errorMessages,
	} = props;

	const handleInputChange = (e) => {
		const {
			target: { value, name },
		} = e;

		setOrgProfile((prev) => ({ ...prev, [name]: value }));
	};

	return (
		<>
			<Typography variant='h1'>About your company</Typography>
			<Typography
				variant='body1'
				style={{
					marginTop: isDesktopOrLaptop ? 24 : 16,
					marginBottom: isDesktopOrLaptop ? 48 : 32,
				}}
			>
				If you own a Company (Agency) or a part thereof, please fill-in your
				Company details to enable Invoices in Uuoni.
			</Typography>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: isDesktopOrLaptop ? 'calc(60% + 24px)' : '100%',
				}}
			>
				<FormControlLabel
					style={{ marginBottom: 24 }}
					checked={noOrganization}
					control={
						<Checkbox
							style={{ padding: '0px 9px' }}
							classes={{ root: classes.checkbox }}
							color='primary'
							disableRipple
							disableFocusRipple
						/>
					}
					label={
						<Typography
							variant='h4'
							style={{ fontWeight: 500 }}
							color='textPrimary'
						>
							I am operating as an individual professional. I am not part of or
							own a company
						</Typography>
					}
					name='noOrganization'
					labelPlacement='end'
					onChange={(e) => setNoOrganization(e.target.checked)}
				/>
				{!noOrganization && (
					<>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography color='textPrimary' variant='body2'>
									Company Name
								</Typography>
								<TextField
									name='name'
									value={orgProfile.name}
									onChange={handleInputChange}
									placeholder='Enter Company Name'
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`name`]}
									helperText={errorMessages[`name`] && errorMessages[`name`]}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography color='textPrimary' variant='body2'>
									Unique Entity Number (UEN)
								</Typography>
								<TextField
									name='uen'
									value={orgProfile.uen}
									onChange={handleInputChange}
									placeholder='Enter UEN'
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`uen`]}
									helperText={errorMessages[`uen`] && errorMessages[`uen`]}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography color='textPrimary' variant='body2'>
									Agency Number
								</Typography>
								<TextField
									name='agencyNo'
									value={orgProfile.agencyNo}
									onChange={handleInputChange}
									placeholder='Enter Agency Number'
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`agencyNo`]}
									helperText={
										errorMessages[`agencyNo`] && errorMessages[`agencyNo`]
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography color='textPrimary' variant='body2'>
									Address
								</Typography>
								<TextField
									name='address'
									value={orgProfile.address}
									onChange={handleInputChange}
									placeholder='Enter Address'
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`address`]}
									helperText={
										errorMessages[`address`] && errorMessages[`address`]
									}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography color='textPrimary' variant='body2'>
									City
								</Typography>
								<TextField
									name='city'
									value={orgProfile.city}
									onChange={handleInputChange}
									placeholder='Enter City'
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`city`]}
									helperText={errorMessages[`city`] && errorMessages[`city`]}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography color='textPrimary' variant='body2'>
									Unit Number
								</Typography>
								<TextField
									name='unitNo'
									value={orgProfile.unitNo}
									onChange={handleInputChange}
									placeholder='Enter Unit Number'
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`unitNo`]}
									helperText={
										errorMessages[`unitNo`] && errorMessages[`unitNo`]
									}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography color='textPrimary' variant='body2'>
									Postal / Zip Code
								</Typography>
								<TextField
									name='postalCode'
									value={orgProfile.postalCode}
									onChange={handleInputChange}
									placeholder='Enter Postal / Zip Code'
									variant='filled'
									fullWidth
									margin='dense'
									error={!!errorMessages[`postalCode`]}
									helperText={
										errorMessages[`postalCode`] && errorMessages[`postalCode`]
									}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</div>
		</>
	);
};

export default Step3;
