import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import NormalView from './NormalView';
import ClaimRequestView from './ClaimRequestView';
import SelfGeneratedView from './SelfGeneratedView';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '96px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export default function RequestViewer(props) {
	const {
		transaction,
		setTransaction,
		setTriggerUpdate,
		setTriggerUpdateActivities,
		disableEdit,
	} = props;
	const classes = useStyles();
	const theme = useTheme();

	const getView = () => {
		if (
			transaction.metaData.hasClientRequest &&
			!transaction.metaData.clientResponded
		) {
			return (
				<div className={classes.root}>
					<Typography color='textSecondary' variant='subtitle1'>
						This section will display information once Documents have been
						uploaded by Client.
					</Typography>
				</div>
			);
		} else {
			if (transaction.isSelfGenerated) {
				return (
					<SelfGeneratedView
						transaction={transaction}
						setTransaction={setTransaction}
						setTriggerUpdate={setTriggerUpdate}
						setTriggerUpdateActivities={setTriggerUpdateActivities}
						disableEdit={disableEdit}
					/>
				);
			} else {
				if (transaction.transactionType === 'Claims') {
					return (
						<ClaimRequestView
							transaction={transaction}
							setTransaction={setTransaction}
							setTriggerUpdate={setTriggerUpdate}
							setTriggerUpdateActivities={setTriggerUpdateActivities}
						/>
					);
				} else {
					return (
						<NormalView
							transaction={transaction}
							setTransaction={setTransaction}
							setTriggerUpdate={setTriggerUpdate}
							setTriggerUpdateActivities={setTriggerUpdateActivities}
							disableEdit={disableEdit}
						/>
					);
				}
			}
		}
	};

	return <>{getView()}</>;
}
