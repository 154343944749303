import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import firebase from 'firebase/compat/app';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import {
	Dashboard,
	Clients,
	Contacts,
	Company,
	Import,
	Transaction,
	Invoice,
	Logout,
	Help,
} from '../../components/Icons';
import React, { useEffect, useState, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
	BrowserRouter,
	Link as RouterLink,
	useLocation,
	Redirect,
	useHistory,
} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import { toast } from 'react-toastify';
import AdPanel from '../../components/AdPanel';
import SideNav from '../../components/SideNav';
import api from '../../config/api';
import Routes from '../../config/public_routes';
import Onboarding from '../../views/Onboarding';
import NotificationMenu from '../Notifications';
import ProfileMenu from '../Profile';
import Subscription from '../../views/Settings/subscription/overview';
import { AuthUserContext, SessionTimeout } from '../Session';
import { LoggerContext } from '../../utils/contexts/logger';
import Loader from '../../components/Loader';
import PricingPlan from '../../components/PricingPlan';
import GlobalSearch from '../../components/GlobalSearch';
// import OnboardingChecklist from '../../components/OnboardingChecklist';
// import './index.css';
import useStyles from './styles';
import { io } from 'socket.io-client';
import { ToastContainer } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { differenceInCalendarDays, format } from 'date-fns';
import ExperienceMessage from '../../components/ExperienceMessage';
import { NavOpenProvider, useNavOpen } from '../../utils/contexts/navOpen';

const CustomAppBar = (props) => {
	const { socketInstance, user, isValidSubscription, isDesktopOrLaptop } =
		props;
	const { navOpen, handleNavDrawerToggle } = useNavOpen();
	const classes = useStyles();
	const theme = useTheme();
	const [resourceOpen, setResourceOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);

	const toggleSearchMenu = () => {
		if (!searchOpen) {
			setSearchOpen(true);
		}
		setSearchOpen(!searchOpen);
	};

	return (
		<AppBar position='fixed' className={classes.appBar}>
			<Toolbar
				className={isDesktopOrLaptop ? classes.toolBar : classes.toolBarMobile}
				disableGutters
			>
				{searchOpen ? (
					<>
						<GlobalSearch
							isDesktopOrLaptop={isDesktopOrLaptop}
							setSearchOpen={setSearchOpen}
						/>
						<IconButton
							style={{
								backgroundColor: searchOpen && '#2372C3',
								marginLeft: 16,
							}}
							size={isDesktopOrLaptop ? 'medium' : 'small'}
							onClick={() => {
								toggleSearchMenu();
							}}
						>
							<SearchIcon classes={{ root: classes.headerIconMobile }} />
						</IconButton>
					</>
				) : (
					<>
						{isValidSubscription && (
							<IconButton
								color='inherit'
								aria-label='open drawer'
								// onClick={() => handleNavDrawerToggle()}
								onClick={handleNavDrawerToggle}
								edge='start'
								className={
									isDesktopOrLaptop
										? classes.menuButton
										: classes.menuButtonMobile
								}
							>
								{navOpen ? <CloseIcon /> : <MenuIcon />}
							</IconButton>
						)}
						<div className='brandContainer'>
							<Button
								component={RouterLink}
								to={'/'}
								style={{ padding: 0, backgroundColor: 'transparent' }}
								disableRipple
								disableFocusRipple
								disableElevation
							>
								<img
									className='brand-logo'
									style={{
										minWidth: 100,
										maxWidth: 143,
										marginRight: 16,
									}}
									src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
									alt=''
								/>
							</Button>
						</div>
						{isValidSubscription && (
							<>
								<div
									id='global-search'
									className={
										isDesktopOrLaptop ? classes.grow : classes.growMobile
									}
									style={{ width: isDesktopOrLaptop && 500 }}
								>
									{isDesktopOrLaptop ? (
										<GlobalSearch
											isDesktopOrLaptop={isDesktopOrLaptop}
											setSearchOpen={setSearchOpen}
										/>
									) : (
										<IconButton
											style={{
												backgroundColor: searchOpen && '#2372C3',
											}}
											size={isDesktopOrLaptop ? 'medium' : 'small'}
											onClick={() => {
												toggleSearchMenu();
											}}
										>
											<SearchIcon
												classes={{ root: classes.headerIconMobile }}
											/>
										</IconButton>
									)}
								</div>
								{isDesktopOrLaptop && (
									<div id='environment-chip' className={classes.grow}>
										{process.env.REACT_APP_ENVIRONMENT !== 'production' && (
											<Chip
												className={classes.chip}
												style={{
													color: '#F1F3FA',
													backgroundColor: '#75ACE4',
												}}
												label={process.env.REACT_APP_ENVIRONMENT}
											/>
										)}
									</div>
								)}
								<div
									id='notification-menu'
									className={
										isDesktopOrLaptop ? classes.grow : classes.growMobile
									}
								>
									<NotificationMenu
										socketInstance={socketInstance}
										isDesktopOrLaptop={isDesktopOrLaptop}
									/>
								</div>
							</>
						)}
						<div
							id='profile-menu'
							className={isDesktopOrLaptop ? classes.grow : classes.growMobile}
						>
							<ProfileMenu
								isValidSubscription={isValidSubscription}
								user={user}
								isDesktopOrLaptop={isDesktopOrLaptop}
							/>
						</div>
						{/* <Divider orientation='vertical' flexItem style={{ margin: 10 }} /> */}

						{/* {isDesktopOrLaptop && (
			<div className={classes.grow}>
				<IconButton
					color='inherit'
					aria-label='open drawer'
					onClick={handleResourceDrawerToggle}
					edge='end'
					// className={classes.menuButton}
				>
					{resourceOpen ? <CloseIcon /> : <ListOutlinedIcon />}
				</IconButton>
			</div>
		)} */}
					</>
				)}
			</Toolbar>
		</AppBar>
	);
};

const menuList = [
	{
		key: 'dashboard',
		value: 'dashboard',
		display: 'Dashboard',
		icon: <Dashboard />,
		subMenu: false,
		subMenuItems: [],
		requireOrg: false,
	},
	// {
	// 	key: 'calendar',
	// 	value: 'calendar',
	// 	display: 'Calendar',
	// 	icon: <TodayOutlinedIcon />,
	// 	subMenu: false,
	// 	subMenuItems: [],
	//  requireOrg: false,
	// },
	{
		key: 'clients',
		value: 'contacts',
		display: 'Clients',
		icon: <Clients />,
		subMenu: true,
		subMenuItems: [
			{
				key: 'contacts',
				value: 'contacts',
				display: 'Contacts',
				icon: <Contacts />,
			},
			{
				key: 'companies',
				value: 'companies',
				display: 'Companies',
				icon: <Company />,
			},
			{
				key: 'import',
				value: 'import/clients',
				display: 'Import Clients',
				icon: <Import />,
			},
		],
		requireOrg: false,
	},
	{
		key: 'transactions',
		value: 'transactions',
		display: 'Transactions',
		icon: <Transaction />,
		subMenu: false,
		subMenuItems: [],
		requireOrg: false,
	},
	{
		key: 'invoices',
		value: 'invoices',
		display: 'Invoices',
		icon: <Invoice />,
		subMenu: false,
		subMenuItems: [],
		requireOrg: true,
	},
	// {
	// 	key: 'reports',
	// 	value: 'reports',
	// 	display: 'Reports',
	// 	icon: <BarChartOutlinedIcon />,
	// 	subMenu: false,
	// 	subMenuItems: [],
	//  requireOrg: false,
	// },
	// {
	// 	key: 'team',
	// 	value: 'team',
	// 	display: 'Team',
	// 	icon: <SupervisorAccountOutlinedIcon />,
	// 	subMenu: false,
	// 	subMenuItems: [],
	// 	forTeams: true,
	//  requireOrg: false,
	// },
];

const NavigationAuth = (props) => {
	const location = useLocation();
	const history = useHistory();
	const { user, isDesktopOrLaptop } = props;
	const logger = useContext(LoggerContext);
	const { navOpen, handleNavDrawerToggle } = useNavOpen();
	const classes = useStyles();
	const theme = useTheme();
	const isValidSubscription = user.userProfile.subscriptionStatus !== 'paused';

	const [socketInstance, setSocketInstance] = useState(null);
	const [resourceOpen, setResourceOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);
	const [subMenus, setSubMenus] = useState({});
	const [loading, setLoading] = useState(true);
	const [showExpiryAlert, setShowExpiryAlert] = useState(false);

	const initPendoInstance = (user) => {
		// This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
		// This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
		// Call this function in your authentication promise handler or callback when your visitor and account id values are available
		// Please use Strings, Numbers, or Bools for value types.
		window.pendo.initialize({
			visitor: {
				id: user.authUser._delegate.uid, // Required if user is logged in, default creates anonymous ID
				email: user.authUser._delegate.email, // Recommended if using Pendo Feedback, or NPS Email
				// full_name:    // Recommended if using Pendo Feedback
				// role:         // Optional
				// uid: user.authUser._delegate.uid,
				// You can add any additional visitor level key-values here,
				// as long as it's not one of the above reserved names.
			},

			account: {
				id: user.authUser._delegate.uid, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
				// name:         // Optional
				// is_paying:    // Recommended if using Pendo Feedback
				// monthly_value:// Recommended if using Pendo Feedback
				// planLevel:    // Optional
				// planPrice:    // Optional
				// creationDate: // Optional

				// You can add any additional account level key-values here,
				// as long as it's not one of the above reserved names.
			},
		});
		if (
			process.env.REACT_APP_ENVIRONMENT !== 'production' &&
			process.env.REACT_APP_ENVIRONMENT !== 'demo'
		) {
			console.log(window.pendo);
		}
	};

	useEffect(() => {
		if (!loading) {
			setLoading(true);
		}

		let socket;
		const connectSocket = () => {
			if (process.env.REACT_APP_ENVIRONMENT !== 'demo') {
				socket = io(process.env.REACT_APP_APIDOMAIN, {
					reconnection: true,
					reconnectionAttempts: 5,
					reconnectionDelay: 10000,
				});

				// Listen for version updates
				socket.on('version', (data) => {
					const serverVersion = data.version;
					if (serverVersion === 'unknown') {
						alert(data.message);
						console.log(data.message);
					} else {
						if (serverVersion !== process.env.REACT_APP_VERSION) {
							window.location.reload(true); // Force reload from the server
						}
					}
				});

				socket.on('connect', () => {
					console.log('Connected to server.');
					process.env.REACT_APP_ENVIRONMENT !== 'local' &&
						logger.info(`Connected to server: ${user.authUser.uid}`);
					socket.emit('newUser', user.authUser.uid);
					setSocketInstance(socket);
				});

				socket.on('reconnect', (attemptNumber) => {
					console.log('Reconnected to server. Attempt:', attemptNumber);
					process.env.REACT_APP_ENVIRONMENT !== 'local' &&
						logger.info(`Reconnected to server: ${user.authUser.uid}`);
					socket.emit('newUser', user.authUser.uid);
					setSocketInstance(socket);
				});

				socket.on('reconnect_failed', () => {
					process.env.REACT_APP_ENVIRONMENT !== 'local' &&
						logger.fatal(
							'Failed to reconnect to server after multiple attempts.'
						);
					console.log('Failed to reconnect to server after multiple attempts.');
					toast.error(
						'Failed to reconnect to server after multiple attempts. Please Refresh the page.'
					);
					setSocketInstance(null);
				});

				if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
					socket.onAny((event, ...args) => {
						console.log(event, args);
					});
				}
			}
		};
		connectSocket();

		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible' && !socket.connected) {
				connectSocket();
			}
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);

		// initPendoInstance(user);
		setShowExpiryAlert(user.userProfile.trialExpiring);
		setLoading(false);

		return () => {
			if (process.env.REACT_APP_ENVIRONMENT !== 'demo') {
				if (socket) {
					socket.disconnect();
				}
			}
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [user]);

	const handleResourceDrawerToggle = () => {
		if (!resourceOpen) {
			setResourceOpen(true);
		}
		setResourceOpen(!resourceOpen);
	};

	const handleSignOut = async (e) => {
		e.preventDefault();

		if (process.env.REACT_APP_ENVIRONMENT === 'demo') {
			window.location.href = 'https://www.uuoni.com';
		} else {
			await firebase
				.auth()
				.signOut()
				.then(function () {
					console.log('Successfully signed out.');
					history.push('/');
				})
				.catch(function (error) {
					console.log(error);
					console.log('An error occurred');
				});
		}
	};

	if (loading)
		return (
			<div style={{ height: '100vh' }}>
				<Loader size='lg' />
			</div>
		);
	return (
		<>
			{!user.userProfile.onboarded ? (
				<>
					<Redirect to='/onboarding' />
					<div
						className={classes.root}
						style={{ flexDirection: !isDesktopOrLaptop && 'column' }}
					>
						<CssBaseline />
						{!isDesktopOrLaptop && (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '100%',
									padding: '16px 24px',
									backgroundColor: theme.palette.primary.main,
								}}
							>
								<Button
									component={RouterLink}
									to={'/'}
									style={{ backgroundColor: 'transparent', padding: 0 }}
									disableRipple
									disableFocusRipple
									disableElevation
								>
									<img
										style={{ width: 143 }}
										src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
										alt='brand-logo'
									/>
								</Button>
								<Button
									style={{
										marginLeft: 'auto',
										color: theme.palette.primary.contrastText,
										backgroundColor: 'transparent',
										padding: 0,
									}}
									variant='text'
									onClick={handleSignOut}
									disableRipple
									disableFocusRipple
									disableElevation
									startIcon={
										<Logout
											style={{
												fontSize: 24,
											}}
											colorcode={theme.palette.primary.contrastText}
										/>
									}
								>
									<Typography variant='subtitle1'>Sign Out</Typography>
								</Button>
							</div>
						)}
						<Onboarding />
					</div>
				</>
			) : (
				<div className={classes.root}>
					<NavOpenProvider>
						<CssBaseline />
						<CustomAppBar
							socketInstance={socketInstance}
							user={user}
							isValidSubscription={isValidSubscription}
							isDesktopOrLaptop={isDesktopOrLaptop}
						/>
						{showExpiryAlert && (
							<Alert
								className={classes.alertBar}
								variant='filled'
								severity='info'
							>
								<span>
									{`You have `}
									<b>
										{differenceInCalendarDays(
											new Date(user.userProfile.subscriptionExpiryDate),
											new Date()
										)}
									</b>
									{` days left in your free trial period. After `}
									<b>
										{format(
											new Date(user.userProfile.subscriptionExpiryDate),
											'MMM dd, yyyy'
										)}
									</b>
									{`, you will be automatically subscribed if a valid payment method
								was provided.`}
								</span>
								<Button
									variant='contained'
									color='primary'
									style={{
										marginLeft: 'auto',
									}}
									component={RouterLink}
									to='/settings/subscription'
								>
									Manage Subscription
								</Button>
								<IconButton
									style={{ marginLeft: 16, padding: 0 }}
									disableRipple
									disableFocusRipple
									disableTouchRipple
									onClick={() => setShowExpiryAlert(false)}
								>
									<CloseIcon />
								</IconButton>
							</Alert>
						)}
						{isValidSubscription && (
							<SideNav user={user} isDesktopOrLaptop={isDesktopOrLaptop} />
						)}
						<main
							className={
								isDesktopOrLaptop
									? clsx(classes.content, {
											[classes.contentShift]: navOpen,
									  })
									: classes.contentShift
							}
						>
							{/* <div className={classes.drawerHeader} /> */}
							<Routes.PrivateRoutes isValidSubscription={isValidSubscription} />
							{/* <OnboardingChecklist /> */}
						</main>
						{/* Resources Menu */}
						{/* <Drawer
				className={classes.resourceDrawer}
				variant='persistent'
				anchor='right'
				open={resourceOpen}
				classes={{
					paper: isDesktopOrLaptop
						? classes.drawerPaper
						: classes.drawerPaperMobile,
				}}
			>
				<Toolbar style={{ minHeight: 80 }} />
				<div className={classes.drawerContainer}>Resources here</div>
			</Drawer> */}
					</NavOpenProvider>
				</div>
			)}
			{process.env.REACT_APP_ENVIRONMENT === 'production' && (
				<SessionTimeout
					onTimeout={async () =>
						await firebase
							.auth()
							.signOut()
							.then(function () {
								console.log('Successfully signed out.');
								history.push('/login');
							})
							.catch(function (error) {
								console.log(error);
								console.log('An error occurred');
							})
					}
				/>
			)}
		</>
	);
};

const NavigationPayment = (props) => {
	const { user, isDesktopOrLaptop } = props;
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();
	const [success, setSuccess] = useState(false);
	const [sessionId, setSessionId] = useState('');
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [yearly, setYearly] = useState(false);
	const [quantity, setQuantity] = useState(0);
	const [loading, setLoading] = useState(false);
	const [showExpiryAlert, setShowExpiryAlert] = useState(false);

	useEffect(() => {
		if (user.userProfile?.existingCust) {
			setShowExpiryAlert(true);
		}
	}, []);

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		if (query.get('success')) {
			setLoading(true);
			console.log('payment success');
			setSuccess(true);
			setSessionId(query.get('session_id'));
			setTimeout(() => {
				history.push('/dashboard');
			}, 2000);
		}
	}, [sessionId]);

	useEffect(() => {
		if (selectedPlan) {
			console.log('getting session');
			getStripeCheckoutSession();
		}
	}, [selectedPlan]);

	const getStripeCheckoutSession = async () => {
		if (!loading) {
			setLoading(true);
		}
		try {
			const response = await api.post('stripe-checkout', {
				email: user.authUser._delegate.email,
				name: selectedPlan.name,
				priceId: selectedPlan.priceId,
				quantity: quantity,
				existingCust: user.userProfile?.existingCust ? true : false,
			});
			if (response.data.ok) {
				window.location = response.data.sessionUrl;
			}
		} catch (error) {
			console.log('error', error);
			setLoading(false);
		}
	};

	const handleSignOut = async (e) => {
		e.preventDefault();

		if (process.env.REACT_APP_ENVIRONMENT === 'demo') {
			window.location.href = 'https://www.uuoni.com';
		} else {
			await firebase
				.auth()
				.signOut()
				.then(function () {
					console.log('Successfully signed out.');
					history.push('/');
				})
				.catch(function (error) {
					console.log(error);
					console.log('An error occurred');
				});
		}
	};

	if (loading)
		return (
			<div style={{ height: '100vh' }}>
				<Loader size='lg' />
			</div>
		);
	if (!success) {
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position='fixed' className={classes.appBar}>
					<Toolbar className={classes.toolBar} disableGutters>
						<div className='brandContainer'>
							<Button
								component={RouterLink}
								to={'/'}
								style={{ padding: 0, backgroundColor: 'transparent' }}
								disableRipple
								disableFocusRipple
								disableElevation
							>
								<img
									className='brand-logo'
									style={{ minWidth: 100, maxWidth: 143, marginRight: 16 }}
									src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
									alt=''
								/>
							</Button>
						</div>
						<Button
							style={{
								backgroundColor: 'transparent',
							}}
							onClick={handleSignOut}
							disableRipple
							disableFocusRipple
							disableElevation
						>
							<Logout
								style={{
									marginRight: 16,
									fontSize: 24,
								}}
								colorcode={theme.palette.primary.contrastText}
							/>
							<Typography
								variant='body1'
								style={{ color: theme.palette.primary.contrastText }}
							>
								Sign Out
							</Typography>
						</Button>
					</Toolbar>
				</AppBar>
				{showExpiryAlert && (
					<Alert className={classes.alertBar} variant='filled' severity='info'>
						<span>
							{`Your subscription has ended on `}
							<b>
								{format(
									new Date(user.userProfile.subscriptionExpiryDate),
									'MMM dd, yyyy'
								)}
							</b>
							{`. To continue enjoying our features, please subscribe!`}
						</span>
						<IconButton
							style={{ marginLeft: 'auto', padding: 0 }}
							disableRipple
							disableFocusRipple
							disableTouchRipple
							onClick={() => setShowExpiryAlert(false)}
						>
							<CloseIcon />
						</IconButton>
					</Alert>
				)}
				<main className={classes.content}>
					<PricingPlan
						setSelectedPlan={setSelectedPlan}
						yearly={yearly}
						setYearly={setYearly}
						quantity={quantity}
						setQuantity={setQuantity}
						isExistingCustomer={user.userProfile?.existingCust}
					/>
				</main>
			</div>
		);
	}
};

const NavigationNonAuth = (props) => {
	const { isDesktopOrLaptop } = props;
	const classes = useStyles();
	let location = useLocation();

	const showAdPanel = (location) => {
		switch (location) {
			case '/forgot-password':
			case '/login':
			case '/register': {
				return true;
			}
			default: {
				return false;
			}
		}
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<main className={classes.landingContent}>
				<Grid
					container
					direction={isDesktopOrLaptop ? 'row' : 'column'}
					justifyContent='center'
					alignItems='stretch'
					style={{ height: isDesktopOrLaptop && '100vh' }}
				>
					{showAdPanel(location.pathname) ? (
						<>
							<Grid item xs={isDesktopOrLaptop ? 4 : 12}>
								<AdPanel type={location.pathname} />
							</Grid>
							<Grid item xs={isDesktopOrLaptop ? 8 : 12}>
								<Routes.PublicRoutes />
							</Grid>
						</>
					) : (
						<Grid item xs={12}>
							<Routes.PublicRoutes />
						</Grid>
					)}
				</Grid>
			</main>
		</div>
	);
};

const Navigation = () => {
	const classes = useStyles();
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const [hideExperienceMessage, setHideExperienceMessage] = useState(false);

	return (
		<AuthUserContext.Consumer>
			{(credentials) => (
				<>
					<ToastContainer
						className={
							credentials.authUser &&
							credentials.verified &&
							classes.toastContainerAuth
						}
						position='top-right'
						autoClose={5000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick={false}
						rtl={false}
						pauseOnFocusLoss
						draggable={false}
						pauseOnHover
						theme='colored'
					/>
					{credentials.authUser &&
					credentials.verified &&
					!credentials.userProfile.suspended ? (
						<>
							{!isDesktopOrLaptop && !hideExperienceMessage ? (
								<ExperienceMessage
									setHideExperienceMessage={setHideExperienceMessage}
								/>
							) : (
								<>
									{credentials.userProfile.subscribed ? (
										<>
											<NavigationAuth
												user={credentials}
												isDesktopOrLaptop={isDesktopOrLaptop}
											/>
										</>
									) : (
										<>
											<NavigationPayment
												user={credentials}
												isDesktopOrLaptop={isDesktopOrLaptop}
											/>
										</>
									)}
								</>
							)}
						</>
					) : (
						<NavigationNonAuth isDesktopOrLaptop={isDesktopOrLaptop} />
					)}
				</>
			)}
		</AuthUserContext.Consumer>
	);
};

export default Navigation;
