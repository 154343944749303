import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import clsx from 'clsx';
import matchSorter from 'match-sorter';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Tooltip from '../../../../../../../components/Tooltip';
import FileUploader from '../../../../../../../components/FileUploader';
import FileInfoCard from '../../../../../../../components/FileInfoCard';
import TransactionModal from '../../../../../../../components/Modals/transactions';

moment.updateLocale('language_code', {
	invalidDate: 'DD/MM/YYYY',
});

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const useStyles = makeStyles((theme) => ({
	recommendedChip: {
		fontWeight: 500,
		fontSize: '0.875rem',
		marginLeft: 16,
		color: theme.palette.background.secondary,
		backgroundColor: theme.palette.success.main,
	},
	selectedChip: {
		fontWeight: 500,
		fontSize: '0.875rem',
		marginLeft: 16,
		color: theme.palette.background.secondary,
		backgroundColor: theme.palette.tertiary.main,
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	card: {
		'&:not(:first-child)': {
			marginTop: 16,
		},
		backgroundColor: theme.palette.background.secondary,
	},
	cardHeader: {
		padding: '8px 24px',
		backgroundColor: theme.palette.secondary.main,
	},
	cardContent: {
		padding: 24,
	},
}));

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

const currencyFormatter = new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency: 'SGD',
});

export default function QuotationCard(props) {
	const {
		index,
		id,
		editable,
		errorMessages,
		requestData,
		quotation,
		handleRemoveQuote,
		handleInputChange,
		handleDateChange,
		handleFileChange,
		toggleOpen,
		isSelfGenerated,
		setTriggerUpdate,
		disableRemove,
		disableEdit,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState(null);
	const [modalData, setModalData] = useState({});

	const handleFiles = (e, files) => {
		e.preventDefault();
		handleFileChange(id, files);
	};

	const handleModalOpen = (body, quotation) => {
		setModalType(body);
		setModalData({ ...requestData, quotation: quotation });
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalType(null);
	};

	const canProcess =
		!disableEdit && !isSelfGenerated && !editable && quotation.selectedByClient;

	return (
		<Card className={classes.card} elevation={0}>
			<CardHeader
				className={classes.cardHeader}
				style={{
					border:
						quotation.selectedByClient &&
						`2px solid ${theme.palette.tertiary.main}`,
				}}
				title={
					<>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Typography
								color='textPrimary'
								variant='h5'
								style={{
									color:
										quotation.selectedByClient && theme.palette.tertiary.main,
								}}
							>
								{quotation.quoteName
									? quotation.quoteName
									: `Quote ${index + 1}`}
							</Typography>
							{quotation.recommended && (
								<Chip
									className={classes.recommendedChip}
									icon={<ThumbUpOutlinedIcon style={{ marginRight: -6 }} />}
									label='Recommended'
								/>
							)}
							{quotation.selectedByClient && (
								<Chip
									className={classes.selectedChip}
									icon={<CheckIcon style={{ marginRight: -6 }} />}
									label='Selected'
								/>
							)}
							<div style={{ marginLeft: 'auto' }}>
								{!disableRemove && editable && (
									<Button
										color='primary'
										startIcon={<RemoveCircleOutlineIcon />}
										onClick={() => handleRemoveQuote(id)}
									>
										Remove
									</Button>
								)}
								{canProcess && (
									<Button
										onClick={() =>
											handleModalOpen(
												'sendRequestForProcessingQuote',
												quotation
											)
										}
										variant='contained'
										color='primary'
									>
										Process Quote
									</Button>
								)}
								<IconButton
									className={clsx(classes.expand, {
										[classes.expandOpen]: quotation.open,
									})}
									style={{ marginLeft: 8, marginRight: -8, padding: 8 }}
									onClick={() => toggleOpen(id)}
									aria-expanded={quotation.open}
									aria-label='show more'
								>
									<ExpandMoreIcon />
								</IconButton>
							</div>
						</div>
					</>
				}
			/>
			<Collapse in={quotation.open} timeout='auto' unmountOnExit>
				<CardContent classes={{ root: classes.cardContent }}>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6} md={4}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Quotation Expiry Date
							</Typography>
							{editable ? (
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										name='expiryDate'
										disableToolbar
										placeholder='DD/MM/YYYY'
										inputVariant='filled'
										format='dd/MM/yyyy'
										openTo='year'
										views={['year', 'month', 'date']}
										margin='dense'
										fullWidth
										value={quotation.expiryDate ? quotation.expiryDate : null}
										autoOk
										variant='inline'
										invalidDateMessage='Invalid Date Format'
										onChange={(date) =>
											handleDateChange(id, 'expiryDate', date)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										error={
											!!errorMessages[
												`updatedQuoteList.${props.index}.expiryDate`
											]
										}
										helperText={
											errorMessages[
												`updatedQuoteList.${props.index}.expiryDate`
											] &&
											errorMessages[
												`updatedQuoteList.${props.index}.expiryDate`
											]
										}
									/>
								</MuiPickersUtilsProvider>
							) : (
								<Typography
									color='textPrimary'
									variant='body1'
									align='left'
									style={{ marginTop: 8 }}
								>
									{moment(quotation.expiryDate).format('DD/MM/YYYY')}
								</Typography>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={editable ? 8 : 4}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Period of Insurance
							</Typography>
							{editable ? (
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<KeyboardDatePicker
											name='startDate'
											disableToolbar
											placeholder='DD/MM/YYYY'
											inputVariant='filled'
											format='dd/MM/yyyy'
											openTo='year'
											views={['year', 'month', 'date']}
											margin='dense'
											fullWidth
											value={quotation.startDate ? quotation.startDate : null}
											autoOk
											variant='inline'
											invalidDateMessage='Invalid Date Format'
											onChange={(date) =>
												handleDateChange(id, 'startDate', date)
											}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											error={
												!!errorMessages[
													`updatedQuoteList.${props.index}.startDate`
												]
											}
											helperText={
												errorMessages[
													`updatedQuoteList.${props.index}.startDate`
												] &&
												errorMessages[
													`updatedQuoteList.${props.index}.startDate`
												]
											}
										/>
										<span style={{ margin: 8 }}> - </span>
										<KeyboardDatePicker
											name='endDate'
											disableToolbar
											placeholder='DD/MM/YYYY'
											inputVariant='filled'
											format='dd/MM/yyyy'
											openTo='year'
											views={['year', 'month', 'date']}
											margin='dense'
											fullWidth
											value={quotation.endDate ? quotation.endDate : null}
											autoOk
											variant='inline'
											invalidDateMessage='Invalid Date Format'
											onChange={(date) => handleDateChange(id, 'endDate', date)}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											error={
												!!errorMessages[
													`updatedQuoteList.${props.index}.endDate`
												]
											}
											helperText={
												errorMessages[
													`updatedQuoteList.${props.index}.endDate`
												] &&
												errorMessages[`updatedQuoteList.${props.index}.endDate`]
											}
										/>
									</div>
								</MuiPickersUtilsProvider>
							) : (
								<Typography
									color='textPrimary'
									variant='body1'
									align='left'
									style={{ marginTop: 8 }}
								>
									{`${moment(quotation.startDate).format(
										'DD/MM/YYYY'
									)} - ${moment(quotation.endDate).format('DD/MM/YYYY')}`}
								</Typography>
							)}
						</Grid>
						<Box width='100%' />
						<Grid item xs={12} sm={6} md={4}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography
									style={{ marginRight: 8 }}
									color='textPrimary'
									variant='body2'
									align='left'
								>
									Total Premium
								</Typography>
								<Tooltip
									content={`Enter Premium inclusive of Goods & Services Tax (GST) if
								applicable. This Premium will be reflected in your Invoice
								to your Client.`}
								/>
							</div>
							{editable ? (
								<TextField
									name='premiumAmt'
									value={quotation.premiumAmt ?? ''}
									type='number'
									onChange={(e) => handleInputChange(e, id)}
									placeholder='Enter Total Premium'
									variant='filled'
									fullWidth
									margin='dense'
									InputProps={{
										startAdornment: (
											<InputAdornment style={{ marginTop: 0 }} position='start'>
												<AttachMoneyOutlinedIcon />
											</InputAdornment>
										),
									}}
									error={
										!!errorMessages[
											`updatedQuoteList.${props.index}.premiumAmt`
										]
									}
									helperText={
										errorMessages[
											`updatedQuoteList.${props.index}.premiumAmt`
										] &&
										errorMessages[`updatedQuoteList.${props.index}.premiumAmt`]
									}
								/>
							) : (
								<Typography
									color='textPrimary'
									variant='body1'
									align='left'
									style={{ marginTop: 8 }}
								>
									{quotation.premiumAmt &&
										currencyFormatter.format(quotation.premiumAmt)}
								</Typography>
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography
									style={{ marginRight: 8 }}
									color='textPrimary'
									variant='body2'
									align='left'
								>
									Sum Insured
								</Typography>
								<Tooltip
									content={`Enter "0" if Sum Insured is available in the
										Quotation/Policy Document`}
								/>
							</div>
							{editable ? (
								<TextField
									name='sumInsured'
									value={quotation.sumInsured ?? ''}
									type='number'
									onChange={(e) => handleInputChange(e, id)}
									placeholder='Enter Sum Insured'
									variant='filled'
									fullWidth
									margin='dense'
									InputProps={{
										startAdornment: (
											<InputAdornment style={{ marginTop: 0 }} position='start'>
												<AttachMoneyOutlinedIcon />
											</InputAdornment>
										),
									}}
									error={
										!!errorMessages[
											`updatedQuoteList.${props.index}.sumInsured`
										]
									}
									helperText={
										errorMessages[
											`updatedQuoteList.${props.index}.sumInsured`
										] &&
										errorMessages[`updatedQuoteList.${props.index}.sumInsured`]
									}
								/>
							) : (
								<Typography
									color='textPrimary'
									variant='body1'
									align='left'
									style={{ marginTop: 8 }}
								>
									{quotation.sumInsured
										? currencyFormatter.format(quotation.sumInsured)
										: 'Refer to Policy Document'}
								</Typography>
							)}
						</Grid>
						<Box width='100%' />
						<Grid item xs={12}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Notes
							</Typography>
							{editable ? (
								<TextField
									name='notes'
									value={quotation.notes}
									onChange={(e) => handleInputChange(e, id)}
									placeholder='Enter Notes'
									variant='filled'
									fullWidth
									margin='dense'
									multiline
									minRows={4}
									error={
										!!errorMessages[`updatedQuoteList.${props.index}.notes`]
									}
									helperText={
										errorMessages[`updatedQuoteList.${props.index}.notes`] &&
										errorMessages[`updatedQuoteList.${props.index}.notes`]
									}
								/>
							) : (
								<Typography
									color='textPrimary'
									variant='body1'
									align='left'
									style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}
								>
									{quotation.notes ? quotation.notes : 'N/A'}
								</Typography>
							)}
						</Grid>
						<Grid item xs={12}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography
									style={{ marginRight: 8 }}
									color='textPrimary'
									variant='body2'
									align='left'
								>
									Upload Quotation Document
								</Typography>
								<Tooltip content={printReqString()} />
							</div>
							<div
								style={{
									width: '100%',
									marginTop: 8,
									marginBottom: 4,
								}}
							>
								{editable ? (
									<FileUploader
										fileLimit={1}
										files={quotation.fileData}
										handleFileChange={handleFiles}
										formErrorMessage={
											errorMessages[
												`updatedQuoteList.${props.index}.fileData`
											] &&
											errorMessages[`updatedQuoteList.${props.index}.fileData`]
										}
									/>
								) : (
									<>
										{quotation.responseDocument && (
											<FileInfoCard file={quotation.responseDocument} />
										)}
									</>
								)}
							</div>
						</Grid>
						{quotation.recommended && (
							<Grid item xs={12}>
								<Typography color='textPrimary' variant='body2' align='left'>
									Recommendation Remarks
								</Typography>
								{editable ? (
									<TextField
										name='recommendationRemarks'
										value={quotation.recommendationRemarks}
										onChange={(e) => handleInputChange(e, id)}
										placeholder='Enter Recommendation Remarks'
										variant='filled'
										fullWidth
										margin='dense'
										multiline
										minRows={4}
										error={
											!!errorMessages[
												`updatedQuoteList.${props.index}.recommendationRemarks`
											]
										}
										helperText={
											errorMessages[
												`updatedQuoteList.${props.index}.recommendationRemarks`
											] &&
											errorMessages[
												`updatedQuoteList.${props.index}.recommendationRemarks`
											]
										}
									/>
								) : (
									<Typography
										color='textPrimary'
										variant='body1'
										align='left'
										style={{
											marginTop: 8,
											overflowWrap: 'anywhere',
											whiteSpace: 'pre-wrap',
										}}
									>
										{quotation.recommendationRemarks
											? quotation.recommendationRemarks
											: 'N/A'}
									</Typography>
								)}
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Collapse>
			{modalOpen && (
				<TransactionModal
					open={modalOpen}
					handleClose={handleModalClose}
					body={modalType}
					transactionId={requestData.transactionId}
					data={modalData}
					setTriggerUpdate={setTriggerUpdate}
					size={
						modalType === 'sendClientRequestForQuoteSelection' ? 'xl' : 'sm'
					}
				/>
			)}
		</Card>
	);
}
