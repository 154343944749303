import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

// The Context
const NavOpenContext = React.createContext({});

// NavOpen Provider
const NavOpenProvider = ({ children }) => {
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const [navOpen, setNavOpen] = useState(isDesktopOrLaptop ? true : false);

	const handleNavDrawerToggle = () => {
		setNavOpen((prevNavOpen) => !prevNavOpen);
	};

	// Context values passed to consumer
	const value = {
		navOpen, // <------ Expose Value to Consumer
		setNavOpen, // <------ Expose Setter to Consumer
		handleNavDrawerToggle,
		// loading,
	};

	return (
		<NavOpenContext.Provider value={value}>{children}</NavOpenContext.Provider>
	);
};

// NavOpen Consumer
const NavOpenConsumer = ({ children }) => {
	return (
		<NavOpenContext.Consumer>
			{(context) => {
				if (context === undefined) {
					throw new Error(
						'NavOpenConsumer must be used within NavOpenProvider'
					);
				}
				return children(context);
			}}
		</NavOpenContext.Consumer>
	);
};

// useNavOpen Hook
const useNavOpen = () => {
	const context = React.useContext(NavOpenContext);
	if (context === undefined)
		throw new Error('useNavOpen must be used within NavOpenProvider');
	return context;
};

export { NavOpenProvider, NavOpenConsumer, useNavOpen, NavOpenContext };
