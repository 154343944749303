import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import React, { useRef, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import './dropzone.css';
import { Delete } from '../Icons';

const useStyles = makeStyles((theme) => ({
	cardContainer: {},
	cardContent: {
		display: 'flex',
		alignItems: 'center',
		padding: 16,
		'&:last-child': {
			padding: 16,
		},
	},
}));

function validateFileExtension(name) {
	if (!name) {
		return false;
	}

	let validExtensions = [
		'xls',
		'xlsx',
		'xlsm',
		'csv',
		'doc',
		'docx',
		'pdf',
		'txt',
		'jpg',
		'jpeg',
		'png',
		'bmp',
		'avi',
		'mp4',
		'mpeg',
		'webm',
	];

	const fileExtension = name.split('.').pop().toLowerCase();

	if (validExtensions.includes(fileExtension)) {
		return true;
	} else {
		return false;
	}
}

function validateFileType(type) {
	if (!type) {
		return false;
	}

	let validTypes = [
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.ms-excel',
		'text/csv',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/pdf',
		'text/plain',
		'image/jpeg',
		'image/jpg',
		'image/png',
		'image/bmp',
		'video/x-msvideo',
		'video/mp4',
		'video/mpeg',
		'video/webm',
	];

	if (validTypes.includes(type)) {
		return true;
	} else {
		return false;
	}
}

const FileUploader = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const fileInputRef = useRef();

	const { fileLimit, files, handleFileChange, documentType, formErrorMessage } =
		props;
	const [errorMessage, setErrorMessage] = useState('');
	const [obj, setObj] = useState(null);

	const preventDefault = (e) => {
		e.preventDefault();
	};

	const dragOver = (e) => {
		preventDefault(e);
	};

	const dragEnter = (e) => {
		preventDefault(e);
	};

	const dragLeave = (e) => {
		preventDefault(e);
	};

	const fileDrop = (e) => {
		preventDefault(e);
		const files = e.dataTransfer.files;
		if (files.length) {
			handleFiles(e, files);
		}
	};

	const filesSelected = (e) => {
		if (fileInputRef.current.files.length) {
			handleFiles(e, fileInputRef.current.files);
		}
	};

	const fileInputClicked = () => {
		fileInputRef.current.click();
	};

	const handleFiles = (e, files) => {
		if (files.length > fileLimit) {
			setErrorMessage(`Only ${fileLimit} file(s) permitted`);
		} else {
			let result = validateFile(files[0]);
			if (result.valid) {
				setErrorMessage('');
				handleFileChange(e, files);
			} else {
				setErrorMessage(result.errorMessage);
			}
		}
	};

	const getAcceptedTypes = (documentType) => {
		switch (documentType) {
			case 'Document': {
				return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf, text/plain';
			}
			case 'Photo': {
				return 'image/jpeg, image/jpg, image/png, image/bmp';
			}
			case 'Video': {
				return 'video/x-msvideo, video/mp4, video/mpeg, video/webm';
			}
			default: {
				return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf, text/plain';
			}
		}
	};

	const validateFile = (file) => {
		let valid = true;
		let errorMessage = '';
		let validFile =
			validateFileExtension(file.name) && validateFileType(file.type);
		let validSize = file.size < 52428800;

		if (!validFile) {
			valid = false;
			errorMessage = 'Invalid file type';
		} else if (!validSize) {
			valid = false;
			errorMessage = 'File size cannot exceed 50mb';
		}

		return {
			valid: valid,
			errorMessage: errorMessage,
		};
	};

	const fileSize = (size) => {
		if (size === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const i = Math.floor(Math.log(size) / Math.log(k));
		return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
	};

	const fileType = (fileName) => {
		return (
			fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
			fileName
		);
	};

	const removeFile = (e, key) => {
		let updatedFiles = [...files].filter((file, index) => index !== key);

		e.preventDefault();
		setErrorMessage('');
		if (fileInputRef.current !== null) {
			fileInputRef.current.value = null;
		}
		handleFileChange(e, updatedFiles);
	};

	return (
		<>
			{/* <div className='wrapper'> */}
			{files && files.length ? (
				[...files].map((file, index) => (
					<Card key={index} className={classes.cardContainer}>
						<CardContent classes={{ root: classes.cardContent }}>
							<div style={{ width: 24 }}>
								<FileIcon
									labelUppercase
									extension={fileType(file.name)}
									{...defaultStyles[fileType(file.name)]}
								/>
							</div>
							<Typography
								style={{
									marginLeft: 24,
								}}
								variant='body1'
								color='textPrimary'
								component='span'
							>
								{file.name}
							</Typography>
							<Typography
								style={{
									marginLeft: 24,
									marginRight: 24,
								}}
								variant='body1'
								color='textSecondary'
								component='span'
							>
								{fileSize(file.size)}
							</Typography>
							{/* {obj && <embed src={obj} width='250' height='200' />} */}
							<IconButton
								style={{ marginLeft: 'auto' }}
								aria-label='expand row'
								size='small'
								onClick={(e) => removeFile(e, index)}
							>
								<Delete style={{ fontSize: 24 }} />
							</IconButton>
						</CardContent>
					</Card>
				))
			) : (
				<>
					<div
						className='drop-container'
						onDragOver={dragOver}
						onDragEnter={dragEnter}
						onDragLeave={dragLeave}
						onDrop={fileDrop}
						onClick={fileInputClicked}
					>
						<CloudUploadOutlinedIcon
							style={{
								width: 60,
								height: 60,
								color: theme.palette.text.secondary,
							}}
						/>
						<div className='drop-message'>
							Drag and drop file, or click to browse computer
						</div>
						<input
							ref={fileInputRef}
							className='file-input'
							type='file'
							multiple
							onChange={(e) => filesSelected(e)}
						/>
					</div>
					{errorMessage && (
						<span className='file-error-message'>{errorMessage}</span>
					)}
					{formErrorMessage && (
						<span className='file-error-message'>{formErrorMessage}</span>
					)}
				</>
			)}
			{/* </div> */}
		</>
	);
};

export default FileUploader;
