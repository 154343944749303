import Backdrop from '../../../components/Backdrop';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link, useParams, useHistory, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import NavPrompt from '../../../components/NavPrompt';
import FilterMenu from '../../../components/FilterMenu';
import ClientModal from '../../../components/Modals/clients.js';
import api from '../../../config/api';
import { filterData } from '../../../utils/filterData';
import DataRow from './contactRow';
import ContactProfile from './InfoPane';
import {
	ClosePaneArrow,
	ContactAdd,
	Delete,
	Export,
} from '../../../components/Icons/index.js';

const useStyles = makeStyles((theme) => ({
	button: {},
	content: {
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	contentShift: {
		marginRight: 0,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawer: {
		flexShrink: 0,
		height: '100%',
	},
	deleteIcon: {
		padding: 0,
		marginLeft: 16,
	},
	deleteText: {
		color: theme.palette.primary.main,
	},
}));

const columns = [
	{ label: 'First Name', name: 'firstName', type: 'string' },
	{ label: 'Last Name', name: 'lastName', type: 'string' },
	{ label: 'Email', name: 'email', type: 'string' },
	{ label: 'Contact No.', name: 'mobileNo', type: 'string' },
];

const initFilter = {
	processor: 'And',
	column: columns[0].name,
	type: columns[0].type,
	operator:
		columns[0].type === 'string' || columns[0].type === 'array'
			? 'contains'
			: '=',
	value: '',
};

export default function Contacts(props) {
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const { id } = useParams();
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();
	const csvLink = useRef();

	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [backdropOpen, setBackdropOpen] = useState(false);
	const [selectedContact, setSelectedContact] = useState(null);
	const [contacts, setContacts] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [editable, setEditable] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogBody, setDialogBody] = useState(0);
	const [filterList, setFilterList] = useState([initFilter]);
	const [sortOptions, setSortOptions] = useState({});
	const [viewTags, setViewTags] = useState(false);
	const [viewCompanies, setViewCompanies] = useState(false);
	const [triggerListUpdate, setTriggerListUpdate] = useState(true);
	const [error, setError] = useState(false);
	const [errorMessages, setErrorMessages] = useState({});

	useEffect(() => {
		if (triggerListUpdate) getContacts();
	}, [triggerListUpdate]);

	useEffect(() => {
		if (id) {
			setOpen(true);
			setSelectedContact(id);
			setEditable(false);
		} else {
			setOpen(false);
			setViewTags(false);
			setViewCompanies(false);
			setSelectedContact(null);
		}
	}, [props]);

	const getContacts = () => {
		if (!loading) {
			setLoading(true);
		}
		api
			.get('contacts')
			.then((response) => {
				let bulkList = response.data
					.map((contact, index) => {
						return { ...contact, checked: false };
					})
					.sort((a, b) => {
						return new Date(b.updatedAt) - new Date(a.updatedAt);
					});
				setContacts(bulkList);
				setTriggerListUpdate(false);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const openPane = (e, contact) => {
		let target = e.target.closest('td');
		if (target === null) {
			return;
		}
		if (target.cellIndex === 0 || target.cellIndex === undefined) {
			return;
		}
		if (target.cellIndex === 4) {
			setViewCompanies(true);
		}
		if (target.cellIndex === 5) {
			setViewTags(true);
		}
		history.replace(`/contacts/${contact._id}`);
	};

	const closePane = () => {
		history.replace(`/contacts`);
	};

	const handleDelete = () => {
		setDialogOpen(false);
		setBackdropOpen(true);
		let checked = contacts.filter((props) => props.checked);
		let ids = checked.map((item) => item._id);
		api.post('contacts/bulk-delete', ids).then((res) => {
			if (res.data.ok) {
				toast.success('Contact(s) deleted!');
				setTriggerListUpdate(true);
				setError(false);
				setErrorMessages({});
				setCheckAll(false);
				setPage(0);
				closePane();
				setBackdropOpen(false);
			} else {
				toast.error('An error has occured!');
			}
		});
	};

	const handleCancel = () => {
		setDialogOpen(false);
		closePane();
		setEditable(false);
	};

	const toggleDialog = (body) => {
		if (!dialogOpen) {
			return setDialogBody(body), setDialogOpen(true);
		}
		setDialogOpen(!dialogOpen);
	};

	const totalChecked = () => {
		return contacts.filter((props) => props.checked).length;
	};

	const handleCheckAll = (e) => {
		let updatedContacts = [...contacts];
		updatedContacts.forEach((contact) => (contact.checked = e.target.checked));
		setCheckAll(e.target.checked);
		setContacts(updatedContacts);
	};

	const handleCheckbox = (e, index) => {
		let updatedContacts = [...contacts];
		updatedContacts.find((x) => x._id === index).checked = e.target.checked;
		setContacts(updatedContacts);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleSearch = (e) => {
		setPage(0);
		setSearchValue(e.target.value);
	};

	const handleClearSearch = () => {
		setPage(0);
		setSearchValue('');
	};

	const searchData = (data) => {
		if (searchValue) {
			let val = searchValue.replace(/\s+/g, ' ').toLowerCase();
			let results = data.filter((item) => {
				return Object.keys(item).some((key) => {
					if (key === 'tags') {
						return Object.keys(item[key]).some((tagType) => {
							return item[key][tagType].some((x) =>
								x.toLowerCase().includes(val.toLowerCase())
							);
						});
					} else if (
						Array.isArray(item[key]) &&
						key === 'associatedCompanies'
					) {
						return item[key].some((x) =>
							x.companyId.companyName.toLowerCase().includes(val.toLowerCase())
						);
					} else {
						return (
							typeof item[key] === 'string' &&
							item[key].toLowerCase().includes(val.toLowerCase())
						);
					}
				});
			});
			return results;
		}
		return data;
	};

	const handleExportData = () => {
		if (filteredContacts.length === 0) return;
		csvLink.current.link.click();
	};

	const handleSort = (key) => {
		setPage(0);
		setSortOptions((prevState) => ({ [key]: !prevState[key] }));
	};

	const sortData = (data) => {
		let sortedData = data;
		Object.keys(sortOptions).forEach((e) => {
			return sortedData.sort(compareByDesc(e, sortOptions[e]));
		});
		return sortedData;
	};

	const compareByDesc = (key, order) => {
		if (order) {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key].toLowerCase() < b[key].toLowerCase() ? -1 : 1;
			};
		} else {
			return function (a, b) {
				if (a[key] === undefined) return 1;
				if (b[key] === undefined) return -1;
				if (a[key] === b[key]) return 0;
				return a[key].toLowerCase() > b[key].toLowerCase() ? -1 : 1;
			};
		}
	};

	const getFilteredContacts = () => {
		let initialList = contacts;
		let sortedContacts = sortData(initialList);
		let filteredContacts = filterData(sortedContacts, filterList);
		let searchedContacts = searchData(filteredContacts);
		return searchedContacts;
	};

	const filteredContacts = getFilteredContacts();

	const emptyRows =
		rowsPerPage -
		Math.min(rowsPerPage, filteredContacts.length - page * rowsPerPage);

	const responseList = () => {
		return filteredContacts
			.slice(page * 10, page * 10 + 10)
			.map((currentContact) => (
				<DataRow
					key={currentContact._id}
					data={currentContact}
					openPane={openPane}
					handleCheckbox={handleCheckbox}
					selected={currentContact._id === selectedContact}
					open={open}
				/>
			));
	};

	const generateFileName = () => {
		let generatedDate = moment(new Date()).format('DD-MM-YYYY');
		return `contact-data-${generatedDate}.csv`;
	};

	const generateExportData = () => {
		let data = cloneDeep(filteredContacts).map((row, index) => {
			delete row._id;
			delete row.createdBy;
			delete row.orgId;
			delete row.__v;
			delete row.checked;
			if (row.tags.likes !== undefined) {
				row.likes = row.tags.likes;
			}
			if (row.tags.dislikes !== undefined) {
				row.dislikes = row.tags.dislikes;
			}
			delete row.tags;

			row.associatedCompanies = row.associatedCompanies.map((company) => {
				return `[Company Name: ${company.companyId.companyName}, UEN: ${company.companyId.uen}]`;
			});
			return row;
		});
		return data;
	};

	return (
		<>
			<NavPrompt when={editable} isRoute={false} />
			<Backdrop text='Deleting Client...' open={backdropOpen} />
			{!isDesktopOrLaptop && id ? (
				<Box style={{ width: '100%' }}>
					<Button
						style={{ marginBottom: 24 }}
						onClick={() => closePane()}
						variant='outlined'
						color='primary'
						startIcon={<NavigateBeforeIcon />}
					>
						Back
					</Button>
					{selectedContact && (
						<div className={classes.drawer}>
							<ContactProfile
								viewTags={viewTags}
								viewCompanies={viewCompanies}
								contactId={selectedContact}
								editable={editable}
								setEditable={setEditable}
								setTriggerListUpdate={setTriggerListUpdate}
							/>
						</div>
					)}
				</Box>
			) : (
				<>
					<Breadcrumbs separator='›' aria-label='breadcrumb'>
						<Typography color='inherit'>Clients</Typography>
						<Typography color='inherit'>Contacts</Typography>
						<Typography color='primary'>Contact Overview</Typography>
					</Breadcrumbs>
					<Typography variant='h2' style={{ margin: '24px 0px 0px 0px' }}>
						Contact Overview
					</Typography>
					<div margin='auto'>
						<div className='actionBar'>
							<Box display='flex' alignItems='center'>
								<Box>
									<Button
										component={Link}
										to={'/create-client/contact'}
										color='primary'
										className={classes.button}
										startIcon={
											<ContactAdd colorcode={theme.palette.tertiary.main} />
										}
									>
										New Contact
									</Button>
								</Box>
								<Box flexGrow={1}>
									<Button
										color='primary'
										className={classes.button}
										startIcon={
											<Export colorcode={theme.palette.tertiary.main} />
										}
										onClick={() => handleExportData()}
									>
										Export Contacts
									</Button>
									<CSVLink
										data={generateExportData()}
										filename={generateFileName()}
										className='hidden'
										ref={csvLink}
										target='_blank'
									/>
								</Box>
							</Box>
						</div>
						<div className='tableBar'>
							<Box display='flex' alignItems='center'>
								<Box style={{ width: 400 }}>
									<TextField
										style={{ margin: 0 }}
										value={searchValue}
										onChange={(e) => handleSearch(e)}
										placeholder='Search Contacts'
										variant='filled'
										fullWidth
										margin='dense'
										InputProps={{
											startAdornment: (
												<InputAdornment
													position='start'
													style={{
														marginTop: 0,
														color: theme.palette.grey[500],
													}}
												>
													<SearchIcon />
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														onClick={handleClearSearch}
														disableFocusRipple
														disableRipple
														style={{
															backgroundColor: 'transparent',
															display: !searchValue && 'none',
														}}
													>
														<ClearIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Box>
								<Box style={{ marginLeft: 16 }}>
									<FilterMenu
										columns={columns}
										filterList={filterList}
										setFilterList={setFilterList}
									/>
								</Box>
							</Box>
						</div>
						<div className='pagination'>
							<TablePagination
								style={{ marginLeft: 'auto' }}
								className='pagination'
								component='div'
								count={filteredContacts.length}
								rowsPerPageOptions={[]}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										'aria-label': 'rows per page',
									},
									native: true,
								}}
								onPageChange={handleChangePage}
							/>
						</div>
						<div
							className={clsx(classes.content, {
								[classes.contentShift]: open,
							})}
						>
							<Box
								display='flex'
								style={{
									overflow: 'auto',
									border: '1px solid #edf2f7',
									borderRadius: 8,
								}}
							>
								<Box style={{ width: '100%' }}>
									{(totalChecked() > 0 || open) && (
										<div
											style={{
												display: 'flex',
												width: '100%',
												height: 40,
												alignItems: 'center',
												backgroundColor:
													totalChecked() > 0
														? theme.palette.tertiary.light
														: theme.palette.background.paper,
											}}
										>
											{totalChecked() > 0 && (
												<div style={{ paddingLeft: 20, display: 'flex' }}>
													<Typography
														className={classes.deleteText}
														variant='body1'
														align='left'
													>
														{totalChecked()} Selected
													</Typography>
													<IconButton
														className={classes.deleteIcon}
														onClick={() => toggleDialog(0)}
													>
														<Delete colorcode={theme.palette.text.secondary} />
													</IconButton>
												</div>
											)}
											{open && (
												<Button
													style={{ marginLeft: 'auto', marginRight: 8 }}
													onClick={() => closePane()}
													color='primary'
													endIcon={
														<ClosePaneArrow
															colorcode={theme.palette.tertiary.main}
														/>
													}
												>
													Close Details
												</Button>
											)}
										</div>
									)}
									<table className='tableContent' cellSpacing='0'>
										<thead>
											<tr
												style={{
													height: 52,
													backgroundColor: theme.palette.background.paper,
												}}
											>
												<th className='checkBox'>
													<Checkbox
														checked={checkAll}
														onChange={(e) => handleCheckAll(e)}
														inputProps={{
															'aria-label': 'primary checkbox',
														}}
													/>
												</th>
												<th onClick={() => handleSort('firstName')}>
													<div className='aligner'>
														<div className='text'>Name</div>
														<div className='icon'>
															{sortOptions['firstName'] ? (
																<ArrowDropUpIcon />
															) : (
																<ArrowDropDownIcon />
															)}
														</div>
													</div>
												</th>
												<th onClick={() => handleSort('email')}>
													<div className='aligner'>
														<div className='text'>Email</div>
														<div className='icon'>
															{sortOptions['email'] ? (
																<ArrowDropUpIcon />
															) : (
																<ArrowDropDownIcon />
															)}
														</div>
													</div>
												</th>
												<th onClick={() => handleSort('mobileNo')}>
													<div className='aligner'>
														<div className='text'>Contact Number</div>
														<div className='icon'>
															{sortOptions['mobileNo'] ? (
																<ArrowDropUpIcon />
															) : (
																<ArrowDropDownIcon />
															)}
														</div>
													</div>
												</th>
												<th style={{ display: open && 'none' }}>Company</th>
												{/* <th onClick={() => handleSort('company')}>
												<div className='aligner'>
													<div className='text'>Company</div>
													<div className='icon'>
														{sortOptions['company'] ? (
															<ArrowDropUpIcon />
														) : (
															<ArrowDropDownIcon />
														)}
													</div>
												</div>
											</th> */}
												{/* <th style={{ display: open && 'none' }}>Latest Action</th> */}
												<th style={{ display: open && 'none' }}>
													Relationship Tags
												</th>
											</tr>
										</thead>
										<tbody>
											{loading ? (
												<tr
													style={{
														height: 640,
													}}
												>
													<td
														colSpan={6}
														className='noResults'
														style={{
															textAlign: 'center',
															border: '1px solid #edf2f7',
														}}
													>
														<CircularProgress size={80} />
													</td>
												</tr>
											) : (
												<>
													{responseList()}
													{emptyRows > 0 && emptyRows < 10 && (
														<tr
															style={{
																height: 64 * emptyRows,
															}}
														>
															<td
																colSpan={6}
																style={{
																	border: '1px solid #edf2f7',
																}}
															/>
														</tr>
													)}
												</>
											)}
											{!filteredContacts.length && !loading && (
												<tr
													style={{
														height: 640,
													}}
												>
													<td
														colSpan={6}
														className='noResults'
														style={{
															textAlign: 'center',
															border: '1px solid #edf2f7',
														}}
													>
														No Results Found
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</Box>
								{open ? (
									<Box style={{ width: '100%', minWidth: 480, maxWidth: 720 }}>
										<Slide
											direction='left'
											in={open}
											mountOnEnter
											unmountOnExit
										>
											{selectedContact && (
												<div className={classes.drawer}>
													<ContactProfile
														viewTags={viewTags}
														viewCompanies={viewCompanies}
														contactId={selectedContact}
														editable={editable}
														setEditable={setEditable}
														setTriggerListUpdate={setTriggerListUpdate}
													/>
												</div>
											)}
										</Slide>
									</Box>
								) : null}
							</Box>
						</div>
						<ClientModal
							open={dialogOpen}
							body={dialogBody}
							handleCancel={() => handleCancel()}
							handleConfirm={() => handleDelete()}
							handleClose={() => toggleDialog(0)}
						/>
					</div>
				</>
			)}
		</>
	);
}
