import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 32,
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 3px 11px 0px #818181, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
		borderRadius: 8,
	},
}));

const confirmDeleteCompanies = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Delete Client(s)
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, marginBottom: 24 }}
		>
			Are you sure you want to delete the selected companies?
		</Typography>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, color: props.theme.palette.error.main }}
		>
			Note: This action cannot be undone and all related{' '}
			<b>Notifications, Transactions and Invoices</b> will be deleted.
		</Typography>
		<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
			<Button
				style={{ marginRight: 16 }}
				onClick={props.handleClose}
				variant='outlined'
				color='primary'
			>
				Cancel
			</Button>
			<Button
				onClick={props.handleConfirm}
				variant='contained'
				style={{
					backgroundColor: props.theme.palette.error.main,
					color: props.theme.palette.background.default,
				}}
			>
				Delete
			</Button>
		</div>
	</>
);

const confirmDeleteContacts = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Delete Client(s)
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, marginBottom: 8 }}
		>
			Are you sure you want to delete the selected contact(s)?
		</Typography>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, color: props.theme.palette.error.main }}
		>
			Note: This action cannot be undone and all related{' '}
			<b>Notifications, Transactions and Invoices</b> will be deleted.
		</Typography>
		<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
			<Button
				style={{ marginRight: 16 }}
				onClick={props.handleClose}
				variant='outlined'
				color='primary'
			>
				Cancel
			</Button>
			<Button
				onClick={props.handleConfirm}
				variant='contained'
				style={{
					backgroundColor: props.theme.palette.error.main,
					color: props.theme.palette.background.default,
				}}
			>
				Delete
			</Button>
		</div>
	</>
);

const confirmDeleteTags = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Remove Tag
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, marginBottom: 24 }}
		>
			Are you sure you want to remove Tag?
		</Typography>
		<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
			<Button
				style={{ marginRight: 16 }}
				onClick={props.handleClose}
				variant='outlined'
				color='primary'
			>
				Cancel
			</Button>
			<Button onClick={props.handleConfirm} variant='contained' color='primary'>
				Delete
			</Button>
		</div>
	</>
);

const confirmDiscardChanges = (props) => (
	<>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Typography
				style={{ textTransform: 'uppercase' }}
				color='textPrimary'
				variant='h3'
			>
				Unsaved Changes
			</Typography>
			<IconButton
				style={{ marginLeft: 'auto', padding: 0 }}
				disableRipple
				disableFocusRipple
				disableTouchRipple
				onClick={props.handleClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<Typography
			variant='body1'
			color='textPrimary'
			style={{ marginTop: 24, marginBottom: 24 }}
		>
			You have unsaved changes. Do you want to discard the changes?
		</Typography>
		<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
			<Button
				style={{ marginRight: 16 }}
				onClick={props.handleClose}
				variant='outlined'
				color='primary'
			>
				Cancel
			</Button>
			<Button
				style={{
					backgroundColor: props.theme.palette.error.main,
					color: props.theme.palette.background.default,
				}}
				onClick={props.handleCancel}
				variant='contained'
			>
				Yes, discard changes
			</Button>
		</div>
	</>
);

const getBody = (props) => {
	switch (props.body) {
		case 0:
			return confirmDeleteContacts(props);
		case 1:
			return confirmDeleteTags(props);
		case 2:
			return confirmDiscardChanges(props);
		case 3:
			return confirmDeleteCompanies(props);
		default:
			return 'Unknown modal';
	}
};

const DialogBox = (props) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<div className={classes.paper}>
					{getBody({ ...props, theme: theme })}
				</div>
			</Dialog>
		</div>
	);
};

export default DialogBox;
