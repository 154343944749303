import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../../../components/Loader';
import AssocModal from '../../../../../components/Modals/associations';
import api from '../../../../../config/api';
import { Company, Delete } from '../../../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	chip: {
		marginLeft: 16,
		height: 24,
		backgroundColor: theme.palette.tertiary.main,
	},
}));

export default function CompanyInfo(props) {
	const {
		paneRef,
		loading,
		contactId,
		associatedCompanies,
		setTriggerListUpdate,
		togglePaneUpdate,
		editable,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpenMenu = (e) => {
		return setAnchorEl(paneRef), setOpen(true);
	};

	const handleCloseMenu = () => {
		return setAnchorEl(null), setOpen(!open);
	};

	const setAssociatedCompanyAsPrimary = async (e, id) => {
		try {
			e.preventDefault();
			let formData = {
				clientType: 'contact',
				clientId: contactId,
				primaryAssociationId: id,
			};
			await api.put('associations', formData).then((res) => {
				if (res.data.ok) {
					toast.success('Associations updated!');

					props.togglePaneUpdate();
				} else {
					toast.error(res.data.error);
				}
			});
		} catch (error) {
			console.log('error', error);
			toast.error(error);
		}
	};

	const handleRemoveAssociatedCompany = async (e, id) => {
		try {
			e.preventDefault();
			await api.delete('associations/' + id).then(async (res) => {
				toast.success('Association deleted!');

				props.togglePaneUpdate();
			});
		} catch (error) {
			console.log('error', error);
		}
	};

	if (loading)
		return (
			<div style={{ padding: 24 }}>
				<Loader />
			</div>
		);
	return (
		<>
			{associatedCompanies && associatedCompanies.length > 0 ? (
				<List dense>
					{associatedCompanies.map((company, index) => (
						<ListItem
							key={index}
							style={{ paddingLeft: 24, paddingRight: 24 }}
							button
							component={Link}
							to={'/companies/' + company.companyId._id}
						>
							<ListItemAvatar>
								<Avatar style={{ width: 48, height: 48, marginRight: 16 }}>
									<Company
										style={{ fontSize: 24, marginLeft: 1 }}
										colorcode={theme.palette.primary.contrastText}
									/>
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								disableTypography
								primary={
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											width: '100%',
										}}
									>
										<Typography
											style={{ fontWeight: 500 }}
											color='textPrimary'
											variant='body1'
											align='left'
										>
											{company.companyId.companyName}
										</Typography>
										{company.isPrimaryCompany && (
											<Chip
												className={classes.chip}
												label='Primary'
												color='primary'
											/>
										)}
									</div>
								}
								secondary={
									<Typography
										style={{ marginTop: 5 }}
										color='textSecondary'
										variant='body1'
										align='left'
									>
										UEN: {company.companyId.uen}
									</Typography>
								}
							/>
							{editable && (
								<ListItemSecondaryAction style={{ right: '10%' }}>
									{!company.isPrimaryCompany && (
										<Button
											style={{ marginRight: 8 }}
											variant='text'
											color='primary'
											onClick={(e) =>
												setAssociatedCompanyAsPrimary(e, company._id)
											}
										>
											Set as Primary
										</Button>
									)}
									<IconButton
										onClick={(e) =>
											handleRemoveAssociatedCompany(e, company._id)
										}
										edge='end'
										aria-label='delete'
									>
										<Delete
											style={{ fontSize: 24 }}
											colorcode={theme.palette.text.secondary}
										/>
									</IconButton>
								</ListItemSecondaryAction>
							)}
						</ListItem>
					))}
				</List>
			) : (
				<Typography
					style={{ padding: 24 }}
					color='textPrimary'
					variant='body1'
					align='left'
				>
					No associated companies found!
				</Typography>
			)}
			{editable && (
				<div
					style={{
						display: 'flex',
						padding: 24,
						width: '100%',
						justifyContent: 'center',
					}}
				>
					<Button
						variant='outlined'
						color='primary'
						className={classes.button}
						onClick={(e) => handleOpenMenu(e)}
					>
						Add Associated Company
					</Button>
				</div>
			)}
			<AssocModal
				body='addAssociatedCompany'
				open={open}
				setOpen={setOpen}
				anchorEl={anchorEl}
				contactId={contactId}
				handleClose={() => handleCloseMenu()}
				setTriggerListUpdate={setTriggerListUpdate}
				togglePaneUpdate={togglePaneUpdate}
			/>
		</>
	);
}
