import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import NavPrompt from '../../../components/NavPrompt';
import api from '../../../config/api';
import { AuthUserContext } from '../../../components/Session';
import { Organization } from '../../../utils/validators/schemas';
import { Alert } from '@material-ui/lab';
import { Delete, Save } from '../../../components/Icons';

const useStyles = makeStyles((theme) => ({
	alertBar: {
		marginBottom: 24,
	},
	large: {
		width: 250,
		height: 150,
	},
	deleteIcon: {
		top: 70,
	},
	input: {
		display: 'none',
	},
	skeletonRect: {
		width: 250,
		height: 150,
		margin: 0,
	},
}));

const initState = {
	isRegistered: false, // To identify if organization is Registered
	registrationDate: '',
	name: '',
	entityType: '', // LP/LLP/SP/PL
	uen: '',
	email: '',
	officeNo: '',
	faxNo: '',
	website: '',
	address: '',
	unitNo: '',
	postalCode: '',
	logo: '', //AWS Key
};

export default function OrganizationInfo(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { userProfile, setUserProfile } = useContext(AuthUserContext);
	const [organizationProfile, setOrganizationProfile] = useState(initState);
	const [loading, setLoading] = useState(true);
	const [logo, setLogo] = useState(null);
	const [errorMessages, setErrorMessages] = useState({});
	const [triggerUpdate, setTriggerUpdate] = useState(true);
	const [modified, setModified] = useState(false);

	const getData = async () => {
		try {
			setLoading(true);
			const res = await api.get('settings/organization/type/profile');
			if (res.data.ok) {
				if (res.data.data.logo !== undefined) {
					setLogo(res.data.data.logo);
				}
				setOrganizationProfile(res.data.data);
				setModified(false);
			}
		} catch (error) {
			console.log('error', error);
		} finally {
			setTriggerUpdate(false);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!loading && !modified) {
			setModified(true);
		}
	}, [organizationProfile]);

	useEffect(() => {
		if (triggerUpdate) getData();
	}, [triggerUpdate]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setOrganizationProfile((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleDateChange = (name, date) => {
		setOrganizationProfile((prev) => ({
			...prev,
			[name]: date,
		}));
	};

	const validateFile = (file) => {
		const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];
		let valid = true;
		let errorMessage = '';

		if (validTypes.indexOf(file.type) === -1) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (!file.name.match(/.(jpg|jpeg|png|bmp)$/i)) {
			valid = false;
			errorMessage = 'Invalid file type';
		}
		if (file.size > 2097152) {
			valid = false;
			errorMessage = 'File size cannot exceed 2mb';
		}

		return {
			valid: valid,
			errorMessage: errorMessage,
		};
	};

	const handleChangeImage = async (e) => {
		try {
			const { name, files } = e.target;
			setLoading(true);

			let result = validateFile(files[0]);
			if (result.valid) {
				const formData = new FormData();
				formData.append('Logo', files[0], files[0].name);

				await api.put('settings/organization/logo', formData).then((res) => {
					toast.success('Logo updated!');
					setTriggerUpdate(true);
				});
			} else {
				toast.error(result.errorMessage);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleRemoveImage = async (e) => {
		try {
			setLoading(true);

			let data = {
				data: {
					fileKey: organizationProfile.fileKey,
				},
			};

			await api.delete('settings/organization/logo', data).then((res) => {
				toast.success('Logo removed!');
				setTriggerUpdate(true);
			});
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleValidation = () => {
		let result = Organization.validate(organizationProfile, {
			abortEarly: false,
			// convert: false,
		});

		const { error } = result;
		if (!error) {
			setErrorMessages({});
			return true;
		} else {
			const errorData = {};
			for (let item of error.details) {
				const key = item.path.join('.');
				const message = item.message;
				errorData[key] = message;
			}
			setErrorMessages(errorData);
			return false;
		}
	};

	const handleSave = async (e) => {
		try {
			e.preventDefault();
			setLoading(true);

			let valid = handleValidation();

			if (valid) {
				let data = {
					name: organizationProfile.name,
					uen: organizationProfile.uen,
					registrationDate: organizationProfile.registrationDate,
					email: organizationProfile.email,
					website: organizationProfile.website,
					officeNo: organizationProfile.officeNo,
					faxNo: organizationProfile.faxNo,
					address: organizationProfile.address,
					city: organizationProfile.city,
					unitNo: organizationProfile.unitNo,
					postalCode: organizationProfile.postalCode,
				};

				await api
					.put('settings/organization/type/profile', data)
					.then((res) => {
						if (res.data.ok) {
							setErrorMessages({});
							setLoading(false);
							setModified(false);
							if (!userProfile.hasOrganization) {
								setUserProfile((prev) => ({
									...prev,
									hasOrganization: true,
								}));
							}
							toast.success('Organization updated!');
						} else {
							setLoading(false);
							toast.error(res.data.error);
						}
					});
			} else {
				setLoading(false);
				toast.error('An error has occured!');
			}
		} catch (error) {
			console.log('error', error);
			setLoading(false);
		}
	};

	return (
		<>
			<NavPrompt when={modified} />
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{!userProfile.hasOrganization && (
					<Alert className={classes.alertBar} variant='filled' severity='info'>
						If you own a Company (Agency) or a part thereof, please fill-in your
						Company details to enable Invoices in Uuoni.
					</Alert>
				)}
				<Grid container spacing={3}>
					<Grid item container alignItems='center' spacing={3}>
						<div style={{ position: 'relative', padding: 12 }}>
							<div className={classes.avatarContainer}>
								<input
									accept='image/jpeg, image/jpg, image/png, image/bmp'
									className={classes.input}
									id='icon-button-file'
									type='file'
									name='logo'
									onChange={
										process.env.REACT_APP_ENVIRONMENT !== 'demo'
											? handleChangeImage
											: undefined
									}
								/>
								<label htmlFor='icon-button-file'>
									<IconButton component='span' style={{ borderRadius: 0 }}>
										{loading ? (
											<Skeleton
												className={classes.skeletonRect}
												variant='rect'
												animation='wave'
											/>
										) : (
											<Avatar
												variant='square'
												src={
													logo !== null ? `data:image/*;base64,${logo}` : 'Logo'
												}
												className={classes.large}
												imgProps={{ style: { objectFit: 'contain' } }}
											/>
										)}
									</IconButton>
								</label>
								{!loading && logo !== null && (
									<IconButton
										className={classes.deleteIcon}
										disableFocusRipple
										disableRipple
										size='small'
										onClick={
											process.env.REACT_APP_ENVIRONMENT !== 'demo'
												? (e) => handleRemoveImage(e)
												: undefined
										}
									>
										<Delete
											style={{ fontSize: 24 }}
											colorcode={theme.palette.text.secondary}
										/>
									</IconButton>
								)}
							</div>
						</div>
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Organization Name
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='name'
								variant='filled'
								margin='dense'
								fullWidth
								onChange={(e) => handleChange(e)}
								value={organizationProfile.name ? organizationProfile.name : ''}
								error={!!errorMessages[`name`]}
								helperText={errorMessages[`name`] && errorMessages[`name`]}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							UEN No.
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='uen'
								variant='filled'
								margin='dense'
								fullWidth
								onChange={(e) => handleChange(e)}
								value={organizationProfile.uen ? organizationProfile.uen : ''}
								error={!!errorMessages[`uen`]}
								helperText={errorMessages[`uen`] && errorMessages[`uen`]}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Website
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='website'
								variant='filled'
								margin='dense'
								fullWidth
								onChange={(e) => handleChange(e)}
								value={
									organizationProfile.website ? organizationProfile.website : ''
								}
								error={!!errorMessages[`website`]}
								helperText={
									errorMessages[`website`] && errorMessages[`website`]
								}
							/>
						)}
					</Grid>
					<Box width='100%' />
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Email
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='email'
								variant='filled'
								margin='dense'
								fullWidth
								onChange={(e) => handleChange(e)}
								value={
									organizationProfile.email ? organizationProfile.email : ''
								}
								error={!!errorMessages[`email`]}
								helperText={errorMessages[`email`] && errorMessages[`email`]}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Office No.
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='officeNo'
								variant='filled'
								margin='dense'
								fullWidth
								onChange={(e) => handleChange(e)}
								value={
									organizationProfile.officeNo
										? organizationProfile.officeNo
										: ''
								}
								error={!!errorMessages[`officeNo`]}
								helperText={
									errorMessages[`officeNo`] && errorMessages[`officeNo`]
								}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Fax No.
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='faxNo'
								variant='filled'
								margin='dense'
								fullWidth
								onChange={(e) => handleChange(e)}
								value={
									organizationProfile.faxNo ? organizationProfile.faxNo : ''
								}
								error={!!errorMessages[`faxNo`]}
								helperText={errorMessages[`faxNo`] && errorMessages[`faxNo`]}
							/>
						)}
					</Grid>
					<Box width='100%' />
					<Grid item xs={9}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Address
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='address'
								variant='filled'
								fullWidth
								margin='dense'
								onChange={(e) => handleChange(e)}
								value={
									organizationProfile.address ? organizationProfile.address : ''
								}
								error={!!errorMessages[`address`]}
								helperText={
									errorMessages[`address`] && errorMessages[`address`]
								}
							/>
						)}
					</Grid>
					<Box width='100%' />
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							City
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='city'
								variant='filled'
								margin='dense'
								fullWidth
								onChange={(e) => handleChange(e)}
								value={organizationProfile.city ? organizationProfile.city : ''}
								error={!!errorMessages[`city`]}
								helperText={errorMessages[`city`] && errorMessages[`city`]}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography color='textPrimary' variant='body2' align='left'>
							Unit No.
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='unitNo'
								variant='filled'
								margin='dense'
								fullWidth
								onChange={(e) => handleChange(e)}
								value={
									organizationProfile.unitNo ? organizationProfile.unitNo : ''
								}
								error={!!errorMessages[`unitNo`]}
								helperText={errorMessages[`unitNo`] && errorMessages[`unitNo`]}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						<Typography
							style={{ whiteSpace: 'nowrap' }}
							color='textPrimary'
							variant='body2'
							align='left'
						>
							Postal Code
						</Typography>
						{loading ? (
							<Skeleton variant='text' animation='wave' />
						) : (
							<TextField
								name='postalCode'
								variant='filled'
								margin='dense'
								fullWidth
								onChange={(e) => handleChange(e)}
								value={
									organizationProfile.postalCode
										? organizationProfile.postalCode
										: ''
								}
								error={!!errorMessages[`postalCode`]}
								helperText={
									errorMessages[`postalCode`] && errorMessages[`postalCode`]
								}
							/>
						)}
					</Grid>
				</Grid>
				<div
					className='formFooter'
					style={{ display: 'flex', marginTop: 32, marginBottom: 32 }}
				>
					<Button
						variant='contained'
						color='primary'
						disabled={!modified}
						startIcon={
							<Save
								style={{ fontSize: 24 }}
								colorcode={
									!modified
										? theme.palette.grey[500]
										: theme.palette.background.default
								}
							/>
						}
						onClick={(e) => handleSave(e)}
					>
						Save
					</Button>
				</div>
			</div>
		</>
	);
}
