import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as BannerLBVector } from '../../assets/MobileUserLoginBanner_LBVector.svg';
import { ReactComponent as BannerRTVector } from '../../assets/MobileUserLoginBanner_RTVector.svg';
import { Home } from '../Icons';

const useStyles = makeStyles((theme) => ({
	links: {
		'& > * + *': {
			marginLeft: 32,
		},
	},
}));

export default function AdPanel(props) {
	let isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });
	const classes = useStyles();
	const theme = useTheme();

	const getBGImage = (type) => {
		switch (type) {
			case '/forgot-password':
			case '/login': {
				return `url(${process.env.REACT_APP_CDN_URL}/Login.png)`;
			}
			case '/register': {
				return `url(${process.env.REACT_APP_CDN_URL}/SignUp.png)`;
			}
			default: {
				return `url(${process.env.REACT_APP_CDN_URL}/ClientTempLogin.png)`;
			}
		}
	};

	return (
		<>
			{isDesktopOrLaptop ? (
				<div
					style={{
						height: '100%',
						backgroundColor: theme.palette.primary.main,
						padding: 40,
						display: 'flex',
						flexDirection: 'column',
						backgroundImage: getBGImage(props.type),
						backgroundSize: 'cover',
					}}
				>
					<div style={{ width: '100%', marginBottom: '60%' }}>
						<Button
							component={RouterLink}
							to={'/'}
							style={{ backgroundColor: 'transparent', padding: 0 }}
							disableRipple
							disableFocusRipple
							disableElevation
						>
							<img
								style={{ width: 200 }}
								src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
								alt='brand-logo'
							/>
						</Button>
					</div>
					<div
						style={{
							flexGrow: 1,
							width: '90%',
							color: theme.palette.primary.contrastText,
						}}
					>
						<Typography variant='h1'>Get Started with Uuoni</Typography>
						<Typography style={{ fontWeight: 400, marginTop: 24 }} variant='h3'>
							Digitalizing your workflows by managing your contacts,
							transactions and communications all in one location
						</Typography>
					</div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							position: 'relative',
							justifyContent: 'space-between',
							margin: '0px auto',
						}}
					>
						<div className={classes.links}>
							<IconButton
								style={{ padding: 0 }}
								component={Link}
								href='https://www.uuoni.com'
								target='_blank'
								rel='noopener'
								disableFocusRipple
								disableTouchRipple
							>
								<Home
									style={{ fontSize: 24 }}
									colorcode={theme.palette.primary.contrastText}
								/>
							</IconButton>
							<Link
								style={{ color: theme.palette.primary.contrastText }}
								variant='subtitle1'
								href='https://www.uuoni.com/terms'
								target='_blank'
								rel='noopener'
							>
								Terms & Conditions
							</Link>
							<Link
								style={{ color: theme.palette.primary.contrastText }}
								variant='subtitle1'
								href='https://www.uuoni.com/privacy'
								target='_blank'
								rel='noopener'
							>
								Privacy Policy
							</Link>
							<Link
								style={{ color: theme.palette.primary.contrastText }}
								variant='subtitle1'
								href='https://www.uuoni.com/contact'
								target='_blank'
								rel='noopener'
							>
								Contact Us
							</Link>
						</div>
					</div>
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						padding: '16px 24px',
						backgroundColor: theme.palette.primary.main,
						position: 'relative',
						height: 77,
					}}
				>
					<BannerLBVector style={{ position: 'absolute', left: 0, top: 53 }} />
					<BannerRTVector style={{ position: 'absolute', right: 0 }} />
					<Button
						component={RouterLink}
						to={'/'}
						style={{ backgroundColor: 'transparent', padding: 0 }}
						disableRipple
						disableFocusRipple
						disableElevation
					>
						<img
							style={{ minWidth: 100, maxWidth: 143, marginRight: 16 }}
							src={`${process.env.REACT_APP_CDN_URL}/logo-brand-white.png`}
							alt='brand-logo'
						/>
					</Button>
					<Button
						style={{
							marginLeft: 'auto',
							color: theme.palette.primary.contrastText,
							padding: 0,
						}}
						variant='text'
						component={Link}
						href='https://www.uuoni.com/contact'
						target='_blank'
						rel='noopener'
						endIcon={
							<ArrowForwardIcon
								style={{
									border: `2px solid ${theme.palette.primary.contrastText}`,
									borderRadius: 12,
									marginRight: 4,
								}}
							/>
						}
					>
						<Typography variant='subtitle1'>Learn More</Typography>
					</Button>
				</div>
			)}
		</>
	);
}
