import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 22,
		padding: 0,
		margin: 8,
		marginRight: 16,
	},
	switchBase: {
		padding: 2,
		'&$checked': {
			transform: 'translateX(20px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: theme.palette.tertiary.main,
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			color: theme.palette.tertiary.main,
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 18,
		height: 18,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	disabled: {
		'& + $track': {
			opacity: '1 !important',
			backgroundColor: '#d9dcdd !important',
		},
	},
	focusVisible: {},
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
				disabled: classes.disabled,
			}}
			{...props}
		/>
	);
});

const CustomSwitch = (props) => {
	const { state, setState, label, disabled } = props;

	return (
		<FormGroup style={{ marginLeft: 4, marginRight: -16 }}>
			<FormControlLabel
				control={
					<IOSSwitch
						checked={state}
						onChange={setState}
						name='switch'
						disabled={disabled}
					/>
				}
				label={label}
			/>
		</FormGroup>
	);
};

export default CustomSwitch;
