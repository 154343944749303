// import app from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database';

import app from 'firebase/compat/app';
import 'firebase/compat/auth';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	// databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
	constructor() {
		app.initializeApp(config);
		this.auth = app.auth();
		// this.db = app.database();

		this.googleProvider = new app.auth.GoogleAuthProvider();
		this.emailProvider = new app.auth.EmailAuthProvider();
	}

	// *** Auth API ***

	doSignInAnonymously = () => this.auth.signInAnonymously();

	doEmailUpdate = (email) => this.auth.currentUser.updateEmail(email);

	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);

	doSignInWithEmailAndPassword = (email, password, rememberMe) => {
		return this.auth
			.setPersistence(
				rememberMe
					? app.auth.Auth.Persistence.LOCAL
					: app.auth.Auth.Persistence.SESSION
			)
			.then(() => {
				return this.auth.signInWithEmailAndPassword(email, password);
			});
	};

	doSignInWithGoogle = () => this.auth.signInWithRedirect(this.googleProvider);

	doSignOut = () => this.auth.signOut();

	doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

	doPasswordUpdate = (password) =>
		this.auth.currentUser.updatePassword(password);

	doSendEmailVerification = () =>
		this.auth.currentUser.sendEmailVerification({
			url: process.env.REACT_APP_DOMAIN,
			// url: 'http://localhost:3000',
			// url: 'https://app.uuoni.com',
		});

	onAuthUserListener = (next, fallback) =>
		this.auth.onAuthStateChanged((authUser) => {
			if (authUser) {
				this.user(authUser.uid)
					.once('value')
					.then((snapshot) => {
						const dbUser = snapshot.val();

						// default empty roles
						if (!dbUser.roles) {
							dbUser.roles = {};
						}

						// merge auth and db user
						authUser = {
							uid: authUser.uid,
							email: authUser.email,
							emailVerified: authUser.emailVerified,
							providerData: authUser.providerData,
							...dbUser,
						};

						next(authUser);
					});
			} else {
				fallback();
			}
		});

	user = (uid) => this.db.ref(`users/${uid}`);

	users = () => this.db.ref('users');
}

export default Firebase;
